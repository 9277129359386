import React, { useState } from 'react';
import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import SRMCombinationsTab from './Tabs/SRMCombinationsTab';
import SRMRunnersTab from './Tabs/SRMRunnersTab';
import SRMAllBetsTab from './Tabs/SRMAllBetsTab';
import { SRM_TAB } from './SRMBets.types';
import { useSearchParams } from 'react-router-dom';

export default function SRMBets() {
  const tabs = Object.values(SRM_TAB);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.get('srm_tab') || tabs[0]
  );

  const changeTab = (tabName: SRM_TAB) => {
    setCurrentTab(tabName);
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('srm_tab', tabName);
    setSearchParams(updatedSearchParams);
  };

  return (
    <Flex
      width="100%"
      direction="column"
      py="4"
      pr="2"
      borderTop="1px solid"
      borderTopColor="gray.200"
    >
      <HStack w="full" ml="6">
        {tabs.map((tab) => (
          <Button
            key={tab}
            colorScheme="gray"
            isActive={tab === currentTab}
            _active={{ bg: 'origin.500', color: 'white' }}
            onClick={() => changeTab(tab)}
            minW="150"
          >
            {tab}
          </Button>
        ))}
      </HStack>
      <Box paddingTop={3}>
        {currentTab === SRM_TAB.ALL_SRM && <SRMAllBetsTab />}
        {currentTab === SRM_TAB.RUNNERS && <SRMRunnersTab />}
        {currentTab === SRM_TAB.COMBOS && <SRMCombinationsTab />}
      </Box>
    </Flex>
  );
}
