import React from 'react';
import { useQueryInPlay } from '@/api/tradeManager/inPlay/inPlay.hooks';
import { BetsTable } from '@/common/components';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import { Box } from '@chakra-ui/react';
import { Column } from '@tanstack/react-table';
import { useState } from 'react';
import { TEvent } from '../../types';
import { useColumns } from '../NextToJump/NextToJump.hooks';
import { TInPlayParams } from '@/api/tradeManager/inPlay/inPlay.types';

export const InPlay: React.FC = () => {
  const limit = 20;
  const [offset, setOffset] = useState<string>('');
  const params: TInPlayParams = {
    offset,
    limit,
  };

  const { data, isLoading } = useQueryInPlay({
    params,
    options: {
      refetchInterval: 30000,
    },
  });
  const columns = useColumns();
  const [history, setHistory] = useState<string[]>([]);
  const isLastFetch = (data?.events.length ?? 0) < limit;
  return (
    <Box height={{ min: '700px' }}>
      <BetsTable
        columns={columns as unknown as Column<TEvent>[]}
        data={(data?.events as TEvent[]) ?? []}
        loading={isLoading}
        // onClickRow={(row: TEvent) => goToDetails(row)}
      />

      <PaginationV3
        nextDisabled={isLastFetch}
        offset={offset}
        onPageChange={(newOffset) => {
          setOffset(newOffset);
        }}
        history={history}
        setHistory={setHistory}
        useHistory
        nextOffsetId={!isLastFetch ? data?.paging.next_offset : undefined}
      />
    </Box>
  );
};
