import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

// import RejectLimits from './Components/RejectLimits';
import BetApprovalLimit from './Components/BetApprovalLimit';
import { untilLimitAPIAvailable } from '../../../../../../common/config/flags';
import ExposureLimitsForm from './Components/ExposureLimits/ExposureLimitsForm';
import ExposureLimitsFormikHOC from './Components/ExposureLimits/ExposureLimitsFormHOC';

export const Limits: FC = () => (
  <Flex direction="column">
    {untilLimitAPIAvailable && (
      <Box
        sx={{
          py: '10',
          px: '7',
          borderBottom: '1px solid var(--bc-colors-gray-200)',
        }}
      >
        <BetApprovalLimit />
      </Box>
    )}
    <Box
      sx={{
        py: '10',
        px: '7',
        borderBottom: '1px solid var(--bc-colors-gray-200)',
      }}
    >
      <ExposureLimitsFormikHOC>
        <ExposureLimitsForm />
      </ExposureLimitsFormikHOC>
    </Box>
    {/* Not part of P1 */}
    {/* <Box sx={{ pb: '10', pt: '8' }}> */}
    {/*   <RejectLimitsFormikHOC> */}
    {/*     <RejectLimitsForm /> */}
    {/*   </RejectLimitsFormikHOC> */}
    {/* </Box> */}
  </Flex>
);
