import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNavLink } from '../../common/components/TabNavLink/TabNavLink';
import { notForP0 } from '../../common/config/flags';
import { getStrings } from '../../common/utils';

export default function BankWrapper() {
  const [
    {
      BanksPage: { RouteNames },
    },
  ] = getStrings();

  return (
    <>
      <Flex direction="row" justify="start">
        <TabNavLink to="/bank/withdrawals" className="grow">
          {RouteNames.withdrawals}
        </TabNavLink>
        {notForP0 && (
          <>
            <TabNavLink to="/bank/deposits" className="grow">
              {RouteNames.deposits}
            </TabNavLink>
            <TabNavLink to="/bank/analysis-dashboard" className="grow">
              {RouteNames.bankAnalysisDashboard}
            </TabNavLink>
          </>
        )}
      </Flex>

      <Outlet />
    </>
  );
}
