import React from 'react';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import { TCMSFormValues } from '../../services/Cms.types';
import { ReactElement } from 'react';
import { useValidation } from '../../services/CMS.hooks';
import { mutateAddCMSLinks, queryCMS } from '@/api/settings/cms/cms';
import { useMutation, useQuery } from 'react-query';
import { keys } from '@/api/api.keys';
import { useHeader } from '@/common/ui/Header/services/Header.hooks';
import { useToast } from '@chakra-ui/react';
import useErrorHandler from '@/hooks/useErrorHandler';
import { LoadingSpinner } from '@/common/components';

type TFormikHOC = {
  children: ReactElement;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const { platform } = useHeader();
  const { customMarketsSchema } = useValidation();
  const toast = useToast();
  const onError = useErrorHandler();

  const { data, isLoading } = useQuery([keys.cms], () =>
    queryCMS(platform ?? '')
  );

  const { mutateAsync } = useMutation(mutateAddCMSLinks, {
    onSuccess: () => {
      toast({
        title: 'Links updated!',
        status: 'success',
        position: 'top',
        duration: 9_000,
      });
    },
    onError,
  });

  if (isLoading) return <LoadingSpinner />;

  const initialData = [
    {
      id: uuid(),
      name: '',
      order: 1,
      top_level: true,
      url: '',
      is_event: false,
      event_id: '',
      competition_id: '',
      is_sport_event: false,
    },
  ];

  const initialValues: TCMSFormValues = {
    links: !!data?.length ? data : initialData,
  };

  return (
    <Formik<TCMSFormValues>
      initialValues={initialValues}
      validationSchema={customMarketsSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        const base64String = btoa(JSON.stringify(values.links));

        mutateAsync({ contentBase64: base64String });
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
