import React, { FC } from 'react';
import {
  StyledDataTable,
  LoadingSpinner,
} from '../../../../../../../../common/components';
import { TRaceExoticResult } from '../../../../../../../../lib/DBModels';
import { useColumns } from '../../../../Services/RaceDetails.hooks';

type TExoticsResultsTableProps = {
  results: TRaceExoticResult[] | undefined;
  resultsLoading: boolean;
};

export const ExoticsResultsTable: FC<TExoticsResultsTableProps> = ({
  results,
  resultsLoading,
}) => {
  const { columns } = useColumns();

  return (
    <StyledDataTable<TRaceExoticResult>
      columns={columns}
      style={{ width: '100%' }}
      placeholder={resultsLoading ? <LoadingSpinner /> : undefined}
      data={results ?? []}
      pad={{ bottom: 'none' }}
      sort={{
        property: 'number',
        direction: 'asc',
      }}
    />
  );
};
