import styled from 'styled-components';
import {
  chakra,
  ChakraComponent,
  Text,
  TextProps,
  HStack,
  StackProps,
} from '@chakra-ui/react';
import { ESettledSportsProps } from '../../../../../../lib/DBModels';

export const SwitchWrapper: ChakraComponent<'div', StackProps> = chakra(
  HStack,
  {
    baseStyle: () => ({
      p: '2',
      minW: '140px',
      h: '50px',
      bg: 'white',
      ml: '3',
      borderRadius: 'md',
    }),
  }
);

export const SportInfoTable = styled.table`
  & > tbody > tr > td {
    border-right: 1px solid ${({ theme }) => theme.global.colors.separatorLine};
    padding: 5px 13px;

    &:last-child {
      border-right: none;
    }
  }
`;

export const PropositionTitle = styled.p`
  flex-direction: row;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
`;

interface FlucPriceProps {
  flucUp?: boolean;
}

export const FlucPriceText = styled.text<FlucPriceProps>`
  padding-left: 5px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ flucUp, theme }) =>
    flucUp ? theme.global.colors.greenText : theme.global.colors.red};
`;

export const FlucText = chakra(Text, {
  baseStyle: () => ({
    fontWeight: '500',
    pl: '5px',
    color: 'blue.300',
  }),
});

// Temp until the table is moved to styled table & chakra!
export const ExposureTD = styled('td')`
  height: 1px; // To set an initial height to ensure we take full height
  padding: 0 !important;
`;

export const BetOutcome = chakra(Text, {
  shouldForwardProp: (prop) => !['outcome'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ outcome }: { outcome: string } & any) => ({
    bg: () => {
      switch (outcome) {
        case ESettledSportsProps.Winner:
          return 'green.400';
        case ESettledSportsProps.Loser:
          return 'red.400';
        default:
          return 'orange.400';
      }
    },
    mr: '1',
    color: 'white',
    borderRadius: 'full',
    fontSize: 'xs',
    px: '2',
    py: '1',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  }),
}) as ChakraComponent<'text', TextProps & { outcome?: ESettledSportsProps }>;

export const HeadingMatchTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    textAlign: 'left',
    alignSelf: 'start',
    fontWeight: 'normal',
    color: 'gray.700',
  }),
});
