import React, { useState } from 'react';
import { Box, Text, Flex, Button, Icon } from '@chakra-ui/react';
import { CaretRight, CaretDown } from '@styled-icons/boxicons-regular';
import { TCombosProps } from '../Services/Types.ExoticsBetCombos';
import { getPlaceFromNumber } from '../Services/Utils.ExoticBetCombos';
import { getStrings } from '../../../utils';

export default function ExoticBetSelection({
  selection,
  boxSx,
  fontSize = 'sm',
}: TCombosProps) {
  const [{ Generic }] = getStrings();

  const [expanded, setExpanded] = useState(false);

  if (!selection) return null;

  return (
    <Box sx={{ ...boxSx }}>
      <Button
        m="0"
        p="0"
        variant="ghost"
        fontSize={fontSize}
        color="brand.500"
        onClick={() => setExpanded(!expanded)}
      >
        <Icon as={expanded ? CaretDown : CaretRight} mr="1" />{' '}
        {Generic.ViewDetails}
      </Button>

      {expanded && (
        <Box mt="2">
          {selection.map((combo, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex key={`combo_${index}`} mb={2}>
              <Text fontSize={fontSize} w={75}>
                {getPlaceFromNumber(index + 1)}
              </Text>
              <Box>
                {combo?.map((runner, runner_index) => (
                  <Text
                    key={`runner-${runner?.runner_name}`}
                    fontSize={fontSize}
                    fontWeight="bold"
                  >
                    {runner_index + 1}. {runner?.runner_name ?? ''} (
                    {runner?.runner_number ?? ''})
                  </Text>
                ))}
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
}
