import React from 'react';
import { ErrorMessage, FormikContextType } from 'formik';
import {
  Flex,
  Text,
  Box,
  FormLabel,
  Button,
  Icon,
  Switch,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { HorseHead } from '@styled-icons/fa-solid/HorseHead';
import { EmojiEvents } from '@styled-icons/material-rounded/EmojiEvents';
import { Delete } from '@styled-icons/fluentui-system-regular/Delete';
import { TCMSFormValues, TLinks } from '../../services/Cms.types';
import { Motion } from '@/common/components';
import Input from '@/common/components/FormElements/Input';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import { CSSObject } from '@emotion/react';

dayjs.extend(utc);
dayjs.extend(tz);

type PropositionProps = {
  link: TLinks;
  index: number;
  removeProposition: (index: number, data: TLinks[]) => void;
  context?: FormikContextType<TCMSFormValues>;
  sxWrapper?: CSSObject;
  filteredData: TLinks[];
};

export const contentVariants = {
  hidden: { opacity: 0, y: -70 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -70 },
};

const LinkItem: React.FC<PropositionProps> = ({
  link,
  index,
  removeProposition,
  context,
  sxWrapper,
  filteredData,
}) => {
  const data = link;
  const isEvent = data?.is_event;
  const isSportEvent = data?.is_sport_event;

  const objectIndex =
    context?.values.links.findIndex((item) => {
      return item.id === data.id;
    }) ?? 0;

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    context?.setFieldValue(`links[${objectIndex}.url]`, text);
  };

  const labelWithError = (name: string, objectIndex: number) => (
    <Flex justifyContent="space-between">
      <FormLabel>{name.replaceAll('_', ' ')}</FormLabel>

      <Text fontSize="2xs" color="red">
        <ErrorMessage
          name={`links[${objectIndex}].${name
            .toLowerCase()
            .replaceAll(' ', '_')}`}
          component="div"
        />
      </Text>
    </Flex>
  );

  const timeZone = dayjs.tz.guess();

  return (
    <Motion variants={contentVariants} sx={sxWrapper}>
      {!isEvent ? (
        <Flex key={link.url} sx={{ gap: '3' }}>
          <Box>
            <FormLabel>Dropdown</FormLabel>
            <Switch
              name="top_level"
              defaultChecked={data?.top_level}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                h: '40px',
              }}
              onChange={() =>
                context?.setFieldValue(
                  `links[${objectIndex}].top_level`,
                  !data?.top_level
                )
              }
            />
          </Box>
          <Box>
            {labelWithError('Order', objectIndex)}
            <Input
              name="order"
              htmlSize={5}
              w="12"
              textAlign="center"
              type="number"
              value={data?.order}
              maxLength={1}
              onChange={(e) =>
                context?.setFieldValue(
                  `links[${objectIndex}].order`,
                  e.target.value
                )
              }
            />
          </Box>

          <Box>
            {labelWithError('Name', objectIndex)}

            <Input
              name="name"
              value={data?.name}
              placeholder="Name"
              minW="300px"
              onChange={(e) =>
                context?.setFieldValue(
                  `links[${objectIndex}].name`,
                  e.target.value
                )
              }
            />
          </Box>
          <Box>
            {labelWithError('url', objectIndex)}

            <InputGroup size="md">
              <Input
                name="url"
                isFormik
                w="400px"
                fontSize="xs"
                value={data?.url}
                placeholder="https://www.punterbet.com"
                onChange={(e) =>
                  context?.setFieldValue(
                    `links[${objectIndex}].url`,
                    e.target.value
                  )
                }
              />

              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="xs"
                  colorScheme="gray"
                  onClick={() => handlePaste()}
                >
                  Paste
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box>
            {labelWithError('Start Time', objectIndex)}

            <DateTimePicker
              disableClock
              name="start_date"
              value={
                data?.start_date
                  ? dayjs.utc(data?.start_date).tz(timeZone).format()
                  : undefined
              }
              wrapperProps={{ h: '10' }}
              onChange={(value) => {
                const utcValue =
                  value === null ? undefined : dayjs(value).utc();
                context?.setFieldValue(
                  `links[${objectIndex}].start_date`,
                  utcValue
                );
              }}
            />
          </Box>

          <Box>
            {labelWithError('End Time', objectIndex)}

            <DateTimePicker
              disableClock
              name="end_date"
              value={
                data?.end_date
                  ? dayjs.utc(data?.end_date).tz(timeZone).format()
                  : undefined
              }
              wrapperProps={{ h: '10' }}
              onChange={(value) => {
                const utcValue =
                  value === null ? undefined : dayjs(value).utc();
                context?.setFieldValue(
                  `links[${objectIndex}].end_date`,
                  utcValue
                );
              }}
            />
          </Box>
          <Flex alignItems="end">
            <Button
              onClick={() => removeProposition(index, filteredData)}
              colorScheme="gray"
            >
              <Icon
                as={Delete}
                onClick={() => removeProposition(index, filteredData)}
                boxSize="6"
              />
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex gap="5" alignItems="end">
          <Flex justifyContent="center" pl="2">
            <Icon
              boxSize="12"
              sx={{
                p: '2',
                borderRadius: 'md',
                border: '1px',
                borderColor: 'origin.500',
                bg: 'origin.500',
                color: 'white',
                boxShadow: 'lg',
                mr: '1',
              }}
              as={isSportEvent ? EmojiEvents : HorseHead}
            />
          </Flex>

          <Box>
            {labelWithError('Order', objectIndex)}
            <Input
              name="order"
              htmlSize={5}
              w="12"
              textAlign="center"
              type="number"
              value={data?.order}
              maxLength={1}
              onChange={(e) =>
                context?.setFieldValue(
                  `links[${objectIndex}].order`,
                  e.target.value
                )
              }
            />
          </Box>
          {isSportEvent && (
            <Flex>
              <Box>
                {labelWithError('competition_id', objectIndex)}

                <Input
                  name="competition_id"
                  value={data?.competition_id}
                  w="100%"
                  minW="320px"
                  placeholder="Competition ID"
                  onChange={(e) =>
                    context?.setFieldValue(
                      `links[${objectIndex}].competition_id`,
                      e.target.value
                    )
                  }
                />
              </Box>
            </Flex>
          )}

          <Flex gap="3">
            <Box>
              {labelWithError('event_id', objectIndex)}

              <Input
                name="event_id"
                value={data?.event_id}
                w="100%"
                minW={isSportEvent ? '320px' : '660px'}
                placeholder={isSportEvent ? 'Match ID' : 'Race ID'}
                onChange={(e) =>
                  context?.setFieldValue(
                    `links[${objectIndex}].event_id`,
                    e.target.value
                  )
                }
              />
            </Box>

            <Box>
              {labelWithError('start_date', objectIndex)}

              <DateTimePicker
                disableClock
                name="start_date"
                value={
                  data?.end_date
                    ? dayjs.utc(data?.start_date).tz(timeZone).format()
                    : undefined
                }
                wrapperProps={{ h: '10' }}
                onChange={(value) => {
                  const utcValue =
                    value === null ? undefined : dayjs(value).utc();
                  context?.setFieldValue(
                    `links[${objectIndex}].start_date`,
                    utcValue
                  );
                }}
              />
            </Box>

            <Box>
              {labelWithError('end_date', objectIndex)}

              <DateTimePicker
                disableClock
                name="end_date"
                value={
                  data?.end_date
                    ? dayjs.utc(data?.end_date).tz(timeZone).format()
                    : undefined
                }
                wrapperProps={{ h: '10' }}
                onChange={(value) => {
                  const utcValue =
                    value === null ? undefined : dayjs(value).utc();
                  context?.setFieldValue(
                    `links[${objectIndex}].end_date`,
                    utcValue
                  );
                }}
              />
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Button
              onClick={() => removeProposition(index, filteredData)}
              colorScheme="gray"
            >
              <Icon
                as={Delete}
                onClick={() => removeProposition(index, filteredData)}
                boxSize="6"
              />
            </Button>
          </Flex>
        </Flex>
      )}
    </Motion>
  );
};

export default LinkItem;
