import React, { useState } from 'react';
import {
  useSRMCombinations,
  useSRMPunterCombinations,
} from '../hooks/SRMCombinations.hooks';
import { PaginationV2 } from '@/common/components/Pagination/PaginationV2';
import { BetsTable } from '@/common/components';
import {
  useSRMCombinationColumns,
  useSRMCombinationSubColumns,
} from '../config/SRMCombinations.config';

export default function SRMCombinationsTab() {
  const [selectedMulti, setSelectedMulti] = useState('');
  const {
    data,
    isLoading,
    next,
    previous,
    previousDisabled,
    nextDisabled,
    hidePagination,
  } = useSRMCombinations();

  const {
    dataInner,
    isLoadingInner,
    nextInner,
    previousInner,
    previousDisabledInner,
    nextDisabledInner,
    hidePaginationInner,
  } = useSRMPunterCombinations(selectedMulti);

  const { columns } = useSRMCombinationColumns();
  const { combinations } = data || {
    combinations: [],
    paging: {
      next_offset: null,
    },
  };
  const { subColumns } = useSRMCombinationSubColumns();
  const { punters } = dataInner || {
    punters: [],
    paging: {
      next_offset: null,
    },
  };

  return (
    <>
      <BetsTable
        hasSubTable
        columns={columns}
        data={combinations as Api['SRMultisRaceCombinationStatsItemResponse'][]}
        loading={isLoading}
        initialSort={[{ id: 'bets', desc: true }]}
        onClickExpand={(
          row: Api['SRMultisRaceCombinationStatsItemResponse']
        ) => {
          setSelectedMulti(row.multi_uid);
        }}
      >
        <BetsTable
          columns={subColumns}
          data={
            (punters ??
              []) as unknown as Api['SRMultisRaceCombinationPunterStatsItemResponse'][]
          }
          loading={isLoadingInner}
        />
        <PaginationV2
          {...{
            onNext: nextInner,
            onPrev: previousInner,
            hidePagination: hidePaginationInner,
            nextDisabled: nextDisabledInner,
            previousDisabled: previousDisabledInner,
          }}
        />
      </BetsTable>

      <PaginationV2
        {...{
          onNext: next,
          onPrev: previous,
          hidePagination,
          nextDisabled,
          previousDisabled,
        }}
      />
    </>
  );
}
