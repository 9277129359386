import { ListGlobalWrapper, ListBookieWrapper } from './Components/List';

const env = window.BETCLOUD_ENV || process.env;

/**
 * TODO: I'm unsure if this will cover all cases that we want.
 * Would be better to explicitly check if we're on wincore or not.
 *
 * This is used here:
 */
// Once Bets From Global Approval Site is done, should set isWincore = !!env.REACT_APP_API_URL.includes('wincore')
export const isWincore = !!env.REACT_APP_API_URL.includes('wincore');

export default isWincore ? ListGlobalWrapper : ListBookieWrapper;
