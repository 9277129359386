import { BetsQuery, TBetsResponse } from '../../types';

export type TResultedBetsState = {
  query: BetsQuery;
  resultedBetsResponse: TBetsResponse;
  betsLoading: boolean;
};

export enum EPlacedBetStates {
  PartiallySettled = 'partially_settled',
  Settled = 'settled',
  Voided = 'voided',
  Cancelled = 'cancelled',
  CashedOut = 'cashed_out',
  Pending = 'pending',
}

export enum EPlacedBetStatesMinusPartial {
  Settled = 'settled',
  Voided = 'voided',
  Cancelled = 'cancelled',
  CashedOut = 'cashed_out',
}
