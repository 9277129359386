import styled from 'styled-components';
import AlertTagProps from './types';

export const AlertTagContainer = styled.div<AlertTagProps>`
  height: 22px;
  border-radius: 5px;
  border: none;
  display: flex;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  ${({ theme, variant }) => {
    switch (variant) {
      case 'warning':
        return `background: ${theme.global.colors.yellow};`;
      case 'error':
        return `background: ${theme.global.colors.red};`;
      case 'success':
        return `background: ${theme.global.colors.green};`;
      case 'default':
      default:
        return `background: ${theme.global.colors.black};`;
    }
  }};
`;
