import React, { useState } from 'react';
import { Flex, Tooltip } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { TRaceMarket } from '@/lib/DBModels';

import { SwitchConfig, TextConfig } from './styles/WinPlaceConfig.styles';
import { useRaceConfig } from './services/WinPlaceConfig.hooks';
import { ERaceConfigSwitch } from './WinPlaceConfig.types';

type TMarketWinPlace = {
  raceMarket: TRaceMarket;
};

export default function WinPlaceConfig({ raceMarket }: TMarketWinPlace) {
  const { toggle } = useRaceConfig();

  const initialFollowPricesState = !raceMarket.lock_price;

  const initialSuspendedState = raceMarket.is_suspended;

  const [followPricesState, setFollowPricesState] = useState(
    initialFollowPricesState
  );

  const [suspendedState, setSuspendedState] = useState(initialSuspendedState);

  return (
    <>
      <Flex
        gap="10px"
        alignItems="left"
        direction="column"
        justifyContent="space-between"
      >
        <Tooltip
          label={
            <FormattedMessage id="trademanagerpage.racedetails.winplace.followprices.tooltip" />
          }
        >
          <Flex alignItems="center">
            <SwitchConfig
              isChecked={followPricesState}
              onChange={() => {
                setFollowPricesState(!followPricesState);
                toggle({
                  value: followPricesState,
                  market_id: raceMarket.market_id || '',
                  key: ERaceConfigSwitch.FollowPrices,
                });
              }}
            />
            <TextConfig>
              <FormattedMessage id="trademanagerpage.racedetails.winplace.followprices" />
            </TextConfig>
          </Flex>
        </Tooltip>

        <Tooltip
          label={
            <FormattedMessage id="trademanagerpage.racedetails.winplace.suspended.tooltip" />
          }
        >
          <Flex alignItems="center">
            <SwitchConfig
              isChecked={suspendedState}
              onChange={() => {
                setSuspendedState(!suspendedState);
                if (!suspendedState) {
                  setFollowPricesState(false);
                }
                toggle({
                  value: !suspendedState,
                  market_id: raceMarket.market_id || '',
                  key: ERaceConfigSwitch.Suspended,
                });
              }}
            />
            <TextConfig>
              <FormattedMessage id="trademanagerpage.racedetails.winplace.suspended" />
            </TextConfig>
          </Flex>
        </Tooltip>
      </Flex>
    </>
  );
}
