import api from '../../../api';
import { TCloseMarketBody } from './closeMarket.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const closeMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/open-close-market`;

// Post: Sports Status - Close Market
export const mutateCloseMarket = (body: TCloseMarketBody) =>
  api.post<TCloseMarketBody>(closeMarketURL, body).then((res) => res.data);
