import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../common/hooks/useRedux';
import { centsToDollars, getStrings } from '../../../../../../common/utils';
import { setModalSettleBet } from '../../Services/Slice.Bets';
import Form from './components/Form';
import FormikHOC from './components/FormikHOC';

export default function ModalSettleBet() {
  const dispatch = useAppDispatch();
  const { modalSettleBet } = useAppSelector((state) => state.punterBets);
  const [
    {
      PunterPage: {
        Bets: { Modal: ModalStrings },
      },
    },
  ] = getStrings();

  return (
    <Modal
      isOpen={!!modalSettleBet}
      onClose={() => dispatch(setModalSettleBet())}
    >
      <ModalOverlay />

      <ModalContent>
        <FormikHOC>
          <ModalCloseButton />

          <ModalHeader textAlign="center">
            Settle {modalSettleBet?.punter_name}{' '}
            {centsToDollars(modalSettleBet?.stake)} stake on{' '}
            {modalSettleBet?.market_name}
          </ModalHeader>

          <ModalBody>
            <Form />
          </ModalBody>

          <ModalFooter>
            <Button type="submit" variant="rounded" sx={{ w: 'full' }}>
              {ModalStrings.Buttons.Confirm}
            </Button>
          </ModalFooter>
        </FormikHOC>
      </ModalContent>
    </Modal>
  );
}
