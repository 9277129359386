import { chakra, ChakraComponent, Icon, IconProps } from '@chakra-ui/react';
import Select, { Props } from 'react-select';

export const SelectPunterFlag = chakra(Select, {
  baseStyle: () => ({
    m: '1',
    minW: '100%',
    textAlign: 'left',
  }),
}) as ChakraComponent<'select', Props>;

export const IconPlaceholder = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '5',
    color: 'gray.700',
    mr: '1',
  }),
}) as ChakraComponent<'select', IconProps>;
