import React from 'react';
import { useFormikContext } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Box, Stack, Button, Flex, Text } from '@chakra-ui/react';
import { TCMSFormValues } from '../../services/Cms.types';
import LinkItem from '../ListItem/LinkItem';
import { useListItem } from '../../services/CMS.hooks';
import Tabs from '@/common/components/Tabs/Tabs';
import tabs from '../../services/CMS.config';

export default function Form() {
  const context = useFormikContext<TCMSFormValues>();
  const { addProposition, removeProposition } = useListItem();

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const filteredData = context.values.links.filter((item) => {
    if (type === 'quick-links') {
      return !item.is_event && !item.is_sport_event;
    }
    if (type === 'sports') {
      return item.is_event && item.is_sport_event;
    }
    if (type === 'racing') {
      return item.is_event && !item.is_sport_event;
    }
  });

  return (
    <Box p="5" minH="80vh">
      <Stack direction={['row', 'column']} my="5" w="100%" spacing="24px">
        <Text fontSize="2xl" color="#444" mb="1">
          Content Management System
        </Text>
        <Text fontSize="md" color="#333" mb="5">
          Manage the quick links and featured matches and races displayed to
          Punters across the web platform and apps.
        </Text>

        <Tabs tabs={tabs} />

        {type === 'quick-links' && (
          <Text fontSize="md" color="#333" mb="14" w="70%">
            <Text mb="4">
              Manage the hyperlinks that appear in the side navigation on the
              Punter web platform and on the home page of the Punter app. These
              can be links to particular punter pages, events, competitions or
              individual races or matches. All links will appear in the Top
              Events dropdown by default to set the display as a primary option
              of the side navigation, turn off the dropdown toggle below.
            </Text>
            <Text>
              Links will display based on the <strong>order</strong> numbers,
              from lowest to highest.
            </Text>
          </Text>
        )}

        {type === 'racing' && (
          <Text fontSize="md" color="#333" mb="14" w="70%">
            <Text mb="4">
              Set the featured races that display on the racing home page of the
              Punter web platform and app.
            </Text>
            <Text>
              Races will display based on the <strong>order</strong> numbers,
              from lowest to highest.
            </Text>
          </Text>
        )}

        {type === 'sports' && (
          <Text fontSize="md" color="#333" mb="14" w="70%">
            <Text mb="4">
              Set the featured sports matches that display on the sports home
              page of the Punter web platform and app.
            </Text>
            <Text>
              Matches will display based on the <strong>order</strong> numbers,
              from lowest to highest.
            </Text>
          </Text>
        )}

        {filteredData.map((link, i) => (
          <LinkItem
            key={i}
            index={i}
            link={link}
            removeProposition={removeProposition}
            context={context}
            filteredData={filteredData}
          />
        ))}
        <Flex alignItems="center" mt="10" justifyContent="space-between">
          <Flex gap="5">
            {type === 'quick-links' && (
              <Button
                colorScheme="gray"
                onClick={() =>
                  addProposition({
                    index: filteredData.length,
                    isEvent: false,
                    rawData: filteredData,
                  })
                }
              >
                + Add Quick Link
              </Button>
            )}
            {type === 'sports' && (
              <Button
                colorScheme="gray"
                onClick={() => {
                  addProposition({
                    index: filteredData.length,
                    isEvent: true,
                    isSport: true,
                    rawData: filteredData,
                  });
                }}
              >
                + Add Featured Sport
              </Button>
            )}
            {type === 'racing' && (
              <Button
                colorScheme="gray"
                onClick={() => {
                  addProposition({
                    index: filteredData.length,
                    isEvent: true,
                    isSport: false,
                    rawData: filteredData,
                  });
                }}
              >
                + Add Featured Race
              </Button>
            )}
          </Flex>

          <Button
            isLoading={context.isSubmitting}
            isDisabled={!context.dirty}
            loadingText="Submitting"
            colorScheme="whatsapp"
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
}
