import { mixed, object, SchemaOf, string } from 'yup';
import { EVoidReason, TVoidBetInput } from './Types.VoidBet';
import { getStrings } from '../../../../../common/utils';

const [{ FormValidation }] = getStrings();

export const VoidBetInputValidation: SchemaOf<TVoidBetInput> = object({
  bet_id: string().required(FormValidation.RequiredField),
  reason: mixed<EVoidReason>().required(FormValidation.RequiredField),
  notes: string(),
});

export const VoidBetInitialValues: TVoidBetInput = {
  bet_id: '',
};
