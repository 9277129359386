import styled from 'styled-components';

export const SingleStatBoxContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.global.colors.turquoise};
  background: ${({ theme }) => theme.global.colors.paleBlue};
  display: flex;
  flex-direction: column;
  min-width: 120px;
  justify-content: center;
  align-items: center;
`;

export const SingleStatLabel = styled.div`
  color: ${({ theme }) => theme.global.colors.midBlue};
  font-weight: bold;
  font-size: 11px;
`;

export const SingleStatValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.global.colors.black};
`;
