import { chakra, MenuItem, MenuGroup, MenuList } from '@chakra-ui/react';

export const BCMenuList = chakra(MenuList, {
  baseStyle: () => ({
    p: 0,
  }),
});

export const BCMenuItem = chakra(MenuItem, {
  baseStyle: () => ({
    color: 'gray.800',
    fontWeight: 'normal',
  }),
});

export const BCMenuGroup = chakra(MenuGroup, {
  baseStyle: () => ({
    color: 'gray.700',
    fontWeight: 'semibold',

    borderBottom: '1px',
    borderBottomColor: 'gray.200',
  }),
});
