/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TBetApprovalsResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { queryHistoryApprovals } from './history';
import { THistoryApprovalsParams } from './history.types';

type THistoryApprovalsHook = {
  params?: THistoryApprovalsParams;
  key?: unknown[];
  options?: UseQueryOptions<TBetApprovalsResponse>;
};

export const useQueryHistoryApprovals = ({
  params = {},
  key = [],
  options = {},
}: THistoryApprovalsHook = {}) => {
  const query = useQuery(
    [keys.historyApprovals, ...Object.values(params), ...key],
    () => queryHistoryApprovals(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
