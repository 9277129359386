import {
  TPunterWithdrawalRequestsPagedHistory,
  TWithdrawalStatsDTO,
} from '@/lib/DTO';
import api from '../../api';
import {
  TWithdrawalParams,
  TWithdrawalStatsParams,
  TWithdrawalsDownloadInput,
} from '../withdrawals.types';

// Withdrawal - History

const withdrawalHistoryURL = '/bookie/bank/withdrawals/history';

export const queryWithdrawalHistory = (params: TWithdrawalParams) =>
  api
    .get<TPunterWithdrawalRequestsPagedHistory>(withdrawalHistoryURL, {
      params,
    })
    .then((res) => res.data);

const withdrawalStatsURL = '/bookie/bank/withdrawals/stats';

export const queryWithdrawalStats = (params: TWithdrawalStatsParams) =>
  api
    .get<TWithdrawalStatsDTO>(withdrawalStatsURL, { params })
    .then((res) => res.data);

const withdrawalHistoryDownloadURL = '/bookie/bank/withdrawals/download';

export async function mutateWithdrawalHistory(body: TWithdrawalsDownloadInput) {
  const res = await api.post<string>(withdrawalHistoryDownloadURL, body);
  return res;
}
