import api from '@/api/api';
import { TQueryPromoSummaryParams } from './promoSummary.types';

export const promoSummaryEndpoint = '/bookie/promos/promo/promo-summary';

export async function queryPromoSummary(params: TQueryPromoSummaryParams) {
  const res = await api.get<Api['PromoSummaryResponse']>(promoSummaryEndpoint, {
    params,
  });
  return res;
}
