import { useMemo } from 'react';
import { getStrings, centsToDollars } from '@/common/utils';
import { ColumnDef } from '@tanstack/react-table';

export const useSRMRunnerColumns = () => {
  const [
    {
      TradeManagerPage: {
        SRMBets: { ColumnTitles },
      },
    },
  ] = getStrings();

  const columns = useMemo<
    ColumnDef<Api['SRMultisRaceRunnerStatsItemResponse']>[]
  >(
    () => [
      {
        accessorKey: 'name',
        header: ColumnTitles.Runner,
      },
      {
        accessorKey: 'bets_count',
        header: ColumnTitles.Bets,
      },
      {
        accessorKey: 'punters_count',
        header: ColumnTitles.NumberPunters,
      },
      {
        id: 'stake',
        accessorKey: 'stake',
        header: ColumnTitles.Stake,
        cell: ({ row }) => centsToDollars(row.original.stake ?? 0),
      },
      // Exposure: 'Exposure',
      {
        id: 'exposure',
        accessorKey: 'exposure',
        header: ColumnTitles.Exposure,
        cell: ({ row }) => centsToDollars(row.original.exposure ?? 0),
      },
    ],
    [ColumnTitles]
  );

  return { columns };
};
