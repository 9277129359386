import { SystemStyleObject, Icon, useBoolean } from '@chakra-ui/react';
import React, { Fragment, useRef } from 'react';
import { Warning } from '@styled-icons/fluentui-system-regular';
import SVG from 'react-inlinesvg';
import { ErrorBoundary } from 'react-error-boundary';
import { useOnScreen } from '@/hooks/useOnScreen';

type TIconSvg = {
  name: string;
  sx?: SystemStyleObject;
};

export default function IconSvg({ name, sx }: TIconSvg) {
  const [hasError, setHasError] = useBoolean();
  const ref = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ref);

  return (
    <ErrorBoundary fallback={<Fragment />}>
      <div ref={ref}></div>
      {isOnScreen && (
        <Icon
          as={!hasError ? SVG : Warning}
          {...(!hasError && {
            src: (() => {
              let icon = '';
              try {
                icon = require(`../../assets/icons/${name}.svg`);
              } catch (error) {
                icon = '';
              }
              return icon;
            })(),
            preProcessor: (code?: string) =>
              code?.replace(/fill=".*?"/g, 'fill="currentColor"'),
            onError: () => setHasError.on(),
          })}
          sx={sx}
        />
      )}
    </ErrorBoundary>
  );
}
