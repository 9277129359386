import { BoxProps, ChakraComponent, chakra, Box } from '@chakra-ui/react';
import { EGeneralStatus } from '../../../lib/DBModels';

export const BoxStatusLights = chakra(Box, {
  shouldForwardProp: (prop) => !['status', 'size'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ status, size }: any) => ({
    borderRadius: 'full',
    bgGradient: (() => {
      if (status === EGeneralStatus.Open)
        return 'linear(to-l, green.500, green.600)';
      if (status === EGeneralStatus.Closed)
        return 'linear(to-l, red.400, red.500)';
      if (status === EGeneralStatus.Abandoned)
        return 'linear(to-l, yellow.500, yellow.600)';
      if (status === EGeneralStatus.Scratched)
        return 'linear(to-l, gray.300, gray.500)';
      if (status === EGeneralStatus.Voided)
        return 'linear(to-l, gray.600, gray.700)';
      if (status === EGeneralStatus.Settled)
        return 'linear(to-l, gray.400, gray.500)';

      return 'linear(to-l, green.500, green.600)';
    })(),
    boxSize: (() => {
      if (size === 'sm') return '2.5';
      if (size === 'md') return '5';
      return '10';
    })(),
  }),
}) as ChakraComponent<
  'div',
  BoxProps & { status: EGeneralStatus; size: 'sm' | 'md' | 'lg' }
>;
