import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import Tabs from './components/Tabs';
import Void from './components/Void';
import Settlement from './components/Settlement/Settlement';
import Status from './components/Status/Status';
import { ETabs } from './ModalConfigure.types';
import { useModalConfigure } from './services/ModalConfigure.hooks';

export default function ModalConfigure() {
  const {
    isModalConfigureOpen,
    onModalConfigureClose,
    modalHeading,
    selectedTab,
    tabButtons,
  } = useModalConfigure();

  return (
    <Modal
      isOpen={isModalConfigureOpen}
      onClose={onModalConfigureClose}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>{modalHeading}</ModalHeader>

        <Tabs selectedTab={selectedTab} buttons={tabButtons} />

        {(() => {
          if (selectedTab === ETabs.Settlement) return <Settlement />;
          if (selectedTab === ETabs.Void) return <Void />;
          if (selectedTab === ETabs.Status) return <Status />;
          return null;
        })()}
      </ModalContent>
    </Modal>
  );
}
