import React from 'react';
import {
  ModalBody,
  Text,
  Tr,
  Th,
  Td,
  Radio,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { InfoCircle } from '@styled-icons/fa-solid/InfoCircle';
import TableChakraConfigure from '@/common/components/TableChakraConfigure/TableChakraConfigure';
import { useStatus } from '../services/Status.hooks';

export default function Form() {
  const [{ value }, , { setValue }] = useField({ name: 'status' });

  const { statusOptions, stub } = useStatus();

  return (
    <ModalBody
      sx={{
        minHeight: '20',
        '&&': { pb: '2' },
      }}
    >
      <TableChakraConfigure
        variant="simple"
        size="sm"
        header={statusOptions.map(({ option, tooltip }) => (
          <Th key={option} textAlign="center">
            {option}

            {tooltip && (
              <Tooltip label={tooltip} placement="top">
                <Icon ml="0.5" mb="0.5" as={InfoCircle} />
              </Tooltip>
            )}
          </Th>
        ))}
      >
        <Tr>
          <Td py="0.5" px="0">
            <Text
              textAlign="left"
              fontWeight="500"
              color="gray.600"
              sx={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            >
              {stub}
            </Text>
          </Td>
          {statusOptions.map(({ option }) => (
            <Td key={`race-${option}`} textAlign="center">
              <Radio
                bg="white"
                value={option}
                isChecked={value === option}
                onChange={() => setValue(option)}
              />
            </Td>
          ))}
        </Tr>
      </TableChakraConfigure>
    </ModalBody>
  );
}
