import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { activeButtonStyle } from '../Button';

export const TabFilterNavLink = styled(Link)`
  height: 30px;
  margin-top: 15px;
  min-width: 50px;
  border: 1px solid ${({ theme }) => theme.global.colors.buttonBorder};
  background: white;
  color: ${({ theme }) => theme.global.colors.tabButtonText};
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  &.active {
    ${activeButtonStyle}
  }
`;
