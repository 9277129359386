import api from '../../../api';
import { TMutatePriceTypesBody } from './updatePriceTypes.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const updatePriceTypesEndpoint = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/update-price-types`;

export async function mutatePriceTypes(body: TMutatePriceTypesBody) {
  const res = await api.post(updatePriceTypesEndpoint, body);
  return res;
}
