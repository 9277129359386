import { useDispatch, useSelector } from 'react-redux';
import { TBookieProfile } from '../types';
import { updateUserEmail } from '../../../../../../../lib/Authentication';
import { AppState, AppThunkDispatch } from '../../../../../../../app/types';
import { updateBookieProfile } from '../actions';

export const useBookieProfile = () => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const { bookieProfile: profile } = useSelector(
    (state: AppState) => state.settings
  );

  const updateProfile = async (
    updatedProfile: TBookieProfile,
    oldprofile: TBookieProfile
  ) => {
    await dispatch(updateBookieProfile(updatedProfile));

    // Handle updating the email change
    if (updatedProfile.email !== oldprofile.email && !!updatedProfile?.email) {
      await updateUserEmail(updatedProfile.email);
    }
  };

  return {
    profile,
    updateProfile,
  } as const;
};
