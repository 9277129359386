import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Image, Text } from '@chakra-ui/react';
import {
  CloseIcon,
  FlexFallbackWrapper,
  FlexWrapper,
} from './DropzonePreview.styles';
import { useDropzonePreview } from './DropzonePreview.hooks';
import { CloseCircle } from 'styled-icons/evaicons-solid';

type DropzonePreviewProps = {
  onFilesSelected: (files: string) => void;
  getName: (fileName: string) => void;
  file: string;
  name: string;
  placeholder: string;
};
const DropzonePreview: React.FC<DropzonePreviewProps> = ({
  onFilesSelected,
  getName,
  name,
  file,
  placeholder,
}) => {
  const { convertToBase64, setFiles, files } = useDropzonePreview();

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.png, .jpg, .jpeg',
    maxSize: 1048576, // 1MB
    onDrop: async (acceptedFiles: File[]) => {
      const filesWithPreview = await Promise.all(
        acceptedFiles.map(async (file) => {
          const preview = URL.createObjectURL(file);
          const name = file.name;
          const base64String = await convertToBase64(file);
          return { ...file, name, preview, base64: base64String };
        })
      );
      setFiles(filesWithPreview);
      onFilesSelected(filesWithPreview[0].base64);
      getName(filesWithPreview[0].name);
    },
  });

  const handleFileSelection = async (selectedFiles: FileList | null) => {
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      const filesWithBase64 = await Promise.all(
        filesArray.map(async (file) => {
          const base64String = await convertToBase64(file);
          const name = file.name;
          return { ...file, preview: base64String, name, base64: base64String };
        })
      );
      onFilesSelected(filesWithBase64[0].base64);
      getName(filesWithBase64[0].name);
      setFiles(filesWithBase64);
    }
  };

  const handleRemove = () => {
    setFiles([]);
    onFilesSelected('');
  };

  const thumbs = files.map((file) => (
    <Box key={file.name}>
      <Image src={file.preview} alt={file.name} sx={{ w: 'full' }} />
    </Box>
  ));

  const filePreview = <Image src={file} sx={{ w: 'full' }} />;

  return (
    <FlexWrapper>
      {!file && files.length === 0 ? (
        <Box {...getRootProps({ className: 'dropzone' })} p={4}>
          <input
            type="file"
            {...getInputProps()}
            height="252px"
            name={name}
            hidden
            onChange={(e) => handleFileSelection(e.target.files ?? null)}
          />
          <Text>Choose a file or drag it here.</Text>
          <Text>Accepts PNG, JPG.</Text>
          <Text>{placeholder}</Text>
        </Box>
      ) : (
        <FlexFallbackWrapper>
          <CloseIcon as={CloseCircle} onClick={handleRemove} />
          {file ? filePreview : thumbs}
        </FlexFallbackWrapper>
      )}
    </FlexWrapper>
  );
};

export default DropzonePreview;
