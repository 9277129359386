import {
  chakra,
  Flex,
  Box,
  Text,
  ChakraComponent,
  FlexProps,
  BoxProps,
  TextProps,
} from '@chakra-ui/react';

export const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    w: '100%',
    flexDirection: 'column',
    alignItems: 'start',
    position: 'relative',
  }),
});

export const BonusBet: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'space-around',
    bg: 'pink.100',
    borderRadius: 'md',
    fontSize: 'sm',
    fontWeight: 'medium',
    textAlign: 'center',
    color: 'red.400',
    cursor: 'default',

    w: '85px',
    py: '1',
    px: '2',
    my: '1',
  }),
});

export const MBLFlag: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    bg: 'purple.100',
    color: 'purple.800',
    borderRadius: 'md',
    fontSize: 'sm',
    textAlign: 'center',
    cursor: 'default',

    py: '.5',
    px: '3',
    ml: '2',
  }),
});

export const MultiWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.200',
    color: 'gray.600',

    w: '450px',
    my: '0',
    mt: '1',
    py: '1',
  }),
});

export const BoldText: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 'bold',
    fontSize: 'md',
    color: 'gray.600',
    textTransform: 'capitalize',
  }),
});

export const MultiHeading: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    color: 'gray.600',
    fontWeight: 'bold',
    fontSize: 'md',

    mb: '1',
  }),
});
