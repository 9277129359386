import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { TabFilterNavLink } from './Styles';

type TabFiltersProps = NavLinkProps & {
  title?: string;
  isActive?: boolean;
};

export const TabFilter: FC<TabFiltersProps> = ({
  to,
  children,
  title,
  isActive,
  onClick,
  style,
}) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <TabFilterNavLink<any>
    to={to}
    title={title}
    onClick={onClick}
    style={style}
    className={isActive && 'active'}
  >
    {children}
  </TabFilterNavLink>
);
