import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddCompetitionBody } from './addCompetition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const addCompetitionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/add-competition`;

// Trade Manager - Add Competition

export const mutateAddCompetition = (body: TAddCompetitionBody) =>
  api
    .post<TMarketCreationResponse>(addCompetitionURL, body)
    .then((res) => res.data);
