import styled from 'styled-components';
import {
  chakra,
  ChakraComponent,
  Text,
  Flex,
  HStack,
  StackProps,
} from '@chakra-ui/react';

export const FlexTitleWrapper = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'flex-start',
    flexDir: 'column',
    ml: '3',
  }),
});

export const SwitchWrapper: ChakraComponent<'div', StackProps> = chakra(
  HStack,
  {
    baseStyle: () => ({
      p: '2',
      minW: '140px',
      h: '50px',
      bg: 'white',
      ml: '3',
      borderRadius: 'md',
    }),
  }
);

export const TextDisclaimer = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'normal',
    lineHeight: '1',
    ml: '1',
  }),
});

export const SportInfoTable = styled.table`
  & > tbody > tr > td {
    border-right: 1px solid ${({ theme }) => theme.global.colors.separatorLine};
    padding: 5px 13px;

    &:last-child {
      border-right: none;
    }
  }
`;

export const PropositionTitle = styled.p`
  flex-direction: row;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
`;

interface FlucPriceProps {
  flucUp?: boolean;
}

export const FlucPriceText = styled.text<FlucPriceProps>`
  padding-left: 5px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ flucUp, theme }) =>
    flucUp ? theme.global.colors.greenText : theme.global.colors.red};
`;

export const MarketTitle = chakra(Text, {
  baseStyle: () => ({
    display: 'flex',
    fontSize: 'md',
    color: 'gray.900',
    textAlign: 'left',
    fontWeight: 'medium',
  }),
});

export const PropositionCount = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    color: 'gray.900',
    bg: 'gray.300',
    borderRadius: 'sm',
    textAlign: 'center',
    fontWeight: 'medium',
    px: '2',
    mr: '3',
  }),
});

export const HeadingMatchTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    textAlign: 'left',
    alignSelf: 'start',
    fontWeight: 'normal',
    color: 'gray.700',
  }),
});
