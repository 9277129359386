import {
  Box,
  BoxProps,
  ChakraComponent,
  ImageProps,
  Image,
  chakra,
} from '@chakra-ui/react';

export const UserBadge: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    boxSize: '7',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'semibold',
    mr: '2',
    outline: '2px solid',
    textTransform: 'uppercase',
  }),
});

export const PlayerIcon: ChakraComponent<'img', ImageProps> = chakra(Image, {
  baseStyle: () => ({
    boxSize: '5',
    p: '1',
  }),
});
