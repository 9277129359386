import React, { FC, useEffect, useState } from 'react';
import { Box } from 'grommet';
import {
  MarginDisplay,
  IncrementButton,
  DecrementButton,
  MarginText,
  PlusMinusText,
} from './Styles';

interface MarginAdjustControlsProps {
  value: number;
  displayModifier?: number;
  disabled?: boolean;
  displayModifierMode?: boolean;
  onChange: (newValue: number) => void;
}

export const MarginAdjustControls: FC<MarginAdjustControlsProps> = ({
  displayModifierMode = false,
  displayModifier = 0,
  value,
  disabled,
  onChange,
}) => {
  const [newValue, setNewValue] = useState(0);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const increment = () => {
    const updatedValue = newValue + 1;
    setNewValue(updatedValue);
    onChange(updatedValue);
  };

  const decrement = () => {
    const updatedValue = newValue - 1;
    setNewValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '120px',
      }}
    >
      <MarginDisplay>
        {displayModifierMode ? (
          <MarginText>
            {displayModifier > 0 && '+'}
            {displayModifier.toString()}
          </MarginText>
        ) : (
          <MarginText>
            {newValue > 0 && '+'}
            {newValue.toString()}
          </MarginText>
        )}
      </MarginDisplay>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
        }}
      >
        <IncrementButton disabled={disabled} hoverIndicator onClick={increment}>
          <PlusMinusText>+</PlusMinusText>
        </IncrementButton>
        <DecrementButton disabled={disabled} hoverIndicator onClick={decrement}>
          <PlusMinusText>-</PlusMinusText>
        </DecrementButton>
      </Box>
    </Box>
  );
};
