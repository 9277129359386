import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, MainContent, ChildContentWrapper } from './Styles';

export default function PublicLayout() {
  return (
    <Container>
      <MainContent>
        <ChildContentWrapper>
          <Outlet />
        </ChildContentWrapper>
      </MainContent>
    </Container>
  );
}
