import { TLimit } from '../../../features/settings/pages/Settings/tabs/Limits/types';
import api from '../../api';

export const exposureLimitsURL = 'bookie/settings/limits/exposure-limit';

export const updateExposureLimit = async (limit: TLimit) =>
  api.put(exposureLimitsURL, limit).then((res) => res.data);

export const queryExposureLimit = async () =>
  api.get<number>(exposureLimitsURL).then((res) => res.data);
