import React, { useState } from 'react';
import { Flex, Text, Icon, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Copy } from '@styled-icons/evaicons-solid/Copy';
import { XCircleFill } from '@styled-icons/bootstrap';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import toast from 'react-hot-toast';
import {
  useQueryAffiliatePromos,
  useQueryAffiliates,
} from '@/api/promoManager/affiliates/affiliates.hooks';
import { centsToDollars } from '@/common/utils';
import dayjs from 'dayjs';
import {
  TAffiliate,
  TPlatformPromo,
} from '@/api/promoManager/affiliates/affiliates.types';
import BetsTablePopover from '@/common/components/BetsTablePopover';
// import { useNavigate } from 'react-router-dom';

// TODO: leaving offset/limit commented out for now as pagination is disabled
export const useAffiliates = () => {
  // const [offset, setOffset] = useState<string | undefined>(undefined);
  const [affiliateName, setAffiliateName] = useState<string | undefined>(
    undefined
  );
  const [
    selectedAffiliateForDeactivation,
    setSelectedAffiliateForDeactivation,
  ] = useState<string | undefined>(undefined);
  const [selectedAffiliateEdit, setSelectedAffiliateEdit] = useState<
    TAffiliate | undefined
  >(undefined);
  const [selectedAffiliateForNewPromo, setSelectedAffiliateForNewPromo] =
    useState<string | undefined>(undefined);
  const { data: affiliatePromoData } = useQueryAffiliatePromos({
    affiliates: true,
    offset: undefined,
    affiliate_name: affiliateName,
  });
  // const limit = 20;
  const { data } = useQueryAffiliates();
  const handleAffiliateEditSelection = (aff: TAffiliate) =>
    setSelectedAffiliateEdit({
      ...aff,
      default_deposit_match: {
        ...aff.default_deposit_match,
        percentage: aff.default_deposit_match?.percentage
          ? aff.default_deposit_match.percentage * 100
          : undefined,
        max_amount: aff.default_deposit_match?.max_amount
          ? aff.default_deposit_match.max_amount / 100
          : undefined,
      },
    });
  // const navigate = useNavigate();

  // Table Columns
  const columns = [
    {
      accessorKey: 'affiliate_name',
      header: <FormattedMessage id="promomanagerpage.affiliates.table.name" />,
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'default_token',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.defaultToken" />
      ),
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'default_deposit_match',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.defaultDepositMatch" />
      ),
      cell: (props: {
        row: {
          original: TAffiliate;
        };
      }) => {
        const { default_deposit_match } = props.row.original;
        const { percentage, max_amount, expiry } = default_deposit_match ?? {};
        if (!default_deposit_match) return null;
        return (
          <Text>
            <span style={{ fontWeight: 'bold' }}>Percentage:</span>{' '}
            {!!percentage && Math.round(percentage * 100)}%{' '}
            <span style={{ fontWeight: 'bold' }}>Limit:</span>{' '}
            {centsToDollars(max_amount)}{' '}
            <span style={{ fontWeight: 'bold' }}>Expiry:</span>{' '}
            {expiry ? dayjs(expiry).format('DD/MM/YYYY') : 'N/A'}
          </Text>
        );
      },
    },
    {
      accessorKey: 'promo_code',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.promoCode" />
      ),
      cell: (props: {
        row: {
          original: TAffiliate;
        };
      }) => {
        const { default_deposit_match } = props.row.original;
        const { promo_code } = default_deposit_match ?? {};
        if (!promo_code) return null;
        return (
          <Flex>
            <Text>{promo_code}</Text>
            <Icon
              paddingLeft="1"
              onClick={async () => {
                await navigator.clipboard.writeText(promo_code);
                toast.success('Copied to clipboard!');
              }}
              boxSize="5"
              as={Copy}
            />
          </Flex>
        );
      },
    },
    {
      accessorKey: 'status',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.status" />
      ),
      cell: (props: {
        row: {
          original: TAffiliate;
        };
      }) => {
        const { status } = props.row.original;
        return (
          <Text
            fontWeight="bold"
            color={status === 'deactivated' ? 'red' : 'green'}
          >
            {status.toUpperCase()}
          </Text>
        );
      },
    },
    {
      accessorKey: 'Add Promo',
      cell: (props: {
        row: {
          original: TAffiliate;
        };
      }) => (
        <Button
          onClick={() =>
            setSelectedAffiliateForNewPromo(props.row.original.affiliate_name)
          }
          // Affiliate with name 'default_mobile_affiliate' should not have any promos. See CORE-5880
          isDisabled={
            props.row.original.status === 'deactivated' ||
            props.row.original.affiliate_name === 'default_mobile_affiliate'
          }
        >
          Add Promo
        </Button>
      ),
    },
    {
      accessorKey: 'Edit',
      cell: (props: {
        row: {
          original: TAffiliate;
        };
      }) => {
        return (
          <BetsTablePopover
            rows={[
              {
                onClick: () =>
                  setSelectedAffiliateForDeactivation(
                    props.row.original.affiliate_name
                  ),
                title: 'Deactivate',
                disabled: props.row.original.status === 'deactivated',
                icon: XCircleFill,
              },
              {
                onClick: () => handleAffiliateEditSelection(props.row.original),
                title: 'Edit',
                disabled: props.row.original.status === 'deactivated',
                icon: Edit,
              },
            ]}
          />
        );
      },
    },
  ];

  const promoColumns = [
    {
      accessorKey: 'promo_type',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.promotion" />
      ),
      cell: (props: { getValue: () => string }) =>
        props
          .getValue()
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (match) => match.toUpperCase()),
    },
    {
      accessorKey: 'affiliate_token',
      header: 'Token',
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'details',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.details" />
      ),
      cell: (props: {
        row: {
          original: TPlatformPromo;
        };
      }) => {
        const { percentage, max_amount, expiry } = props.row.original;
        return (
          <Text>
            <span style={{ fontWeight: 'bold' }}>Percentage:</span>{' '}
            {!!percentage && Math.round(percentage * 100)}%{' '}
            <span style={{ fontWeight: 'bold' }}>Limit:</span>{' '}
            {centsToDollars(max_amount)}{' '}
            <span style={{ fontWeight: 'bold' }}>Expiry:</span>{' '}
            {expiry ? dayjs(expiry).format('DD/MM/YYYY') : 'N/A'}
          </Text>
        );
      },
    },
    {
      accessorKey: 'promo_code',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.promoCode" />
      ),
      cell: (props: { getValue: () => string }) => {
        const promoCode = props.getValue();
        if (!promoCode) return null;
        return (
          <Flex>
            <Text>{promoCode}</Text>
            <Icon
              paddingLeft="1"
              onClick={async () => {
                await navigator.clipboard.writeText(promoCode);
                toast.success('Copied to clipboard!');
              }}
              boxSize="5"
              as={Copy}
            />
          </Flex>
        );
      },
    },
    {
      accessorKey: 'status',
      header: (
        <FormattedMessage id="promomanagerpage.affiliates.table.status" />
      ),
      cell: (props: {
        row: {
          original: TPlatformPromo;
        };
      }) => {
        const { expiry } = props.row.original;
        const isActive = !expiry || dayjs(expiry).isAfter(dayjs());
        const text = isActive ? 'ACTIVE' : 'EXPIRED';
        return (
          <Text fontWeight="bold" color={isActive ? 'green' : 'red'}>
            {text}
          </Text>
        );
      },
    },
  ];

  return {
    columns,
    promoColumns,
    data,
    affiliatePromoData,
    setAffiliateName,
    affiliateName,
    selectedAffiliateForNewPromo,
    setSelectedAffiliateForNewPromo,
    selectedAffiliateForDeactivation,
    setSelectedAffiliateForDeactivation,
    selectedAffiliateEdit,
    setSelectedAffiliateEdit,
  };
};
