import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app';
import reportWebVitals from './reportWebVitals';

/**
 * MSW allows us to intercept requests in the browser
 */
const { NODE_ENV, REACT_APP_MOCKING } = window.BETCLOUD_ENV;
if (NODE_ENV === 'development' && REACT_APP_MOCKING === 'true') {
  const { worker } = require('./__mocks__/browser');
  worker.start();
}

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7f1cdf32c2ba481dae0295eb6539f170@o1282895.ingest.sentry.io/4504239372894208',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
