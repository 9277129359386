import * as yup from 'yup';

import { getStrings } from '../../../../common/utils';

const [{ LoginPage: Strings }] = getStrings();

const loginSchema = yup
  .object({
    email: yup
      .string()
      .email(Strings.Errors.Invalid.Email)
      .required(Strings.Errors.Required.Email),
    password: yup.string().required(Strings.Errors.Required.Password),
  })
  .required();

export type LoginSchema = yup.InferType<typeof loginSchema>;

export default loginSchema;
