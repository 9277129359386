import { chakra, ChakraComponent } from '@chakra-ui/react';
import Select, {
  TIndex,
} from '../../../../../../common/components/FormElements/Select';

export const TypeSelector: ChakraComponent<'select', TIndex> = chakra(Select, {
  baseStyle: () => ({
    cursor: 'pointer',
    width: '150px',
    maxWidth: '150px',
    fontSize: 'sm',
    border: 'none',
    color: 'gray.700',
    '&:hover': {
      bg: 'gray.200',
      color: 'gray.700',
    },
    '&:focus': {
      bg: 'gray.200',
      color: 'gray.700',
    },
  }),
});
