import { rest } from 'msw';
import { TBulkBonusAward } from '../types';
import { mockBulkBonusAwards } from './mockBulkBonusAwards';
import { ENV } from '@/lib/Constants';

const baseURL = ENV.REACT_APP_API_URL;

export const bulkBonusAwardsHandler = [
  rest.get<TBulkBonusAward[]>(
    `${baseURL}/bookie/promos/bonus/bonus-bet`,
    (req, res, ctx) => res(ctx.json(mockBulkBonusAwards))
  ),
];
