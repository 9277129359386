import React, { FC } from 'react';
import { BetsTable } from '@/common/components';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { Box } from '@chakra-ui/react';
import { Column } from '@tanstack/react-table';
import { useColumns, useCustomMarkets } from './services/CustomMarkets.hooks';
import CustomMarketModal from '../../../../components/CustomMarketModal/CustomMarketModal';
import { TMarket } from '@/api/tradeManager/marketCreation/getMarket/getMarket.types';

export const CustomMarkets: FC = () => {
  const columns = useColumns();

  const { data, isLoading, offset, setOffset, isLastFetch } =
    useCustomMarkets();

  return (
    <>
      <CustomMarketModal />

      <Box minH="700px">
        <BetsTable
          columns={columns as unknown as Column<TMarket>[]}
          data={(data as TMarket[]) ?? []}
          loading={isLoading}
        />
      </Box>
      <Pagination
        nextDisabled={isLastFetch}
        offset={offset}
        onPageChange={(newOffset) => setOffset(newOffset)}
      />
    </>
  );
};
