import { colors } from './foundations/colors';
import { fontSizes } from './foundations/fontSizes';
import { images } from './foundations/images';
import { lineHeights } from './foundations/lineHeight';

/**
 * Base theme for every application.
 * It's best to handle each applications theme within the application,
 * however, if there is a universal change we want, it can be added here.
 */
export default {
  themeName: 'base',
  lineHeights,
  colors,
  fontSizes,
  images,
};
