import React from 'react';
import { TRealMoneyAndBonusBetsItem } from '../utils/utils';
import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  Icon,
  Progress,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { PercentageBadge } from './PercentageBadge';
import { GiftCard } from '@styled-icons/fluentui-system-regular/GiftCard';
import { AutoFixHigh } from '@styled-icons/material/AutoFixHigh';

interface IBonusTransactionStatsProps {
  transactions?: TRealMoneyAndBonusBetsItem[];
  isLoading: boolean;
}

export function BonusTransactionStats({
  isLoading,
  transactions,
}: IBonusTransactionStatsProps) {
  const automatedList = transactions
    ?.filter((trans) => trans.isBonusData)
    .filter(
      (bonus) =>
        bonus.title === 'Bet Boost' ||
        bonus.title === 'Betslip Bonus' ||
        bonus.title === 'Auto Deposit Match'
    );

  const manualBonusData = transactions
    ?.filter((trans) => trans.isBonusData)
    ?.filter(
      (trans) =>
        trans.title === 'Deposit Match' ||
        trans.title === 'Money Back' ||
        trans.title === 'Other Bonus'
    );

  const allBonusTurnover = transactions?.find(
    (trans) => trans.title === 'All Bonus Turnover'
  );

  const autoBonusTurnover = transactions?.find(
    (trans) => trans.title === 'Auto Bonus Turnover'
  );

  const manualBonusTurnover = transactions?.find(
    (trans) => trans.title === 'Ad Hoc (Manual) Bonus Turnover'
  );

  if (isLoading)
    return (
      <Flex gap="3">
        {Array.from({ length: 3 }, (_, i) => (
          <Flex
            key={i}
            sx={{
              flex: '1 0 30%',
              height: '300px',
              w: 'fit-content',
              borderRadius: 'sm',
              boxShadow:
                '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            <Skeleton
              height="full"
              w="full"
              startColor="#0c264c"
              endColor="#01060d"
            />
          </Flex>
        ))}
      </Flex>
    );

  return (
    <Flex gap="3">
      {isLoading ? (
        <Flex
          sx={{
            minWidth: '450px',
            h: '270px',
            borderRadius: 'sm',
            boxShadow:
              '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Skeleton
            height="full"
            w="full"
            startColor="#0c264c"
            endColor="#01060d"
          />
        </Flex>
      ) : (
        <Box
          bg="transparent"
          color="white"
          p="3"
          py="4"
          sx={{
            minWidth: '450px',
            boxShadow:
              '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
          }}
        >
          <Flex pos="relative">
            <Box>
              <Flex gap="3">
                <Text
                  sx={{
                    fontWeight: '500',
                    fontSize: '16px',
                    mb: '1.5',
                    color: '#ccc',
                  }}
                >
                  Promos
                </Text>
                <PercentageBadge
                  value={allBonusTurnover?.percentageValue ?? 0}
                  sx={{ h: 'fit-content' }}
                />
              </Flex>

              {isLoading ? (
                <Skeleton h="25px" mb="4" w="full" />
              ) : (
                <Text mb="4" fontSize="25px" color="#ccc">
                  {allBonusTurnover?.value}
                </Text>
              )}
            </Box>
          </Flex>

          <Flex justifyContent="space-between">
            <Box>
              <Flex my="2" alignItems="end">
                <Icon
                  as={AutoFixHigh}
                  sx={{
                    color: '#28c76f',
                    bg: '#324b50',
                    p: '1',
                    boxSize: '8',
                    borderRadius: 'md',
                    mr: '2',
                  }}
                />
                <Text fontSize="16px">Auto </Text>
              </Flex>
              <Text>{autoBonusTurnover?.value}</Text>
              <PercentageBadge
                value={autoBonusTurnover?.percentageValue ?? 0}
              />
            </Box>
            <Box position="relative" padding="10">
              <Divider
                colorScheme="blackAlpha"
                orientation="vertical"
                pos="absolute"
                h="100%"
                top="0"
                borderColor="#4261ee"
              />
              <AbsoluteCenter
                bg="origin.500"
                borderRadius="full"
                color="white"
                p="1"
                textAlign="center"
                boxSize="20px"
                fontSize="10px"
                lineHeight="1"
              >
                vs
              </AbsoluteCenter>
            </Box>
            <Box>
              <Flex
                my="2"
                textAlign="right"
                alignItems="end"
                justifyContent="end"
              >
                <Text fontSize="16px">Ad Hoc</Text>
                <Icon
                  as={GiftCard}
                  sx={{
                    color: '#00bad1',
                    bg: '#30495f',
                    p: '1',
                    boxSize: '8',
                    borderRadius: 'md',
                    ml: '2',
                  }}
                />
              </Flex>
              <Text textAlign="right">{manualBonusTurnover?.value} </Text>
              <PercentageBadge
                sx={{ ml: 'auto', mr: '0' }}
                value={manualBonusTurnover?.percentageValue ?? 0}
              />
            </Box>
          </Flex>
          <Progress
            isAnimated
            bg="#d90e0e"
            mt="8"
            borderRadius="sm"
            value={allBonusTurnover?.percentageValue ?? 0}
            sx={{ '& > div': { bg: '#28c76f' } }}
          />
        </Box>
      )}
      <Flex
        color="white"
        flexDir="column"
        gap="5"
        sx={{
          p: '3',
          flex: '1',
          borderRadius: 'sm',
          boxShadow:
            '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
        }}
      >
        <Text
          sx={{
            fontWeight: '500',
            fontSize: '16px',
            mb: '1.5',
            color: '#ccc',
          }}
        >
          Automated
        </Text>

        {(automatedList ?? []).map((stat) => (
          <Flex key={stat.title} w="full" justifyContent="space-between">
            <Flex>
              <Icon
                as={stat.icon}
                sx={{
                  color: stat.colours?.[0] ?? '#7367f0',
                  bg: stat.colours?.[1] ?? '#3a3c64',
                  p: '1',
                  boxSize: '10',
                  borderRadius: 'md',
                  mr: '4',
                }}
              />
              <Box>
                <Text>
                  {stat.title}{' '}
                  {`${stat?.count !== 0 ? `(${stat?.count})` : ''}`}
                </Text>
                <Text>{stat.value}</Text>
              </Box>
            </Flex>

            {stat.percentageValue !== 0 && (
              <PercentageBadge
                value={stat.percentageValue}
                sx={{ ml: '2', h: 'fit-content' }}
              />
            )}
          </Flex>
        ))}
      </Flex>
      <Flex
        color="white"
        flexDir="column"
        gap="5"
        sx={{
          p: '3',
          flex: '1',
          borderRadius: 'sm',
          boxShadow:
            '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
        }}
      >
        <Text
          sx={{
            fontWeight: '500',
            fontSize: '16px',
            mb: '1.5',
            color: '#ccc',
          }}
        >
          Ad Hoc
        </Text>

        {(manualBonusData ?? []).map((stat) => (
          <Flex key={stat.title} w="full" justifyContent="space-between">
            <Flex>
              <Icon
                sx={{
                  color: stat.colours?.[0] ?? '#7367f0',
                  bg: stat.colours?.[1] ?? '#3a3c64',
                  p: '1',
                  boxSize: '10',
                  borderRadius: 'md',
                  mr: '4',
                }}
                as={stat.icon}
              />
              <Box>
                <Text>
                  {stat.title}
                  {`${stat?.count !== 0 ? ` (${stat?.count})` : ''}`}
                </Text>
                <Text>{stat.value}</Text>
              </Box>
            </Flex>

            {stat.percentageValue !== 0 && (
              <PercentageBadge
                value={stat.percentageValue}
                sx={{ ml: '2', h: 'fit-content' }}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
