import { Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { Cross } from '@styled-icons/entypo';
import { Tick } from '@styled-icons/typicons';
import { Offer } from '@styled-icons/boxicons-solid';
import { getStrings } from '../../../../../../common/utils';
import { useModals } from '../Services/BetApprovals.hooks';
import { ActionButton } from '../Services/BetApprovals.styles';
import { TRequestBet } from '../Services/BetApprovals.types';
import {
  CommonResponse,
  normalizeBetApproval,
} from '../Services/BetApprovals.utils';

type BetApprovalActionsWrapperProps = {
  bet: TRequestBet;
};

export const BetApprovalActionsWrapper = ({
  bet,
}: BetApprovalActionsWrapperProps) => {
  const { showCounterBetModal, showRejectBetModal, approveRequest, acceptMBL } =
    useModals();

  return (
    <BetApprovalActions
      bet={normalizeBetApproval(bet)}
      showCounterBetModal={showCounterBetModal}
      showRejectBetModal={showRejectBetModal}
      approveRequest={approveRequest}
      acceptMBL={acceptMBL}
    />
  );
};

// ---

type BetApprovalActionsProps = {
  disabled?: boolean;
  bet: CommonResponse;
  showCounterBetModal: (bet: CommonResponse) => void;
  showRejectBetModal: (bet: CommonResponse) => void;
  approveRequest: (bet: CommonResponse) => Promise<void>;
  acceptMBL: (bet: CommonResponse) => Promise<void>;
};

export const BetApprovalActions = ({
  disabled = false,
  bet,
  showCounterBetModal,
  showRejectBetModal,
  approveRequest,
  acceptMBL,
}: BetApprovalActionsProps) => {
  const [
    {
      BetApprovalsPage: { Approvals },
    },
  ] = getStrings();

  const isPlacement = bet?.requestType?.toLowerCase() === 'placement';

  const isNegativePayout = !isPlacement && !bet?.requestedPayout;

  return (
    <Flex maxW="120px" direction="column" ml="auto" mr="2">
      <Flex>
        {(isNegativePayout || isPlacement) && (
          <ActionButton
            action="reject"
            onClick={() => showRejectBetModal(bet)}
            aria-label={Approvals.Reject}
            icon={<Icon boxSize="6" as={Cross} />}
            isDisabled={disabled}
          />
        )}
        {!isNegativePayout && (
          <ActionButton
            action="approve"
            onClick={() => approveRequest(bet)}
            aria-label={Approvals.Approve}
            icon={<Icon boxSize="6" as={Tick} />}
            isDisabled={disabled}
          />
        )}
      </Flex>
      <Flex>
        {(bet.mblStake ?? 0) > 0 && (
          <ActionButton
            action="mbl"
            onClick={() => acceptMBL(bet)}
            aria-label={Approvals.Mbl}
            icon={<Text>{Approvals.Mbl}</Text>}
            isDisabled={disabled}
          />
        )}
        <ActionButton
          action="counter"
          onClick={() => showCounterBetModal(bet)}
          aria-label={Approvals.Counter}
          icon={<Icon boxSize="6" as={Offer} />}
          isDisabled={disabled}
        />
      </Flex>
    </Flex>
  );
};
