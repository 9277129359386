import { keys } from '@/api/api.keys';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryGetSportMarket } from './getSportMarket';
import { TGetSportMarketParams, TSportMarket } from './getSportMarket.types';

// Trade Manager - Get Sport Market

type TQueryGetSportMarketHook = {
  params: TGetSportMarketParams;
  options?: Omit<UseQueryOptions<TSportMarket>, 'queryKey' | 'queryFn'>;
};

export const useQueryGetSportMarket = ({
  params,
  options,
}: TQueryGetSportMarketHook) =>
  useQuery(
    [keys.getSportMarket, params],
    () => queryGetSportMarket(params),
    options
  );
