import Axios, { AxiosInstance, AxiosPromise, Cancel } from 'axios';
import { axios as _axiosInstance } from '../app/components/Providers/Axios';
import {
  ApiRequestConfig,
  WithAbortFn,
  ApiExecutor,
  ApiExecutorArgs,
  ApiError,
} from './api.types';

// Create axios instance with default params
const axiosInstance = _axiosInstance;
axiosInstance.defaults.withCredentials = true;

const didAbort = (error: unknown): error is Cancel & { aborted: boolean } =>
  Axios.isCancel(error);

export const isApiError = (error: unknown): error is ApiError =>
  Axios.isAxiosError(error);

const withAbort = <T>(fn: WithAbortFn) => {
  const executor: ApiExecutor<T> = async (...args: ApiExecutorArgs) => {
    const originalConfig = args[args.length - 1] as ApiRequestConfig;
    // Extract abort property from the config
    const { abort, ...config } = originalConfig;

    /**
     * Create cancel token and abort method only if abort
     * function was passed
     */
    if (typeof abort === 'function') {
      const { cancel, token } = Axios.CancelToken.source();
      config.cancelToken = token;
      abort(cancel);
    }

    try {
      if (args.length > 2) {
        const [url, body] = args;
        return await fn<T>(url, body, config);
      }
      const [url] = args;
      return await fn<T>(url, config);
    } catch (error) {
      // Add "aborted" property to the error if the request was cancelled
      if (didAbort(error)) {
        error.aborted = true;
      }

      throw error;
    }
  };

  return executor;
};

const withLogger = async <T>(promise: AxiosPromise<T>) =>
  promise.catch((error: ApiError) => {
    // Log error only if REACT_APP_DEBUG_API env is set to true
    if (!window.BETCLOUD_ENV.REACT_APP_DEBUG_API) throw error;
    if (error.response) {
      /**
       * The request was made and the server responded with a status code
       * that falls out of the range of 2xx.
       */
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      /**
       * The request was made but no response was received
       * `error.request` is an instance of XMLHttpRequest
       * in the browser and an instance of
       * http.ClientRequest in node.js
       */
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

    console.log(error.config);
    throw error;
  });

// Main api function
/* eslint-disable @typescript-eslint/unbound-method */
const api = (axios: AxiosInstance) => ({
  get: <T>(url: string, config: ApiRequestConfig = {}) =>
    withLogger<T>(withAbort<T>(axios.get)(url, config)),
  delete: <T>(url: string, config: ApiRequestConfig = {}) =>
    withLogger<T>(withAbort<T>(axios.delete)(url, config)),
  post: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) =>
    withLogger<T>(withAbort<T>(axios.post)(url, body, config)),
  patch: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) =>
    withLogger<T>(withAbort<T>(axios.patch)(url, body, config)),
  put: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) =>
    withLogger<T>(withAbort<T>(axios.put)(url, body, config)),
});
/* eslint-enable @typescript-eslint/unbound-method */

export default api(axiosInstance);
