import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TSettingsState,
  TBookieActivityLog,
  TActivityLogBookieForPlatform,
  TPdfUploadModal,
} from './types';
import {
  getBookieActivityLogs,
  getPrivacyPolicy,
  getTermsConditions,
} from './actions';

import { BookieProfileDefault } from './tabs/YourProfile/Services/Config.BookieProfile';
import { TBookieProfile } from './tabs/YourProfile/types';

import { BankInformationSchema } from './validators/bankInformation';

const initialState: TSettingsState = {
  bookieActivityLogs: [],
  bookieActivityLogsLoading: false,
  bookiesForPlatform: [],
  termsConditions: '',
  termsConditionsLoading: false,
  privacyPolicy: '',
  privacyPolicyLoading: false,
  bookieProfile: BookieProfileDefault,
  bookieBankInformation: null,
  pdfUploadModal: { isOpen: false },
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBookieActivityLogs: (
      state: TSettingsState,
      action: PayloadAction<TBookieActivityLog[]>
    ) => ({ ...state, bookieActivityLogs: action.payload }),
    setBookiesForPlatform: (
      state: TSettingsState,
      action: PayloadAction<TActivityLogBookieForPlatform[]>
    ) => ({ ...state, bookiesForPlatform: action.payload }),
    setTermsConditions: (
      state: TSettingsState,
      action: PayloadAction<string>
    ) => ({ ...state, termsConditions: action.payload }),
    setPrivacyPolicy: (
      state: TSettingsState,
      action: PayloadAction<string>
    ) => ({ ...state, privacyPolicy: action.payload }),
    setPdfUploadModal: (
      state: TSettingsState,
      action: PayloadAction<TPdfUploadModal>
    ) => ({ ...state, pdfUploadModal: action.payload }),

    setBookieProfile: (
      state: TSettingsState,
      action: PayloadAction<TBookieProfile>
    ) => ({ ...state, bookieProfile: action.payload }),

    setBookieBankInformation: (
      state: TSettingsState,
      action: PayloadAction<BankInformationSchema>
    ) => ({ ...state, bookieBankInformation: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookieActivityLogs.pending, (state: TSettingsState) => ({
        ...state,
        bookieActivityLogsLoading: true,
      }))
      .addCase(getBookieActivityLogs.fulfilled, (state: TSettingsState) => ({
        ...state,
        bookieActivityLogsLoading: false,
      }))
      .addCase(getPrivacyPolicy.pending, (state: TSettingsState) => ({
        ...state,
        privacyPolicyLoading: true,
      }))
      .addCase(getPrivacyPolicy.fulfilled, (state: TSettingsState) => ({
        ...state,
        privacyPolicyLoading: false,
      }))
      .addCase(getTermsConditions.pending, (state: TSettingsState) => ({
        ...state,
        termsConditionsLoading: true,
      }))
      .addCase(getTermsConditions.fulfilled, (state: TSettingsState) => ({
        ...state,
        termsConditionsLoading: false,
      }));
  },
});

// Slice actions
export const {
  setBookieActivityLogs,
  setBookiesForPlatform,
  setPrivacyPolicy,
  setTermsConditions,
  setBookieProfile,
  setBookieBankInformation,
  setPdfUploadModal,
} = settings.actions;

// Slide reducer
export default settings.reducer;
