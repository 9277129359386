import { ENV } from '@/lib/Constants';
import { rest } from 'msw';

export const exposureLimitsHandler = [
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/global-exposure-limits`,
    async (req, res, ctx) =>
      res(
        ctx.json({
          race_win_market_exposure_manual_approval_limit: 500000,
          race_place_market_exposure_manual_approval_limit: 500000,
          sport_win_market_exposure_manual_approval_limit: 500000,
        })
      )
  ),
  rest.post(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/global-exposure-limits`,
    async (req, res, ctx) => res(ctx.json(true))
  ),
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/global-bet-limit`,
    async (req, res, ctx) => res(ctx.json(600000))
  ),
  rest.post(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/global-bet-limit`,
    async (req, res, ctx) => res(ctx.status(200))
  ),
];
