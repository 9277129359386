import { EPlayerType } from '@/lib/DBModels';
import HighRoller from '@/assets/icons/player/new/HighRoller.png';
import Player from '@/assets/icons/player/new/Player.png';
import Recreational from '@/assets/icons/player/new/Recreational.png';
import Newbie from '@/assets/icons/player/new/Newbie.png';
import Sharp from '@/assets/icons/player/new/Sharp.png';
import PromoArber from '@/assets/icons/player/new/PromoArber.png';
import ApprovalOnly from '@/assets/icons/player/new/ApprovalOnly.png';
import BlackList from '@/assets/icons/player/new/Blacklist.png';
import { TPunterCategory } from '../api/api';

export const getPlayerIcon = (playerType: Api['PunterCategories']) => {
  switch (playerType) {
    case EPlayerType.HighRoller:
      return HighRoller;
    case EPlayerType.Player:
      return Player;
    case EPlayerType.Recreational:
      return Recreational;
    case EPlayerType.Blacklist:
      return BlackList;
    case EPlayerType.Newbie:
      return Newbie;
    case EPlayerType.Sharp:
      return Sharp;
    case EPlayerType.PromoArber:
      return PromoArber;
    case EPlayerType.ApprovalOnly:
      return ApprovalOnly;
    default:
      return Newbie;
  }
};

export function generateDonutChartData(stats: TPunterCategory[]) {
  const donutChartData = {
    labels: [
      'Approval Only',
      'Blacklist',
      'High Roller',
      'Newbie',
      'Player',
      'Promo Arber',
      'Recreational',
      'Sharp',
    ],
  };

  const chartData = {
    series: donutChartData.labels.map((label) => {
      const matchingStat = stats?.find((stat) => stat.category === label);
      return matchingStat ? matchingStat.stats.value : 0;
    }),
    labels: donutChartData.labels,
  };

  const donutOptions = {
    labels: donutChartData.labels,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'left',
    },
    stroke: {
      show: false,
      width: 0,
    },
    colors: [
      '#FA9B2C',
      '#EB2360',
      '#7FE231',
      '#626262',
      '#FDCE28',
      '#BD55FC',
      '#36C4FF',
      '#FF4A81',
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 0,
              formatter: (val: string) => val,
            },
            value: {
              show: true,
              offsetY: 10,
              color: '#FFF',
              formatter: (val: number) => val,
            },
            total: {
              show: true,
              label: 'Total',
              color: '#FFF',
              formatter: (w) => {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              },
            },
          },
        },
      },
    },
  };

  return { chartData, donutOptions };
}

export const mockTransactionsData = {
  deposit_count: {
    value: 1256,
    percentage_change: -30.07,
  },
  deposit_amount: {
    value: 61521430,
    percentage_change: -55.7,
  },
  average_deposit_amount: {
    value: 61521430,
    percentage_change: -55.7,
  },
  first_deposit_count: {
    value: 3498,
    percentage_change: 0.98,
  },
  first_deposit_amount: {
    value: 37263617,
    percentage_change: 17.39,
  },
  withdrawal_count: {
    value: 48982,
    percentage_change: -36.65,
  },
  withdrawal_amount: {
    value: 48982,
    percentage_change: -36.65,
  },
  total_balance: {
    value: 48982,
    percentage_change: -36.65,
  },
};
