import { Text, useCheckbox, useCheckboxGroup } from '@chakra-ui/react';
import React from 'react';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { BCLabel, FlexWrapper, IconCheckbox } from './GroupCheckbox.styles';

type TGroupCheckbox = {
  onChange: () => void;
  value: string;
  name: string;
  isChecked: boolean;
};
type CustomCheckboxProps = {
  isChecked: boolean;
  // Other expected props...
};

export default function GroupCheckbox({
  value,
  onChange,
  name,
  isChecked,
}: TGroupCheckbox) {
  const CustomCheckbox = (props: CustomCheckboxProps) => {
    const { getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
      useCheckbox(props);

    return (
      <BCLabel
        sx={{
          bg: isChecked ? 'origin.500' : 'transparent',
          boxShadow: isChecked && '2xl',
        }}
        {...htmlProps}
        onChange={onChange}
      >
        <input name={name} value={value} checked {...getInputProps()} />
        <FlexWrapper {...getCheckboxProps()} onChange={onChange}>
          <IconCheckbox
            as={CheckCircleFill}
            sx={{
              transform: isChecked ? 'scale(1)' : 'scale(0)',
              boxShadow: isChecked && '2xl',
              opacity: isChecked ? 1 : 0,
            }}
          />
        </FlexWrapper>
        <Text color={isChecked ? 'white' : 'origin.500'} {...getLabelProps()}>
          {value}
        </Text>
      </BCLabel>
    );
  };

  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: [''],
  });

  return (
    <CustomCheckbox isChecked={isChecked} {...getCheckboxProps({ value })} />
  );
}
