import styled from 'styled-components';
import { MoreVertical } from 'styled-icons/evaicons-solid';
import { Card, SingleStatBox } from '../../../../common/components';
import { chakra, Text } from '@chakra-ui/react';

export const FullWidthCard = styled(Card)`
  width: 100%;
  position: relative;
`;

export const RaceDetailsHeading = styled.h4`
  color: ${({ theme }) => theme.global.colors.primary};
  font-size: 19px;
  padding: 0;
  margin: 0;
  margin-bottom: -20px;
`;

export const StyledStatBox = styled(SingleStatBox)`
  background: white;
  min-width: 0;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #e9e9e9e6;

  div:first-child {
    color: #4361ee;
  }
`;

export const SmallStatBox = styled(SingleStatBox)`
  background: white;
  min-width: 0;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #e9e9e9e6;

  div:first-child {
    color: #4361ee;
  }
`;

export const RaceDetailsStatBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 10px;
`;

export const IconMoreVertical = styled(MoreVertical)`
  width: 20px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;
  text-align: right;
`;

export const HeadingRaceTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    textAlign: 'left',
    alignSelf: 'start',
    fontWeight: 'normal',
    color: 'gray.700',
  }),
});
