import React, { FC } from 'react';
import { centsToDollars } from '../../utils';
import { FinalPriceText } from './Statbox.styles';
import {
  SingleStatBoxContainer,
  SingleStatLabel,
  SingleStatValue,
} from './Styles';
import { SingleStatBoxProps } from './types';

export const SingleStatBox: FC<SingleStatBoxProps> = ({
  label,
  value,
  result,
  ...rest
}) => (
  <SingleStatBoxContainer {...rest}>
    <SingleStatLabel>{label}</SingleStatLabel>
    <SingleStatValue>{value}</SingleStatValue>
    {!!result && result !== 0 && (
      <FinalPriceText color={result > 0 ? 'green.500' : 'red.500'}>
        {centsToDollars(result)}
      </FinalPriceText>
    )}
  </SingleStatBoxContainer>
);
