import React, { useState } from 'react';
import { TBetLegs, TBetsData } from '@/api/punters/punters.types';
import { BetsTable } from '@/common/components';
import { useRolloverColumns } from './Rollovers.config';
import { Column } from '@tanstack/react-table';

type TRolloversContainer = {
  bets: TBetsData[];
  isLoading: boolean;
};
export default function RolloversContainer({
  bets,
  isLoading,
}: TRolloversContainer) {
  const { columns, subcolumns } = useRolloverColumns();
  const [childrenData, setChildrenData] = useState<TBetLegs[]>([]);

  return (
    <BetsTable
      hasSubTable
      columns={columns as unknown as Column<TBetsData>[]}
      data={(bets ?? []) as TBetsData[]}
      loading={isLoading}
      onClickExpand={(row: TBetsData) =>
        setChildrenData(row.bet_legs as React.SetStateAction<TBetLegs[]>)
      }
    >
      <BetsTable
        columns={subcolumns as unknown as Column<TBetLegs>[]}
        data={childrenData as unknown as TBetLegs[]}
        loading={isLoading}
      />
    </BetsTable>
  );
}
