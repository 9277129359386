import api from '../../api';
import {
  TTransactionCsv,
  TDownloadTransactionsParams,
} from './downloadTransactions.types';

const downloadTransactionsURL = '/bookie/regulator/download-transactions';

// Regulator - Financial Transaction History - Pdf download

export const queryDownloadTransactions = (
  params: TDownloadTransactionsParams
) =>
  api
    .get<TTransactionCsv>(downloadTransactionsURL, { params })
    .then((res) => res.data);
