import {
  TBetApprovalsResponse,
  TBetRequestStatusInput,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import api from '../api';
import { TPendingApprovalsParams, TSettleBetBody } from './betApprovals.types';

// Bet Approvals - Pending
export const pendingApprovalsURL = '/bookie/bet-approvals';
export const queryPendingApprovals = (params: TPendingApprovalsParams) =>
  api
    .get<TBetApprovalsResponse>(pendingApprovalsURL, { params })
    .then((res) => res.data);

//  Bet Approvals - action - ['approve', 'reject', 'counter offer']
export const approvalsStatusURL = '/bookie/bet-approvals/status';
export const mutateApprovalStatus = (body: TBetRequestStatusInput) =>
  api.post(approvalsStatusURL, body).then((res) => res.data);

export const settleBet = '/bookie/bet-approvals/settle-bet';
export const mutateSettleBetProps = (betProps: TSettleBetBody) =>
  api.post(settleBet, betProps).then((res) => res.data);
