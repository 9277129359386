import { chakra, Box, Flex } from '@chakra-ui/react';
import { Motion } from '../../common/components';

const isDashboard = window.location.pathname.includes('dashboard');

type TContentContainerStyle = {
  isDashboard?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & any;

export const ContentContainer = chakra<TContentContainerStyle>(Flex, {
  shouldForwardProp: (prop) => !['isDashboard'].includes(prop),
  baseStyle: ({ isDashboard }: TContentContainerStyle) => ({
    h: '100%',
    flexDir: 'column',
    overflow: 'auto',
    bg:
      isDashboard &&
      'linear-gradient(335deg, rgb(0 45 88) 39%, rgb(0 28 55) 100%)',
  }),
});

export const ContentWrapper = chakra(Box, {
  baseStyle: () => ({
    w: '100%',
    height: '100%',
  }),
});

export const Content = chakra<TContentContainerStyle>(Box, {
  shouldForwardProp: (prop) => !['isDashboard'].includes(prop),
  baseStyle: ({ isDashboard }: TContentContainerStyle) => ({
    overflow: 'auto',
    minHeight: '100vh',
    height: '100%',
    mt: '-1',
    p: '2',
    m: '2',
    border: !isDashboard && '1px',
    borderColor: 'gray.100',
    borderRadius: 'sm',
  }),
});

export const SidebarWrapper = chakra(Motion, {
  baseStyle: () => ({
    position: 'sticky',
    top: 0,
  }),
});

export const HeaderWrapper = chakra(Motion, {
  baseStyle: () => ({
    position: !isDashboard && 'sticky',
    top: '0',
    w: '100%',
    zIndex: 'dropdown',
    bg: isDashboard && 'transparent',
  }),
});
