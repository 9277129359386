/* eslint-disable no-nested-ternary */
import React, { FC, useState } from 'react';
import { Box, ColumnConfig, Text } from 'grommet';
import { Flex, Text as TextChakra } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  centsToDollars,
  getStrings,
} from '../../../../../../../../common/utils';

import {
  LoadingSpinner,
  StyledDataTable,
  DataTableMultiSelectBar,
} from '../../../../../../../../common/components';

import {
  ActionsContainer,
  DeclineButton,
  MultiDeclineButton,
  ApproveButton,
  MultiApproveButton,
  LeftSideMultiActions,
} from '../../Styles';
import GrommetTheme from '../../../../../../../../styles/GrommetTheme';

import { TPunterWithdrawalRequestsDTO } from '../../../../../../../../lib/DTO';
import { TPunterWithdrawalStatus } from '../../../../types';
import { formatDateTime24 } from '../../../../../../../../lib/Time';
import { useWithdrawalRequest } from '../../services/Withdrawals.hooks';
import IconSvg from '@/components/IconSvg/IconSvg';
import {
  BoxFlagWrapper,
  BoxPunterFlag,
  PlayerIcon,
  UserBadge,
} from './PendingApprovals.styles';
import { usePunterCategory } from '@/features/punters/pages/Punters/services/Punters.utils';
import { EPlayerType } from '@/lib/DBModels';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';

export const PendingApprovals: FC = () => {
  const [
    {
      BanksPage: {
        Withdrawals: { PendingApproval: Strings },
      },
    },
  ] = getStrings();

  const [history, setHistory] = useState<string[]>([]);
  const {
    handleActionSingle,
    handleActionMulti,
    isLastFetch,
    isLoading,
    selected,
    setSelected,
    data,
    paging,
    setOffset,
    offset,
  } = useWithdrawalRequest(TPunterWithdrawalStatus.Pending);

  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory();

  const dataCols: ColumnConfig<TPunterWithdrawalRequestsDTO>[] = [
    {
      property: 'withdrawal_id',
      primary: true,
      render: () => null,
    },
    {
      property: 'punter_name',
      header: <Text textAlign="start">{Strings.TableHeadings.PunterName}</Text>,
      render: (datum) => (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/punter/${datum.punter_id}`}
        >
          <Text color={GrommetTheme?.global?.colors?.midBlue} textAlign="start">
            {datum?.punter_name ?? ''}
          </Text>
        </Link>
      ),
    },
    {
      property: 'punter_category',
      header: (
        <FormattedMessage id="bankspage.withdrawals.pendingApproval.tableHeadings.punterCategory" />
      ),
      render: (datum) => {
        const punter_category = datum.category ?? EPlayerType.Newbie;

        return (
          <Flex alignItems="center">
            <UserBadge bg={getPunterCategoryColor(punter_category)}>
              <PlayerIcon src={getPlayerIcon(punter_category)} />
            </UserBadge>
            <Text>{punter_category}</Text>
          </Flex>
        );
      },
    },
    {
      property: 'punter_flags',
      header: 'Punter Flags',
      render: (datum) => {
        const flags = datum.punter_flags;

        if (!flags) return <></>;
        const MAX_TO_SHOW = 5;
        const visibleFlags = flags?.slice(0, MAX_TO_SHOW);

        return (
          <BoxFlagWrapper>
            {visibleFlags?.map((flag) => {
              if (!flag?.label) return;
              return (
                <BoxPunterFlag key={flag?.label}>
                  {flag?.label?.replaceAll('_', ' ')}
                </BoxPunterFlag>
              );
            })}
          </BoxFlagWrapper>
        );
      },
    },
    {
      property: 'custom_flags',
      header: 'Custom Flags',
      render: (datum) => {
        const flags = datum.custom_flags;

        const MAX_TO_SHOW = 5;
        const visibleFlags = flags?.slice(0, MAX_TO_SHOW);

        return (
          <BoxFlagWrapper>
            {visibleFlags?.map((flag) => {
              if (!flag?.flag) return;
              return (
                <BoxPunterFlag bg="red.700" key={flag?.flag}>
                  {flag?.flag?.replaceAll('_', ' ')}
                </BoxPunterFlag>
              );
            })}
          </BoxFlagWrapper>
        );
      },
    },
    {
      property: 'created_at',
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          {Strings.TableHeadings.Requested}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{formatDateTime24(datum?.created_at)}</Text>
      ),
    },
    {
      property: 'amount',
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          {Strings.TableHeadings.WithdrawalAmount}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{centsToDollars(datum?.amount)}</Text>
      ),
    },
    {
      property: '',
      sortable: false,
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          {Strings.TableHeadings.Result}
        </Text>
      ),
      render: (datum) => (
        <ActionsContainer>
          <DeclineButton
            onClick={() =>
              handleActionSingle(
                datum?.withdrawal_id,
                TPunterWithdrawalStatus.Rejected
              )
            }
          >
            <IconSvg name="close-white" sx={{ boxSize: '2.5' }} />
          </DeclineButton>
          <ApproveButton
            onClick={() =>
              handleActionSingle(
                datum?.withdrawal_id,
                TPunterWithdrawalStatus.Approved
              )
            }
          >
            <IconSvg name="checkmark" sx={{ boxSize: '2.5' }} />
          </ApproveButton>
        </ActionsContainer>
      ),
    },
  ];

  const tableData = (data ?? []) as TPunterWithdrawalRequestsDTO[];

  return (
    <>
      <Box
        align="center"
        justify="start"
        direction="row"
        pad={{ horizontal: '28px' }}
      >
        <Box width="full">
          {isLoading ? <LoadingSpinner /> : null}
          {data?.length ? (
            <StyledDataTable<TPunterWithdrawalRequestsDTO>
              columns={dataCols}
              data={tableData}
              fill
              style={{ width: '100%' }}
              pad={{ vertical: '16px' }}
              select={selected}
              onSelect={(selectedArray) => setSelected(selectedArray)}
              className="no-border-vertical-selectable"
            />
          ) : (
            <TextChakra my="5">
              <FormattedMessage
                id={'bankspage.withdrawals.pendingapproval.nomorerecords'}
              />
            </TextChakra>
          )}
          <PaginationV3
            nextDisabled={isLastFetch}
            offset={offset}
            onPageChange={(newOffset) => setOffset(newOffset)}
            useHistory
            history={history}
            setHistory={setHistory}
            nextOffsetId={!isLastFetch ? paging?.next_offset : undefined}
          />
        </Box>
      </Box>
      <DataTableMultiSelectBar selected={selected} setSelected={setSelected}>
        <LeftSideMultiActions>
          <MultiApproveButton
            onClick={() => handleActionMulti(TPunterWithdrawalStatus.Approved)}
          >
            <IconSvg name="checkmark" sx={{ mt: '-4', boxSize: '2.5' }} />
            <Text>
              &nbsp;
              {Strings.ApproveSelected}
            </Text>
          </MultiApproveButton>
          <MultiDeclineButton
            onClick={() => handleActionMulti(TPunterWithdrawalStatus.Rejected)}
          >
            <IconSvg name="close-white" sx={{ mt: '-4', boxSize: '2.5' }} />
            <Text>
              &nbsp;
              {Strings.RejectSelected}
            </Text>
          </MultiDeclineButton>
        </LeftSideMultiActions>
      </DataTableMultiSelectBar>
    </>
  );
};
