import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { errorMessageFromHTTPException } from '@/lib/Error';
import { useStatus } from '../services/Status.hooks';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const { initialValue, submitStatus } = useStatus();

  if (!initialValue) return null;

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={async (value) => {
        try {
          await submitStatus(value.status);
        } catch (error) {
          toast.error(errorMessageFromHTTPException(error));
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
