import {
  Box,
  Flex,
  FormLabel,
  ModalBody,
  useToast,
  Text,
  FormControl,
  Switch,
  SystemStyleObject,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useMutationPriceTypes } from '@/api/tradeManager/raceDetails/updatePriceTypes/updatePriceTypes.hooks';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { getMarketByType } from '@/common/utils';
import useErrorHandler from '@/hooks/useErrorHandler';
import { EInividualMarketTypes } from '@/lib/Constants';
import { TPriceType } from '@/lib/DBModels';
import { getRaceMarkets } from '../../../Services/RaceDetails.actions';
import { setIsConfigureRaceModalOpen } from '../../../Services/RaceDetails.slices';
import Form from './components/Form';
import { SwitchConfig } from '../../ManageRaceConfig/styles/ManageRaceConfig.styles';
import { mutateRacePriceTypes } from '@/api/tradeManager/raceDetails/priceTypes/priceTypes';
import { useMutation } from 'react-query';
import { useToggles } from './Offerings.hooks';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

export type TOfferingsFormValues = {
  win: boolean;
  place: boolean;
  winTote: boolean;
  placeTote: boolean;
  bestTote: boolean;
  srm: boolean;
  evenShot: boolean;
  mysteryBet: boolean;
};

export default function Offerings() {
  const dispatch = useAppDispatch();
  const { raceData, raceMarkets } = useAppSelector(
    ({ raceDetails }) => raceDetails
  );
  const onError = useErrorHandler();
  const intl = useIntl();
  const mutation = useMutationPriceTypes();
  const [params] = useSearchParams();
  const toast = useToast();

  const marketsByKey = {
    win: getMarketByType(raceMarkets, EInividualMarketTypes.WIN),
    place: getMarketByType(raceMarkets, EInividualMarketTypes.PLACE),
  };

  const isToteEnabled =
    !!raceData.price_types_intercept_all?.includes('tote_single_mid');
  const isBestToteEnabled =
    !!raceData.price_types_intercept_all?.includes('tote_single_best');

  const { mutate } = useMutation(mutateRacePriceTypes);
  const [totesToApproval, setTotesToApproval] = useState(
    !!isToteEnabled || isBestToteEnabled
  );

  const [srmDisabledState, setSrmDisabledState] = useState(
    raceData?.srm_disabled || false
  );
  const [srmInterceptAllState, setSrmInterceptAllState] = useState(
    raceData?.srm_intercept_all || false
  );
  const [blendedDisabledState, setBlendedDisabledState] = useState(
    raceData?.blended_disabled || false
  );
  const [blendedInterceptAllState, setBlendedInterceptAllState] = useState(
    raceData?.blended_intercept_all || false
  );
  const [evenShotDisabledState, setEvenShotDisabledState] = useState(
    raceData?.even_shot_disabled || false
  );

  const [mysteryBetDisabledState, setMysteryBetDisabledState] = useState(
    raceData?.mystery_bet_disabled || false
  );
  const { updateSettings } = useToggles();

  if (!marketsByKey.win && !marketsByKey.place) {
    return (
      <ModalBody sx={{ pt: '0 !important' }}>
        <FormattedMessage id="generic.na" />
      </ModalBody>
    );
  }

  // Default to false for MVP
  const initialValues = {
    win: !!marketsByKey.win?.price_types?.includes('starting'),
    place: !!marketsByKey.place?.price_types?.includes('starting'),
    winTote: !!marketsByKey.win?.price_types?.includes('tote_single_mid'),
    placeTote: !!marketsByKey.place?.price_types?.includes('tote_single_mid'),
    srm: !!marketsByKey.place?.price_types?.includes('srm'),
    evenShot: !!marketsByKey.place?.price_types?.includes('even_shot'),
    bestTote: !!marketsByKey.win?.price_types?.includes('tote_single_best'),
    mysteryBet: !!marketsByKey.win?.price_types?.includes('mystery_bet'),
  };

  const hasPlaceTote =
    !!marketsByKey.place?.price_types?.includes('tote_single_mid');
  const hasWinTote =
    !!marketsByKey.win?.price_types?.includes('tote_single_mid');

  const borderStyles: SystemStyleObject = {
    borderTop: '1px',
    borderColor: 'blackAlpha.100',
  };

  return (
    <Box overflowY="scroll">
      <Formik<TOfferingsFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const marketKeys = ['win', 'place'] as const;
          const markets = marketKeys
            .filter((key) => marketsByKey[key])
            .map((key) => {
              const marketId = marketsByKey[key]?.market_id ?? '';

              const priceTypes: TPriceType[] = [];

              if (values[key]) {
                priceTypes.push('fixed', 'starting');
              } else {
                priceTypes.push('fixed');
              }

              if (key === 'win' && values.bestTote === true) {
                priceTypes.push('tote_single_best');
              }

              if (key === 'win' && values.winTote && !values.bestTote) {
                priceTypes.push('tote_single_mid');
              }
              if (key === 'place' && values.placeTote) {
                priceTypes.push('tote_single_mid');
              }

              if (values.srm) {
                priceTypes.push('srm');
              }

              if (values.evenShot) {
                priceTypes.push('even_shot');
              }

              if (values.mysteryBet) {
                priceTypes.push('mystery_bet');
              }

              return { market_id: marketId, price_types: priceTypes };
            });
          await updateSettings({
            blended_disabled: blendedDisabledState,
            blended_intercept_all: blendedInterceptAllState,
            even_shot_disabled: evenShotDisabledState,
            srm_intercept_all: srmInterceptAllState,
            srm_disabled: srmDisabledState,
            mystery_bet_disabled: mysteryBetDisabledState,
          });
          mutation.mutate(markets, {
            onError,
            onSuccess: async () => {
              // ! The order matters here
              toast({
                title: intl.formatMessage({
                  id: 'tradeManagerPage.raceDetails.saveOfferingsSuccessTitle',
                }),
                status: 'success',
                position: 'top',
                duration: 9_000,
              });
              await dispatch(
                getRaceMarkets({
                  raceId: params.get('id') ?? '',
                  isUpdate: true,
                })
              );
              resetForm();
            },
            onSettled: () => {
              dispatch(setIsConfigureRaceModalOpen(false));
            },
          });
        }}
      >
        <Form
          win={!!marketsByKey.win}
          place={!!marketsByKey.place}
          winTote={!!hasWinTote}
          placeTote={!!hasPlaceTote}
        >
          <Flex
            flex="50%"
            borderTop="1px solid"
            borderTopColor="blackAlpha.100"
          >
            <FormLabel mb="0" mt="2" marginRight="auto">
              All TOTES to approval
            </FormLabel>
            <SwitchConfig
              mt="2"
              mr="-0.5"
              isChecked={totesToApproval}
              onChange={() => {
                mutate({
                  race_id: raceData.race_id ?? '',
                  price_types:
                    isToteEnabled || isBestToteEnabled
                      ? []
                      : ['tote_single_mid', 'tote_single_best'],
                });
                setTotesToApproval(!totesToApproval);
              }}
            />
          </Flex>

          <Box>
            <Text fontWeight="bold" py="2" mt="1">
              SRM
            </Text>

            <FormControl display="flex" alignItems="center" sx={borderStyles}>
              <FormLabel htmlFor="srm-enabled" mb="0" marginRight="auto" mt="2">
                SRM Disabled
              </FormLabel>
              <Switch
                mt="2"
                id="srm-enabled"
                onChange={(e) => {
                  setSrmDisabledState(e.target.checked);
                }}
                isChecked={srmDisabledState}
                marginLeft="2"
              />
            </FormControl>
            <FormControl
              sx={borderStyles}
              display="flex"
              alignItems="center"
              mt="2"
            >
              <FormLabel
                htmlFor="srm-approval-enabled"
                mb="0"
                marginRight="auto"
              >
                All SRMs to approval
              </FormLabel>
              <Switch
                mt="2"
                id="srm-approval-enabled"
                isChecked={srmInterceptAllState}
                onChange={(e) => {
                  setSrmInterceptAllState(e.target.checked);
                }}
                marginLeft="2"
              />
            </FormControl>
            <Text fontWeight="bold" py="2" mt="1">
              Blended
            </Text>

            <FormControl display="flex" alignItems="center" sx={borderStyles}>
              <FormLabel
                htmlFor="blended-disabled"
                mb="0"
                marginRight="auto"
                mt="2"
              >
                Blended Disabled
              </FormLabel>
              <Switch
                id="blended-disabled"
                onChange={(e) => setBlendedDisabledState(e.target.checked)}
                isChecked={blendedDisabledState}
                mt="2"
                marginLeft="2"
              />
            </FormControl>

            <FormControl
              display="flex"
              alignItems="center"
              mt="2"
              sx={borderStyles}
            >
              <FormLabel
                htmlFor="blended-approval"
                mb="0"
                marginRight="auto"
                mt="2"
              >
                All Blendeds to approval
              </FormLabel>
              <Switch
                id="blended-approval"
                onChange={(e) => setBlendedInterceptAllState(e.target.checked)}
                isChecked={blendedInterceptAllState}
                mt="2"
                marginLeft="2"
              />
            </FormControl>

            <Text fontWeight="bold" py="2" mt="1">
              Even Shot
            </Text>

            <FormControl display="flex" alignItems="center" sx={borderStyles}>
              <FormLabel
                htmlFor="evenshot-disabled"
                mb="0"
                marginRight="auto"
                mt="2"
              >
                Even Shot Disabled
              </FormLabel>
              <Switch
                id="evenshot-disabled"
                onChange={(e) => setEvenShotDisabledState(e.target.checked)}
                isChecked={evenShotDisabledState}
                marginLeft="2"
                mt="2"
              />
            </FormControl>

            {FEATURE_FLAGS.IS_MYSTERY_BET_ENABLED && (
              <FormControl display="flex" alignItems="center" sx={borderStyles}>
                <FormLabel
                  htmlFor="mysterybet-disabled"
                  mb="0"
                  marginRight="auto"
                  mt="2"
                >
                  Mystery Bet Disabled
                </FormLabel>
                <Switch
                  id="mysterybet-disabled"
                  onChange={(e) => setMysteryBetDisabledState(e.target.checked)}
                  isChecked={mysteryBetDisabledState}
                  marginLeft="2"
                  mt="2"
                />
              </FormControl>
            )}
          </Box>
        </Form>
      </Formik>
    </Box>
  );
}
