import { StyleFunctionProps, theme } from '@chakra-ui/react';

export const Select = {
  sizes: {
    lg: {
      field: {
        fontSize: 'sm',
        borderRadius: 'md',
        h: '14',
      },
    },
  },
  variants: {
    filled: (props: StyleFunctionProps) => ({
      ...theme.components.Select.variants?.filled(props),
      field: {
        borderColor: 'white',
        background: 'white',
        '&&:hover': {
          background: 'white',
        },
        '&&:focus': {
          background: 'white',
        },
      },
    }),
  },
  defaultProps: {},
};
