import { EBonusAwardReason, TBulkBonusAward } from '../types';

export const mockBulkBonusAwards: TBulkBonusAward[] = [
  {
    name: 'Test 1',
    reason: EBonusAwardReason.bonus_back_for_2nd,
    total_punters: 120,
    total_credited: 1200,
    promotion_date: '1/2/2020',
  },
  {
    name: 'Test 2',
    reason: EBonusAwardReason.deposit_match,
    total_punters: 100,
    total_credited: 1000,
    promotion_date: '3/2/2020',
  },
  {
    name: 'Test 3',
    reason: EBonusAwardReason.bonus_back_for_2nd,
    total_punters: 50,
    total_credited: 500,
    promotion_date: '10/2/2020',
  },
  {
    name: 'Test 4',
    reason: EBonusAwardReason.deposit_match,
    total_punters: 1000,
    total_credited: 10000,
    promotion_date: '20/2/2020',
  },
];

export const mockBulkBonusSubmission = {
  name: 'test-bulk.csv',
  reason: EBonusAwardReason.deposit_match,
  bonus_awards: [
    {
      punter: 'test-id',
      amount: 100,
    },
    {
      punter: 'test-id2',
      amount: 200,
    },
  ],
};
