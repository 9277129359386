import { Box, Collapse, ListItem, OrderedList } from '@chakra-ui/react';
import React from 'react';
import { rgba } from 'polished';
import { useValidationErrors } from '../services/Hooks.Settle';

export default function BannerValidation() {
  const errors = useValidationErrors();

  return (
    <Collapse in={!!errors.length} animateOpacity>
      <Box
        sx={{
          bgColor: rgba(255, 0, 0, 0.1),
          borderRadius: 'sm',
          borderLeft: '5px solid',
          borderLeftColor: 'red.500',
          p: 4,
          mt: 6,
        }}
      >
        <OrderedList>
          {errors.map((err) => (
            <ListItem
              key={`li-${err}`}
              fontWeight="medium"
              fontSize="xs"
              color="red.500"
            >
              {err}
            </ListItem>
          ))}
        </OrderedList>
      </Box>
    </Collapse>
  );
}
