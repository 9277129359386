import styled from 'styled-components';
import { Box, Text, Button } from 'grommet';

export const MarginDisplay = styled(Box)`
  display: flex;
  width: 75px;
  height: 40px;
  background: ${({ theme }) => theme.global.colors.marginGrey};
  border-width: 2px;
  align-items: center;
  align-self: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  align-content: center;
  justify-content: center;
  border-style: solid;
  border-width: 3px 0px 3px 3px;
  border-color: ${({ theme }) => theme.global.colors.borderGrey};
`;

export const MarginText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.global.colors.black};
`;

export const IncrementButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.global.colors.marginGrey};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-style: solid;
  border-width: 3px 3px 1.5px 3px;
  border-color: ${({ theme }) => theme.global.colors.borderGrey};
`;

export const PlusMinusText = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.global.colors.yellow};
`;

export const DecrementButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.global.colors.marginGrey};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-style: solid;
  border-width: 1.5px 3px 3px 3px;
  border-color: ${({ theme }) => theme.global.colors.borderGrey};
`;
