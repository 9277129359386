import React from 'react';
import { Tags } from '@styled-icons/fa-solid/Tags';
import { Text } from '@chakra-ui/react';
import { flagOptions } from './services/PunterFlag.config';
import { IconPlaceholder, SelectPunterFlag } from './styles/PunterFlag.styles';
import { usePunterFlag } from './services/PunterFlag.hooks';
import { TPunterFlag } from './services/PunterFlag.types';
import makeAnimated from 'react-select/animated';
import { FormattedMessage } from 'react-intl';

export const PunterFlag = ({ punter }: TPunterFlag) => {
  const { defaultFlags, handleOnchange } = usePunterFlag({ punter });
  const animatedComponents = makeAnimated();

  return (
    <SelectPunterFlag
      options={flagOptions}
      isMulti
      placeholder={
        <Text>
          <IconPlaceholder as={Tags} />
          <FormattedMessage id="punterpage.profile.flagplaceholder" />
        </Text>
      }
      isLoading={!punter}
      defaultValue={defaultFlags}
      // eslint-disable-next-line
      onChange={(_, actionMeta: any) => handleOnchange(actionMeta)}
      components={{
        ...animatedComponents,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => null}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#efefef',
        },
      })}
      styles={{
        multiValue: (base) => ({
          ...base,
          backgroundColor: '#718096',
          color: 'white',
          borderRadius: '15px',
          padding: '0 5px',
          fontSize: '13px',
          cursor: 'pointer',
          transition: '.3s ease-in-out',
          '&:hover': {
            backgroundColor: '#6f819c',
          },
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: 'white',
          cursor: 'pointer',
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '5px',
        }),
        indicatorsContainer: (base, state) => ({
          ...base,
          transition: 'all .2s ease',
          opacity: state.selectProps.menuIsOpen ? '1' : '0',
          display: state.selectProps.menuIsOpen ? 'flex' : 'none',
        }),
        multiValueRemove: (base, state) => ({
          ...base,
          borderTopRightRadius: '15px',
          borderBottomRightRadius: '15px',
          transition: '.3s ease-in-out',
          display: state.selectProps.menuIsOpen ? 'flex' : 'none',
          opacity: state.selectProps.menuIsOpen ? '1' : '0',
          '&:hover': {
            color: 'white',
            backgroundColor: '#6f819c',
          },
        }),
      }}
    />
  );
};
