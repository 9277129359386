import api from '@/api/api';
import { TSGMBetsParams } from './bets.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// SGM - Bets
const querySGMBetsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sgmultis/bets`;
export const querySGMBets = (params: TSGMBetsParams) =>
  api
    .get<Api['SGMultisBetsResponse']>(querySGMBetsURL, { params })
    .then((res) => res.data);
