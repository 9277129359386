/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TPunterWithdrawalRequestsDTO } from '@/lib/DTO';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { keys } from '../../api.keys';
import {
  TWithdrawalParams,
  TWithdrawalsDownloadInput,
  TWithdrawalStatsParams,
} from '../withdrawals.types';
import {
  mutateWithdrawalHistory,
  queryWithdrawalHistory,
  queryWithdrawalStats,
} from './history';
import { TBetCloudErrorResponse } from '@/api/api.types';
import { AxiosError, AxiosResponse } from 'axios';

type TQueryWithdrawalHistoryHook = {
  params?: TWithdrawalParams;
  key?: unknown[];
  options?: UseQueryOptions<TPunterWithdrawalRequestsDTO[]>;
};

export const useQueryWithdrawalHistory = ({
  params = {},
  key = [],
  options = {},
}: TQueryWithdrawalHistoryHook = {}) => {
  const query = useQuery(
    [keys.historyWithdrawals, ...Object.values(params), ...key],
    () => queryWithdrawalHistory(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

type TQueryWithdrawalStatsHook = {
  params?: TWithdrawalStatsParams;
};

export const useQueryWithdrawalStats = ({
  params = {},
}: TQueryWithdrawalStatsHook) =>
  useQuery([keys.historyWithdrawalStats, ...Object.values(params)], () =>
    queryWithdrawalStats(params)
  );

export const useMutationWithdrawalHistory = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<string>,
      AxiosError<TBetCloudErrorResponse>,
      TWithdrawalsDownloadInput
    >,
    'mutationFn'
  >
) => useMutation(mutateWithdrawalHistory, options);
