import React from 'react';
import Footer from '../Footer';
import Form from './components/Form/Form';
import FormikHOC from './components/FormHOC';

export default function Settlement() {
  return (
    <FormikHOC>
      <Form />
      <Footer />
    </FormikHOC>
  );
}
