import { TDeepPartial } from './types';
import { TExoticCombo } from '../common/components/ExoticsBetSelection/Services/Types.ExoticsBetCombos';
import { DeepPartial } from '@reduxjs/toolkit';
import { TBetLegs } from '@/api/punters/punters.types';

export enum ERaceStatus {
  Open = 'Open',
  Closed = 'Closed',
  Abandoned = 'Abandoned',
  Scratched = 'Scratched',
  Settled = 'Settled',
  Voided = 'Voided',
}

export type TState = TDeepPartial<{
  venues: TVenue[];
  display_name: string;
  all_offered: boolean;
}>;

export type TVenue = TDeepPartial<{
  venue_id: string;
  display_name: string;
  is_offered?: boolean;
  notification_count?: number;
  has_races?: boolean;
}>;

export type TCountry = TDeepPartial<{
  states: TState[];
  venues: TVenue[];
  display_name: string;
  all_offered: boolean;
}>;

export type TRaceType = TDeepPartial<{
  display_name: string;
  countries: TCountry[];
  all_offered: boolean;
}>;

export type TRace = TDeepPartial<{
  race_id: string;
  display_name: string;
  race_type: string;
  start_time: string;
  runners?: TRunner[];
  race_number?: number;
  win_proposition?: TMarginModifier;
  place_proposition?: TMarginModifier;
}>;

export type TRaceDetails = TDeepPartial<{
  race_id: string;
  is_suspended: boolean;
  display_name: string;
  venue_name: string;
  race_type: string;
  start_time: string;
  status: EGeneralStatus;
  lock_status: boolean;
  ignore_winter_settlement: boolean;
  lock_price: boolean;
  fptp_protest_override: boolean;
  win_modifier: TMarginModifier;
  place_modifier: TMarginModifier;
  runners: TRunner[];
  race_number?: number;
  stats: TRaceState;
  price_types_intercept_all: string[];
  srm_intercept_all: boolean;
  srm_disabled: boolean;
  even_shot_disabled: boolean;
  blended_disabled?: boolean;
  blended_intercept_all?: boolean;
  mystery_bet_disabled?: boolean;
}>;

export type TRaceState = TDeepPartial<{
  total_bets: number;
  turnover: number;
  exposure: number;
  player_type_distribution: [];
  win_turnover: number;
  place_turnover: number;
  exotic_turnover: number;
  srm_total_bets: number;
  srm_turnover: number;
  tote_multi_turnover: number;
}>;

export type TRunnerProposition = TDeepPartial<{
  proposition_id: string;
  proposition_name: string;
  return_amount: number;
  winter_return_amount: number;
  proposition_modifier: TMarginModifier;
  is_suspended: boolean;
  winter_is_suspended: boolean;
  lock_status: boolean;
  status: EGeneralStatus;
  lock_price: boolean;
  deductions: number[];
  scratch_time: string;
  winter_scratch_time: string;
}>;

export type TRunner = TDeepPartial<{
  runner_id: string;
  race_runner_id: string;
  race_runner_external_ref: string;
  display_name: string;
  number: number;
  barrier_number?: number;
  win_proposition: TRunnerProposition;
  place_proposition: TRunnerProposition;
  silk_url?: string;
  results_place?: number;
  win_result: number;
  win_bets: number;
  win_hold: number;
  avg_win_price: number;
  place_bets: number;
  place_hold: number;
  place_liability: number;
  biggest_win_bet: number;
  best_win_result: number;
  result_yield: number;
  is_scratched: boolean;
  scratched_by: string;
  is_suspended: boolean;
  proposition_is_suspended: boolean;
  winter_is_suspended: boolean;
  lock_status: boolean;
  lock_price: boolean;
  status: EGeneralStatus;
  winter_status: EGeneralStatus;
  event_status: EGeneralStatus;
}>;

export type TRunnerWinProposition = TDeepPartial<{
  proposition_id: string;
  proposition_name: string;
  return_amount: number;
  proposition_modifier: TMarginModifier;
}>;

// not used?
// export type TRunnerPlaceProposition = {
//   proposition_id: string;
//   proposition_name: string;
//   return_amount: number;
//   proposition_modifier: TMarginModifier;
// };

export type RaceDetailsTableProps = TDeepPartial<{
  raceData: TRaceDetails;
  raceDataLoading: boolean;
}>;

export interface ReactRouterLocationState {
  from: {
    pathname: string;
    search: string;
  };
}

export enum EBetStatus {
  Pending = 'pending',
  PartiallySettled = 'partially_settled',
  Settled = 'settled',
  Voided = 'voided',
  CashedOut = 'cashed_out',
  Approved = 'Approved',
  Rejected = 'Rejected',
  ReducedStake = 'ReducedStake',
  Cancelled = 'cancelled',
  Partial = 'Partial',
}

export enum PromoTypeSchema {
  OddsBoost = 'odds-boost',
  CashOut = 'cashout',
  MoneyBack = 'money-back',
}

export enum ESettledSportsProps {
  Winner = 'Winner',
  Loser = 'Loser',
  Top2 = 'Top2',
  Top3 = 'Top3',
}

export enum EIncetiveType {
  'Place (Fixed)' = 'Place (Fixed)',
  'Win (Fixed)' = 'Win (Fixed)',
}

export type TWinnerStatus = 'Won' | 'No Return';

export enum EStakeReductionReason {
  MBLThreshold = 'mbl_threshold',
  PunterCategoryExposureLimit = 'punter_category_exposure_limit',
  GlobalPropositionExposureLimit = 'global_proposition_exposure_limit',
  PcelRacing = 'pcel_racing',
  PcelThoroughbred = 'pcel_thoroughbred',
  PcelHarness = 'pcel_harness',
  PcelGreyhound = 'pcel_greyhound',
  PcelSport = 'pcel_sport',
  PcelSportsLowTier = 'pcel_sports_low_tier',
  PcelSportsHighTier = 'pcel_sports_high_tier',
}

export type TDividends = TDeepPartial<{
  dividends: { combo: number[]; dividend: number }[];
}>;

export type TBet = DeepPartial<{
  bet_id: string;
  bet_legs: TBetLegs[];
  event_id: string;
  created_at: string;
  event_type: string;
  event_icon: string;
  currency?: string;
  stake: number;
  odds: number;
  is_won: boolean;
  is_settled: boolean;
  is_bonus: boolean;
  punter_id: string;
  punter_name: string;
  punter_type: string;
  event_title: string;
  event_subtitle: string;
  event_start: string;
  tab_odds: number;
  current_odds: number;
  bonus_stake: number;
  bcs: number;
  punter_total_profit: number;
  punter_code_profit: number;
  event_exposure: number;
  result: number;
  status: EBetStatus;
  exotic_selections: TExoticCombo[];
  stake_reduction: number;
  stake_reduction_reason: EStakeReductionReason;
  return?: number;
  exotic_dividends: TDividends;
  tokens?: TPromo[];
  promotions?: TPromotion[];
}>;

export type TPromotion = {
  id: string;
  type:
    | 'deposit_match'
    | 'first_deposit_match'
    | 'bonus_bet'
    | 'manual_credit'
    | 'money_back';
  claimed_amount: number;
  bonus_amount: number;
};

export type TPromo = {
  id: string;
  token_type: string;
  status: string;
  can_claim: boolean;
  bonus_amount: number;
  original_odds: number;
  boosted_odds: number;
};

export type TMarket = TDeepPartial<{
  market_id: string;
  market_name: string;
  market_type: string;
  modifiers: TMarginModifier;
  propositions?: TProposition[];
  propositions_count: number;
  status: EGeneralStatus;
  is_suspended: boolean;
  is_visible: boolean;
  lock_price: boolean;
  lock_status: boolean;
  market_proposition?: TProposition[];
  raw_market_propositions: TProposition[];
  update_value?: number;
  exposure?: number;
  disclaimer: string;
  is_manual: boolean;
  is_focus: boolean;
  ignore_winter_settlement: boolean;
  price_types?: TPriceType[];
}>;

export type TRaceMarket = Omit<
  TMarket,
  'modifiers' | 'propositions' | 'propositions_count'
> & {
  market_is_suspended?: boolean;
};

export type TMarginModifier = TDeepPartial<{
  display_modifier: number;
  modifier: number;
  update_value?: number;
  original_tick_index?: number;
}>;

export type TProposition = TDeepPartial<{
  proposition_id: string;
  proposition_name: string;
  proposition_modifier: TMarginModifier;
  override_return_amount: number;
  margin_return_amount: number;
  proposition_stats: {
    total_bets: number;
    total_staked: number;
    biggest_bet: number;
    average_price: number;
    bonus_bets: number;
    exposure: number;
    result: number;
  };
  return_amount: number;
  status: EGeneralStatus;
  outcome: ESettledSportsProps;
  is_suspended: boolean;
  lock_status: boolean;
  lock_price: boolean;
  ignore_winter_settlement: boolean;
}>;

export type TMatch = TDeepPartial<{
  match_id: string;
  display_name: string;
  modifier: TMarginModifier;
  sport_name: string;
  sport_id: string;
  sport_type?: string;
  start_time: string;
  competition_name?: string;
  tournament_name?: string;
  match_stats: TMatchStats;
  status: EGeneralStatus;
  is_suspended: boolean;
  lock_status: boolean;
  ignore_winter_settlement: boolean;
}>;

export type TMatchStats = TDeepPartial<{
  total_bets: number;
  turnover: number;
  exposure: number;
  player_type_distribution: [];
  win_turnover: number;
  place_turnover: number;
  multi_turnover: number;
  sgm_total_bets?: number;
  sgm_turnover?: number;
  cashed_out_bets?: number;
  cash_out_turnover?: number;
  cash_out_profit?: number;
}>;

export type TMarketGroup = TDeepPartial<{
  market_group_id: string;
  market_group_title: string;
}>;

export type TSports = TDeepPartial<{
  competitions: TCompetitionOffering[];
  all_offered: boolean;
  cashout_offered: boolean;
  display_name: string;
  sport_id: string;
}>;

export type TCompetitionOffering = TDeepPartial<{
  competition_id: string;
  display_name: string;
  is_offered: boolean;
  is_om: boolean;
}>;

export type TSportsOffered = TDeepPartial<{
  sports: TSports[];
  all_sport: boolean;
}>;

export type TRacingOffered = TDeepPartial<{
  race_types: TRaceType[];
  all_racing: boolean;
}>;

export type TRunnerResult = TDeepPartial<{
  race_runner_id: string;
  race_runner_external_ref: string;
  runner_name: string;
  runner_number: string;
  result_place: string;
  barrier_number: string;
  win_odds: number;
  place_odds: number;
  silk_url: string | null;
}>;

export type TToteMultiResults = {
  type: string;
  legs: number[];
  selection: number[];
  dividend: number;
};

export type TRaceResults = TDeepPartial<{
  race_id: string;
  runner_results: TRunnerResult[];
  exotic_results: TRaceExoticResult[];
  tote_multi_results: TToteMultiResults[];
}>;

export enum EGeneralStatus {
  Open = 'Open',
  Closed = 'Closed',
  Suspended = 'Suspended',
  Abandoned = 'Abandoned',
  Settled = 'Settled',
  ManuallySettled = 'Manually Settled',
  Voided = 'Voided',
  Scratched = 'Scratched',
  Removed = 'Removed',
  Mixed = 'Mixed',
}

export enum EPlayerType {
  Newbie = 'Newbie',
  Player = 'Player',
  Recreational = 'Recreational',
  Sharp = 'Sharp',
  HighRoller = 'High Roller',
  Blacklist = 'Blacklist',
  ApprovalOnly = 'Approval Only',
  PromoArber = 'Promo Arber',
}

export type TPunterStats = TDeepPartial<{
  punter_id: string;
  bet_cloud_score: number;
  turnover: number;
  balance: number;
  restricted_balance: number;
  unrestricted_balance: number;
  bonus_balance: number;
  bonus_given: number;
  last_bet_date: string;
  average_bet: number;
  total_bets: number;
  last_bet: number;
  player_type: EPlayerType;
  frequent_bet_types: string[];
  punter_category: EPlayerType;
  storm_score: string;
  eligible_bonus: number;

  winnings: number;
  settlement_winnings: number;
  punter_winnings?: number;
  cash_out_winnings?: number;
  bonus_winnings?: number;

  bonus_turnover: number;
  pending_turnover: number;
  cash_out_turnover: number;
  settlement_turnover: number;

  odds_boost_win_count: number;
  odds_boost_total_amount: number;
  odds_boost_win_new_amount: number;
  odds_boost_win_original_amount: number;
  bet_boost_count: number;
  bet_boost_amount: number;

  money_back_amount: number;

  deposit_match_token_claimed_amount: number;
  deposit_match_token_max_amount: number;
  bet_slip_bonus_amount: number;
}>;

export type TPunterDepositLimit = TDeepPartial<{
  bookie_id?: string;
  amount: number;
  frequency: number;
  date_set?: string;
  last_updated?: string;
  updated_by?: string;
}>;

export type TPunterCurrentDepositLimit = TPunterDepositLimit & {
  cooldown_ends?: string;
  active_when?: string;
  limit_waiting?: number;
  last_updated?: string;
};

export enum ETransactionType {
  Deposit = 'Deposit',
  Bet = 'Bet',
  Winnings = 'Winnings',
  Bonus = 'Bonus',
  Withdrawal = 'Withdrawal',
  Void = 'Void',
  Adjustment = 'Adjustment',
  Credit = 'Credit',
}

export enum ETransactionStatus {
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  STAKE = 'Stake',
  WON = 'Won',
  LOST = 'Lost',
  PAID = 'Paid',
  APPROVED = 'Approved',
}

export type TPunterTransaction = TDeepPartial<{
  transaction_id: string;
  transaction_created_at: string;
  transaction_type: ETransactionType;
  transaction_amount: number;
  bet_status: EBetStatus;
  bet_odds: number;
  bet_stake: number;
  punter_balance: number;
  type: string;
  details: string;
  note: string;
  reason: string;
}>;

export type TBetBonusData = {
  bonus_cash_awarded: number;
  date_issued: string;
  date_used: string;
  id: string;
  reason: string;
};

export enum ERaceBetType {
  ToteWin = 'Tote Win',
  TotePlace = 'Tote Place',
  Exacta = 'Exacta',
  Quinella = 'Quinella',
  Trifecta = 'Trifecta',
  FirstFour = 'First 4',
}

export type TRaceExoticResult = TDeepPartial<{
  dividend: number;
  type: ERaceBetType;
  selection: number[];
}>;

export type TPunterDepositsData = {
  amount: number;
  type: string;
  status: string;
  actioned_at: string;
  reason?: string;
  actioned_by?: string;
  card_bin?: string;
  last_4?: string;
};

export type TPunterProfileCategory = {
  punter_id: string;
  bookie_category: string;
  storm_category?: string | null;
  use_storm_category?: boolean;
};

export type TGetRace = {
  raceId: string;
  isUpdate?: boolean;
};

export type TGetMatch = {
  matchId: string;
  isUpdate?: boolean;
};

export type TGetPropositions = {
  marketId: string;
  isUpdate?: boolean;
};

export type TPriceType =
  | 'starting'
  | 'fixed'
  | 'tote_single_mid'
  | 'tote_single_best'
  | 'srm'
  | 'even_shot'
  | 'mystery_bet'
  | 'tote_multi';

export enum EBetTypesDisplayNames {
  Exacta = 'Exacta',
  Quinella = 'Quinella',
  Trifecta = 'Trifecta',
  FirstFour = 'First Four',
  RacingWin = 'Racing Win',
  RacingPlace = 'Racing Place',
  Win = 'Win',
  WinPlace = 'Win/Place',
  TopMarket = 'Top Market',
  SRMulti = 'Same Race Multi',
  SGMulti = 'Same Game Multi',
  Blended = 'Blended',
  EvenShot = 'Even Shot',
  MysteryBet = 'Mystery Bet',
  Quaddie = 'Quaddie',
  EarlyQuaddie = 'EarlyQuaddie',
  DailyDouble = 'DailyDouble',
  RunningDouble = 'RunningDouble',
  Treble = 'Treble',
  BigSix = 'BigSix',
  Multiples = 'Multiples',
  Exotics = 'Exotics',
}
