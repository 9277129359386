import {
  Button,
  ModalBody,
  Text,
  ModalFooter,
  Divider,
} from '@chakra-ui/react';
import React from 'react';
import { useAppDispatch } from '../../../../../../../../../common/hooks/useRedux';
import { getStrings } from '../../../../../../../../../common/utils';
import { setIsConfigureMatchModalOpen } from '../../../../../slices';
import { useVoid } from '../services/Void.hooks';

export default function Void() {
  const dispatch = useAppDispatch();
  const { submit, loading } = useVoid();

  const [
    {
      Generic,
      TradeManagerPage: {
        ByRacingTab: { Modals },
      },
    },
  ] = getStrings();

  return (
    <>
      <ModalBody
        sx={{
          pt: `0 !important`,
          pb: 'var(--bc-space-2) !important',
        }}
      >
        <Divider sx={{ mt: '4', mb: '5', borderColor: 'gray.400' }} />
        <Text fontSize="sm">{Modals.Labels.void}</Text>
      </ModalBody>

      <ModalFooter alignItems="flex-end">
        <Button
          variant="outline-rounded"
          colorScheme="gray"
          disabled={loading}
          onClick={() => dispatch(setIsConfigureMatchModalOpen(false))}
        >
          {Generic.Cancel}
        </Button>

        <Button
          type="submit"
          isLoading={loading}
          variant="rounded"
          colorScheme="red"
          ml="4"
          onClick={() => submit()}
        >
          {Generic.SaveConfirm}
        </Button>
      </ModalFooter>
    </>
  );
}
