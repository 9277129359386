import api from '@/api/api';
import { TRunnerBody } from './runnerStatus.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const runnerStatusURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/manual-runner-status-update`;

// Post: Update Runner Status
export const mutateRunnerStatus = (body: TRunnerBody[]) =>
  api.post<TRunnerBody>(runnerStatusURL, body).then((res) => res.data);
