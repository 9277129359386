import React, { ReactElement } from 'react';
import {
  FormLabelProps,
  FormErrorMessageProps,
  TextProps,
  StyleProps,
} from '@chakra-ui/react';
import FormikHOC from './FormikHOC';
import FormControl from './FormControl';

export type TIndex = {
  isFormik?: boolean;
  name: string;
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: TextProps;
  sxWrapper?: StyleProps;
  children: ReactElement;
};

/**
 */
export default function HOCs({
  isFormik = true,
  name,
  label,
  error,
  helperText,
  propsLabel,
  propsError,
  propsHelper,
  sxWrapper,
  children,
}: TIndex) {
  const id = `${name}-input`;
  const formControlProps = {
    id,
    label,
    error,
    helperText,
    propsLabel,
    propsError,
    propsHelper,
    sxWrapper,
  };

  if (isFormik) {
    return (
      <FormikHOC name={name}>
        <FormControl {...formControlProps}>{children}</FormControl>
      </FormikHOC>
    );
  }

  return <FormControl {...formControlProps}>{children}</FormControl>;
}
