import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { BonusBet } from '../../EventMarketInfo/EventMarketInfo.styles';
import { GiftPack } from '@/assets/icons';
import Countdown from '@/components/Countdown/Countdown';

interface IMarketInfoMultiProps {
  legs: number;
  timeStamp: string;
  isBonusBet: boolean;
  items: (
    | {
        type: string;
        runnerName: string;
        odds: number;
        marketName: string;
        venueName: string;
        raceNumber: number;
        priceType: string;
      }
    | {
        type: string;
        matchName: string;
        odds: number;
        propositionName: string;
        marketName: string;
        priceType: string;
      }
  )[];
}

function isRace(item: IMarketInfoMultiProps['items'][0]): item is {
  type: string;
  runnerName: string;
  odds: number;
  marketName: string;
  venueName: string;
  raceNumber: number;
  priceType: string;
} {
  return item.type !== 'Match';
}

export function MarketInfoMulti({
  legs,
  timeStamp,
  isBonusBet,
  items,
}: IMarketInfoMultiProps) {
  const isMB = items.some((i) => i.priceType === 'mystery_bet');

  return (
    <Box>
      <Text fontWeight="bold">
        {isMB ? `Mystery Bet With Rollover` : `${legs} Leg Multi `}
      </Text>
      <Box
        sx={{
          '& > div': {
            justifyContent: 'left',
          },
        }}
      >
        <Countdown eventTime={timeStamp} />
      </Box>
      {isBonusBet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>Bonus</Text>
        </BonusBet>
      )}
      {items.map((item) => {
        if (isRace(item)) {
          return (
            <Box
              key={item.runnerName}
              w="full"
              borderBottom={'1px solid gray'}
              my={2}
            >
              <HStack justifyContent="space-between">
                <Text fontWeight="bold">{item.runnerName} </Text>
                <Text fontWeight="bold">{item.odds.toFixed(2)}</Text>
              </HStack>
              <Text>{`${isMB ? 'Mystery Bet' : item.marketName} - ${
                item.venueName
              } R${item.raceNumber}`}</Text>
            </Box>
          );
        }
        return (
          <Box key={item.marketName} borderBottom={'1px solid gray'} my={2}>
            <HStack justifyContent="space-between">
              <Text fontWeight="bold">{item.matchName} </Text>
              <Text fontWeight="bold">{item.odds.toFixed(2)}</Text>
            </HStack>
            <Text>{`${item.propositionName} - ${item.marketName}`}</Text>
          </Box>
        );
      })}
    </Box>
  );
}
