import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import { centsToDollars } from '@/common/utils';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';
import BetStatusBadge from '@/common/components/BetStatusBadge';

export const useEvenShotBetsColumns = () => {
  const columns = useMemo<ColumnDef<Api['EvenShotRaceRunnerStats']>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Name',
        cell: ({ row }) => {
          const {
            original: { barrier_number, number, display_name },
          } = row;
          return (
            <Text color="brand.500">
              {number}. {display_name} {barrier_number && `(${barrier_number})`}
            </Text>
          );
        },
      },
      {
        accessorKey: 'even_shot_bets',
        header: 'Bets',
        enableSorting: false,
        cell: ({ row }) => {
          const {
            original: { even_shot_bets },
          } = row;
          return <Flex flexDirection="column">{even_shot_bets}</Flex>;
        },
      },
      {
        header: 'Avg Stake',
        cell: ({ row }) => {
          const {
            original: { even_shot_hold, even_shot_bets },
          } = row;
          return <Box>{centsToDollars(even_shot_hold / even_shot_bets)}</Box>;
        },
      },
      {
        header: 'Stake Aggregate',
        cell: ({ row }) => {
          const {
            original: { even_shot_hold },
          } = row;
          return <Box>{centsToDollars(even_shot_hold)}</Box>;
        },
      },
      {
        header: 'Exposure',
        cell: ({ row }) => {
          const {
            original: { even_shot_liability },
          } = row;
          return <Box>{centsToDollars(even_shot_liability)}</Box>;
        },
      },
    ],
    []
  );

  const punterColumns = useMemo<ColumnDef<TMarketBet>[]>(
    () => [
      {
        header: 'Punter',
        cell: ({ row: { original } }) => {
          return <>{`${original.bet_punter_name}`}</>;
        },
      },
      {
        header: 'Bet Type',
        cell: ({
          row: {
            original: { proposition, price_type },
          },
        }) => {
          return (
            <>
              {`${proposition} - `}
              <Badge colorScheme="blackAlpha">
                {price_type?.replace('_', ' ')}
              </Badge>
            </>
          );
        },
      },
      {
        header: 'Status',
        cell: ({ row: { original } }) => (
          <BetStatusBadge status={original.bet_status} />
        ),
      },
      {
        header: 'Odds',
        cell: ({
          row: {
            original: { bet_odds },
          },
        }) => <>@{bet_odds?.toFixed(2)}</>,
      },
      {
        header: 'Stake',
        cell: ({
          row: {
            original: { bet_stake },
          },
        }) => <>{centsToDollars(bet_stake)}</>,
      },
      {
        header: 'Return',
        cell: ({
          row: {
            original: { potential_return },
          },
        }) => <>{centsToDollars(potential_return)}</>,
      },
    ],
    []
  );

  return { columns, punterColumns };
};
