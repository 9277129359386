import { useAuthContext } from '@/lib/firebase/AuthProvider';
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../api.keys';
import { queryAuthToken } from './authentication';

type TQueryAuthTokenHook = {
  key?: unknown[];
  options?: UseQueryOptions<unknown>;
};

export const useQueryAuthToken = ({
  key = [],
  options = {},
}: TQueryAuthTokenHook = {}) => {
  const { currentUser } = useAuthContext();
  const query = useQuery(
    [keys.authentication, ...key],
    () => queryAuthToken(),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
      refetchInterval: 600000,
      enabled: !!currentUser,
    }
  );

  return query;
};
