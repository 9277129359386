import React from 'react';
import { TByPunterCategory, TPunterCategory } from '../api/api';
import { Box, Flex, Text, Image, Skeleton, Icon } from '@chakra-ui/react';
import { ExchangeDollar } from '@styled-icons/remix-line/ExchangeDollar';
import { AccountBalanceWallet } from '@styled-icons/material-outlined/AccountBalanceWallet';

import Chart from 'react-apexcharts';
import { EPlayerType } from '@/lib/DBModels';
import { Motion } from '@/common/components';
import { generateDonutChartData, getPlayerIcon } from './config';
import { PercentageBadge } from './PercentageBadge';
import { centsToDollars } from '@/common/utils';

interface IPunterCategoryStatsProps {
  stats?: TPunterCategory[];
  punterBets?: TByPunterCategory[];
  isLoading: boolean;
}

export function PunterCategoryStats({
  stats,
  punterBets,
  isLoading,
}: IPunterCategoryStatsProps) {
  if (isLoading)
    return (
      <Box padding="6" h="400px" boxShadow="lg" bg="blackAlpha.600">
        <Skeleton />
      </Box>
    );

  if (!stats && !isLoading) return null;

  const { chartData, donutOptions } = generateDonutChartData(stats ?? []);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        p: '3',
        boxShadow:
          '0px 10px 20px 0px #000000, 0px 0px 5px 1px #094C8B inset, 0px 0px 0px 1px #36C4FF inset',
        span: { color: 'white !important' },
        '.apexcharts-legend': { justifyContent: 'center !important' },
      }}
    >
      <Chart
        options={donutOptions}
        series={chartData.series}
        type="donut"
        width="400"
        height="300"
      />
      <Flex w="full" gap="1" flexWrap="wrap" alignItems="flex-start">
        {stats?.map((stat, i) => {
          const profit = punterBets?.find(
            (pBet) => pBet.punter_category === stat.category
          )?.real_money.bets_placed_stake;

          const pnl = punterBets?.find(
            (pBet) => pBet.punter_category === stat.category
          )?.real_money.profit;

          return (
            <Flex
              key={stat.category}
              sx={{
                color: 'white',
                flex: '1 0 20%',
                mr: '1',
                mt: '1',
                p: '2',
                borderRadius: 'sm',
                height: '120px',
                boxShadow: 'md',
                bg: '#061f3ca8',
              }}
            >
              <Motion
                w="100%"
                initial={{ translateY: -40, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                transition={{
                  duration: 0.3,
                  ...(i < 20 && { delay: i * 0.2 }),
                }}
              >
                <Flex flexDir="row" gap="2">
                  <Image
                    src={getPlayerIcon(
                      (stat.category as EPlayerType) ?? EPlayerType.Newbie
                    )}
                    sx={{
                      boxSize: '14',
                      p: '1',
                    }}
                  />
                  <Box w="full">
                    <Flex w="full" justifyContent="space-between">
                      <Text
                        sx={{
                          fontWeight: '500',
                          fontSize: '14px',
                          mb: '1.5',
                          color: '#ccc',
                        }}
                      >
                        {stat.category} ({stat.stats.value})
                      </Text>
                      <PercentageBadge
                        value={stat.stats.percentage_change}
                        sx={{ ml: '1', h: 'fit-content' }}
                      />
                    </Flex>

                    {stat.stats.value === 0 ? (
                      <Text>N/A</Text>
                    ) : (
                      <Flex
                        alignItems="center"
                        sx={{
                          gap: '2',
                          mt: '2',
                          flexDirection: 'column',
                        }}
                      >
                        <Flex w="full" justifyContent="space-between">
                          <Text>
                            <Icon boxSize="6" mr="1" as={ExchangeDollar} />
                            {centsToDollars(profit?.value)}
                          </Text>
                          <PercentageBadge
                            value={profit?.percentage_change}
                            sx={{ h: 'fit-content' }}
                          />
                        </Flex>
                        <Flex w="full" justifyContent="space-between">
                          <Text color={pnl?.value > 0 ? '#29c770' : 'red.500'}>
                            <Icon
                              boxSize="6"
                              mr="1"
                              as={AccountBalanceWallet}
                            />
                            {centsToDollars(pnl?.value)}
                          </Text>

                          <PercentageBadge
                            value={pnl?.percentage_change}
                            sx={{ h: 'fit-content' }}
                          />
                        </Flex>
                      </Flex>
                    )}
                  </Box>
                </Flex>
              </Motion>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
