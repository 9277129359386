import React from 'react';
import { Icon, Menu, MenuButton } from '@chakra-ui/react';
import { UserProfileButton } from '../../Header.styles';
import { links } from './services/Menu.config';
import { TBookieProfile } from '@/api/profile/profile.types';
import { useMenu } from './services/Menu.hooks';
import { FormattedMessage } from 'react-intl';
import { BCMenuItem, BCMenuGroup, BCMenuList } from './styles/Menu.styles';

type TUserMenu = {
  profile: TBookieProfile;
};

export default function UserMenu({ profile }: TUserMenu) {
  const { handleLogout, navigate } = useMenu();
  if (!profile.first_name || !profile.last_name) return <></>;
  const fullName = `${profile.first_name} ${profile.last_name}`;

  return (
    <Menu>
      <MenuButton aria-label="Options">
        <UserProfileButton boxSize="9">
          {profile.first_name?.substring(0, 1)}
        </UserProfileButton>
      </MenuButton>
      <BCMenuList>
        <BCMenuGroup title={fullName}>
          {links.map((link) => (
            <BCMenuItem
              key={link.name}
              icon={<Icon boxSize="5" as={link.icon} />}
              sx={{ color: 'gray.500' }}
              onClick={() => (link.link ? navigate(link.link) : handleLogout())}
            >
              <FormattedMessage id={link.name} />
            </BCMenuItem>
          ))}
        </BCMenuGroup>
      </BCMenuList>
    </Menu>
  );
}
