/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { TTransaction } from './transactions.types';
import { queryTransactions } from './transactions';
import { TTransactionsParams } from './transactions.types';

// Regulator - Financial Transaction History

type TQueryTransactionsHook = {
  params?: TTransactionsParams;
  key?: unknown[];
  options?: UseQueryOptions<TTransaction[]>;
};

export const useQueryTransactions = ({
  params = {},
  key = [],
  options = {},
}: TQueryTransactionsHook = {}) => {
  const query = useQuery(
    [keys.transactions, ...Object.values(params), ...key],
    () => queryTransactions(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
