import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import {
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  Grid,
  GridItem,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import {
  centsToDollars,
  getStrings,
  oddsFormatted,
  betReturnCalculator,
} from '../../../../../../../../../common/utils';
import { useModals } from '../../../../Services/BetApprovals.hooks';
import Input from '../../../../../../../../../common/components/FormElements/Input';
import { TBetRequestStatusInput } from '../../../../Services/BetApprovals.types';
import { getEventNameFromBet } from '@/common/components/BetsTable/components/utils';
import { isWincore } from '../../..';

export default function Form() {
  const [
    {
      Generic: GenericStrings,
      BetApprovalsPage: {
        Modals: { CounterOffer: Strings },
      },
    },
  ] = getStrings();

  const { modalCounterBet } = useModals();

  const firstLeg = modalCounterBet?.betLegs[0] ?? undefined;
  const priceType = isWincore
    ? firstLeg?.price_type
    : modalCounterBet?.priceType;
  const hasFixedOdds =
    priceType !== 'starting' &&
    priceType !== 'tote_single_mid' &&
    priceType !== 'tote_single_best';

  const isMysteryBet = priceType === 'mystery_bet';
  const hasRollover =
    isMysteryBet && (modalCounterBet?.betLegs.length ?? 0) > 1;

  const isSettlement =
    modalCounterBet?.requestType?.toLowerCase() === 'settlement';

  const { isSubmitting, values: formValues } =
    useFormikContext<TBetRequestStatusInput>();

  const renderType = () => {
    switch (priceType) {
      case 'tote_single_mid':
        return 'MD';
      case 'starting':
        return 'SP';
      case 'tote_single_best':
        return 'BT';
      default:
        return modalCounterBet?.requestedOdds;
    }
  };

  const amount = formValues?.amount ?? 0;
  const odds = modalCounterBet?.requestedOdds ?? 0;
  const returnAmount = amount * (hasRollover ? odds * odds : odds);

  const values = [
    {
      title: GenericStrings.Bet,
      value: centsToDollars(modalCounterBet?.requestedStake, true),
      isVisible: !isSettlement,
    },
    {
      title: GenericStrings.Odds,
      value: hasFixedOdds
        ? oddsFormatted(modalCounterBet?.requestedOdds)
        : renderType(),
      isVisible: !isSettlement,
    },
    {
      title: GenericStrings.Return,
      value: hasFixedOdds ? (
        centsToDollars(
          !isSettlement
            ? betReturnCalculator(
                modalCounterBet?.requestedStake,
                hasRollover ? odds * odds : odds,
                modalCounterBet?.isBonusBet
              )
            : modalCounterBet?.requestedPayout,
          true
        )
      ) : (
        <FormattedMessage id="generic.na" />
      ),
      isVisible: true,
    },
  ];

  const InputLeftMoneySign = () => (
    <Text fontSize="md" color="gray.300">
      {GenericStrings.DollarSign}
    </Text>
  );

  return (
    <ModalContent>
      <ModalHeader textAlign="center">
        Send a partial offer on {getEventNameFromBet(modalCounterBet)}
      </ModalHeader>

      <ModalBody>
        <Grid
          templateColumns={`repeat(${isSettlement ? '1' : '3'}, 1fr)`}
          mb="8"
          gap="4"
          w="full"
        >
          {values.map(({ title, value, isVisible }) => {
            if (!isVisible) return <></>;
            return (
              <GridItem key={title}>
                <Text fontWeight="bold">{GenericStrings.Current}</Text>
                <Text fontWeight="bold" mb="2">
                  {title}
                </Text>
                <Text>{value}</Text>
              </GridItem>
            );
          })}
        </Grid>

        <Text fontWeight="bold" mb="4">
          {isSettlement ? 'Enter a partial payout' : 'Enter a counter stake'}
        </Text>

        <Input
          name="amount"
          type="number"
          fontSize="md"
          InputLeftElement={InputLeftMoneySign}
        />
      </ModalBody>

      {!!formValues.amount && !isSettlement && (
        <Text
          m="auto"
          w="fit-content"
          textAlign="center"
          px="5"
          py="1"
          borderRadius="sm"
        >
          Potential Return:{' '}
          <Text fontWeight="bold">
            $
            {!isSettlement
              ? (modalCounterBet?.isBonusBet
                  ? returnAmount - (formValues.amount ?? 0)
                  : returnAmount
                ).toFixed(2)
              : formValues.amount.toFixed(2)}
          </Text>
        </Text>
      )}
      <ModalFooter>
        <Button type="submit" isLoading={isSubmitting} sx={{ w: 'full' }}>
          {Strings.Button}
        </Button>
      </ModalFooter>

      <ModalCloseButton />
    </ModalContent>
  );
}
