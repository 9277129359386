import React from 'react';
import { UserBadge, PlayerIcon } from './PunterInfo.styles';
import { Flex, Link, Text } from '@chakra-ui/react';
import { usePunterCategory } from '@/hooks/usePunterCategory';
import { PunterCategories } from '@/api/api.schema.d';

type TPunterInfo = {
  punter_name: string;
  punter_category: Api['PunterCategories'];
  id?: string;
};
const PunterInfo = ({ punter_name, punter_category, id }: TPunterInfo) => {
  const punterCategory = punter_category ?? PunterCategories[0];

  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory();

  return (
    <>
      <Link
        textDecoration="underline"
        fontWeight="bold"
        href={`/punter/${id ?? ''}/bets`}
      >
        {punter_name}
      </Link>
      <Flex alignItems="center">
        <UserBadge bg={getPunterCategoryColor(punterCategory)}>
          <PlayerIcon color="white" src={getPlayerIcon(punterCategory)} />
        </UserBadge>
        <Text fontSize="md">{punterCategory}</Text>
      </Flex>
    </>
  );
};

export default PunterInfo;
