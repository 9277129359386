import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Icon, HStack, Flex, Text, Switch } from '@chakra-ui/react';
import { Button } from 'grommet';
import { TriangleRight } from '@styled-icons/entypo/TriangleRight';
import toast from 'react-hot-toast';
import { errorMessageFromHTTPException } from '@/lib/Error';

import { centsToDollars, getStrings } from '../../../../../../common/utils';
import { getPropositionsForMarket } from '../../api';
import { MARGIN_TICK_MAP } from '../../../../../../lib/Constants';

import BetsForProposition from '../../../../components/BetsForProposition';

import {
  OddsSettingsTable,
  Separator,
  UpdateableMarginControls,
} from '../../../../../../common/components';
import Select from '../../../../../../common/components/FormElements/Select';
import {
  ExposureTD,
  FlucText,
  BetOutcome,
  SwitchWrapper,
} from './PropositionDetails.styles';

import {
  EGeneralStatus,
  TMarket,
  TMatch,
  TProposition,
} from '../../../../../../lib/DBModels';
import { AppThunkDispatch } from '../../../../../../app/types';

import { useMarketModifiers } from '../../services/MatchDetails.hooks';
import EventExposure from '../../../../../../common/components/EventExposure';
import { EStatusOptions } from '../../components/ModalConfigure/components/Status/Status.types';
import { getStatus } from '../../components/ModalConfigure/components/Status/services/Status.utils';
import { EEventType } from '../../../RaceDetails/Services/RaceDetails.types';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import { useMatchConfig } from '../../services/MatchDetails.hooks';
import { getEGeneralStatus } from '../../services/MatchDetails.utils';
import { TMatchStatus } from '@/api/tradeManager/sportDetails/matchStatus/matchStatus.types';
import { convertToUSOdds } from '@/lib/ConverToUSOdds';
import { isSweeps } from '@/configs/brands.config';

type TMarketDetails = {
  proposition: TProposition | undefined;
  market: TMarket;
  selectedMarket: TMarket | undefined;
  matchData: TMatch | undefined;
  marketDropDownId: string | undefined;
  matchStatusState: EGeneralStatus | undefined;
};

export default function PropositionDetails({
  proposition,
  market,
  selectedMarket,
  matchData,
  marketDropDownId,
  matchStatusState,
}: TMarketDetails) {
  const allowedStatus = [
    EGeneralStatus.Open,
    EGeneralStatus.Closed,
    EGeneralStatus.Voided,
    ...(matchData?.sport_name === 'Racing Futures' ||
    matchData?.sport_name === 'Specials'
      ? [EGeneralStatus.Removed]
      : []),
  ];
  const [
    {
      TradeManagerPage: { MatchDetailsText },
    },
  ] = getStrings();
  const dispatch: AppThunkDispatch = useDispatch();
  const [punterDropDownId, setPunterDropDownId] = useState<string>();
  const [followFeedState, setFollowFeedState] = useState(
    !proposition?.lock_status
  );
  const [suspendedState, setSuspendedState] = useState(
    proposition?.is_suspended
  );
  const [statusState, setStatusState] = useState(
    matchData?.status === 'Closed' && proposition?.status === 'Open'
      ? EGeneralStatus.Closed
      : proposition?.status
  );
  const {
    handleChangeMarketPropositionModifier,
    handleSubmitPropositionModifier,
    ellipsisLoaderId,
    getMarketProposition,
  } = useMarketModifiers();
  const { updateMatch } = useMatchConfig();

  const getUpdatedPropositions = useCallback(
    async (market: TMarket) => {
      try {
        await dispatch(
          getPropositionsForMarket({
            marketId: market.market_id ?? '',
            isUpdate: true,
          })
        );
      } catch (e) {
        toast.error(errorMessageFromHTTPException(e));
      }
      getMarketProposition(market);
    },
    [dispatch, getMarketProposition]
  );

  const handlePunterDropdown = useCallback(
    (id: string | undefined) => {
      if (!id) return;
      setPunterDropDownId(id === punterDropDownId ? undefined : id);
    },
    [punterDropDownId, setPunterDropDownId]
  );

  const handleFeedChange = () => {
    const matchToUpdate: TMatchStatus = {
      propositions: [
        {
          id: proposition?.proposition_id || '',
          lock_status: followFeedState,
        },
      ],
    };
    if (!followFeedState) {
      matchToUpdate.match = {
        id: matchData?.match_id || '',
        lock_status: followFeedState,
      };
      matchToUpdate.markets = [
        {
          id: selectedMarket?.market_id || '',
          lock_status: followFeedState,
        },
      ];
    }
    updateMatch({
      matchUpdateData: matchToUpdate,
    });
    setFollowFeedState((followFeed) => !followFeed);
  };
  const handleSuspendedChange = () => {
    const matchToUpdate: TMatchStatus = {
      propositions: [
        {
          id: proposition?.proposition_id || '',
          is_suspended: !suspendedState,
          lock_price: !suspendedState,
        },
      ],
    };
    updateMatch({
      matchUpdateData: matchToUpdate,
    });
    setSuspendedState((suspended) => !suspended);
  };
  const handleStatusChange = (status: string) => {
    const matchToUpdate: TMatchStatus = {
      propositions: [
        {
          id: proposition?.proposition_id || '',
          status: getEGeneralStatus(status),
          lock_status: true,
        },
      ],
    };
    updateMatch({
      matchUpdateData: matchToUpdate,
    });
    setFollowFeedState(false);
    setStatusState(getEGeneralStatus(status));
  };

  const rawWinterReturnAmount = useCallback(
    (propId: string, is_us_odds: boolean) => {
      if (!selectedMarket && !propId) return <></>;

      const relatedProposition = selectedMarket?.raw_market_propositions?.find(
        (el) => el?.proposition_id === propId
      );

      const rawPrice = relatedProposition?.return_amount ?? 0 * 100;

      if (is_us_odds) return <>{convertToUSOdds(rawPrice)}</>;

      return <>{rawPrice.toFixed(2)}</>;
    },
    [selectedMarket]
  );

  useEffect(() => {
    if (
      matchStatusState === EGeneralStatus.Closed &&
      proposition?.status === 'Open'
    ) {
      setStatusState(matchStatusState);
    }
    if (matchStatusState === EGeneralStatus.Open) {
      setStatusState(proposition?.status);
    }
  }, [proposition?.status, matchStatusState]);

  return (
    <tbody key={`tbody-${proposition?.proposition_id}`}>
      <tr>
        <td>
          <Flex alignItems="center">
            <Flex alignItems="center" justify="space-between" width="90%">
              <Flex alignItems="center" ml="2">
                <Text fontWeight="bold" mr="2">
                  {proposition?.proposition_name ?? ''}
                </Text>

                {proposition?.status === EGeneralStatus.Settled && (
                  <BetOutcome as="span" outcome={proposition?.outcome}>
                    {proposition?.outcome}
                  </BetOutcome>
                )}
                {proposition?.status && (
                  <StatusBadge
                    status={proposition?.status}
                    isSuspended={proposition?.is_suspended}
                    isManualOverride={
                      getStatus([market]) !== EStatusOptions.Winter
                    }
                    size="sm"
                    sx={{
                      '&&': {
                        mb: '0',
                        ml: '1.5',
                        ...(getStatus([market]) !== EStatusOptions.Winter && {
                          mt: '1',
                        }),
                      },
                    }}
                  />
                )}
              </Flex>

              <Flex alignItems="center">
                <Select
                  isFormik={false}
                  name="status-dropdown"
                  value={
                    statusState && !allowedStatus.includes(statusState)
                      ? EGeneralStatus.Closed
                      : statusState
                  }
                  onChange={(e) => handleStatusChange(e.target.value)}
                  sxWrapper={{
                    maxW: '170px',
                  }}
                  sx={{
                    bg: 'white',
                    w: 'full',
                    h: 'full',
                    p: '3',
                  }}
                >
                  {allowedStatus.map((status: EGeneralStatus, idx: number) => (
                    <option key={idx} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>
                <SwitchWrapper>
                  <Text>
                    <FormattedMessage id="trademanagerpage.common.followFeedStatus" />
                  </Text>
                  <Switch
                    isChecked={followFeedState}
                    onChange={handleFeedChange}
                  />
                </SwitchWrapper>
                <SwitchWrapper>
                  <Text>
                    <FormattedMessage id="trademanagerpage.racedetails.configRunner.suspended" />
                  </Text>
                  <Switch
                    isChecked={suspendedState}
                    onChange={handleSuspendedChange}
                  />
                </SwitchWrapper>
              </Flex>
            </Flex>
          </Flex>
          <Button
            onClick={() => handlePunterDropdown(proposition?.proposition_id)}
          >
            <HStack align="center" mt="1">
              <Icon
                as={TriangleRight}
                sx={{
                  color: 'brand.500',
                  boxSize: '4',
                  transform: `rotate(${
                    punterDropDownId === proposition?.proposition_id
                      ? '90deg'
                      : '0'
                  })`,
                }}
              />
              <FlucText fontSize="14px">
                {MatchDetailsText.ViewIndividualBets}
              </FlucText>
            </HStack>
          </Button>
        </td>
        <td className="no-padding">
          <OddsSettingsTable cellPadding={4} cellSpacing={3}>
            <tbody>
              <tr>
                <td>
                  <Flex>
                    {isSweeps && (
                      <Flex
                        direction="column"
                        alignItems="center"
                        sx={{ marginRight: '2' }}
                      >
                        <FlucText fontSize="14px">
                          {MatchDetailsText.US}
                        </FlucText>
                        <FlucText fontSize="18px">
                          {rawWinterReturnAmount(
                            proposition?.proposition_id ?? '',
                            true
                          )}
                        </FlucText>
                      </Flex>
                    )}
                    <Flex
                      direction="column"
                      alignItems="center"
                      sx={{ marginRight: '2' }}
                    >
                      <FlucText fontSize="14px">
                        {MatchDetailsText.Current}
                      </FlucText>
                      <FlucText fontSize="18px">
                        {rawWinterReturnAmount(
                          proposition?.proposition_id ?? '',
                          false
                        )}
                      </FlucText>
                    </Flex>

                    <UpdateableMarginControls
                      value={
                        proposition?.proposition_modifier?.display_modifier ?? 0
                      }
                      differenceValue={
                        proposition?.proposition_modifier?.update_value ?? 0
                      }
                      marginType="single"
                      onUpdate={(prevVal) => {
                        handleSubmitPropositionModifier(
                          prevVal,
                          proposition?.proposition_id
                        ).catch(undefined);
                        getUpdatedPropositions(market ?? undefined).catch(
                          undefined
                        );
                      }}
                      tickUpDisabled={
                        (proposition?.proposition_modifier
                          ?.original_tick_index ?? 0) +
                          (proposition?.proposition_modifier
                            ?.display_modifier ?? 0) +
                          1 >=
                          MARGIN_TICK_MAP.length || !proposition
                      }
                      onTickUp={(prevVal, newVal) =>
                        handleChangeMarketPropositionModifier(
                          prevVal,
                          newVal,
                          market.market_id,
                          proposition?.proposition_id
                        )
                      }
                      tickDownDisabled={
                        (proposition?.proposition_modifier
                          ?.original_tick_index ?? 0) +
                          (proposition?.proposition_modifier
                            ?.display_modifier ?? 0) -
                          1 <
                          0 || !proposition
                      }
                      onTickDown={(prevVal, newVal) =>
                        handleChangeMarketPropositionModifier(
                          prevVal,
                          newVal,
                          market.market_id,
                          proposition?.proposition_id
                        )
                      }
                      loading={ellipsisLoaderId === proposition?.proposition_id}
                    />
                  </Flex>
                </td>
              </tr>
            </tbody>
          </OddsSettingsTable>
        </td>
        <td align="center" className="highlight">
          {proposition?.proposition_stats?.total_bets ?? ''}
        </td>
        <td align="center">
          {centsToDollars(
            proposition?.proposition_stats?.total_staked,
            true,
            isSweeps
          )}
        </td>
        <td align="center">
          {centsToDollars(
            proposition?.proposition_stats?.biggest_bet,
            true,
            isSweeps
          )}
        </td>
        <td align="center">
          {`${isSweeps ? 'BC$' : '$'}${
            proposition?.proposition_stats?.average_price?.toFixed(2) ?? ''
          }`}
        </td>
        {/* Temp until this is moved to styled table & chakra! */}
        <ExposureTD align="center">
          <EventExposure
            exposure={proposition?.proposition_stats?.exposure}
            isSweeps={isSweeps}
          />
        </ExposureTD>
      </tr>
      {punterDropDownId === proposition?.proposition_id &&
        !!marketDropDownId && (
          <th colSpan={8}>
            <BetsForProposition
              type={EEventType.Match}
              marketId={marketDropDownId ?? ''}
              marketPropositionId={proposition?.proposition_id}
            />
            <Separator />
          </th>
        )}
    </tbody>
  );
}
