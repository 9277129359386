import { useState } from 'react';
import { useQueryPromoPuntersCSV } from '@/api/promos/promo/promoPuntersCSV.hooks';
import { TQueryPromoPuntersCSVResponse } from '@/api/promos/promo/promoPuntersCSV.types';
import { downloadFile } from '@/common/utils';
import { DownloadCSVButtonProps } from './DownloadCSVButton.types';

export function useDownloadCSV({ promoId, filename }: DownloadCSVButtonProps) {
  const [enabled, setEnabled] = useState(false);
  const { data, isLoading } = useQueryPromoPuntersCSV(
    { promo_id: promoId },
    {
      enabled,
      onSuccess: ({ data }) => {
        downloadCSVFile(data);
        setEnabled(false);
      },
    }
  );

  const downloadCSVFile = (data: TQueryPromoPuntersCSVResponse) => {
    const file = new File([data], `${filename}.csv`, { type: 'text/csv' });
    downloadFile(file);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSV = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e.stopPropagation();
    if (enabled && data?.data) {
      downloadCSVFile(data.data);
    } else {
      setEnabled(true);
    }
  };

  return { isLoading, downloadCSV };
}
