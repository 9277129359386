import { chakra, Flex } from '@chakra-ui/react';
import { Text } from 'grommet';
import styled from 'styled-components';

export const HeaderLabel = styled(Text)`
  display: flex;
  padding: 10px 0;
  color: ${({ theme }) => theme.global.colors.blueGreyBlack};
`;

export const PlainSelectContainer = chakra(Flex, {
  baseStyle: () => ({
    px: '1',
    borderRadius: 'lg',
    bg: 'white',
    borderWidth: '1px',
    borderColor: 'gray.300',
    color: 'gray.600',
  }),
});
