import {
  chakra,
  ChakraComponent,
  Spinner,
  SpinnerProps,
  Switch,
  SwitchProps,
  TextProps,
  Text,
} from '@chakra-ui/react';

export const SwitchConfig = chakra(Switch, {
  baseStyle: () => ({
    size: 'lg',
    mr: '3',
    ml: 'auto',
  }),
}) as ChakraComponent<'label', SwitchProps>;

export const SpinnerConfig = chakra(Spinner, {
  baseStyle: () => ({
    size: 'lg',
    mr: '3',
  }),
}) as ChakraComponent<'label', SpinnerProps>;

export const TextConfig = chakra(Text, {
  baseStyle: () => ({
    whiteSpace: 'nowrap',
    mr: '2',
    cursor: 'default',
  }),
}) as ChakraComponent<'p', TextProps>;
