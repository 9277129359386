import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { usePunterTabs } from './services/Punter.config';
import Tabs from '@/common/components/Tabs/Tabs';

export default function PunterWrapper() {
  const { updatedTabs } = usePunterTabs();

  return (
    <>
      <Flex direction="row" justify="start">
        <Tabs tabs={updatedTabs} />
      </Flex>

      <Outlet />
    </>
  );
}
