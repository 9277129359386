import { useQueryPunterCategoryList } from '@/api/punter/categories/punterCategories.hooks';

export const usePunterCategoriesObject = () => {
  const { data } = useQueryPunterCategoryList();
  const punterCategoriesObject = Object.fromEntries(
    data?.map(({ id, name }) => [id, name]) ?? []
  );

  return punterCategoriesObject;
};
