import { useState } from 'react';
import toast from 'react-hot-toast';
import { queryPropositionForMarket } from '../../../../../api/tradeManager/sportDetails/propositionsForMarket';
import { useQuery } from '../../../../../common/hooks/useQuery';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../common/hooks/useRedux';
import { deepClone, getStrings, logError } from '../../../../../common/utils';
import { TMarket, TMatch } from '../../../../../lib/DBModels';
import {
  updateSportMarketModifier,
  updateSportMatchModifier,
  updateSportPropositionModifier,
} from '../actions';
import { getMarkets } from '../api';
import { setMatchData, setMatchMarkets, setSelectedMarket } from '../slices';
import {
  getMarketUpdateValue,
  addPropositionModifier,
} from './MatchDetails.utils';
import { errorMessageFromHTTPException } from '@/lib/Error';
import { useMutationMatchStatus } from '@/api/tradeManager/sportDetails/matchStatus/matchStatus.hooks';
import {
  TUpdateMatchEntity,
  TMatchStatus,
} from '@/api/tradeManager/sportDetails/matchStatus/matchStatus.types';

export const useMarketModifiers = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();

  const queryMatchId = query.get('id');

  const [{ Generic }] = getStrings();

  const { matchData, matchMarkets, selectedMarketGroup, selectedMarket } =
    useAppSelector((state) => state.matchDetails);

  const [ellipsisLoaderId, setEllipsisLoaderId] = useState('');

  const getMarketProposition = (market: TMarket) => {
    (async () => {
      /** If market propositions have already been fetched, return */
      if (
        matchMarkets?.some(
          (matchMarket: TMarket) => 'market_proposition' in matchMarket
        ) ||
        !matchMarkets.length ||
        !market
      )
        return;

      const matchMarketWithProposition = await Promise.all(
        matchMarkets
          .filter((el) => el.market_id === market.market_id)
          .map(async (matchMarket) => {
            if (!matchMarket.market_id) return matchMarket;
            const { propositions: marketPropositions } =
              await queryPropositionForMarket({
                market_id: matchMarket.market_id,
              });

            const marketPropositionsWithModifier =
              addPropositionModifier(marketPropositions);
            const marketUpdateValue = getMarketUpdateValue(
              marketPropositionsWithModifier
            );

            return {
              ...matchMarket,
              update_value: marketUpdateValue,
              raw_market_propositions: marketPropositions,
              market_proposition: marketPropositionsWithModifier,
            };
          })
      );

      dispatch(setSelectedMarket(matchMarketWithProposition[0]));
    })().catch(logError);
  };

  const handleChangeMarketModifier = (
    prevModifier: number,
    newModifier: number,
    marketId: string
  ) => {
    const newMatchMarkets = deepClone(matchMarkets) as TMarket[];

    const marketToEdit = newMatchMarkets.find(
      (mod) => mod.market_id === marketId
    );

    const insertIndex = newMatchMarkets.findIndex(
      (mod) => mod.market_id === marketId
    );

    if (
      !marketToEdit ||
      marketToEdit?.modifiers?.modifier === undefined ||
      !selectedMarket
    ) {
      toast.error(Generic.Error);
      return;
    }

    const listWithoutEditedMarket = newMatchMarkets.filter(
      (mod) => mod.market_id !== marketId
    );

    // update display value to newValue from margin control tick handler
    marketToEdit.modifiers.display_modifier = newModifier;

    // update modifier to tick direction
    marketToEdit.modifiers.modifier += newModifier > prevModifier ? 1 : -1;

    listWithoutEditedMarket.splice(insertIndex, 0, marketToEdit);

    dispatch(setMatchMarkets(listWithoutEditedMarket));
  };

  // Submission function for update button on market modifier
  const handleSubmitMarketModifier = async (marketId: string) => {
    if (!matchMarkets || !queryMatchId) return;

    const currentMarket = matchMarkets?.find(
      (market) => market.market_id === marketId
    );

    if (!currentMarket || currentMarket?.modifiers?.modifier === undefined) {
      toast.error(Generic.Error);
      return;
    }

    setEllipsisLoaderId(marketId);

    await dispatch(
      updateSportMarketModifier({
        marketId,
        newModifier: currentMarket.modifiers.modifier,
      })
    );

    if (selectedMarketGroup?.market_group_id && queryMatchId) {
      await dispatch(
        getMarkets({
          matchId: queryMatchId,
          marketGroupId: selectedMarketGroup?.market_group_id,
        })
      );
    }

    setEllipsisLoaderId('');
  };

  const handleChangeMarketPropositionModifier = (
    prevModifier: number,
    newModifier: number,
    marketId: string | undefined,
    propositionId: string | undefined
  ) => {
    if (!propositionId || !marketId) return;

    const newMatchMarkets = deepClone(matchMarkets) as TMarket[];

    const marketToEdit = newMatchMarkets.find(
      (mod) => mod.market_id === marketId
    );

    if (!marketToEdit || !selectedMarket?.market_proposition?.length) {
      toast.error(Generic.Error);
      return;
    }
  };

  // Submission function for update button on proposition modifiers
  const handleSubmitPropositionModifier = async (
    newModifier: number | undefined,
    propositionId: string | undefined
  ) => {
    if (newModifier === undefined || !propositionId) {
      toast.error(Generic.Error);
      return;
    }

    setEllipsisLoaderId(propositionId);
    await dispatch(
      updateSportPropositionModifier({ propositionId, newModifier })
    );

    if (selectedMarketGroup?.market_group_id && queryMatchId) {
      await dispatch(
        getMarkets({
          matchId: queryMatchId,
          marketGroupId: selectedMarketGroup?.market_group_id,
        })
      );
    }
    setEllipsisLoaderId('');
  };

  // On tick function for Sport modifier
  const handleChangeMatchModifier = (
    prevModifier: number,
    newModifier: number
  ) => {
    const updatedMatchData = deepClone(matchData) as TMatch;

    if (
      !updatedMatchData ||
      !updatedMatchData?.modifier ||
      updatedMatchData?.modifier?.modifier === undefined ||
      updatedMatchData?.modifier?.display_modifier === undefined
    ) {
      toast.error(Generic.Error);
      return;
    }

    updatedMatchData.modifier.display_modifier = newModifier;

    if (newModifier > prevModifier) {
      updatedMatchData.modifier.modifier += 1;
    } else if (prevModifier > newModifier) {
      updatedMatchData.modifier.modifier -= 1;
    }

    const updatedMatchMarkets = matchMarkets.map((market) => {
      const newMarket = deepClone(market) as TMarket;

      if (
        !newMarket ||
        newMarket?.modifiers?.modifier === undefined ||
        newMarket?.modifiers?.display_modifier === undefined
      ) {
        toast.error(Generic.Error);
        return market;
      }

      newMarket.modifiers.display_modifier +=
        newModifier > prevModifier ? 1 : -1;

      // update modifier to tick direction
      newMarket.modifiers.modifier += newModifier > prevModifier ? 1 : -1;

      return newMarket;
    });

    dispatch(setMatchData(updatedMatchData));

    dispatch(setMatchMarkets(updatedMatchMarkets));
  };

  // Submission function for update button on Sport modifier
  const handleSubmitMatchModifier = async () => {
    if (
      !matchData?.modifier ||
      matchData?.modifier?.modifier === undefined ||
      !queryMatchId
    ) {
      toast.error(Generic.Error);
      return;
    }

    setEllipsisLoaderId('matchModifier');

    await dispatch(
      updateSportMatchModifier({
        matchId: queryMatchId,
        newModifier: matchData.modifier.modifier,
      })
    );

    setEllipsisLoaderId('');

    if (queryMatchId && selectedMarketGroup?.market_group_id) {
      await dispatch(
        getMarkets({
          matchId: queryMatchId,
          marketGroupId: selectedMarketGroup?.market_group_id,
        })
      );
    }
  };

  return {
    /* Match Level */
    handleChangeMatchModifier,
    handleSubmitMatchModifier,

    /* Market Level */
    handleChangeMarketModifier,
    handleSubmitMarketModifier,

    /* Proposition Level */
    handleChangeMarketPropositionModifier,
    handleSubmitPropositionModifier,

    ellipsisLoaderId,
    getMarketProposition,
  };
};

export const useMatchConfig = () => {
  const { mutateAsync } = useMutationMatchStatus();
  const getMsg = (e: TUpdateMatchEntity) => {
    if (e.status !== undefined) {
      return 'Successfully updated status';
    }
    if (e.lock_status !== undefined) {
      return e.lock_status
        ? 'Successfully locked status'
        : 'Successfully unlocked status';
    }
    if (e.is_suspended !== undefined) {
      return e.is_suspended
        ? 'Successfully suspended'
        : 'Successfully unsuspended';
    }
    if (e.lock_settlement !== undefined) {
      return e.lock_settlement
        ? 'Successfully locked settlement'
        : 'Successfully unlocked settlement';
    }
    if (e.lock_price !== undefined) {
      return e.lock_price
        ? 'Successfully locked price'
        : 'Successfully unlocked price';
    }
    return 'Successfully updated match';
  };
  const handleMsgReturn = (matchUpdateData: TMatchStatus) => {
    if (matchUpdateData.match) {
      return getMsg(matchUpdateData.match);
    }
    if (matchUpdateData.markets) {
      return getMsg(matchUpdateData.markets[0]);
    }
    if (matchUpdateData.propositions) {
      return getMsg(matchUpdateData.propositions[0]);
    }
    return 'Successfully updated match';
  };

  const updateMatch = async ({
    matchUpdateData,
  }: {
    matchUpdateData: TMatchStatus;
  }) => {
    if (!matchUpdateData) return;

    try {
      await mutateAsync({
        match: matchUpdateData.match,
        markets: matchUpdateData.markets,
        propositions: matchUpdateData.propositions,
      });
      toast.success(handleMsgReturn(matchUpdateData));
    } catch (error) {
      toast.error(errorMessageFromHTTPException(error));
    }
  };

  return {
    updateMatch,
  };
};
