import { Tooltip } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useQueryPromoSummary } from '@/api/promoSummary/promoSummary.hooks';
import { useTablePagination } from '@/hooks/useTablePagination';
import DownloadCSVButton from './components/DownloadCSVButton/DownloadCSVButton';
import { ImportCSVModalForm } from './components/ImportCSVModalForm/ImportCSVModalForm';
import { PAGE_SIZES, Table } from './components/Table/Table';
import { array, object, string, number } from 'yup';
import {
  TMutatePromosBody,
  TPromoPunter,
} from '@/api/bulkPromoUpload/bulkPromoUpload.types';
import { ExampleCSVDepositMatchTable } from './components/ExampleCSVTable/ExampleCSVDepositMatchTable';

const DEFAULT_PAGE_SIZE: (typeof PAGE_SIZES)[number] = 20;

export const DepositMatch = () => {
  const intl = useIntl();

  const { pagination, handlePaginationChange } = useTablePagination({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const parsePromos = (values: TPromoPunter[]): TPromoPunter[] =>
    values.map((punter) => ({
      ...punter,
      percentage: (punter.percentage ?? 1) / 100,
      max_amount: (punter.max_amount ?? 0) * 100,
    }));

  const validate = async (values: TMutatePromosBody) => {
    const errors: Partial<Record<keyof TMutatePromosBody, string>> = {};

    try {
      const schema = array(
        object({
          punter_id: string().required().uuid(),
          percentage: number().required().min(1).max(100),
          max_amount: number().required().min(1).integer(),
        })
      ).min(1);
      await schema.validate(values.promo_punters);
    } catch ({ errors: [error] }) {
      errors.promo_punters = typeof error === 'string' ? error : undefined;
    }

    try {
      const schema = string()
        .label(intl.formatMessage({ id: 'promoManagerPage.nameErrorLabel' }))
        .required();
      await schema.validate(values.name);
    } catch ({ errors: [error] }) {
      errors.name = typeof error === 'string' ? error : undefined;
    }

    return errors;
  };

  const { data, isError, isFetching, isLoading, refetch } =
    useQueryPromoSummary(
      {
        promo_type: 'deposit_match',
        limit: pagination.pageSize,
        offset: pagination.offset,
      },
      {
        keepPreviousData: true,
      }
    );

  const columns = useMemo<ColumnDef<Api['PromoSummaryResponseItem']>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: () => (
          <FormattedMessage id="promomanagerpage.nametableheader" />
        ),
      },
      {
        id: 'reason',
        accessorKey: 'reason',
        header: () => (
          <FormattedMessage id="promomanagerpage.reasontableheader" />
        ),
      },
      {
        id: 'filename',
        accessorKey: 'file_name',
        header: () => (
          <FormattedMessage id="promomanagerpage.filenametableheader" />
        ),
      },
      {
        id: 'potentialValue',
        accessorKey: 'max_value',
        header: () => (
          <FormattedMessage id="promomanagerpage.potentialvaluetableheader" />
        ),
        cell: ({ row }) => {
          const { max_value, total_punters: totalPunters } = row.original;
          const maxValue = intl.formatNumber(max_value / 100, {
            style: 'currency',
            currency: 'AUD',
          });
          const values = { maxValue, totalPunters };

          return (
            <Tooltip
              label={
                <FormattedMessage
                  id="promomanagerpage.potentialvaluetooltip"
                  values={values}
                />
              }
            >
              <span>
                <FormattedMessage
                  id="promomanagerpage.potentialvaluecell"
                  values={{
                    ...values,
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </span>
            </Tooltip>
          );
        },
      },
      {
        id: 'claimedAmount',
        accessorKey: 'claimed_amount',
        header: () => (
          <FormattedMessage id="promoManagerPage.claimedAmountTableHeader" />
        ),
        cell: ({ row }) => {
          const { claimed_amount, claimed_punters: claimedPunters } =
            row.original;

          if (!claimed_amount) {
            return null;
          }

          const claimedAmount = intl.formatNumber(claimed_amount / 100, {
            style: 'currency',
            currency: 'AUD',
          });
          const values = { claimedAmount, claimedPunters };

          return (
            <Tooltip
              label={
                <FormattedMessage
                  id="promoManagerPage.claimedAmountTooltip"
                  values={values}
                />
              }
            >
              <span>
                <FormattedMessage
                  id="promoManagerPage.claimedAmountCell"
                  values={{
                    ...values,
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </span>
            </Tooltip>
          );
        },
      },
      {
        id: 'startDate',
        accessorKey: 'promotion_date',
        header: () => (
          <FormattedMessage id="promoManagerPage.startDateTableHeader" />
        ),
        cell: (info) => (
          <time dateTime={info.getValue<string>()}>
            <FormattedDate value={info.getValue<string>()} />
          </time>
        ),
      },
      {
        id: 'endDate',
        accessorKey: 'expiry',
        header: () => (
          <FormattedMessage id="promoManagerPage.endDateTableHeader" />
        ),
        cell: (info) => {
          const value = info.getValue<string | null>();

          if (!value) {
            return null;
          }

          return (
            <time dateTime={value}>
              <FormattedDate value={value} />
            </time>
          );
        },
      },
      {
        accessorKey: 'promo_id',
        header: () => (
          <FormattedMessage id="promoManagerPage.actionsTableHeader" />
        ),
        cell: ({ row }) => {
          const { promo_id, file_name } = row.original;
          return <DownloadCSVButton promoId={promo_id} filename={file_name} />;
        },
      },
    ],
    [intl]
  );

  const { promotions, offset: nextOffset } = data?.data ?? {};
  const state = { pagination: { ...pagination, offset: nextOffset } };

  return (
    <>
      <Table
        data={promotions ?? []}
        columns={columns}
        state={state}
        isError={isError}
        isFetching={isFetching}
        isLoading={isLoading}
        onPaginationChange={handlePaginationChange}
      />
      <ImportCSVModalForm
        promoType="deposit_match"
        validate={validate}
        parsePromos={parsePromos}
        onSuccess={refetch}
      >
        <ExampleCSVDepositMatchTable />
      </ImportCSVModalForm>
    </>
  );
};
