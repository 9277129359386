import styled from 'styled-components';
import { Button } from 'grommet';

export const ClearSelectionsButton = styled(Button)`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.global.colors.seperatorGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

export const MultiSelectActionWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  width: calc(100% - 102px);
  height: 4rem;
  min-height: 4rem;
  padding: 0 1.5rem;
  margin-left: 2px;
  border-top: 1px solid ${({ theme }) => theme.global.colors.seperatorGrey};
  background-color: ${({ theme }) => theme.global.colors.white};
  bottom: ${({ visible }) => (visible ? '0' : '-4rem')};
  transition: all 0.2s ease-in-out;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectedRecordsText = styled.p`
  margin-right: 1.5rem;
`;

export const MultiSelectActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  flex: 3;
`;
