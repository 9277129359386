import { StyleFunctionProps, theme } from '@chakra-ui/react';

export const Alert = {
  parts: ['container', 'title'],
  baseStyle: {
    title: {
      fontWeight: 'normal',
    },
    container: {
      borderRadius: 'md',
    },
  },
  variants: {
    subtle: (props: StyleFunctionProps) => ({
      ...theme.components.Alert.variants?.subtle(props),
      container: {
        background: (() => {
          switch (props.status) {
            case 'info':
              return 'gray.100';
            case 'warning':
              return 'orange.100';
            case 'error':
              return 'pink.100';
            default:
              return 'green.100';
          }
        })(),
      },
    }),
  },
};
