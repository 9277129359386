import { Button, ModalFooter } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../../../../common/hooks/useRedux';
import { getStrings } from '../../../../../../../../common/utils';
import { setIsConfigureRaceModalOpen } from '../../../../Services/RaceDetails.slices';
import { TSettleFormValues } from '../Types';

export default function Footer() {
  const dispatch = useAppDispatch();
  const { isSubmitting } = useFormikContext<TSettleFormValues>();

  const [{ Generic }] = getStrings();

  return (
    <ModalFooter alignItems="flex-end">
      <Button
        variant="outline-rounded"
        colorScheme="gray"
        borderRadius="lg"
        onClick={() => dispatch(setIsConfigureRaceModalOpen(false))}
        disabled={isSubmitting}
      >
        {Generic.Cancel}
      </Button>

      <Button
        type="submit"
        borderRadius="lg"
        variant="rounded"
        ml="4"
        isLoading={isSubmitting}
      >
        <FormattedMessage id="generic.update" />
      </Button>
    </ModalFooter>
  );
}
