import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddMarketGroupBody } from './addMarketGroup.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const addMarketGroupURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/add-market-group`;

// Trade Manager - Add Market Group

export const mutateAddMarketGroup = (body: TAddMarketGroupBody) =>
  api
    .post<TMarketCreationResponse>(addMarketGroupURL, body)
    .then((res) => res.data);
