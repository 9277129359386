import { useMutation, UseMutationOptions } from 'react-query';
import { mutateCloseProposition } from './closeProposition';
import {
  TClosePropositionBody,
  TClosePropositionResponse,
} from './closeProposition.types';

export const useMutationCloseProposition = (
  options?: Omit<
    UseMutationOptions<
      TClosePropositionResponse,
      unknown,
      TClosePropositionBody
    >,
    'mutationFn'
  >
) => useMutation(mutateCloseProposition, options);
