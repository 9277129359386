import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkDispatch } from '../../../../../../app/types';
import { apiGetRequest, apiPostRequest } from '../../../../../../lib/api/api';
import { TSportsOffered } from '../../../../../../lib/DBModels';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

import {
  TSportModifier,
  TRaceModifierUpdateItem,
  TSportModifierUpdateItem,
  TUpdateGlobalRacingMargin,
  TUpdateGlobalSportsMargin,
  TVenueNameAndNotifications,
  TAllOfferedRaceTypeVenues,
  TRaceModifiers,
} from './types';

import {
  setGlobalRacingMargin,
  setRaceVenueList,
  setRaceModifiers,
  setGlobalSportsMargin,
  setSportModifiers,
  setSportsWithCompetitions,
} from './slices';

// RACING

/**
 * Gets the global racing margin.
 */
export const getGlobalRacingMargin = createAsyncThunk<
  number,
  undefined,
  { dispatch: AppThunkDispatch }
>('globalMargins/getGlobalRacingMargin', async (_, thunkAPI) => {
  const globalMargin = await apiGetRequest<number>(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/global-margins/global-race-margin`
  );
  thunkAPI.dispatch(setGlobalRacingMargin(globalMargin));

  return globalMargin;
});

/**
 * Sets the global racing margin.
 *
 * @param newMargin object: The global racing margin.
 */
export const updateGlobalRacingMargin = createAsyncThunk<
  number,
  TUpdateGlobalRacingMargin,
  { dispatch: AppThunkDispatch }
>('globalMargins/setGlobalRacingMargin', async (newMargin, thunkAPI) => {
  const globalRacingMargin = await apiPostRequest<number>(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/global-margins/global-race-margin`,
    newMargin
  );

  thunkAPI.dispatch(setGlobalRacingMargin(globalRacingMargin));

  return globalRacingMargin;
});

/**
 * Gets the venue details from a race_type
 * @param raceType string: race type name
 */
export const getRaceVenues = createAsyncThunk<
  TAllOfferedRaceTypeVenues,
  undefined,
  { dispatch: AppThunkDispatch }
>('globalMargins/getAllRaceTypeVenues', async (_, thunkAPI) => {
  const [horseRacing, harnessRacing, greyhounds] = await Promise.all([
    await apiGetRequest<TVenueNameAndNotifications[]>(
      `/${getPrefixPath(
        isWincore
      )}/trade-manager/by-racing/venues?raceType=Horse%20Racing`
    ),
    await apiGetRequest<TVenueNameAndNotifications[]>(
      `/${getPrefixPath(
        isWincore
      )}/trade-manager/by-racing/venues?raceType=Harness%20Racing`
    ),
    await apiGetRequest<TVenueNameAndNotifications[]>(
      `/${getPrefixPath(
        isWincore
      )}/trade-manager/by-racing/venues?raceType=Greyhounds`
    ),
  ]);

  const allRaceVenues = {
    horseRacing,
    harnessRacing,
    greyhounds,
  };

  thunkAPI.dispatch(setRaceVenueList(allRaceVenues));
  return allRaceVenues;
});

/**
 * Gets the venue details from a race_type
 */
export const getRaceModifiers = createAsyncThunk<
  TRaceModifiers[],
  undefined,
  { dispatch: AppThunkDispatch }
>('globalMargins/getRaceModifiers', async (_, thunkAPI) => {
  const raceModifiers = await apiGetRequest<TRaceModifiers[]>(
    `/${getPrefixPath(isWincore)}/trade-manager/global-margins/race-margins`
  );

  thunkAPI.dispatch(setRaceModifiers(raceModifiers));
  return raceModifiers;
});

// SPORTS

/**
 * update global race modifiers.
 * @param updatedPayload []: A flattened list of the race modifiers.
 */
export const updateGlobalRaceModifier = createAsyncThunk<
  TRaceModifiers[],
  TRaceModifierUpdateItem[],
  { dispatch: AppThunkDispatch }
>(
  'globalMargins/updateGlobalRaceModifier',
  async (updatedPayload, thunkAPI) => {
    const raceModifiers = await apiPostRequest<TRaceModifiers[]>(
      `/${getPrefixPath(isWincore)}/trade-manager/global-margins/race-margins`,
      updatedPayload
    );

    if (raceModifiers) thunkAPI.dispatch(setRaceModifiers(raceModifiers));
    return raceModifiers;
  }
);

/**
 * Gets the global sports margin.
 */
export const getGlobalSportsMargin = createAsyncThunk<
  number,
  undefined,
  { dispatch: AppThunkDispatch }
>('globalMargins/getGlobalSportsMargin', async (_, thunkAPI) => {
  const globalSportsMargin = await apiGetRequest<number>(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/global-margins/global-sport-margin`
  );
  thunkAPI.dispatch(setGlobalSportsMargin(globalSportsMargin));

  return globalSportsMargin;
});

/**
 * update the global sports margin.
 * @param newMargin object: The global sports margin.
 */
export const updateGlobalSportsMargin = createAsyncThunk<
  number,
  TUpdateGlobalSportsMargin,
  { dispatch: AppThunkDispatch }
>('globalMargins/updateGlobalSportsMargin', async (newMargin, thunkAPI) => {
  const globalSportsMargin = await apiPostRequest<number>(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/global-margins/global-sport-margin`,
    newMargin
  );

  thunkAPI.dispatch(setGlobalSportsMargin(globalSportsMargin));

  return globalSportsMargin;
});

/**
 * get Sport modifiers list.
 */
export const getSportModifiers = createAsyncThunk<
  TSportModifier[],
  undefined,
  { dispatch: AppThunkDispatch }
>('globalMargins/getSportModifiers', async (_, thunkAPI) => {
  const sportModifiers = await apiGetRequest<TSportModifier[]>(
    `/${getPrefixPath(isWincore)}/trade-manager/global-margins/sport-margins`
  );

  thunkAPI.dispatch(setSportModifiers(sportModifiers));
  return sportModifiers;
});

/**
 * update global sport modifiers.
 * @param updatedPayload []: A flattened list of the sport modifiers.
 */
export const updateGlobalSportModifiers = createAsyncThunk<
  TSportModifier[],
  TSportModifierUpdateItem[],
  { dispatch: AppThunkDispatch }
>(
  'globalMargins/updateGlobalSportModifiers',
  async (updatedPayload, thunkAPI) => {
    const sportModifiers = await apiPostRequest<TSportModifier[]>(
      `/${getPrefixPath(isWincore)}/trade-manager/global-margins/sport-margins`,
      updatedPayload
    );

    if (sportModifiers) thunkAPI.dispatch(setSportModifiers(sportModifiers));
    return sportModifiers;
  }
);

/**
 * gets all offered sports.
 */
export const getAllSportsWithCompetitions = createAsyncThunk<
  TSportsOffered,
  undefined,
  { dispatch: AppThunkDispatch }
>('globalMargins/getAllSportsWithCompetitions', async (_, thunkAPI) => {
  const sports = await apiGetRequest<TSportsOffered>(
    `/${getPrefixPath(isWincore)}/offerings/sport-offerings`
  );

  thunkAPI.dispatch(setSportsWithCompetitions(sports));
  return sports;
});
