import { useState } from 'react';

export const useTableRows = () => {
  const [clickedRows, setClickedRows] = useState<number[]>([]);

  const handleRowClick = (index: number) => {
    if (clickedRows.includes(index)) {
      setClickedRows(clickedRows.filter((i) => i !== index));
    } else {
      setClickedRows([...clickedRows, index]);
    }
  };

  return { clickedRows, handleRowClick };
};
