import api from '../../api';
import { TPunterBetParams, TVoidBetInput } from './bets.types';
import { TBetsData } from '../punters.types';

const betsDataURL = '/bookie/punters/bets/';

// Incentives - Bet History
export const queryBetsData = (id: string | null, params: TPunterBetParams) =>
  api
    .get<TBetsData[]>(`${betsDataURL}${id}`, { params })
    .then((res) => res.data);

export const mutateVoidBet = (body: TVoidBetInput) =>
  api
    .post<TVoidBetInput>(`/bookie/punters/bets/cancel`, body)
    .then((res) => res.data);
