import {
  FlexProps,
  chakra,
  Flex,
  ChakraComponent,
  Text,
  TextProps,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { EUpdateActions } from './UpdateableMarginControls.types';

export const ButtonUpdate = chakra(Button, {
  shouldForwardProp: (prop) => !['isHalfSize'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isHalfSize }: any) => ({
    position: 'absolute',
    bottom: '1.5',
    zIndex: '2',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: isHalfSize ? '16' : '14',
    height: isHalfSize ? '18px' : '9',
    background: 'green.500',
    p: '0',
    borderRadius: '0',

    _hover: {
      bg: 'green.200',
    },

    _disabled: {
      bg: 'green.50',
      opacity: '1',
      _hover: {
        bg: 'green.50',
      },
    },

    '&&': {
      boxShadow: 'none',
      borderTopRightRadius: '8',
      borderBottomRightRadius: '8',
    },
  }),
}) as ChakraComponent<'button', ButtonProps & { isHalfSize: boolean }>;

export const ButtonTick = chakra(Button, {
  shouldForwardProp: (prop) => !['operator'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ operator }: any) => ({
    bg: 'blackAlpha.50',
    justifyContent: 'center',
    alignItems: 'center',
    minW: '6',
    h: '6',
    p: '0',
    borderRadius: '0',
    borderWidth: (() => {
      if (operator === EUpdateActions.Increment) return '2px 2px 1px 2px';

      if (operator === EUpdateActions.Decrement) return '1px 2px 2px 2px';

      return '';
    })(),
    borderColor: 'blackAlpha.200',

    _hover: {
      bg: 'blackAlpha.200',
    },

    _disabled: {
      _hover: {
        bg: 'blackAlpha.50',
      },
    },

    '&&': {
      boxShadow: 'none',
      ...(() => {
        if (operator === EUpdateActions.Increment)
          return { borderTopRightRadius: '8', borderTopLeftRadius: '8' };

        if (operator === EUpdateActions.Decrement)
          return { borderBottomRightRadius: '8', borderBottomLeftRadius: '8' };

        return {};
      })(),
    },
  }),
}) as ChakraComponent<'button', ButtonProps & { operator: EUpdateActions }>;

export const FlexMarginDisplay = chakra(Flex, {
  baseStyle: () => ({
    minW: '12',
    px: '1',
    h: '9',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeftRadius: '11',
    borderWidth: '1px 0 1px 2px',
    borderColor: 'blackAlpha.200',
    bg: 'blackAlpha.50',
    alignSelf: 'center',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const FlexTickDifference = chakra(Flex, {
  baseStyle: () => ({
    minW: '10',
    px: '1',
    h: '9',
    justifyContent: 'center',
    borderRightRadius: '11',
    borderWidth: '1px 2px 1px 0',
    borderColor: 'blackAlpha.200',
    bg: 'blackAlpha.50',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const TextMargin = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: 'medium',
    lineHeight: '4',
    cursor: 'default',
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextOperator = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    color: 'orange.300',
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextTickDifference = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    lineHeight: '4',
    cursor: 'default',
  }),
}) as ChakraComponent<'p', TextProps>;
