import api from '@/api/api';
import { TRaceStatus } from './raceStatus.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const raceStatusURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/manual-race-status-update`;

// Post: Update Race Status
export const mutateRaceStatus = (body: TRaceStatus) =>
  api.post<TRaceStatus>(raceStatusURL, body).then((res) => res.data);
