import React from 'react';
import {
  BoxProps,
  chakra,
  ChakraComponent,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp, MotionProps } from 'framer-motion';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TMotion = BoxProps & MotionProps & any;

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
}) as ChakraComponent<'div', TMotion>;

export const Motion = ({ children, ...rest }: TMotion) => (
  <ChakraBox
    initial="hidden"
    animate="enter"
    exit="exit"
    transition={{ ease: 'easeOut', duration: '.3' }}
    {...rest}
  >
    {children}
  </ChakraBox>
);
