/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TPunterWithdrawalRequestsDTO } from '@/lib/DTO';
import {
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  useMutation,
} from 'react-query';
import { keys } from '../../api.keys';
import { TWithdrawalParams } from '../withdrawals.types';
import { mutateWithdrawalsStatus, queryWithdrawalsStatus } from './status';
import { TWithdrawalBody } from './status.types';

type TQueryWithdrawalStatusHook = {
  params?: TWithdrawalParams;
  key?: unknown[];
  options?: UseQueryOptions<TPunterWithdrawalRequestsDTO[]>;
};

export const useQueryWithdrawalStatus = ({
  params = {},
  key = [],
  options = {},
}: TQueryWithdrawalStatusHook = {}) => {
  const query = useQuery(
    [keys.statusWithdrawals, ...Object.values(params), ...key],
    () => queryWithdrawalsStatus(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

export const useMutationWithdrawalStatus = (
  options?: UseMutationOptions<unknown, unknown, TWithdrawalBody>
) => useMutation((data) => mutateWithdrawalsStatus(data), options);
