import { ExtensionPriority } from 'remirror';
import jsx from 'refractor/lang/jsx.js';
import md from 'refractor/lang/markdown.js';
import typescript from 'refractor/lang/typescript.js';

import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  DocExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  TableExtension,
  TrailingNodeExtension,
  ImageExtension,
  DropCursorExtension,
} from 'remirror/extensions';
import { useRemirror } from '@remirror/react';
import {
  postPrivacyPolicy,
  postTermsConditions,
} from '@/features/settings/pages/Settings/actions';
import { useBoolean } from '@chakra-ui/react';
import { useAppDispatch } from '@/common/hooks/useRedux';

export const extensions = () => [
  new ImageExtension(),
  new DropCursorExtension(),
  new LinkExtension({ autoLink: true }),
  new BoldExtension(),
  new StrikeExtension(),
  new ItalicExtension(),
  new HeadingExtension(),
  new LinkExtension(),
  new BlockquoteExtension(),
  new BulletListExtension({ enableSpine: true }),
  new OrderedListExtension(),
  new ListItemExtension({
    priority: ExtensionPriority.High,
    enableCollapsible: true,
  }),
  new CodeExtension(),
  new CodeBlockExtension({ supportedLanguages: [jsx, typescript] }),
  new TrailingNodeExtension(),
  new TableExtension(),
  new MarkdownExtension({ copyAsMarkdown: false }),
  new HardBreakExtension(),
];

export const useWyswyg = () => {
  const visual = useRemirror({
    extensions,
    stringHandler: 'markdown',
    content: '**Markdown** content is the _best_',
  });
  const markdown = useRemirror({
    extensions: () => [
      new DocExtension({ content: 'codeBlock' }),
      new CodeBlockExtension({
        supportedLanguages: [md, typescript],
        defaultLanguage: 'markdown',
        syntaxTheme: 'base16_ateliersulphurpool_light',
        defaultWrap: true,
      }),
    ],
    builtin: {
      exitMarksOnArrowPress: false,
    },

    stringHandler: 'html',
  });

  return { visual, markdown };
};

/**
 * Hook that saves the T&C MD
 */
export const useSave = () => {
  const [isSaving, setIsSaving] = useBoolean();
  const dispatch = useAppDispatch();

  const handleSaveTerms = async (policy: string, policyName: string) => {
    setIsSaving.on();
    if (policyName === 'terms') {
      await dispatch(postTermsConditions({ terms_of_service: policy }));
    } else {
      await dispatch(postPrivacyPolicy({ privacy_policy: policy }));
    }
    setIsSaving.off();
  };

  return [
    (policy: string, policyName: string) => handleSaveTerms(policy, policyName),
    { isSaving },
  ] as const;
};
