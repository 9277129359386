import api from '@/api/api';
import { TGetCompetitionParams, TCompetition } from './getCompetition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const getCompetitionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/get-competition`;

// Trade Manager - Get Competition

export const queryGetCompetition = (params: TGetCompetitionParams) =>
  api
    .get<TCompetition[]>(getCompetitionURL, { params })
    .then((res) => res.data);
