import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import useErrorHandler from '@/hooks/useErrorHandler';
import { keys } from '@/api/api.keys';
import { useMutationCreateAffiliate } from '@/api/promoManager/affiliates/affiliates.hooks';
import { AffiliateModal } from './AffiliateModal';

export const CreateAffiliate = () => {
  const onError = useErrorHandler();
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutationCreateAffiliate({
    onError,
    onSuccess: () => {
      toast({
        title: 'Affiliate added',
        status: 'success',
        position: 'top',
        duration: 9_000,
      });
      queryClient.invalidateQueries([keys.affiliates]);
    },
  });
  return <AffiliateModal modalType={'create'} submit={mutation.mutate} />;
};
