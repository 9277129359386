import { number, object, SchemaOf } from 'yup';
import { TGlobalExposureLimits } from '../../../types';
import { getStrings } from '../../../../../../../../../common/utils';

const [
  {
    FormValidation: {
      RequiredField: RequiredFieldMessage,
      MustBeNumber,
      MaxAmount,
      MinimumAmount,
    },
  },
] = getStrings();

export const globalExposureLimitsDefault: TGlobalExposureLimits = {
  race_win_market_exposure_manual_approval_limit: '0.00',
  race_place_market_exposure_manual_approval_limit: '0.00',
  sport_win_market_exposure_manual_approval_limit: '0.00',
  sgm_per_bet_exposure_manual_approval_limit: '0.00',
  srm_per_bet_exposure_manual_approval_limit: '0.00',
  blended_per_bet_exposure_manual_approval_limit: '0.00',
  even_shot_proposition: '0.00',
  even_shot_per_bet_exposure_manual_approval_limit: '0.00',
};

export const globalExposureLimitsValidation: SchemaOf<TGlobalExposureLimits> =
  object().shape({
    race_win_market_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    race_place_market_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    sport_win_market_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    sgm_per_bet_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    srm_per_bet_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    blended_per_bet_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    even_shot_proposition: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
    even_shot_per_bet_exposure_manual_approval_limit: number()
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/g, ''))
      )
      .max(1000000, MaxAmount)
      .min(0, MinimumAmount)
      .typeError(MustBeNumber)
      .required(RequiredFieldMessage),
  });
