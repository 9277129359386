import styled from 'styled-components/macro';
import { Button, Text } from 'grommet';
import { ModalStyleProps, ModalTextProps } from './types';

export const ModalBackground = styled.div<ModalStyleProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ModalWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.global.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  border-radius: 24px;
  padding: 30px;
  margin: 30px;
`;

export const Icon = styled.img`
  margin-bottom: 30px;
  height: 80px;
  width: 80px;
`;

export const ModalTitle = styled(Text)<ModalTextProps>`
  font-size: 18px;
  color: ${({ theme }) => theme.global.colors.blueGreyBlack};
  font-weight: 700;
`;

export const ModalSubtext = styled(Text)<ModalTextProps>`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.global.colors.darkGrey};
`;

export const ModalBodyText = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: ;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
`;

export const ButtonConfirm = styled(Button)`
  background-color: ${({ theme }) => theme.global.colors.red};
  color: ${({ theme }) => theme.global.colors.white};
  font-weight: 700;
  width: 150px;
  height: 40px;
  border: none;
`;

export const ButtonCancel = styled(Button)`
  color: ${({ theme }) => theme.global.colors.black};
  background-color: ${({ theme }) => theme.global.colors.backingGrey};
  border: none;
  font-weight: 700;
  width: 150px;
  height: 40px;
  border-width: 1px;
`;
