import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BookieState, Bookie } from './types';

const initialState: BookieState = {
  bookieDetails: null,
};

const bookieSlice = createSlice({
  name: 'bookie',
  initialState,
  reducers: {
    setBookieDetails: (state: BookieState, action: PayloadAction<Bookie>) => ({
      ...state,
      bookieDetails: action.payload,
    }),
  },
});

// Slice actions
export const { setBookieDetails } = bookieSlice.actions;

// Slide reducer
export default bookieSlice.reducer;
