import React from 'react';
import { Collapse, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { ButtonUpdate } from '../RaceDetailsTable.styles';
import { EGeneralStatus, TRunner, TMarket } from '@/lib/DBModels';
import BetsForProposition from '../../../../../../../../components/BetsForProposition';
import {
  TPriceModifierReducerState,
  useColumns,
} from '../services/RaceDetailsTable.hooks';
import TableChakra from '@/common/components/TableChakra/TableChakra';
import ConfigRunnerRow from './ConfigRunnerRow/ConfigRunnerRow';
import { FormikContextType } from 'formik';
import { TRaceDetailsFormValues } from '../RaceDetailsTable.types';
import {
  EEventType,
  ERaceMarketType,
} from '../../../../../../Services/RaceDetails.types';
import { TRunnerBody } from '@/api/tradeManager/raceDetails/runnerStatus/runnerStatus.types';
import { TRunnerDetailsAction } from './RunnerDetails/RunnerDetails';

type TFormProps = {
  formik: FormikContextType<TRaceDetailsFormValues>;
  priceModifierState: TPriceModifierReducerState;
  priceModifiersDispatch: React.Dispatch<TRunnerDetailsAction>;
  data: TRunner[];
  isManuallyManaged: boolean;
  onClickRow: (raceRunner: TRunner) => void;
  apiModel: Record<string, TRunnerBody>;
  isUpdateButtonEnabled: boolean;
  setIsUpdateButtonEnabled: (value: boolean) => void;
  raceMarkets?: TMarket[];
  priceType?: ERaceMarketType;
};

const Form = ({
  formik,
  priceModifierState,
  priceModifiersDispatch,
  data,
  raceMarkets,
  isManuallyManaged,
  onClickRow,
  apiModel,
  isUpdateButtonEnabled,
  setIsUpdateButtonEnabled,
  priceType,
}: TFormProps) => {
  const { submitForm, isSubmitting } = formik;
  const { columns } = useColumns(
    data,
    priceModifierState,
    priceModifiersDispatch,
    isManuallyManaged,
    raceMarkets,
    () => setIsUpdateButtonEnabled(true)
  );

  return (
    <Flex flexDir="column">
      <TableChakra<TRunner>
        columns={columns}
        data={data}
        placeholder={
          <Text py="3">
            <FormattedMessage id="trademanagerpage.norunners" />
          </Text>
        }
        rowDetailsStyle={(x) =>
          x.status === EGeneralStatus.Scratched
            ? {
                overflow: 'visible',
              }
            : {}
        } // To prevent datepicker box cut off
        onClickRow={onClickRow}
        rowDetails={(raceRunner: TRunner, _, expanded) => {
          return expanded && raceRunner?.race_runner_id ? (
            <>
              <ConfigRunnerRow
                formik={formik}
                show={isManuallyManaged}
                runner={raceRunner}
                apiModel={apiModel}
                setIsUpdateButtonEnabled={setIsUpdateButtonEnabled}
              />
              <BetsForProposition
                type={EEventType.Race}
                raceRunnerId={raceRunner?.race_runner_id}
                priceType={priceType}
              />
            </>
          ) : null;
        }}
      />
      <Collapse in={isManuallyManaged}>
        <Flex justifyContent="flex-end">
          <ButtonUpdate
            isDisabled={isSubmitting || !isUpdateButtonEnabled}
            isLoading={isSubmitting}
            onClick={async () => {
              try {
                await submitForm();
              } catch (error) {
                console.error('Error submitting form:', error);
              }
            }}
          >
            <FormattedMessage id="generic.update" />
          </ButtonUpdate>
        </Flex>
      </Collapse>
    </Flex>
  );
};

export default Form;
