import React, { useMemo } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { getStrings, centsToDollars } from '@/common/utils';
import { formatSRMPlacement } from '../SRMBets.utils';
import BetStatusBadge from '@/common/components/BetStatusBadge';
import { EBetStatus } from '@/lib/DBModels';
import { ColumnDef } from '@tanstack/react-table';

export const useSRMBetsColumns = () => {
  const [
    {
      TradeManagerPage: {
        SRMBets: { ColumnTitles },
      },
    },
  ] = getStrings();

  const columns = useMemo<ColumnDef<Api['SRMultiRaceBetResponse']>[]>(
    () => [
      {
        accessorKey: 'runners',
        header: ColumnTitles.SRMBets,
        cell: ({ row }) => {
          const runners = row.original.runners;
          return (
            <Flex flexDirection="column">
              {runners?.map((runner) => (
                <Flex
                  key={runner.name}
                  flexDirection="column"
                  paddingBottom={2}
                >
                  <Box>
                    <b>{`${runner.number}. ${runner.name}`}</b>
                  </Box>
                  <Box>{formatSRMPlacement(runner.top_selection)}</Box>
                </Flex>
              ))}
            </Flex>
          );
        },
      },
      {
        accessorKey: 'duplicate_bets',
        header: ColumnTitles.DuplicateBets,
        cell: ({ row }) => (
          <Text color="brand.500">{row.original.duplicate_bets}</Text>
        ),
      },
      // Date: 'Date',
      {
        accessorKey: 'created_at',
        header: ColumnTitles.Date,
        cell: ({ row }) => (
          <Text>{new Date(row.original.created_at).toLocaleString()}</Text>
        ),
      },
      // Punter: 'Punter',
      {
        accessorKey: 'punter_name',
        header: ColumnTitles.Punter,
        cell: ({ row }) => (
          <Link to={`/punter/${row.original.punter_id}`}>
            <Text>{row.original.punter_name}</Text>
          </Link>
        ),
      },
      // BetStatus: 'Bet Status',
      {
        accessorKey: 'status',
        header: ColumnTitles.BetStatus,
        cell: ({ row }) => (
          <BetStatusBadge status={row.original.status as EBetStatus} />
        ),
      },
      // Stake: 'Stake',
      {
        accessorKey: 'stake',
        header: ColumnTitles.Stake,
        cell: ({ row }) => centsToDollars(row.original.stake),
      },
      // Odds: 'Odds',
      {
        accessorKey: 'odds',
        header: ColumnTitles.Odds,
      },
      // Exposure: 'Exposure',
      {
        id: 'exposure',
        accessorKey: 'exposure',
        header: ColumnTitles.Exposure,
        cell: ({ row }) => (
          <Text>{centsToDollars(row.original.exposure ?? 0)}</Text>
        ),
      },
    ],
    [ColumnTitles]
  );

  return { columns };
};
