import { rest } from 'msw';
import { TSport } from '@/api/tradeManager/marketCreation/getSport/getSport.types';
import { TCompetition } from '@/api/tradeManager/marketCreation/getCompetition/getCompetition.types';
import { baseURL } from '@/__mocks__/utils';
import { ENV } from '@/lib/Constants';

export const handlerCustomMarketSports = [
  rest.get(`${baseURL}/bookie/trade-manager/get-sport`, (req, res, ctx) => {
    const sports = [
      {
        sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
        name: 'Extreme Pillow Fighting League',
        display_name: 'Extreme Pillow Fighting League',
        is_manual: true,
      },
      {
        sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
        name: 'Office Chair Racing Championship',
        display_name: 'Office Chair Racing Championship',
        is_manual: true,
      },
      {
        sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
        name: 'Banana Peel Curling',
        display_name: 'Banana Peel Curling',
        is_manual: true,
      },
      {
        sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
        name: 'Synchronized Belly Flopping',
        display_name: 'Synchronized Belly Flopping',
        is_manual: true,
      },
    ] as TSport[];

    return res(ctx.delay(), ctx.json(sports));
  }),
];

export const handlerCustomMarketCompetitions = [
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/trade-manager/get-competition`,
    (req, res, ctx) => {
      const competitions = [
        {
          competition_id: '431443f6-df4e-4eb0-ac51-a4776c590541',
          sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
          name: 'Rolling in the Cubicle',
          display_name: 'Rolling in the Cubicle',
          is_manual: true,
        },
        {
          competition_id: '431443f6-df4e-4eb0-ac51-a4776c590541',
          sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
          name: 'Slippery Shenanigans',
          display_name: 'Slippery Shenanigans',
          is_manual: true,
        },
        {
          competition_id: '431443f6-df4e-4eb0-ac51-a4776c590541',
          sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
          name: 'The Swivel Showdown',
          display_name: 'The Swivel Showdown',
          is_manual: true,
        },
        {
          competition_id: '431443f6-df4e-4eb0-ac51-a4776c590541',
          sport_id: '431443f6-df4e-4eb0-ac51-a4776c590540',
          name: 'The Battle Royale of Fluff',
          display_name: 'The Battle Royale of Fluff',
          is_manual: true,
        },
      ] as TCompetition[];

      return res(ctx.delay(), ctx.json(competitions));
    }
  ),
];
