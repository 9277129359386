import styled from 'styled-components';
import {
  chakra,
  Image,
  ImageProps,
  Box,
  Icon,
  BoxProps,
  ChakraComponent,
  IconButton,
} from '@chakra-ui/react';
import Input, { TIndex } from '@/common/components/FormElements/Input';
import Select, { Props } from 'react-select';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 0px 0px;
`;

export const InputSearch = chakra(Input, {
  baseStyle: () => ({
    w: 'full',
    fontSize: 'sm',
    mr: '2',
  }),
}) as ChakraComponent<'div', TIndex>;

export const ButtonSearch = chakra(IconButton, {
  baseStyle: () => ({
    pos: 'relative',
    bg: 'origin.500',
    ml: '2',
    mr: 'auto',

    _hover: {
      bg: 'origin.300',
    },
  }),
});

export const IconSearch = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '5',
  }),
});

export const UserBadge = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    boxSize: '7',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'semibold',
    mr: '2',
    outline: '2px solid',
    textTransform: 'uppercase',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const BoxPunterFlag = chakra(Box, {
  baseStyle: () => ({
    display: 'inline-block',
    bg: 'origin.500',
    color: 'white',
    fontSize: 'xs',
    borderRadius: 'base',
    fontWeight: '500',
    textTransform: 'capitalize',
    boxShadow: 'xl',
    mr: '1',
    p: '.5',
    px: '2',
    w: 'fit-content',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const BoxFlagWrapper = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const PlayerIcon = chakra(Image, {
  baseStyle: () => ({
    boxSize: '5',
    p: '1',
  }),
}) as ChakraComponent<'img', ImageProps>;

export const SelectPunterFlag = chakra(Select, {
  baseStyle: () => ({
    w: '200px',
    ml: '3',
    p: '1',
    pl: '0',
    mr: '0.5',
    '&': {
      textTransform: 'capitalize',
    },
  }),
}) as ChakraComponent<'select', Props>;
