export type TBreakPeriod = {
  period: number;
  label: string;
};

export enum EPunterAccountStatus {
  normal = 'Normal',
  suspended_kyc = 'Suspended_KYC',
  temporary_self_exclusion = 'Temporary_Self_Exclusion',
  permanent_self_exclusion = 'Permanent_Self_Exclusion',
  closed = 'Closed_Customer_Requested',
}

export const PunterAvailableAccountStatues: EPunterAccountStatus[] = [
  EPunterAccountStatus.normal,
  EPunterAccountStatus.suspended_kyc,
  EPunterAccountStatus.temporary_self_exclusion,
  EPunterAccountStatus.permanent_self_exclusion,
  EPunterAccountStatus.closed,
];

export type TBonusBetStatusInput = {
  amount: number;
  reason?: EBonusBetReasons;
};

export enum EBonusBetReasons {
  normal = 'Normal',
  a_b = 'Reason 1',
  c_d_e = 'Reason 2',
  f_g_h = 'Reason 3',
  void = '',
}

export enum EPunterProfileOptions {
  Newbie = 'Newbie',
  Player = 'Player',
  Recreational = 'Recreational',
  Sharp = 'Sharp',
  HighRoller = 'High Roller',
  Blacklist = 'Blacklist',
  ApprovalOnly = 'Approval Only',
  PromoArber = 'Promo Arber',
}
