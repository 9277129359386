import {
  chakra,
  ChakraComponent,
  BoxProps,
  Box,
  Image,
  ImageProps,
} from '@chakra-ui/react';

export const BoxPunterFlag: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    display: 'inline-block',
    bg: 'origin.500',
    color: 'white',
    fontSize: 'xs',
    borderRadius: 'base',
    fontWeight: '500',
    textTransform: 'capitalize',
    boxShadow: 'xl',
    mr: '1',
    p: '.5',
    px: '2',
    w: 'fit-content',
  }),
});

export const BoxFlagWrapper: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
  }),
});

export const UserBadge: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    boxSize: '7',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'semibold',
    mr: '2',
    outline: '2px solid',
    textTransform: 'uppercase',
  }),
});

export const PlayerIcon: ChakraComponent<'img', ImageProps> = chakra(Image, {
  baseStyle: () => ({
    boxSize: '5',
    p: '1',
  }),
});
