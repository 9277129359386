import { NavLink } from 'react-router-dom';
import {
  chakra,
  IconButton as ChakraIconButton,
  Icon as ChakraIcon,
  Box,
  Icon,
  Tooltip,
  ChakraComponent,
  IconProps,
  TooltipProps,
  keyframes,
} from '@chakra-ui/react';

export const NavLinkLogo = chakra(NavLink, {
  baseStyle: () => ({
    display: 'flex',
    flexDir: 'row',
    pl: '2.5',
    py: '5',
  }),
});

const loopPulse = keyframes`
  0% {
    background: transparent;
    box-shadow: none;
  }
 50% {
    background: red;
    box-shadow: 0px 1px 11px #ff303061;
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
`;

type TNavLinkWrapperStyle = {
  isOpen?: boolean;
  isFlashing?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & any;

export const NavLinkWrapper = chakra<TNavLinkWrapperStyle>(NavLink, {
  shouldForwardProp: (prop) => !['isOpen', 'isFlashing'].includes(prop),
  baseStyle: ({ isOpen, isFlashing }: TNavLinkWrapperStyle) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: !isOpen && isFlashing && 'center',
    justifyItems: 'space-between',
    w: '-webkit-fill-available',
    h: '9',
    bg: isFlashing && 'red.500',
    fontSize: 'md',
    my: '1',
    mx: isOpen ? '3' : '2',
    p: '2',
    color: isFlashing ? 'white' : 'gray.400',
    borderRadius: 'md',
    transition: 'all, 0.3s ease-in-out',

    _hover: {
      bg: 'gray.100',
      color: isFlashing ? 'white' : 'gray.600',
    },
    animation: isFlashing && `${loopPulse} 1.5s ease-in-out infinite`,

    '&.active': {
      color: 'white',
      fontWeight: '500',
      boxShadow: 'md',
      bg: 'origin.500',
    },
  }),
});

export const IconLogo = chakra(ChakraIcon, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isOpen }: any) => ({
    color: 'gray.500',
    boxSize: '5',
    transition: '0.3s ease-in-out',
    transform: isOpen ? 'scale(1.5)' : 'scale(1)',
    mt: '2',
    mx: isOpen ? '4' : '0',
  }),
}) as ChakraComponent<'div', IconProps & { isOpen: boolean }>;

export const IconTextLogo = chakra(ChakraIcon, {
  baseStyle: () => ({
    color: 'gray.500',
    h: '30',
    w: '146',
    mt: '0.5',
    ml: '3',
  }),
});

export const IconButton = chakra(ChakraIconButton, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isOpen }: any) => ({
    bg: 'white',
    color: 'gray.200',
    boxShadow: 'none',
    width: '20px',
    mr: '1.5',
    mt: '1.5',
    transition: '0.3s ease-in-out',
    transform: isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',

    '&:hover': {
      bg: 'white',
    },
  }),
}) as ChakraComponent<'div', IconProps & { isOpen?: boolean }>;

export const NavIcon = chakra(Icon, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isOpen }: any) => ({
    iconColor: isOpen ? 'white' : 'gray.500',
    boxSize: '5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mr: '1',
  }),
}) as ChakraComponent<'div', IconProps & { isOpen: boolean }>;

export const NavText = chakra(Box, {
  baseStyle: () => ({
    fontSize: 'md',
    alignSelf: 'center',
  }),
});

export const MenuTooltip = chakra(Tooltip, {
  shouldForwardProp: (prop) => !['isExpanded'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isExpanded }: any) => ({
    px: '3',
    py: '2',
    color: 'gray.600',
    borderRadius: 'base',
    display: isExpanded && 'none',
  }),
}) as ChakraComponent<'div', TooltipProps & { isExpanded: boolean }>;
