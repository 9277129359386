import React from 'react';
import { TRegulatorForm } from '../../Regulator.types';
import Form from './components/Form';
import FormHOC from './components/FormHOC';
import { TFormProps } from './Filters.types';

const Filters = ({
  onSubmit,
  formProps,
}: {
  onSubmit: (values: TRegulatorForm) => void;
  formProps: TFormProps;
}) => {
  return (
    <FormHOC onSubmit={onSubmit}>
      <Form {...formProps} />
    </FormHOC>
  );
};

export default Filters;
