export function convertToUSOdds(odds: number): string {
  if (odds < 1 || Number.isNaN(odds)) return String(odds);
  if (odds > 2) return `+${Math.round((odds - 1) * 100)}`;

  const ladder = {
    1.01: -10000,
    1.02: -5000,
    1.03: -3334,
    1.04: -2500,
    1.05: -2000,
    1.06: -1667,
    1.07: -1429,
    1.08: -1250,
    1.09: -1112,
    1.1: -1000,
    1.11: -910,
    1.12: -834,
    1.13: -770,
    1.14: -715,
    1.15: -667,
    1.16: -625,
    1.17: -589,
    1.18: -556,
    1.19: -527,
    1.2: -500,
    1.21: -477,
    1.22: -455,
    1.23: -435,
    1.24: -417,
    1.25: -400,
    1.26: -385,
    1.27: -371,
    1.28: -358,
    1.29: -345,
    1.3: -334,
    1.31: -323,
    1.32: -313,
    1.33: -304,
    1.34: -295,
    1.35: -286,
    1.36: -278,
    1.37: -271,
    1.38: -264,
    1.39: -257,
    1.4: -250,
    1.41: -244,
    1.42: -239,
    1.43: -233,
    1.44: -228,
    1.45: -223,
    1.46: -218,
    1.47: -213,
    1.48: -209,
    1.49: -205,
    1.5: -200,
    1.51: -197,
    1.52: -193,
    1.53: -190,
    1.54: -186,
    1.55: -182,
    1.56: -180,
    1.57: -176,
    1.58: -173,
    1.59: -170,
    1.6: -167,
    1.61: -165,
    1.62: -162,
    1.63: -160,
    1.64: -157,
    1.65: -155,
    1.66: -152,
    1.67: -150,
    1.68: -148,
    1.69: -145,
    1.7: -143,
    1.71: -141,
    1.72: -140,
    1.73: -137,
    1.74: -136,
    1.75: -135,
    1.76: -132,
    1.77: -130,
    1.78: -129,
    1.79: -127,
    1.8: -125,
    1.81: -124,
    1.82: -122,
    1.83: -121,
    1.84: -120,
    1.85: -118,
    1.86: -117,
    1.87: -115,
    1.88: -114,
    1.89: -113,
    1.9: -112,
    1.91: -110,
    1.92: -109,
    1.93: -108,
    1.94: -107,
    1.95: -106,
    1.96: -105,
    1.97: -104,
    1.98: -103,
    1.99: -102,
    2.0: -100,
  }[odds];
  return String(ladder);
}
