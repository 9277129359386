import api from '../../api';
import {
  TBlendedCombinationParams,
  TBlendedCombinationPuntersParams,
} from './combinations.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// Blended - All Bets
const blendedCombinationsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/blended/combinations`;
export const queryBlendedCombinations = (params: TBlendedCombinationParams) =>
  api
    .get<Api['BlendedCombinationStatsResponse']>(blendedCombinationsURL, {
      params,
    })
    .then((res) => res.data);

const blendedCombinationPuntersURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/blended/combination-punters`;

export const queryBlendedCombinationPunters = (
  params: TBlendedCombinationPuntersParams
) =>
  api
    .get<Api['BlendedCombinationPunterStatsResponse']>(
      blendedCombinationPuntersURL,
      {
        params,
      }
    )
    .then((res) => res.data);
