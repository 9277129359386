import { EGeneralStatus } from '@/lib/DBModels';

export type TSuspendMarketInput = {
  // Race or match ID
  id?: string;
  // ID's of markets
  ids: string[];
  // Whether to suspend or enable
  suspend: boolean;
};

/* EStatusOptions.Open will be used instead of EStatusOptions.Winter when ignore winter is switched on on the race details page */
export enum EStatusOptions {
  Open = 'Open',
  Winter = 'winter',
  Suspend = 'suspend',
  SoftClose = 'close',
  HardClose = 'close & hide',
}

export type TStatusFormRace = {
  id: string;
  status: EGeneralStatus;
  follow_feed: boolean;
  settle_by_feed: boolean;
  allowedStatus: EGeneralStatus[];
};

export type TStatusFormMarket = {
  id: string;
  name: string;
  status: EGeneralStatus;
  follow_feed: boolean;
  settle_by_feed: boolean;
  allowedStatus: EGeneralStatus[];
};

export type TStatusFormValues = {
  race: TStatusFormRace;
  markets: TStatusFormMarket[];
};
