import {
  TPagedAPIInput,
  TPagedAPIResponse,
} from '../../../../../../lib/api/types';
import { TDeepPartial } from '../../../../../../lib/types';
import {
  EGeneralStatus,
  EBetStatus,
  EPlayerType,
  TPriceType,
} from '../../../../../../lib/DBModels';
import { TBetLegs } from '../../../../../../api/punters/punters.types';
import { CommonResponse } from './BetApprovals.utils';

export type TBetRequestState = {
  pending: TRequestBet[];
  pendingLoading: boolean;

  history: TBetRequestHistoryResponse;
  historyLoading: boolean;

  modalRejectBet?: CommonResponse;
  modalCounterBet?: CommonResponse;
};

export enum ERequestBetType {
  Single = 'single',
  Eachway = 'eachway',
  Multi = 'multi',
  Combo = 'combo',
  Exotics = 'exotics',
  SGMulti = 'SGMulti',
  SRMulti = 'SRMulti',
  Blended = 'Blended',
}

export enum EBetRequestApprovalState {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
}

export type TStickyNote = {
  text?: string;
  updated_at?: string;
  updated_by?: string;
};

export type TRequestBet = TDeepPartial<{
  request_id: string;
  status: EBetStatus;
  type: ERequestBetType;
  sub_type?: string;
  event_status: EGeneralStatus;
  actioned_at: string;
  actioned_by: string;
  approved_state: number;
  approved_odds: number;
  bet_payout: number;
  bet_status: EBetStatus;
  bet_won: boolean;
  created_at: string;
  event_id: string;
  event_title: string;
  event_subtitle: string;
  event_start: string;
  event_type: string;
  event_icon: string;
  event_exposure: number;
  punter_id: string;
  punter_name: string;
  proposition_id: string;
  proposition_odds: number;
  requested_odds: number;
  requested_stake: number;
  odds: number;

  is_bonus_bet: boolean;
  exotic_combo: number[][];

  punter_category?: EPlayerType;
  punter_category_at_placement?: EPlayerType;
  punter_storm_score?: number;
  punter_category_limit?: number;
  punter_category_limit_name: string;

  approved_stake?: number;
  selection: number[][];
  market_name: string;
  bet_legs: TBetLegs[];

  mbl_stake?: number;
  mbl_threshold?: number;
  intercept_reason: string;

  runner_name: string;
  runner_number: number;
  proposition_type: string;
  price_type: TPriceType;
  raain_rule_breached: boolean;

  sticky_note?: TStickyNote;
  stickyNote?: TStickyNote;
  request_type: string;
  requested_payout: number;
  stake?: number;
  source_platform_name: string;
  source_api_url: string;
  source_portal_url: string;

  can_claim: true;
  can_edit: true;
  can_override_claim: false;
  can_release_claim: false;
  claimed_by_name: string | null;
  claimed_by_email: string | null;
  tote_pool_legs: number[] | null;
}>;

export type TBetApprovalsResponse = TDeepPartial<{
  paging: {
    next_offset: string;
    limit: number;
  };
  bet_requests: TRequestBet[];
}>;

export type TRequestBetResponse = TDeepPartial<{
  total_pages: number;
  total_records: number;
  records: TRequestBet[];
}>;

export type TBetRequestHistoryResponse = TDeepPartial<
  TPagedAPIResponse & {
    records: TRequestBet[];
  }
>;

export type TBetPendingInput = {
  sort_by?: string;
  sort_direction?: 'asc' | 'desc';
};

export type TBetRequestHistoryQuery = {
  punter_name?: string;
  approval_from?: string;
  approval_to?: string;
  approval_state?: EBetRequestApprovalState;
  approval_by?: string;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
};

export type TBetRequestHistoryInput = TPagedAPIInput & TBetRequestHistoryQuery;

export enum EBetRequestStatusInputAction {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Counter = 'Counter',
  AcceptMBL = 'AcceptMBL',
}

export enum EBetRequestRejectReasons {
  high_risk_market = 'high_risk_market',
  high_risk_punter = 'high_risk_punter',
  fraud = 'fraud',
  t_and_c = 't_and_c',
  mbl_applied = 'mbl_applied',
}

export type TBetRequestStatusInput = {
  request_id: string;
  action: EBetRequestStatusInputAction;
  amount?: number;
  reason?: EBetRequestRejectReasons;
  type?: string;
  source_url?: string;
};
