import { CommonResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import { create } from 'zustand';

interface IPendingApprovalsStore {
  pendingApprovals: CommonResponse[];
  insertOrCreatePendingApproval(
    pendingApproval: CommonResponse,
    insertAtStart?: boolean
  ): void;
  updatePendingApproval(pendingApproval: CommonResponse): void;
  removePendingApproval(id: string): void;
  addBulkPendingApprovals(pendingApprovals: CommonResponse[]): void;
}

export const usePendingApprovalsStore = create<IPendingApprovalsStore>(
  (set) => ({
    pendingApprovals: [],
    insertOrCreatePendingApproval: (pendingApproval, insertAtStart = true) => {
      set((state) => {
        const existingPendingApproval = state.pendingApprovals.find(
          (pa) => pa.requestId === pendingApproval.requestId
        );

        if (!existingPendingApproval) {
          if (insertAtStart)
            return {
              pendingApprovals: [pendingApproval, ...state.pendingApprovals],
            };
          else
            return {
              pendingApprovals: [...state.pendingApprovals, pendingApproval],
            };
        }

        return {
          pendingApprovals: state.pendingApprovals.map((pa) =>
            pa.requestId === pendingApproval.requestId ? pendingApproval : pa
          ),
        };
      });
    },
    updatePendingApproval: (pendingApproval) => {
      set((state) => ({
        pendingApprovals: state.pendingApprovals.map((pa) =>
          pa.requestId === pendingApproval.requestId ? pendingApproval : pa
        ),
      }));
    },
    addBulkPendingApprovals: (pendingApprovals) => {
      set((state) => ({
        pendingApprovals: [...state.pendingApprovals, ...pendingApprovals],
      }));
    },
    removePendingApproval: (id: string) => {
      set((state) => ({
        pendingApprovals: state.pendingApprovals.filter(
          (pa) => pa.requestId !== id
        ),
      }));
    },
  })
);
