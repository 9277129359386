import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateAddSport } from './addSport';
import { TAddSportBody } from './addSport.types';

export const useMutationAddSport = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TAddSportBody>,
    'mutationFn'
  >
) => useMutation(mutateAddSport, options);
