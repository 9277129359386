import { keys } from '@/api/api.keys';
import { BetsTable } from '@/common/components/BetsTable/BetsTable';
import LoadingSpinner from '@/common/components/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import { useBlendedRunnerColumns } from './services/combinations.config';
import {
  queryBlendedCombinationPunters,
  queryBlendedCombinations,
} from '@/api/blended/combinations/combinations';
import { TBlendedCombinationParams } from '@/api/blended/combinations/combinations.types';

export default function Combinations() {
  const [params] = useSearchParams();
  const raceId = params.get('id');

  const [history, setHistory] = useState<string[]>([]);
  const { combinationColumns, combinationSubColumns } =
    useBlendedRunnerColumns();

  const limit = 20;
  const [offset, setOffset] = useState<string>('0');
  const [multiId, setMultiId] = useState<string>('');

  const [subData, setSubData] = useState<
    Api['BlendedCombinationPunterStatsItemResponse'][]
  >([]);

  const { data: punters, isLoading: isPuntersLoading } = useQuery(
    [keys.blendedRunners, offset, !!multiId],
    () =>
      queryBlendedCombinationPunters({
        multi_uid: multiId ?? '',
        limit,
        offset,
      }),
    {
      enabled: !!multiId,
      refetchInterval: !!multiId ? 60000 : 0, // polling every minute on first page only
      staleTime: !!multiId ? 0 : 60 * 5000,
    }
  );

  // Set subData when the data is available
  useEffect(() => {
    if (punters) {
      setSubData(punters?.punters ?? []);
    }
  }, [punters]);

  const { data, isLoading } = useQuery([keys.blendedRunners, raceId], () => {
    const queryParameters: TBlendedCombinationParams = {
      race_id: raceId ?? '',
      limit,
    };

    if (offset !== '') {
      queryParameters.offset = offset;
    }

    return queryBlendedCombinations(queryParameters);
  });

  const isLastFetch = (data?.combinations?.length ?? 0) < limit;

  if (isLoading || isPuntersLoading) return <LoadingSpinner />;

  return (
    <>
      <BetsTable
        hasSubTable
        columns={combinationColumns}
        data={data?.combinations ?? []}
        loading={isLoading}
        onClickExpand={(row: Api['SGMultisCombinationStatResponse']) =>
          setMultiId(row.multi_uid ?? [])
        }
      >
        <BetsTable
          columns={combinationSubColumns}
          data={subData}
          loading={isPuntersLoading}
        />
      </BetsTable>

      <PaginationV3
        nextDisabled={isLastFetch}
        offset={offset}
        history={history}
        setHistory={setHistory}
        onPageChange={(newOffset) => setOffset(newOffset)}
        useHistory
        nextOffsetId={!isLastFetch ? data?.paging?.next_offset : undefined}
      />
    </>
  );
}
