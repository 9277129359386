import api from '@/api/api';
import { TSGMBetsParams, TSGMPunterCombinations } from './combination.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// SGM - Bets
const querySGMCombinationsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sgmultis/combinations`;
export const querySGMCombinations = (params: TSGMBetsParams) =>
  api
    .get<Api['SGMultisCombinationStatsResponse']>(querySGMCombinationsURL, {
      params,
    })
    .then((res) => res.data);

// SGM - Punters
const querySGMCombinationPuntersURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sgmultis/combination-punters`;
export const querySGMCombinationPunters = (params: TSGMPunterCombinations) =>
  api
    .get<Api['SEMultisCombinationPunterStatsResponse']>(
      querySGMCombinationPuntersURL,
      {
        params,
      }
    )
    .then((res) => res.data);
