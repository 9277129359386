import { TFilter } from './Upcoming.slice';

export const upcomingBetsTableMatrix: Record<string, TFilter['sort_by']> = {
  event: 'event_title',
  date: 'created_at',
  punter_name: 'punter_name',
  status: 'status',
  odds: 'odds',
  stake: 'stake',
  return: 'return',
};

export const punterCategoryOptions = [
  { value: 'high-roller', label: 'High Roller' },
  { value: 'recreational', label: 'Recreational' },
  { value: 'newbie', label: 'Newbie' },
  { value: 'player', label: 'Player' },
  { value: 'sharp', label: 'Sharp' },
  { value: 'blacklist', label: 'Blacklist' },
  { value: 'approval-only', label: 'Approval Only' },
  { value: 'promo-arber', label: 'Promo Arber' },
];
