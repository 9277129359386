import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { keys } from '@/api/api.keys';
import {
  TPunterWithdrawalStatus,
  TWithdrawalActionInput,
} from '../../../types';
import {
  useMutationWithdrawalHistory,
  useQueryWithdrawalHistory,
  useQueryWithdrawalStats,
} from '@/api/withdrawals/history/history.hooks';
import {
  useMutationWithdrawalStatus,
  useQueryWithdrawalStatus,
} from '@/api/withdrawals/status/status.hooks';
import { TWithdrawalBody } from '@/api/withdrawals/status/status.types';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { ENewRelicActions } from '@/constants/newRelicActions';

export const useWithdrawalRequest = (status: TPunterWithdrawalStatus) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutationWithdrawalStatus();
  const intl = useIntl();
  const newRelicLog = useNewRelic();

  const [selected, setSelected] = useState<(string | number)[]>([]);

  const [filter, setFilter] = useState<boolean | null>();

  const [offset, setOffset] = useState<string>('0');
  const limit = 50;

  const { data: statsData, isLoading: statsLoading } = useQueryWithdrawalStats({
    params: {
      stats_type: 'Ready To Pay',
    },
  });

  const { mutateAsync } = useMutationWithdrawalHistory();

  const { data, isLoading } = useQueryWithdrawalStatus({
    params: {
      offset,
      limit,
      withdrawal_status: status,
      exported: filter,
    },
  });

  const isLastFetch = (data?.withdrawals.length ?? 0) < limit;

  const handleWithdrawalRequestAction = useCallback(
    (withdrawalInput: TWithdrawalBody) => {
      mutate(withdrawalInput, {
        onSuccess: async () => {
          toast.success(
            intl.formatMessage({
              id: 'bankspage.withdrawals.pendingApproval.successMessage',
            })
          );
          await queryClient.invalidateQueries([keys.statusWithdrawals]);

          newRelicLog(ENewRelicActions.Withdrawal, {
            punter_ids: data?.withdrawals
              ?.filter((req) =>
                withdrawalInput.withdrawal_ids?.includes(req?.withdrawal_id)
              )
              .map((w) => w.punter_id),
            ...withdrawalInput,
          });
        },
        onError: (e) => {
          toast.success(
            intl.formatMessage({
              id: 'bankspage.withdrawals.pendingApproval.errorMessage',
            })
          );

          newRelicLog(ENewRelicActions.Withdrawal, {
            error: JSON.stringify(e),
            punter_ids: data?.withdrawals
              ?.filter((req) =>
                withdrawalInput.withdrawal_ids?.includes(req?.withdrawal_id)
              )
              .map((w) => w.punter_id),
            ...withdrawalInput,
          });
        },
      });
    },
    [data, intl, mutate, newRelicLog, queryClient]
  );

  const handleActionSingle = useCallback(
    async (rowId: string | undefined, newStatus: TPunterWithdrawalStatus) => {
      if (!rowId || !data?.withdrawals?.length) return;

      const requestToAction =
        data?.withdrawals?.find((req) => req?.withdrawal_id === rowId) || null;

      if (!requestToAction || !requestToAction?.withdrawal_id) return;

      const apiUpdateData: TWithdrawalActionInput = {
        withdrawal_ids: [requestToAction.withdrawal_id],
        status: newStatus,
      };
      handleWithdrawalRequestAction(apiUpdateData);
      setSelected([]);
      await queryClient.invalidateQueries([keys.historyWithdrawalStats]);
    },
    [data, handleWithdrawalRequestAction, queryClient]
  );

  const handleActionMulti = useCallback(
    async (newStatus: TPunterWithdrawalStatus) => {
      if (!data?.withdrawals?.length || selected.length < 1) return;

      const requestsToAction = data?.withdrawals
        ?.filter(
          (req) => !!req?.withdrawal_id && selected.includes(req.withdrawal_id)
        )
        .map((req) => req?.withdrawal_id ?? ''); // the obj is defined but we need to change the type. TS doesnt like us casting it here?

      handleWithdrawalRequestAction({
        withdrawal_ids: requestsToAction ?? [],
        status: newStatus,
        all_approved_exported: filter,
      });
      setSelected([]);
      await queryClient.invalidateQueries([keys.historyWithdrawalStats]);
    },
    [data, selected, handleWithdrawalRequestAction, filter, queryClient]
  );

  const handleExportSelected = useCallback(async () => {
    const newFile = await mutateAsync({
      withdrawal_ids: [...(selected as string[])],
      exported: filter,
    });

    await queryClient.invalidateQueries([keys.withdrawals.withdrawalRequests]);
    setSelected([]);
    return newFile;
  }, [selected, queryClient, filter, mutateAsync]);

  const handleDownloadCSV = useCallback(
    (csvString: string, fileName: string) => {
      const contentType = 'text/aba';

      // Create blob link to download
      const csvFile = window.URL.createObjectURL(
        new Blob([csvString], { type: contentType })
      );
      const link = document.createElement('a');
      link.href = csvFile;
      link.setAttribute('download', `${fileName}.aba`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    },
    []
  );

  const handleDownloadClick = useCallback(async () => {
    const downloadData = await handleExportSelected();
    const csvString = downloadData.data;

    if (!csvString) return;

    // TODO: file name format undecided, default datetime stamp
    const dateTime = dayjs().format('DD/MM/YYYY_HH:mm:ss');
    const fileName = `Withdrawal_Requests_${dateTime}`;
    handleDownloadCSV(csvString, fileName);
  }, [handleExportSelected, handleDownloadCSV]);

  return {
    data: data?.withdrawals,
    paging: data?.paging,
    offset,
    isLoading,
    isLastFetch,
    selected,
    setOffset,
    handleActionSingle,
    handleActionMulti,
    setSelected,
    filter,
    setFilter,
    handleDownloadClick,
    statsData,
    statsLoading,
  };
};

type TFilters = {
  start_date?: string;
  end_date?: string;
  date_column?: 'requested_at' | 'completed_at';
  resulted?: TPunterWithdrawalStatus | undefined;
};

export const useWithdrawalRequestsHistory = () => {
  const [offset, setOffset] = useState<string>('0');
  const [filters, setFilters] = useState<TFilters>({
    date_column: 'completed_at',
  });

  const limit = 50;

  const { data, isLoading } = useQueryWithdrawalHistory({
    params: {
      offset,
      limit,
      completed_period_end:
        filters.end_date && filters.date_column === 'completed_at'
          ? new Date(filters.end_date).toISOString()
          : undefined,
      completed_period_start:
        filters.start_date && filters.date_column === 'completed_at'
          ? new Date(filters.start_date).toISOString()
          : undefined,
      requested_period_end:
        filters.end_date && filters.date_column === 'requested_at'
          ? new Date(filters.end_date).toISOString()
          : undefined,
      requested_period_start:
        filters.start_date && filters.date_column === 'requested_at'
          ? new Date(filters.start_date).toISOString()
          : undefined,
      withdrawal_status: filters.resulted,
      sort_by:
        filters.date_column === 'requested_at' ? 'created_at' : 'completed_at',
      sort_order: 'desc',
    },
  });

  const { data: statsData, isLoading: statsLoading } = useQueryWithdrawalStats({
    params: {
      stats_type: 'History Today',
    },
  });

  return {
    withdrawalRequestsHistory: data?.withdrawals,
    paging: data?.paging,
    isLoading,
    isLastFetch: (data?.withdrawals?.length ?? 0) < limit,
    setOffset,
    offset,
    statsData,
    statsLoading,
    filters,
    setFilters,
  };
};
