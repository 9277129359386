import React from 'react';
import { useFormikContext } from 'formik';
import {
  Button,
  ModalHeader,
  ModalContent,
  Heading,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { getStrings } from '../../../../../common/utils';
import Select from '../../../../../common/components/FormElements/Select';
import { EVoidReason } from '../Services/Types.VoidBet';
import { enumToLocalisationsDropdown } from '../../../../../lib/Localisations';

type TForm = {
  heading: string;
};

export default function Form({ heading }: TForm) {
  const [{ VoidBetModal: LocalisedStrings }] = getStrings();
  const { isSubmitting } = useFormikContext();

  const statusOptions = enumToLocalisationsDropdown(
    Object.keys(EVoidReason),
    LocalisedStrings.Reasons
  );

  return (
    <ModalContent>
      <ModalHeader sx={{ textAlign: 'center' }}>{heading}</ModalHeader>

      <ModalBody>
        <Heading sx={{ fontSize: 'm', mb: '4' }}>
          {LocalisedStrings.Message}
        </Heading>

        <Select name="reason" placeholder={LocalisedStrings.ReasonPlaceholder}>
          {statusOptions.map(({ value, label }) => (
            <option key={`option-${value}`} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </ModalBody>

      <ModalFooter>
        <Button
          type="submit"
          colorScheme="red"
          isLoading={isSubmitting}
          sx={{ w: 'full' }}
        >
          {LocalisedStrings.Button}
        </Button>
      </ModalFooter>

      <ModalCloseButton disabled={isSubmitting} />
    </ModalContent>
  );
}
