import React from 'react';
import {
  FormLabelProps,
  FormErrorMessageProps,
  TextProps,
  StyleProps,
  TextareaProps,
} from '@chakra-ui/react';
import HOCs from '../HOCs';
import TextareaComponent from './Textarea';

export type TIndex = {
  name: string;
  isFormik?: boolean;
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: TextProps;
  sxWrapper?: StyleProps;
} & TextareaProps;

export default function Textarea({
  isFormik = true,
  name,
  label,
  error,
  helperText,
  propsLabel,
  propsError,
  propsHelper,
  sxWrapper,
  ...rest
}: TIndex) {
  const id = `${name}-input`;

  return (
    <HOCs
      {...{
        isFormik,
        id,
        name,
        label,
        error,
        helperText,
        propsLabel,
        propsError,
        propsHelper,
        sxWrapper,
      }}
    >
      <TextareaComponent id={id} name={name} {...rest} />
    </HOCs>
  );
}
