/* eslint-disable no-param-reassign */
import React from 'react';
import { Text, Box } from '@chakra-ui/react';
import { ColumnConfig } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { getStrings, centsToDollars, getPlaceFromNumber } from '@/common/utils';
import { useQuery as useQueryString } from '@/common/hooks/useQuery';
import { TRaceExoticResult } from '@/lib/DBModels';
import { ERaceMarketType } from './RaceDetails.types';

/**
 * Hook which returns to the columns for the exotic result table
 */
export const useColumns = () => {
  const [
    {
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  const columns: ColumnConfig<TRaceExoticResult>[] = [
    {
      property: 'display_name',
      primary: true,
      plain: true,
      size: 'large',

      render: (exotic) => (
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <Box width={100}>
              <Text fontWeight="bold" fontSize="md" color="gray.600">
                {`${exotic?.type ?? ''}`}
              </Text>
            </Box>
            {exotic.selection?.map((sel, index) => (
              <Box
                key={`exotic-${sel}`}
                display="flex"
                alignItems="center"
                flexDirection="column"
                width={50}
              >
                <Text fontSize="xs">{getPlaceFromNumber(index + 1)}</Text>
                <Box mx={2} px={2} bg="gray.200" borderRadius="md">
                  <Text size="small">{sel}</Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ),
    },
    {
      property: 'dividend',
      size: 'xsmall',
      sortable: false,
      align: 'center',
      header: (
        <Text as="h3" fontSize="xs" color="gray.600">
          {Common.Dividend}
        </Text>
      ),
      render: (exotic) => (
        <Text size="small">
          {centsToDollars(Number(exotic?.dividend), false) ?? ''}
        </Text>
      ),
    },
  ];

  return { columns };
};

export const useMarketTypeNavigation = () => {
  const query = useQueryString();
  const marketType = query.get('marketType') ?? ERaceMarketType.WinPlace;
  const navigate = useNavigate();

  const goToMarketType = (type: ERaceMarketType) => {
    const updatedSearch = new URLSearchParams(query.toString());
    updatedSearch.set('marketType', type.toString());
    navigate({ search: `?${updatedSearch.toString()}` });
  };

  return {
    marketType,
    goToMarketType,
  };
};
