import React, { FC } from 'react';
import { Box } from 'grommet';
import { Card, Separator, BetsTable } from '../../../../common/components';
import { Search } from '@styled-icons/bootstrap/Search';

import {
  TextInputContainer,
  InputSearch,
  ButtonSearch,
  IconSearch,
  SelectPunterFlag,
} from './styles/Punters.styles';

import { TPunterSearch } from './services/Punters.types';
import { usePunters, usePuntersColumns } from './services/Punters.hooks';
import { Pagination } from '../../../../common/components/Pagination/Pagination';
import { useIntl } from 'react-intl';
import { Column } from '@tanstack/react-table';

export const Punters: FC = () => {
  const intl = useIntl();
  const { columns } = usePuntersColumns();

  const {
    data,
    isLoading,
    isLastFetch,
    searchVal,
    setSearchVal,
    results,
    setResults,
    offset,
    setOffset,
    punterFlag,
    handleFlagChange,
    defaultFlags,
    goToPunterProfile,
    defaultColumns,
    handleSearch,
  } = usePunters();

  return (
    <Card title={intl.formatMessage({ id: `punterspage.headertitle` })}>
      <Box
        direction="column"
        pad={{ top: '10px', horizontal: '10px', bottom: '10px' }}
      >
        <TextInputContainer>
          <InputSearch
            id="searchTerm"
            variant="filled"
            isFormik={false}
            name="searchTerm"
            type="text"
            placeholder={intl.formatMessage({
              id: `punterspage.searchplaceholder`,
            })}
            onChange={(e) => setSearchVal(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            onFocus={() => setOffset(0)}
            value={searchVal}
            sxWrapper={{ w: '250px' }}
          />
          <ButtonSearch
            aria-label="Search"
            onClick={handleSearch}
            icon={<IconSearch as={Search} />}
          />
          <SelectPunterFlag
            options={defaultFlags}
            styles={{
              control: (base) => ({
                ...base,
                cursor: 'pointer',
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            defaultValue={{
              value:
                punterFlag ??
                intl.formatMessage({ id: `punterspage.flagOption` }),
              label:
                punterFlag ??
                intl.formatMessage({ id: `punterspage.flagOption` }),
            }}
            // eslint-disable-next-line
            onChange={(newValue: any) => handleFlagChange(newValue.value)}
          />

          <SelectPunterFlag
            options={defaultColumns}
            width="80px"
            styles={{
              control: (base) => ({
                ...base,
                cursor: 'pointer',
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            defaultValue={defaultColumns[0]}
            // eslint-disable-next-line
            onChange={(newValue: any) => setResults(newValue.value)}
          />
        </TextInputContainer>
      </Box>
      <Separator />
      <Box height={{ min: '600px' }}>
        <BetsTable
          columns={columns as unknown as Column<TPunterSearch>[]}
          data={(data ?? []) as TPunterSearch[]}
          loading={isLoading}
          placeHolder="Empty"
          onClickRow={(row: TPunterSearch) => goToPunterProfile(row)}
        />
        <Pagination
          nextDisabled={isLastFetch}
          offset={offset}
          limit={results}
          onPageChange={(newOffset) => setOffset(newOffset)}
        />
      </Box>
    </Card>
  );
};

export default Punters;
