import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TAllSportsState, TSport } from './types';

import { getAllSports } from './actions';

const initialState: TAllSportsState = {
  allSports: [],
  sportsLoading: false,
  customMarketModal: {
    isOpen: false,
    mode: undefined,
    market_id: undefined,
    market_name: undefined,
  },
};

const allSportsSlice = createSlice({
  name: 'allSports',
  initialState,
  reducers: {
    setAllSports: (
      state: TAllSportsState,
      action: PayloadAction<TSport[]>
    ) => ({
      ...state,
      allSports: action.payload,
    }),
    setCustomMarketModal: (
      state: TAllSportsState,
      action: PayloadAction<TAllSportsState['customMarketModal']>
    ) => ({
      ...state,
      customMarketModal: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSports.pending, (state: TAllSportsState) => ({
        ...state,
        sportsLoading: true,
      }))
      .addCase(getAllSports.fulfilled, (state: TAllSportsState) => ({
        ...state,
        sportsLoading: false,
      }))
      .addCase(getAllSports.rejected, (state: TAllSportsState) => ({
        ...state,
        sportsLoading: false,
      }));
  },
});

export const { setAllSports, setCustomMarketModal } = allSportsSlice.actions;

export default allSportsSlice.reducer;
