import React, { FC } from 'react';
import { Badge, Icon, SystemStyleObject, VStack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { EGeneralStatus } from '../../../../lib/DBModels';
import { getStrings } from '../../../utils';
import { StatusColorStyles, BadgeSizeStyles } from '../Styles';
import { HourglassEnd } from '@styled-icons/fa-solid/HourglassEnd';

interface StatusBadgeProps {
  status: EGeneralStatus;
  isSuspended?: boolean;
  isManualOverride?: boolean;
  size: 'sm' | 'md' | 'lg';
  sx?: SystemStyleObject;
  sxWrapper?: SystemStyleObject;
}

export const StatusBadge: FC<StatusBadgeProps> = ({
  status,
  isSuspended = false,
  isManualOverride = false,
  size,
  sx,
  sxWrapper,
}) => {
  if (!status || !size) return null;

  const [
    {
      Generic: { GeneralStatus: LocalisedStrings, Suspended },
    },
  ] = getStrings();

  // Override when is_disabled is true as this isn't something that can be added to status on BE and must be a separate prop
  const label = isSuspended ? Suspended : LocalisedStrings[status];

  const { backgroundColour, textColour } = isSuspended
    ? StatusColorStyles.Suspended
    : StatusColorStyles[status];

  return (
    <VStack sx={sxWrapper}>
      <Badge
        sx={{
          textTransform: 'none',
          textAlign: 'center',
          borderRadius: 'md',
          lineHeight: 3,
          bgGradient: backgroundColour,
          color: textColour,
          '&&': { mb: '1' },
          ...BadgeSizeStyles[size],
          ...sx,
        }}
      >
        {status === EGeneralStatus.ManuallySettled && (
          <Icon as={HourglassEnd} mr="0.5" />
        )}
        {label}
      </Badge>
      {isManualOverride && (
        <Badge
          sx={{
            textTransform: 'none',
            textAlign: 'center',
            borderRadius: 'md',
            bgGradient: 'linear(to-l, gray.400, gray.600)',
            color: 'white',
            lineHeight: 3,
            '&&': { mb: '1', mt: '0' },
            ...BadgeSizeStyles[size],
            ...sx,
          }}
        >
          <FormattedMessage id="trademanagerpage.common.manualoverride" />
        </Badge>
      )}
    </VStack>
  );
};
