import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ApprovalResponse } from '../ApproveRaces/types';
import api from '@/api/api';
import Axios from 'axios';
import { ENV } from '@/lib/Constants';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { usePendingApprovalsStore } from './store';
import { normalizeBetApproval } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import notification from '@/assets/sounds/notification.mp3';
import { TStickyNoteResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals/Components/List';
import toast from 'react-hot-toast';
import { getBookieProfile } from '@/features/settings/pages/Settings/tabs/YourProfile/actions';

export const axios = Axios.create({
  baseURL: ENV.REACT_APP_API_URL,
});

interface IApproveRacesprovider {
  children: ReactNode;
}

export function ApproveBetProvider({ children }: IApproveRacesprovider) {
  const hasConnection = useRef(false);
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState<User | null>();
  const { insertOrCreatePendingApproval, removePendingApproval } =
    usePendingApprovalsStore();

  useEffect(() => {
    if (isWincore)
      onAuthStateChanged(auth, (user) => setCurrentUser(user ?? null));
  }, [auth]);

  useEffect(() => {
    (async () => {
      if (!isWincore) return;
      if (hasConnection.current) return;
      try {
        const { data } = await api.get<{ url: string; access_token: string }>(
          `${ENV.REACT_APP_API_URL}/bookie/bet-approvals/global/connection-details`
        );

        if (data?.url) {
          const hbu = new HubConnectionBuilder()
            .withUrl(data?.url ?? '', {
              accessTokenFactory: () => data.access_token,
            })
            .configureLogging(LogLevel.Information)
            .build();

          getBookieProfile();

          hbu.on('SendMessage', async (message: ApprovalResponse) => {
            if (message.status === 'Pending') {
              try {
                const { data: noteData } = await api.get<TStickyNoteResponse[]>(
                  `${ENV.REACT_APP_API_URL}/bookie/bet-approvals/global/punter-details?punter_ids=${message.punter_id}&source_url=${message.source_api_url}`
                );
                message.stickyNote = noteData[0].sticky_note;
              } catch (e) {
                console.error('Notes failed to fetch');
              }

              insertOrCreatePendingApproval(
                normalizeBetApproval(message),
                false
              );
              await new Audio(notification as string).play();
            } else if (
              message.status === 'Approved' ||
              message.status === 'Rejected' ||
              message.status === 'ReducedStake'
            ) {
              removePendingApproval(normalizeBetApproval(message).requestId);
            }
          });

          // Start the connection
          const startConnection = async () => {
            await hbu.start();
            await api
              .post(
                `/bookie/bet-approvals/global/add-connection?connection=${hbu.connectionId}`,
                undefined
              )
              .then((res) => res.data);
            hasConnection.current = true;
          };

          hbu.onclose(async () => {
            try {
              toast.error('Approved Races - Connection lost. Reconnecting...');
              hasConnection.current = false;
              startConnection();
              toast.success('Approved Races - Connection reestablished.');
            } catch (e) {
              toast.error(
                'Approved Races - Connection lost. Please refresh the page.'
              );
              hasConnection.current = false;
            }
          });

          startConnection();
          return hbu;
        } else return null;
      } catch (error) {}
    })();
  }, [currentUser, insertOrCreatePendingApproval, removePendingApproval]);

  return <>{children}</>;
}
