import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useRaceDetailsMulti } from './MultiBets.hooks';

import { Pagination } from '../../../../../../../common/components/Pagination/Pagination';
import MultiMonitorContainer from '@/features/tradeManager/components/Multimonitor/Multimonitor';

export default function MultiBets() {
  const { data, setOffset, offset, isLoading, isLastFetch } =
    useRaceDetailsMulti();

  return (
    <Flex
      width="100%"
      direction="column"
      py="4"
      pr="2"
      borderTop="1px solid"
      borderTopColor="gray.200"
    >
      <MultiMonitorContainer data={data} loading={isLoading} />
      <Pagination
        nextDisabled={isLastFetch}
        offset={offset}
        onPageChange={(newOffset) => setOffset(newOffset)}
      />
    </Flex>
  );
}
