import React from 'react';
import { BetsTable } from '@/common/components';
import { useAffiliates } from '../services/Affiliates.hooks';
import { CreateAffiliatePromo } from './components/CreateAffiliatePromo';
import { Column } from '@tanstack/react-table';
import {
  TAffiliate,
  TAffiliatePromo,
} from '@/api/promoManager/affiliates/affiliates.types';
import { CreateAffiliate } from './components/CreateAffiliate';
import { DeactivateAffiliate } from './components/DeactivateAffiliate';
import { UpdateAffiliate } from './components/UpdateAffiliate';

export default function Affiliates() {
  const {
    columns,
    promoColumns,
    data,
    affiliateName,
    setAffiliateName,
    affiliatePromoData,
    selectedAffiliateForNewPromo,
    setSelectedAffiliateForNewPromo,
    selectedAffiliateForDeactivation,
    setSelectedAffiliateForDeactivation,
    selectedAffiliateEdit,
    setSelectedAffiliateEdit,
  } = useAffiliates();

  return (
    <>
      <BetsTable
        data={data ?? []}
        columns={columns as unknown as Column<TAffiliate>[]}
        loading={false}
        onClickExpand={(row: TAffiliate) => {
          setAffiliateName(
            affiliateName === row.affiliate_name
              ? undefined
              : row.affiliate_name
          );
        }}
        hasSubTable
      >
        <BetsTable
          columns={promoColumns as unknown as Column<TAffiliatePromo>[]}
          data={affiliatePromoData?.promotions ?? []}
          loading={false}
        />
      </BetsTable>
      <CreateAffiliate />
      {!!selectedAffiliateEdit && (
        <UpdateAffiliate
          values={selectedAffiliateEdit}
          setSelectedAffiliateEdit={setSelectedAffiliateEdit}
        />
      )}

      {!!selectedAffiliateForNewPromo && (
        <CreateAffiliatePromo
          selectedAffiliate={selectedAffiliateForNewPromo}
          setSelectedAffiliateForNewPromo={setSelectedAffiliateForNewPromo}
        />
      )}
      <DeactivateAffiliate
        selectedAffiliateForDeactivation={selectedAffiliateForDeactivation}
        setSelectedAffiliateForDeactivation={
          setSelectedAffiliateForDeactivation
        }
      />
    </>
  );
}
