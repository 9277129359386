import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TPunterState,
  TPunterActivityLog,
  TNote,
  TPunterTransactionsResponse,
} from './types';
import {
  getPunterActivityLogs,
  getPunterTransactions,
  getPunterCurrentDepositLimit,
  getPunterDepositLimit,
} from './actions';
import {
  TPunterCurrentDepositLimit,
  TPunterDepositLimit,
  TPunterStats,
} from '../../../../lib/DBModels';

const initialState: TPunterState = {
  punterActivityLogsLoading: false,
  punterActivityLogs: [],
  punterNotes: [],
  punterStats: undefined,
  depositLimits: [],
  currentDepositLimit: undefined,
  punterTransactionsData: {
    total_records: 0,
    total_pages: 0,
    records: [],
  },
  depositId: '',
  punterTransactionsDataLoading: false,
  modalRemovePayment: false,
  modalEditPayment: false,
};

const punter = createSlice({
  name: 'punter',
  initialState,
  reducers: {
    setPunterActivityLogs: (
      state: TPunterState,
      action: PayloadAction<TPunterActivityLog[]>
    ) => ({ ...state, punterActivityLogs: action.payload }),
    setPunterNotes: (state: TPunterState, action: PayloadAction<TNote[]>) => ({
      ...state,
      punterNotes: action.payload,
    }),
    setPunterStats: (
      state: TPunterState,
      action: PayloadAction<TPunterStats | undefined>
    ) => ({ ...state, punterStats: action.payload }),
    setPunterDepositLimits: (
      state: TPunterState,
      action: PayloadAction<TPunterDepositLimit[]>
    ) => ({
      ...state,
      depositLimits: action.payload,
    }),
    setPunterCurrentDepositLimit: (
      state: TPunterState,
      action: PayloadAction<TPunterCurrentDepositLimit | undefined>
    ) => ({
      ...state,
      currentDepositLimit: action.payload,
    }),
    setPunterTransactions: (
      state: TPunterState,
      action: PayloadAction<TPunterTransactionsResponse>
    ) => ({
      ...state,
      punterTransactionsData: action.payload,
    }),
    setModalBonusBets: (
      state: TPunterState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      modalBonusBets: action.payload,
    }),
    setReconcileModal: (
      state: TPunterState,
      action: PayloadAction<number>
    ) => ({
      ...state,
      modalReconcile: action.payload,
    }),
    setReconcileDepositId: (
      state: TPunterState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      depositId: action.payload,
    }),
    setModalBalanceAdjustment: (
      state: TPunterState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      modalBalanceAdjustment: action.payload,
    }),
    setModalRemovePayment: (
      state: TPunterState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      modalRemovePayment: action.payload,
    }),
    setModalEditPayment: (
      state: TPunterState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      modalEditPayment: action.payload,
    }),
    resetPunter: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPunterActivityLogs.pending, (state: TPunterState) => ({
        ...state,
        punterActivityLogsLoading: true,
      }))
      .addCase(getPunterActivityLogs.fulfilled, (state: TPunterState) => ({
        ...state,
        punterActivityLogsLoading: false,
      }))
      .addCase(
        getPunterCurrentDepositLimit.rejected,
        (state: TPunterState) => ({
          ...state,
          currentDepositLimit: undefined,
        })
      )
      .addCase(getPunterDepositLimit.rejected, (state: TPunterState) => ({
        ...state,
        depositLimits: [],
      }))
      .addCase(getPunterTransactions.pending, (state: TPunterState) => ({
        ...state,
        punterTransactionsDataLoading: true,
      }))
      .addCase(getPunterTransactions.fulfilled, (state: TPunterState) => ({
        ...state,
        punterTransactionsDataLoading: false,
      }))
      .addCase(getPunterTransactions.rejected, (state: TPunterState) => ({
        ...state,
        punterTransactionsDataLoading: false,
      }));
  },
});

// Slice actions
export const {
  setPunterActivityLogs,
  setPunterNotes,
  setPunterTransactions,
  setPunterDepositLimits,
  setPunterCurrentDepositLimit,
  setModalBonusBets,
  setReconcileModal,
  setReconcileDepositId,
  setModalBalanceAdjustment,
  setPunterStats,
  setModalRemovePayment,
  setModalEditPayment,
  resetPunter,
} = punter.actions;

// Slide reducer
export default punter.reducer;
