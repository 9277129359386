import { Stack, Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { TFormikValues } from '../Form/FormHOC';
import GroupCheckbox from './GroupCheckbox';
import { TextTitle, TextError } from './BCCheckbox.styles';

type TCheckboxType = {
  values: Record<string, string>;
  name: string;
  title: string;
  banner: string[];
};

const BCCheckbox = ({ values, name, title, banner }: TCheckboxType) => {
  const [selectedStates, setSelectedStates] = useState<string[]>(banner ?? []);
  const { setFieldValue } = useFormikContext<TFormikValues>();

  const handleCheckboxChange = (state: string) => {
    const updatedStates = [...selectedStates];

    if (updatedStates.includes(state)) {
      updatedStates.splice(updatedStates.indexOf(state), 1);
    } else {
      updatedStates.push(state);
    }

    setFieldValue(name, updatedStates);
    setSelectedStates(updatedStates);
  };

  const isAllChecked = selectedStates.length === 8;

  return (
    <Box mb="5">
      <TextTitle>{title}</TextTitle>
      <TextError>
        <ErrorMessage name={name} component="div" className="error-message" />
      </TextError>

      {Object.keys(values).map((key) => {
        const stateKey = key;
        const state = values[stateKey];
        return (
          <Stack mt={1} spacing={3} key={state}>
            <GroupCheckbox
              value={state}
              isChecked={
                isAllChecked ||
                selectedStates.includes(stateKey) ||
                selectedStates.includes('All')
              }
              name={name}
              onChange={() => handleCheckboxChange(stateKey)}
            />
          </Stack>
        );
      })}
    </Box>
  );
};

export default BCCheckbox;
