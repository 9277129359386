import api from '../../api';
import {
  TQueryTokenSummaryParams,
  TQueryTokenSummaryResponse,
} from './tokenSummary.types';

export const tokenSummaryEndpoint = '/bookie/promos/token/token-summary';

export async function queryTokenSummary(params: TQueryTokenSummaryParams) {
  const res = await api.get<TQueryTokenSummaryResponse>(tokenSummaryEndpoint, {
    params,
  });
  return res;
}
