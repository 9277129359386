import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TResultedBetsState } from './types';
import { TBetsResponse } from '../../types';
import { getResultedBets } from './actions';
import { resultedBetReturnCalculator } from '../../../../../../common/utils';
import { TBet } from '../../../../../../lib/DBModels';

const initialState: TResultedBetsState = {
  query: {},
  resultedBetsResponse: {
    records: [],
    total_pages: 1,
    total_records: 0,
  },
  betsLoading: false,
};

const resultedBetsSlice = createSlice({
  name: 'resultedBets',
  initialState,
  reducers: {
    setResultedBetsResponse: (
      state: TResultedBetsState,
      action: PayloadAction<TBetsResponse>
    ) => {
      const updatedBetResponse = action.payload.records?.map((bet) => ({
        ...bet,
        return: resultedBetReturnCalculator(
          bet?.status,
          bet?.is_won,
          bet?.stake,
          Number(bet?.odds?.toFixed(2)),
          bet?.is_bonus,
          bet?.bonus_stake
        ),
      })) as TBet[];

      return {
        ...state,
        resultedBetsResponse: {
          ...action.payload,
          records: updatedBetResponse,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResultedBets.pending, (state: TResultedBetsState) => ({
        ...state,
        betsLoading: true,
      }))
      .addCase(getResultedBets.fulfilled, (state: TResultedBetsState) => ({
        ...state,
        betsLoading: false,
      }))
      .addCase(getResultedBets.rejected, (state: TResultedBetsState) => ({
        ...state,
        betsLoading: false,
      }));
  },
});

export const { setResultedBetsResponse } = resultedBetsSlice.actions;

export default resultedBetsSlice.reducer;
