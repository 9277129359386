import { Button, ButtonProps, chakra, ChakraComponent } from '@chakra-ui/react';

export const ButtonRow: ChakraComponent<'button', ButtonProps> = chakra(
  Button,
  {
    baseStyle: () => ({
      borderRadius: '0',
      bg: 'transparent',
      h: '100%',
      w: 'full',
      p: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      _hover: { bg: 'gray.50' },
    }),
  }
);
