import { useMutation, UseMutationOptions } from 'react-query';
import { mutateSettleSportMarketPropositions } from './settleSportMarketPropositions';
import { TSettleSportMarketPropositionsBody } from './settleSportMarketPropositions.types';

export const useMutationSettleSportMarketPropositions = (
  options?: UseMutationOptions<
    unknown,
    unknown,
    TSettleSportMarketPropositionsBody
  >
) => useMutation((data) => mutateSettleSportMarketPropositions(data), options);
