import React from 'react';
import {
  Card,
  CardBody,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { TRealMoneyAndBonusBets } from '../utils/utils';
import { RealMoneyAndBonusBetsItem } from './RealMoneyAndBonusBetsItem';

const TAB_ITEMS = ['Real Money', 'Bonus Bets'];

interface IRealMoneyAndBonusBetsProps {
  realMoney: TRealMoneyAndBonusBets['realMoney'];
  bonusBet: TRealMoneyAndBonusBets['bonusBets'];
}

export function RealMoneyAndBonusBets({
  realMoney,
  bonusBet,
}: IRealMoneyAndBonusBetsProps) {
  return (
    <Card w={'full'} bg="transparent" borderRadius={'6px'} boxShadow="none">
      <CardBody p={0}>
        <Tabs>
          <TabList
            border="none"
            bg={'rgba(0, 156,256, 0.05)'}
            boxShadow={
              ' 0px 0px 5px 1px #094C8B inset,0px 0px 0px 1px #36C4FF inset'
            }
            borderRadius={'6px'}
          >
            {TAB_ITEMS.map((item) => (
              <Tab
                key={item}
                fontSize={'xs'}
                fontWeight="bold"
                _selected={{
                  color: '#36C4FF',
                }}
                color="white"
              >
                {item}
              </Tab>
            ))}
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="#36C4FF"
            borderRadius="1px"
          />
          <TabPanels px="0">
            <TabPanel px="0">
              <Flex justifyContent="space-between" gap="4" flexWrap="wrap">
                {realMoney.map((item) => (
                  <RealMoneyAndBonusBetsItem {...item} key={item.title} />
                ))}
              </Flex>
            </TabPanel>
            <TabPanel px="0">
              <Flex justifyContent="space-between" gap="4" flexWrap="wrap">
                {bonusBet.map((item) => (
                  <RealMoneyAndBonusBetsItem {...item} key={item.title} />
                ))}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
}
