/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { TBet } from './bets.types';
import { queryBets } from './bets';
import { TBetsParams } from './bets.types';

// Regulator - Betting History

type TQueryBetsHook = {
  params?: TBetsParams;
  key?: unknown[];
  options?: UseQueryOptions<TBet[]>;
};

export const useQueryBets = ({
  params = {},
  key = [],
  options = {},
}: TQueryBetsHook = {}) => {
  const query = useQuery(
    [keys.bets, ...Object.values(params), ...key],
    () => queryBets(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
