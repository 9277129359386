import {
  Badge,
  Center,
  CenterProps,
  List,
  ListIcon,
  ListItem,
  useFormControl,
} from '@chakra-ui/react';
import { FileEarmarkCheck } from '@styled-icons/bootstrap/FileEarmarkCheck';
import { FileEarmarkX } from '@styled-icons/bootstrap/FileEarmarkX';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

export const Dropzone = (props: DropzoneOptions) => {
  const { isDragActive, acceptedFiles, getRootProps, getInputProps } =
    useDropzone(props);
  const formControl = useFormControl(props);
  const { 'aria-invalid': isInvalid } = formControl;

  const centerProps: CenterProps = {
    bg: isDragActive ? 'blue.50' : 'transparent',
    border: '1px dashed',
    borderColor: isDragActive ? 'blue.500' : isInvalid ? 'red.500' : 'gray.300',
    borderRadius: 'base',
    cursor: 'pointer',
    p: '10',
    transitionDuration: 'normal',
    transitionProperty: 'common',
    _hover: isInvalid ? undefined : { borderColor: 'gray.400' },
  };

  return (
    /**
     * Props should always pass through the `getRootProps` function - the
     * `__css` prop allows us do this while also being type safe.
     *
     * @see {@link https://react-dropzone.js.org/}
     */
    <Center {...getRootProps({ __css: centerProps })}>
      <input {...getInputProps(formControl)} />

      {acceptedFiles.length ? (
        <List spacing={3}>
          {acceptedFiles.map(({ name }, index) => (
            <ListItem key={index} noOfLines={1}>
              <ListIcon
                as={isInvalid ? FileEarmarkX : FileEarmarkCheck}
                color={isInvalid ? 'red.500' : 'green.500'}
                verticalAlign="middle"
              />
              {name}
            </ListItem>
          ))}
        </List>
      ) : (
        <Badge>
          {isDragActive ? (
            <FormattedMessage id="promomanagerpage.dragactivedropzone" />
          ) : (
            <FormattedMessage id="promomanagerpage.dropzone" />
          )}
        </Badge>
      )}
    </Center>
  );
};
