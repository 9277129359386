export const Tooltip = {
  baseStyle: {
    py: '4',
    px: '5',
    borderRadius: 'lg',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
