import React, { useState } from 'react';
import { useAllBetsColumns } from './services/AllBets.config';
import { BetsTable, LoadingSpinner } from '@/common/components';
import { keys } from '@/api/api.keys';
import { querySGMBets } from '@/api/sgm/bets/bets';
import { useQuery } from 'react-query';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { useSearchParams } from 'react-router-dom';

export default function AllBets() {
  const [params] = useSearchParams();
  const matchId = params.get('id');
  const { allBetColumns } = useAllBetsColumns();

  const limit = 20;
  const [offset, setOffset] = useState<string>('0');
  const { data, isLoading } = useQuery([keys.sgmBets, !!matchId, offset], () =>
    querySGMBets({ match_id: matchId ?? '', limit, offset })
  );
  const isLastFetch = (data?.bets?.length ?? 0) < limit;

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <BetsTable
        columns={allBetColumns}
        data={data?.bets ?? []}
        loading={isLoading}
      />

      <Pagination
        nextDisabled={isLastFetch}
        offset={Number(offset)}
        onPageChange={(newOffset) => setOffset(String(newOffset))}
        useHistory
        nextOffsetId={!isLastFetch ? data?.paging?.next_offset : null}
      />
    </>
  );
}
