import api from '../api';
import {
  TMutatePromosBody,
  TMutatePromosResponse,
} from './bulkPromoUpload.types';

export const bulkPromoUploadEndpoint = '/bookie/promos/promo/bulk-promo-upload';

export async function mutatePromos(body: TMutatePromosBody) {
  const res = await api.post<TMutatePromosResponse>(
    bulkPromoUploadEndpoint,
    body
  );
  return res;
}
