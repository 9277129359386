import { rest } from 'msw';
import { TBookieProfile } from '../types';
import { profileMock } from '@/api/profile/profile.mocks';
import { ENV } from '@/lib/Constants';

const baseURL = ENV.REACT_APP_API_URL;

export const BookieProfileMock: TBookieProfile = {
  id: '123',
  email: 'booke@bookie.com',
  first_name: 'Bookie',
  last_name: 'Bloggs',
  mobile: '0400000000',
  licensed_state: 'VIC',
  license_id: '123',
};

const handlerBookieProfileGet = rest.get(
  `${baseURL}/bookie/settings/profile`,
  (req, res, ctx) => res(ctx.json(profileMock))
);

const handlerBookieProfileUpdate = rest.post<TBookieProfile>(
  `${baseURL}/bookie/settings/profile`,
  (req, res, ctx) =>
    res(
      ctx.json({
        ...req.body,
        first_name: 'Updated first name!',
        licensed_state: 'QLD',
      })
    )
);

export const handlerBookieProfile = [
  handlerBookieProfileGet,
  handlerBookieProfileUpdate,
];
