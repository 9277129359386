import React, { ReactNode } from 'react';
import { Grommet as GrommetProvider } from 'grommet';
import GrommetTheme from '../../../styles/GrommetTheme';

type TGrommet = {
  children: ReactNode;
};

export default function Grommet({ children }: TGrommet) {
  return (
    <GrommetProvider theme={GrommetTheme} background="none" full>
      {children}
    </GrommetProvider>
  );
}
