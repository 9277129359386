import { useState } from 'react';
import { useQueryEvenShotBets } from '@/api/tradeManager/evenshot/evenshot.hooks';
import { useQueryBetsForRunner } from '@/api/tradeManager/raceDetails/betsForRunner/betsForRunner.hooks';

export const useRaceDetailsEvenShot = () => {
  const { data, isLoading } = useQueryEvenShotBets();

  return {
    data,
    isLoading,
  };
};

export const usePunterEvenshotBets = (runnerId: string) => {
  const limit = 20;

  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState('');
  const [offsetHistory] = useState<string[]>([]);

  const shouldRefetch = pageIndex === 0;

  const next = () => {
    if (!data) return;
    setOffset(data.paging?.next_offset ?? '');
    setPageIndex((prevIdx) => ++prevIdx);
  };
  const previous = () => {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  };

  const { data, isLoading } = useQueryBetsForRunner({
    params: {
      ...(offset && { offset }),
      limit,
      race_runner: runnerId,
      price_type: 'even_shot',
    },
    options: {
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
      staleTime: shouldRefetch ? 0 : 60 * 5000,
    },
  });

  return {
    data,
    isLoading,
    next,
    previous,
    previousDisabled: pageIndex === 0,
    nextDisabled: data?.paging?.next_offset === '',
    hidePagination: pageIndex === 0 && data?.paging?.next_offset === null,
  };
};
