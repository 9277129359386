import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { useSettle } from '../services/Settlement.hooks';
import { TSettleFormValues } from '../Settlement.types';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const { initialValues, validationSchema, onSubmit } = useSettle();

  if (!initialValues) return null;

  return (
    <Formik<TSettleFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
