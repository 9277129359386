import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import SGMLogo from '@/assets/icons/sgm-icon.svg';
import { BonusBet } from '../../EventMarketInfo/EventMarketInfo.styles';
import { GiftPack } from '@/assets/icons';
import Countdown from '@/components/Countdown/Countdown';

interface IMarketInfoSGMProps {
  legs: number;
  matchName: string;
  timeStamp: string;
  isBonusBet: boolean;
  items: {
    propositionName: string;
    marketName: string;
  }[];
}

export function MarketInfoSGM({
  legs,
  matchName,
  timeStamp,
  isBonusBet,
  items,
}: IMarketInfoSGMProps) {
  return (
    <Box>
      <Flex>
        <Text fontWeight="bold">{legs} Leg Multi </Text>
        <Image src={SGMLogo} ml="1" alt="Same Game Multi Icon" />
      </Flex>
      <Text>{matchName}</Text>
      <Box
        sx={{
          '& > div': {
            justifyContent: 'left',
          },
        }}
      >
        <Countdown eventTime={timeStamp} />
      </Box>
      {isBonusBet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>Bonus</Text>
        </BonusBet>
      )}
      {items.map((item) => (
        <Text
          key={item.propositionName}
        >{`${item.propositionName} - ${item.marketName}`}</Text>
      ))}
    </Box>
  );
}
