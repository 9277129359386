import api from '../../api';
import { TRaceIDQuery } from './evenshot.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const evenshotBetsURL = `/${getPrefixPath(isWincore)}/trade-manager/even-shot`;

// Trade Manager - Evenshot Bets
export const queryEvenShotBets = (params: TRaceIDQuery) =>
  api
    .get<Api['EvenShotRaceBetStats']>(evenshotBetsURL, { params })
    .then((res) => res.data);
