import React, { useMemo } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { centsToDollars } from '@/common/utils';
import { ColumnDef } from '@tanstack/react-table';
import { usePunterCategory } from '@/hooks/usePunterCategory';
import { formatSRMPlacement } from '../SRMBets.utils';
import {
  PlayerIcon,
  UserBadge,
} from '@/common/ui/Header/components/Notification/styles/Notification.styles';

export const useSRMCombinationColumns = () => {
  const columns = useMemo<
    ColumnDef<Api['SRMultisRaceCombinationStatsItemResponse']>[]
  >(
    () => [
      {
        accessorKey: 'runners',
        header: 'Combination',
        cell: ({ row }) => {
          const { runners } = row.original;
          return (
            <Flex flexDirection="column">
              {runners?.map((runner) => (
                <Flex
                  key={runner.name}
                  flexDirection="column"
                  paddingBottom={2}
                >
                  <Box>
                    <b>{`${runner.number}. ${runner.name}`}</b>
                  </Box>
                  <Box>{formatSRMPlacement(runner.top_selection)}</Box>
                </Flex>
              ))}
            </Flex>
          );
        },
      },
      {
        id: 'bets',
        accessorKey: 'bets_count',
        header: 'Bets',
      },
      {
        accessorKey: 'punters_count',
        header: 'Number of punters',
      },
      {
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => centsToDollars(row.original.stake ?? 0),
      },
      {
        accessorKey: 'odds',
        header: 'Odds',
      },
      {
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => centsToDollars(row.original.exposure ?? 0),
      },
    ],
    []
  );

  return { columns };
};

export const useSRMCombinationSubColumns = () => {
  const { getPunterCategoryColor, getPlayerIcon } = usePunterCategory();

  const subColumns = useMemo<
    ColumnDef<Api['SRMultisRaceCombinationPunterStatsItemResponse']>[]
  >(
    () => [
      {
        accessorKey: 'name',
        header: 'Punter',
        cell: ({ row }) => (
          <Link to={`/punter/${row.original.id}`}>
            <Flex flexDir="column" minW="8.5rem">
              <Box>
                <Text>{row.original.name}</Text>
              </Box>
              <Flex flexDir="row" alignItems="center">
                <UserBadge
                  bg={getPunterCategoryColor(
                    row.original.category as Api['PunterCategories']
                  )}
                >
                  <PlayerIcon
                    color="white"
                    src={getPlayerIcon(
                      row.original.category as Api['PunterCategories']
                    )}
                  />
                </UserBadge>
                {row.original.category}
              </Flex>
            </Flex>
          </Link>
        ),
      },
      {
        accessorKey: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => (
          <Box width="12rem" mr="4">
            {row.original.bets_count}
          </Box>
        ),
      },
      {
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => centsToDollars(row.original.stake ?? 0),
      },
      {
        accessorKey: 'odds',
        header: 'Odds',
      },
      {
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => centsToDollars(row.original.exposure ?? 0),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { subColumns };
};
