import api from '@/api/api';
import { TGetSportParams, TSport } from './getSport.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const getSportURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/get-sport`;

// Trade Manager - Get Sport

export const queryGetSport = (params: TGetSportParams) =>
  api.get<TSport[]>(getSportURL, { params }).then((res) => res.data);
