import api from '@/api/api';
import { TIgnoreWinterSettlementBody } from './setIgnoreWinterSettlement.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const ignoreWinterSettlementURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/set-ignore-winter-settlement`;

// Post: Ignore Winter Settlement
export const mutateIgnoreWinterSettlement = (
  body: TIgnoreWinterSettlementBody
) =>
  api
    .post<TIgnoreWinterSettlementBody>(ignoreWinterSettlementURL, body)
    .then((res) => res.data);
