/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { TTransactionCsv } from './downloadTransactions.types';
import { queryDownloadTransactions } from './downloadTransactions';
import { TDownloadTransactionsParams } from './downloadTransactions.types';

// Regulator - Financial Transaction History

type TQueryDownloadTransactionsHook = {
  params?: TDownloadTransactionsParams;
  key?: unknown[];
  options?: UseQueryOptions<TTransactionCsv>;
};

export const useQueryDownloadTransactions = ({
  params = {},
  key = [],
  options = {},
}: TQueryDownloadTransactionsHook = {}) => {
  const query = useQuery(
    [keys.downloadTransactions, ...Object.values(params), ...key],
    () => queryDownloadTransactions(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
