import React from 'react';
import { TRunner } from '@/lib/DBModels';
import { useMysteryBetRunnerColumns } from './Runners.config';
import { BetsTable } from '@/common/components';
import { Column } from '@tanstack/react-table';

type TAllBetsContainer = {
  runners: TRunner[];
  isLoading: boolean;
};
export default function RunnersContainer({
  runners,
  isLoading,
}: TAllBetsContainer) {
  const { columns } = useMysteryBetRunnerColumns();

  return (
    <BetsTable
      columns={columns as unknown as Column<TRunner>[]}
      data={runners ?? []}
      loading={isLoading}
    />
  );
}
