import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateUpdateProposition } from './updateProposition';
import { TUpdatePropositionBody } from './updateProposition.types';

export const useMutationUpdateProposition = (
  options?: Omit<
    UseMutationOptions<
      TMarketCreationResponse,
      unknown,
      TUpdatePropositionBody
    >,
    'mutationFn'
  >
) => useMutation(mutateUpdateProposition, options);
