import React, { useState } from 'react';
import {
  Checkbox,
  Box,
  VStack,
  Text,
  Tooltip,
  Th,
  Icon,
  Tr,
  Td,
  Radio,
  Collapse,
} from '@chakra-ui/react';
import { ErrorMessage, useFormikContext } from 'formik';
import { InfoCircle } from '@styled-icons/fa-solid/InfoCircle';
import { FormattedMessage } from 'react-intl';
import {
  ModalBodyForm,
  FlexRow,
  IconInfo,
  InputExpectedWin,
  TextError,
  TextStub,
} from './styles/Form.styles';
import { TSettleFormValues } from '../../Settlement.types';
import { useSettle } from '../../services/Settlement.hooks';
import { EResult } from '@/api/tradeManager/sportDetails/settleSportMarketPropositions/settleSportMarketPropositions.types';
import TableChakraConfigure from '@/common/components/TableChakraConfigure/TableChakraConfigure';

export default function Form() {
  const context = useFormikContext<TSettleFormValues>();

  const [noWinState, setNoWinState] = useState(false);

  const {
    intl,
    match,
    propositions,
    propositionsFormValues,
    settleOptions,
    showManualSettings,
    handleCheckBoxChange,
    handleNoWinCheckBoxChange,
  } = useSettle(context);

  return (
    <ModalBodyForm>
      <TableChakraConfigure
        variant="simple"
        size="sm"
        header={settleOptions.map(({ option, tooltip }) => (
          <Th key={option} textAlign="center">
            {option}

            {tooltip && (
              <Tooltip label={tooltip} placement="top">
                <Icon ml="0.5" mb="0.5" as={InfoCircle} />
              </Tooltip>
            )}
          </Th>
        ))}
      >
        <Tr>
          <Td py="0.5" px="0">
            <TextStub>
              <FormattedMessage id="trademanagerpage.config.settle.settletype" />
            </TextStub>
          </Td>
          {settleOptions.map(({ option }) => (
            <Td key={`settle-${option}`} textAlign="center">
              <Radio
                value={option}
                isChecked={context.values.settle_option === option}
                onChange={() => context.setFieldValue('settle_option', option)}
              />
            </Td>
          ))}
        </Tr>
      </TableChakraConfigure>
      <Box
        as={Collapse}
        in={showManualSettings}
        px="1px" // to avoid boxShadows being cut off
      >
        <InputExpectedWin
          name="expected_winners"
          propsLabel={{ fontWeight: 'semibold', color: 'gray.700', mt: '8' }}
          label={intl.formatMessage({
            id: 'trademanagerpage.config.settle.expectedwinners',
          })}
        />
        <VStack align="flex-end" mt="8">
          <Tooltip
            label={<FormattedMessage id="trademanagerpage.config.deadheat" />}
            placement="top"
          >
            <IconInfo as={InfoCircle} />
          </Tooltip>
          {propositions?.map((proposition) => {
            const isChecked =
              propositionsFormValues?.find(
                (p) => p.proposition_id === proposition.proposition_id
              )?.result === EResult.Winner;

            return (
              <FlexRow key={proposition.proposition_id}>
                <Text>{proposition.proposition_name}</Text>
                <Checkbox
                  onChange={() =>
                    handleCheckBoxChange(proposition.proposition_id ?? '')
                  }
                  value={proposition.proposition_id}
                  isChecked={isChecked && !noWinState}
                  ml="2"
                />
              </FlexRow>
            );
          })}
          {match?.sport_type === 'Promotional' && (
            <FlexRow key={match?.sport_id}>
              <Text>
                <FormattedMessage id="trademanagerpage.config.settle.nowinner" />
              </Text>
              <Checkbox
                onChange={() => {
                  setNoWinState(!noWinState);
                  if (!noWinState) {
                    handleNoWinCheckBoxChange();
                  }
                }}
                value={match?.sport_id}
                isChecked={noWinState}
                ml="2"
              />
            </FlexRow>
          )}
        </VStack>

        <ErrorMessage
          name="propositions"
          render={(msg) => <TextError>{msg}</TextError>}
        />
      </Box>
    </ModalBodyForm>
  );
}
