import {
  ERegulatorFilters,
  ERegulatorPathname,
} from '@/views/Regulator/Regulator.types';
import { useTheme } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

export const useRegulatorForm = () => {
  const location = useLocation();

  const pathname = location.pathname.split('/')[2];

  const theme = useTheme();

  const intl = useIntl();

  const searchByOptions = [
    {
      value: ERegulatorFilters.ClientName,
      label: intl.formatMessage({
        id: 'regulatorpage.tabs.bethistory.clientname',
      }),
    },
    {
      value: ERegulatorFilters.ClientEmail,
      label: intl.formatMessage({
        id: 'regulatorpage.tabs.bethistory.clientid',
      }),
    },
    ...(pathname === ERegulatorPathname.Bets
      ? [
          {
            value: ERegulatorFilters.Ticket,
            label: intl.formatMessage({
              id: 'regulatorpage.tabs.bethistory.ticketnumber',
            }),
          },
        ]
      : []),
  ];

  const validationSchema = Yup.object().shape({
    searchOption: Yup.string().required(),
    date_from: Yup.string().when('searchOption', {
      is: ERegulatorFilters.ClientName,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    date_to: Yup.string().when('searchOption', {
      is: ERegulatorFilters.ClientName,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    client_name: Yup.string().when('searchOption', {
      is: ERegulatorFilters.ClientName,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    client_email: Yup.string().when('searchOption', {
      is: ERegulatorFilters.ClientEmail,
      then: Yup.string().email('Needs to be a valid email').required(),
      otherwise: Yup.string().email(),
    }),
    ticket_number: Yup.string().when('searchOption', {
      is: ERegulatorFilters.Ticket,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  });

  const initialValues = { searchOption: ERegulatorFilters.ClientName };

  return { searchByOptions, theme, validationSchema, initialValues };
};
