import React from 'react';
import { useFormikContext } from 'formik';
import {
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import {
  centsToDollars,
  getStrings,
} from '../../../../../../../../../common/utils';
import { useModals } from '../../../../Services/BetApprovals.hooks';
import Select from '../../../../../../../../../common/components/FormElements/Select';
import {
  EBetRequestRejectReasons,
  TBetRequestStatusInput,
} from '../../../../Services/BetApprovals.types';
import { enumToLocalisationsDropdown } from '../../../../../../../../../lib/Localisations';
import { getEventNameFromBet } from '@/common/components/BetsTable/components/utils';

export default function Form() {
  const [
    {
      BetApprovalsPage: {
        Modals: { Reject: Strings },
      },
    },
  ] = getStrings();

  const { modalRejectBet } = useModals();
  const {
    isSubmitting,
    values: { reason },
  } = useFormikContext<TBetRequestStatusInput>();

  const statusOptions = enumToLocalisationsDropdown(
    Object.keys(EBetRequestRejectReasons),
    Strings.Reasons
  );

  const eventTitle = getEventNameFromBet(modalRejectBet);

  const isSettlement =
    modalRejectBet?.requestType?.toLowerCase() === 'settlement';

  const modalTitle = Strings.Title.replace(
    '{Name}',
    modalRejectBet?.punterName ?? ''
  )
    .replace(
      '{Stake}',
      centsToDollars(
        isSettlement ? modalRejectBet?.stake : modalRejectBet?.requestedStake,
        true
      )
    )
    .replace('{Event}', eventTitle);

  return (
    <ModalContent>
      <ModalHeader textAlign="center">{modalTitle}</ModalHeader>

      <ModalBody p="0" mx="7" mt="3">
        <HStack mb="2" justifyContent="space-between">
          <Text fontWeight="medium">{Strings.Label}</Text>
          <Text as="i" color="blackAlpha.500">
            {Strings.OptionalLabel}
          </Text>
        </HStack>

        <Select
          name="reason"
          placeholder={Strings.Placeholder}
          color={reason ? 'black' : 'blackAlpha.500'}
          iconColor="black"
        >
          {statusOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" colorScheme="red" isLoading={isSubmitting}>
          {Strings.Button}
        </Button>
      </ModalFooter>
      <ModalCloseButton />
    </ModalContent>
  );
}
