import { rest } from 'msw';
import { baseURL } from '@/__mocks__/utils';
import { tokenSummaryEndpoint } from './tokenSummary';
import { TQueryTokenSummaryResponse } from './tokenSummary.types';

export const tokenSummaryHandlers = [
  rest.get(baseURL(tokenSummaryEndpoint), (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json<TQueryTokenSummaryResponse>([
        {
          token_type: 'spin-wheel',
          available_count: 3,
          used_count: 10,
        },
      ])
    )
  ),
];
