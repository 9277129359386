import React from 'react';
import { MenuAlt2 } from '@styled-icons/heroicons-solid/MenuAlt2';
import { ChevronRight } from '@styled-icons/evil/ChevronRight';
import {
  IconBurger,
  TextBreadcrumb,
  IconChevron,
} from './styles/Navigation.styles';
import { useNavigation } from './services/Navigation.hook';
import { FormattedMessage } from 'react-intl';

export default function SideNavigation() {
  const { breadCrumbs, isLoading, punter, punterId, expandNavPanel } =
    useNavigation();

  if (isLoading) return <></>;

  return (
    <>
      <IconBurger as={MenuAlt2} onClick={expandNavPanel} />
      <TextBreadcrumb to="/trade-manager">
        <FormattedMessage id="generic.home" />
      </TextBreadcrumb>
      {breadCrumbs.map((link, i) => {
        const routeTo = `/${breadCrumbs.slice(0, i + 1).join('/')}`;
        const isPunters = link === 'punter';
        const breadcrumbLink = isPunters ? '/punters/' : routeTo;
        const breadcrumbText =
          link === punterId
            ? `${punter?.first_name} ${punter?.last_name}`
            : isPunters
            ? 'punters'
            : link.replaceAll('-', ' ');

        return (
          <TextBreadcrumb to={breadcrumbLink} key={i}>
            <IconChevron as={ChevronRight} />
            {breadcrumbText}
          </TextBreadcrumb>
        );
      })}
    </>
  );
}
