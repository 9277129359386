import {
  chakra,
  shouldForwardProp,
  ChakraComponent,
  BoxProps,
} from '@chakra-ui/react';
import { motion, isValidMotionProp, MotionProps } from 'framer-motion';

export type TMotion = BoxProps & MotionProps;

export const ChakraMotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
  baseStyle: () => ({
    flex: 'none',
    bg: 'white',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    height: '100%',
  }),
}) as ChakraComponent<'div', TMotion>;
