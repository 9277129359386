export const FormError = {
  parts: ['text'],
  baseStyle: {
    text: {
      fontSize: 'xs',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
