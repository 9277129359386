import { chakra, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const ParentContainer = styled.div``;

export const ContainerTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: '2xl',
    fontWeight: 'normal',
    color: 'gray.500',
    mb: '5',
  }),
});

export const ContainerWrapper = styled.div`
  background: white;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  margin-bottom: 23px;
`;
