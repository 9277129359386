import { keys } from '@/api/api.keys';
import { BetsTable } from '@/common/components/BetsTable/BetsTable';
import LoadingSpinner from '@/common/components/LoadingSpinner';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import { queryBlendedRunners } from '@/api/blended/runners/runners';
import { useBlendedRunnerColumns } from './services/Runners.config';

export default function Runners() {
  const [params] = useSearchParams();
  const raceId = params.get('id');
  const { runnerColumns } = useBlendedRunnerColumns();

  const [history, setHistory] = useState<string[]>([]);
  const limit = 20;
  const [offset, setOffset] = useState<string>('0');
  const { data, isLoading } = useQuery(
    [keys.blendedRunners, offset, !!raceId],
    () => queryBlendedRunners({ race_id: raceId ?? '', limit, offset })
  );
  const isLastFetch = (data?.runners?.length ?? 0) < limit;

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <BetsTable
        columns={runnerColumns}
        data={data?.runners ?? []}
        loading={isLoading}
      />

      <PaginationV3
        nextDisabled={isLastFetch}
        offset={offset}
        history={history}
        setHistory={setHistory}
        onPageChange={(newOffset) => setOffset(newOffset)}
        useHistory
        nextOffsetId={!isLastFetch ? data?.paging?.next_offset : undefined}
      />
    </>
  );
}
