import { useQuery } from 'react-query';
import { keys } from '../../api/api.keys';
import { queryProfile } from '../../api/profile/profile';
import { ENewRelicActions } from '../../constants/newRelicActions';
import { useAuthContext } from '../../lib/firebase/AuthProvider';

export const useNewRelic = () => {
  const { currentUser } = useAuthContext();

  const { data: bookieData } = useQuery(
    [keys.profile, currentUser],
    () => queryProfile(),
    {
      onSuccess: (data) => {
        if (window.newrelic) {
          window.newrelic.addPageAction(ENewRelicActions.Login, {
            status: 200,
            bookie_id: data?.id ?? '',
            success: true,
          });
        }
      },
      enabled: !!currentUser,
    }
  );

  const newRelicLog = (action: string, props: Record<string, unknown> = {}) => {
    const isLogin = props.login;

    if (window.newrelic) {
      window.newrelic.addPageAction(action, {
        bookie_id: isLogin ? props.bookie : bookieData?.id ?? '',
        status_code: !props?.error ? 200 : '4xx/5xx',
        success: !props?.error,
        ...props,
      });
    }
  };

  return newRelicLog;
};
