import styled from 'styled-components';

export const CurrentOddsText = styled.p`
  color: ${({ theme }) => theme.global.colors.primary};
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  margin: 0 5px 0 0;
`;

export const CurrentOddsValue = styled.div`
  color: ${({ theme }) => theme.global.colors.primary};
  font-size: 14px;
  font-weight: 300;
`;

export const WinPlaceLabel = styled.span`
  font-size: 19px;
  font-weight: bold;
  margin-right: 5px;
  color: ${({ theme }) => theme.global.colors.darkBlue};
`;

export const CurrentOddsContainer = styled.div`
  min-width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

export const WinPlaceMarginContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0.7rem;

  &:first-child {
    padding-right: 0;
  }
`;

export const FlucsTable = styled.table`
  & > tbody > tr > td {
    padding: 5px 10px;
    border-bottom: none;
  }
`;

export const RunnerSilk = styled.img`
  width: 32px;
`;

export const RunnerNameContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const UpdateBetsContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const PlaceLabel = styled.div`
  background: ${({ theme }) => theme.global.colors.greyBlue};
  border-radius: 5px;
  padding: 0 0.7rem;
  margin-left: 0.5rem;
`;
