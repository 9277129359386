import api from '@/api/api';
import { getURLBasedOnLocation } from '@/common/utils';
import { TLinks } from '@/features/settings/pages/Settings/tabs/CMS/services/Cms.types';
import { TCMSParams } from './cms.types';

export const queryCMS = async (platform: string): Promise<TLinks[]> => {
  const response = await fetch(
    `https://storage.googleapis.com/assets.${getURLBasedOnLocation(
      platform
    )}/cms/side_nav.json?time=${new Date().getTime()}`
  );

  return response.json();
};

// mutate cms links
export const addCMSLinksURL = '/bookie/cms/side_nav';
export const mutateAddCMSLinks = (body?: TCMSParams) =>
  api.put<TCMSParams>(addCMSLinksURL, body).then((res) => res.data);
