import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { mutateAddBanner, mutateMedia, queryBanners } from './banner';
import {
  TAddBannerParams,
  TUploadMediaParams,
  TUploadMediaData,
  TData,
} from './banner.types';
import { useHeader } from '@/common/ui/Header/services/Header.hooks';
import { keys } from '@/api/api.keys';

export const useMutationAddBanner = (
  options?: UseMutationOptions<unknown, unknown, TAddBannerParams>
) => useMutation((data: TAddBannerParams) => mutateAddBanner(data), options);

export const useMutationUploadMedia = (
  options?: UseMutationOptions<unknown, unknown, TUploadMediaData>
) => {
  const query = useMutation(
    (data: TUploadMediaParams) => mutateMedia(data),
    options
  );

  return query;
};

type TQueryBannerHook = {
  key?: string[];
  options?: UseQueryOptions<TData[], string[]>;
};
export const useQueryBanners = (hook: TQueryBannerHook = {}) => {
  const { key = [], options = {} } = hook;

  const { platform } = useHeader();
  const query = useQuery<TData[], string[]>(
    [keys.banners, ...key],
    () => queryBanners(platform ?? ''),
    {
      ...options,
    }
  );
  return query;
};
