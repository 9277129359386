export const punterBets = [
  {
    bet_id: '6cfdc90f-a288-4b13-a7a7-e784f458aa95',
    created_at: '2022-11-10T23:36:34Z',
    stake: 50000,
    odds: 75.4,
    is_won: null,
    is_bonus: false,
    status: 'pending',
    current_stake_value: 50000,
    payout: null,
    bet_legs: [
      {
        bet_leg_id: 'bb8ffcd9-4f30-4321-912b-1d1d3bdb4194',
        event_id: 'dea46d8c-000b-40d6-8323-6042272d3597',
        bet_description: 'RESULT',
        event_start: '2022-11-12T15:00:00Z',
        event_type: 'Match',
        event_icon: 'Soccer',
        odds: 2.9,
        is_won: null,
        status: 'voided',
        event_data: {
          sport_id: '51e9ba06-a9c9-4edb-8042-b55410cf840f',
          competition_name: 'English Championship',
          competition_id: 'ec157058-cda8-4ed5-8de3-7707a9c8eba8',
          tournament_name: null,
          tournament_id: null,
          match_name: 'Preston v Millwall',
          proposition_name: 'Under 1.5 Goals',
          market_name: 'Test Market',
        },
      },
    ],
    punter_id: 'b2d6999e-2032-4110-8594-1f5141f4ede1',
    punter_name: 'depo sit',
    punter_category: 'Sharp',
  },
  {
    bet_id: 'd4dc5d0c-4e1f-4ffb-92da-ac5a87b6b55d',
    created_at: '2022-11-28T05:42:51Z',
    stake: 1300,
    odds: 13.6,
    is_won: null,
    is_bonus: false,
    status: 'pending',
    current_stake_value: 12800,
    payout: null,
    bet_legs: [
      {
        bet_leg_id: 'ce690e9f-1f27-480a-9441-1c8c98f5a54c',
        event_id: '0e1ed7eb-10ef-4a15-8848-4e2145607ecf',
        bet_description: 'Racing Win',
        event_start: '2022-11-28T05:42:00Z',
        event_type: 'Race',
        event_icon: 'Greyhounds',
        odds: 4,
        is_won: true,
        status: 'pending',
        event_data: {
          venue_name: 'Dubbo',
          venue_id: '582cd9d2-207c-46f4-81fb-50e8a96f3343',
          race_name: 'LADBROKES SAME RACE MULTI MAIDEN HEAT 5',
          race_number: 6,
          race_meeting_date: '2022-11-28',
          runner_name: 'SUBZERO SELMA',
          runner_number: 6,
          runner_barrier_number: 6,
          market_name: 'Racing Win',
        },
      },
      {
        bet_leg_id: 'eefd8e8e-564e-45a5-978b-8995de07cc39',
        event_id: 'aad01b62-b3f6-4820-8da4-6328b068ba73',
        bet_description: 'Racing Win',
        event_start: '2022-11-28T05:45:00Z',
        event_type: 'Race',
        event_icon: 'Harness Racing',
        odds: 3.2,
        is_won: false,
        status: 'pending',
        event_data: {
          venue_name: 'Goulburn',
          venue_id: '9ef69bd0-9c84-4a6f-80d7-f08913bf8ff6',
          race_name: 'ASPIRE TRAFFIC OFFENDER COURSE PACE MS',
          race_number: 5,
          race_meeting_date: '2022-11-28',
          runner_name: 'SHARK DANCER',
          runner_number: 2,
          runner_barrier_number: 0,
          market_name: 'Racing Win',
        },
      },
      {
        bet_leg_id: 'eefd8e8e-564e-45a5-978b-8995de07cc39',
        event_id: 'aad01b62-b3f6-4820-8da4-6328b068ba73',
        bet_description: 'Racing Win',
        event_start: '2022-11-28T05:45:00Z',
        event_type: 'Race',
        event_icon: 'Harness Racing',
        odds: 3.2,
        is_won: true,
        status: 'pending',
        event_data: {
          venue_name: 'Goulburn',
          venue_id: '9ef69bd0-9c84-4a6f-80d7-f08913bf8ff6',
          race_name: 'ASPIRE TRAFFIC OFFENDER COURSE PACE MS',
          race_number: 5,
          race_meeting_date: '2022-11-28',
          runner_name: 'SHARK DANCER',
          runner_number: 2,
          runner_barrier_number: 0,
          market_name: 'Racing Win',
        },
      },
      {
        bet_leg_id: 'eefd8e8e-564e-45a5-978b-8995de07cc39',
        event_id: 'aad01b62-b3f6-4820-8da4-6328b068ba73',
        bet_description: 'Racing Win',
        event_start: '2022-11-28T05:45:00Z',
        event_type: 'Race',
        event_icon: 'Harness Racing',
        odds: 3.2,
        is_won: true,
        status: 'pending',
        event_data: {
          venue_name: 'Goulburn',
          venue_id: '9ef69bd0-9c84-4a6f-80d7-f08913bf8ff6',
          race_name: 'ASPIRE TRAFFIC OFFENDER COURSE PACE MS',
          race_number: 5,
          race_meeting_date: '2022-11-28',
          runner_name: 'SHARK DANCER',
          runner_number: 2,
          runner_barrier_number: 0,
          market_name: 'Racing Win',
        },
      },
      {
        bet_leg_id: 'eefd8e8e-564e-45a5-978b-8995de07cc39',
        event_id: 'aad01b62-b3f6-4820-8da4-6328b068ba73',
        bet_description: 'Racing Win',
        event_start: '2022-11-28T05:45:00Z',
        event_type: 'Race',
        event_icon: 'Harness Racing',
        odds: 3.2,
        is_won: true,
        status: 'pending',
        event_data: {
          venue_name: 'Goulburn',
          venue_id: '9ef69bd0-9c84-4a6f-80d7-f08913bf8ff6',
          race_name: 'ASPIRE TRAFFIC OFFENDER COURSE PACE MS',
          race_number: 5,
          race_meeting_date: '2022-11-28',
          runner_name: 'SHARK DANCER',
          runner_number: 2,
          runner_barrier_number: 0,
          market_name: 'Racing Win',
        },
      },
    ],
    punter_id: 'ca97274b-8a0b-44e8-8daf-14d083819431',
    punter_name: 'Andy Aydin',
    punter_category: 'Newbie',
  },
  {
    bet_id: '61572498-4a3b-4914-bcdc-9b32b9acafb3',
    created_at: '2022-11-21T00:58:11Z',
    stake: 1000,
    odds: 48.75,
    is_won: null,
    is_bonus: false,
    status: 'pending',
    current_stake_value: 10500,
    payout: null,
    bet_legs: [
      {
        bet_leg_id: '0c9ba28b-c2ad-40a0-a5a2-91f7febd8c11',
        event_id: '821c66ff-c2c0-4e07-85ac-fb419c29df51',
        bet_description: 'Racing Win',
        event_start: '2022-11-20T23:27:00Z',
        event_type: 'Race',
        event_icon: 'Greyhounds',
        odds: 10.5,
        is_won: true,
        status: 'settled',
        event_data: {
          venue_name: 'Addington',
          venue_id: 'dcc6416f-febb-4da3-871c-5dfbd0f7a8b8',
          race_name: 'THE FITZ SPORTS BAR PBD SPRINT',
          race_number: 1,
          race_meeting_date: '2022-11-21',
          runner_name: "RYDER'S AMBITION",
          runner_number: 5,
          runner_barrier_number: 5,
          market_name: 'Racing Win',
        },
      },
    ],
    punter_id: '87ef048c-7c73-4399-82d8-8ea7a07c1302',
    punter_name: 'New Guy',
    punter_category: 'High Roller',
  },
];
