import React from 'react';
import { useFormikContext } from 'formik';
import { Text, Heading, Button, Flex, Box } from '@chakra-ui/react';

import Input from '../../../../../../../../common/components/FormElements/Input';

import { getStrings } from '../../../../../../../../common/utils';
import { TBetApprovalLimitValues } from '../../types';

export default function RejectLimitsForm() {
  const [
    {
      Generic,
      SettingsPage: {
        Limits: { BetApprovalLimit: LocalisedStrings },
      },
    },
  ] = getStrings();

  const { isSubmitting, dirty } = useFormikContext<TBetApprovalLimitValues>();

  return (
    <>
      <Heading size="lg" mb={2}>
        {LocalisedStrings.heading}
      </Heading>
      <Text width="4xl" color="gray.600" mb={4}>
        {LocalisedStrings.body}
      </Text>
      <Flex>
        <Box width="xs">
          <Input
            width="30"
            name="limit"
            InputLeftAddon="$"
            label={LocalisedStrings.globalBetLimitInputLabel}
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="exposure_limit"
            InputLeftAddon="$"
            label={LocalisedStrings.exposureLimitInputLabel}
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="exotic_limit"
            InputLeftAddon="$"
            label={LocalisedStrings.exoticLimitInputLabel}
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="cross_game_limit"
            InputLeftAddon="$"
            label={LocalisedStrings.crossGameMultiInputLabel}
          />
        </Box>
      </Flex>

      <Flex justify="flex-end" mt="10">
        <Button
          type="submit"
          variant="rounded"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !dirty}
        >
          {Generic.Save}
        </Button>
      </Flex>
    </>
  );
}
