import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { TabNavLink } from '../../common/components/TabNavLink/TabNavLink';

export default function RegulatorWrapper() {
  return (
    <>
      <Flex
        sx={{ justifyContent: 'start', border: '1px', borderColor: 'gray.100' }}
      >
        <TabNavLink
          to="/regulator/betting-history"
          className="grow"
          data-cy="betHistoryLink"
        >
          <FormattedMessage id="regulatorpage.tabs.bethistory" />
        </TabNavLink>
        <TabNavLink
          to="/regulator/financial-transaction-history"
          className="grow"
          data-cy="financialTransactionHistoryLink"
        >
          <FormattedMessage id="regulatorpage.tabs.financialtransactionhistory" />
        </TabNavLink>
      </Flex>

      <Outlet />
    </>
  );
}
