import React from 'react';
import { useIntl } from 'react-intl';
import { Badge as BCBadge } from '@chakra-ui/react';
import { EBetStatus } from '../../../lib/DBModels';
import { statusColours } from './Services/Config.BetStatusBadge';

export type TBadge = {
  status?: EBetStatus;
};

export default function Badge({ status }: TBadge) {
  const intl = useIntl();

  if (!status) return null;

  const { backgroundColour, textColour } = statusColours[status];

  return (
    <BCBadge
      sx={{
        textTransform: 'none',
        textAlign: 'center',
        borderRadius: 'md',
        lineHeight: '3',
        px: '3',
        py: '1',
        fontSize: 'xs',
        color: textColour,
        background: backgroundColour,
        width: 'fit-content',
      }}
    >
      {intl.formatMessage({ id: `generic.betstatus.${status.toLowerCase()}` })}
    </BCBadge>
  );
}
