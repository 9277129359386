import React, { useState } from 'react';
import { BetsTable, LoadingSpinner } from '@/common/components';
import { usePropositionColumns } from './services/Propositions.config';
import { useQuery } from 'react-query';
import { querySGMPropositions } from '@/api/sgm/propositions/propositions';
import { keys } from '@/api/api.keys';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { useSearchParams } from 'react-router-dom';

export default function AllBets() {
  const [params] = useSearchParams();
  const matchId = params.get('id');
  const { propositionColumns } = usePropositionColumns();

  const limit = 20;
  const [offset, setOffset] = useState<string>('0');
  const { data, isLoading } = useQuery(
    [keys.sgmPropositions, offset, !!matchId],
    () => querySGMPropositions({ match_id: matchId ?? '', limit, offset })
  );

  const isLastFetch = (data?.propositions?.length ?? 0) < limit;
  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <BetsTable
        columns={propositionColumns}
        data={data?.propositions ?? []}
        loading={isLoading}
      />

      <Pagination
        nextDisabled={isLastFetch}
        offset={Number(offset)}
        onPageChange={(newOffset) => setOffset(String(newOffset))}
        useHistory
        nextOffsetId={!isLastFetch ? data?.paging?.next_offset : null}
      />
    </>
  );
}
