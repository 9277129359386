import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { TBetCloudErrorResponse } from '../api.types';
import { mutatePromos } from './bulkPromoUpload';
import {
  TMutatePromosBody,
  TMutatePromosResponse,
} from './bulkPromoUpload.types';

export const useMutationPromos = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TMutatePromosResponse>,
      AxiosError<TBetCloudErrorResponse>,
      TMutatePromosBody
    >,
    'mutationFn'
  >
) => useMutation(mutatePromos, options);
