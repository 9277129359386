/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TBetsData } from '@/api/punters/punters.types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useQuery as useQueryString } from '../../../common/hooks/useQuery';
import { keys } from '../../api.keys';
import {
  querySRMBets,
  querySRMRunners,
  querySRMCombinations,
  querySRMPunterCombinations,
} from './srm';
import { TSRMParams } from './srm.types';

// Trade Manager - SRM Bets
type TQuerySRMBetsHook = {
  params?: TSRMParams;
  key?: unknown[];
  options?: UseQueryOptions<TBetsData[]>;
};

export const useQuerySRMBets = ({
  params = {},
  key = [],
  options = {},
}: TQuerySRMBetsHook = {}) => {
  const q = useQueryString();
  const race_id = q.get('id');

  const query = useQuery(
    [keys.srmBets, race_id, ...Object.values(params), ...key],
    () => querySRMBets({ ...params, race_id }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

export const useQuerySRMRunners = ({
  params = {},
  key = [],
  options = {},
}: TQuerySRMBetsHook = {}) => {
  const q = useQueryString();
  const race_id = q.get('id');

  const query = useQuery(
    [keys.srmRunners, race_id, ...Object.values(params), ...key],
    () => querySRMRunners({ ...params, race_id }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

export const useQuerySRMCombinations = ({
  params = {},
  key = [],
  options = {},
}: TQuerySRMBetsHook = {}) => {
  const q = useQueryString();
  const race_id = q.get('id');

  const query = useQuery(
    [keys.srmCombinations, race_id, ...Object.values(params), ...key],
    () => querySRMCombinations({ ...params, race_id }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

export const useQuerySRMPunterCombinations = ({
  params = {},
  key = [],
  options = {},
}: TQuerySRMBetsHook = {}) => {
  const { multi_uid } = params;

  const query = useQuery(
    [keys.srmPunterCombinations, multi_uid, ...Object.values(params), ...key],
    () => querySRMPunterCombinations({ ...params, multi_uid }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
