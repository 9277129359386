import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { SportFilterNavLink } from './Styles';

type SportFilterProps = NavLinkProps & {
  title?: string;
  dataCy?: string;
  isActive?: boolean;
};

export const SportFilter: FC<SportFilterProps> = ({
  to,
  children,
  title,
  onClick,
  dataCy,
  isActive,
}) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <SportFilterNavLink<any>
    to={to}
    title={title}
    onClick={onClick}
    data-cy={dataCy}
    className={isActive ? 'selected' : 'unselected'}
    end
  >
    {children}
  </SportFilterNavLink>
);
