import { faker } from '@faker-js/faker';
import { TQueryPromoSummaryParams } from './promoSummary.types';

export function makeData({
  limit,
}: TQueryPromoSummaryParams): Api['PromoSummaryResponse'] {
  return {
    promotions: Array(limit)
      .fill('')
      .map(() => {
        const total_punters = faker.datatype.number({ min: 1, max: 10 });
        const max_value = faker.datatype.number(10_000);
        const claimed_punters = faker.datatype.number(total_punters);
        const claimed_amount = faker.datatype.number(max_value);
        const promotion_date = faker.date.recent().toISOString();
        const expiry = faker.date.future(1, promotion_date).toISOString();

        return {
          promo_id: faker.datatype.uuid(),
          name: faker.random.words(),
          file_name: faker.system.commonFileName('csv'),
          reason: 'Deposit Match',
          total_punters,
          max_value,
          claimed_punters,
          claimed_amount,
          promotion_date,
          expiry,
        };
      }),
    offset: faker.date.recent().toISOString(),
  };
}
