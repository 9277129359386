import { Button, ModalFooter } from '@chakra-ui/react';
import React from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../common/hooks/useRedux';
import { setIsConfigureRaceModalOpen } from '../../../../Services/RaceDetails.slices';

export default function Footer() {
  const dispatch = useAppDispatch();

  const { isConfigureRaceModalLoading } = useAppSelector(
    (state) => state.raceDetails
  );

  return (
    <ModalFooter alignItems="flex-end">
      <Button
        variant="outline-rounded"
        borderRadius="lg"
        colorScheme="gray"
        onClick={() => dispatch(setIsConfigureRaceModalOpen(false))}
        disabled={isConfigureRaceModalLoading}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        borderRadius="lg"
        ml="4"
        isLoading={isConfigureRaceModalLoading}
      >
        Save
      </Button>
    </ModalFooter>
  );
}
