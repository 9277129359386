import React from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import SelectReact from 'react-select';
import { FormattedMessage } from 'react-intl';
import { FlexWrapper, selectStyles } from '../styles/Filters.styles';
import ByClientNameForm from './ByClientNameForm';
import ByClientEmailForm from './ByClientEmailForm';
import ByTicketNumberForm from './ByTicketNumberForm';
import { useFormikContext } from 'formik';
import {
  ERegulatorFilters,
  TRegulatorForm,
  TSearchByOption,
} from '@/views/Regulator/Regulator.types';
import { TFormProps } from '../Filters.types';
import { useRegulatorForm } from '../services/Filters.hooks';

const Form = ({
  searchBy,
  onChangeFilter,
  downloadDisabled,
  isDownloading,
  setIsDownloading,
}: TFormProps) => {
  const { searchByOptions, theme } = useRegulatorForm();

  const { setValues } = useFormikContext<TRegulatorForm>();

  return (
    <FlexWrapper>
      <VStack alignItems="start">
        <Text>
          <FormattedMessage id="regulatorpage.tabs.bethistory.searchby" />
        </Text>
        <SelectReact
          options={searchByOptions}
          defaultValue={searchByOptions[0]}
          onChange={(searchOption) => {
            setValues({
              searchOption: (searchOption as TSearchByOption).value,
            });
            onChangeFilter((searchOption as TSearchByOption).value);
          }}
          isSearchable={false}
          styles={selectStyles(theme)}
        />
      </VStack>

      {(() => {
        if (searchBy === ERegulatorFilters.ClientName)
          return <ByClientNameForm />;
        if (searchBy === ERegulatorFilters.ClientEmail)
          return <ByClientEmailForm />;
        if (searchBy === ERegulatorFilters.Ticket)
          return <ByTicketNumberForm />;
      })()}

      <Button px="10" mt="10" type="submit">
        <FormattedMessage id="regulatorpage.tabs.bethistory.search" />
      </Button>

      <Button
        px="10"
        mt="10"
        variant="outline"
        isDisabled={downloadDisabled}
        isLoading={isDownloading}
        onClick={() => setIsDownloading(true)}
      >
        <FormattedMessage id="regulatorpage.tabs.bethistory.download" />
      </Button>
    </FlexWrapper>
  );
};

export default Form;
