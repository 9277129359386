import { TDeepPartial } from '../../../../../lib/types';

export type TVoidBetInput = {
  bet_id: string;
  reason?: EVoidReason;
  notes?: string;
};

export type TVoidBetModalData = TDeepPartial<{
  bet_id: string;
  punter_name: string;
  stake: number;
  event_title: string;
}>;

export enum EVoidReason {
  customer_requested = 'customer_requested',
  system_error = 'system_error',
  invalid_market_or_proposition = 'invalid_market_or_proposition',
  void_terms_conditions = 'void_terms_conditions',
}
