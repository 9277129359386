import { Badge, BadgeProps, chakra, ChakraComponent } from '@chakra-ui/react';
import styled from 'styled-components';

export const UpdateBetsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const WinPlaceMarginContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0.7rem;

  &:first-child {
    padding-right: 0;
  }
`;

export const CurrentOddsText = styled.p`
  color: ${({ theme }) => theme.global.colors.primary};
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  margin: 0 5px 0 0;
`;

export const CurrentOddsValue = styled.div`
  color: ${({ theme }) => theme.global.colors.primary};
  font-size: 14px;
  font-weight: 300;
`;

export const WinPlaceLabel = styled.span`
  font-size: 19px;
  font-weight: bold;
  margin-right: 5px;
  color: ${({ theme }) => theme.global.colors.darkBlue};
`;

export const CurrentOddsContainer = styled.div`
  min-width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

export const BadgeSus: ChakraComponent<'span', BadgeProps> = chakra(Badge, {
  baseStyle: () => ({
    textTransform: 'none',
    textAlign: 'center',
    borderRadius: 'md',
    bgGradient: 'linear(to-l, gray.400, gray.600)',
    color: 'white',
    lineHeight: 3,
    px: '3',
    py: '1',
    fontSize: 'xs',
    width: 'fit-content',
  }),
});
