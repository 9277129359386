import {
  ChakraComponent,
  RadioGroup,
  RadioGroupProps,
  TextProps,
  Text,
  chakra,
  FlexProps,
  Flex,
} from '@chakra-ui/react';

export const RadioButtonWrapper: ChakraComponent<'input', RadioGroupProps> =
  chakra(RadioGroup, {
    baseStyle: () => ({
      color: 'gray.600',
      my: '3',
      bg: 'white',
      boxShadow: 'md',
      borderRadius: 'md',
      px: '3',
      py: '2',
      span: { fontSize: 'md', fontWeight: 'normal', color: 'gray.700' },
    }),
  });

export const TextError: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    color: 'red.500',
  }),
});

export const FlexDrozoneWrapper: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    baseStyle: () => ({
      w: 'full',
      gap: '10',
      mb: '10',
    }),
  }
);

export const FlexDropZoneBody: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    baseStyle: () => ({
      flexDir: 'column',
      w: 'full',
    }),
  }
);
