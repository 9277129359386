import { getURLBasedOnLocation } from '@/common/utils';
import api from '../../api';
import { TAddBannerParams, TUploadMediaParams } from './banner.types';

export const addBannerURL = 'bookie/banners/state';
export const uploadMediaURL = '/bookie/upload-media';

// update the banner json
export const mutateAddBanner = (body?: TAddBannerParams) =>
  api.put<TAddBannerParams>(addBannerURL, body).then((res) => res.data);

// add images & get public url
export const mutateMedia = (body?: TUploadMediaParams) =>
  api
    .put<TUploadMediaParams>(`bookie/banners/images`, body)
    .then((res) => res.data);

export const queryBanners = async (platform: string) => {
  const response = await fetch(
    `https://storage.googleapis.com/assets.${getURLBasedOnLocation(
      platform
    )}/banners/state.json?time=${new Date().getTime()}`
  );
  // If status is not found this means this is a clean slate, state file has been deleted
  // or this is a new bookie. Return an empty array to simulate no records
  if (response.status === 404) {
    return [];
  }
  const data = await response.json();
  return data;
};
