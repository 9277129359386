import { TBetsData } from '@/api/punters/punters.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TFilter = {
  [key: string]: string | boolean | undefined;
  event_title: string;
  bet_description: string;
  is_won: string;
};

type TBets = {
  filter: TFilter;
  response: TBetsData[];
};

const initialState: TBets = {
  filter: {
    event_title: '',
    bet_description: '',
    is_won: '',
  },
  response: [],
};

const incentives = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<Partial<TBets['filter']>>) => {
      const key = Object.keys(action.payload)[0];
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: action.payload[key],
        },
      };
    },
    setData: (
      state,
      action: PayloadAction<NonNullable<TBets['response']>>
    ) => ({
      ...state,
      response: action.payload,
    }),
    reset: () => initialState,
  },
});

export const { reset, setFilter, setData } = incentives.actions;
export default incentives.reducer;
