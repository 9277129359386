import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateCompetitionBody } from './updateCompetition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const updateCompetitionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/update-competition`;

// Trade Manager - Update Competition

export const mutateUpdateCompetition = (body: TUpdateCompetitionBody) =>
  api
    .post<TMarketCreationResponse>(updateCompetitionURL, body)
    .then((res) => res.data);
