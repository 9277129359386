import { rest } from 'msw';
import {
  ERequestBetType,
  TRequestBet,
  TRequestBetResponse,
} from '../tabs/Services/BetApprovals.types';
import {
  EGeneralStatus,
  EBetStatus,
  EPlayerType,
} from '../../../../../lib/DBModels';
import { ENV } from '@/lib/Constants';

const betRequest: TRequestBet[] = [
  {
    request_id: '312a5c81-65c4-4bb5-a67a-911111312d1c',
    created_at: '2021-11-10T16:10:30.724535',
    type: ERequestBetType.Multi,
    proposition_id: 'd7c08543-5f1f-42ef-8127-789eb2e3da2c',
    requested_odds: 2,
    requested_stake: 420000,
    is_bonus_bet: true,
    status: EBetStatus.Pending,
    actioned_at: '2021-11-10T16:10:30.724535',
    actioned_by: 'Peter Parker',
    punter_id: '1042978a-74e1-4766-8dcc-12f647323ff3',
    punter_name: 'Rob Banks',
    punter_category_at_placement: EPlayerType.HighRoller,
    punter_storm_score: 0,
    event_id: '51a6179d-9b57-487b-b76d-d8677d57c9e1',
    event_title: 'Nadal vs Khachanov',
    event_subtitle: 'Flemington',
    event_start: '2022-12-16T23:30:00Z',
    event_type: 'Race',
    event_icon: 'Horse Racing',
    event_status: EGeneralStatus.Open,
    proposition_odds: 1.1,
    approved_odds: 1.1,
    approved_stake: 420000,
    punter_category_limit: 15000,
    bet_payout: 420000,
    bet_status: EBetStatus.CashedOut,
    bet_won: true,
    selection: [[0], [1], [2]],
    market_name: 'AFW Gee-NMl',
    bet_legs: undefined,
  },
  {
    request_id: '2312a5c81-65c4-4bb5-a67a-911111312d1c',
    created_at: '2021-11-10T16:10:30.724535',
    type: ERequestBetType.Multi,
    proposition_id: 'd7c08543-5f1f-42ef-8127-789eb2e3da2c',
    requested_odds: 2,
    requested_stake: 420000,
    is_bonus_bet: false,
    status: EBetStatus.Pending,
    actioned_at: '2021-11-10T16:10:30.724535',
    actioned_by: 'Peter Parker',
    punter_id: '1042978a-74e1-4766-8dcc-12f647323ff3',
    punter_name: 'Rob Banks',
    punter_category_at_placement: EPlayerType.Sharp,
    punter_storm_score: 0,
    event_id: '1b87a9e7-4026-4f76-8798-561f92d37262',
    event_title: 'Newcastle Jets v Melbourne City',
    event_subtitle: 'Melbourne City 1 - 0',
    event_start: '2022-12-17T03:29:00Z',
    event_type: 'Match',
    event_icon: 'Soccer',
    event_status: EGeneralStatus.Open,
    proposition_odds: 1.1,
    approved_odds: 1.1,
    approved_stake: 420000,
    bet_payout: 420000,
    bet_status: EBetStatus.Pending,
    bet_won: true,
    selection: [[0], [1], [2]],
    market_name: 'AFW Gee-NMl',
    bet_legs: undefined,
  },
];

const approvedBetRequest: TRequestBet = {
  request_id: 'fdd460b5-132c-4659-89f3-292d2351f1d8',
  created_at: '2022-12-16T12:27:20Z',
  type: ERequestBetType.Single,
  sub_type: '',
  proposition_id: '907fb75f-18a7-4a3f-866a-26f725ab1c50',
  requested_odds: 51,
  requested_stake: 250000,
  is_bonus_bet: false,
  status: EBetStatus.Pending,
  actioned_at: '2022-12-16T12:27:20Z',
  actioned_by: '',
  punter_id: 'ee0b03c5-7c43-4de3-b902-960ccb951db2',
  punter_name: 'And Aydin',
  punter_category_at_placement: EPlayerType.Newbie,
  punter_storm_score: 0,
  event_id: '32344162-9026-4d44-82e4-395291d5b72e',
  event_title: 'Gold Coast - Race 3',
  event_subtitle: 'THE PREACHER',
  event_start: '2022-12-17T03:29:00Z',
  event_type: 'Race',
  event_icon: 'Horse Racing',
  event_status: EGeneralStatus.Closed,
  proposition_odds: 51,
  approved_odds: 0,
  approved_stake: 0,
  bet_payout: undefined,
  bet_status: undefined,
  bet_won: undefined,
  selection: undefined,
  market_name: undefined,
  bet_legs: undefined,

  mbl_stake: 25000,
  mbl_threshold: 200,
};

const multiExample: TRequestBet = {
  request_id: '7a4fd82d-ff9d-40f5-9315-9cf46c0175a6',
  created_at: '2022-12-17T00:23:11Z',
  type: ERequestBetType.Multi,
  sub_type: '',
  proposition_id: '',
  requested_odds: 4.2,
  requested_stake: 255500,
  is_bonus_bet: false,
  status: EBetStatus.Pending,
  actioned_at: '2022-12-17T00:23:11Z',
  actioned_by: '',
  punter_id: 'ee0b03c5-7c43-4de3-b902-960ccb951db2',
  punter_name: 'And Aydin',
  punter_category_at_placement: EPlayerType.Player,
  punter_storm_score: 0,
  event_id: undefined,
  event_title: undefined,
  event_subtitle: undefined,
  event_start: '2022-12-17T05:16:00Z',
  event_type: 'soccer',
  event_icon: undefined,
  event_status: undefined,
  proposition_odds: undefined,
  approved_odds: undefined,
  approved_stake: undefined,
  bet_payout: undefined,
  bet_status: undefined,
  bet_won: undefined,
  selection: undefined,
  market_name: undefined,
  bet_legs: [
    {
      bet_leg_id: '135aa01f-080b-4199-8113-7a08065c5612',
      event_id: 'eb2ffda1-91ab-40b4-8d15-13add2c9a42e',
      bet_description: 'RESULT',
      event_start: '1970-01-20T07:18:09Z',
      event_type: 'Match',
      event_icon: 'Soccer',
      odds: 4.25,
      is_won: false,
      status: EBetStatus.Pending,
      event_data: {
        sport_id: '51e9ba06-a9c9-4edb-8042-b55410cf840f',
        competition_name: 'AFF Suzuki Cup',
        competition_id: '56a34353-cbbd-4023-8874-18b195d283d3',
        tournament_name: '',
        tournament_id: '',
        match_name: 'Timor Leste v Brunei',
        proposition_name: 'Sintrense - Draw',
        market_name: 'Result',
      },
    },
    {
      bet_leg_id: '724faa26-c86c-40db-8c5d-d0f81cd2da69',
      event_id: 'd7e8e4ad-1fe1-4b1f-82f4-a626ab02a31f',
      bet_description: 'TOTAL_GOALS_OVER_UNDER',
      event_start: '2022-10-28T16:10:00Z',
      event_type: 'Match',
      event_icon: 'Soccer',
      odds: 8.5,
      is_won: false,
      status: EBetStatus.Pending,
      event_data: {
        sport_id: '51e9ba06-a9c9-4edb-8042-b55410cf840f',
        competition_name: 'Austria Erste Liga',
        competition_id: 'f5d383e4-f8d4-436d-8e38-5ebe5cb0e192',
        tournament_name: 'null',
        tournament_id: 'null',
        match_name: 'Amstetten v Grazer AK',
        proposition_name: 'Under 0.5 Goals',
        market_name: 'Total Goals Over/Under',
      },
    },
    {
      bet_leg_id: '193dfcc8-aae1-452f-8165-7be1ccd80281',
      event_id: 'c9595e82-9a11-4456-8980-602e83e5e206',
      bet_description: 'CORRECT_SCORE',
      event_start: '2022-11-03T01:00:00Z',
      event_type: 'Match',
      event_icon: 'Soccer',
      odds: 15,
      is_won: false,
      status: EBetStatus.Pending,
      event_data: {
        sport_id: '51e9ba06-a9c9-4edb-8042-b55410cf840f',
        competition_name: 'Colombia Copa',
        competition_id: 'c47b34a5-b299-49d7-820c-15022fee2416',
        tournament_name: 'null',
        tournament_id: 'null',
        match_name: 'Millonarios v Jr Barranquilla',
        proposition_name: 'Millonarios 3 - 0',
        market_name: 'Correct Score',
      },
    },
  ],
};

const multiRaceBet: TRequestBet = {
  request_id: '38b1a264-c8f7-4bf9-be47-356c4f4b5b04',
  created_at: '2022-12-17T01:56:59Z',
  type: ERequestBetType.Multi,
  sub_type: '',
  proposition_id: 'null',
  requested_odds: 11,
  requested_stake: 300000,
  punter_category_limit: 15000,
  is_bonus_bet: true,
  status: EBetStatus.Pending,
  actioned_at: '2022-12-17T01:56:59Z',
  actioned_by: '',
  punter_id: 'ee0b03c5-7c43-4de3-b902-960ccb951db2',
  punter_name: 'And Aydin',
  punter_category_at_placement: EPlayerType.HighRoller,
  punter_storm_score: 0,
  event_id: undefined,
  event_title: undefined,
  event_subtitle: undefined,
  event_start: '2022-12-17T02:03:00Z',
  event_type: 'Race',
  event_icon: undefined,
  event_status: undefined,
  proposition_odds: undefined,
  approved_odds: undefined,
  approved_stake: undefined,
  bet_payout: undefined,
  bet_status: undefined,
  bet_won: undefined,
  selection: undefined,
  market_name: undefined,
  bet_legs: [
    {
      bet_leg_id: '62525b10-3873-432f-8d58-302f2347352c',
      event_id: 'fe9cf123-7bc0-43ce-891f-90dd230be1b6',
      bet_description: 'Racing Win',
      event_start: '2022-12-17T02:03:00Z',
      event_type: 'Race',
      event_icon: 'Horse Racing',
      odds: 9,
      is_won: false,
      status: EBetStatus.Pending,
      event_data: {
        venue_name: 'Eagle Farm',
        venue_id: 'c40d19e6-d9a0-4de7-8513-53b30636fc11',
        race_name: 'CLUBS QUEENSLAND',
        race_number: 1,
        race_meeting_date: '2022-12-17',
        runner_name: 'LOVE SENSATION',
        runner_number: 5,
        runner_barrier_number: 6,
        market_name: 'Racing Place',
      },
    },
    {
      bet_leg_id: '2666092d-6315-451e-87d8-a986c57e41f0',
      event_id: '27c6a066-ac5b-49e8-8142-61d2e8ed3074',
      bet_description: 'Racing Win',
      event_start: '2022-12-17T02:06:00Z',
      event_type: 'Race',
      event_icon: 'Horse Racing',
      odds: 11,
      is_won: false,
      status: EBetStatus.Pending,
      event_data: {
        venue_name: 'Te Rapa',
        venue_id: '35e2357a-f07f-492d-868a-2f07eda410d3',
        race_name: 'LODGE COMMERCIAL',
        race_number: 5,
        race_meeting_date: '2022-12-17',
        runner_name: 'ATA RANGI',
        runner_number: 10,
        runner_barrier_number: 6,
        market_name: 'Racing Win',
      },
    },
    {
      bet_leg_id: 'd9241c7c-c675-43cc-80ce-33fb7faf95ba',
      event_id: '71d48b61-41f2-468b-8333-8decdc9f6985',
      bet_description: 'Racing Win',
      event_start: '2022-12-17T02:10:00Z',
      event_type: 'Race',
      event_icon: 'Horse Racing',
      odds: 8.75,
      is_won: false,
      status: EBetStatus.Pending,
      event_data: {
        venue_name: 'Flemington',
        venue_id: 'fdaaa254-79a5-4b35-8bba-24282554a1f9',
        race_name: 'G.H.MUMM',
        race_number: 2,
        race_meeting_date: '2022-12-17',
        runner_name: 'DAZZLING LUCY',
        runner_number: 9,
        runner_barrier_number: 2,
        market_name: 'Racing Place',
      },
    },
  ],
};

const singleRace: TRequestBet = {
  request_id: '7218c407-078c-4428-ba7b-a88515b66522',
  created_at: '2022-12-18T01:12:34Z',
  type: ERequestBetType.Multi,
  sub_type: '',
  proposition_id: 'f5662105-2ebc-4ec3-8c36-2fdbbe34f9d7',
  requested_odds: 31,
  requested_stake: 50000,
  is_bonus_bet: false,
  status: EBetStatus.Pending,
  actioned_at: '2022-12-18T01:12:34Z',
  actioned_by: '',
  punter_id: 'e17debc2-eb42-4d8b-9067-e259371d719e',
  punter_name: 'Dean Brown',
  punter_storm_score: 0,
  event_id: '4a63ab45-9983-4f6f-820b-43ef096fb193',
  event_title: 'Port Lincoln - Race 1',
  event_subtitle: 'REBEL ATTACK',
  event_start: '2022-12-18T03:10:00Z',
  event_type: 'Race',
  event_icon: 'Horse Racing',
  event_status: EGeneralStatus.Open,
  proposition_odds: 32,
  approved_odds: undefined,
  approved_stake: undefined,
  bet_payout: undefined,
  bet_status: undefined,
  bet_won: undefined,
  selection: undefined,
  market_name: undefined,
  bet_legs: undefined,
  mbl_stake: 3333,
  mbl_threshold: 100000,
  intercept_reason: 'pcel_thoroughbred',
  punter_category_at_placement: EPlayerType.HighRoller,
  punter_category_limit_name: 'pcel_thoroughbred',
  punter_category_limit: 800000,
};

export const betApprovalsHandler = [
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/bet-approvals`,
    async (req, res, ctx) => {
      const approvals: TRequestBetResponse = {
        total_records: 150,
        total_pages: 2,
        records: [
          ...betRequest,
          approvedBetRequest,
          multiExample,
          multiRaceBet,
          singleRace,
        ],
      };
      return res(ctx.delay(500), ctx.json(approvals));
    }
  ),

  rest.post(
    `${ENV.REACT_APP_API_URL}/bookie/bet-approvals/status`,
    async (req, res, ctx) => res(ctx.status(401))
  ),
];
