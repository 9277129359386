import { TSportMarket } from '@/api/tradeManager/marketCreation/getSportMarket/getSportMarket.types';
import { centsToDollarsNumber, dollarsToCents } from '@/common/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TCustomMarketsFormValues } from '../../../pages/TradeManager/tabs/CustomMarkets/Custommarket.types';
import { INITIAL_FORM_VALUES } from './CustomMarketModal.config';

dayjs.extend(utc);

export const formatDateTime = ({ date, time }: { date: Date; time: Date }) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const combinedDate = new Date(year, month, day, hours, minutes, seconds);

  return dayjs(combinedDate).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
};

export const parseFormData = (values: TCustomMarketsFormValues) => {
  return {
    ...values,
    max_bet: dollarsToCents(values.max_bet),
    date: formatDateTime({ date: values.date, time: values.time }),
  };
};

export const getPrefilledFormValues = (data: TSportMarket) => {
  const customFormValues: TCustomMarketsFormValues = {
    code: { label: data.name ?? '', value: data.sport_id ?? '' },
    competition: {
      label: data.competition?.name ?? '',
      value: data.competition?.competition_id ?? '',
    },
    match: data.competition?.match?.name || '',
    date: new Date(data.competition?.match?.start_time || ''),
    time: new Date(data.competition?.match?.start_time || ''),
    market_type: data.competition?.match?.market?.market_type || '',
    market_group_name: data.competition?.match?.market_groups?.[0]?.name || '',
    market_name: data.competition?.match?.market?.name || '',
    status: data.competition?.match?.status || '',
    max_bet: centsToDollarsNumber(
      data.competition?.match?.market?.propositions?.[0]?.max_stake_limit || 0
    ),
    allow_multis:
      !!data.competition?.match?.market?.propositions?.[0]?.allow_multi,
    propositions: data.competition?.match?.market?.propositions?.map((p) => ({
      id: p?.proposition_id ?? '',
      name: p?.name ?? '',
      odds: p?.odds ?? 1.01,
    })) || [...INITIAL_FORM_VALUES.propositions],
  };

  return customFormValues;
};
type CustomMarketsFormValueKeys = keyof TCustomMarketsFormValues;

export const checkFormValuesChanged = (
  values: TCustomMarketsFormValues,
  initialValues: TCustomMarketsFormValues
): Record<CustomMarketsFormValueKeys, boolean> => {
  const keys = <CustomMarketsFormValueKeys[]>Object.keys(initialValues);

  return keys.reduce<Record<CustomMarketsFormValueKeys, boolean>>(
    (changedValues, key) => {
      changedValues[key] = values[key] !== initialValues[key];

      if (key === 'date' || key === 'time') {
        changedValues[key] =
          values[key].getTime() !== initialValues[key].getTime();
      }

      if (key === 'code' || key === 'competition') {
        changedValues[key] = values[key]?.label !== initialValues[key]?.label;
      }
      if (key === 'propositions') {
        changedValues[key] = values[key].some(
          (prop, index) =>
            prop.name !== initialValues[key][index]?.name ||
            prop.odds !== initialValues[key][index]?.odds
        );
      }

      return changedValues;
    },
    <Record<CustomMarketsFormValueKeys, boolean>>{}
  );
};
