import React from 'react';
import { TimePicker } from './styles/Timepicker.styles';
import { TimePickerProps } from './Timepicker.types';

export default function Timepicker(props: TimePickerProps) {
  const { wrapperProps, isInvalid } = props;

  return (
    <TimePicker
      isInvalid={isInvalid}
      wrapperProps={wrapperProps}
      format="HH:mm"
      {...props}
    />
  );
}
