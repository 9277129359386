import { TRunner } from '@/api/tradeManager/mysteryBet/mysteryBet.types';
import { centsToDollars } from '@/common/utils';
import { Flex, Box, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';

export const useMysteryBetRunnerColumns = () => {
  const columns = useMemo<ColumnDef<TRunner>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Runner',
        cell: ({ row }) => {
          const {
            original: { barrier_number, number, display_name },
          } = row;
          return (
            <Text color="brand.500">
              {number}. {display_name} ({barrier_number})
            </Text>
          );
        },
      },
      {
        header: 'Bets',
        enableSorting: false,
        cell: ({ row }) => {
          const {
            original: { topx_stats },
          } = row;
          return (
            <Flex flexDirection="column">
              {topx_stats?.map((stat) => (
                <Box key={stat.proposition_type}>{stat.mystery_bet_bets}</Box>
              ))}
            </Flex>
          );
        },
      },
      {
        header: 'Proposition',
        enableSorting: false,
        cell: ({ row }) => {
          const {
            original: { topx_stats },
          } = row;
          return (
            <Flex flexDirection="column">
              {topx_stats?.map((stat) => (
                <Box key={stat.proposition_type}>{stat.proposition_type}</Box>
              ))}
            </Flex>
          );
        },
      },
      {
        header: 'Stake',
        cell: ({ row }) => {
          const {
            original: { topx_stats },
          } = row;
          return (
            <Flex flexDirection="column">
              {topx_stats?.map((stat) => (
                <Box key={stat.proposition_type}>
                  {centsToDollars(stat.mystery_bet_hold)}
                </Box>
              ))}
            </Flex>
          );
        },
      },
      {
        header: 'Exposure',
        cell: ({ row }) => {
          const {
            original: { topx_stats },
          } = row;
          return (
            <Flex flexDirection="column">
              {topx_stats?.map((stat) => (
                <Box key={stat.proposition_type}>
                  {centsToDollars(stat.mystery_bet_liability)}
                </Box>
              ))}
            </Flex>
          );
        },
      },
    ],
    []
  );

  return { columns };
};
