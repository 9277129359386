import { TMarketId } from '../../../features/tradeManager/pages/MatchDetails/types';
import { TProposition } from '../../../lib/DBModels';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

import api from '../../api';

export const propositionForMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/proposition-for-market`;

export const queryPropositionForMarket = (params: TMarketId) =>
  api
    .get<{ propositions: TProposition[] }>(propositionForMarketURL, { params })
    .then((res) => res.data);
