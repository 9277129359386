import styled from 'styled-components';
import {
  chakra,
  ChakraComponent,
  Text,
  TextProps,
  Flex,
  FlexProps,
  IconProps,
  HStack,
  StackProps,
} from '@chakra-ui/react';
import { ESettledSportsProps } from '../../../../lib/DBModels';
import { Card } from '../../../../common/components';

export const FlexTitleWrapper = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'flex-start',
    flexDir: 'column',
    ml: '3',
  }),
});

export const SwitchWrapper: ChakraComponent<'div', StackProps> = chakra(
  HStack,
  {
    baseStyle: () => ({
      p: '2',
      minW: '140px',
      h: '50px',
      bg: 'white',
      ml: '3',
      borderRadius: 'md',
    }),
  }
);

export const TextDisclaimer = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    fontWeight: 'normal',
    lineHeight: '1',
    ml: '1',
  }),
});

export const FullWidthCard = styled(Card)`
  width: 100%;
`;

export const SportInfoTable = styled.table`
  & > tbody > tr > td {
    border-right: 1px solid ${({ theme }) => theme.global.colors.separatorLine};
    padding: 5px 13px;

    &:last-child {
      border-right: none;
    }
  }
`;

export const PropositionTitle = styled.p`
  flex-direction: row;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
`;

interface FlucPriceProps {
  flucUp?: boolean;
}

export const FlucPriceText = styled.text<FlucPriceProps>`
  padding-left: 5px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ flucUp, theme }) =>
    flucUp ? theme.global.colors.greenText : theme.global.colors.red};
`;

export const FlucText = chakra(Text, {
  baseStyle: () => ({
    fontWeight: '500',
    pl: '5px',
    color: 'blue.300',
  }),
});

// Temp until the table is moved to styled table & chakra!
export const ExposureTD = styled('td')`
  height: 1px; // To set an initial height to ensure we take full height
  padding: 0 !important;
`;

export const MarketTitle = chakra(Text, {
  baseStyle: () => ({
    display: 'flex',
    fontSize: 'md',
    color: 'gray.900',
    textAlign: 'left',
    fontWeight: 'medium',
  }),
});

export const PropositionCount = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    color: 'gray.900',
    bg: 'gray.300',
    borderRadius: 'sm',
    textAlign: 'center',
    fontWeight: 'medium',
    px: '2',
    mr: '3',
  }),
});

export const BetOutcome = chakra(Text, {
  shouldForwardProp: (prop) => !['outcome'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ outcome }: { outcome: string } & any) => ({
    bg: () => {
      switch (outcome) {
        case ESettledSportsProps.Winner:
          return 'green.400';
        case ESettledSportsProps.Loser:
          return 'red.400';
        default:
          return 'orange.400';
      }
    },
    mr: '1',
    color: 'white',
    borderRadius: 'full',
    fontSize: 'xs',
    px: '2',
    py: '1',
    fontWeight: 'normal',
    textTransform: 'uppercase',
  }),
}) as ChakraComponent<'text', TextProps & { outcome?: ESettledSportsProps }>;

export const HeadingMatchTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    textAlign: 'left',
    alignSelf: 'start',
    fontWeight: 'normal',
    color: 'gray.700',
  }),
});

export const StatFlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    border: '1px',
    mx: '2',
    borderColor: 'gray.100',
    borderRadius: 'md',
    p: '2',
    gap: '2',
  }),
});

export const BlueStatIcon: ChakraComponent<'div', IconProps> = chakra(Flex, {
  baseStyle: () => ({
    boxSize: '10',
    borderRadius: 'full',
    p: '2',
    bg: 'blue.100',
    color: 'blue.700',
    mr: '3',
  }),
});

export const GreenStatIcon: ChakraComponent<'div', IconProps> = chakra(Flex, {
  baseStyle: () => ({
    boxSize: '10',
    borderRadius: 'full',
    p: '2',
    bg: 'green.100',
    color: 'green.700',
    mr: '3',
  }),
});

export const OrangeStatIcon: ChakraComponent<'div', IconProps> = chakra(Flex, {
  baseStyle: () => ({
    boxSize: '10',
    borderRadius: 'full',
    p: '2',
    bg: 'orange.500',
    color: 'orange.100',
    mr: '3',
  }),
});
