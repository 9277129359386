/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TBetsData } from '@/api/punters/punters.types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useQuery as useQueryString } from '../../../common/hooks/useQuery';
import { keys } from '../../api.keys';
import { queryMultiBets } from './multi';
import { TMultiBetsParams } from './multi.types';

// Trade Manager - Multi Bets

type TQueryMultiBetsHook = {
  params?: TMultiBetsParams;
  key?: unknown[];
  options?: UseQueryOptions<TBetsData[]>;
};

export const useQueryMultiBets = ({
  params = {},
  key = [],
  options = {},
}: TQueryMultiBetsHook = {}) => {
  const q = useQueryString();
  const event_id = q.get('id');

  const query = useQuery(
    [keys.multiBets, event_id, ...Object.values(params), ...key],
    () => queryMultiBets({ ...params, event_id }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
