import styled from 'styled-components';
import { Button } from 'grommet';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DeclineButton = styled(Button)`
  min-width: 3.2rem;
  height: 1.8rem;
  border-radius: 2rem;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.global.colors.red};
  align-items: center;
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.global.colors.white};
`;

export const ApproveButton = styled(Button)`
  min-width: 3.2rem;
  height: 1.8rem;
  border-radius: 2rem;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.global.colors.green};
  align-items: center;
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.global.colors.white};
`;

export const LeftSideMultiActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RightSideMultiActions = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiActionButton = styled(Button)`
  height: 1.8rem;
  border-radius: 5px;
  margin-right: 6px;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  & > span {
    color: ${({ theme }) => theme.global.colors.white};
  }
`;

export const MultiApproveButton = styled(MultiActionButton)`
  background-color: ${({ theme }) => theme.global.colors.green};
`;

export const MultiDeclineButton = styled(MultiActionButton)`
  background-color: ${({ theme }) => theme.global.colors.red};
`;

export const MultiExportButton = styled(MultiActionButton)`
  background-color: ${({ theme }) => theme.global.colors.midBlue};
  margin: 0;
`;
