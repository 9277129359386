import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import { utcToLocalTime } from '../../../utils';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;

// This hook handles:
// - Showing the countdown if the race is within 1 hr of the current time
// - Counting down the timer to 0 (and into negative time)
export const useStartTimeCalculator = (date: string | undefined) => {
  const [countdownTimeLeft, setCountdownTimeLeft] = useState(0);

  const updateTimeLeft = useCallback(() => {
    // get local time - turn time to milliseconds
    const currentDate = new Date();
    const currentDateToMil = currentDate.getTime();

    // convert given time from utc to local - turn time to milliseconds
    const utcToLocal = utcToLocalTime(date);
    const startDate = new Date(utcToLocal).getTime();

    // set remaining time
    const remainingTime = startDate - currentDateToMil;
    setCountdownTimeLeft(remainingTime);
  }, [date]);

  useEffect(() => {
    updateTimeLeft();

    const interval = setInterval(() => updateTimeLeft(), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [updateTimeLeft]);
  const minutesLeft = Math.trunc((countdownTimeLeft % hour) / minute);
  const hoursLeft = Math.trunc((countdownTimeLeft % day) / hour);

  const countdownShow = useMemo(
    () => countdownTimeLeft < week,
    [countdownTimeLeft]
  );

  const countdownLabel = useMemo(() => {
    // If 24 hours have past display days ago
    if (Math.abs(countdownTimeLeft) >= day) {
      return dayjs(utcToLocalTime(date)).fromNow();
    }
    const secondsLeft = Math.trunc((countdownTimeLeft % minute) / second);
    const hours = hoursLeft !== 0 ? `${hoursLeft}h` : '';
    const minutes = `${minutesLeft}m`;
    const seconds = `${secondsLeft}s`;

    return `${hours} ${minutes} ${seconds}`;
  }, [countdownTimeLeft, date, minutesLeft, hoursLeft]);

  const startsIn15Mins = hoursLeft === 0 && minutesLeft < 15;

  return { countdownLabel, countdownTimeLeft, startsIn15Mins, countdownShow };
};
