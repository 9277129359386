/* eslint-disable @typescript-eslint/naming-convention */
import { chakra, ChakraComponent } from '@chakra-ui/react';
import ReactDatePicker from 'react-date-picker';
import { DatePickerProps } from '../Datepicker.types';

export const DatePicker = chakra(ReactDatePicker, {
  shouldForwardProp: (prop) => !['wrapperProps', 'innerProps'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ wrapperProps, innerProps }: any) => ({
    w: 'full',
    ...innerProps,
    '.react-date-picker__wrapper': {
      bg: 'white',
      borderRadius: 'md',
      h: '55px',
      border: '1px',
      borderColor: 'gray.200',
      px: '2.5',
      overflow: 'visible',
      ...wrapperProps,

      '[data-invalid="true"] &': {
        borderColor: 'red.500',
      },
    },
  }),
}) as ChakraComponent<'input', DatePickerProps>;
