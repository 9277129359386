import { rest } from 'msw';
import { baseURL } from '@/__mocks__/utils';
import { bulkPromoUploadEndpoint } from './bulkPromoUpload';
import { mutatePromosMock } from './bulkPromoUpload.mocks';

export const bulkPromoUploadHandlers = [
  rest.post(baseURL(bulkPromoUploadEndpoint), (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mutatePromosMock))
  ),
];
