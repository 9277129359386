import React, { FC } from 'react';
import { Button, HStack, Flex } from '@chakra-ui/react';
import { DateSelect } from '../../../common/components';
import { getStrings } from '../../../common/utils';
import { TBonusAwardsParams } from '../../../api/bulkBonusAwards/bulkBonusAwards.types';

type TFiltersProps = {
  filters: TBonusAwardsParams;
  setFilters: (query: TBonusAwardsParams) => void;
  clearFilters: () => void;
};

export const Filters: FC<TFiltersProps> = ({
  filters,
  setFilters,
  clearFilters,
}) => {
  const [
    {
      PromoManagerPage: {
        History: { Filters: FilterStrings },
      },
    },
  ] = getStrings();

  return (
    <HStack gap="4" m="4" alignItems="center">
      <Flex direction="row" flexWrap="wrap" justify="start" gap="10">
        <DateSelect
          label={FilterStrings.dateFrom}
          format="dd/mm/yyyy"
          value={filters.applied_from}
          onChange={({ value }) => {
            if (typeof value === 'string') {
              setFilters({ ...filters, applied_from: value.substring(0, 10) });
            }
          }}
        />
        <DateSelect
          label={FilterStrings.dateTo}
          format="dd/mm/yyyy"
          value={filters.applied_to}
          onChange={({ value }) => {
            if (typeof value === 'string') {
              setFilters({ ...filters, applied_to: value.substring(0, 10) });
            }
          }}
        />
      </Flex>

      <Button variant="ghost" onClick={clearFilters}>
        {FilterStrings.reset}
      </Button>
    </HStack>
  );
};
