import React from 'react';
import { VStack, Flex, Box } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { Separator, StatsPanel } from '../../../../common/components';
import EventDateTimeExposure from '../../components/EventDateTimeExposure';
import { FullWidthCard } from './RaceDetailsPage.styles';
import { EGeneralStatus } from '../../../../lib/DBModels';
import { getStatus } from './components/ModalConfigure/Status/services/Status.utils';
import { EStatusOptions } from './components/ModalConfigure/Status/Status.types';
import ConfigureModal from './components/ModalConfigure/ModalConfigure';
import VoidedRaceBanner from './components/VoidedRaceBanner';
import RaceNumbers from './components/RaceNumbers/RaceNumbers';
import MarketWinPlace from './components/Markets/WinPlace';
import MarketExotics from './components/Markets/Exotics/MarketExotics';
import MultiBets from './components/Markets/MultiBets/MultiBets';
import SRMBets from './components/Markets/SRM/SRMBets';
import RaceStats from './components/RaceStats/RaceStats';
import Tabs from './components/Markets/Tabs';
import { AppState } from '@/app/types';
import { useSelector } from 'react-redux';
import { useMarketTypeNavigation } from './Services/RaceDetails.hooks';
import { ERaceMarketType } from './Services/RaceDetails.types';
import { useQuery as useQueryString } from '@/common/hooks/useQuery';
import { setRace } from './Services/RaceDetails.slices';
import ManageRaceConfig from './components/ManageRaceConfig/ManageRaceConfig';
import { useQueryRaceDetails } from '@/api/tradeManager/raceDetails/raceDetails.hooks';
import { useAppDispatch } from '@/common/hooks/useRedux';
import {
  getRaceMarkets,
  getRaceNumbersForVenue,
  getRaceResults,
} from './Services/RaceDetails.actions';
import Blended from './components/Markets/Blended/Blended';
import EvenShotBets from './components/Markets/EvenShot/EvenShotBets';
import MysteryBet from './components/Markets/MysteryBet/MysteryBet';
import { ToteMulti } from './components/Markets/Exotics/ToteMulti';

export const RaceDetailsPage = () => {
  const {
    raceData,
    raceMarkets,
    raceResults,
    raceNumbers,
    ignoreWinterSwitchOn,
  } = useSelector((state: AppState) => state.raceDetails);

  const queryRaceId = useQueryString().get('id') ?? '';

  const { marketType } = useMarketTypeNavigation();

  const dispatch = useAppDispatch();

  const { isLoading } = useQueryRaceDetails({
    params: {
      race_id: queryRaceId,
    },
    options: {
      refetchInterval: 5000,
      onSuccess(data) {
        dispatch(setRace(data));
        dispatch(getRaceResults(queryRaceId));
        dispatch(getRaceMarkets({ raceId: queryRaceId }));

        if (!raceNumbers.length) dispatch(getRaceNumbersForVenue(queryRaceId));
      },
    },
  });

  if (queryRaceId === null || queryRaceId === '') {
    return <Navigate to="/trade-manager" />;
  }

  const raceStatus = (() => {
    /* When not all markets have the same status -> show mixed */
    if (
      !raceMarkets.every(
        (m) =>
          getStatus(m, ignoreWinterSwitchOn) ===
          getStatus(raceMarkets[0], ignoreWinterSwitchOn)
      )
    )
      return EGeneralStatus.Mixed;

    /* If race is mannually settled */
    if (raceData.ignore_winter_settlement)
      return EGeneralStatus.ManuallySettled;

    return raceData.status;
  })();

  const isManualOverride = raceMarkets.some(
    (m) => getStatus(m, ignoreWinterSwitchOn) !== EStatusOptions.Winter
  );

  return (
    <>
      <ConfigureModal />

      <VStack spacing={6} maxWidth="full">
        {raceData?.status === EGeneralStatus.Voided && <VoidedRaceBanner />}
        <FullWidthCard>
          <Box
            position="sticky"
            background="white"
            borderTopRadius={24}
            top={-8}
            zIndex="dropdown"
          >
            <StatsPanel>
              <EventDateTimeExposure
                venueName={raceData.venue_name}
                dateTime={raceData?.start_time}
                status={raceStatus}
                isManualOverride={isManualOverride}
                isSuspended={raceData.is_suspended}
                isProtestOverride={raceData.fptp_protest_override}
              />
              <RaceNumbers />

              <RaceStats raceData={raceData} raceResults={raceResults} />
            </StatsPanel>

            <Separator />

            <Flex direction="row" m="2" alignItems="center">
              <Tabs />
              {!isLoading && <ManageRaceConfig />}
            </Flex>
          </Box>

          {marketType === ERaceMarketType.WinPlace && (
            <MarketWinPlace
              isLoading={isLoading}
              raceData={raceData}
              raceResults={raceResults}
              raceMarkets={raceMarkets}
            />
          )}
          {marketType === ERaceMarketType.MultiBets && <MultiBets />}
          {marketType === ERaceMarketType.Exotics && <MarketExotics />}
          {marketType === ERaceMarketType.SRMulti && <SRMBets />}
          {marketType === ERaceMarketType.Blended && <Blended />}
          {marketType === ERaceMarketType.EvenShot && <EvenShotBets />}
          {marketType === ERaceMarketType.MysteryBet && <MysteryBet />}
          {marketType === ERaceMarketType.Multiples && <ToteMulti />}
        </FullWidthCard>
      </VStack>
    </>
  );
};

export default RaceDetailsPage;
