import api from '../../api';
import { TBlendedRunnersParams } from './runners.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// Blended - All Bets
const blendedRunnersURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/blended/runners`;
export const queryBlendedRunners = (params: TBlendedRunnersParams) =>
  api
    .get<Api['BlendedRaceRunnerStatsResponse']>(blendedRunnersURL, { params })
    .then((res) => res.data);
