import { useLocation } from 'react-router-dom';

export function usePathPayload() {
  const location = useLocation();

  function pathPayload(navPath: string, search?: string) {
    return {
      pathname: `${location.pathname.substring(
        0,
        location.pathname.lastIndexOf('/') + 1
      )}${navPath}`,
      search,
      state: { from: location },
    };
  }

  return {
    pathPayload,
  };
}

export const headerAnimation = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export const containerAnimation = {
  hidden: { y: 100, opacity: 0 },
  enter: { y: 0, opacity: 1 },
  exit: { y: 100, opacity: 0 },
};
