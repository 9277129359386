import { StyleFunctionProps, theme } from '@chakra-ui/react';

export const Input = {
  sizes: {
    lg: {
      element: {
        fontSize: '2xl',
      },
      field: {
        fontSize: 'sm',
        borderRadius: 'md',
        h: '14',
      },
    },
  },
  variants: {
    filled: (props: StyleFunctionProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Input.variants?.filled(props),
      field: {
        borderColor: 'gray.100',
        background: 'white',
        '&:hover': {
          borderColor: 'gray.300',
          background: 'transparent',
        },
        '&:focus': {
          borderColor: 'gray.500',
        },
      },
    }),
  },
  defaultProps: {},
};
