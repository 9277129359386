import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkDispatch } from '../../../../app/types';
import { apiPostRequest } from '../../../../lib/api/api';

import { TMarginModifier } from '../../../../lib/DBModels';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

/**
 * Updates margin modifier for sport propositions.
 */
export const updateSportPropositionModifier = createAsyncThunk<
  TMarginModifier,
  { propositionId: string; newModifier: number },
  { dispatch: AppThunkDispatch }
>(
  'matchDetails/updateSportPropositionModifier',
  async ({ propositionId, newModifier }) => {
    const updatedModifier = await apiPostRequest<TMarginModifier>(
      `/${getPrefixPath(
        isWincore
      )}/trade-manager/sport-details/proposition-margin`,
      {
        id: propositionId,
        modifier: newModifier,
      }
    );

    return updatedModifier;
  }
);

/**
 * Updates margin modifier for sport markets.
 */
export const updateSportMarketModifier = createAsyncThunk<
  TMarginModifier,
  { marketId: string; newModifier: number },
  { dispatch: AppThunkDispatch }
>(
  'matchDetails/updateSportMarketModifier',
  async ({ marketId, newModifier }) => {
    const updatedModifier = await apiPostRequest<TMarginModifier>(
      `/${getPrefixPath(isWincore)}/trade-manager/sport-details/market-margin`,
      {
        id: marketId,
        modifier: newModifier,
      }
    );

    return updatedModifier;
  }
);

/**
 * Updates margin modifier for sport matches.
 */
export const updateSportMatchModifier = createAsyncThunk<
  TMarginModifier,
  { matchId: string; newModifier: number },
  { dispatch: AppThunkDispatch }
>('matchDetails/updateSportMatchModifier', async ({ matchId, newModifier }) => {
  const updatedModifier = await apiPostRequest<TMarginModifier>(
    `/${getPrefixPath(isWincore)}/trade-manager/sport-details/match-margin`,
    {
      id: matchId,
      modifier: newModifier,
    }
  );

  return updatedModifier;
});
