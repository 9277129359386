import { TIncentivesBonusBets } from './incentives/bonusBets/bonusBets.types';

export const receivedMockData: TIncentivesBonusBets[] = [
  {
    revocation_transaction_id: '1f4df723-6e2b-4851-8a48-8f3626cdac14',
    transaction_id: '1f4df723-6e2b-4851-8a48-8f3626cdac14',
    date_issued: '2022-01-01T17:40:46.876465',
    date_revoked: '2022-02-02T17:40:46.876465',
    issued_by: 'John Carmack',
    issue_amount: 10000,
    revoked_by: undefined,
    note: 'Excepteur sint occaecat cupidatat ',
    revoke_amount: 1550,
    issue_reason: 'Some Reason',
  },
  {
    revocation_transaction_id: '1f4df723-6e2b-4851-8a48-8f3626cdac14',
    transaction_id: '1f4df723-6e2b-4851-8a48-8f3626cdac14',
    date_issued: '2022-01-01T17:40:46.876465',

    date_revoked: '2022-02-02T17:40:46.876465',

    issued_by: 'John Carmack',
    issue_amount: 25000,
    revoke_amount: 5000,
    revoked_by: `Rachelle Dickinson`,

    issue_reason: 'Some Reason 2',
  },
];
