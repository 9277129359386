import api from '@/api/api';

export type TDashboardQueryParams = {
  start_time?: string;
  end_time?: string;
  punter_category?: string[];
  bet_type?: string[];
  event_type?: string[];
  region?: string[];
  code?: string[];
  race_type?: string[];
  sport_id?: string;
};

type TValueAndPercentage = {
  value: number;
  percentage_change: number;
};

type TStats = {
  bets_placed_count: TValueAndPercentage; // bets places
  bets_placed_stake: TValueAndPercentage;
  active_bets_count: TValueAndPercentage; // active bets count
  active_bets_stake: TValueAndPercentage;
  average_bet_stake: TValueAndPercentage;
  bets_settled_count: TValueAndPercentage;
  bets_settled_payout: TValueAndPercentage;
  bets_settled_stake: TValueAndPercentage;
  profit: TValueAndPercentage;
  margin: TValueAndPercentage;
};

export type TByPunterCategory = {
  real_money: TStats;
  bonus_bet: TStats;
  punter_category: string;
};

export type TDashboardDataResponse = {
  real_money: TStats;
  bonus_bet: TStats;
  by_punter_category: TByPunterCategory[];
};

export type TPunterCategory = {
  category: string;
  stats: TValueAndPercentage;
};

export type TPunterCategoryStats = {
  PunterCategoryStats: TPunterCategory[];
};

export type TTransactionStatsResponse = {
  deposit_count: TValueAndPercentage;
  deposit_amount: TValueAndPercentage;
  average_deposit_amount: TValueAndPercentage;
  first_deposit_count: TValueAndPercentage;
  first_deposit_amount: TValueAndPercentage;
  withdrawal_count: TValueAndPercentage;
  withdrawal_amount: TValueAndPercentage;
  total_balance: TValueAndPercentage;
  average_bet_stake?: TValueAndPercentage;
  new_punters: number;
  all_bonus_percentage_of_turnover: TValueAndPercentage;
  auto_bonus_percentage_of_turnover: TValueAndPercentage;
  auto_deposit_match_amount: TValueAndPercentage;
  auto_deposit_match_count: TValueAndPercentage;
  bet_boost_amount: TValueAndPercentage;
  bet_boost_count: TValueAndPercentage;
  bet_slip_bonus_amount: TValueAndPercentage;
  bet_slip_bonus_count: TValueAndPercentage;
  bonus_revoke_amount: TValueAndPercentage;
  bonus_revoke_count: TValueAndPercentage;
  deposit_match_amount: TValueAndPercentage;
  deposit_match_count: TValueAndPercentage;
  manual_bonus_percentage_of_turnover: TValueAndPercentage;
  money_back_amount: TValueAndPercentage;
  money_back_count: TValueAndPercentage;
  other_bonus_amount: TValueAndPercentage;
  other_bonus_count: TValueAndPercentage;
  auto_bonus_amount: TValueAndPercentage;
  manual_bonus_amount: TValueAndPercentage;
  all_bonus_amount: TValueAndPercentage;
};

export function getDashboardData(queryParams: TDashboardQueryParams) {
  return api.get<TDashboardDataResponse>('/dashboard/bet-stats/', {
    params: queryParams,
  });
}

export function queryPunterStats(queryParams: TDashboardQueryParams) {
  return api.get<TPunterCategoryStats>('/dashboard/punter-bet-stats/', {
    params: queryParams,
  });
}

export function queryTransactionStats(queryParams: TDashboardQueryParams) {
  return api.get<TTransactionStatsResponse>('/dashboard/transaction-stats', {
    params: queryParams,
  });
}
