import React from 'react';
import { Column } from '@tanstack/react-table';
import { BetsTable } from '@/common/components';
import { PaginationV2 } from '@/common/components/Pagination/PaginationV2';
import { useSRMRunners } from '../hooks/SRMRunners.hooks';
import { useSRMRunnerColumns } from '../config/SRMRunners.config';

export default function SRMRunnersTab() {
  const {
    data,
    isLoading,
    next,
    previous,
    previousDisabled,
    nextDisabled,
    hidePagination,
  } = useSRMRunners();
  const { columns } = useSRMRunnerColumns();

  const { runners } = data || {
    runners: [],
    paging: {
      next_offset: null,
    },
  };

  return (
    <>
      <BetsTable
        columns={
          columns as unknown as Column<Api['SRMultisRaceRunnerStatsResponse']>[]
        }
        data={runners as Api['SRMultisRaceRunnerStatsResponse'][]}
        loading={isLoading}
        initialSort={[{ id: 'exposure', desc: true }]}
      />
      <PaginationV2
        {...{
          onNext: next,
          onPrev: previous,
          hidePagination,
          nextDisabled,
          previousDisabled,
        }}
      />
    </>
  );
}
