// TODO: move dir to src/features/betApprovals/pages/BetApprovals/tabs/components

import React from 'react';
import { MarketInfoSRM } from './MarketInfoSRM';
import { MarketInfoBlended } from './MarketInfoBlended';
import { MarketInfoSGM } from './MarketInfoSGM';
import { MarketInfoMulti } from './MarketInfoMulti';
import { MarketInfoSingleRacing } from './MarketInfoSingleRacing';
import { MarketInfoSingleSport } from './MarketInfoSingleSport';
import { CommonResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import {
  extractBlendedValuesForMarketInfo,
  extractSGMValuesForMarketInfo,
  extractSRMValuesForMarketInfo,
  extractSMultiValuesForMarketInfo,
  extractSingleRacingValuesForMarketInfo,
  extractSingleSportValuesForMarketInfo,
} from './utils';

export enum EBetTypes {
  SRM = 'SRMulti',
  BLENDED = 'Blended',
  SGM = 'SGMulti',
  MULTI = 'Multi',
  SINGLE_RACING = 'SINGLE_RACING',
  SINGLE_SPORT = 'SINGLE_SPORT',
}

interface IMarketTableItemProps {
  type: string;
  data: CommonResponse;
}

export function MarketTableItem({ type, data }: IMarketTableItemProps) {
  if (type === 'Single') {
    type =
      data.betLegs[0].event_type === 'Match'
        ? EBetTypes.SINGLE_SPORT
        : EBetTypes.SINGLE_RACING;
  }
  switch (type) {
    case EBetTypes.SRM: {
      const srmInfo = extractSRMValuesForMarketInfo(data);
      return <MarketInfoSRM {...srmInfo} />;
    }
    case EBetTypes.BLENDED: {
      const blendedInfo = extractBlendedValuesForMarketInfo(data);
      return <MarketInfoBlended {...blendedInfo} />;
    }
    case EBetTypes.SGM: {
      const sgmInfo = extractSGMValuesForMarketInfo(data);
      return <MarketInfoSGM {...sgmInfo} />;
    }
    case EBetTypes.MULTI: {
      const multiInfo = extractSMultiValuesForMarketInfo(data);
      return <MarketInfoMulti {...multiInfo} />;
    }
    case EBetTypes.SINGLE_RACING: {
      const singleRacing = extractSingleRacingValuesForMarketInfo(data);
      return <MarketInfoSingleRacing {...singleRacing} />;
    }
    case EBetTypes.SINGLE_SPORT: {
      const singleSport = extractSingleSportValuesForMarketInfo(data);
      return <MarketInfoSingleSport {...singleSport} />;
    }
    default: {
      return <p>N/A</p>;
    }
  }
}
