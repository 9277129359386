import Input, { TIndex } from '@/common/components/FormElements/Input';
import {
  chakra,
  ChakraComponent,
  IconProps,
  Icon,
  Flex,
  FlexProps,
  TextProps,
  Text,
  ModalBody,
  ModalBodyProps,
} from '@chakra-ui/react';

export const TextError: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    color: 'red',
    fontSize: '12',
    mt: '3',
  }),
});

export const InputExpectedWin: ChakraComponent<'div', TIndex> = chakra(Input, {
  baseStyle: () => ({
    maxW: '12',
    textAlign: 'center',
  }),
});

export const IconInfo: ChakraComponent<'select', IconProps> = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '4',
    ml: '2',
    mb: '2',
    color: 'gray.600',
  }),
});

export const FlexRow: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    w: 'full',
    borderBottom: 'solid 1px',
    borderColor: 'gray.100',
    py: '3',
    '&&': { mt: '0' },
    '&:first-of-type': {
      borderTop: 'solid 1px',
      borderColor: 'gray.100',
    },
  }),
});

export const TextStub: ChakraComponent<'span', TextProps> = chakra(Text, {
  baseStyle: () => ({
    textAlign: 'left',
    fontWeight: 'medium',
    color: 'gray.600',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  }),
});

export const ModalBodyForm: ChakraComponent<'div', ModalBodyProps> = chakra(
  ModalBody,
  {
    baseStyle: () => ({
      overflowY: 'auto',
      overflowX: 'hidden',
      maxH: '65vh',
      py: '10',
    }),
  }
);
