import { permissions, routes } from '@/configs/permissions.config';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryProfile } from '@/api/profile/profile.hooks';

export const usePermission = () => {
  const { data } = useQueryProfile();

  const navigate = useNavigate();
  const location = useLocation();

  const hasPermission = data?.permissions?.filter((el: string) =>
    permissions.includes(el)
  );

  const isPermitted = routes.some((route) => {
    const currentPath = location.pathname;
    return (
      currentPath.includes(route.path) &&
      data?.permissions?.some((permission) => permission === route.permissonId)
    );
  });

  useEffect(() => {
    if (data && !isPermitted)
      navigate('/settings/your-profile', { replace: true });
  }, [isPermitted, data, navigate]);

  return { hasPermission };
};
