import React, { FC } from 'react';
import { ToggleLabel, ToggleInput, ToggleSpan } from './Styles';

interface ToggleProps {
  name: string;
  parentTitle?: string;
  active?: boolean;
  toggleData?: string[];
  toggleClick?: (
    event: React.ChangeEvent<HTMLInputElement>,
    data?: string[]
  ) => void;
  dataCy?: string;
  testId?: string;
  disabled?: boolean;
}

export const Toggle: FC<ToggleProps> = ({
  name,
  parentTitle,
  active,
  toggleClick,
  toggleData,
  dataCy,
  testId,
  disabled = false,
}) => (
  <ToggleLabel>
    <ToggleInput
      type="checkbox"
      checked={active}
      onChange={(event) => {
        if (!toggleClick) return;
        toggleClick(event, toggleData);
      }}
      name={name}
      data-parent={parentTitle}
      data-cy={dataCy}
      data-testid={`${testId}-toggle`}
      disabled={disabled}
    />
    <ToggleSpan style={disabled ? { cursor: 'not-allowed' } : {}} />
  </ToggleLabel>
);
