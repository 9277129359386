import {
  Badge,
  BadgeProps,
  BoxProps,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Text,
} from '@chakra-ui/react';

type TBadgeCountdown = 'prior' | 'ended' | 'inprogress';

export const BadgeCountdown = chakra(Badge, {
  shouldForwardProp: (prop) => !['state'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ state }: any) => ({
    h: '7',
    w: 'fit-content',
    bg: () => {
      switch (state) {
        case 'prior':
          return 'red.500';
        case 'ended':
          return 'red.600';
        default:
          return 'yellow.400';
      }
    },
    color: () => {
      switch (state) {
        case 'prior':
          return 'gray.600';
        case 'ended':
          return 'white';
        default:
          return 'yellow.900';
      }
    },
    borderRadius: 'md',
    fontSize: 'xs',
    fontWeight: 'medium',
    textTransform: 'capitalize',

    py: '1',
    px: '2',
    mr: '2',
    my: '1',
  }),
}) as ChakraComponent<'div', BadgeProps & { state: TBadgeCountdown }>;

export const CounterWrapper = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'center',

    my: '1',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const DateText = chakra(Text, {
  baseStyle: () => ({
    color: 'gray.600',
  }),
}) as ChakraComponent<'p', TextProps>;
