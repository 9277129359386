import React, { useState } from 'react';
import { BetsTable } from '@/common/components';
import { UseMoneyBackColumns } from './List.config';
import { useNavigate } from 'react-router-dom';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import { queryPromoSummary } from '@/api/promoSummary/promoSummary';
import { useQuery } from 'react-query';

export default function MoneyBackTableContainer() {
  const navigate = useNavigate();
  const { columns } = UseMoneyBackColumns();

  const limit = 20;
  const [history, setHistory] = useState<string[]>([]);
  const [offset, setOffset] = useState<string | undefined>(undefined);

  const { data, isLoading, isFetching } = useQuery(
    ['promoSummary', offset, history],
    () =>
      queryPromoSummary({
        promo_type: 'money_back',
        limit: limit,
        sources: 'manual',
      }),
    { refetchOnWindowFocus: false, refetchOnMount: true }
  );

  const isLastFetch = (data?.data.promotions?.length ?? 0) < limit;

  const handleClickRow = (row: Api['PromoSummaryResponseItem']) =>
    navigate(`/promo-manager/money-back/promotion?id=${row.promo_id ?? ''}`);

  return (
    <>
      <BetsTable
        onClickRow={handleClickRow}
        data={data?.data.promotions ?? []}
        columns={columns}
        loading={isLoading || isFetching}
      />

      <PaginationV3
        nextDisabled={isLastFetch}
        offset={offset}
        onPageChange={(newOffset) => {
          setOffset(newOffset);
        }}
        history={history}
        setHistory={setHistory}
        useHistory
        nextOffsetId={!isLastFetch ? data?.data.paging?.next_offset : undefined}
      />
    </>
  );
}
