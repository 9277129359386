import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../common/hooks/useRedux';
import { setIsConfigureRaceModalOpen } from '../../Services/RaceDetails.slices';
import Settle from './Settle/Settle';
import Tabs, { ETabs } from './components/Tabs';
import Status from './Status/Status';
import Offerings from './Offerings/Offerings';

export default function ConfigureModal() {
  const [selectedTab, setSelectedTab] = useState<ETabs>('settlement');
  const dispatch = useAppDispatch();
  const { isConfigureRaceModalOpen, isConfigureRaceModalLoading } =
    useAppSelector((state) => state.raceDetails);

  return (
    <Modal
      isOpen={isConfigureRaceModalOpen}
      onClose={() =>
        !isConfigureRaceModalLoading &&
        dispatch(setIsConfigureRaceModalOpen(false))
      }
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>Configure Race</ModalHeader>

        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          isDisabled={isConfigureRaceModalLoading}
        />
        {selectedTab === 'settlement' && <Settle />}
        {selectedTab === 'status' && <Status />}
        {selectedTab === 'offerings' && <Offerings />}
      </ModalContent>
    </Modal>
  );
}
