import { PaginationState, Updater } from '@tanstack/react-table';
import { useState } from 'react';

export function useTablePagination(initialState: PaginationState) {
  const [pagination, setPagination] = useState<PaginationState>(initialState);
  const [offset, setOffset] = useState<string>();
  const [offsetList, setOffsetList] = useState<(string | undefined)[]>([
    undefined,
  ]);

  const handlePaginationChange = (
    updater: Updater<PaginationState>,
    nextOffset?: string
  ) => {
    setPagination(updater);

    if (typeof updater !== 'function') {
      return;
    }

    const { pageIndex, pageSize } = pagination;
    const next = updater(pagination);

    if (next.pageIndex < pageIndex) {
      setOffset(offsetList[pageIndex - 1]);
      setOffsetList(offsetList.slice(0, -1));
    }

    if (next.pageIndex > pageIndex) {
      setOffset(nextOffset);
      setOffsetList([...offsetList, nextOffset]);
    }

    if (next.pageSize !== pageSize) {
      setOffset(undefined);
      setOffsetList([undefined]);
    }
  };

  return {
    pagination: { ...pagination, offset },
    handlePaginationChange,
  };
}
