import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../lib/api/api';
import {
  setRaceNumbers,
  setRaceResults,
  setExoticResults,
  setRaceMarkets,
  setToteMultiResults,
} from './RaceDetails.slices';

import { AppThunkDispatch } from '../../../../../app/types';
import { TRaceNumbersForVenueResponse } from './RaceDetails.types';
import {
  TRaceResults,
  TRaceExoticResult,
  TRunnerResult,
  TRaceMarket,
  TGetRace,
  TToteMultiResults,
} from '@/lib/DBModels';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const getRaceResults = createAsyncThunk<
  TRaceResults,
  string,
  { dispatch: AppThunkDispatch }
>(
  'raceDetails/getRaceResults',
  async (raceId, thunkAPI): Promise<TRaceResults> => {
    const raceResultData = await apiGetRequest<TRaceResults>(
      `${getPrefixPath(
        isWincore
      )}/trade-manager/race-details/race-result?race_id=${raceId}`
    );

    thunkAPI.dispatch(
      setRaceResults((raceResultData?.runner_results as TRunnerResult[]) ?? [])
    );

    thunkAPI.dispatch(
      setToteMultiResults(
        (raceResultData?.tote_multi_results as TToteMultiResults[]) ?? []
      )
    );

    thunkAPI.dispatch(
      setExoticResults(
        (raceResultData?.exotic_results as TRaceExoticResult[]) ?? []
      )
    );

    return raceResultData;
  }
);

export const getRaceMarkets = createAsyncThunk<
  TRaceMarket[],
  TGetRace,
  { dispatch: AppThunkDispatch }
>(
  'raceDetails/getRaceMarkets',
  async (params, thunkAPI): Promise<TRaceMarket[]> => {
    const raceMarkets = await apiGetRequest<TRaceMarket[]>(
      `/${getPrefixPath(
        isWincore
      )}/trade-manager/race-details/race-markets?race_id=${params.raceId}${
        params.isUpdate ? `&t=${new Date().valueOf()}` : ''
      }`
    );

    thunkAPI.dispatch(setRaceMarkets(raceMarkets));

    return raceMarkets;
  }
);

/**
 * Gets Race numbers for venues.
 * @param raceId
 */
export const getRaceNumbersForVenue = createAsyncThunk<
  TRaceNumbersForVenueResponse[],
  string,
  { dispatch: AppThunkDispatch }
>('raceDetails/getRaceNumbersForVenue', async (raceId, thunkAPI) => {
  const raceNumbersForVenue = await apiGetRequest<
    TRaceNumbersForVenueResponse[]
  >(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/race-details/race-numbers-for-venue?race_id=${raceId}`
  );

  thunkAPI.dispatch(setRaceNumbers(raceNumbersForVenue));

  return raceNumbersForVenue;
});
