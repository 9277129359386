/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { useParams } from 'react-router-dom';
import { keys } from '../../api.keys';
import { TBetsData } from '../punters.types';
import { mutateVoidBet, queryBetsData } from './bets';
import { TPunterBetParams, TVoidBetInput } from './bets.types';

// Punter - Bets

type TQueryPunterBetsHook = {
  params?: TPunterBetParams;
  key?: any[];
  options?: UseQueryOptions<TBetsData[]>;
};

export const useQueryPunterBets = ({
  params = {},
  key = [],
  options = {},
}: TQueryPunterBetsHook = {}) => {
  const { punterId } = useParams();

  const query = useQuery(
    [keys.punterBets, punterId, ...Object.values(params), ...key],
    () => queryBetsData(punterId as string, params),
    {
      enabled: !!punterId,
      ...(options as any),
    }
  );

  return query;
};

export const useMutationVoidBet = (
  options?: UseMutationOptions<unknown, unknown, TVoidBetInput>
) => useMutation((data) => mutateVoidBet(data), options);
