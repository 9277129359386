// Punter - Category list

import { UseQueryOptions, useQuery } from 'react-query';
import { TPunterCategoryList } from './punterCategories.types';
import { keys } from '@/api/api.keys';
import { queryPunterCategoryList } from './punterCategories';

type TQueryPunterBetsHook = {
  key?: unknown[];
  options?: UseQueryOptions<TPunterCategoryList[], string[]>;
};

export const useQueryPunterCategoryList = (hook: TQueryPunterBetsHook = {}) => {
  const { key = [], options = {} } = hook;
  const query = useQuery<TPunterCategoryList[], string[]>(
    [keys.punterCategoryList, ...key],
    () => queryPunterCategoryList(),
    {
      ...options,
    }
  );
  return query;
};
