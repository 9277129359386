import styled from 'styled-components';

export const MatchOrRunnerDetailsTable = styled.table`
  margin: 0;
  width: 100%;

  th {
    border-bottom: 1px solid ${({ theme }) => theme.global.colors.separatorLine};
    padding: 10px;
    font-size: 9px;
    font-weight: 500;
    vertical-align: bottom;
  }

  thead > tr > th {
    padding: 10px 10px;
    font-size: 9px;
    font-weight: 500;
    vertical-align: bottom;
  }

  tbody > tr > td {
    border-right: 1px solid ${({ theme }) => theme.global.colors.separatorLine};
    border-bottom: 1px solid ${({ theme }) => theme.global.colors.separatorLine};
    padding: 5px 10px;

    & > span {
      font-weight: 300;
      color: ${({ theme }) => theme.global.colors.textOffGrey};
    }

    &:last-child {
      border-right: none;
    }

    &.no-padding {
      padding: 0;
    }

    &.highlight {
      background: ${({ theme }) => theme.global.colors.babyBlue};
    }

    &.warning {
      background: ${({ theme }) => theme.global.colors.lightRed};
      color: ${({ theme }) => theme.global.colors.red};
      font-weight: bold;

      span {
        color: ${({ theme }) => theme.global.colors.red};
      }
    }
  }
`;

export const OddsSettingsTable = styled.table`
  width: 100%;

  & > tbody > tr {
    & > td {
      padding: 2px 10px;
      border-right: 1px solid
        ${({ theme }) => theme.global.colors.separatorLine};
      color: ${({ theme }) => theme.global.colors.darkGrey};

      :last-child {
        border-right: none;
      }

      strong {
        color: ${({ theme }) => theme.global.colors.darkBlue};
      }

      .tab-odds {
        color: ${({ theme }) => theme.global.colors.midBlue};
      }
    }

    &:last-child > td {
      border-bottom: none;
    }
  }
`;
