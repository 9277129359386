import React, { useMemo } from 'react';
import { centsToDollars } from '@/common/utils';
import { Box, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import PunterInfo from '@/common/components/PunterInfo/PunterInfo';
import { EBetStatus, EPlayerType } from '@/lib/DBModels';
import BetStatusBadge from '@/common/components/BetStatusBadge';

export const useAllBetsColumns = () => {
  const allBetColumns = useMemo<ColumnDef<Api['SGMultiBetResponse']>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'SGM Bets',
        cell: ({ row }) => {
          const { propositions } = row.original;

          return (
            <Box>
              <Text>{propositions?.length} Legs SGM</Text>
              {propositions?.map((el, i: number) => (
                <>
                  <Text key={i} as="strong">
                    {el.market_name}
                  </Text>
                  <Text>{el.name}</Text>
                </>
              ))}
            </Box>
          );
        },
      },
      {
        accessorKey: 'punter',
        header: 'Punter',
        cell: ({ row }) => {
          const { punter_name, punter_category, punter_id } = row.original;

          return (
            <PunterInfo
              punter_category={punter_category as EPlayerType}
              punter_name={punter_name}
              id={punter_id}
            />
          );
        },
      },
      {
        accessorKey: 'duplicate_bets',
        header: 'Duplicate Bets',
        cell: ({ row }) => {
          const { duplicate_bets } = row.original;

          return duplicate_bets;
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date',
        cell: ({ row }) => {
          const { created_at } = row.original;

          return dayjs(created_at).format('DD MMM YYYY HH:mm');
        },
      },
      {
        accessorKey: 'status',
        header: 'Bet Status',
        cell: ({ row }) => {
          const { status } = row.original;

          return <BetStatusBadge status={status as EBetStatus} />;
        },
      },
      {
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        accessorKey: 'odds',
        header: 'Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  return { allBetColumns };
};
