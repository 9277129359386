import { Text, Box, DateInput } from 'grommet';
import styled from 'styled-components';

export const HeaderLabel = styled(Text)`
  display: flex;
  padding: 10px 0;
  color: ${({ theme }) => theme.global.colors.blueGreyBlack};
`;

interface StyledInputProps {
  error?: boolean;
}

export const StyledLabel = styled.label<StyledInputProps>`
  color: ${({ theme, error }) =>
    error ? theme.global.colors.inputError : theme.global.colors.blueGreyBlack};
  font-size: 14px;
`;

export const ErrorLabel = styled.span`
  color: ${({ theme }) => theme.global.colors.inputError};
  font-size: 12px;
  font-weight: 400;
`;

export const LabelContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DateInputSelect = styled(DateInput)`
  height: 55px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  background: ${({ theme }) => theme.global.colors.selectBackground};
  border: none;
  font-weight: 400;
`;

export const DateSelectContainer = styled(Box)``;

export const DateSelectWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  border-radius: var(--bc-radii-lg);
  background: ${({ theme }) => theme.global.colors.selectBackground};
  border: 1px solid ${({ theme }) => theme.global.colors.inputBorderGrey};
  position: relative;

  & > div {
    border: none;

    & > button {
      display: block;
      width: 48px;
      height: 48px%;
      position: relative;
      z-index: 9;

      & > svg {
        display: none;
      }
    }
  }
`;

export const CustomIconWrapper = styled.div`
  position: absolute;
  right: 15px;
  padding-bottom: 2px;
  z-index: 8;
`;
