import React from 'react';
import { BetsTable } from '@/common/components';
import { usePromotions } from './services/Promotions.hooks';
import { Column } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { TData } from '@/api/promoManager/promotions/promotions.types';
import { useToast } from '@chakra-ui/react';
import { useMutationAddPromotion } from '@/api/promoManager/promotions/promotions.hooks';

export default function Promotions() {
  const { columns, data } = usePromotions();
  const validcolumns = columns.filter((column) => !column.isHidden);
  const navigate = useNavigate();

  const handleClickRow = (row: TData) =>
    navigate(`/promo-manager/promotion/${row.id ?? ''}`);

  const toast = useToast();
  const { mutateAsync } = useMutationAddPromotion();

  const handleUpdateOrder = (orderedData: TData[]) =>
    mutateAsync({
      contentBase64: btoa(JSON.stringify(orderedData)),
      contentType: 'application/json',
    }).then(() => {
      toast({
        title: 'Promotion order successfully updated',
        status: 'success',
        position: 'top-right',
        duration: 5000,
      });
    });

  return (
    <BetsTable
      data={data ?? []}
      columns={validcolumns as unknown as Column<TData>[]}
      loading={false}
      onClickRow={handleClickRow}
      isApprovalsTable
      isDraggable
      updateOrder={handleUpdateOrder}
    />
  );
}
