import highroller from '@/assets/icons/player/highroller.svg';
import plus from '@/assets/icons/player/plus.svg';
import triangle from '@/assets/icons/player/triangle.svg';
import amber from '@/assets/icons/player/amber.svg';
import fire from '@/assets/icons/player/fire.svg';
import blacklist from '@/assets/icons/player/blacklist.svg';
import player from '@/assets/icons/player/player.svg';
import recreational from '@/assets/icons/player/recreational.svg';
import { EPlayerType } from '@/lib/DBModels';

export const usePunterCategory = () => {
  const getPlayerIcon = (playerType: Api['PunterCategories']) => {
    switch (playerType) {
      case EPlayerType.HighRoller:
        return highroller;
      case EPlayerType.Player:
        return player;
      case EPlayerType.Recreational:
        return recreational;
      case EPlayerType.Blacklist:
        return blacklist;
      case EPlayerType.Newbie:
        return plus;
      case EPlayerType.Sharp:
        return triangle;
      case EPlayerType.PromoArber:
        return amber;
      default:
        return fire;
    }
  };

  const getPunterCategoryColor = (
    playerType: Api['PunterCategories']
  ): string => {
    switch (playerType) {
      case EPlayerType.HighRoller:
        return 'green.500';
      case EPlayerType.Newbie:
        return 'origin.500';
      case EPlayerType.Player:
        return 'yellow.400';
      case EPlayerType.Recreational:
        return 'purple.400';
      case EPlayerType.Sharp:
        return 'red.500';
      case EPlayerType.ApprovalOnly:
        return 'teal.400';
      case EPlayerType.PromoArber:
        return 'yellow.500';
      default:
        return 'red.900';
    }
  };

  return { getPlayerIcon, getPunterCategoryColor };
};
