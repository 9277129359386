import React from 'react';
import {
  Popover as BCPopover,
  PopoverBody,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverTrigger,
  Button,
  IconButton,
  Icon,
  As,
} from '@chakra-ui/react';
import { MoreVertical } from '@styled-icons/evaicons-solid';

type BetsTablePopoverRow = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: As<any>;
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

export type BetsTablePopoverProps = {
  rows: BetsTablePopoverRow[];
};

export default function BetsTablePopover({ rows }: BetsTablePopoverProps) {
  return (
    <BCPopover placement="end-start">
      <PopoverTrigger>
        <IconButton
          onClick={(e) => e.stopPropagation()}
          aria-label="Expand"
          size="sm"
          icon={<Icon as={MoreVertical} boxSize={5} />}
          variant="unstyled"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            {rows.map((row) => (
              <Button
                key={`btn-${row.title}`}
                leftIcon={<Icon as={row.icon} boxSize="4" />}
                variant="unstyled"
                isDisabled={row?.disabled ?? false}
                fontSize="sm"
                display="flex"
                width="100%"
                justifyContent="flex-start"
                fontWeight="normal"
                onClick={(e) => {
                  e.stopPropagation();
                  row.onClick();
                }}
              >
                {row.title}
              </Button>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </BCPopover>
  );
}
