import { EStorageKey } from '@/common/localStorage/localStorage.keys';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/common/localStorage/localStorage.utils';
import { logError } from '@/common/utils';
import { TRaceDetails, TRunner } from '@/lib/DBModels';

export const loadSwitchState = (storageKey: EStorageKey): string[] => {
  try {
    const parsedSwitchState = getLocalStorageItem(storageKey);

    /* we only keep 30 swtich states in storage at all times, when a new one is added the oldest will be removed */
    if (parsedSwitchState.length > 30) {
      parsedSwitchState.shift();
      setLocalStorageItem(storageKey, parsedSwitchState);
    }

    return parsedSwitchState;
  } catch (e) {
    return [];
  }
};

export const saveSwitchState = (
  value: boolean,
  key: string,
  storageKey: EStorageKey
): void => {
  try {
    if (!key) return;

    const currentSwitchState = getLocalStorageItem(storageKey);

    if (currentSwitchState.includes(key) && !value)
      setLocalStorageItem(
        storageKey,
        currentSwitchState.filter((i) => key !== i)
      );
    else if (!currentSwitchState.includes(key) && value)
      setLocalStorageItem(storageKey, [...currentSwitchState, key]);
  } catch (e) {
    logError(e);
  }
};

/**
 * Resets the prices of a runner in a race to winter values.
 * It updates the win proposition and place proposition of the specified runner
 * with the corresponding winter return amounts and sets the display modifiers to 0.
 *
 * @param race - The race details containing the list of runners.
 * @param runnerId - The ID of the runner to edit.
 * @returns The updated race object with the prices reset to winter values.
 */
export const resetPricesToWinter = (race: TRaceDetails) => {
  const updatedRunners = race?.runners?.reduce<TRunner[]>((acc, runner) => {
    // Retrieve the place proposition and win proposition of the runner to edit
    const placePropositionToEdit = runner?.place_proposition;
    const winPropositionToEdit = runner?.win_proposition;

    // Create an updated copy of the runner object with the updated place and win propositions
    return [
      ...acc,
      {
        ...runner,
        win_proposition: {
          ...winPropositionToEdit,
          proposition_modifier: {
            ...winPropositionToEdit?.proposition_modifier,
            display_modifier: 0,
            modifier: 0,
          },
        },
        place_proposition: {
          ...placePropositionToEdit,
          proposition_modifier: {
            ...placePropositionToEdit?.proposition_modifier,
            display_modifier: 0,
            modifier: 0,
          },
        },
      },
    ];
  }, []);

  const updatedRace = { ...race, runners: updatedRunners };

  return updatedRace;
};
