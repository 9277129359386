import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Header from '../../common/ui/Header';
import { ContentContainer, Content, HeaderWrapper } from './Styles';
import { Motion } from '../../common/components';
import { headerVariants, contentVariants } from './PrivateLayout.config';
import Navigation from '../../common/ui/Navigation';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

export default function PrivateLayout() {
  const isDashboard = window.location.pathname.includes('dashboard');

  return (
    <ContentContainer isDashboard={isDashboard}>
      <VStack flex="1" flexDir="row">
        {!FEATURE_FLAGS.IS_REGELATOR && <Navigation />}
        <Motion variants={contentVariants} w="100%">
          {!FEATURE_FLAGS.IS_REGELATOR && (
            <HeaderWrapper variants={headerVariants}>
              <Header />
            </HeaderWrapper>
          )}
          <Content isDashboard={isDashboard}>
            <Outlet />
          </Content>
        </Motion>
      </VStack>
    </ContentContainer>
  );
}
