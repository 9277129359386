import { apiGetRequest } from '../../../../../lib/api/api';
import { TMarketGroup } from '../../../../../lib/DBModels';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const getMarketGroups = async (
  matchId: string
): Promise<TMarketGroup[]> =>
  apiGetRequest<TMarketGroup[]>(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/sport-details/market-groups/${matchId}`
  );
