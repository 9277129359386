/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TBetsData } from '@/api/punters/punters.types';
import { useQuery, UseQueryOptions } from 'react-query';
import { useQuery as useQueryString } from '../../../common/hooks/useQuery';
import { keys } from '../../api.keys';
import { queryEvenShotBets } from './evenshot';

// Trade Manager - EvenShot Bets
type TQueryEvenShotBetsHook = {
  key?: unknown[];
  options?: UseQueryOptions<TBetsData[]>;
};

export const useQueryEvenShotBets = ({
  key = [],
  options = {},
}: TQueryEvenShotBetsHook = {}) => {
  const q = useQueryString();
  const race_id = q.get('id');

  const query = useQuery(
    [keys.evenShotBets, race_id, {}, ...key],
    () => queryEvenShotBets({ race_id }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
