import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkDispatch } from '../../../../app/types';
import { getStrings } from '../../../../common/utils';
import { apiGetRequest, apiPostRequest } from '../../../../lib/api/api';

import {
  setBookieActivityLogs,
  setBookieBankInformation,
  setBookiesForPlatform,
  setPrivacyPolicy,
  setTermsConditions,
} from './slices';

import {
  TActivityLogBookieForPlatform,
  TRecordUserActionPayload,
  TRecordResetPasswordPayload,
  TBookieActivityLog,
  TBookieActivityLogFilterOptions,
} from './types';
import { BankInformationSchema } from './validators/bankInformation';

const [
  {
    SettingsPage: {
      SystemSetupPage: { Security, Bank },
    },
  },
] = getStrings();

export const recordUserAction = createAsyncThunk<
  void,
  TRecordUserActionPayload,
  { dispatch: AppThunkDispatch }
>('bookie/recordAction', async (payload) => {
  const response = await apiPostRequest<void>(
    '/bookie/account/record-action',
    payload
  );

  return response;
});

export const recordResetPasswordAction = createAsyncThunk<
  void,
  TRecordResetPasswordPayload,
  { dispatch: AppThunkDispatch }
>('bookie/recordResetPassword', async (payload) => {
  const response = await apiPostRequest<void>(
    '/bookie/account/record-reset-password',
    payload
  );

  return response;
});

export const getBookiesForPlatform = createAsyncThunk<
  TActivityLogBookieForPlatform[],
  undefined,
  { dispatch: AppThunkDispatch }
>('settings/getBookiesForPlatform', async (_, thunkAPI) => {
  const bookiesForPlatform = await apiGetRequest<
    TActivityLogBookieForPlatform[]
  >('/bookie/settings/activity-log/bookies-for-platform');
  thunkAPI.dispatch(setBookiesForPlatform(bookiesForPlatform));
  return bookiesForPlatform;
});

export const getBookieActivityLogs = createAsyncThunk<
  TBookieActivityLog[],
  TBookieActivityLogFilterOptions,
  { dispatch: AppThunkDispatch }
>('settings/getBookieActivityLogs', async (filterOptions, thunkAPI) => {
  const params = new URLSearchParams({ ...filterOptions });
  const hasFilters = Object.entries(filterOptions).length !== 0;

  const filters = `${hasFilters ? `?${params.toString()}` : ''}`;

  const url = `/bookie/settings/activity-log/bookie-actions${filters}`;
  const bookieLogs = await apiGetRequest<TBookieActivityLog[]>(url);

  thunkAPI.dispatch(setBookieActivityLogs(bookieLogs));
  return bookieLogs;
});

export const postTermsConditions = createAsyncThunk<
  void,
  { terms_of_service: string },
  { dispatch: AppThunkDispatch }
>('settings/postTermsConditions', async (payload, thunkApi) => {
  await apiPostRequest<null>('/bookie/settings/system/terms', payload, {
    successMessage: Security.postPromoSuccessMessage,
    failMessage: 'Error',
  }).then(() => {
    thunkApi.dispatch(setTermsConditions(payload.terms_of_service));
  });
});

export const getTermsConditions = createAsyncThunk<
  { terms_of_service: string },
  undefined,
  { dispatch: AppThunkDispatch }
>('settings/getTermsConditions', async (_, thunkApi) => {
  const response = await apiGetRequest<{ terms_of_service: string }>(
    '/bookie/settings/system/terms'
  );
  thunkApi.dispatch(setTermsConditions(response.terms_of_service));

  return response;
});

export const postPrivacyPolicy = createAsyncThunk<
  void,
  { privacy_policy: string },
  { dispatch: AppThunkDispatch }
>('settings/postPrivacyPolicy', async (payload, thunkApi) => {
  await apiPostRequest<null>('/bookie/settings/system/privacy', payload, {
    successMessage: Security.postPrivacySuccessMessage,
    failMessage: 'Error',
  }).then(() => {
    thunkApi.dispatch(setPrivacyPolicy(payload.privacy_policy));
  });
});

export const getPrivacyPolicy = createAsyncThunk<
  { privacy_policy: string },
  undefined,
  { dispatch: AppThunkDispatch }
>('settings/getPrivacyPolicy', async (_, thunkApi) => {
  const response = await apiGetRequest<{ privacy_policy: string }>(
    '/bookie/settings/system/privacy'
  );
  thunkApi.dispatch(setPrivacyPolicy(response.privacy_policy));

  return response;
});

export const getBookieBankAccount = createAsyncThunk<
  BankInformationSchema,
  undefined,
  { dispatch: AppThunkDispatch }
>('settings/getBookieBankAccount', async (_, thunkApi) => {
  const response = await apiGetRequest<BankInformationSchema>(
    '/bookie/settings/system/bank'
  );

  thunkApi.dispatch(setBookieBankInformation(response));

  return response;
});

export const postBookieBankAccount = createAsyncThunk<
  void,
  BankInformationSchema,
  { dispatch: AppThunkDispatch }
>('settings/postBookieBankAccount', async (payload, thunkApi) => {
  await apiPostRequest<null>('/bookie/settings/system/bank', payload, {
    successMessage: Bank.postSuccessMessage,
    failMessage: 'Error',
  }).then(() => {
    thunkApi.dispatch(
      setBookieBankInformation({
        account_name: payload.account_name,
        account_number: payload.account_number,
        apca_number: payload.apca_number,
        bank_code: payload.bank_code,
        bsb: payload.bsb,
      })
    );
  });
});
