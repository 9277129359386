import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddMarketBody } from './addMarket.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const addMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/add-market`;

// Trade Manager - Add Market

export const mutateAddMarket = (body: TAddMarketBody) =>
  api.post<TMarketCreationResponse>(addMarketURL, body).then((res) => res.data);
