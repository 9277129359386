import { PathParams, rest } from 'msw';
import { baseURL } from '@/__mocks__/utils';
import { offeringsEndpoint } from './offerings';
import {
  TMutateOfferingsBody,
  TMutateOfferingsResponse,
} from './offerings.types';

export const offeringsHandlers = [
  rest.post<TMutateOfferingsBody, PathParams, TMutateOfferingsResponse>(
    baseURL(offeringsEndpoint),
    (req, res, ctx) => {
      const { starting_price, sgm, blended } = req.body;
      return res(ctx.status(200), ctx.json({ starting_price, sgm, blended }));
    }
  ),
];
