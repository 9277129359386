import {
  BoxProps,
  ChakraComponent,
  chakra,
  Box,
  Button,
  ButtonProps,
} from '@chakra-ui/react';

export const TabWrapper: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    p: '1',
    mb: '3',
    borderRadius: 'md',
    border: '1px',
    borderColor: 'gray.200',
  }),
});

export const TabButton = chakra(Button, {
  shouldForwardProp: (prop) => !['isActive', 'isForRace'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isActive, isForRace }: any) => ({
    mr: '1',
    boxShadow: isActive ? 'md' : 'none',
    borderRadius: 'md',
    border: '1px',
    borderColor: isActive ? 'origin.500' : 'white',
    bg: isActive ? 'origin.500' : isForRace ? 'gray.100' : 'white',
    color: isActive ? 'white' : 'gray.700',
    _hover: {
      bg: isActive ? 'origin.500' : 'white',
      color: isActive ? 'white' : 'origin.500',
      borderColor: 'origin.500',
      boxShadow: 'lg',
    },
  }),
}) as ChakraComponent<'button', ButtonProps>;
