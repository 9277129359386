import { UseQueryOptions, useQuery } from 'react-query';
import { TBookieProfile } from './profile.types';
import { queryProfile } from './profile';
import { keys } from '../api.keys';
import { useMenu } from 'src/common/ui/Header/components/Menu/services/Menu.hooks';

type TUseQueryProfile = {
  key?: string[];
  options?: UseQueryOptions<TBookieProfile>;
};

export const useQueryProfile = ({
  key = [],
  options = {},
}: TUseQueryProfile = {}) => {
  const query = useQuery([keys.bets, ...key], () => queryProfile(), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(options as any),
  });
  const { handleLogout } = useMenu();

  if (query.data === undefined && !query.isLoading) {
    handleLogout();
  }

  return query;
};
