import React from 'react';
import { DeviceMobile } from '@styled-icons/heroicons-outline/DeviceMobile';
import { Desktop } from '@styled-icons/ionicons-sharp/Desktop';
import { Image, Tag, HStack, Icon, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { usePunterCategoriesObject } from '../views/Banner/components/Form/Form.hooks';
import { useQueryBanners } from '@/api/promoManager/banners/banner.hooks';
import { TMedia } from '@/api/promoManager/banners/banner.types';
import { TPlatformType } from '../Banners';

export const useBanners = (platform?: TPlatformType) => {
  const punterCategoryList = usePunterCategoriesObject();
  const { data, isLoading } = useQueryBanners();
  const filteredData = data?.filter((banner) => {
    if (!platform) return true;
    return platform === 'web'
      ? banner.platform === 'web' || !banner.platform
      : banner.platform === 'app';
  });

  const getCategoryNameById = (id: string) => {
    if (!punterCategoryList) return;
    const categoryName = punterCategoryList[id];
    return categoryName ?? null;
  };

  // Table Columns
  const columns = [
    {
      accessorKey: 'desktop',
      header: (
        <FormattedMessage id="promomanagerpage.banners.table.thumbnail" />
      ),
      cell: (props: { row: { original: { media: TMedia } } }) => {
        const cell = props?.row?.original?.media;
        return (
          <Image
            sx={{ maxW: '280px', maxH: '150px' }}
            src={
              (cell.desktop?.publicUrl ||
                cell.mobile?.publicUrl ||
                cell.app?.publicUrl) ??
              ''
            }
          />
        );
      },
    },
    {
      accessorKey: 'title',
      header: <FormattedMessage id="promomanagerpage.banners.table.name" />,
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'status',
      header: <FormattedMessage id="promomanagerpage.banners.table.status" />,
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'location',
      header: <FormattedMessage id="promomanagerpage.banners.table.location" />,
      cell: (props: { getValue: () => string[] }) => (
        <HStack spacing={1}>
          {props.getValue().map((el) => (
            <Tag size="sm" key={el} variant="solid" bg="origin.500">
              {el}
            </Tag>
          ))}
        </HStack>
      ),
    },
    {
      accessorKey: 'display',
      header: (
        <FormattedMessage id="promomanagerpage.banners.table.authenticated" />
      ),
      cell: (props: { getValue: () => string }) => props.getValue(),
    },
    {
      accessorKey: 'punter_category',
      header: (
        <FormattedMessage id="promomanagerpage.banners.table.punterCategory" />
      ),
      cell: (props: { getValue: () => string[] }) => (
        <HStack spacing={1}>
          {props.getValue().map((el) => (
            <Tag size="sm" key={el} variant="solid" bg="origin.500">
              {getCategoryNameById(el)}
            </Tag>
          ))}
        </HStack>
      ),
    },
    {
      accessorKey: 'device',
      header: <FormattedMessage id="promomanagerpage.banners.table.device" />,
      cell: (props: { row: { original: { media: TMedia } } }) => (
        <Flex>
          {!!props.row.original.media.desktop?.publicUrl && (
            <Icon as={Desktop} boxSize="8" />
          )}
          {(!!props.row.original.media.mobile?.publicUrl ||
            props.row.original.media.mobile) && (
            <Icon as={DeviceMobile} boxSize="8" />
          )}
        </Flex>
      ),
    },
    {
      accessorKey: 'order',
      header: <FormattedMessage id="promomanagerpage.banners.table.order" />,
      cell: (props: { row: { index: number } }) => (
        <Flex>{props.row.index + 1}</Flex>
      ),
    },
  ];

  return { columns, data: filteredData, isLoading };
};
