import React from 'react';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { getStrings } from '../../../../../../common/utils';

export const VoidedMatchBanner = () => {
  const [{ Generic }] = getStrings();

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>{Generic.MatchVoid}</AlertTitle>
    </Alert>
  );
};
