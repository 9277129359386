import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { activeButtonStyle } from '../Button';

export const SportFilterNavLink = styled(NavLink)`
  height: 50px;
  width: 50px;
  margin-top: 15px;
  border: 1px solid ${({ theme }) => theme.global.colors.buttonBorder};
  background: white;
  color: ${({ theme }) => theme.global.colors.tabButtonText};
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.active,
  &.selected.active {
    ${activeButtonStyle}
  }

  &.unselected.active {
    border: 1px solid ${({ theme }) => theme.global.colors.buttonBorder};
    color: ${({ theme }) => theme.global.colors.tabButtonText};
  }

  & > div > svg {
    width: 30px;
    display: flex;
    align-content: center;
  }
`;
