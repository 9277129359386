export enum ERegulatorPathname {
  Transactions = 'financial-transaction-history',
  Bets = 'betting-history',
}

export enum ERegulatorFilters {
  Ticket = 'Ticket',
  ClientName = 'ClientName',
  ClientEmail = 'ClientEmail',
}

export type TSearchByOption = {
  label: string;
  value: ERegulatorFilters;
};

export type TRegulatorForm = {
  searchOption?: ERegulatorFilters;
  date_from?: string;
  date_to?: string;
  client_name?: string;
  client_email?: string;
  ticket_number?: string;
};
