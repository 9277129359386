import { Text } from 'grommet';
import styled from 'styled-components';

interface SelectContainerProps {
  variation?: string;
  width?: string;
}

interface StyledInputProps {
  error?: boolean;
}

export const HeaderLabel = styled(Text)<StyledInputProps>`
  display: flex;
  padding: 10px 0;
  color: ${({ theme, error }) =>
    error ? theme.global.colors.inputError : theme.global.colors.blueGreyBlack};
`;

export const ErrorLabel = styled.span`
  color: ${({ theme }) => theme.global.colors.inputError};
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  border-radius: var(--bc-radii-lg);
  background: ${({ theme }) => theme.global.colors.selectBackground};
  height: 56px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.global.colors.inputBorderGrey};

  ${({ variation, theme }) => {
    switch (variation) {
      case 'alternate':
        return `
          border: 1px solid ${theme.global.colors.silver};
          background: ${theme.global.colors.white};
    
          svg {
            stroke: ${theme.global.colors.midBlue};
          }`;
      case 'punter_profile_form':
        return `
          border: 0px solid ${theme.global.colors.silver};
          width: 35vw;
          `;
      default:
        return null;
    }
  }}
`;
