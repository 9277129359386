import faker from '@faker-js/faker';
import { TBookieProfile } from './profile.types';

export const profileMock: TBookieProfile = {
  id: faker.database.mongodbObjectId(),
  first_name: faker.name.firstName(),
  last_name: faker.name.lastName(),
  email: faker.internet.email(),
  mobile: faker.phone.phoneNumber('04########'),
  licensed_state: faker.address.state(),
  license_id: faker.database.collation(),
  brand: 'Wellbet Platform',
  permissions: ['risk_manager_home_view'],
};
