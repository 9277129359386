import React from 'react';
import loadable from '@loadable/component';
import { FC } from 'react';
import Loader from '@/components/Routes/Loader';

const Resulted = loadable(
  () => import('@/features/bets/pages/PlacedBets/tabs/Resulted'),
  { fallback: <Loader /> }
) as FC;

const BetsDashboard = loadable(
  () => import('@/features/bets/pages/PlacedBets/tabs/BetsDashboard'),
  { fallback: <Loader /> }
) as FC;

const Flagged = loadable(
  () => import('@/features/bets/pages/PlacedBets/tabs/Flagged'),
  { fallback: <Loader /> }
) as FC;

export { Resulted, BetsDashboard, Flagged };
