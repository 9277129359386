import api from '../../api';
import { TqueryPunterStickyNoteParams, TPunterStickyNote } from './notes.types';

const punterStickyNoteURL = '/bookie/punters/punter-sticky-note';

// Punter profile - Search
export const queryStickyNote = (params: TqueryPunterStickyNoteParams) =>
  api
    .get<TPunterStickyNote>(`${punterStickyNoteURL}/${params.punter_id}`)
    .then((res) => res.data);

export const mutateStickyNote = (body: TqueryPunterStickyNoteParams) =>
  api
    .post(`${punterStickyNoteURL}/${body.punter_id}`, body)
    .then((res) => res.data);
