import React, { useCallback, useEffect, useState } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { BetsTable, TabNavLink } from '@/common/components';
import { useBanners } from './services/Banners.hooks';
import { Column } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { TData } from '@/api/promoManager/banners/banner.types';
import { useMutationAddBanner } from '@/api/promoManager/banners/banner.hooks';
import { FormattedMessage } from 'react-intl';

export type TPlatformType = 'web' | 'app' | 'both';

type TNavLinks = {
  to: string;
  type: TPlatformType;
  children: JSX.Element;
};

const NAV_LINKS: TNavLinks[] = [
  {
    to: '/promo-manager/banners',
    type: 'web',
    children: <FormattedMessage id="promomanagerpage.tabs.banners.web" />,
  },
  {
    to: '/promo-manager/banners',
    type: 'app',
    children: <FormattedMessage id="promomanagerpage.tabs.banners.app" />,
  },
];

const Banners = () => {
  const [platform, setPlatform] = useState<TPlatformType>('web');
  const { columns, data } = useBanners();
  const navigate = useNavigate();
  const getFilteredData = useCallback(
    () =>
      data?.filter((banner) => {
        if (!platform) return true;
        return platform === 'web'
          ? banner.platform === 'web' || !banner.platform
          : banner.platform === 'app';
      }),
    [data, platform]
  );
  const [filteredOrderedData, setFilteredOrderedData] = useState<
    TData[] | undefined
  >(getFilteredData());

  useEffect(() => {
    if (!filteredOrderedData) {
      setFilteredOrderedData(getFilteredData());
    }
  }, [data, filteredOrderedData, platform, getFilteredData]);

  useEffect(() => {
    setFilteredOrderedData(getFilteredData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  const { mutateAsync } = useMutationAddBanner();

  const toast = useToast();

  const handleUpdateOrder = (orderedData: TData[]) => {
    const orderedDataPlusOtherStuff = [
      ...orderedData,
      ...(data?.filter((banner) =>
        platform === 'web'
          ? banner.platform === 'app'
          : !banner.platform || banner.platform === 'web'
      ) ?? []),
    ];
    setFilteredOrderedData(orderedData);

    return mutateAsync({
      contentBase64: btoa(JSON.stringify(orderedDataPlusOtherStuff)),
      contentType: 'application/json',
    }).then(() => {
      toast({
        title: 'Banner order successfully updated',
        status: 'success',
        position: 'top-right',
        duration: 5000,
      });
    });
  };

  const handleClickRow = (row: TData) =>
    navigate(`/promo-manager/banner/${row.id ?? ''}`);

  return (
    <>
      <Flex alignItems="center" borderBottom="1px" borderColor="gray.100">
        {NAV_LINKS.map((props, index) => {
          const { to } = props;
          return (
            <TabNavLink
              onClick={() => setPlatform(props.type)}
              key={`${to}-${index}`}
              isActive={props.type === platform}
              {...props}
            />
          );
        })}
      </Flex>

      <BetsTable
        data={filteredOrderedData ?? []}
        columns={columns as unknown as Column<TData>[]}
        loading={false}
        onClickRow={handleClickRow}
        isApprovalsTable
        isDraggable
        updateOrder={handleUpdateOrder}
      />
    </>
  );
};

export default Banners;
