import React from 'react';
import { DatePicker } from './styles/Datepicker.styles';
import { DatePickerProps } from './Datepicker.types';

export default function Datepicker(props: DatePickerProps) {
  const { wrapperProps, innerProps } = props;
  return (
    <DatePicker
      wrapperProps={wrapperProps}
      innerProps={innerProps}
      format="dd/MM/yyyy"
      {...props}
    />
  );
}
