import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  dollarsToCents,
  centsToDollars,
} from '../../../../../../../../../common/utils';
import { TGlobalExposureLimits } from '../../../types';
import { globalExposureLimitsDefault } from './ExposureLimits.config';

import {
  queryGlobalExposureLimits,
  updateGlobalExposureLimits,
} from '../../../../../../../../../api/settings/limits/globalExposureLimits';
import { keys } from '../../../../../../../../../api/api.keys';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { ENewRelicActions } from '@/constants/newRelicActions';

export const useExposureLimit = () => {
  const [globalExposureLimits, setGlobalExposureLimits] = useState(
    globalExposureLimitsDefault
  );

  const { data } = useQuery([keys.settings.limits.globalExposureLimits], () =>
    queryGlobalExposureLimits()
  );

  const { mutate: mutateGlobalExposureLimits } = useMutation(
    updateGlobalExposureLimits
  );

  const queryClient = useQueryClient();
  const newRelicLog = useNewRelic();

  const updateExposureLimits = (
    updatedExposureLimits: TGlobalExposureLimits
  ) => {
    const payload = {
      race_win_market_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.race_win_market_exposure_manual_approval_limit
        ),
        10
      ),
      race_place_market_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.race_place_market_exposure_manual_approval_limit
        ),
        10
      ),
      sport_win_market_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.sport_win_market_exposure_manual_approval_limit
        ),
        10
      ),
      sgm_per_bet_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.sgm_per_bet_exposure_manual_approval_limit
        ),
        10
      ),
      srm_per_bet_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.srm_per_bet_exposure_manual_approval_limit
        ),
        10
      ),
      blended_per_bet_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.blended_per_bet_exposure_manual_approval_limit
        ),
        10
      ),
      even_shot_proposition: parseInt(
        dollarsToCents(updatedExposureLimits.even_shot_proposition),
        10
      ),
      even_shot_per_bet_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.even_shot_per_bet_exposure_manual_approval_limit
        ),
        10
      ),
      mystery_bet_proposition: parseInt(
        dollarsToCents(updatedExposureLimits.mystery_bet_proposition),
        10
      ),
      mystery_bet_per_bet_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.mystery_bet_per_bet_exposure_manual_approval_limit
        ),
        10
      ),
      multi_per_bet_exposure_manual_approval_limit: parseInt(
        dollarsToCents(
          updatedExposureLimits.multi_per_bet_exposure_manual_approval_limit
        ),
        10
      ),
    };

    mutateGlobalExposureLimits(payload, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          keys.settings.limits.globalExposureLimits,
        ]);

        newRelicLog(ENewRelicActions.ExposureLimits, {
          message: 'Global limits updated',
          ...payload,
        });
      },
      onError: (e) => {
        newRelicLog(ENewRelicActions.ExposureLimits, {
          error: JSON.stringify(e),
          ...payload,
        });
      },
    });
  };

  useEffect(() => {
    setGlobalExposureLimits({
      race_win_market_exposure_manual_approval_limit: centsToDollars(
        (data?.race_win_market_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      race_place_market_exposure_manual_approval_limit: centsToDollars(
        (data?.race_place_market_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      sport_win_market_exposure_manual_approval_limit: centsToDollars(
        (data?.sport_win_market_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      sgm_per_bet_exposure_manual_approval_limit: centsToDollars(
        (data?.sgm_per_bet_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      srm_per_bet_exposure_manual_approval_limit: centsToDollars(
        (data?.srm_per_bet_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      blended_per_bet_exposure_manual_approval_limit: centsToDollars(
        (data?.blended_per_bet_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      even_shot_proposition: centsToDollars(
        (data?.even_shot_proposition as number) ?? 0,
        false
      ),
      even_shot_per_bet_exposure_manual_approval_limit: centsToDollars(
        (data?.even_shot_per_bet_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
      mystery_bet_proposition: centsToDollars(
        (data?.mystery_bet_proposition as number) ?? 0,
        false
      ),
      mystery_bet_per_bet_exposure_manual_approval_limit: centsToDollars(
        (data?.mystery_bet_per_bet_exposure_manual_approval_limit as number) ??
          0,
        false
      ),
      multi_per_bet_exposure_manual_approval_limit: centsToDollars(
        (data?.multi_per_bet_exposure_manual_approval_limit as number) ?? 0,
        false
      ),
    });
  }, [data]);

  return {
    globalExposureLimits,
    updateExposureLimits,
  };
};
