import { Icon, Image } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledIcon } from 'styled-icons/types';
import { TabButton, TabWrapper } from './styles/Tabs.styles';

type Tab = {
  to: string;
  text: string;
  dataCy: string;
  icon?: StyledIcon | string;
  isHidden?: boolean;
};

type TTabs = {
  tabs: Tab[];
};

export default function Tabs({ tabs }: TTabs) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <TabWrapper>
      {tabs.map((tab) => {
        const isActive =
          tab.to === location.pathname || location.search.includes(tab.to);
        if (tab.isHidden) return null;

        return (
          <TabButton
            key={tab.text}
            isActive={isActive}
            onClick={() => navigate(tab.to)}
            leftIcon={
              tab.icon ? (
                typeof tab.icon === 'string' ? (
                  <Image
                    src={tab.icon}
                    sx={{
                      filter: isActive && 'grayscale(100%) brightness(1000%)',
                    }}
                  />
                ) : (
                  <Icon as={tab.icon} boxSize="6" mr="0" />
                )
              ) : undefined
            }
          >
            {tab.text}
          </TabButton>
        );
      })}
    </TabWrapper>
  );
}
