export type SRMultiRaceBetResponse = {
  bets: Api['SRMultiRaceBetResponse'][];
  paging: {
    next_offset: string | null;
  };
};

export const formatSRMPlacement = (placementStr: string | undefined) => {
  return placementStr?.split(/(\d+)/).join(' ');
};
