import toast from 'react-hot-toast';
import { errorMessageFromHTTPException } from '@/lib/Error';
import { useMutationMarketsStatus } from '@/api/tradeManager/raceDetails/marketStatus/marketStatus.hooks';
import { TMarketStatus } from '@/api/tradeManager/raceDetails/marketStatus//marketStatus.types';
import { ERaceConfigSwitch } from '../WinPlaceConfig.types';

export const useRaceConfig = () => {
  const { mutateAsync } = useMutationMarketsStatus();

  const toggle = async ({
    value,
    market_id,
    key,
  }: {
    value: boolean;
    market_id: string;
    key: string;
  }) => {
    if (!market_id) return;

    const marketsToUpdate: TMarketStatus = {
      id: market_id,
      lock_price: value,
    };

    if (key !== ERaceConfigSwitch.FollowPrices) {
      marketsToUpdate.is_suspended = value;
      marketsToUpdate.lock_price = true;
    }

    try {
      await mutateAsync({
        markets: [marketsToUpdate],
      });
      toast.success('Successfully updated market');
    } catch (error) {
      toast.error(errorMessageFromHTTPException(error));
    }
  };

  return {
    toggle,
  };
};
