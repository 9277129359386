import api from '@/api/api';
import { TMysteryBetParams, TMysteryBetResponse } from './mysteryBet.types';
import { TBetsData } from '@/api/punters/punters.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// Mystery Bet - All Bets
const mysteryBetEndpoint = `/${getPrefixPath(
  isWincore
)}/trade-manager/mystery-bet`;
export const queryMysteryBet = (params: TMysteryBetParams) =>
  api
    .get<TMysteryBetResponse>(mysteryBetEndpoint, { params })
    .then((res) => res.data);

// Rollovers
const mysteryBetRolloversEndpoint = `/${getPrefixPath(
  isWincore
)}/trade-manager/mystery-bet/rollovers`;
export const queryMysteryBetRollovers = (params: TMysteryBetParams) =>
  api
    .get<TBetsData[]>(mysteryBetRolloversEndpoint, { params })
    .then((res) => res.data);
