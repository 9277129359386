import { Textarea as TextareaChakra, TextareaProps } from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import React from 'react';

type TTextarea = {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
} & TextareaProps;

export default function Textarea({ field, ...rest }: TTextarea) {
  const [fieldData] = field ?? [];

  return (
    <TextareaChakra data-cy={`field-${rest.name}`} {...fieldData} {...rest} />
  );
}
