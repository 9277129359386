import {
  useDeletionPunterFlags,
  useMutationPunterFlags,
} from '@/api/punterProfile/punterProfile.hooks';
import { TActionMeta, TPunterFlag } from './PunterFlag.types';

export const usePunterFlag = ({ punter }: TPunterFlag) => {
  const { mutateAsync: addFlag } = useMutationPunterFlags();
  const { mutateAsync: removeFlag } = useDeletionPunterFlags();

  const handleOnchange = (actionMeta: TActionMeta) => {
    if (actionMeta.action === 'select-option') {
      addFlag({
        punter_id: punter?.punter_id ?? '',
        label: actionMeta?.option?.label.replaceAll(' ', '_') ?? '',
      });
    } else {
      removeFlag({
        punter_id: punter?.punter_id ?? '',
        label: actionMeta?.removedValue?.label.replaceAll(' ', '_') ?? '',
      });
    }
  };

  const defaultFlags = punter?.flags?.map((flag) => ({
    value: flag?.label?.replaceAll('_', ' '),
    label: flag?.label?.replaceAll('_', ' '),
  }));

  return { handleOnchange, defaultFlags };
};
