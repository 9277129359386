import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { TBetCloudErrorResponse } from '../../../api.types';
import { mutatePriceTypes } from './updatePriceTypes';
import { TMutatePriceTypesBody } from './updatePriceTypes.types';

export const useMutationPriceTypes = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<TBetCloudErrorResponse>,
      TMutatePriceTypesBody
    >,
    'mutationFn'
  >
) => useMutation(mutatePriceTypes, options);
