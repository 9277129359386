/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { keys } from '../api.keys';
import { TPendingApprovalsParams, TSettleBetBody } from './betApprovals.types';
import {
  mutateApprovalStatus,
  mutateSettleBetProps,
  queryPendingApprovals,
} from './betApprovals';
import {
  TBetApprovalsResponse,
  TBetRequestStatusInput,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryProfile } from '../profile/profile.hooks';

type TQueryPendingApprovalsHook = {
  params?: TPendingApprovalsParams;
  key?: any[];
  options?: UseQueryOptions<TBetApprovalsResponse>;
};

export const useQueryPendingApprovals = ({
  params = {},
  key = [],
  options = {},
}: TQueryPendingApprovalsHook = {}) => {
  const { data } = useQueryProfile();

  const isEnabled =
    !!data?.permissions &&
    !!data?.permissions?.includes('bet_approvals_approvals_view');

  const query = useQuery(
    [keys.pendingApprovals, ...Object.values(params), ...key],
    () => queryPendingApprovals(params),
    {
      ...(options as any),
      enabled: !FEATURE_FLAGS.IS_REGELATOR && isEnabled,
    }
  );

  return query;
};

export const useMutationApproval = (
  options?: UseMutationOptions<unknown, unknown, TBetRequestStatusInput>
) =>
  useMutation((data) => mutateApprovalStatus(data), {
    ...options,
  });

export const useMutationSettleBetProps = (
  options?: UseMutationOptions<unknown, unknown, TSettleBetBody>
) =>
  useMutation((data) => mutateSettleBetProps(data), {
    ...options,
  });
