/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { TBetsCsv } from './downloadBets.types';
import { queryDownloadBets } from './downloadBets';
import { TDownloadBetsParams } from './downloadBets.types';

// Regulator - Bets History

type TQueryDownloadBetsHook = {
  params?: TDownloadBetsParams;
  key?: unknown[];
  options?: UseQueryOptions<TBetsCsv>;
};

export const useQueryDownloadBets = ({
  params = {},
  key = [],
  options = {},
}: TQueryDownloadBetsHook = {}) => {
  const query = useQuery(
    [keys.downloadBets, ...Object.values(params), ...key],
    () => queryDownloadBets(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
