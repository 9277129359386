import {
  TBonusAwardItem,
  TRevokeIncentive,
  TPunterReconcile,
  TPunterProfileSettingsParams,
  TPunterProfileOfferingsParams,
  TPunterProfileSettingsSchema,
} from '../../features/punters/pages/Punter/types';
import { TPunterFlag } from './punters.types';
import api from '../api';

export const punterProfileSettings = '/bookie/punters/punter-settings/';
export const mutateProfileCategory = '/bookie/punters/punter-settings';
export const punterOfferings = '/bookie/punters/punter-settings/offerings';
export const depositsForPunter = '/bookie/punters/deposits';
export const reconcilePunterDeposit = '/bookie/punters/deposits/reconcile';
export const addbonusBetsEndpoint = '/bookie/promos/bonus-bets/bonus-award';
export const bonusBetsGetEndpoint = '/bookie/punters/incentives/bonus-bets';
export const revokeIncentiveEndpoint = '/bookie/promos/bonus-bets/bonus-revoke';

// Punter profile - Bookie categories
export const queryPunterSettings = (
  id: string | null,
  params?: Record<string, unknown>
) =>
  api
    .get<TPunterProfileSettingsSchema>(`${punterProfileSettings}${id}`, {
      params,
    })
    .then((res) => res.data);

export const mutatePunterSettings = async (
  profileCategory: TPunterProfileSettingsParams
) => api.post(mutateProfileCategory, profileCategory).then((res) => res.data);

export const mutatePunterOfferings = async (
  params: TPunterProfileOfferingsParams
) => api.post(punterOfferings, params).then((res) => res.data);

// Deposits - Punter - Reconcile
export const mutateReconcilePunter = async (reconcileEntry: TPunterReconcile) =>
  api.post(reconcilePunterDeposit, reconcileEntry).then((res) => res.data);

export const mutateAddBonusBet = async (bonusBetItem: TBonusAwardItem) =>
  api.post(addbonusBetsEndpoint, bonusBetItem).then((res) => res.data);

export const mutateRemoveIncentive = async (revokeItem: TRevokeIncentive) =>
  api.post(revokeIncentiveEndpoint, revokeItem).then((res) => res.data);

export const mutatePunterFlag = (punterFlag: TPunterFlag) =>
  api.post(mutateProfileCategory, punterFlag).then((res) => res.data);
