import {
  ReactRouterLocationState,
  TMarket,
  TProposition,
  TMatch,
  TMarketGroup,
} from '../../../../lib/DBModels';
import { TCompetition, TTournament } from '../TradeManager/tabs/BySport/types';
import { TSport, TStatusType } from '../TradeManager/types';
import { ETabs } from './components/ModalConfigure/ModalConfigure.types';

export interface MatchDetailsState {
  isModalSettleOpen: boolean;
  modalSettleData: TMarket | undefined;
  isConfigureMatchModalOpen: boolean;
  modalVoidData: TVoidSuspendModalData | undefined;
  modalSuspendData: TVoidSuspendModalData | undefined;
  modalConfigureData: TConfigureModalData;
  isModalConfigureLoading: boolean;
  matchData: TMatch | undefined;
  matchMarkets: TMarket[];
  selectedMarketGroup: TMarketGroup | undefined;
  selectedMarketPropositions: TProposition[];
  selectedMarket: TMarket | undefined;
  selectedConfigTab: ETabs;
}

export type TVoidSuspendModalData = {
  proposition?: TProposition;
  market?: TMarket;
};

export type TConfigureModalData = {
  configureLevel?: EConfigureLevel;
  match?: TMatch;
  propositions?: TProposition[];
  market?: TMarket;
};

export enum EConfigureLevel {
  Match = 'match',
  Market = 'market',
  Proposition = 'proposition',
}

export interface ReactRouterLocationBySportFilterState
  extends ReactRouterLocationState {
  filterParams?: {
    sport?: TSport;
    competition?: TCompetition;
    tournament?: TTournament;
    status?: TStatusType;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    selectedPage: number;
  };
}

export type TMarketId = {
  market_id: string;
};

export type TMarketParams = {
  matchId: string;
  marketGroupId?: string;
  isFocus?: boolean;
  isUpdate?: boolean;
};
