import { mutateRaceSettings } from '@/api/srm/srm';
import { RaceSettingsDTO } from '@/api/srm/srm.types';
import { useQuery as useQueryString } from '@/common/hooks/useQuery';

export const useToggles = () => {
  const queryId = useQueryString().get('id');

  const updateSettings = async (settings: RaceSettingsDTO) => {
    if (!queryId) return;
    await mutateRaceSettings(queryId, settings);
  };

  return { updateSettings };
};
