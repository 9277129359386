import React, { FC } from 'react';
import { DateInputProps } from 'grommet';
import {
  DateSelectContainer,
  DateInputSelect,
  StyledLabel,
  ErrorLabel,
  LabelContainer,
  CustomIconWrapper,
  DateSelectWrapper,
} from './Styles';

import { CalendarIcon } from '../../../assets/icons';

export type DropButtonExtendedProps = DateInputProps;

export interface CustomDateSelectProps extends DropButtonExtendedProps {
  labelTitle?: string;
  label?: string;
  error?: boolean;
  errorText?: string;
}

export const DateSelect: FC<CustomDateSelectProps> = ({
  labelTitle,
  label,
  error,
  errorText,
  ...rest
}) => (
  <DateSelectContainer>
    {labelTitle && (
      <LabelContainer>
        <StyledLabel error={error}>{labelTitle}</StyledLabel>
        {errorText && <ErrorLabel>{errorText}</ErrorLabel>}
      </LabelContainer>
    )}
    <DateSelectWrapper>
      <DateInputSelect
        placeholder={label}
        {...rest}
        inputProps={{
          focusIndicator: false,
        }}
      />
      <CustomIconWrapper>
        <CalendarIcon />
      </CustomIconWrapper>
    </DateSelectWrapper>
  </DateSelectContainer>
);
