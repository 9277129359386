/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { queryInPlay } from './inPlay';
import { TInPlayResponse, TInPlayParams } from './inPlay.types';

type TQueryByRacingHook = {
  params?: TInPlayParams;
  key?: unknown[];
  options?: UseQueryOptions<TInPlayResponse>;
};

export const useQueryInPlay = ({
  params = {},
  key = [],
  options = {},
}: TQueryByRacingHook = {}) => {
  const query = useQuery(
    [keys.inPlay, ...Object.values(params), ...key],
    () => queryInPlay(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
