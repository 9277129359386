import { EGeneralStatus } from '../../../lib/DBModels';

export const StatusColorStyles = {
  [EGeneralStatus.Open]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, green.500, green.600)',
  },
  [EGeneralStatus.Closed]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, red.400, red.500)',
  },
  [EGeneralStatus.Abandoned]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, yellow.500, yellow.600)',
  },
  [EGeneralStatus.Scratched]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, gray.400, gray.600)',
  },
  [EGeneralStatus.Voided]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, gray.600, gray.700)',
  },
  [EGeneralStatus.Settled]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, gray.400, gray.600)',
  },
  [EGeneralStatus.Mixed]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, brand.300, brand.600)',
  },
  [EGeneralStatus.ManuallySettled]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, orange.300, orange.600)',
  },
  [EGeneralStatus.Removed]: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, orange.300, orange.600)',
  },
  // Not part of GeneralStatus but required to be shown in status badge
  Suspended: {
    textColour: 'white',
    backgroundColour: 'linear(to-l, yellow.400, yellow.500)',
  },
};

export const BadgeSizeStyles = {
  // Example use - used on trade manager proposition rows
  sm: {
    px: 2,
    py: 1,
    fontSize: '2xs',
  },
  // Example use - used on trade manager header
  md: { px: 4, py: 2, fontSize: 'xs' },
  // Example use = used on trade manager overview roes
  lg: {
    width: '100%',
  },
};
