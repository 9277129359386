import { useState } from 'react';

type FileWithPreview = File & {
  preview: string;
};

export const useDropzonePreview = () => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        /* `reader.result` contains the data URL */
        const dataUrl = reader.result as string;

        /* Extract the base64 data from the data URL */
        const base64Data = dataUrl;

        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return { convertToBase64, setFiles, files };
};
