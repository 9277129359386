import BetStatusBadge from '@/common/components/BetStatusBadge';
import PunterInfo from '@/common/components/PunterInfo/PunterInfo';
import { centsToDollars } from '@/common/utils';
import { EBetStatus, EPlayerType } from '@/lib/DBModels';
import { Flex, Box, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

export const useBlendedAllBets = () => {
  const allBetsColumns = useMemo<ColumnDef<Api['BlendedBetResponse']>[]>(
    () => [
      {
        accessorKey: 'runners',
        header: 'Runners',
        cell: ({ row }) => {
          const { runners } = row.original;

          return (
            <Box>
              <Text>{runners?.length} Leg Blended</Text>
              {runners?.map((el) => (
                <Flex ml="3" mt="2" key={el.name} gap="2">
                  <Text as="strong">{el.number}.</Text>
                  <Text as="strong">{el.name}</Text>
                </Flex>
              ))}
            </Box>
          );
        },
      },
      {
        id: 'duplicate_bets',
        accessorKey: 'duplicate_bets',
        header: 'Duplicate Bets',
        cell: ({ row }) => {
          const { duplicate_bets } = row.original;

          return duplicate_bets;
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date',
        cell: ({ row }) => {
          const { created_at } = row.original;

          return dayjs(created_at).format('DD MMM YYYY HH:mm');
        },
      },
      {
        accessorKey: 'punter',
        header: 'Punter',
        cell: ({ row }) => {
          const { punter_name, punter_category, punter_id } = row.original;

          return (
            <PunterInfo
              punter_category={punter_category as EPlayerType}
              punter_name={punter_name}
              id={punter_id}
            />
          );
        },
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'status',
        cell: ({ row }) => {
          const { status } = row.original;

          return <BetStatusBadge status={status as EBetStatus} />;
        },
      },
      {
        id: 'stake',
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        id: 'odds',
        accessorKey: 'odds',
        header: 'Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        id: 'exposure',
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  return { allBetsColumns };
};
