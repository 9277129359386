import React from 'react';
import { Text, Center } from '@chakra-ui/react';
import { centsToDollars } from '../../../utils';

export type TBetExposure = {
  exposure?: number;
  isSweeps?: boolean;
  // balance: 'good' | 'neutral' | 'unbalanced';
};

// Shows the exposure for a event. Used in a table.
// Some code is commented out as it is now for P0
export default function EventExposure({ exposure, isSweeps }: TBetExposure) {
  // const { bgColor, textColor } = useMemo(() => {
  //   if (balance === 'unbalanced') {
  //     return {
  //       bgColor: 'red.50',
  //       textColor: 'red.400',
  //     };
  //   }
  //   if (balance === 'neutral') {
  //     return {
  //       bgColor: 'orange.50',
  //       textColor: 'orange.400',
  //     };
  //   }
  //   return {
  //     bgColor: 'green.50',
  //     textColor: 'green.400',
  //   };
  // }, [balance]);

  return (
    <Center height="full" width="full">
      <Text>{centsToDollars(exposure, true, isSweeps)}</Text>
    </Center>
  );
}
