import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  queryPunterSettings,
  mutatePunterSettings,
} from '../../../../../api/punters/punters';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../common/hooks/useRedux';

import {
  setReconcileModal,
  setReconcileDepositId,
  setModalBalanceAdjustment,
  setModalEditPayment,
  setModalRemovePayment,
} from '../slices';

export const useModals = () => {
  const dispatch = useAppDispatch();

  const { modalBalanceAdjustment, modalEditPayment, modalRemovePayment } =
    useAppSelector((state) => state.punter);

  const showBalanceAdjustmentModal = () => {
    dispatch(setModalBalanceAdjustment(true));
  };

  const closeBalanceAdjustmentModal = () =>
    dispatch(setModalBalanceAdjustment(false));

  const showRemovePaymentModal = () => {
    dispatch(setModalRemovePayment(true));
  };

  const closeRemovePaymentModal = () => dispatch(setModalRemovePayment(false));

  const showEditPaymentModal = () => {
    dispatch(setModalEditPayment(true));
  };

  const closeEditPaymentModal = () => dispatch(setModalEditPayment(false));

  return {
    showBalanceAdjustmentModal,
    closeBalanceAdjustmentModal,
    showRemovePaymentModal,
    closeRemovePaymentModal,
    showEditPaymentModal,
    closeEditPaymentModal,
    modalBalanceAdjustmentOpened: !!modalBalanceAdjustment,
    removePaymentModalOpened: !!modalRemovePayment,
    editPaymentModalOpened: !!modalEditPayment,
  };
};

export const useReconcileModal = () => {
  const dispatch = useAppDispatch();

  const { modalReconcile } = useAppSelector((state) => state.punter);

  const showReconcileModal = (amount: number, deposit_id: string) => {
    dispatch(setReconcileModal(amount));
    dispatch(setReconcileDepositId(deposit_id));
  };

  const closeReconcileModal = () => dispatch(setReconcileModal(0));

  return {
    showReconcileModal,
    closeReconcileModal,
    modalReconcile,
    reconcileModalOpened: !!modalReconcile,
  };
};

export const usePunterCategoryProfile = () => {
  const { punterId: id } = useParams();
  const { data, isLoading } = useQuery(
    ['punterCategory', id],
    () => queryPunterSettings(id as string, { t: new Date().valueOf() }),
    {
      staleTime: 60 * 5000, // 5 minutes
      enabled: !!id,
    }
  );

  return {
    data,
    isLoading,
  };
};

export const useMutatePunterCategory = () => {
  const { punterId: id } = useParams<string>();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(mutatePunterSettings);

  const handleSubmit = async (category: string, stormCategory = false) => {
    if (!id) return;

    await mutateAsync({
      punter_id: id,
      bookie_category: category,
      use_storm_category: stormCategory,
      t: new Date().valueOf(),
    });
    await queryClient.invalidateQueries(['punterCategory']);
  };

  return handleSubmit;
};
