/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../../api.keys';
import { queryPunterSearch } from './search';
import { TPunterSearchParams, TPunterSearch } from './search.types';

// Punter profile - Search

type TQueryPunterSearchHook = {
  params?: TPunterSearchParams;
  key?: unknown[];
  options?: UseQueryOptions<TPunterSearch[]>;
};

export const useQueryPunterSearch = ({
  params = {},
  key = [],
  options = {},
}: TQueryPunterSearchHook = {}) => {
  const query = useQuery(
    [keys.punterSearch, ...Object.values(params), ...key],
    () => queryPunterSearch(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
