import { TMarket, TProposition } from '@/lib/DBModels';
import { EGeneralStatus } from '../../../../../../../../../lib/DBModels';
import { EConfigureLevel } from '../../../../../types';
import { EStatusOptions } from '../Status.types';

/* Get status for market */
export const getStatus = (current: TMarket[]) => {
  if (current.length > 0) {
    if (current.every((m) => m.is_suspended)) return EStatusOptions.Suspend;
    if (
      current.every((m) => m.lock_status && m.status === EGeneralStatus.Closed)
    )
      return EStatusOptions.CloseHide;
  }

  return EStatusOptions.Winter;
};

type TIsLevel = {
  configureLevel?: EConfigureLevel;
  matchMarkets?: TMarket[];
  market?: TMarket;
  propositions?: TProposition[];
};

/* checks if the current configure level is supended */
export const isLevelSuspended = ({
  configureLevel,
  matchMarkets,
  market,
  propositions = [],
}: TIsLevel) => {
  const isMatchSuspended =
    configureLevel === EConfigureLevel.Match &&
    matchMarkets?.every((m) => m.is_suspended);

  const isMarketSuspended =
    configureLevel === EConfigureLevel.Market && market?.is_suspended;

  const isPropositionSuspended =
    configureLevel === EConfigureLevel.Proposition &&
    !!propositions.length &&
    propositions[0].is_suspended;

  return isMatchSuspended || isMarketSuspended || isPropositionSuspended;
};

/* checks if the current configure level is closed */
export const isLevelClosed = ({
  configureLevel,
  matchMarkets,
  market,
  propositions = [],
}: TIsLevel) => {
  const isMatchClosed =
    configureLevel === EConfigureLevel.Match &&
    matchMarkets?.every(
      (m) => m.lock_status && m.status === EGeneralStatus.Closed
    );

  const isMarketClosed =
    configureLevel === EConfigureLevel.Market &&
    market?.lock_status &&
    market?.status === EGeneralStatus.Closed;

  const isPropositionClosed =
    configureLevel === EConfigureLevel.Proposition &&
    !!propositions.length &&
    propositions[0].lock_status &&
    propositions[0].status === EGeneralStatus.Closed;

  return isMatchClosed || isMarketClosed || isPropositionClosed;
};
