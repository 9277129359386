import { rest } from 'msw';
import { baseURL } from '../../__mocks__/utils';
import { pendingApprovalsURL } from './betApprovals';
import { pendingApprovalMock } from './pendingApprovals.mocks';

export const pendingApprovalHandler = [
  rest.get(baseURL(pendingApprovalsURL), (req, res, ctx) =>
    res(ctx.delay(), ctx.json(pendingApprovalMock))
  ),
];
