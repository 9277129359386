export enum ENewRelicActions {
  Login = 'bookieLogIn',
  LogOut = 'bookieLogOut',
  BetApproval = 'BetApproval',
  Withdrawal = 'Withdrawal',
  PromoUpload = 'PromoUpload',
  PunterProfileUpdate = 'PunterProfileUpdate',
  PunterDepositLimit = 'PunterDepositLimit',
  ExposureLimits = 'ExposureLimits',
  BetHistory = 'BetHistory',
}
