import { keys } from '@/api/api.keys';
import { useQuery, UseQueryOptions } from 'react-query';
import { TMarketBetResponse } from '../../trademanager.types';
import { queryBetsForRunner } from './betsForRunner';
import { TBetsForRunnerParams } from './betsForRunner.types';

type TQueryBetsForRunnerHook = {
  params: TBetsForRunnerParams;
  options?: Omit<UseQueryOptions<TMarketBetResponse>, 'queryKey' | 'queryFn'>;
};

export const useQueryBetsForRunner = ({
  params,
  options,
}: TQueryBetsForRunnerHook) =>
  useQuery(
    [keys.betsForRunner, params],
    () => queryBetsForRunner({ ...params }),
    options
  );
