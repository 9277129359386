import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getRaceNumbersForVenue, getRaceResults } from './RaceDetails.actions';

import {
  TRaceDetailsState,
  TRaceNumbersForVenueResponse,
} from './RaceDetails.types';
import {
  TRaceDetails,
  TRunnerResult,
  TRaceExoticResult,
  TRaceMarket,
} from '@/lib/DBModels';

const initialState: TRaceDetailsState = {
  raceData: {
    race_id: '',
    display_name: '',
    venue_name: '',
    race_type: '',
    start_time: '',
    runners: [],
    win_modifier: {
      display_modifier: 0,
      modifier: 0,
    },
    place_modifier: {
      display_modifier: 0,
      modifier: 0,
    },
    stats: {
      total_bets: 0,
      turnover: 0,
      exposure: 0,
      player_type_distribution: [],
      win_turnover: 0,
      place_turnover: 0,
      exotic_turnover: 0,
    },
  },
  raceExoticResults: [],
  raceResults: [],
  raceMarkets: [],
  raceNumbers: [],
  raceNumbersLoading: false,
  raceConfigLoading: false,
  manualManageSwitchOn: false,
  ignoreWinterSwitchOn: false,
  raceResultsLoading: false,
  configureRunnerModal: { isOpen: false },
  isConfigureRaceModalOpen: false,
  isConfigureRaceModalLoading: false,
  toteMultiResults: [],
};

const raceDetails = createSlice({
  name: 'raceDetails',
  initialState,
  reducers: {
    setRace: (
      state: TRaceDetailsState,
      action: PayloadAction<TRaceDetails>
    ) => ({
      ...state,
      raceData: action.payload,
    }),
    setExoticResults: (
      state: TRaceDetailsState,
      action: PayloadAction<TRaceExoticResult[]>
    ) => ({
      ...state,
      raceExoticResults: action.payload,
    }),
    setRaceResults: (
      state: TRaceDetailsState,
      action: PayloadAction<TRunnerResult[]>
    ) => ({
      ...state,
      raceResults: action.payload,
    }),
    setToteMultiResults: (
      state: TRaceDetailsState,
      action: PayloadAction<TRunnerResult[]>
    ) => ({
      ...state,
      toteMultiResults: action.payload,
    }),
    setRaceMarkets: (
      state: TRaceDetailsState,
      action: PayloadAction<TRaceMarket[]>
    ) => ({
      ...state,
      raceMarkets: action.payload,
    }),
    setRaceNumbers: (
      state: TRaceDetailsState,
      action: PayloadAction<TRaceNumbersForVenueResponse[]>
    ) => ({ ...state, raceNumbers: action.payload }),
    setManualManageToggle: (
      state: TRaceDetailsState,
      action: PayloadAction<boolean>
    ) => ({ ...state, manualManageSwitchOn: action.payload }),
    setIgnoreWinterSwitch: (
      state: TRaceDetailsState,
      action: PayloadAction<boolean>
    ) => ({ ...state, ignoreWinterSwitchOn: action.payload }),
    setIsConfigureRaceModalOpen: (
      state,
      action: PayloadAction<TRaceDetailsState['isConfigureRaceModalOpen']>
    ) => ({ ...state, isConfigureRaceModalOpen: action.payload }),
    setIsConfigureRaceModalLoading: (
      state,
      action: PayloadAction<TRaceDetailsState['isConfigureRaceModalLoading']>
    ) => ({ ...state, isConfigureRaceModalLoading: action.payload }),
    setRaceConfigLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      raceConfigLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRaceNumbersForVenue.pending, (state: TRaceDetailsState) => ({
        ...state,
        raceNumbersLoading: true,
      }))
      .addCase(
        getRaceNumbersForVenue.fulfilled,
        (state: TRaceDetailsState) => ({
          ...state,
          raceNumbersLoading: false,
        })
      )
      .addCase(getRaceResults.pending, (state: TRaceDetailsState) => ({
        ...state,
        raceResultsLoading: true,
      }))
      .addCase(getRaceResults.fulfilled, (state: TRaceDetailsState) => ({
        ...state,
        raceResultsLoading: false,
      }));
  },
});

// Slice actions
export const {
  setRace,
  setRaceNumbers,
  setManualManageToggle,
  setIgnoreWinterSwitch,
  setRaceResults,
  setExoticResults,
  setIsConfigureRaceModalOpen,
  setIsConfigureRaceModalLoading,
  setRaceConfigLoading,
  setRaceMarkets,
  setToteMultiResults,
} = raceDetails.actions;

// Slide reducer
export default raceDetails.reducer;
