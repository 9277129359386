import { useMutationAddBanner } from '@/api/promoManager/banners/banner.hooks';
import { useBanners } from '../../../services/Banners.hooks';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TData } from '@/api/promoManager/banners/banner.types';

type TBannerType = {
  id: string | undefined;
};

export const useBanner = ({ id }: TBannerType) => {
  const { data, isLoading } = useBanners();
  const { mutateAsync } = useMutationAddBanner();
  const toast = useToast();
  const navigate = useNavigate();

  const bannerToShow = data?.find((el: TData) => el?.id === id);

  const handleDelete = () => {
    const list = data?.filter((el: TData) => el.id !== bannerToShow?.id);
    const JSONObj = JSON.stringify(list);
    const base64String = btoa(JSONObj);

    mutateAsync({
      contentBase64: base64String,
      contentType: 'application/json',
      version: null,
    }).then(() => {
      toast({
        title: 'Banner Successfully Deleted',
        status: 'success',
        position: 'top-right',
        duration: 5000,
      });

      navigate(`/promo-manager/banners/${bannerToShow?.platform ?? 'web'}`, {
        replace: true,
      });
    });
  };

  return { bannerToShow, handleDelete, isLoading };
};
