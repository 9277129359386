import {
  ChakraComponent,
  chakra,
  Flex,
  FlexProps,
  Text,
  TextProps,
  IconProps,
  Icon,
} from '@chakra-ui/react';
import { StylesConfig } from 'react-select';

export const ContainerHeader: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    bg: '#EAEAEA',
    p: '4',
    borderTopRadius: 'md',
    border: 'none',
  }),
});

export const SectionWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    bg: '#F8F8F8',
    p: '4',
    gap: '2',
    mb: '4',
    borderRadius: 'md',
    border: '1px solid',
    borderColor: '#EAEAEA',
    flexDir: 'column',
  }),
});

export const SectionLabel: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    textTransform: 'uppercase',
    fontWeight: 'normal',
    color: '#666666',
    fontSize: '12px',
  }),
});

export const selectStyles: StylesConfig = {
  menu: (provided) => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    zIndex: 9999,
  }),
  control: (provided) => ({
    ...provided,
    padding: 0,
    cursor: 'pointer',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#EBF4FF' : 'transparent', // Change background color for selected options
    color: state.isSelected ? '#1E40AF' : '#000', // Change text color for selected options
    cursor: 'pointer',
  }),
  groupHeading: (provided) => ({
    ...provided,
    margin: 0,
    background: '#E2E8F0',
    color: '#4A5568',
    fontSize: '14px',
    padding: '10px',
  }),
  multiValue: (provided) => ({
    ...provided,
    background: '#EBF4FF',
    padding: '5',
    borderRadius: '4px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

// Race Card
export const RaceCardViewWrapper: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    baseStyle: () => ({
      flexDir: 'column',
      w: 'full',
      bg: '#194C87',
      p: '4',
      borderRadius: 'md',
      boxShadow: 'lg',
      mt: '3',
    }),
  }
);

export const RaceCard: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    w: 'full',
    justifyContent: 'space-between',
    flexDir: 'row',
    alignItems: 'center',
    bg: 'white',
    borderRadius: 'md',
    mb: '2',
    p: '3',
  }),
});

export const RemoveRaceIcon: ChakraComponent<'p', IconProps> = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '10',
    cursor: 'pointer',
    transition: '.3s ease-in-out',
    _hover: {
      bg: '#194C87',
      borderRadius: 'full',
      p: '2',
      color: 'white',
      boxShadow: 'md',
    },
  }),
});
