import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UseQueryResult } from 'react-query';
import SkeletonTableBody from '@/common/components/SkeletonTableBody/SkeletonTableBody';
import SkeletonTableCell from '@/common/components/SkeletonTableCell/SkeletonTableCell';
import {
  TPaginatedTableOptions,
  usePaginatedTable,
} from '@/hooks/usePaginatedTable';

type TTableProps = TPaginatedTableOptions<Api['PromoSummaryResponseItem']> &
  Pick<UseQueryResult, 'isError' | 'isFetching' | 'isLoading'>;

export const PAGE_SIZES = [10, 20, 30, 40, 50] as const;

export const Table = ({
  isError,
  isFetching,
  isLoading,
  ...rest
}: TTableProps) => {
  const {
    getState,
    getRowModel,
    getHeaderGroups,
    setPageSize,
    getCanPreviousPage,
    getCanNextPage,
    previousPage,
    nextPage,
  } = usePaginatedTable(rest);

  if ((!isLoading && !getRowModel().rows.length) || isError) {
    return (
      <Text p="4">
        <FormattedMessage id="generic.notAvailable" />
      </Text>
    );
  }

  return (
    <>
      <TableContainer>
        <ChakraTable variant="ledger">
          <VisuallyHidden as={TableCaption}>
            <FormattedMessage id="promomanagerpage.tablecaption" />
          </VisuallyHidden>
          <Thead>
            {getHeaderGroups().map(({ id, headers }) => (
              <Tr key={id}>
                {headers.map(({ id, column, getContext }) => (
                  <Th key={id}>
                    {flexRender(column.columnDef.header, getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <SkeletonTableBody
            isLoaded={!isLoading}
            noOfColumns={rest.columns.length}
          >
            <Tbody>
              {getRowModel().rows.map(({ id, getVisibleCells }) => (
                <Tr key={id}>
                  {getVisibleCells().map(
                    ({ id, column, getContext }, index) => {
                      const isHeader = index === 0;
                      return (
                        <SkeletonTableCell key={id} isLoaded={!isFetching}>
                          <Td
                            as={isHeader ? 'th' : undefined}
                            _empty={{ _before: { content: '"-"' } }}
                          >
                            {flexRender(column.columnDef.cell, getContext())}
                          </Td>
                        </SkeletonTableCell>
                      );
                    }
                  )}
                </Tr>
              ))}
            </Tbody>
          </SkeletonTableBody>
        </ChakraTable>
      </TableContainer>
      <Flex p="4">
        <ButtonGroup>
          <Button
            isDisabled={!getCanPreviousPage()}
            onClick={() => previousPage()}
          >
            <FormattedMessage id="generic.previous" />
          </Button>
          <Button isDisabled={!getCanNextPage()} onClick={() => nextPage()}>
            <FormattedMessage id="generic.next" />
          </Button>
        </ButtonGroup>
        <FormControl ml="auto" w="auto">
          <VisuallyHidden as={FormLabel}>
            <FormattedMessage id="promomanagerpage.pagesizelabel" />
          </VisuallyHidden>
          <Select
            isDisabled={isFetching}
            value={getState().pagination.pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {PAGE_SIZES.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                <FormattedMessage
                  id="promomanagerpage.pagesizeoption"
                  values={{ pageSize }}
                />
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>
    </>
  );
};
