import {
  chakra,
  ChakraComponent,
  Checkbox,
  CheckboxProps,
  Flex,
  FlexProps,
  HTMLChakraProps,
} from '@chakra-ui/react';

export const BCLabel: ChakraComponent<
  'label',
  HTMLChakraProps<'label'>
> = chakra('label', {
  baseStyle: {
    bg: 'transparent',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 10,
    border: '2px solid',
    borderColor: '#009CFF',
    color: '#009CFF',
    rounded: 'md',
    cursor: 'pointer',
    transition: '.2s ease-in-out',
    textAlign: 'center',
    px: '3',
  },
});

export const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
    justifyContent: 'center',
    boxSize: '5',
    border: '2px solid',
    borderColor: 'origin.500',
    borderRadius: 'full',
    bg: 'transparent',
  }),
});

export const IconCheckbox: ChakraComponent<'input', CheckboxProps> = chakra(
  Checkbox,
  {
    baseStyle: () => ({
      w: '6',
      h: '6',
      color: 'white',
      borderRadius: 'full',
    }),
  }
);
