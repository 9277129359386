import React, { FC } from 'react';

import { Flex, Skeleton } from '@chakra-ui/react';
import { BrandBadge, HeaderWrapper } from './Header.styles';

import { useBookieProfile } from '../../../features/settings/pages/Settings/tabs/YourProfile/Services/Hooks.BookieProfile';
import { useHeader } from './services/Header.hooks';
import BetMenu from './components/Notification/Notification';
import UserMenu from './components/Menu/Menu';
import SideNavigation from './components/Navigation/Navigation';

const Header: FC = () => {
  const { profile } = useBookieProfile(); // 👀 known - this wont update when the user updates via settings page - need to discuss approach / tradeoffs
  const { data, isLoading } = useHeader();
  if (!data || !profile) return <></>;

  const isDashboard = window.location.pathname.includes('dashboard');

  return (
    <HeaderWrapper isDashboard={isDashboard}>
      <SideNavigation />
      {/* User details */}
      <Flex sx={{ alignItems: 'center', ml: 'auto', borderRadius: 'full' }}>
        <BetMenu />
        {/* Brand name */}
        {(() => {
          if (isLoading) return <Skeleton sx={{ h: '4', w: '16', ml: '4' }} />;
          if (data?.brand) return <BrandBadge>{data.brand}</BrandBadge>;
          return null;
        })()}
        <UserMenu profile={profile} />
      </Flex>
    </HeaderWrapper>
  );
};

export default Header;
