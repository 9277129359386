import {
  centsToDollars,
  centsToDollarsNumber,
  dateFormatter,
  getPositionValue,
} from '@/common/utils';
import React, { useMemo } from 'react';
import { Text, Icon, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CheckSquareFill, App, XSquareFill } from '@styled-icons/bootstrap';
import { TBetsData } from '@/api/punters/punters.types';
import { StartTimeCalculator } from '@/common/components';
import { ColumnDef } from '@tanstack/react-table';

export const useRolloverColumns = () => {
  const columns = useMemo<ColumnDef<TBetsData>[]>(
    () => [
      {
        accessorKey: 'punter_name',
        header: 'Punter',
        cell: ({ row }) => {
          const {
            original: { punter_id, punter_name },
          } = row;

          return (
            <Link to={`/punter/${punter_id}`}>
              <Text color="brand.500">{punter_name ?? ''}</Text>
            </Link>
          );
        },
      },
      {
        accessorKey: 'stake',
        header: 'Starting Stake',
        cell: ({ row }) => {
          const {
            original: { stake },
          } = row;
          return <Text>{centsToDollars(stake ?? 0).replace(/\.00$/, '')}</Text>;
        },
      },
      {
        accessorKey: 'odds',
        header: 'Starting Odds',
        cell: ({ row }) => {
          const {
            original: { odds },
          } = row;
          return <Text>{odds?.toFixed(2) ?? 0}</Text>;
        },
      },
      {
        accessorKey: 'bet_legs',
        header: 'Legs',
        cell: ({ row }) => {
          const {
            original: { bet_legs },
          } = row;

          return <Text>{bet_legs?.length ?? 0}</Text>;
        },
      },
      {
        accessorKey: 'legs_won',
        header: 'Legs won',
        cell: ({ row }) => {
          const {
            original: { bet_legs },
          } = row;

          const legs = bet_legs;
          const won = legs?.filter((leg) => leg?.is_won);
          return <Text>{won ? won.length : '0'}</Text>;
        },
      },
      {
        accessorKey: 'current_stake_value',
        header: 'Current Stake Value',
        cell: ({ row }) => {
          const {
            original: { current_stake_value },
          } = row;
          return <Text>{centsToDollarsNumber(current_stake_value ?? 0)}</Text>;
        },
      },
      {
        accessorKey: 'current_stake_value',
        header: 'Potential Payout',
        cell: ({ row }) => {
          const {
            original: { bet_legs, current_stake_value },
          } = row;
          const currentStake = current_stake_value ?? 0;
          const accumulatedOdds = bet_legs
            ?.map((leg) => leg?.odds)
            .reduce((prev = 1, curr = 1) => prev * curr);
          let potentialPayout;
          if (accumulatedOdds) {
            potentialPayout = currentStake * accumulatedOdds;
          }
          return (
            <Text>
              {centsToDollars(potentialPayout ?? 0).replace(/\.00$/, '')}
            </Text>
          );
        },
      },
      {
        accessorKey: 'next_leg',
        header: 'Next Leg',
        cell: ({ row }) => {
          const {
            original: { bet_legs },
          } = row;

          const nextLeg = bet_legs?.find((leg) => leg?.status === 'pending');
          return (
            <Flex justify="left">
              {!!nextLeg?.event_start && (
                <StartTimeCalculator date={nextLeg?.event_start} />
              )}
            </Flex>
          );
        },
      },
    ],
    []
  );

  const subcolumns = useMemo<ColumnDef<TBetsData>[]>(
    () => [
      {
        accessorKey: 'is_won',
        header: '',
        cell: ({ row }) => {
          const {
            original: { is_won },
          } = row;

          return (
            <>
              {is_won ? (
                <Icon
                  paddingRight="1"
                  color="green.300"
                  boxSize="5"
                  as={CheckSquareFill}
                />
              ) : is_won === false ? (
                <Icon
                  paddingRight="1"
                  color="red.500"
                  boxSize="5"
                  as={XSquareFill}
                />
              ) : (
                <Icon paddingRight="1" color="gray.300" boxSize="5" as={App} />
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'bet_description',
        header: 'Proposition',
        cell: ({ row }) => {
          const {
            original: { event_data },
          } = row;

          return (
            <Text>
              {!!event_data?.proposition_type &&
                `${getPositionValue(event_data?.proposition_type)} -`}
              {event_data?.runner_number ?? ''}
              {'. '}
              {event_data?.runner_name ?? ''}
            </Text>
          );
        },
      },
      {
        accessorKey: 'bet_market',
        header: 'Market',
        cell: ({ row }) => {
          const {
            original: { event_data },
          } = row;
          return (
            <Text>
              {event_data?.venue_name ?? ''} R{event_data?.race_number ?? ''}
            </Text>
          );
        },
      },
      {
        accessorKey: 'bet_market_type',
        header: 'Market Type',
        cell: ({ row }) => {
          const {
            original: { bet_description, leg_num },
          } = row;

          const isRollover = leg_num === 1;

          return (
            <Text>
              {isRollover && 'Rollover - '}
              {bet_description ?? ''}
            </Text>
          );
        },
      },
      {
        accessorKey: 'odds',
        header: 'Odds',
        cell: ({ row }) => {
          const {
            original: { odds },
          } = row;
          return <Text>{odds?.toFixed(2) ?? 0}</Text>;
        },
      },
      {
        accessorKey: 'event_start',
        header: 'Leg Time',

        cell: ({ row }) => {
          const {
            original: { event_start },
          } = row;
          return (
            <Flex justify="left">
              {dateFormatter(event_start ?? '', 'DD/MM/YYYY, h:mma')}
            </Flex>
          );
        },
      },
    ],
    []
  );

  return { columns, subcolumns };
};
