import { TBetApprovalsResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import api from '../../api';
import { THistoryApprovalsParams } from './history.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';

const historyApprovalsURL = '/bookie/bet-approvals/history';
const wincoreHistoryApprovalsURL =
  '/bookie/global-trading/bet-approvals/history?';

// Bet Approvals - History
export const queryHistoryApprovals = (params: THistoryApprovalsParams) =>
  api
    .get<TBetApprovalsResponse>(
      isWincore ? wincoreHistoryApprovalsURL : historyApprovalsURL,
      { params }
    )
    .then((res) => res.data);
