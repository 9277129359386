import { chakra, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const ParentContainer = styled.div``;

export const ContainerTitle = chakra(Text, {
  baseStyle: () => ({
    fontSize: '2xl',
    fontWeight: 'normal',
    color: 'gray.500',
    mb: '5',
  }),
});

export const ContainerWrapper = styled.div<{ noBottomPad?: boolean }>`
  overflow-x: hidden;
  border-radius: 0;
  padding-bottom: ${({ noBottomPad }) => (noBottomPad ? '0' : '55px')};
`;
