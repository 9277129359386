import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useActions, useModals } from '../../../../Services/BetApprovals.hooks';

import { errorMessageFromHTTPException } from '../../../../../../../../../lib/Error';
import {
  getStrings,
  dollarsToCents,
  centsToDollarsNumber,
} from '../../../../../../../../../common/utils';
import { useAppSelector } from '../../../../../../../../../common/hooks/useRedux';
import {
  EBetRequestStatusInputAction,
  TBetRequestStatusInput,
} from '../../../../Services/BetApprovals.types';
import { betRequestCounterValidation } from '../../../../Services/BetApprovals.config';
import { isWincore } from '../../..';
import api from '@/api/api';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormHOC({ children }: TFormikHOC) {
  const { closeCounterBetModal, modalCounterBet } = useModals();
  const { actionApproval } = useActions();
  const betApprovals = useAppSelector((state) => state.betApprovals);

  const [
    {
      BetApprovalsPage: { Toast: ToastStrings },
    },
  ] = getStrings();

  const isSettlement =
    modalCounterBet?.requestType?.toLowerCase() === 'settlement';

  const maxStakeAllowed = centsToDollarsNumber(
    !isSettlement
      ? betApprovals.modalCounterBet?.requestedStake
      : !!betApprovals.modalCounterBet?.betPayout
      ? betApprovals.modalCounterBet?.betPayout
      : betApprovals.modalCounterBet?.requestedPayout
  );

  return (
    <Formik<TBetRequestStatusInput>
      initialValues={{
        request_id: modalCounterBet?.requestId ?? '',
        action: EBetRequestStatusInputAction.Counter,
      }}
      validationSchema={() =>
        betRequestCounterValidation(maxStakeAllowed, isSettlement)
      }
      onSubmit={async (formData, { setSubmitting }) => {
        const isSettlement =
          betApprovals?.modalCounterBet?.requestType?.toLowerCase() ===
          'settlement';
        const actionPayload: TBetRequestStatusInput = {
          ...formData,
          amount: Number(dollarsToCents(formData?.amount ?? 0)),
        };

        if (isSettlement) {
          actionPayload.type = 'Settlement';
        }

        try {
          if (isWincore) {
            await api.post(
              '/bookie/bet-approvals/global/global-update-status',
              {
                ...formData,
                amount: Number(dollarsToCents(formData?.amount ?? 0)),
                source_url: modalCounterBet?.sourceApiUrl,
                type: isSettlement ? 'Settlement' : 'Placement',
              }
            );
          } else actionApproval(actionPayload);
          closeCounterBetModal();
          toast.success(ToastStrings.Countered);
        } catch (e) {
          toast.error(errorMessageFromHTTPException(e));
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
