import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateAddProposition } from './addProposition';
import { TAddPropositionBody } from './addProposition.types';

export const useMutationAddProposition = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TAddPropositionBody>,
    'mutationFn'
  >
) => useMutation(mutateAddProposition, options);
