import api from '@/api/api';
import {
  TQueryRaceNumbersParams,
  TQueryRaceNumbersResponse,
} from './raceNumbers.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// Race numbers for venue
const raceNumbersURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/race-numbers-for-venue`;

export async function queryRaceNumbers(params: TQueryRaceNumbersParams) {
  const res = await api.get<TQueryRaceNumbersResponse[]>(raceNumbersURL, {
    params,
  });
  return res;
}
