import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';
import {
  chakra,
  IconButton as ChakraIconButton,
  Icon as ChakraIcon,
  Flex,
  keyframes,
  Box,
  ChakraComponent,
} from '@chakra-ui/react';
import { HorizontalCollapse } from '../../components/HorizontalCollapse/HorizontalCollapse';

export const NavLinkLogo = chakra(NavLink, {
  baseStyle: () => ({
    display: 'flex',
    flexDir: 'row',
    px: '2.5',
    ml: '1',
    py: '3',
    pb: '3',
  }),
});

export const StyledNavLink = chakra(NavLink, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isOpen }: any) => ({
    position: 'relative',
    h: '40px',
    borderRadius: 'full',
    borderLeftRadius: isOpen ? '0' : 'full',
    display: 'flex',
    flexDir: 'row',
    alignItems: 'center',
    color: 'gray.500',
    my: '1.5',
    fontWeight: '300',
    transition: '0.3s ease-in-out',
    pl: isOpen ? '3.5' : '2',
    ml: isOpen ? '0' : '1.5',
    mr: '1.5',

    '&:hover': {
      bg: 'gray.200',
      color: 'gray.600',
    },

    '&.active': {
      color: 'white',
      fontWeight: '500',
      bg: 'blue.500',
    },
  }),
}) as ChakraComponent<'link', NavLinkProps & { isOpen?: boolean }>;

export const IconLogo = chakra(ChakraIcon, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isOpen }: any) => ({
    color: 'gray.500',
    boxSize: '6',
    transition: '0.3s ease-in-out',
    transform: isOpen ? 'scale(1.5)' : 'scale(1)',
    mx: isOpen ? '2' : '0',
  }),
});

export const IconTextLogo = chakra(ChakraIcon, {
  baseStyle: () => ({
    color: 'black',
    h: '5',
    w: '100px',
    mt: '0.5',
    ml: '3',
  }),
});

export const NavList = chakra(HorizontalCollapse, {
  baseStyle: () => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    top: '0',
    position: 'sticky',
    borderRight: '2px',
    borderColor: 'gray.100',
  }),
});

export const IconButton = chakra(ChakraIconButton, {
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isOpen }: any) => ({
    bg: 'white',
    color: 'gray.200',
    boxShadow: 'none !important',
    width: '20px',
    mr: '1.5',
    mt: '1.5',
    transition: '0.3s ease-in-out',
    transform: isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',

    '&:hover': {
      bg: 'white',
    },
  }),
});

export const NavIcon = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 8px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavText = chakra(Box, {
  baseStyle: () => ({
    fontSize: 'md',
    alignSelf: 'center',
  }),
});

export const QuickLinks = styled.div`
  font-size: var(--bc-fontSizes-md);
`;

const flash = keyframes`
  0% {
    background-color: red;
    color: white;
  }

  50% {
    background-color: white;
    color: red;
  }

  100% {
    background-color: red;
    color: white;
  }
`;

export const NotificationBadge = chakra(Flex, {
  shouldForwardProp: (prop) => !['icon'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ icon }: any) => ({
    borderRadius: 'full',
    boxSize: icon ? '6' : '8',
    animation: `${flash} 1s infinite`,
    fontSize: icon ? 'xs' : 'sm',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'semibold',
  }),
});
