import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TEventsResponse } from '../../types';

import { TBySportsState, TCompetition } from './types';

import { getCompetitionsTournamentsForSport, getMatches } from './actions';

const initialState: TBySportsState = {
  competitions: [],
  competitionsLoading: false,
  query: {},
  eventsResponse: {
    records: [],
    total_pages: 1,
    total_records: 0,
  },
  eventsLoading: false,
};

const sportsSlice = createSlice({
  name: 'bySports',
  initialState,
  reducers: {
    setCompetitions: (
      state: TBySportsState,
      action: PayloadAction<TCompetition[]>
    ) => ({ ...state, competitions: action.payload }),
    setEventsResponse: (
      state: TBySportsState,
      action: PayloadAction<TEventsResponse>
    ) => ({ ...state, eventsResponse: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCompetitionsTournamentsForSport.pending,
        (state: TBySportsState) => ({ ...state, competitionsLoading: true })
      )
      .addCase(
        getCompetitionsTournamentsForSport.fulfilled,
        (state: TBySportsState) => ({ ...state, competitionsLoading: false })
      )
      .addCase(getMatches.pending, (state: TBySportsState) => ({
        ...state,
        eventsLoading: true,
      }))
      .addCase(getMatches.fulfilled, (state: TBySportsState) => ({
        ...state,
        eventsLoading: false,
      }));
  },
});

export const { setCompetitions, setEventsResponse } = sportsSlice.actions;

export default sportsSlice.reducer;
