import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import Filters from '../../components/Filters/Filters';
import TableChakra from '@/common/components/TableChakra/TableChakra';
import { FormattedMessage } from 'react-intl';
import { useRegulator } from '../../services/Regulator.hooks';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { TBet } from '@/api/regulator/bets/bets.types';
import { useColumns } from './services/BettingHistory.hooks';
import SearchRules from './components/SearchRules';

const BettingHistory = () => {
  const {
    betsData,
    betsLoading,
    isLastFetchForBets,
    offset,
    setOffset,
    searchBy,
    setSearchOption,
    onSubmitSearch,
    isDownloading,
    setIsDownloading,
  } = useRegulator();

  const { columns } = useColumns();

  return (
    <Flex flexDir="column">
      <Filters
        onSubmit={(values) => onSubmitSearch(values)}
        formProps={{
          downloadDisabled: !betsData || !betsData.length,
          isDownloading,
          setIsDownloading,
          searchBy: searchBy,
          onChangeFilter: (searchOption) => setSearchOption(searchOption),
        }}
      />
      <SearchRules />
      <TableChakra<TBet>
        columns={columns}
        data={(betsData as TBet[]) ?? []}
        isLoading={betsLoading}
        placeholder={
          <Text py="5" mb="20">
            <FormattedMessage id="regulatorpage.noresults" />
          </Text>
        }
      />
      <Pagination
        nextDisabled={isLastFetchForBets}
        offset={offset}
        onPageChange={(newOffset) => setOffset(newOffset)}
      />
    </Flex>
  );
};

export default BettingHistory;
