import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TBankState, TPunterWithdrawalStatus } from './types';
import {
  TPunterWithdrawalRequestsPagedHistoryDTO,
  TPunterWithdrawalRequestsResponse,
} from '../../../../lib/DTO';

const initialState: TBankState = {
  withdrawalRequests: {
    total_pages: 0,
    total_records: 0,
    withdrawal_requests: [],
  },
  withdrawalRequestsHistory: {
    total_pages: 0,
    total_records: 0,
    records: [],
  },
  withdrawalRequestsHistoryLoading: false,
  withdrawalRequestsLoading: false,
};

const bank = createSlice({
  name: 'raceDetails',
  initialState,
  reducers: {
    setWithdrawalRequestsHistory: (
      state: TBankState,
      action: PayloadAction<TPunterWithdrawalRequestsPagedHistoryDTO>
    ) => ({ ...state, withdrawalRequestsHistory: action.payload }),
    setWithdrawalRequests: (
      state: TBankState,
      action: PayloadAction<TPunterWithdrawalRequestsResponse>
    ) => ({ ...state, withdrawalRequests: action.payload }),
    updateExistingWithdrawalRequests: (
      state: TBankState,
      action: PayloadAction<TPunterWithdrawalRequestsResponse>
    ) => {
      // Grab existing requests inside store
      const existingRequests = [
        ...(state.withdrawalRequests.withdrawal_requests ?? []),
      ];
      // Loop through each request and create a mutable object to be updated
      // with the response data from the API
      const updatedWithdrawals = existingRequests.map((existingReq) => {
        const mutableReq = { ...existingReq };
        // Find the corresponding existing withdrawal, that matches the updated one by id
        const updatedWithdrawal =
          (action.payload?.withdrawal_requests ?? []).find(
            (updatedReq) =>
              updatedReq &&
              updatedReq.withdrawal_id === mutableReq.withdrawal_id
          ) || null;
        if (!updatedWithdrawal) return mutableReq;

        mutableReq.status =
          updatedWithdrawal?.status || TPunterWithdrawalStatus.Pending;
        return mutableReq;
      });

      return {
        ...state,
        withdrawalRequests: {
          ...action.payload,
          withdrawal_requests: updatedWithdrawals,
        },
      };
    },
  },
  extraReducers: {
    // get withdrawal requests
    'bank/getWithdrawalRequests/pending': (state: TBankState) => ({
      ...state,
      withdrawalRequestsLoading: true,
    }),
    'bank/getWithdrawalRequests/fulfilled': (state: TBankState) => ({
      ...state,
      withdrawalRequestsLoading: false,
    }),
    'bank/getWithdrawalRequests/rejected': (state: TBankState) => ({
      ...state,
      withdrawalRequestsLoading: false,
    }),
    // get withdrawal requests history
    'bank/getWithdrawalRequestsHistory/pending': (state: TBankState) => ({
      ...state,
      withdrawalRequestsHistoryLoading: true,
    }),
    'bank/getWithdrawalRequestsHistory/fulfilled': (state: TBankState) => ({
      ...state,
      withdrawalRequestsHistoryLoading: false,
    }),
    'bank/getWithdrawalRequestsHistory/rejected': (state: TBankState) => ({
      ...state,
      withdrawalRequestsHistoryLoading: false,
    }),
    // update existing requests
    'bank/actionWithdrawalRequest/pending': (state: TBankState) => ({
      ...state,
      withdrawalRequestsLoading: true,
    }),
    'bank/actionWithdrawalRequest/fulfilled': (state: TBankState) => ({
      ...state,
      withdrawalRequestsLoading: false,
    }),
    'bank/actionWithdrawalRequest/rejected': (state: TBankState) => ({
      ...state,
      withdrawalRequestsLoading: false,
    }),
  },
});

// Slice actions
export const {
  setWithdrawalRequests,
  setWithdrawalRequestsHistory,
  updateExistingWithdrawalRequests,
} = bank.actions;

// Slide reducer
export default bank.reducer;
