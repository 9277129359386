import { EDisplay } from '@/api/promoManager/banners/banner.types';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  id: yup.string().required('ID is required'),
  title: yup.string().required('Title is required'),
  location: yup.array().when('display', {
    is: (display: EDisplay) =>
      display === EDisplay.LoggedOut || display === EDisplay.Both,
    then: yup.array().notRequired(),
    otherwise: yup
      .array()
      .min(1, 'Location is required')
      .required('Location is required'),
  }),
  punter_category: yup.array().when('display', {
    is: (display: EDisplay) =>
      display === EDisplay.LoggedOut || display === EDisplay.Both,
    then: yup.array().notRequired(),
    otherwise: yup
      .array()
      .min(1, 'Punter Category is required')
      .required('Punter Category is required'),
  }),
  display: yup.string().required('Display is required'),
  status: yup.string().required('Status is required'),
  start_date: yup.string().when('status', {
    is: (status: string) => status === 'Active' || status === 'Draft',
    then: yup.string().notRequired(),
    otherwise: yup.string().required('Start Date is required'),
  }),
  end_date: yup.string().when('status', {
    is: (status: string) => status === 'Active' || status === 'Draft',
    then: yup.string().notRequired(),
    otherwise: yup.string().required('End Date is required'),
  }),
  button_text: yup.string().required('Button text is required'),
  button_link: yup.string().required('Button link is required'),
  description: yup.string().required('Description is required'),
  terms_and_conditions: yup
    .string()
    .required('Terms and Conditions are required'),
});

export type validationSchema = yup.InferType<typeof validationSchema>;

export default validationSchema;
