/* eslint-disable camelcase */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../lib/api/api';
import { setAllSports } from './slices';

import { AppThunkDispatch } from '../../../../app/types';

import { TSport } from './types';
import api from '@/api/api';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const getAllSports = createAsyncThunk<
  TSport[] | undefined,
  undefined,
  { dispatch: AppThunkDispatch }
>('sports/getAllSports', async (_, thunkAPI): Promise<TSport[] | undefined> => {
  try {
    const sports = await apiGetRequest<TSport[]>(
      `${getPrefixPath(isWincore)}/trade-manager/by-sport/sport-list`
    );
    thunkAPI.dispatch(setAllSports(sports));
    return sports;
  } catch (error) {
    return undefined;
  }
});

const sportListURL = `${getPrefixPath(
  isWincore
)}/trade-manager/by-sport/sport-list`;
export const querySportList = () =>
  api.get<TSport[]>(sportListURL).then((res) => res.data);
