import { chakra, Flex, Text, Icon, Badge, Image } from '@chakra-ui/react';

export const UserLinks = chakra(Flex, {
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    mr: '0',
    p: '1',
  }),
});

type THeaderWrapperStyle = {
  isDashboard?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & any;

export const HeaderWrapper = chakra<THeaderWrapperStyle>(Flex, {
  shouldForwardProp: (prop) => !['isDashboard'].includes(prop),
  baseStyle: ({ isDashboard }: THeaderWrapperStyle) => ({
    bg: isDashboard ? 'transparent' : 'white',
    color: isDashboard && 'white',
    flex: '1',
    pl: '2',
    pr: '4',
    height: '50px',
    alignItems: 'center',
    border: !isDashboard && '1px',
    borderColor: 'gray.200',
    borderRadius: 'sm',
    mx: '2',
    my: !isDashboard && '2',
  }),
});

export const BrandBadge = chakra(Badge, {
  baseStyle: () => ({
    bg: 'origin.50',
    color: 'origin.500',
    borderRadius: 'md',
    px: '5',
    py: '.5',
    mx: '2',
    boxShadow: 'xl',
  }),
});

export const UserProfileButton = chakra(Flex, {
  baseStyle: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'md',
    bg: 'origin.500',
    color: 'white',
    fontSize: 'lg',
    fontWeight: 'normal',
    ml: '3',
  }),
});

export const HeaderIcon = chakra(Icon, {
  baseStyle: () => ({
    w: '10',
    h: '10',
    ml: '.5',
    p: '2',
    transition: '.3s ease',
    strokeWidth: '1.5',
    color: 'gray.500',
    borderRadius: 'full',

    '&:hover': {
      cursor: 'pointer',
      bg: 'gray.100',
      color: 'gray.900',
    },
  }),
});

export const LogoutIcon = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '10',
    ml: '.5',
    transition: '.3s ease',
    color: 'gray.400',
    borderRadius: 'full',
    p: '1',

    '&:hover': {
      cursor: 'pointer',
      bg: 'gray.100',
      color: 'gray.700',
    },
  }),
});

export const HeaderText = chakra(Text, {
  baseStyle: () => ({
    fontWeight: 'normal',
    fontSize: 'sm',
    lineHeight: 'short',
    color: 'gray.500',
  }),
});

export const ImageBrand = chakra(Image, {
  baseStyle: () => ({
    boxSize: '9',
    ml: '2',
    borderRadius: 'md',
    boxShadow: 'md',
  }),
});
