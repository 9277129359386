import {
  EResult,
  TSettlePropositions,
} from '@/api/tradeManager/sportDetails/settleSportMarketPropositions/settleSportMarketPropositions.types';

export type TSettleMarketProps = {
  proposition_id: string;
  result: EResult;
}[];

export type TSettleFormValues = {
  settle_option: ESettleOptions;
  expected_winners: string;
  no_win: boolean;
  propositions: TSettlePropositions[];
};

export enum ESettleOptions {
  Winter = 'Winter',
  Manual = 'Manual',
}
