import { MARGIN_TICK_MAP } from '../../../../../lib/Constants';
import { TProposition, EGeneralStatus } from '../../../../../lib/DBModels';

export const addPropositionModifier = (marketPropositions: TProposition[]) =>
  marketPropositions.map((marketProposition) => {
    if (
      marketProposition?.return_amount === undefined ||
      !marketProposition?.proposition_id ||
      marketProposition?.proposition_modifier?.display_modifier === undefined
    )
      return {
        ...marketProposition,
        proposition_modifier: {
          ...marketProposition.proposition_modifier,
          return_amount: 0,
          update_value: 0,
          original_tick_index: 0,
        },
      };

    const originalTickIndex = MARGIN_TICK_MAP.findIndex(
      (tick) => tick.odds === marketProposition.return_amount
    );

    let returnAmount = marketProposition.return_amount;

    if (
      marketProposition?.proposition_modifier?.display_modifier &&
      originalTickIndex > -1 &&
      originalTickIndex +
        marketProposition.proposition_modifier.display_modifier >
        -1
    ) {
      returnAmount =
        MARGIN_TICK_MAP[
          originalTickIndex +
            marketProposition.proposition_modifier.display_modifier
        ]?.odds;
    }

    return {
      ...marketProposition,
      return_amount: returnAmount ?? 0,
      proposition_modifier: {
        ...marketProposition.proposition_modifier,
        update_value: returnAmount ?? 0,
        original_tick_index: originalTickIndex,
      },
    };
  });

export const getMarketUpdateValue = (
  marketPropositionsWithModifier: TProposition[]
) =>
  marketPropositionsWithModifier
    .map(({ proposition_modifier, status }) => {
      if (
        !proposition_modifier ||
        (status &&
          [
            EGeneralStatus.Abandoned,
            EGeneralStatus.Scratched,
            EGeneralStatus.Voided,
            EGeneralStatus.Closed,
            EGeneralStatus.Removed,
          ].includes(status))
      )
        return 0;

      if (
        proposition_modifier?.original_tick_index === undefined ||
        proposition_modifier?.display_modifier === undefined
      )
        return 0;

      const newTickIndex =
        proposition_modifier.original_tick_index +
        proposition_modifier.display_modifier;

      if (newTickIndex < 0) return 1 / MARGIN_TICK_MAP[0].odds;
      if (newTickIndex >= MARGIN_TICK_MAP.length)
        return 1 / MARGIN_TICK_MAP[MARGIN_TICK_MAP.length - 1].odds;
      return 1 / MARGIN_TICK_MAP[newTickIndex].odds;
    })
    .reduce((a, b) => a + b, 0) * 100;

export const getEGeneralStatus = (status: string) => {
  if (status === EGeneralStatus.Open) return EGeneralStatus['Open'];
  if (status === EGeneralStatus.Closed) return EGeneralStatus['Closed'];
  if (status === EGeneralStatus.Voided) return EGeneralStatus['Voided'];
  if (status === EGeneralStatus.Removed) return EGeneralStatus['Removed'];

  return EGeneralStatus['Open'];
};
