import { apiGetRequest } from '../../../../../../../lib/api/api';
import { TRace } from '../../../../../../../lib/DBModels';
import { TEvent } from '../../../types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const getNextToJumpRaces = async (raceType: string): Promise<TRace[]> =>
  apiGetRequest<TRace[]>(
    `/${getPrefixPath(
      isWincore
    )}/trade-manager/next-to-jump/races?raceType=${raceType}`
  );

export const getNextToJumpEvents = async (
  eventType: string,
  nextStart?: string
): Promise<TEvent[]> => {
  let URL = '';
  if (eventType === 'All' || !eventType) {
    URL = nextStart
      ? `/${getPrefixPath(
          isWincore
        )}/trade-manager/next-to-jump?next_start_time=${nextStart}`
      : `/${getPrefixPath(isWincore)}/trade-manager/next-to-jump`;
  } else {
    URL = nextStart
      ? `/${getPrefixPath(
          isWincore
        )}/trade-manager/next-to-jump?event_type=${eventType}&next_start_time=${nextStart}`
      : `/${getPrefixPath(
          isWincore
        )}/trade-manager/next-to-jump?event_type=${eventType}`;
  }
  const response = apiGetRequest<TEvent[]>(URL);

  return response;
};
