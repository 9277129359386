import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { keys } from '@/api/api.keys';
import { queryStickyNote, mutateStickyNote } from '@/api/punters/notes/notes';
import { CheckAll } from '@styled-icons/bootstrap/CheckAll';
import { Cancel } from '@styled-icons/material/Cancel';
import { Edit } from '@styled-icons/evaicons-solid/Edit';
import {
  ButtonGroup,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  useEditableControls,
  Icon,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { NoteInput, EditableWrapper } from './styles/StickyNote.styles';

export default function StickyNote() {
  const client = useQueryClient();
  const { punterId } = useParams();

  const [addNote, setAddNote] = useState(false);

  // Create a ref using the useRef hook
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { data, isLoading } = useQuery([keys.stickyNote, punterId], () =>
    queryStickyNote({ punter_id: punterId ?? '' })
  );

  const { mutate } = useMutation(mutateStickyNote);

  const handleGetValue = () => {
    const inputValue = inputRef.current?.value;
    mutate(
      {
        punter_id: punterId ?? '',
        text: inputValue ?? '',
      },
      {
        onSuccess: async () => {
          await client.invalidateQueries([keys.stickyNote]);
        },
      }
    );
  };

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="Save note"
          colorScheme="green"
          h="full"
          w="10"
          onClick={handleGetValue}
          icon={<Icon as={CheckAll} boxSize={5} onClick={handleGetValue} />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancel"
          colorScheme="red"
          h="full"
          w="10"
          icon={<Icon as={Cancel} boxSize={5} />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          aria-label="Edit note"
          colorScheme="yellow"
          h="full"
          w="10"
          size="sm"
          icon={<Icon as={Edit} color="yellow.800" boxSize={5} />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  if (isLoading) return <></>;

  const hasText = !!data?.text;

  if (!hasText && !addNote)
    return (
      <Button
        onClick={() => setAddNote(true)}
        mb="2"
        bg="origin.500"
        textTransform="uppercase"
        fontSize="2xs"
        fontWeight="bold"
        _hover={{ bg: 'origin.500' }}
        _active={{ bg: 'origin.500' }}
      >
        Add Note
      </Button>
    );

  return (
    <Tooltip
      bg="wheat"
      color="gray.600"
      hasArrow
      label={`Last updated by ${data?.updated_by} on ${dayjs(
        data?.updated_at
      ).format('DD MMM YYYY hh:mm:ss')}`}
      isDisabled={!data?.text}
    >
      <EditableWrapper
        textAlign="center"
        defaultValue={!!data?.text ? data?.text : 'Add note'}
        fontSize="md"
        isPreviewFocusable={false}
        bg={data?.text ? 'wheat' : 'transparent'}
        mb="2"
      >
        <EditablePreview />
        {/* Here is the custom input */}
        <NoteInput
          as={EditableInput}
          ref={inputRef}
          border="none"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleGetValue();
            }
          }}
        />
        <EditableControls />
      </EditableWrapper>
    </Tooltip>
  );
}
