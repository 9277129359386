import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import { centsToDollars } from '@/common/utils';
import BetStatusBadge from '@/common/components/BetStatusBadge';
import { TRunner } from '@/api/tradeManager/mysteryBet/mysteryBet.types';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';

const POSITION_MAP: Record<string, string> = {
  Top1: 'Win',
  Top2: 'Top 2',
  Top3: 'Top 3',
  Top4: 'Top 4',
};

export const useMysteryBetBetsColumns = () => {
  const columns = useMemo<ColumnDef<TRunner>[]>(
    () => [
      {
        accessorKey: 'number',
        header: 'Name',
        cell: ({ row }) => {
          const {
            original: { barrier_number, number, display_name },
          } = row;
          return (
            <Text color="brand.500">
              {number}. {display_name} {barrier_number && `(${barrier_number})`}
            </Text>
          );
        },
      },
      {
        accessorKey: 'mystery_bet_bets',
        header: 'Bets',
        enableSorting: false,
        cell: ({ row }) => {
          const {
            original: { mystery_bet_bets },
          } = row;
          return <Flex flexDirection="column">{mystery_bet_bets}</Flex>;
        },
      },
      {
        header: 'Avg Stake',
        cell: ({ row }) => {
          const {
            original: { mystery_bet_hold, mystery_bet_bets },
          } = row;

          if (!mystery_bet_hold || !mystery_bet_bets) return 'N/A';

          return (
            <Box>{centsToDollars(mystery_bet_hold / mystery_bet_bets)}</Box>
          );
        },
      },
      {
        header: 'Stake Aggregate',
        cell: ({ row }) => {
          const {
            original: { mystery_bet_hold },
          } = row;
          return <Box>{centsToDollars(mystery_bet_hold)}</Box>;
        },
      },
      {
        header: 'Exposure',
        cell: ({ row }) => {
          const {
            original: { mystery_bet_liability },
          } = row;
          return <Box>{centsToDollars(mystery_bet_liability)}</Box>;
        },
      },
    ],
    []
  );

  const punterColumns = useMemo<ColumnDef<TMarketBet>[]>(
    () => [
      {
        header: 'Punter',
        cell: ({ row: { original } }) => {
          return <>{`${original.bet_punter_name}`}</>;
        },
      },
      {
        header: 'Bet Type',
        cell: ({
          row: {
            original: { proposition, price_type },
          },
        }) => {
          return (
            <>
              {`${POSITION_MAP[proposition as string]} - `}
              <Badge colorScheme="blackAlpha">
                {price_type?.replace('_', ' ')}
              </Badge>
            </>
          );
        },
      },
      {
        header: 'Status',
        cell: ({ row: { original } }) => (
          <BetStatusBadge status={original.bet_status} />
        ),
      },
      {
        header: 'Odds',
        cell: ({
          row: {
            original: { bet_odds },
          },
        }) => <>@{bet_odds?.toFixed(2)}</>,
      },
      {
        header: 'Stake',
        cell: ({
          row: {
            original: { bet_stake },
          },
        }) => <>{centsToDollars(bet_stake)}</>,
      },
      {
        header: 'Return',
        cell: ({
          row: {
            original: { potential_return },
          },
        }) => <>{centsToDollars(potential_return)}</>,
      },
    ],
    []
  );

  return { columns, punterColumns };
};
