import styled from 'styled-components';

interface ToggleContainerProps {
  active?: boolean;
}

export const ToggleLabel = styled.label<ToggleContainerProps>`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

export const ToggleSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9e9e9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSpan} {
    background-color: #99da00;
  }

  &:focus + ${ToggleSpan} {
    box-shadow: 0 0 1px #99da00;
  }

  &:checked + ${ToggleSpan}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;
