import { TPunterWithdrawalRequestsPagedHistory } from '@/lib/DTO';
import api from '../../api';
import { TWithdrawalParams } from '../withdrawals.types';
import { TWithdrawalBody } from './status.types';

// Withdrawal - Status

export const withdrawalsStatusURL = '/bookie/bank/withdrawals/status';

export const queryWithdrawalsStatus = (params: TWithdrawalParams) =>
  api
    .get<TPunterWithdrawalRequestsPagedHistory>(withdrawalsStatusURL, {
      params,
    })
    .then((res) => res.data);

export const mutateWithdrawalsStatus = (body: TWithdrawalBody) =>
  api.patch(withdrawalsStatusURL, body).then((res) => res.data);
