import { TRaceDetails } from '../../../lib/DBModels';
import api from '../../api';
import { TRaceDetailsParams } from './raceDetails.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const raceDetailsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details`;

// Risk Manager - Get Race Details
export const queryRaceDetails = (params: TRaceDetailsParams) =>
  api.get<TRaceDetails>(raceDetailsURL, { params }).then((res) => res.data);
