type TFilterOptions = {
  value: string;
  label: string;
};

export const filterOptions: TFilterOptions[] = [
  { value: 'all', label: 'All' },
  { value: 'new', label: 'New' },
  { value: 'exported', label: 'Exported' },
];
