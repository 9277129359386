import api from '../../../api';
import { TSettleSportMarketPropositionsBody } from './settleSportMarketPropositions.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const settleSportMarketPropositionsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/settle-sport-market-propositions`;

// Post: settle sport market propositions
export const mutateSettleSportMarketPropositions = (
  body: TSettleSportMarketPropositionsBody
) =>
  api
    .post<TSettleSportMarketPropositionsBody>(
      settleSportMarketPropositionsURL,
      body
    )
    .then((res) => res.data);
