import toast from 'react-hot-toast';
import { axios } from '../../app/components/Providers/Axios';
import { logError } from '../../common/utils';
import { HTTPError, ToastMessageOptions } from './types';

export const buildEndpointQueryString = (params?: {
  [key: string]: unknown | undefined;
}) => {
  let requestUrl = '';
  if (params && Object.keys(params).length > 0) {
    const queryString = Object.keys(params)
      .map((key) => {
        if (params[key] === undefined) {
          return '';
        }
        return `${key}=${params[key]}`;
      })
      .filter((param) => param !== '')
      .join('&');
    requestUrl += `?${queryString}`;
  }
  return requestUrl;
};

export const apiGetRequest = async <T>(
  url: string,
  params?: { [key: string]: unknown | undefined },
  messageOptions?: ToastMessageOptions
): Promise<T> => {
  try {
    const requestUrl = `${url}${buildEndpointQueryString(params)}`;
    const response = await axios.get<T>(requestUrl);
    return response.data;
  } catch (error) {
    const e = error as HTTPError;
    if (messageOptions?.failMessage)
      toast.error(
        `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );

    logError(error);
    throw error;
  }
};

export const apiPostRequest = async <T>(
  url: string,
  data: unknown,
  messageOptions?: ToastMessageOptions
): Promise<T> => {
  try {
    const response = await axios.post<T>(url, data);

    if (messageOptions?.successMessage)
      toast.success(messageOptions.successMessage);

    return response.data;
  } catch (error) {
    const e = error as HTTPError;

    if (messageOptions?.failMessage)
      toast.error(
        `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );

    logError(error);
    throw error;
  }
};

export const apiPatchRequest = async <T>(
  url: string,
  data: unknown,
  messageOptions?: ToastMessageOptions
): Promise<T> => {
  try {
    const response = await axios.patch<T>(url, data);

    if (messageOptions?.successMessage)
      toast.success(messageOptions.successMessage);

    return response.data;
  } catch (error) {
    const e = error as HTTPError;

    if (messageOptions?.failMessage)
      toast.error(
        `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );

    logError(error);
    throw error;
  }
};

export const apiDeleteRequest = async <T>(
  url: string,
  params?: { [key: string]: unknown | undefined },
  messageOptions?: ToastMessageOptions
): Promise<T> => {
  try {
    const requestUrl = `${url}${buildEndpointQueryString(params)}`;
    const response = await axios.delete<T>(requestUrl);

    if (messageOptions?.successMessage)
      toast.success(messageOptions.successMessage);

    return response.data;
  } catch (error) {
    const e = error as HTTPError;

    if (messageOptions?.failMessage)
      toast.error(
        `${messageOptions.failMessage}: ${e.response.data.detail.user_msg}`
      );

    logError(error);
    throw error;
  }
};
