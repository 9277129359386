import React from 'react';
import { PaintBrush } from '@/assets/icons';
import { StatusLight } from '@/common/components/StatusLight/StatusLight';
import { EGeneralStatus } from '@/lib/DBModels';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { StartTimeCalculator } from '@/common/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { centsToDollars } from '@/common/utils';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { setCustomMarketModal } from '../../../slices';
import { ECustomMarketModalMode } from '../../../types';
import { useAppDispatch } from '@/common/hooks/useRedux';
import { useQueryGetMarket } from '@/api/tradeManager/marketCreation/getMarket/getMarket.hooks';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import { TMarket } from '@/api/tradeManager/marketCreation/getMarket/getMarket.types';
import { TOptionType } from '@/common/components/FormElements/SelectAdvanced/SelectAdvanced';
import * as Yup from 'yup';
import { ButtonRow } from '../CustomMarkets.styles';

export const useCustomMarkets = () => {
  const [offset, setOffset] = useState(0);

  const limit = 20;

  const { data, isLoading } = useQueryGetMarket({
    params: {
      is_manual: true,
      limit,
      offset,
    },
  });

  const isLastFetch = (data?.length ?? 0) < limit;

  return {
    data,
    isLoading,
    isLastFetch,
    offset,
    setOffset,
  };
};

export const useColumns = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToDetails = (matchID?: string) => {
    if (!matchID) return;

    const detailsState = {
      from: location,
    };

    navigate({
      pathname: '/trade-manager/match-details',
      search: `?id=${matchID}`,
      state: detailsState,
    } as unknown as Path);
  };

  const columns = [
    {
      accessorKey: 'match_name',
      header: intl.formatMessage({
        id: 'trademanagerpage.custommarkets.name',
      }),
      omit: true,
      sortable: false,
      cell: (props: {
        row: {
          original: TMarket;
        };
        getValue: () => string | undefined;
      }) => (
        <ButtonRow
          onClick={() => goToDetails(props.row.original?.match?.match_id)}
        >
          <StatusLight
            status={props.row.original?.match?.status as EGeneralStatus}
            size="md"
          />
          <Box boxSize="8" mx="5">
            <PaintBrush />
          </Box>
          <Flex alignItems="start" flexDirection="column" mt="2px">
            <Text ml="1" color="gray.700">
              {props.row.original?.match?.name ?? ''}
            </Text>

            {!!props.row.original?.status &&
              !!props.row.original?.match?.start_time && (
                <StartTimeCalculator
                  status={props.row.original?.status as EGeneralStatus}
                  date={props.row.original?.match?.start_time}
                  sx={{ mt: '2' }}
                />
              )}
          </Flex>
        </ButtonRow>
      ),
    },
    {
      accessorKey: 'name',
      header: intl.formatMessage({
        id: 'trademanagerpage.custommarkets.marketname',
      }),
      cell: (props: { getValue: () => string | undefined }) => (
        <Text>{props.getValue()}</Text>
      ),
    },
    {
      accessorKey: 'max_stake',
      header: intl.formatMessage({
        id: 'trademanagerpage.custommarkets.maxstake',
      }),
      cell: (props: {
        row: {
          original: TMarket;
        };
        getValue: () => string | undefined;
      }) => (
        <Text size="sm">
          {centsToDollars(
            props.row.original?.propositions?.[0]?.max_stake_limit
          )}
        </Text>
      ),
    },
    {
      accessorKey: 'multis_allowed',
      header: intl.formatMessage({
        id: 'trademanagerpage.custommarkets.multiallowed',
      }),
      cell: (props: {
        row: {
          original: TMarket;
        };
        getValue: () => string | undefined;
      }) => (
        <Text size="sm">
          <FormattedMessage
            id={`generic.${
              props.row.original?.propositions?.[0]?.allow_multi ? 'yes' : 'no'
            }`}
          />
        </Text>
      ),
    },
    {
      accessorKey: 'edit',
      header: '',
      cell: (props: {
        row: {
          original: TMarket;
        };
      }) => (
        <Button
          variant="ghost"
          onClick={() =>
            dispatch(
              setCustomMarketModal({
                isOpen: true,
                mode: ECustomMarketModalMode.Edit,
                market_id: props.row.original.market_id,
                market_name: props.row.original.name,
              })
            )
          }
        >
          <Icon as={Edit} boxSize="6" />
        </Button>
      ),
    },
  ];

  return columns;
};

export const useValidation = () => {
  const intl = useIntl();

  const customMarketPropositionSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.namerequired',
      })
    ),
  });

  const customMarketsSchema = Yup.object().shape({
    code: Yup.mixed<TOptionType>().test(
      'is-selected',
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.sportrequired',
      }),
      (value) => {
        return !!value && !!(value as TOptionType).value;
      }
    ),
    competition: Yup.mixed<TOptionType>().test(
      'is-selected',
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.competitionrequired',
      }),
      (value) => {
        return !!value && !!(value as TOptionType).value;
      }
    ),
    match: Yup.string().required(
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.matchrequired',
      })
    ),
    date: Yup.date().required(
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.daterequired',
      })
    ),
    time: Yup.date().test(
      'future-time-required',
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.futuretimeisrequired',
      }),
      function (value) {
        if (this.parent.date.toDateString() === new Date().toDateString()) {
          return !!value && value?.getTime() > new Date().getTime();
        }
        return !!value;
      }
    ),
    market_type: Yup.string().required(
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.markettyperequired',
      })
    ),
    market_group_name: Yup.string().required(
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.marketgroupnamerequired',
      })
    ),
    market_name: Yup.string().required(
      intl.formatMessage({
        id: 'trademanagerpage.custommarkets.error.marketnamerequired',
      })
    ),
    max_bet: Yup.number()
      .nullable()
      .typeError(
        intl.formatMessage({
          id: 'trademanagerpage.custommarkets.error.maxstakenumber',
        })
      )
      .min(
        0,
        intl.formatMessage({
          id: 'trademanagerpage.custommarkets.error.maxstakenotnegative',
        })
      ),
    propositions: Yup.array()
      .min(
        1,
        intl.formatMessage({
          id: 'trademanagerpage.custommarkets.error.minoneprop',
        })
      )
      .of(customMarketPropositionSchema),
  });

  return { customMarketsSchema };
};
