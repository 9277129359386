import { Button, Icon } from '@chakra-ui/react';
import { DocumentDownload } from '@styled-icons/heroicons-solid/DocumentDownload';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDownloadCSV } from './DownloadCSVButton.hooks';
import { DownloadCSVButtonProps } from './DownloadCSVButton.types';

export default function DownloadCSVButton(props: DownloadCSVButtonProps) {
  const { promoId, filename } = props;
  const { isLoading, downloadCSV } = useDownloadCSV({ promoId, filename });

  return (
    <Button
      colorScheme="gray"
      isLoading={isLoading}
      leftIcon={<Icon as={DocumentDownload} />}
      size="sm"
      onClick={downloadCSV}
    >
      <FormattedMessage id="promoManagerPage.downloadButton" />
    </Button>
  );
}
