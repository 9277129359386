import React from 'react';
import {
  Flex,
  Text,
  Icon,
  Box,
  Divider,
  Checkbox,
  ScaleFade,
} from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/fa-solid/ChevronDown';
import { Plus } from '@styled-icons/fa-solid/Plus';
import {
  ButtonAddMore,
  ButtonUpdate,
  FlexForm,
  IconButtonDeleteProp,
  LabelStyle,
  Select,
  selectStyles,
  TextLabel,
  ErrorMsgContainer,
} from '../CustomMarketModal.styles';
import { useCustomMarketForm } from '../services/CustomMarketModal.hooks';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { TCustomMarketsFormValues } from '../../../pages/TradeManager/tabs/CustomMarkets/Custommarket.types';
import Timepicker from '@/common/components/FormElements/Timepicker/Timepicker';
import Datepicker from '@/common/components/FormElements/Datepicker/Datepicker';
import Input from '@/common/components/FormElements/Input';
import { ECustomMarketModalMode } from '@/features/tradeManager/pages/TradeManager/types';
import { Delete } from '@styled-icons/typicons/Delete';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const Form = () => {
  const context = useFormikContext<TCustomMarketsFormValues>();

  const {
    isLoading,
    modalMode,
    intl,
    theme,
    racingFutures,
    sportsFutures,
    sportOptions,
    competitionOptions,
    sportsLoading,
    competitionsLoading,
    initialPropositions,
    onChangeSport,
    onChangeCompetition,
    addProposition,
    removeProposition,
    setPropositionsTouched,
  } = useCustomMarketForm(context);

  const status = ['Open', 'Closed'].map((value) => ({
    value,
    label: value,
  }));

  return (
    <FlexForm>
      <Select
        name="code"
        value={context?.values.code}
        isDisabled={modalMode === ECustomMarketModalMode.Edit}
        isLoading={sportsLoading || isLoading}
        options={sportOptions}
        placeholder={
          <Text>
            <FormattedMessage id="trademanagerpage.custommarkets.modal.codeplaceholder" />
          </Text>
        }
        onChange={onChangeSport}
        styles={selectStyles(theme)}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.code',
        })}
        propsLabel={LabelStyle}
        noOptionsMessage={() => null}
        isSearchable={false}
      />

      <Select
        name="competition"
        value={context?.values.competition}
        isDisabled={
          !context?.values.code ||
          modalMode === ECustomMarketModalMode.Edit ||
          context?.values.code.label === racingFutures
        } // Disabled when no sport is selected or when edit or when sport is racing futures
        isLoading={competitionsLoading || isLoading}
        options={competitionOptions}
        placeholder={
          <Text>
            <FormattedMessage id="trademanagerpage.custommarkets.modal.competitionplaceholder" />
          </Text>
        }
        onChange={onChangeCompetition}
        styles={selectStyles(theme)}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.competition',
        })}
        propsLabel={LabelStyle}
        noOptionsMessage={() => null}
      />

      <Input
        name="match"
        placeholder={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.matchplaceholder',
        })}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.match',
        })}
        propsLabel={LabelStyle}
        fontSize="sm"
      />

      <Input
        name="market_group_name"
        placeholder={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.marketgroupnameplaceholder',
        })}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.marketgroupname',
        })}
        propsLabel={LabelStyle}
        fontSize="sm"
      />

      <Input
        name="market_name"
        placeholder={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.marketnameplaceholder',
        })}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.market',
        })}
        propsLabel={LabelStyle}
        fontSize="sm"
      />

      <Input
        name="market_type"
        placeholder={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.markettypeplaceholder',
        })}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.markettype',
        })}
        propsLabel={LabelStyle}
        fontSize="sm"
      />

      <TextLabel>
        <FormattedMessage id="trademanagerpage.custommarkets.modal.starttime" />
      </TextLabel>
      <Flex>
        <Datepicker
          name="date"
          value={
            context?.values.date ? new Date(context?.values.date) : new Date()
          }
          minDate={new Date()}
          onChange={(date) => {
            context?.setFieldValue('date', date);
          }}
          clearIcon={null}
          calendarIcon={<Icon as={ChevronDown} />}
          wrapperProps={{
            h: '10',
            mr: '2',
            fontSize: 'sm',
          }}
        />
        <Box w="24">
          <Timepicker
            disableClock
            name="time"
            value={context?.values.time ?? new Date()}
            onChange={(time) => {
              if (!time) {
                context?.setFieldValue('time', new Date());
              } else {
                const timeObj = dayjs(time, 'HH:mm');
                const currentDate = dayjs();
                const updatedDate = currentDate
                  .set('hour', timeObj.hour())
                  .set('minute', timeObj.minute());

                context?.setFieldValue('time', updatedDate.toDate());
              }
            }}
            clearIcon={null}
            wrapperProps={{ h: '10', fontSize: 'sm' }}
          />
          {!!context?.errors.time && (
            <ErrorMsgContainer>
              <FormattedMessage id="trademanagerpage.custommarkets.error.futuretimeisrequired" />
            </ErrorMsgContainer>
          )}
        </Box>
      </Flex>

      <Select
        name="status"
        value={status.find(
          (a) => a.value === (context.values.status ?? 'Closed')
        )}
        options={status}
        onChange={(e) => context?.setFieldValue('status', e?.value)}
        styles={selectStyles(theme)}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.status',
        })}
        propsLabel={LabelStyle}
        noOptionsMessage={() => null}
      />

      <Input
        name="max_bet"
        InputLeftAddon={intl.formatMessage({
          id: 'generic.dollarsign',
        })}
        placeholder={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.maxbetplaceholder',
        })}
        label={intl.formatMessage({
          id: 'trademanagerpage.custommarkets.modal.maxbet',
        })}
        propsLabel={LabelStyle}
        fontSize="sm"
      />
      <Checkbox
        maxW="28"
        mt="4"
        isDisabled={context?.values.code?.label === sportsFutures}
        isChecked={context?.values.allow_multis}
        onChange={(e) =>
          context?.setFieldValue('allow_multis', e.target.checked)
        }
      >
        <Text fontSize="sm">
          <FormattedMessage id="trademanagerpage.custommarkets.modal.allowmultis" />
        </Text>
      </Checkbox>

      <Divider mt="4" />
      <TextLabel>
        <FormattedMessage id="trademanagerpage.custommarkets.modal.propositions" />
      </TextLabel>

      {context?.values.propositions.map((p, i) => (
        <ScaleFade key={i} in={true}>
          <Flex alignItems="center" mt="2" position="relative" gap="8px">
            {i >= initialPropositions && (
              <IconButtonDeleteProp
                variant="ghost"
                size="xs"
                aria-label={intl.formatMessage({
                  id: 'trademanagerpage.custommarkets.modal.removeprop',
                })}
                icon={<Icon as={Delete} boxSize="6" />}
                onClick={() => {
                  removeProposition(i);
                }}
              />
            )}

            <Input
              onBlur={() => {
                // set positions context?.touched when 2 or more inputs have been context?.touched
                setPropositionsTouched((prev) => {
                  const touchedCount = prev + 1;
                  context?.setFieldTouched('propositions', touchedCount >= 2);
                  return touchedCount;
                });
              }}
              name={`propositions[${i}].name`}
              value={p.name}
              placeholder={intl.formatMessage({
                id: 'trademanagerpage.custommarkets.modal.propositionsplaceholder',
              })}
              onChange={(e) =>
                context?.setFieldValue(
                  `propositions[${i}].name`,
                  e.target.value
                )
              }
              fontSize="sm"
            />
            <Box w="28">
              <Input
                max={1000}
                min={1.01}
                step="any"
                type="number"
                name={`propositions[${i}].odds`}
                value={p.odds}
                placeholder={intl.formatMessage({
                  id: 'trademanagerpage.custommarkets.modal.propositionsoddsplaceholder',
                })}
                onChange={(e) =>
                  context?.setFieldValue(
                    `propositions[${i}].odds`,
                    e.target.value
                  )
                }
                fontSize="sm"
              />
            </Box>
          </Flex>
        </ScaleFade>
      ))}

      <ButtonAddMore
        variant="ghost"
        leftIcon={<Icon as={Plus} />}
        onClick={() => {
          addProposition(context?.values.propositions.length ?? 0);
        }}
      >
        <FormattedMessage id="trademanagerpage.custommarkets.modal.addmore" />
      </ButtonAddMore>

      <ButtonUpdate type="submit" isLoading={context?.isSubmitting}>
        <FormattedMessage
          id={
            modalMode === ECustomMarketModalMode.Create
              ? 'trademanagerpage.custommarkets.modal.createmarket'
              : 'trademanagerpage.custommarkets.modal.update'
          }
        />
      </ButtonUpdate>
    </FlexForm>
  );
};

export default Form;
