import api from '@/api/api';
import { TMarketBet } from '../../trademanager.types';
import { TBetsForExoticsParams } from './betsForExotics.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const betsForExoticsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/bets-for-exotics`;

// Bets For Exotics
export const queryBetsForExotics = (params: TBetsForExoticsParams) =>
  api.get<TMarketBet[]>(betsForExoticsURL, { params }).then((res) => res.data);

export const betsForToteMulti = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/bets-for-tote-multi`;

// Bets For Multiples
export const queryBetsForToteMulti = (params: TBetsForExoticsParams) =>
  api.get<TMarketBet[]>(betsForToteMulti, { params }).then((res) => res.data);
