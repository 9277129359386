import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { keys } from '@/api/api.keys';
import { TBetCloudErrorResponse } from '@/api/api.types';
import {
  mutateBestToteOfferings,
  mutateOfferings,
  mutateSportCashOutOfferings,
  queryOfferings,
} from './offerings';
import {
  TMutateBestToteOfferingsBody,
  TMutateCashOutOfferingsBody,
  TMutateOfferingsBody,
  TMutateOfferingsResponse,
  TQueryOfferingsResponse,
} from './offerings.types';

export const useMutationOfferings = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TMutateOfferingsResponse>,
      AxiosError<TBetCloudErrorResponse>,
      TMutateOfferingsBody
    >,
    'mutationFn'
  >
) => useMutation(mutateOfferings, options);

export const useQueryOfferings = (
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryOfferingsResponse>>,
    'queryKey' | 'queryFn'
  >
) => useQuery([keys.offerings], () => queryOfferings(), options);

export const useMutationBestToteOfferings = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TMutateBestToteOfferingsBody>,
      AxiosError<TBetCloudErrorResponse>,
      TMutateBestToteOfferingsBody
    >,
    'mutationFn'
  >
) => useMutation(mutateBestToteOfferings, options);

export const useMutationSportsCashOutOfferings = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<TMutateCashOutOfferingsBody>,
      AxiosError<TBetCloudErrorResponse>,
      TMutateCashOutOfferingsBody
    >,
    'mutationFn'
  >
) => useMutation(mutateSportCashOutOfferings, options);
