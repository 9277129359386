import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateUpdateCompetition } from './updateCompetition';
import { TUpdateCompetitionBody } from './updateCompetition.types';

export const useMutationUpdateCompetition = (
  options?: Omit<
    UseMutationOptions<
      TMarketCreationResponse,
      unknown,
      TUpdateCompetitionBody
    >,
    'mutationFn'
  >
) => useMutation(mutateUpdateCompetition, options);
