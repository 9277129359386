import { configureStore } from '@reduxjs/toolkit';

import navigationReducer from '../common/ui/Navigation/Navigation.slices';
import bookieReducer from '../features/settings/pages/Bookie/slices';
import settingsReducer from '../features/settings/pages/Settings/slices';
import punterReducer from '../features/punters/pages/Punter/slices';
import matchDetailsReducer from '../features/tradeManager/pages/MatchDetails/slices';
import globalMarginsReducer from '../features/tradeManager/pages/TradeManager/tabs/ManageGlobalMargins/slices';
import allSportsReducer from '../features/tradeManager/pages/TradeManager/slices';
import bySportsReducer from '../features/tradeManager/pages/TradeManager/tabs/BySport/slices';
import byRacingReducer from '../features/tradeManager/pages/TradeManager/tabs/ByRacing/slices';
import findAnEventReducer from '../features/tradeManager/pages/TradeManager/tabs/FindAnEvent/slices';
import raceDetailsReducer from '../features/tradeManager/pages/RaceDetails/Services/RaceDetails.slices';
import punterBets from '../features/punters/pages/Bets/Services/Slice.Bets';
import resultedBetsReducer from '../features/bets/pages/PlacedBets/tabs/Resulted/slices';
import upcomingBetsReducer from '../features/bets/pages/PlacedBets/tabs/Upcoming/Services/Upcoming.slice';
import bankReducer from '../features/banking/pages/Bank/slices';
import betApprovalsReducer from '../features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.slices';
import puntersReducer from '../features/punters/pages/Punters/services/Punters.slices';
import punterIncentives from '../features/punters/pages/Punter/tabs/Incentive/Services/Incentives.slice';
import { ENV } from '@/lib/Constants';

export const reducer = {
  navigation: navigationReducer,
  bookie: bookieReducer,
  settings: settingsReducer,
  punter: punterReducer,
  punters: puntersReducer,

  // TradeManager Tabs
  globalMargins: globalMarginsReducer,
  allSports: allSportsReducer,
  raceDetails: raceDetailsReducer,
  matchDetails: matchDetailsReducer,
  bySports: bySportsReducer,
  byRacing: byRacingReducer,
  findAnEvent: findAnEventReducer,

  // Punters Profile Tabs
  punterBets,
  punterIncentives,

  // Bets Tabs
  resultedBets: resultedBetsReducer,
  upcomingBets: upcomingBetsReducer,
  betApprovals: betApprovalsReducer,

  // Bank Tabs
  bank: bankReducer,
};

const store = configureStore({
  reducer,
  devTools: ENV.NODE_ENV !== 'production',
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
