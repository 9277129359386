import api from '../../../api';
import { TSuspendPropositionBody } from './suspendProposition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const suspendPropositionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/suspend-proposition`;

// Patch: Suspend Proposition
export const mutateSuspendProposition = (body: TSuspendPropositionBody) =>
  api
    .patch<TSuspendPropositionBody>(suspendPropositionURL, body)
    .then((res) => res.data);
