import React, { useState, useEffect } from 'react';
import { Button, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
} from '@tanstack/react-table';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { PlaceHolder, PlaceHolderWrapper } from './BetsTable.styles';
import { getStrings } from '../../utils';
import { BetApprovalsEmpty } from '../../../assets/icons';
import { useAppDispatch } from '@/common/hooks/useRedux';
import { BetApprovalActions } from '@/features/betApprovals/pages/BetApprovals/tabs/components/BetApprovalActions';
import {
  setModalCounterBet,
  setModalRejectBet,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.slices';
import { CommonResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import api from '@/api/api';
import toast from 'react-hot-toast';
import { Table as BCTable } from './components/Table';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';

type TTableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  children?: React.ReactNode;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickRow?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickExpand?: any;
  isApprovalsTable?: boolean;
  hasSubTable?: boolean;
  placeHolder?: string;
  initialSort?: SortingState;
  isDraggable?: boolean;
  updateOrder?: (body: TData[]) => Promise<void>;
};

export const BetsTable = <T extends Record<string, unknown>>({
  data,
  columns,
  children,
  loading,
  onClickRow,
  onClickExpand,
  isApprovalsTable,
  hasSubTable,
  placeHolder,
  initialSort,
  isDraggable = false,
  updateOrder,
}: TTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>(initialSort ?? []);

  const [draggedOrder, setDraggedOrder] = useState<T[]>(data);
  const [displaySaveOrderButtons, setDisplaySaveOrderButtons] = useState(false);

  useEffect(() => {
    if (!draggedOrder.length && data.length) {
      setDraggedOrder(data);
    }
  }, [data, draggedOrder.length]);

  useEffect(() => {
    setDraggedOrder(data);
    setDisplaySaveOrderButtons(false);
  }, [data]);

  const table = useReactTable({
    data: isDraggable ? draggedOrder : data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setDisplaySaveOrderButtons(true);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedData = reorder(
      draggedOrder,
      result.source.index,
      result.destination.index
    );

    setDraggedOrder(reorderedData);
  };

  const [{ PlaceBetsPage: strings }] = getStrings();

  if (loading)
    return (
      <Stack>
        {Array.from({ length: 8 }, (_, i) => (
          <Skeleton key={i} height="20" />
        ))}
      </Stack>
    );
  if (!data.length)
    return (
      <PlaceHolderWrapper justify="center" pt="50" flexDirection="column">
        {placeHolder ? (
          <>
            <BetApprovalsEmpty />
            <PlaceHolder>{placeHolder}</PlaceHolder>
          </>
        ) : (
          <Text>{strings.NoResults}</Text>
        )}
      </PlaceHolderWrapper>
    );

  return isDraggable ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <BCTable
        data={data}
        draggedOrder={draggedOrder}
        setDisplaySaveOrderButtons={setDisplaySaveOrderButtons}
        displaySaveOrderButtons={displaySaveOrderButtons}
        isApprovalsTable={isApprovalsTable}
        table={table}
        setDraggedOrder={setDraggedOrder}
        hasSubTable={hasSubTable}
        isDraggable
        onClickRow={onClickRow}
        onClickExpand={onClickExpand}
        loading={loading}
        updateOrder={updateOrder}
      >
        {children}
      </BCTable>
    </DragDropContext>
  ) : (
    <BCTable
      data={data}
      draggedOrder={draggedOrder}
      setDisplaySaveOrderButtons={setDisplaySaveOrderButtons}
      displaySaveOrderButtons={displaySaveOrderButtons}
      isApprovalsTable={isApprovalsTable}
      table={table}
      setDraggedOrder={setDraggedOrder}
      hasSubTable={hasSubTable}
      onClickRow={onClickRow}
      onClickExpand={onClickExpand}
      loading={loading}
      updateOrder={updateOrder}
    >
      {children}
    </BCTable>
  );
};

// -----

export type ActionWrapperProps = {
  data: CommonResponse;
};

type TApprovalPayload = {
  request_id: string;
  action: 'Approved';
  source_url: string;
};

type TCounterPayload = {
  request_id: string;
  action: 'Counter';
  amount: number;
  source_url: string;
};

type TRejectPayload = {
  request_id: string;
  action: 'Rejected';
  reason: string;
  source_url: string;
};

type TMblPayload = {
  request_id: string;
  action: 'AcceptMBL';
  source_url: string;
};

type ActionTypes = (
  | TApprovalPayload
  | TCounterPayload
  | TRejectPayload
  | TMblPayload
) & { type: string };

enum EClaimActions {
  RELEASE = 'RELEASE',
  CLAIM = 'CLAIM',
  OVERRIDE = 'OVERRIDE',
}

export const ActionWrapper = ({ data }: ActionWrapperProps) => {
  const {
    canClaim,
    canEdit,
    canReleaseClaim,
    canOverrideClaim,
    claimedByName,
  } = data;
  const dispatch = useAppDispatch();

  const mutate = (payload: ActionTypes) =>
    api
      .post(
        isWincore
          ? '/bookie/global-trading/bet-approvals/global-update-status'
          : '/bookie/bet-approvals/status',
        payload
      )
      .then(() => void toast.success('Success'))
      .catch((err) => console.log('err', err));

  let text = '';
  if (canReleaseClaim) text = EClaimActions.RELEASE;
  else if (canOverrideClaim && !canEdit) text = EClaimActions.OVERRIDE;
  else text = EClaimActions.CLAIM;

  const isSettlement = data.requestType?.toLowerCase() === 'settlement';

  return (
    <Flex justifyContent="end" flexDir="column" alignItems="flex-end">
      <BetApprovalActions
        disabled={!canEdit}
        bet={data}
        showCounterBetModal={() => dispatch(setModalCounterBet(data))}
        showRejectBetModal={() => dispatch(setModalRejectBet(data))}
        approveRequest={() =>
          mutate({
            action: 'Approved',
            request_id: data.requestId,
            source_url: data.sourceApiUrl,
            type: isSettlement ? 'Settlement' : 'Placement',
          })
        }
        acceptMBL={() =>
          mutate({
            action: 'AcceptMBL',
            request_id: data.requestId,
            source_url: data.sourceApiUrl,
            type: isSettlement ? 'Settlement' : 'Placement',
          })
        }
      />
      <>
        {!isSettlement && (canEdit || canOverrideClaim) && (
          <Button
            sx={
              text === EClaimActions.RELEASE
                ? { bg: '#F6AD55', '&:hover': { bg: '#DD6B20' } }
                : text === EClaimActions.CLAIM
                ? { bg: '#359eea' }
                : { bg: 'red', '&:hover': { bg: '#C53030' } }
            }
            w="104px"
            mr="12px"
            disabled={!canClaim}
            onClick={async () => {
              try {
                if (text === EClaimActions.RELEASE) {
                  await api.post(
                    isWincore
                      ? '/bookie/global-trading/bet-approvals/claims/release'
                      : '/bookie/bet-approvals/claims/release',
                    {
                      request_id: data.requestId,
                      ...(isWincore ? { source_url: data.sourceApiUrl } : {}),
                    }
                  );
                } else if (text === EClaimActions.CLAIM) {
                  await api.post(
                    isWincore
                      ? '/bookie/global-trading/bet-approvals/claims'
                      : '/bookie/bet-approvals/claims',
                    {
                      request_id: data.requestId,
                      ...(isWincore ? { source_url: data.sourceApiUrl } : {}),
                    }
                  );
                } else if (text === EClaimActions.OVERRIDE) {
                  const proceed = confirm(
                    'Are you sure you want to override the claim?'
                  );
                  if (proceed)
                    await api.post(
                      isWincore
                        ? '/bookie/global-trading/bet-approvals/claims/override'
                        : '/bookie/bet-approvals/claims/override',
                      {
                        request_id: data.requestId,
                        ...(isWincore ? { source_url: data.sourceApiUrl } : {}),
                      }
                    );
                }
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {text}
          </Button>
        )}
        {claimedByName && (
          <Text mr="12px">
            Claimed by:
            <br />
            {claimedByName}
          </Text>
        )}
      </>
    </Flex>
  );
};
