import { TBonusAwardItem } from '../../features/punters/pages/Punter/types';
import { TDeepPartial } from '../../lib/types';

export type TBulkBonusAward = TDeepPartial<{
  name: string;
  reason: EBonusAwardReason;
  total_punters: number;
  total_credited: number;
  promotion_date: string;
}>;

export enum EBonusAwardReason {
  deposit_match = 'deposit_match',
  bonus_back_for_2nd = 'bonus_back_for_2nd',
  loyalty_bonus = 'loyalty_bonus',
}

export type TCSVUploadInput = TDeepPartial<{
  name?: string;
  reason?: EBonusAwardReason;
  bonus_awards: TBonusAwardItem[];
}>;

export interface TUploadCSVModalProps {
  isAddingBulkBonusAward: boolean;
  onClose: () => void;
}

export type TBulkBonusAwardQuery = {
  applied_from: string;
  applied_to: string;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
};
