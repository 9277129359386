import {
  chakra,
  Input,
  InputProps,
  ChakraComponent,
  Editable,
  EditableProps,
} from '@chakra-ui/react';

export const EditableWrapper: ChakraComponent<'div', EditableProps> = chakra(
  Editable,
  {
    baseStyle: () => ({
      display: 'flex',
      gap: '2',
      w: 'fit-content',
      justifyContent: 'space-between',
      borderTopRadius: 'md',
      p: '2',
      transition: '.2s ease-in-out',
    }),
  }
);

export const NoteInput: ChakraComponent<'input', InputProps> = chakra(Input, {
  baseStyle: () => ({
    bg: 'white',
    _focus: { border: 'none', borderRadius: 'none', boxShadow: 'none' },
    minW: '300px',
    p: '0.5',
    w: '100%',
    textAlign: 'left',
    h: '30px',
  }),
});
