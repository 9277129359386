import api from '@/api/api';
import { TSGMPropositionsParams } from './propositions.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// SGM - Propositions
const querySGMPropositionsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sgmultis/propositions`;
export const querySGMPropositions = (params: TSGMPropositionsParams) =>
  api
    .get<Api['SGMultisPropositionStatsResponse']>(querySGMPropositionsURL, {
      params,
    })
    .then((res) => res.data);
