import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRacingCountryAndStates, TByRacingState } from './types';

import { getLocations, getVenues, getRaces } from './actions';

import { TEventsResponse } from '../../types';

import { TVenue } from '../../../../../../lib/DBModels';

const initialState: TByRacingState = {
  countries: [],
  countriesLoading: false,
  venues: [],
  venuesLoading: false,
  eventsResponse: {
    records: [],
    total_pages: 1,
    total_records: 0,
  },
  eventsLoading: false,
  query: {},
};

const racingSlice = createSlice({
  name: 'bySports',
  initialState,
  reducers: {
    setCountries: (
      state: TByRacingState,
      action: PayloadAction<TRacingCountryAndStates[]>
    ) => ({ ...state, countries: action.payload }),
    setVenues: (state: TByRacingState, action: PayloadAction<TVenue[]>) => ({
      ...state,
      venues: action.payload,
    }),
    setEventsResponse: (
      state: TByRacingState,
      action: PayloadAction<TEventsResponse>
    ) => ({ ...state, eventsResponse: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state: TByRacingState) => ({
        ...state,
        countriesLoading: true,
      }))
      .addCase(getLocations.fulfilled, (state: TByRacingState) => ({
        ...state,
        countriesLoading: false,
      }))
      .addCase(getVenues.pending, (state: TByRacingState) => ({
        ...state,
        venuesLoading: true,
      }))
      .addCase(getVenues.fulfilled, (state: TByRacingState) => ({
        ...state,
        venuesLoading: false,
      }))
      .addCase(getRaces.pending, (state: TByRacingState) => ({
        ...state,
        eventsLoading: true,
      }))
      .addCase(getRaces.fulfilled, (state: TByRacingState) => ({
        ...state,
        eventsLoading: false,
      }));
  },
});

export const { setCountries, setVenues, setEventsResponse } =
  racingSlice.actions;

export default racingSlice.reducer;
