import { BetsTable } from '@/common/components';
import React, { useState } from 'react';
import { Column } from '@tanstack/react-table';
import { TBetLegs, TBetsData } from '@/api/punters/punters.types';
import { useMultiColumns, useMultiSubColumns } from './Multimonitor.config';

type TMultiMonitorContainer = {
  data: TBetsData[];
  loading: boolean;
};

const MultiMonitorContainer = ({ data, loading }: TMultiMonitorContainer) => {
  const [childrenData, setChildrenData] = useState([]);
  const [groupedLeg, setGroupedLeg] = useState([]);

  const columns = useMultiColumns();
  const { subcolumns, groupedLegColumns } = useMultiSubColumns();

  return (
    <BetsTable
      hasSubTable
      columns={columns as unknown as Column<TBetsData>[]}
      data={data ?? []}
      loading={loading}
      onClickExpand={(row: TBetsData) =>
        setChildrenData(row.bet_legs as React.SetStateAction<TBetLegs[]>)
      }
    >
      <BetsTable
        hasSubTable
        columns={subcolumns as unknown as Column<TBetLegs>[]}
        data={childrenData}
        loading={loading}
        onClickExpand={(row: TBetsData) =>
          setGroupedLeg(row?.legs as React.SetStateAction<TBetLegs[]>)
        }
      >
        <BetsTable
          columns={groupedLegColumns as unknown as Column<TBetLegs>[]}
          data={groupedLeg}
          loading={loading}
        />
      </BetsTable>
    </BetsTable>
  );
};

export default MultiMonitorContainer;
