import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';
import Input from '../../../../../../../common/components/FormElements/Input';
import { getStrings } from '../../../../../../../common/utils';

export default function Form() {
  const [{ value: name }, { error, touched }, { setValue: setResultValue }] =
    useField({
      name: 'is_won',
    });
  const [
    {
      PunterPage: {
        Bets: { Modal: ModalStrings },
      },
    },
  ] = getStrings();

  return (
    <Box>
      <FormControl isInvalid={!!error && touched} mb="6">
        <RadioGroup onChange={setResultValue}>
          <VStack align="flex-start" spacing={2}>
            <Radio value="true">{ModalStrings.Misc.Win}</Radio>
            <Radio value="false">{ModalStrings.Misc.Lose}</Radio>
          </VStack>
        </RadioGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <Flex mb="6" align="center">
        <Text fontSize="xs" whiteSpace="nowrap" mr="1">
          {ModalStrings.Headings.ConfirmPayout}
        </Text>
        <Divider />
      </Flex>

      <Flex mb="10">
        <Input
          label={ModalStrings.Misc.Payout}
          name="payout"
          sxWrapper={{ mr: '2' }}
          InputLeftAddon="$"
          isDisabled={name === 'false'}
        />
        {/**
         * Deductions aren't for P0
         */}
        <Input
          label={ModalStrings.Misc.Deduction}
          name="deduction"
          sxWrapper={{ ml: '2' }}
          InputLeftAddon="$"
          isDisabled
        />
      </Flex>
    </Box>
  );
}
