import { StyleFunctionProps, theme } from '@chakra-ui/react';

const { simple } = theme.components.Table.variants ?? {};

export const Table = {
  variants: {
    ledger: (props: StyleFunctionProps) => ({
      ...simple?.(props),
      th: {
        ...simple?.(props).th,
        bg: 'origin.50',
        color: 'gray.500',
        fontWeight: 'normal',
        textTransform: 'none',
      },
    }),
  },
};
