import styled from 'styled-components';
import { SeparatorProps } from './types';

export const SeparatorLine = styled.div<SeparatorProps>`
  ${({ vertical }) =>
    vertical
      ? `
    min-height: 80%;
    width: 1px;
    `
      : `
    width: 100%;
    height: 1px;
    `}
  background: ${({ theme }) => theme.global.colors.separatorLine};
`;
