import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateUpdateMatch } from './updateMatch';
import { TUpdateMatchBody } from './updateMatch.types';

export const useMutationUpdateMatch = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TUpdateMatchBody>,
    'mutationFn'
  >
) => useMutation(mutateUpdateMatch, options);
