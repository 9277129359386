import { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { DataTable } from 'grommet';

/**
 * I've tried to type this out correctly, however, there seems to be
 * a problem with Grommets types throwing errors.
 * As we are moving away from using this, I dont see it being a huge blocker.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledDataTable = styled(DataTable as any)`
  tr,
  th,
  td,
  tbody,
  thead,
  thead {
    th {
      font-weight: 300;
      &:first-child {
        padding-left: 10px;
      }
    }
  }
  & > tbody {
    th {
      padding-left: 15px;
    }
    a {
      color: ${({ theme }) => theme.global.colors.contentText};
      font-size: 13px;
      :hover {
        text-decoration: underline;
      }
    }

    & > tr {
      transition: background-color 0.2s ease-in-out;
      & > th,
      & > td {
        border-bottom: 1px solid
          ${({ theme }) => theme.global.colors.separatorLine};
        border-right: 1px solid
          ${({ theme }) => theme.global.colors.separatorLine};

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  button:focus {
    span {
      background: none;
    }
  }

  ${(props) =>
    props.className === 'no-header' &&
    css`
      thead {
        display: none;
      }
    `}

  ${(props) =>
    props.className === 'no-border-vertical' &&
    css`
      tbody {
        & > tr > th,
        & > tr > td {
          border-right: none;
        }
      }
    `}

  ${(props) =>
    props.className === 'transactions-no-border-vertical' &&
    css`
      tbody {
        & > tr > th,
        & > tr > td {
          border-right: none;
        }
      }
      th,
      td {
        vertical-align: top;
      }
    `}

  ${(props) =>
    props.className === 'no-border-vertical-opposite-sort' &&
    css`
      tbody {
        & > tr > th,
        & > tr > td {
          border-right: none;
        }
      }

      thead svg {
        transform: rotate(180deg);
      }
    `}

  ${(props) =>
    props.className === 'no-border-vertical-selectable' &&
    css`
      label {
        padding-left: 28px;
      }
      thead {
        th {
          font-weight: 300;
          border-bottom: 1px solid
            ${({ theme }) => theme.global.colors.separatorLine};

          &:first-child {
            padding-left: 0px;
          }
        }
      }
      & > tbody {
        & > tr > th,
        & > tr > td {
          border-right: none;
        }
      }
      & div {
        box-shadow: none;
      }
    `}
` as ComponentType as new <TRowType = unknown>() => DataTable<TRowType>; // Allows passing generic TS param of the datatype of the row through the styled component and on to the base grommet component.
