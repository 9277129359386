import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateSportBody } from './updateSport.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const updateSportURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/update-sport`;

// Trade Manager - Update Sport

export const mutateUpdateSport = (body: TUpdateSportBody) =>
  api
    .post<TMarketCreationResponse>(updateSportURL, body)
    .then((res) => res.data);
