import { Box, StatusType } from 'grommet';
import React from 'react';
import { validate } from 'uuid';
import { useIntl } from 'react-intl';
import { Select } from '../../../../../common/components';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../common/hooks/useRedux';
import { getStrings } from '../../../../../common/utils';
import { setFilter } from '../Services/Slice.Bets';
import { StyledInput } from '../Styles';
import { EBetStatus, PromoTypeSchema } from '../../../../../lib/DBModels';
import IconSvg from '@/components/IconSvg/IconSvg';

export default function Filters() {
  const [
    {
      PunterPage: {
        Bets: {
          Filters: { Placeholders, Labels },
        },
      },
    },
  ] = getStrings();

  const intl = useIntl();

  const {
    filter: {
      event_title,
      event_type,
      states: filter_states,
      promo_type: promo_type,
    },
  } = useAppSelector((state) => state.punterBets);
  const dispatch = useAppDispatch();

  const handleFilterChange = (val: string) => {
    const isUUID = validate(val);
    const newFilter = isUUID
      ? {
          event_title: undefined,
          bet_id: val,
        }
      : {
          event_title: val,
          bet_id: undefined,
        };
    dispatch(setFilter(newFilter));
  };

  const statusOptions = [
    {
      option: EBetStatus.Pending,
      label: intl.formatMessage({ id: 'generic.betstatus.pending' }),
    },
    {
      option: EBetStatus.Settled,
      label: intl.formatMessage({ id: 'generic.betstatus.settled' }),
    },
    {
      option: EBetStatus.Voided,
      label: intl.formatMessage({ id: 'generic.betstatus.voided' }),
    },
    {
      option: EBetStatus.Cancelled,
      label: intl.formatMessage({ id: 'generic.betstatus.cancelled' }),
    },
    // {
    //   option: EBetStatus.CashedOut,
    //   label: BetStatus.cashed_out,
    // },
  ];

  const promoOptions = [
    {
      option: PromoTypeSchema.CashOut,
      label: 'Cash Out',
    },
    {
      option: PromoTypeSchema.OddsBoost,
      label: 'Odds Boost',
    },
    {
      option: PromoTypeSchema.MoneyBack,
      label: 'Money Back',
    },
  ];

  return (
    <Box
      direction="row"
      pad={{ vertical: '16px', horizontal: '28px' }}
      gap="10px"
    >
      <StyledInput
        placeholder={Placeholders.EventName}
        icon={<IconSvg name="search" />}
        value={event_title}
        onChange={({ currentTarget }) =>
          handleFilterChange(currentTarget.value)
        }
      />
      <Select
        id="sport"
        name="sport"
        placeholder={Placeholders.Sport}
        options={[Labels.Race, Labels.Match]}
        value={event_type}
        onChange={({ option }: { option: StatusType }) => {
          dispatch(setFilter({ event_type: option || undefined }));
        }}
        width="100%"
        clear
      />
      <Select
        id="status"
        name="Status"
        placeholder={Placeholders.Status}
        options={statusOptions}
        labelKey="label"
        valueKey="option"
        value={statusOptions.filter((r) => r.option === filter_states)}
        onChange={({ option }: { option: { option?: EBetStatus } }) => {
          dispatch(setFilter({ states: option?.option || undefined }));
        }}
        width="small"
        clear
      />

      <Select
        id="promoType"
        name="Promo Type"
        placeholder="Promo Type"
        options={promoOptions}
        labelKey="label"
        valueKey="option"
        value={promoOptions.filter((r) => r.option === promo_type)}
        onChange={({ option }: { option: { option?: PromoTypeSchema } }) => {
          dispatch(setFilter({ promo_type: option?.option || undefined }));
        }}
        width="small"
        clear
      />
    </Box>
  );
}
