import { useTableau } from '@/api/scoreboard/scoreboard.hooks';
import React, { useRef } from 'react';
import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';
import { Button } from '@chakra-ui/react';

type TableauEmbedRef = {
  refreshDataAsync: () => void;
};

const Scoreboard: React.FC = () => {
  const ref = useRef<TableauEmbedRef | null>(null);
  const { token, reportLink } = useTableau();

  if (!token || !reportLink) return <></>;

  const handleRefresh = () => ref.current?.refreshDataAsync();

  return (
    <>
      <Button onClick={handleRefresh}>Refresh</Button>
      <TableauEmbed
        sourceUrl={reportLink}
        width="100%"
        token={token}
        ref={ref}
        {...{ 'hide-tabs': true }}
        toolbar="hidden"
      />
    </>
  );
};

export default Scoreboard;
