import { useMemo } from 'react';
import { centsToDollars } from '@/common/utils';
import { ColumnDef } from '@tanstack/react-table';

export const usePropositionColumns = () => {
  const propositionColumns = useMemo<
    ColumnDef<Api['SGMultisPropositionStatResponse']>[]
  >(
    () => [
      {
        id: 'name',
        header: 'Proposition',
        cell: ({ row }) => {
          const { name } = row.original;

          return name;
        },
      },
      {
        id: 'bets_count',
        accessorKey: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => {
          const { bets_count } = row.original;

          return bets_count;
        },
      },
      {
        id: 'punters_count',
        accessorKey: 'punters_count',
        header: 'Number of Punters',
        cell: ({ row }) => {
          const { punters_count } = row.original;

          return punters_count;
        },
      },
      {
        id: 'stake',
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        id: 'odds',
        accessorKey: 'odds',
        header: 'Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        id: 'exposure',
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  return { propositionColumns };
};
