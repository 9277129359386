import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateUpdateSport } from './updateSport';
import { TUpdateSportBody } from './updateSport.types';

export const useMutationUpdateSport = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TUpdateSportBody>,
    'mutationFn'
  >
) => useMutation(mutateUpdateSport, options);
