import { chakra, Icon } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

export const TextBreadcrumb = chakra(NavLink, {
  baseStyle: () => ({
    textTransform: 'capitalize',
    transition: '.3s ease-in-out',
    _hover: {
      color: 'origin.500',
    },
  }),
});

export const IconBurger = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '6',
    mr: '6',
    cursor: 'pointer',
    color: 'gray.500',

    _hover: {
      color: 'origin.500',
    },
  }),
});

export const IconChevron = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '6',
    mb: '1px',
  }),
});
