import React from 'react';
import { Column } from '@tanstack/react-table';
import { Box } from '@chakra-ui/react';
import { BetsTable } from '../../../../common/components';
import { useAppDispatch } from '../../../../common/hooks/useRedux';
import Filters from './Components/Filters';
import { useBet, useBetsData, useColumns } from './Services/Hooks.Bets';
import { setFilter } from './Services/Slice.Bets';
import VoidBetModal from '../../../bets/modals/VoidBetModal';
import InfoBetModal from '../../../bets/modals/InfoBetModal/Modal';
import ModalSettleBet from './Components/SettleBetModal/ModalSettleBet';
import { TBetsData } from '../../../../api/punters/punters.types';
import { Pagination } from '../../../../common/components/Pagination/Pagination';

export default function Bets() {
  useBet();
  const { data, isLoading, isLastFetch, offset } = useBetsData();
  const columns = useColumns();
  const dispatch = useAppDispatch();

  return (
    <>
      <InfoBetModal />

      <VoidBetModal />

      <ModalSettleBet />

      <Box>
        <Filters />

        <BetsTable
          columns={columns as unknown as Column<TBetsData>[]}
          data={(data ?? []) as TBetsData[]}
          loading={isLoading}
        />

        <Pagination
          nextDisabled={isLastFetch}
          offset={offset}
          onPageChange={(newOffset) =>
            dispatch(setFilter({ offset: newOffset }))
          }
        />
      </Box>
    </>
  );
}
