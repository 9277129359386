import api from '../../api';
import { TBlendedAllBetsParams } from './blended.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

// Blended - All Bets
const blendedAllBets = `/${getPrefixPath(
  isWincore
)}/trade-manager/blended/all-bets`;
export const queryBlendedAllBets = (params: TBlendedAllBetsParams) =>
  api
    .get<Api['BlendedBetsResponse']>(blendedAllBets, { params })
    .then((res) => res.data);
