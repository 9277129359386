import React, { cloneElement, ReactElement } from 'react';
import {
  FormControl as FormControlChakra,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Icon,
  FormLabelProps,
  FormErrorMessageProps,
  TextProps,
  StyleProps,
} from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { AlertCircleOutline } from '@styled-icons/evaicons-outline';

export type TFormControl = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: TextProps;
  sxWrapper?: StyleProps;
  children: ReactElement;
};

/**
 * The FormControl component will wrap all of our form elements, E.G
 * Radios, Checkboxes, Inputs, Selects, Textarea's, etc.
 *
 * This provides consistent styling & functionality by adding
 * such things as Labels, Error messages & Helper text. It also
 * hooks into Chakra's isInvalid functionality to provide error ui
 * to all parts of the children components.
 */
export default function FormControl({
  id,
  field,
  label,
  error,
  helperText,
  propsLabel,
  propsError,
  propsHelper,
  sxWrapper,
  children,
}: TFormControl) {
  const [, meta] = field ?? [];
  const isInvalid = (meta?.touched && !!meta?.error) || !!error;

  return (
    <FormControlChakra isInvalid={isInvalid} sx={{ ...sxWrapper }}>
      {!!label && (
        <FormLabel htmlFor={id} {...propsLabel}>
          {label}
        </FormLabel>
      )}

      {field ? cloneElement(children, { field }) : children}

      <FormErrorMessage {...propsError}>
        <Icon as={AlertCircleOutline} mr="1" />
        {meta?.error || error}
      </FormErrorMessage>
      {!!helperText && (
        <FormHelperText {...propsHelper}>{helperText}</FormHelperText>
      )}
    </FormControlChakra>
  );
}
