import api from '@/api/api';
import {
  TQueryMeetRacesParams,
  TQueryMeetRacesResponse,
  TQueryRaceMeetsParams,
} from './meetRaces.types';

export const meetRacesEndpointURL = '/bookie/promos/racing/meet-races';

export const queryMeetRaces = (params: TQueryMeetRacesParams) =>
  api
    .get<TQueryMeetRacesResponse>(meetRacesEndpointURL, { params })
    .then((res) => res.data);

// Race meets, different then above 👇 takes multiple race ids & returns bunch of races instead.
export const raceMeetsEndpointURL = '/bookie/promos/racing/race-meets';

export const queryRaceMeets = (params: TQueryRaceMeetsParams) => {
  const newParams = new URLSearchParams();

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(params)) {
    if (!Array.isArray(value)) {
      newParams.append(key, String(value));
    } else {
      for (const val of value) {
        newParams.append(key, String(val));
      }
    }
  }
  /* eslint-enable no-restricted-syntax */

  return api
    .get<TQueryMeetRacesResponse[]>(raceMeetsEndpointURL, { params: newParams })
    .then((res) => res.data);
};
