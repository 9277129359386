import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { keys } from '../../../../api/api.keys';
import { queryProfile } from '../../../../api/profile/profile';
import { setBookieProfile } from '../../../../features/settings/pages/Settings/slices';
import { useAppDispatch } from '../../../hooks/useRedux';

/**
 * Hooks that gets the data required for the
 * header component
 */
export const useHeader = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useQuery([keys.profile], () => queryProfile(), {
    refetchInterval: 60000,
    onSuccess(data) {
      dispatch(setBookieProfile(data));
    },
  });
  const { title } = document;

  // Add brand name to page title
  useEffect(() => {
    const { brand } = data ?? {};

    if (brand && !title.includes(brand)) {
      document.title = `${brand} | ${title}`;
    }
  }, [data, title]);

  const platform = data?.brand?.replaceAll(' Platform', '').toLowerCase();

  return { data, isLoading, platform };
};
