import { EGeneralStatus, TRunner } from '@/lib/DBModels';
import { EPositions } from '../Types';

/**
 * Util function to parse the raw data from the form
 * into an object of key: string[] containing all the id's
 * of the racers in which positions.
 *
 * We dont need to send any id's after position 4
 */
export const parseFormData = (
  values: Record<string, string>,
  runners: TRunner[]
) => {
  const keys: string[] = Object.keys(values);
  const nameSpace: Record<number | string, EPositions> = {
    1: EPositions.FirstPlaces,
    2: EPositions.SecondPlaces,
    3: EPositions.ThirdPlaces,
    4: EPositions.ForthPlaces,
    s: EPositions.Scratched,
    r: EPositions.Removed,
  };

  /**
   * Create an object using specific keys from the namespace.
   * Scratched isn't required on the form, however, is necessary when posting.
   */
  const data = keys.reduce<Record<EPositions, string[]>>(
    (acc, cur) => {
      if (cur === 'settle_status' || cur === 'protest_override') return acc;

      const position = values[cur];
      const key = nameSpace[position];

      return {
        ...acc,
        ...(!!key && { [key]: [...(acc[key] ?? []), cur] }),
      };
    },
    {
      [EPositions.FirstPlaces]: [] as string[],
      [EPositions.SecondPlaces]: [] as string[],
      [EPositions.ThirdPlaces]: [] as string[],
      [EPositions.ForthPlaces]: [] as string[],
      [EPositions.Scratched]: [] as string[],
      [EPositions.Removed]: [] as string[],
    }
  );

  if (Object.values(data).every((value) => value.length === 0)) {
    return undefined;
  }

  // Add all runners that are scratched
  const parsedData = {
    ...data,
    scratched: runners
      .filter((r) => r.status === EGeneralStatus.Scratched)
      .map((r) => r.race_runner_external_ref?.toString() ?? ''),
    removed: runners
      .filter((r) => r.status === EGeneralStatus.Removed)
      .map((r) => r.race_runner_external_ref?.toString() ?? ''),
  };

  return parsedData;
};
