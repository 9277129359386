import {
  Badge,
  BadgeProps,
  Button,
  ButtonProps,
  chakra,
  ChakraComponent,
} from '@chakra-ui/react';
import styled, { CSSProperties } from 'styled-components';

export const ButtonUpdate = chakra(Button, {
  baseStyle: () => ({
    bg: 'green.500',
    maxW: '20',
    m: '5',

    _hover: {
      bg: 'green.200',
    },

    _disabled: {
      bg: 'green.50',
      opacity: '1',
      _hover: {
        bg: 'green.50',
      },
    },

    '&&': {
      boxShadow: 'none',
    },
  }),
}) as ChakraComponent<'button', ButtonProps>;

export const FlucsTable = styled.table`
  & > tbody > tr > td {
    padding: 5px 10px;
    border-bottom: none;
  }
`;

export const RunnerSilk = styled.img`
  width: 32px;
`;

export const RunnerNameContainer = styled.div``;

export const tableColHeaderStyle: CSSProperties = {
  fontSize: '8px',
  fontWeight: 500,
  textAlign: 'center',
  margin: '5px 0',
  textTransform: 'uppercase',
  lineHeight: 1,
};

export const BadgeOddsPercentage: ChakraComponent<'span', BadgeProps> = chakra(
  Badge,
  {
    baseStyle: () => ({
      borderRadius: 'lg',
      p: '2',
      px: '4',
    }),
  }
);
