export const flagOptions = [
  { value: 'suspicious', label: 'suspicious' },
  { value: 'high risk', label: 'high risk' },
  { value: 'known punter', label: 'known punter' },
  { value: 'revisit', label: 'revisit' },
  { value: 'flag IP', label: 'flag IP' },
  { value: 'Withdrawal Hold', label: 'Withdrawal Hold' },
  { value: 'Suspected Fake Account', label: 'Suspected Fake Account' },
  { value: 'allow apple pay', label: 'Allow Apple Pay' },
  { value: 'no 3ds', label: 'No 3DS' },
  { value: 'upbank', label: 'UPBANK' },
  { value: 'aml watch', label: 'AML watch' },
  { value: 'aml high', label: 'AML high' },
  { value: 'aml medium', label: 'AML medium' },
  { value: 'treasury watch', label: 'Treasury watch' },
];
