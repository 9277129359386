import api from '../../../api';
import { TSettleRaceBody } from './settleRace.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const settleRaceURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/settle-race`;

// Post: Settle Race
export const mutateSettleRace = (body: TSettleRaceBody) =>
  api.post<TSettleRaceBody>(settleRaceURL, body).then((res) => res.data);
