import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '@/lib/api/api';
import { TPuntersResponse, TPunterQuery } from './Punters.types';
import { AppThunkDispatch } from '@/app/types';
import { setAllPunters } from './Punters.slices';

export const getAllPunters = createAsyncThunk<
  TPuntersResponse | undefined,
  TPunterQuery | undefined,
  { dispatch: AppThunkDispatch }
>(
  'punters/getPunters',
  async (queryObject, thunkAPI): Promise<TPuntersResponse | undefined> => {
    try {
      const puntersQuery: TPunterQuery = {
        limit: queryObject?.limit,
        offset: queryObject?.offset,
        query: queryObject?.query,
        sort_by: queryObject?.sort_by,
        sort_direction: queryObject?.sort_direction,
      };

      const puntersData = await apiGetRequest<TPuntersResponse>(
        'bookie/punters/search',
        puntersQuery
      );

      thunkAPI.dispatch(setAllPunters(puntersData));

      return puntersData;
    } catch (error) {
      return undefined;
    }
  }
);
