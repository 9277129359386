import { Button, HStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import AllBets from './components/Bets/Bets';
import Runners from './components/Runners/Runners';
import Combinations from './components/Combinations/Combinations';

export default function Blended() {
  enum ETab {
    allBets = 'allBets',
    runners = 'runners',
    combinations = 'combinations',
  }

  const [tab, setTab] = useState<ETab>(ETab.allBets);

  return (
    <>
      <HStack gap="2" m="2">
        <Button
          colorScheme="gray"
          onClick={() => setTab(ETab.allBets)}
          isActive={tab === ETab.allBets}
          _active={{ bg: 'origin.500', color: 'white' }}
        >
          All Blended
        </Button>
        <Button
          colorScheme="gray"
          onClick={() => setTab(ETab.runners)}
          isActive={tab === ETab.runners}
          _active={{ bg: 'origin.500', color: 'white' }}
        >
          Runners
        </Button>
        <Button
          colorScheme="gray"
          onClick={() => setTab(ETab.combinations)}
          isActive={tab === ETab.combinations}
          _active={{ bg: 'origin.500', color: 'white' }}
        >
          Combinations
        </Button>
      </HStack>
      {tab === ETab.allBets && <AllBets />}
      {tab === ETab.runners && <Runners />}
      {tab === ETab.combinations && <Combinations />}
    </>
  );
}
