import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import BlendedLogo from '@/assets/icons/blended-icon.svg';
import { BonusBet } from '../../EventMarketInfo/EventMarketInfo.styles';
import { GiftPack } from '@/assets/icons';
import Countdown from '@/components/Countdown/Countdown';

interface IMarketInfoBlendedProps {
  legs: number;
  timeStamp: string;
  isBonusBet: boolean;
  venue: string;
  raceNumber: string;
  items: {
    runnerNumber: number;
    runnerName: string;
  }[];
}

export function MarketInfoBlended({
  legs,
  timeStamp,
  isBonusBet,
  venue,
  raceNumber,
  items,
}: IMarketInfoBlendedProps) {
  return (
    <Box>
      <Flex>
        <Text fontWeight="bold">{legs} Leg Blended </Text>
        <Image src={BlendedLogo} w="14" ml="1" alt="Same Game Multi Icon" />
      </Flex>
      <Text>{`${venue} R${raceNumber}`}</Text>
      <Box
        sx={{
          '& > div': {
            justifyContent: 'left',
          },
        }}
      >
        <Countdown eventTime={timeStamp} />
      </Box>
      {isBonusBet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>Bonus</Text>
        </BonusBet>
      )}
      {items.map((item) => (
        <Text
          key={item.runnerName}
        >{`${item.runnerNumber}. - ${item.runnerName}`}</Text>
      ))}
    </Box>
  );
}
