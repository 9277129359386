/* eslint-disable no-param-reassign */
import React, { FC, CSSProperties } from 'react';

import { Box, Text, ColumnConfig } from 'grommet';

import { getStrings, getNumberOrdinal } from '@/common/utils';

import { StyledDataTable, LoadingSpinner } from '@/common/components';

import { RunnerSilk, RunnerNameContainer, PlaceLabel } from './Styles';

import { TRunnerResult } from '@/lib/DBModels';
import { srcDefaultRunnerSilk } from '@/assets/icons';

type RaceResultsTableProps = {
  results: TRunnerResult[];
  resultsLoading: boolean;
};

export const RaceResultsTable: FC<RaceResultsTableProps> = ({
  results,
  resultsLoading,
}) => {
  const [
    {
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  const tableColHeaderStyle: CSSProperties = {
    fontSize: '8px',
    fontWeight: 500,
    textAlign: 'center',
    margin: '5px 0',
    textTransform: 'uppercase',
    lineHeight: 1,
    padding: '0 10px',
  };

  const columns: ColumnConfig<TRunnerResult>[] = [
    {
      property: 'display_name',
      primary: true,
      render: (datum) => (
        <Box
          direction="row"
          align="center"
          pad={{ top: '10px', bottom: '10px' }}
          margin={{ left: '-17px' }}
          gap="15px"
        >
          <RunnerSilk
            src={datum?.silk_url || srcDefaultRunnerSilk}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = srcDefaultRunnerSilk;
            }}
          />
          <RunnerNameContainer>
            <Text style={{ whiteSpace: 'nowrap' }} size="large" weight={500}>
              {`${datum?.runner_number ?? ''}. ${datum?.runner_name ?? ''} (${
                datum?.barrier_number ?? ''
              })`}
            </Text>
            <PlaceLabel>
              <Text style={{ whiteSpace: 'nowrap' }} size="small" weight={500}>
                {getNumberOrdinal(datum?.result_place)}
              </Text>
            </PlaceLabel>
          </RunnerNameContainer>
        </Box>
      ),
      plain: true,
    },
    {
      property: 'win_odds',
      header: <Text style={tableColHeaderStyle}>{Common.Win}</Text>,
      render: (datum) =>
        datum?.result_place === '1' ? (
          <Text size="small">{datum?.win_odds}</Text>
        ) : null,
      sortable: false,
      align: 'center',
    },
    {
      property: 'place_odds',
      header: <Text style={tableColHeaderStyle}>{Common.Place}</Text>,
      render: (datum) => <Text size="small">{datum?.place_odds ?? ''}</Text>,
      sortable: false,
      align: 'center',
    },
  ];

  return (
    <>
      <StyledDataTable<TRunnerResult>
        columns={columns}
        placeholder={resultsLoading ? <LoadingSpinner /> : undefined}
        data={results ?? []}
        pad={{
          bottom: 'none',
        }}
        sort={{
          property: 'number',
          direction: 'asc',
        }}
      />
    </>
  );
};
