import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TBetRequestHistoryResponse,
  TBetRequestState,
  TRequestBet,
} from './BetApprovals.types';
import {
  getBetApprovalHistory,
  getBetRequestsPending,
} from './BetApprovals.actions';
import { CommonResponse } from './BetApprovals.utils';

const initialState: TBetRequestState = {
  pending: [],
  pendingLoading: false,

  history: {
    records: [],
    total_pages: 0,
    total_records: 0,
  },
  historyLoading: false,
};

const betRequestSlice = createSlice({
  name: 'betRequests',
  initialState,
  reducers: {
    setModalRejectBet: (
      state: TBetRequestState,
      action: PayloadAction<CommonResponse | undefined>
    ) => ({
      ...state,
      modalRejectBet: action.payload,
    }),
    setModalCounterBet: (
      state: TBetRequestState,
      action: PayloadAction<CommonResponse | undefined>
    ) => ({
      ...state,
      modalCounterBet: action.payload,
    }),
    setPendingBetRequests: (
      state: TBetRequestState,
      action: PayloadAction<TRequestBet[]>
    ) => ({
      ...state,
      pending: action.payload,
    }),
    setHistoryBetRequests: (
      state: TBetRequestState,
      action: PayloadAction<TBetRequestHistoryResponse>
    ) => ({
      ...state,
      history: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBetApprovalHistory.pending, (state: TBetRequestState) => ({
        ...state,
        historyLoading: true,
      }))
      .addCase(getBetApprovalHistory.fulfilled, (state: TBetRequestState) => ({
        ...state,
        historyLoading: false,
      }))
      .addCase(getBetRequestsPending.pending, (state: TBetRequestState) => ({
        ...state,
        pendingLoading: true,
      }))
      .addCase(getBetRequestsPending.fulfilled, (state: TBetRequestState) => ({
        ...state,
        pendingLoading: false,
      }));
  },
});

export const {
  setModalRejectBet,
  setModalCounterBet,
  setPendingBetRequests,
  setHistoryBetRequests,
} = betRequestSlice.actions;

export default betRequestSlice.reducer;
