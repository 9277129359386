import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../lib/api/api';
import { TGetMatch, TMatch } from '../../../../../lib/DBModels';
import { setMatchData } from '../slices';
import { AppThunkDispatch } from '../../../../../app/types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const getMatch = createAsyncThunk<
  TMatch,
  TGetMatch,
  { dispatch: AppThunkDispatch }
>('matchDetails/getMatch', async (params, thunkAPI) => {
  const matchData = await apiGetRequest<TMatch>(
    `/${getPrefixPath(isWincore)}/trade-manager/sport-details/match?match_id=${
      params.matchId
    }${params.isUpdate ? `&t=${new Date().valueOf()}` : ''}`
  );
  thunkAPI.dispatch(setMatchData(matchData));

  return matchData;
});
