import { TextProps, ChakraComponent, chakra, Text } from '@chakra-ui/react';

export const TextTitle: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'md',
    color: 'gray.600',
    mt: '3',
    py: '2',
  }),
});

export const TextError: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    color: 'red.500',

    '& .error-message': {
      color: 'red.500',
    },
  }),
});
