import { chakra } from '@chakra-ui/react';
import ReactDateTimePicker from 'react-datetime-picker';

import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

export const DateTimePicker = chakra(ReactDateTimePicker, {
  shouldForwardProp: (prop) => !['wrapperProps'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ wrapperProps }: any) => ({
    w: 'full',

    '.react-datetime-picker__wrapper': {
      bg: 'white',
      border: '1px',
      borderColor: 'gray.200',
      borderRadius: 'md',
      h: '55px',
      overflow: 'visible',
      px: '2.5',

      '[data-invalid="true"] &': {
        borderColor: 'red.500',
      },

      ...wrapperProps,
    },
  }),
});
