import React from 'react';
import { CardBody, Card, Text, Flex, Box } from '@chakra-ui/react';
import { TRealMoneyAndBonusBetsItem } from '../utils/utils';
import { PercentageBadge } from './PercentageBadge';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRealMoneyAndBonusBetsItemProps extends TRealMoneyAndBonusBetsItem {}

export function RealMoneyAndBonusBetsItem({
  title,
  count,
  value,
  percentageValue,
}: IRealMoneyAndBonusBetsItemProps) {
  const isDollarAndCents = Array.isArray(value) && value.length === 2;
  const hasPercentage = percentageValue !== null;

  return (
    <Card
      w={'full'}
      bg="transparent"
      boxShadow={
        '0px 2px 10px 0px #000000, 0px 0px 5px 1px #094C8B inset,0px 0px 0px 1px #36C4FF inset'
      }
      borderRadius={'6px'}
      sx={{
        flex: '1 0 25%',
        transition: 'all 0.3s ease-in-out',
        borderBottom: '2px',
        borderColor: 'transparent',
      }}
    >
      <CardBody p={4}>
        <Flex justifyContent="start" gap="1">
          <Text
            sx={{
              fontWeight: '500',
              fontSize: '16px',
              mb: '1.5',
              color: '#ccc',
            }}
          >
            {title}
          </Text>
          {count !== null && count > 0 && (
            <Text fontSize="12px" color="whiteAlpha.900">
              {`(${count})`}
            </Text>
          )}
        </Flex>
        <Flex justifyContent="start" gap="1">
          {isDollarAndCents && (
            <Flex mt="2">
              <Text color="white" fontSize="25px">
                {`${value[0]}.`}
              </Text>

              <Text color="white" fontSize="12px">
                {value[1]}
              </Text>
            </Flex>
          )}
          {!isDollarAndCents && (
            <Box mt="2">
              <Text color="white" fontSize="25px">
                {value}
              </Text>
            </Box>
          )}
        </Flex>
        {hasPercentage && percentageValue !== 0 && (
          <PercentageBadge
            value={percentageValue}
            sx={{ pos: 'absolute', mr: '2', mt: '4', top: '5px' }}
          />
        )}
      </CardBody>
    </Card>
  );
}
