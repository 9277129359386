import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import EllipsisLoader from '../EllipsisLoader';
import colours from '../../../styles/colours';
import { useUpdateMarginControls } from './services/UpdateableMarginControls.hooks';
import { EUpdateActions } from './UpdateableMarginControls.types';

import {
  FlexTickDifference,
  TextMargin,
  FlexMarginDisplay,
  TextTickDifference,
  ButtonTick,
  TextOperator,
  ButtonUpdate,
} from './Styles';

export interface UpdateableMarginControlsProps {
  value: number;
  differenceValue: number;
  marginType: 'global' | 'single';
  onUpdate?: (newValue: number) => void;
  onTickUp?: (prevValue: number, newValue: number) => void;
  tickUpDisabled?: boolean;
  onTickDown?: (prevValue: number, newValue: number) => void;
  tickDownDisabled?: boolean;
  showDifferenceValue?: boolean;
  loading?: boolean;
  testId?: string;
  updateDisabled?: boolean;
  onSelect?: () => void;
  isSelected?: boolean;
  isEdited?: boolean;
}

export const UpdateableMarginControls: FC<UpdateableMarginControlsProps> = ({
  value,
  differenceValue,
  showDifferenceValue = true,
  marginType,
  onUpdate,
  onTickUp = () => null,
  onTickDown = () => null,
  tickUpDisabled = false,
  tickDownDisabled = false,
  loading = false,
  testId,
  updateDisabled,
  onSelect = () => null,
  isSelected = false,
  isEdited = false,
}) => {
  const { handleMouseUp, handleMouseDown, newValue, displayValue } =
    useUpdateMarginControls({
      tickDownDisabled,
      tickUpDisabled,
      onTickUp,
      onTickDown,
      marginType,
      differenceValue,
      value,
      isSelected,
    });

  return (
    <Flex
      onClick={() => onSelect()}
      boxShadow={isSelected ? '0px 0px 2px #369eea' : ''}
      px="2"
    >
      <FlexMarginDisplay>
        <TextMargin>
          {marginType === 'global' && newValue > 0 && '+'}
          {marginType === 'global' && newValue}
          {marginType === 'single' && differenceValue.toFixed(2)}
        </TextMargin>
      </FlexMarginDisplay>
      <Flex flexDir="column">
        <ButtonTick
          operator={EUpdateActions.Increment}
          data-testid={`increment-odds-for-${testId}`}
          isDisabled={tickUpDisabled}
          onMouseDown={() => handleMouseDown(EUpdateActions.Increment)}
          onMouseUp={() => handleMouseUp()}
          onMouseLeave={() => handleMouseUp()}
        >
          <TextOperator>+</TextOperator>
        </ButtonTick>
        <ButtonTick
          operator={EUpdateActions.Decrement}
          isDisabled={tickDownDisabled}
          onMouseDown={() => handleMouseDown(EUpdateActions.Decrement)}
          onMouseUp={() => handleMouseUp()}
          onMouseLeave={() => handleMouseUp()}
        >
          <TextOperator>-</TextOperator>
        </ButtonTick>
      </Flex>
      <Flex
        alignItems="center"
        position="relative"
        overflow="hidden"
        minW={onUpdate && '16'}
      >
        {showDifferenceValue && (
          <FlexTickDifference
            alignItems={onUpdate ? 'start' : 'center'}
            bg={isEdited ? 'green.500' : 'blackAlpha.50'}
          >
            <TextTickDifference color={isEdited ? 'white' : 'black'}>
              {displayValue}
            </TextTickDifference>
          </FlexTickDifference>
        )}

        {onUpdate && (
          <ButtonUpdate
            isDisabled={updateDisabled}
            isHalfSize={showDifferenceValue}
            onClick={() => onUpdate(newValue)}
          >
            {loading ? (
              <EllipsisLoader
                size={20}
                ballCount={3}
                frontColor={colours.regularGrey}
                backColor={colours.darkGrey}
              />
            ) : (
              <TextOperator color="white">
                <FormattedMessage id="generic.update" />
              </TextOperator>
            )}
          </ButtonUpdate>
        )}
      </Flex>
    </Flex>
  );
};
