import { Button, chakra, Icon, Td } from '@chakra-ui/react';

export const TdButton = chakra(Td, {
  baseStyle: () => ({
    borderRight: '1px solid',
    borderColor: 'gray.100',
    p: '0',
    verticalAlign: 'top',
  }),
});

export const ButtonRow = chakra(Button, {
  baseStyle: () => ({
    borderRadius: '0',
    bg: 'transparent',
    h: '100%',
    w: 'full',
    p: '0',
    display: 'flex',
    alignItems: 'flex-start',
    _hover: { bg: 'gray.100' },
  }),
});

export const IconButtonRow = chakra(Icon, {
  baseStyle: () => ({
    color: 'gray.500',
    boxSize: '25',
    transition: '0.3s ease-in-out',
  }),
});
