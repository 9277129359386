/* eslint-disable camelcase */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../../lib/api/api';

import { AppThunkDispatch } from '../../../../../../app/types';

import { TVenue } from '../../../../../../lib/DBModels';
import { TVenuesQuery, TRacingCountryAndStates, TRaceQuery } from './types';
import { TEventsResponse } from '../../types';
import { RacingType } from '../../../../../../lib/Constants';
import { setCountries, setVenues, setEventsResponse } from './slices';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const getVenues = createAsyncThunk<
  Promise<TVenue[] | undefined>,
  TVenuesQuery,
  { dispatch: AppThunkDispatch }
>(
  'racing/getVenues',
  async (
    { venueRaceType, venueRaceState, venueRaceCountry },
    thunkAPI
  ): Promise<TVenue[] | undefined> => {
    try {
      const venues = await apiGetRequest<TVenue[]>(
        `${getPrefixPath(isWincore)}/trade-manager/by-racing/venues`,
        {
          race_type: venueRaceType,
          state: venueRaceState,
          country: venueRaceCountry,
        }
      );
      thunkAPI.dispatch(setVenues(venues));
      return venues;
    } catch (error) {
      return undefined;
    }
  }
);

const getLocations = createAsyncThunk<
  Promise<TRacingCountryAndStates[] | undefined>,
  RacingType | undefined,
  { dispatch: AppThunkDispatch }
>(
  'racing/getLocations',
  async (
    locationRaceType,
    thunkAPI
  ): Promise<TRacingCountryAndStates[] | undefined> => {
    try {
      const racingCountriesAndStates = await apiGetRequest<
        TRacingCountryAndStates[]
      >(`${getPrefixPath(isWincore)}/trade-manager/by-racing/locations`, {
        race_type: locationRaceType,
      });

      thunkAPI.dispatch(setCountries(racingCountriesAndStates));

      return racingCountriesAndStates;
    } catch (error) {
      return undefined;
    }
  }
);

const getRaces = createAsyncThunk<
  Promise<TEventsResponse | undefined>,
  TRaceQuery,
  { dispatch: AppThunkDispatch }
>(
  'racing/getRaces',
  async (raceQuery, thunkAPI): Promise<TEventsResponse | undefined> => {
    try {
      const racesData = await apiGetRequest<TEventsResponse>(
        `${getPrefixPath(isWincore)}/trade-manager/by-racing`,
        {
          race_type: raceQuery.race_type,
          state: raceQuery.state,
          country: raceQuery.country,
          venue_id: raceQuery.venue_id,
          resulted: raceQuery.resulted,
          race_status: raceQuery.race_status,
          start_date: raceQuery.start_date,
          end_date: raceQuery.end_date,
          offset: raceQuery.offset,
          limit: raceQuery.limit,
          sort_by: raceQuery.sort_by,
          sort_direction: raceQuery.sort_direction,
        }
      );
      thunkAPI.dispatch(setEventsResponse(racesData));
      return racesData;
    } catch (error) {
      return undefined;
    }
  }
);

export { getVenues, getLocations, getRaces };
