import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  updateEmail,
} from 'firebase/auth';

export const changePassword = async (newPassword: string): Promise<void> => {
  const auth = getAuth();
  if (!auth.currentUser) return;
  await updatePassword(auth.currentUser, newPassword);
};

export const updateUserEmail = async (newEmail: string): Promise<void> => {
  const auth = getAuth();
  if (!auth.currentUser) return;
  await updateEmail(auth.currentUser, newEmail);
};

export const verifyUsersPassword = async (
  email: string | null | undefined,
  password: string
): Promise<boolean> => {
  try {
    const auth = getAuth();

    if (!auth.currentUser || !email || !password) return false;

    const credential = EmailAuthProvider.credential(email, password);
    const result = await reauthenticateWithCredential(
      auth.currentUser,
      credential
    );

    return result !== null;
  } catch (error) {
    return false;
  }
};
