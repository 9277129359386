import {
  FlexProps,
  ChakraComponent,
  Flex,
  chakra,
  IconProps,
  Icon,
} from '@chakra-ui/react';

export const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    border: '1px dashed',
    borderColor: 'gray.300',
    w: 'full',
    minH: '250px',
    my: '2',
    gap: '4',
    p: '3',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: 'sm',
    overflow: 'hidden',
    pos: 'relative',
  }),
});

export const CloseIcon: ChakraComponent<'svg', IconProps> = chakra(Icon, {
  baseStyle: () => ({
    boxSize: '10',
    pos: 'absolute',
    cursor: 'pointer',
    borderRadius: 'full',
    bg: 'white',
    m: '2',
    p: '1',
    right: '0',
  }),
});

export const FlexFallbackWrapper: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    baseStyle: () => ({
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }),
  }
);
