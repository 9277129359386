import { useIntl } from 'react-intl';

export default function usePercentSign() {
  const intl = useIntl();
  const percentSign =
    intl
      .formatNumberToParts(0, { style: 'unit', unit: 'percent' })
      .find(({ type }) => type === 'unit')?.value ?? '%';

  return percentSign;
}
