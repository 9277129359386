import api from '../../../api';
import { TRaceResultResponse, TRaceResultParams } from './raceResult.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const raceResultURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/race-result`;

// Race Result
export const queryRaceResult = (params: TRaceResultParams) =>
  api
    .get<TRaceResultResponse>(raceResultURL, { params })
    .then((res) => res.data);
