import { TLimit } from '../../../features/settings/pages/Settings/tabs/Limits/types';
import api from '../../api';

export const crossGameMultiLimitsURL =
  'bookie/settings/limits/cross-game-multi';

export const updateCrossGameMultiLimit = async (limit: TLimit) =>
  api.put(crossGameMultiLimitsURL, limit).then((res) => res.data);

export const queryCrossGameMultiLimit = async () =>
  api.get<number>(crossGameMultiLimitsURL).then((res) => res.data);
