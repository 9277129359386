import { ENV } from '@/lib/Constants';
import {
  getCoreRowModel,
  PaginationState,
  Table,
  TableOptions,
  TableState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';

export type TPaginatedTableOptions<TData> = Omit<
  TableOptions<TData>,
  | 'pageCount'
  | 'state'
  | 'onPaginationChange'
  | 'getCoreRowModel'
  | 'manualPagination'
  | 'debugTable'
> & {
  state: Omit<Partial<TableState>, 'pagination'> & {
    pagination: PaginationState & { offset?: string | null };
  };
  onPaginationChange: (
    updater: Updater<PaginationState>,
    offset?: string
  ) => void;
};

export function usePaginatedTable<TData>(
  options: TPaginatedTableOptions<TData>
): Table<TData> {
  const { state, onPaginationChange } = options;
  const { offset, ...pagination } = state.pagination;

  const table = useReactTable({
    ...options,
    pageCount: -1,
    state: { ...state, pagination },
    onPaginationChange: (updater) =>
      onPaginationChange(updater, offset ?? undefined),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: ENV.NODE_ENV === 'development',
  });

  return {
    ...table,
    getCanNextPage: () => !!offset,
  };
}
