import { BetsTable } from '@/common/components';
import React from 'react';
import { useEvenShotBetsColumns } from './config/EvenShotAllBets.config';
import { Column } from '@tanstack/react-table';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';
import { usePunterEvenshotBets } from './hooks/EvenShot.hooks';
import { PaginationV2 } from '@/common/components/Pagination/PaginationV2';

export const EvenShotTable = ({ runnerId }: { runnerId: string }) => {
  const { punterColumns } = useEvenShotBetsColumns();
  const {
    data,
    isLoading,
    next,
    previous,
    hidePagination,
    nextDisabled,
    previousDisabled,
  } = usePunterEvenshotBets(runnerId);

  return (
    <>
      <BetsTable
        columns={punterColumns as unknown as Column<TMarketBet>[]}
        loading={isLoading}
        data={data?.items || []}
      />
      <PaginationV2
        {...{
          onNext: next,
          onPrev: previous,
          hidePagination,
          nextDisabled,
          previousDisabled,
        }}
      />
    </>
  );
};
