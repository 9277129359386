import React, { useState } from 'react';
import { Text, Icon, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CheckSquareFill, App, XSquareFill } from '@styled-icons/bootstrap';
import { StartTimeCalculator } from '@/common/components';
import {
  getStrings,
  centsToDollars,
  centsToDollarsNumber,
  dateFormatter,
} from '@/common/utils';
import { TBetEventData, TBetLegs } from '@/api/punters/punters.types';
import { useQuery as useQueryString } from '@/common/hooks/useQuery';
import { useQuerySRMBets } from '@/api/tradeManager/srm/srm.hooks';

export const useRaceDetailsSRM = () => {
  const q = useQueryString();
  const id = q.get('id');
  const checkId = id || '';
  const limit = 20;

  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState('');
  const [offsetHistory] = useState<string[]>([]);

  const shouldRefetch = pageIndex === 0;

  const { data, isLoading } = useQuerySRMBets({
    params: {
      offset,
      limit,
      race_id: checkId,
    },
    options: {
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
      staleTime: shouldRefetch ? 0 : 60 * 5000,
    },
  });

  const next = () => {
    if (!data) return;
    setOffset(data.paging?.next_offset ?? '');
    setPageIndex((prevIdx) => ++prevIdx);
  };
  const previous = () => {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  };

  return {
    data,
    isLoading,
    next,
    previous,
    previousDisabled: pageIndex === 0,
    nextDisabled: data?.paging?.next_offset === null,
    hidePagination: pageIndex === 0 && data?.paging?.next_offset === null,
  };
};

export const useSRMColumns = () => {
  const [
    {
      TradeManagerPage: {
        MultiMonitor: { ColumnTitles },
      },
    },
  ] = getStrings();

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'punter_name',
        header: ColumnTitles.Punter,
        cell: (props: {
          row: { original: { punter_id: string; result: number } };
          getValue: () => string;
        }) => (
          <>
            <Link to={`/punter/${props.row.original.punter_id}`}>
              <Text color="brand.500">{props.getValue() ?? ''}</Text>
            </Link>
          </>
        ),
      },
      {
        accessorKey: 'bet_details',
        header: ColumnTitles.NextLeg,
        cell: (props: { row: { original: { bet_legs: TBetLegs[] } } }) => {
          const legs = props.row.original.bet_legs;
          const nextLeg = legs.find((leg) => leg.status === 'pending');
          const eventType = nextLeg?.event_type?.toLocaleLowerCase();

          return (
            <Link
              to={`${eventType === 'match' ? 'match' : 'race'}-details?id=${
                nextLeg?.event_id
              }`}
            >
              <Text color="brand.500">
                {nextLeg?.event_data?.runner_name ?? ''}
              </Text>
              <Text color="brand.500">
                {nextLeg?.event_data?.venue_name ?? ''} {ColumnTitles.RaceR}
                {nextLeg?.event_data?.race_number ?? ''}{' '}
                {nextLeg?.bet_description ?? ''}
              </Text>
            </Link>
          );
        },
      },
      {
        accessorKey: 'stake',
        header: ColumnTitles.StartingStake,
        cell: (props: { getValue: () => number }) => (
          <Text>
            {centsToDollars(props.getValue() ?? 0).replace(/\.00$/, '')}
          </Text>
        ),
      },
      {
        accessorKey: 'odds',
        header: ColumnTitles.StartingOdds,
        cell: (props: { getValue: () => number }) => (
          <Text>${props.getValue() ?? 0}</Text>
        ),
      },
      {
        accessorKey: 'bet_legs',
        header: ColumnTitles.Legs,
        cell: (props: { getValue: () => { length: number } }) => (
          <Text>{props.getValue().length}</Text>
        ),
      },
      {
        accessorKey: 'legs_won',
        header: ColumnTitles.LegsWon,
        cell: (props: { row: { original: { bet_legs: TBetLegs[] } } }) => {
          const legs = props.row.original.bet_legs;
          const won = legs.filter((leg: TBetLegs) => leg.is_won);
          return <Text>{won ? won.length : '0'}</Text>;
        },
      },
      {
        accessorKey: 'current_stake_value',
        header: ColumnTitles.CurrentStakeValue,
        cell: (props: {
          getValue: () => number;
          row: { original: { bet_legs: TBetLegs[] } };
        }) => <Text>{centsToDollarsNumber(props.getValue() ?? 0)}</Text>,
      },
      {
        accessorKey: 'current_stake_value',
        header: ColumnTitles.PotentialPayout,
        cell: (props: {
          getValue: () => number;
          row: { original: { bet_legs: TBetLegs[] } };
        }) => {
          const currentStake = props.getValue();
          const accumulatedOdds = props.row.original.bet_legs
            .map((leg) => leg.odds)
            .reduce((prev = 1, curr = 1) => prev * curr);
          let potentialPayout;
          if (accumulatedOdds) {
            potentialPayout = currentStake * accumulatedOdds;
          }
          return (
            <Text>
              {centsToDollars(potentialPayout ?? 0).replace(/\.00$/, '')}
            </Text>
          );
        },
      },
      {
        accessorKey: 'next_leg',
        header: ColumnTitles.NextLeg,
        cell: (props: { row: { original: { bet_legs: TBetLegs[] } } }) => {
          const legs = props.row.original.bet_legs;
          const nextLeg = legs.find((leg) => leg.status === 'pending');
          return (
            <Flex justify="left">
              {!!nextLeg?.event_start && (
                <StartTimeCalculator date={nextLeg?.event_start} />
              )}
            </Flex>
          );
        },
      },
    ],
    [ColumnTitles]
  );

  return columns;
};

export const useMultiSubColumns = () => {
  const [
    {
      TradeManagerPage: {
        MultiMonitor: { SubColumnTitles },
      },
    },
  ] = getStrings();

  const subcolumns = React.useMemo(
    () => [
      {
        accessorKey: 'is_won',
        header: '',
        cell: (props: { row: { original: { is_won: boolean | null } } }) => (
          <>
            {props.row.original.is_won ? (
              <Icon
                paddingRight="1"
                color="green.300"
                boxSize="5"
                as={CheckSquareFill}
              />
            ) : (
              (props.row.original.is_won === false && (
                <Icon
                  paddingRight="1"
                  color="red.500"
                  boxSize="5"
                  as={XSquareFill}
                />
              )) || (
                <Icon paddingRight="1" color="gray.300" boxSize="5" as={App} />
              )
            )}
          </>
        ),
      },
      {
        accessorKey: 'bet_description',
        header: SubColumnTitles.Proposition,
        cell: (props: {
          row: {
            original: {
              event_data: TBetEventData;
              event_type: string;
              bet_description: string;
            };
          };
        }) => (
          <>
            {props.row.original.event_type.toLocaleLowerCase() === 'race' ? (
              <Text>
                {props.row.original.event_data.runner_number ?? ''}
                {'. '}
                {props.row.original.event_data.runner_name ?? ''}
              </Text>
            ) : (
              <Text>
                {props.row.original.event_data?.proposition_name ?? ''}
              </Text>
            )}
          </>
        ),
      },
      {
        accessorKey: 'bet_market',
        header: SubColumnTitles.Market,
        cell: (props: {
          row: {
            original: {
              event_data: TBetEventData;
              event_type: string;
            };
          };
        }) =>
          props.row.original.event_type.toLocaleLowerCase() === 'race' ? (
            <Text>
              {props.row.original.event_data?.venue_name ?? ''}{' '}
              {SubColumnTitles.RaceR}
              {props.row.original.event_data?.race_number ?? ''}
            </Text>
          ) : (
            <Text>{props.row.original.event_data?.match_name}</Text>
          ),
      },
      {
        accessorKey: 'bet_market_type',
        header: SubColumnTitles.MarketType,
        cell: (props: {
          row: {
            original: {
              bet_description: string;
              event_type: string;
              event_data: TBetEventData;
            };
          };
        }) => (
          <Text>
            {props.row.original.event_type.toLocaleLowerCase() === 'race'
              ? props.row.original.bet_description ?? ''
              : props.row.original.event_data.market_name ?? ''}
          </Text>
        ),
      },
      {
        accessorKey: 'odds',
        header: SubColumnTitles.Odds,
        cell: (props: { getValue: () => number }) => (
          <Text>{props.getValue()}</Text>
        ),
      },
      {
        accessorKey: 'event_start',
        header: SubColumnTitles.LegTime,
        cell: (props: { getValue: () => string }) => (
          <Flex justify="left">
            {dateFormatter(props.getValue() ?? '', 'DD/MM/YYYY, h:mma')}
          </Flex>
        ),
      },
    ],
    [SubColumnTitles]
  );

  return subcolumns;
};
