import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateAddMatch } from './addMatch';
import { TAddMatchBody } from './addMatch.types';

export const useMutationAddMatch = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TAddMatchBody>,
    'mutationFn'
  >
) => useMutation(mutateAddMatch, options);
