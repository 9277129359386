export const Modal = {
  parts: ['header', 'body', 'footer', 'dialog', 'closeButton'],
  baseStyle: {
    closeButton: {
      top: -2,
      backgroundColor: 'black',
      color: 'white',
      borderRadius: '2xl',
      right: -2,
      p: 2,
      '&&:hover': {
        bg: 'blackAlpha.600',
      },
    },
  },
  sizes: {
    '3xl': {
      header: { px: 12, pt: 12 },
      body: {
        p: 12,
      },
      footer: { px: 12, pb: 12 },
      dialog: {
        borderRadius: '3xl',
      },
    },
    '2xl': {
      header: { px: 10, pt: 10 },
      body: {
        p: 10,
      },
      footer: { px: 10, pb: 10 },
      dialog: {
        borderRadius: '2xl',
      },
    },
    xl: {
      header: { px: 9, pt: 9 },
      body: {
        p: 9,
      },
      footer: { px: 9, pb: 9 },
      dialog: {
        borderRadius: 'xl',
      },
    },
    lg: {
      header: { px: 8, pt: 8 },
      body: {
        p: 8,
      },
      footer: { px: 8, pb: 8 },
      dialog: {
        borderRadius: 'lg',
      },
    },
    md: {
      header: { px: 7, pt: 7 },
      body: {
        p: 7,
      },
      footer: { px: 7, pb: 7 },
      dialog: {
        borderRadius: 'lg',
      },
    },
    sm: {
      header: { px: 6, pt: 6 },
      body: {
        p: 6,
      },
      footer: { px: 6, pb: 6 },
      dialog: {
        borderRadius: 'lg',
      },
    },
  },
};
