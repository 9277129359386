import React from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { ETabs, TTabButton } from '../ModalConfigure.types';

type TTabs = {
  selectedTab: ETabs;
  buttons: TTabButton[];
};

export default function Tabs({ buttons, selectedTab }: TTabs) {
  return (
    <HStack px="8">
      {buttons.map(({ text, option, onClick }) => (
        <Button
          key={text}
          onClick={onClick}
          size="sm"
          variant={selectedTab === option ? 'rounded' : 'outline-rounded'}
          minW="125px"
        >
          {text}
        </Button>
      ))}
    </HStack>
  );
}
