import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { useToast } from '@chakra-ui/react';
import { useActions, useModals } from '../../../../Services/BetApprovals.hooks';
import { errorMessageFromHTTPException } from '../../../../../../../../../lib/Error';
import { getStrings } from '../../../../../../../../../common/utils';
import {
  EBetRequestStatusInputAction,
  TBetRequestStatusInput,
} from '../../../../Services/BetApprovals.types';
import { betRequestRejectValidation } from '../../../../Services/BetApprovals.config';
import { ENewRelicActions } from '@/constants/newRelicActions';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { isWincore } from '../../..';
import api from '@/api/api';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormHOC({ children }: TFormikHOC) {
  const { modalRejectBet, closeRejectBetModal } = useModals();
  const { actionApproval } = useActions();
  const toast = useToast();
  const newRelicLog = useNewRelic();

  const [
    {
      BetApprovalsPage: { Toast: ToastStrings },
    },
  ] = getStrings();

  return (
    <Formik<TBetRequestStatusInput>
      initialValues={{
        request_id: modalRejectBet?.requestId ?? '',
        action: EBetRequestStatusInputAction.Rejected,
      }}
      validateOnChange={false}
      validationSchema={betRequestRejectValidation}
      onSubmit={async (formData, { setSubmitting }) => {
        try {
          if (isWincore) {
            await api.post(
              '/bookie/bet-approvals/global/global-update-status',
              {
                ...formData,
                source_url: modalRejectBet?.sourceApiUrl,
              }
            );
          } else {
            const isSettlement =
              modalRejectBet?.requestType?.toLowerCase() === 'settlement';

            const actionPayload: TBetRequestStatusInput = {
              action: EBetRequestStatusInputAction.Rejected,
              request_id: formData.request_id,
            };

            if (isSettlement) {
              actionPayload.type = 'Settlement';
            }

            await actionApproval(actionPayload);
          }

          closeRejectBetModal();

          toast({
            title: ToastStrings.Rejected,
            status: 'success',
            position: 'top-right',
            duration: 5000,
          });

          newRelicLog(ENewRelicActions.BetApproval, {
            punter_id: modalRejectBet?.punterId,
            message: 'Bet Rejected',
            ...formData,
          });
        } catch (e) {
          toast({
            title: errorMessageFromHTTPException(e),
            status: 'error',
            position: 'top-right',
            duration: 5000,
          });

          newRelicLog(ENewRelicActions.BetApproval, {
            error: JSON.stringify(e),
            punter_id: modalRejectBet?.punterId,
            message: 'Bet Rejected',
            ...formData,
          });
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
