import dayjs from 'dayjs';

export const formatDateTime24 = (str: string | undefined): string => {
  if (!str) return '';
  const dateObj = dayjs(str);
  if (!dateObj.isValid()) return '';
  return dateObj.format('DD/MM/YY HH:mm');
};

export const formatDateTime12 = (str: string | undefined): string => {
  if (!str) return '';
  const dateObj = dayjs(str);
  if (!dateObj.isValid()) return '';
  return dateObj.format('DD/MM, hh:mma');
};

export const formatDate = (str: string | undefined): string => {
  if (!str) return '';
  const dateObj = dayjs(str);
  if (!dateObj.isValid()) return '';
  return dateObj.format('DD/MM/YY');
};

export const formatTime = (str: string | undefined): string => {
  if (!str) return '';
  const dateObj = dayjs(str);
  if (!dateObj.isValid()) return '';
  return dateObj.format('HH:mm');
};
