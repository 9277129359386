import {
  useApprovalsNotification,
  usePendingRequestsData,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.hooks';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { setExpanded } from './Navigation.slices';
import { TRequestBet } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { usePermission } from '@/hooks/usePermissions';

export const useNavPanel = () => {
  const dispatch = useAppDispatch();
  const { hasPermission } = usePermission();
  const { data, offset } = usePendingRequestsData();
  const { totalRecords } = useApprovalsNotification(
    data?.bet_requests as TRequestBet[],
    offset
  );

  const { expanded } = useAppSelector((state) => state.navigation);

  const expandNavPanel = () => {
    dispatch(setExpanded(!expanded));
  };

  return { expandNavPanel, expanded, totalRecords, hasPermission };
};
