import { rest } from 'msw';
import { baseURL } from '../../__mocks__/utils';
import { incentivesBonusBetsURL } from './incentives/bonusBets/bonusBets';
import { receivedMockData } from './received.mocks';

export const handlerBets = [
  rest.get(baseURL(incentivesBonusBetsURL), (req, res, ctx) =>
    res(ctx.delay(), ctx.json(receivedMockData))
  ),
  rest.post(
    baseURL('/bookie/promos/bonus-bets/bonus-revoke'),
    (req, res, ctx) => res(ctx.status(200), ctx.json(res))
  ),
];
