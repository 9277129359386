import axios from 'axios';
import { HTTPError } from './api/types';
import { getStrings } from '../common/utils';

const [{ Generic }] = getStrings();

/* Takes in an exception and returns the correct user message */
export const errorMessageFromHTTPException = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const err = error as HTTPError;

    if (
      err.response?.data?.detail?.msg &&
      err.response?.data?.detail?.user_msg
    ) {
      return err.response?.data?.detail?.user_msg;
    }
  }

  return Generic.Error;
};
