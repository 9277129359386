import React, { FC } from 'react';
import { Box } from 'grommet';

import { Navigate } from 'react-router-dom';
import { getStrings } from '../../../../../../common/utils';
import { useQuery } from '../../../../../../common/hooks/useQuery';

import WithdrawalHistory from './tabs/WithdrawalHistory';
import PendingApprovals from './tabs/PendingApprovals';
import ReadyToPay from './tabs/ReadyToPay';

import { Separator, TabFilter } from '../../../../../../common/components';

export const Withdrawals: FC = () => {
  const query = useQuery();
  const withdrawalFilter = query.get('withdrawalFilter');
  const [
    {
      BanksPage: {
        Withdrawals: { FilterNames },
      },
    },
  ] = getStrings();

  // If there is no filter set, redirect to same page with a default filter of pending
  if (!withdrawalFilter) {
    return <Navigate to="/bank/withdrawals?withdrawalFilter=pending" />;
  }
  if (!withdrawalFilter) return <></>;

  return (
    <>
      <Box
        align="center"
        justify="start"
        direction="row"
        gap="10px"
        pad={{ horizontal: '28px', bottom: '13px' }}
      >
        <TabFilter
          to="/bank/withdrawals?withdrawalFilter=pending"
          isActive={withdrawalFilter === 'pending'}
        >
          {FilterNames.pendingApprovals}
        </TabFilter>
        <TabFilter
          to="/bank/withdrawals?withdrawalFilter=ready"
          isActive={withdrawalFilter === 'ready'}
        >
          {FilterNames.readyToPay}
        </TabFilter>
        <TabFilter
          to="/bank/withdrawals?withdrawalFilter=history"
          isActive={withdrawalFilter === 'history'}
        >
          {FilterNames.history}
        </TabFilter>
      </Box>
      <Separator />
      {withdrawalFilter === 'pending' && <PendingApprovals />}
      {withdrawalFilter === 'ready' && <ReadyToPay />}
      {withdrawalFilter === 'history' && <WithdrawalHistory />}
    </>
  );
};
