import { BetsTable } from '@/common/components';
import React, { useState } from 'react';
import { useCombinationsColumns } from './services/Combinations.config';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { querySGMCombinations } from '@/api/sgm/combinations/combinations';
import { keys } from '@/api/api.keys';
import { Pagination } from '@/common/components/Pagination/Pagination';
import { PaginationV2 } from '@/common/components/Pagination/PaginationV2';
import { useSGMCombinationPunters } from './services/Combinations.hooks';

export default function AllBets() {
  const [selectedMulti, setSelectedMulti] = useState('');

  const [params] = useSearchParams();
  const matchId = params.get('id');

  const { combinationColumns, combinationSubColumns } =
    useCombinationsColumns();

  // Api
  const limit = 20;
  const [offset, setOffset] = useState<string>('0');

  const { data, isLoading } = useQuery(
    [keys.sgmCombinations, !!matchId, offset],
    () => querySGMCombinations({ match_id: matchId ?? '', offset, limit })
  );

  const {
    data: innerData,
    isLoading: innerIsLoading,
    next,
    previous,
    hidePagination,
    nextDisabled,
    previousDisabled,
  } = useSGMCombinationPunters(selectedMulti);

  const isLastFetch = (data?.combinations?.length ?? 0) < limit;

  return (
    <>
      <BetsTable
        hasSubTable
        columns={combinationColumns}
        data={data?.combinations ?? []}
        loading={isLoading}
        onClickExpand={(row: Api['SGMultisCombinationStatResponse']) =>
          setSelectedMulti(row.multi_uid)
        }
      >
        <BetsTable
          columns={combinationSubColumns}
          loading={innerIsLoading}
          data={innerData?.punters || []}
        />

        <PaginationV2
          {...{
            onNext: next,
            onPrev: previous,
            hidePagination,
            nextDisabled,
            previousDisabled,
          }}
        />
      </BetsTable>

      <Pagination
        nextDisabled={isLastFetch}
        offset={Number(offset)}
        onPageChange={(newOffset) => setOffset(String(newOffset))}
        useHistory
        nextOffsetId={!isLastFetch ? data?.paging?.next_offset : null}
      />
    </>
  );
}
