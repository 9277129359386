import React, { ReactNode } from 'react';
import { TRegulatorForm } from '@/views/Regulator/Regulator.types';
import { Form, Formik } from 'formik';
import { useRegulatorForm } from '../services/Filters.hooks';

type TFormikHOC = {
  onSubmit: (values: TRegulatorForm) => void;
  children: ReactNode;
};

export default function FormikHOC({ onSubmit, children }: TFormikHOC) {
  const { validationSchema, initialValues } = useRegulatorForm();

  return (
    <Formik<TRegulatorForm>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
