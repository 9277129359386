import React, { FC } from 'react';
import { StyleProps, Tooltip } from '@chakra-ui/react';
import { EGeneralStatus } from '../../../lib/DBModels';
import { BoxStatusLights } from './StatusLight.styles';
import { getStrings } from '../../utils';

interface StatusLightProps {
  status: EGeneralStatus;
  isSuspended?: boolean;
  size: 'sm' | 'md' | 'lg';
  sx?: StyleProps;
}

export const StatusLight: FC<StatusLightProps> = ({
  status,
  isSuspended = false,
  size,
  sx,
}) => {
  // Override when is_disabled is true as this isn't something that can be added to status on BE and must be a separate prop
  const [
    {
      Generic: { GeneralStatus: LocalisedStrings, Suspended },
    },
  ] = getStrings();

  const label = isSuspended ? Suspended : LocalisedStrings[status];

  return (
    <Tooltip label={label} placement="left">
      <BoxStatusLights status={status} size={size} sx={sx} />
    </Tooltip>
  );
};
