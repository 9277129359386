import React from 'react';
import { Text, TextProps, Icon } from '@chakra-ui/react';
import { Gem } from '@styled-icons/bootstrap/Gem';
import { centsToDollars } from '../../utils';
import { EBetStatus } from '../../../lib/DBModels';
import { isSweeps } from '@/configs/brands.config';
import { Currency } from '@/lib/Constants';

export type TBetReturnText = TextProps & {
  status?: EBetStatus;
  payout?: number;
  currency?: string;
};

export default function BetReturnText({
  status,
  payout,
  currency,
  ...props
}: TBetReturnText) {
  const isLoss = Number(payout) === 0;

  const color = () => {
    if (status === EBetStatus.Settled) {
      return isLoss ? 'red.500' : 'green.500';
    }
    return 'blue.500';
  };

  const formattedPayout = centsToDollars(payout ?? 0).replace(/\.00$/, '');

  return (
    <Text color={color()} {...props}>
      {currency === Currency.GEMS && <Icon as={Gem} color={'blue.500'} />}
      {isSweeps && currency === Currency.BCCASH
        ? formattedPayout.replace('$', 'BC$')
        : formattedPayout}
    </Text>
  );
}
