import React from 'react';
import { FormControl, FormErrorMessage, Icon } from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/fa-solid/ChevronDown';
import Input from '@/common/components/FormElements/Input';
import { useFormikContext } from 'formik';
import Datepicker from '@/common/components/FormElements/Datepicker/Datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRegulatorForm } from '@/views/Regulator/Regulator.types';
import {
  FormLabelDatepicker,
  inputLabelStyles,
} from '../styles/Filters.styles';

const ByClientEmailForm = () => {
  const intl = useIntl();

  const { errors, values, setFieldValue, touched } =
    useFormikContext<TRegulatorForm>();

  return (
    <>
      <Input
        variant="filled"
        size="lg"
        label={intl.formatMessage({
          id: 'regulatorpage.tabs.bethistory.clientid',
        })}
        propsLabel={inputLabelStyles}
        sxWrapper={{ marginBottom: '0', w: '80' }}
        name="client_email"
        placeholder={intl.formatMessage({
          id: 'regulatorpage.tabs.bethistory.clientid',
        })}
      />

      <FormControl
        maxW="60"
        data-invalid={!!errors.date_from && !!touched.date_from}
        isInvalid={!!errors.date_from && !!touched.date_from}
      >
        <FormLabelDatepicker>
          <FormattedMessage id="regulatorpage.bettinghistory.datefrom" />
        </FormLabelDatepicker>
        <Datepicker
          value={values.date_from ? new Date(values.date_from) : undefined}
          onChange={(date) => {
            setFieldValue('date_from', date, true);
          }}
          calendarIcon={<Icon as={ChevronDown} />}
        />
        {errors.date_from && (
          <FormErrorMessage>{errors.date_from}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        w="60"
        data-invalid={!!errors.date_to && !!touched.date_to}
        isInvalid={!!errors.date_to && !!touched.date_to}
      >
        <FormLabelDatepicker>
          <FormattedMessage id="regulatorpage.bettinghistory.dateto" />
        </FormLabelDatepicker>
        <Datepicker
          value={values.date_to ? new Date(values.date_to) : undefined}
          onChange={(date) => {
            setFieldValue('date_to', date, true);
          }}
          calendarIcon={<Icon as={ChevronDown} />}
        />
        {errors.date_to && (
          <FormErrorMessage>{errors.date_to}</FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};

export default ByClientEmailForm;
