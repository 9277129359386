import { mixed, number, object, SchemaOf, string } from 'yup';
import { getStrings } from '../../../../../../common/utils';
import {
  EBetRequestStatusInputAction,
  EBetRequestRejectReasons,
  TBetRequestStatusInput,
} from './BetApprovals.types';

const [{ FormValidation }] = getStrings();

export const betRequestCounterValidation = (
  currentStake: number,
  isSettlement: boolean
): SchemaOf<TBetRequestStatusInput> =>
  object({
    request_id: string().required(FormValidation.RequiredField),
    amount: number()
      .moreThan(0, FormValidation.MinimumStake)
      .lessThan(
        currentStake,
        isSettlement
          ? 'Partial payout cannot be higher than current return'
          : 'Counter stake can not be higher then current stake.'
      )
      .required(FormValidation.RequiredField),
    reason: mixed<EBetRequestRejectReasons>(),
    action: mixed<EBetRequestStatusInputAction>().required(
      FormValidation.RequiredField
    ),
    type: string(),
    source_url: string().optional(),
  });

export const betRequestRejectValidation: SchemaOf<TBetRequestStatusInput> =
  object({
    request_id: string().required(FormValidation.RequiredField),
    amount: number(),
    reason: mixed<EBetRequestRejectReasons>().optional(),
    action: mixed<EBetRequestStatusInputAction>().required(
      FormValidation.RequiredField
    ),
    type: string(),
    source_url: string().optional(),
  });
