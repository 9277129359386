import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkDispatch } from '../../../../../../app/types';
import { apiGetRequest, apiPatchRequest } from '../../../../../../lib/api/api';
import { setBookieProfile } from '../../slices';
import { TBookieProfile } from './types';

export const getBookieProfile = createAsyncThunk<
  TBookieProfile,
  undefined,
  { dispatch: AppThunkDispatch }
>('settings/getBookieProfile', async (_, thunkApi) => {
  const response = await apiGetRequest<TBookieProfile>(
    '/bookie/settings/profile'
  );

  thunkApi.dispatch(setBookieProfile(response));

  return response;
});

export const updateBookieProfile = createAsyncThunk<
  void,
  TBookieProfile,
  { dispatch: AppThunkDispatch }
>('settings/updateBookieProfile', async (payload, thunkApi) => {
  await apiPatchRequest<TBookieProfile>(
    '/bookie/settings/profile',
    payload
  ).then(() => {
    thunkApi.dispatch(setBookieProfile(payload));
  });
});
