import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState, AppThunkDispatch } from '../../../../app/types';
import {
  apiGetRequest,
  apiPostRequest,
  apiDeleteRequest,
} from '../../../../lib/api/api';
import { deepClone, getStrings } from '../../../../common/utils';
import {
  setPunterActivityLogs,
  setPunterNotes,
  setPunterDepositLimits,
  setPunterCurrentDepositLimit,
  setPunterTransactions,
  setPunterStats,
} from './slices';
import {
  TPunterActivityLog,
  TPunterActivityLogFilterOptions,
  TNewPunterNote,
  TNewNoteAttachment,
  TNoteAttachment,
  TNote,
  TAddDepositLimitData,
  TCreateDepositLimitResponse,
  TPunterTransactionsResponse,
  TPunterTransactionsQuery,
} from './types';
import {
  TPunterCurrentDepositLimit,
  TPunterDepositLimit,
  TPunterStats,
} from '../../../../lib/DBModels';

const [
  {
    PunterPage: { Transactions: strings },
  },
] = getStrings();

/**
 * Get punter logs
 * @param filterOptions
 */
export const getPunterActivityLogs = createAsyncThunk<
  TPunterActivityLog[],
  { punterId: string; filterOptions: TPunterActivityLogFilterOptions },
  { dispatch: AppThunkDispatch }
>('punter/getPunterActivityLogs', async (payload, thunkAPI) => {
  const params = new URLSearchParams({ ...payload.filterOptions });
  const hasFilters = Object.entries(payload.filterOptions).length !== 0;
  const filters = `${hasFilters ? `&${params.toString()}` : ''}`;

  const punterId = `?punter_id=${payload.punterId}`;

  const url = `/bookie/settings/activity-log/punter-actions${punterId}${filters}`;

  const punterLogs = await apiGetRequest<TPunterActivityLog[]>(url);

  thunkAPI.dispatch(setPunterActivityLogs(punterLogs));
  return punterLogs;
});

/**
 * Get punters Notes
 *
 */
export const getPunterNotes = createAsyncThunk<
  TNote[],
  string,
  { dispatch: AppThunkDispatch }
>('punter/getPunterNotes', async (punterID, thunkAPI) => {
  const punterNotes = await apiGetRequest<TNote[]>(
    `/bookie/punters/notes?punter_id=${punterID}`
  );
  thunkAPI.dispatch(setPunterNotes(punterNotes));
  return punterNotes;
});

/**
 * Create a punter note
 *
 */
export const addPunterNote = createAsyncThunk<
  TNote,
  TNewPunterNote,
  { dispatch: AppThunkDispatch; state: AppState }
>('punter/addPunterNote', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();

  const response = await apiPostRequest<TNote>(
    '/bookie/punters/notes',
    payload
  );
  thunkAPI.dispatch(setPunterNotes([response, ...state.punter.punterNotes]));

  return response;
});

/**
 * Add attachments to a note
 *
 */
export const addNoteAttachments = createAsyncThunk<
  TNoteAttachment[] | null,
  {
    noteId: string;
    attachments: TNewNoteAttachment[];
  },
  { dispatch: AppThunkDispatch; state: AppState }
>('punter/addNoteAttachments', async (payload, thunkAPI) => {
  const response = await apiPostRequest<TNoteAttachment[]>(
    `/bookie/punters/notes/attachments/${payload.noteId}`,
    payload.attachments
  );
  const state = thunkAPI.getState();
  // clones punter notes so we can modify data.
  const newPunterNotes = deepClone(state.punter.punterNotes) as TNote[];

  const noteIndex = newPunterNotes.findIndex(
    (note) => note.note_id === payload.noteId
  );

  if (newPunterNotes[noteIndex]?.attachments) {
    // Update the specific not with the response attachments.
    newPunterNotes[noteIndex].attachments = [
      ...(newPunterNotes[noteIndex]?.attachments as TNoteAttachment[]),
      ...response,
    ];
  }

  thunkAPI.dispatch(setPunterNotes(newPunterNotes));
  return response;
});

export const deleteNote = createAsyncThunk<
  undefined,
  string,
  { dispatch: AppThunkDispatch; state: AppState }
>('punter/deleteNote', async (noteId) => {
  const response = await apiDeleteRequest<undefined>(
    `/bookie/punters/notes?note_id=${noteId}`
  );

  return response;
});

/**
 * Delete an attachment for an existing Note.
 *
 */
export const deleteNoteAttachment = createAsyncThunk<
  undefined,
  string,
  { dispatch: AppThunkDispatch }
>('punter/deleteNoteAttachment', async (payload) => {
  const response = await apiDeleteRequest<undefined>(
    `/bookie/punters/notes/attachment/${payload}`
  );

  return response;
});

export const getPunterStats = createAsyncThunk<
  TPunterStats,
  string,
  { dispatch: AppThunkDispatch }
>('punter/getPunterStats', async (punterId, thunkAPI) => {
  const response = await apiGetRequest<TPunterStats>(
    `/bookie/punters/stats/${punterId}`
  );

  thunkAPI.dispatch(setPunterStats(response));

  return response;
});

export const getPunterDepositLimit = createAsyncThunk<
  TPunterDepositLimit[],
  string,
  { dispatch: AppThunkDispatch }
>('punter/getPunterDepositLimit', async (punter_id, thunkAPI) => {
  const response = await apiGetRequest<TPunterDepositLimit[]>(
    `/bookie/punters/limits/deposit-limit/${punter_id}`
  );

  thunkAPI.dispatch(setPunterDepositLimits(response));

  return response;
});

export const getPunterCurrentDepositLimit = createAsyncThunk<
  TPunterCurrentDepositLimit,
  string,
  { dispatch: AppThunkDispatch }
>('punter/getPunterCurrentDepositLimit', async (punter_id, thunkAPI) => {
  const response = await apiGetRequest<TPunterCurrentDepositLimit>(
    `/bookie/punters/limits/deposit-limit/current/${punter_id}`
  );

  thunkAPI.dispatch(setPunterCurrentDepositLimit(response));

  return response;
});

export const addPunterDepositLimit = createAsyncThunk<
  TCreateDepositLimitResponse,
  TAddDepositLimitData,
  { dispatch: AppThunkDispatch; state: AppState }
>('punter/addPunterDepositLimit', async (payload, thunkAPI) => {
  const response = await apiPostRequest<TCreateDepositLimitResponse>(
    `/bookie/punters/limits/deposit-limit/${payload.punter_id}`,
    {
      amount: payload.amount,
      frequency: payload.frequency,
    },
    { failMessage: 'Error' }
  );

  if (response.deposit_limit_current && response.deposit_limit_frequency) {
    await thunkAPI.dispatch(getPunterCurrentDepositLimit(payload.punter_id));

    await thunkAPI.dispatch(getPunterDepositLimit(payload.punter_id));
  }

  return response;
});

export const getPunterTransactions = createAsyncThunk<
  TPunterTransactionsResponse,
  TPunterTransactionsQuery,
  { dispatch: AppThunkDispatch }
>('punter/getPunterTransactions', async (transQuery, thunkAPI) => {
  const response = await apiGetRequest<TPunterTransactionsResponse>(
    '/bookie/punters/transactions',
    transQuery
  );

  thunkAPI.dispatch(setPunterTransactions(response));

  return response;
});

export const getPunterAccountTransactionsDownload = createAsyncThunk<
  string,
  string | null | undefined,
  { dispatch: AppThunkDispatch }
>('punter/getPunterAccountTransactionsDownload', async (punterId) => {
  if (punterId) {
    const accountTransactions = await apiGetRequest<string>(
      '/bookie/punters/transactions/download-all',
      { punter_id: punterId },
      { failMessage: strings.downloadErrorMessage }
    );
    return accountTransactions;
  }
  return '';
});
