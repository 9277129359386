import api from '../../api';
import { TMarketBet } from '../trademanager.types';
import {
  TConfigProps,
  TGetMatchParams,
  TGetBetsParams,
  TMarket,
  TMarketGroup,
  TMatch,
  TSettleMarketProps,
} from './match.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const matchDetailsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/match`;
export const queryMatchData = (params: TGetMatchParams) =>
  api.get<TMatch>(matchDetailsURL, { params }).then((res) => res.data);

export const marketGroupsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/market-groups/`;
export const queryMarketGroups = (matchId: string) =>
  api
    .get<TMarketGroup[]>(`${marketGroupsURL}${matchId}`)
    .then((res) => res.data);

export const marketsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/markets`;
export const queryMarkets = (params: Record<string, unknown>) =>
  api.get<TMarket[]>(marketsURL, { params }).then((res) => res.data);

export const marketPropositionsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/proposition-for-market`;
export const queryMarketPropositions = (params: Record<string, unknown>) =>
  api.get<TMarket[]>(marketPropositionsURL, { params }).then((res) => res.data);

// This is the only query being used for the time being, above will be come into play once the matchDetails.tsx has been re-written.
export const betsForMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/bets-for-market`;
export const queryBetsForMarket = (params: TGetBetsParams) =>
  api.get<TMarketBet[]>(betsForMarketURL, { params }).then((res) => res.data);

export const settleMarketPropsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/settle-sport-market-propositions`;

export const mutateMarketProps = (marketProps: TSettleMarketProps) =>
  api.post(settleMarketPropsURL, marketProps).then((res) => res.data);

export const mutateMatchConfig = (configProps: TConfigProps) => {
  const { match_id, ...restConfig } = configProps; // Destructure match_id from configProps
  const apiURL = `/${getPrefixPath(
    isWincore
  )}/trade-manager/sport-details/matches/${match_id}/settings`;

  return api.patch(apiURL, restConfig).then((res) => res.data);
};
