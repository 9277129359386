import { extendTheme, withDefaultProps } from '@chakra-ui/react';
import base from './base';
import { Button } from './components/button';
import { FormError } from './components/formError';
import { FormLabel } from './components/formLabel';
import { Radio } from './components/radio';
import { Popover } from './components/popover';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { styles } from './styles';
import { Modal } from './components/modal';
import { Tooltip } from './components/tooltip';
import { Alert } from './components/alert';
import { Select } from './components/select';
import { Input } from './components/input';
import { Table } from './components/table';

const bookie = extendTheme(
  {
    ...base,
    theme: 'bookie',
    colors,
    fonts,
    components: {
      FormLabel,
      FormError,
      Button,
      Popover,
      Modal,
      Tooltip,
      Alert,
      Radio,
      Select,
      Input,
      Table,
    },
    styles,
    config: { cssVarPrefix: 'bc' },
  },
  withDefaultProps({
    defaultProps: { size: 'md' },
    components: [
      'Button',
      'Input',
      'NumberInput',
      'PinInput',
      'Select',
      'Textarea',
    ],
  })
);

export default bookie;
