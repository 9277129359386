import api from '@/api/api';
import {
  TQueryVenueMeetsResponse,
  TQueryVenueMeetsParams,
} from './venueMeets.types';

export const venueMeetsEndpointURL = '/bookie/promos/racing/venue-meets';

export const queryVenueMeets = (params: TQueryVenueMeetsParams) => {
  const newParams = new URLSearchParams();

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(params)) {
    if (!Array.isArray(value)) {
      newParams.append(key, String(value));
    } else {
      for (const val of value) {
        newParams.append(key, String(val));
      }
    }
  }
  /* eslint-enable no-restricted-syntax */

  return api
    .get<TQueryVenueMeetsResponse>(venueMeetsEndpointURL, { params: newParams })
    .then((res) => res.data);
};
