import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';

import { getStrings } from '../../../../../../../../common/utils';
import { useBetApprovalLimit } from './Services/BetApprovalLimit.hooks';
import { BetApprovalLimitValidation } from './Services/BetApprovalLimit.config';

import { TBetApprovalLimitValues } from '../../types';

type TFormikHOC = {
  children: ReactNode;
};

export default function RejectLimitsFormikHOC({ children }: TFormikHOC) {
  const { betApprovalLimit, updateBetApprovalLimit } = useBetApprovalLimit();
  const [
    {
      Generic,
      SettingsPage: {
        Limits: { BetApprovalLimit: LocalisedStrings },
      },
    },
  ] = getStrings();

  return (
    <Formik<TBetApprovalLimitValues>
      initialValues={betApprovalLimit}
      enableReinitialize
      validateOnChange={false}
      validationSchema={BetApprovalLimitValidation}
      onSubmit={(limits, { setSubmitting }) => {
        try {
          setSubmitting(true);
          updateBetApprovalLimit(limits);

          toast.success(LocalisedStrings.updated);
        } catch (e) {
          toast.error(Generic.Error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
