import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TBetResponse, TBetsData } from './Types.Bets';
import { EBetStatus, PromoTypeSchema } from '../../../../../lib/DBModels';
import { TVoidBetModalData } from '../../../../bets/modals/VoidBetModal/Services/Types.VoidBet';

export type TFilter = {
  [key: string]: string | boolean | number | undefined;
  event_title: string | undefined;
  bet_id: string | undefined;
  event_type: string | undefined;
  states: EBetStatus | undefined;
  offset: number;
  sort_direction: 'desc' | 'asc';
  sort_by:
    | 'created_at'
    | 'event_title'
    | 'event_subtitle'
    | 'punter_name'
    | 'bookie_name'
    | 'odds'
    | 'stake';
  promo_type: PromoTypeSchema | undefined;
  cashout_only?: boolean;
};

type TBets = {
  filter: TFilter;
  response: TBetResponse | undefined;

  modalInfoBet: TBetsData | undefined;
  modalVoidBet: TVoidBetModalData | undefined;
  modalSettleBet?: TBetsData;
};

const initialState: TBets = {
  filter: {
    event_title: undefined,
    bet_id: undefined,
    event_type: undefined,
    states: undefined,
    promo_type: undefined,
    offset: 0,
    sort_direction: 'desc',
    sort_by: 'created_at',
  },
  response: undefined,
  modalInfoBet: undefined,
  modalVoidBet: undefined,
  modalSettleBet: undefined,
};

const bets = createSlice({
  name: 'bets',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<Partial<TBets['filter']>>) => {
      const key = Object.keys(action.payload);

      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
          ...(!key.includes('offset') && { offset: 0 }),
        },
      };
    },
    setData: (
      state,
      action: PayloadAction<NonNullable<TBets['response']>>
    ) => ({
      ...state,
      response: {
        ...action.payload,
      },
    }),
    setModalInfoBet: (state, action: PayloadAction<TBetsData | undefined>) => ({
      ...state,
      modalInfoBet: action.payload,
    }),
    setModalVoidBet: (
      state,
      action: PayloadAction<TVoidBetModalData | undefined>
    ) => ({
      ...state,
      modalVoidBet: action.payload,
    }),
    setModalSettleBet: (
      state,
      action: PayloadAction<TBets['modalSettleBet']>
    ) => ({
      ...state,
      modalSettleBet: action.payload,
    }),
    reset: () => initialState,
  },
});

export const {
  reset,
  setFilter,
  setData,
  setModalInfoBet,
  setModalVoidBet,
  setModalSettleBet,
} = bets.actions;
export default bets.reducer;
