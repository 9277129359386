import { ERaceStatus } from './DBModels';
import { firebaseConfig } from './firebase/config';
export const imageHostingFilePaths = {
  punterNotes: '/punter/notes/attachments',
};

export enum RacingType {
  HORSE_RACING = 'Horse Racing',
  GREYHOUNDS = 'Greyhounds',
  HARNESS = 'Harness Racing',
}

export enum AU_STATES {
  VIC = 'VIC',
  NSW = 'NSW',
  QLD = 'QLD',
  SA = 'SA',
  WA = 'WA',
  NT = 'NT',
  ACT = 'ACT',
  TAS = 'TAS',
}

export enum AuthErrorCode {
  INVALID_EMAIL = 'auth/invalid-email',
  USER_DISABLED = 'auth/user-disabled',
  RESET_PASSWORD_EXCEED_LIMIT = 'auth/too-many-requests',
}

// STATUS MARKET NAMES AND CORRECT ORDER
export const marketNameAndOrder: { [key: string]: string } = {
  'Racing Win': 'Win',
  'Racing Place': 'Place',
  Exacta: 'Exacta',
  Quinella: 'Quinella',
  Trifecta: 'Trifecta',
  FirstFour: 'First Four',
};

// INDIVIDUAL MARKET TYPES
export enum EInividualMarketTypes {
  WIN = 'Racing Win',
  PLACE = 'Racing Place',
  EXACTA = 'Exacta',
  QUINELLA = 'Quinella',
  TRIFECTA = 'Trifecta',
  FIRSTFOUR = 'FirstFour',
  TOTE = 'tote_single_mid',
}

// ACTIVITY LOGGING ENUMS AND CONSTS
export enum BOOKIE_ACTIVITY_LOG_TYPE {
  APPROVE_BET = 'BetApprovals_Approve',
  BOOKIE_UPDATE = 'BookieProfile_Update',
  LOGIN = 'Authentication_Login',
  LOGOUT = 'Authentication_Logout',
  MARGINS_UPDATE = 'Margins_Update',
  OFFERINGS_DISABLED = 'Offerings_Disabled',
  OFFERINGS_ENABLED = 'Offerings_Enabled',
  PUNTER_UPDATE = 'PunterProfile_InfoUpdate',
  REJECT_BET = 'BetApprovals_Reject',
  RESET_PASSWORD = 'Authentication_ResetPassword',
}

// Used for activity log dropdown
export const bookieActivityLogTypeOptions = Object.values(
  BOOKIE_ACTIVITY_LOG_TYPE
).map((value) => value);

// Display values for activity log dropdown
export const BOOKIE_ACTIVITY_LOG_TYPE_DISPLAY: { [key: string]: string } = {
  [BOOKIE_ACTIVITY_LOG_TYPE.LOGIN]: 'Login',
  [BOOKIE_ACTIVITY_LOG_TYPE.LOGOUT]: 'Logout',
  [BOOKIE_ACTIVITY_LOG_TYPE.RESET_PASSWORD]: 'Reset Password',
  [BOOKIE_ACTIVITY_LOG_TYPE.BOOKIE_UPDATE]: 'Bookie Profile Update',
  [BOOKIE_ACTIVITY_LOG_TYPE.PUNTER_UPDATE]: 'Punter Profile Update',
  [BOOKIE_ACTIVITY_LOG_TYPE.OFFERINGS_ENABLED]: 'Offerings enabled',
  [BOOKIE_ACTIVITY_LOG_TYPE.OFFERINGS_DISABLED]: 'Offerings disabled',
  [BOOKIE_ACTIVITY_LOG_TYPE.APPROVE_BET]: 'Approve Bet',
  [BOOKIE_ACTIVITY_LOG_TYPE.REJECT_BET]: 'Reject Bet',
  [BOOKIE_ACTIVITY_LOG_TYPE.MARGINS_UPDATE]: 'Margins Update',
};

// Used for race status filter dropdown
export const RACE_STATUS_OPTIONS = Object.values(ERaceStatus).map(
  (value) => value
);

// Display values for race status filter dropdown
export const RACE_STATUS_OPTIONS_DISPLAY: { [key: string]: string } = {
  [ERaceStatus.Open]: 'Open',
  [ERaceStatus.Closed]: 'Closed',
  [ERaceStatus.Abandoned]: 'Abandoned',
  [ERaceStatus.Settled]: 'Settled',
  [ERaceStatus.Scratched]: 'Scratched',
  [ERaceStatus.Voided]: 'Voided',
};

// Only required for activity types fired on FE
export const ACTIVITY_LOG_SUMMARY: { [key: string]: string } = {
  [BOOKIE_ACTIVITY_LOG_TYPE.LOGIN]: 'User logged in.',
  [BOOKIE_ACTIVITY_LOG_TYPE.LOGOUT]: 'User logged out.',
  [BOOKIE_ACTIVITY_LOG_TYPE.RESET_PASSWORD]: 'User reset password.',
};

export enum PUNTER_ACTIVITY_LOG_TYPE {
  BET_APPROVED = 'Bet_Approved',
  BET_PLACED = 'Bet_Placed',
  BET_REJECTED = 'Bet_Rejected',
  DEPOSIT_LIMIT_REMOVED = 'Limits_DepositRemoved',
  DEPOSIT_LIMIT_SET = 'Limits_DepositSet',
  DEPOSIT_LIMIT_UPDATED = 'Limits_DepositUpdated',
  LOGIN = 'Authentication_Login',
  LOGOUT = 'Authentication_Logout',
  PERSONAL_INFO_UPDATED = 'PersonalInfo_Updated',
  REGISTRATION_COMPLETED = 'Registration_Completed',
  REGISTRATION_INITIATED = 'Registration_Initiated',
  RESET_PASSWORD = 'Authentication_ResetPassword',
  VERIFICATION_DOC_FAILED = 'Verification_DocumentFailed',
  VERIFICATION_DOC_REQUIRED = 'Verification_DocumentRequired',
  VERIFICATION_DOC_SUCCESS = 'Verification_DocumentSuccess',
  VERIFICATION_FAILED = 'Verification_Failed',
  VERIFICATION_SUCCESS = 'Verification_Success',
}

// Used for punter profile dropdown
export const punterActivityLogTypeOptions = Object.values(
  PUNTER_ACTIVITY_LOG_TYPE
).map((value) => value);

// Display values for dropdown
export const PUNTER_ACTIVITY_LOG_TYPE_DISPLAY: { [key: string]: string } = {
  [PUNTER_ACTIVITY_LOG_TYPE.LOGIN]: 'Login',
  [PUNTER_ACTIVITY_LOG_TYPE.LOGOUT]: 'Logout',
  [PUNTER_ACTIVITY_LOG_TYPE.RESET_PASSWORD]: 'Reset Password',
  [PUNTER_ACTIVITY_LOG_TYPE.BET_APPROVED]: 'Bet Approved',
  [PUNTER_ACTIVITY_LOG_TYPE.BET_PLACED]: 'Bet Placed',
  [PUNTER_ACTIVITY_LOG_TYPE.BET_REJECTED]: 'Bet Rejected',
  [PUNTER_ACTIVITY_LOG_TYPE.DEPOSIT_LIMIT_REMOVED]: 'Deposit Limit Removed',
  [PUNTER_ACTIVITY_LOG_TYPE.DEPOSIT_LIMIT_SET]: 'Deposit Limit Set',
  [PUNTER_ACTIVITY_LOG_TYPE.DEPOSIT_LIMIT_UPDATED]: 'Deposit Limit Updated',
  [PUNTER_ACTIVITY_LOG_TYPE.PERSONAL_INFO_UPDATED]: 'Personal Info Updated',
  [PUNTER_ACTIVITY_LOG_TYPE.REGISTRATION_COMPLETED]: 'Registration Completed',
  [PUNTER_ACTIVITY_LOG_TYPE.REGISTRATION_INITIATED]: 'Registration Initiated',
  [PUNTER_ACTIVITY_LOG_TYPE.VERIFICATION_DOC_FAILED]:
    'Verification Document Failed',
  [PUNTER_ACTIVITY_LOG_TYPE.VERIFICATION_DOC_REQUIRED]:
    'Verification Document Required',
  [PUNTER_ACTIVITY_LOG_TYPE.VERIFICATION_DOC_SUCCESS]:
    'Verification Document Success',
  [PUNTER_ACTIVITY_LOG_TYPE.VERIFICATION_FAILED]: 'Verification Failed',
  [PUNTER_ACTIVITY_LOG_TYPE.VERIFICATION_SUCCESS]: 'Verification Success',
};

export const AU_STATES_ARRAY = [
  'VIC',
  'NSW',
  'QLD',
  'SA',
  'WA',
  'NT',
  'ACT',
  'TAS',
];

export const AU_STATES_ARRAY_LONG = [
  'Tasmania',
  'Victoria',
  'New South Wales',
  'Queensland',
  'South Australia',
  'Western Australia',
  'Northern Territory',
  'Australian Capital Territory',
];

export const RACE_TYPES_OBJECTS = [
  { raceType: RacingType.HORSE_RACING },
  { raceType: RacingType.GREYHOUNDS },
  { raceType: RacingType.HARNESS },
];
export const RESULTED_STATUSES = [
  { value: 'All', label: 'All' },
  { value: 'Upcoming', label: 'Upcoming' },
  { value: 'Resulted', label: 'Resulted' },
];

// TODO: First item had no probability in source sheet, check index 0 === 100%
export const MARGIN_TICK_MAP = [
  { tickIndex: 0, odds: 1.01 },
  { tickIndex: 1, odds: 1.02 },
  { tickIndex: 2, odds: 1.03 },
  { tickIndex: 3, odds: 1.04 },
  { tickIndex: 4, odds: 1.05 },
  { tickIndex: 5, odds: 1.06 },
  { tickIndex: 6, odds: 1.07 },
  { tickIndex: 7, odds: 1.08 },
  { tickIndex: 8, odds: 1.09 },
  { tickIndex: 9, odds: 1.1 },
  { tickIndex: 10, odds: 1.11 },
  { tickIndex: 11, odds: 1.12 },
  { tickIndex: 12, odds: 1.13 },
  { tickIndex: 13, odds: 1.14 },
  { tickIndex: 14, odds: 1.15 },
  { tickIndex: 15, odds: 1.16 },
  { tickIndex: 16, odds: 1.17 },
  { tickIndex: 17, odds: 1.18 },
  { tickIndex: 18, odds: 1.19 },
  { tickIndex: 19, odds: 1.2 },
  { tickIndex: 20, odds: 1.22 },
  { tickIndex: 21, odds: 1.24 },
  { tickIndex: 22, odds: 1.26 },
  { tickIndex: 23, odds: 1.28 },
  { tickIndex: 24, odds: 1.3 },
  { tickIndex: 25, odds: 1.32 },
  { tickIndex: 26, odds: 1.34 },
  { tickIndex: 27, odds: 1.36 },
  { tickIndex: 28, odds: 1.38 },
  { tickIndex: 29, odds: 1.4 },
  { tickIndex: 30, odds: 1.42 },
  { tickIndex: 31, odds: 1.44 },
  { tickIndex: 32, odds: 1.46 },
  { tickIndex: 33, odds: 1.48 },
  { tickIndex: 34, odds: 1.5 },
  { tickIndex: 35, odds: 1.52 },
  { tickIndex: 36, odds: 1.54 },
  { tickIndex: 37, odds: 1.56 },
  { tickIndex: 38, odds: 1.58 },
  { tickIndex: 39, odds: 1.6 },
  { tickIndex: 40, odds: 1.62 },
  { tickIndex: 41, odds: 1.64 },
  { tickIndex: 42, odds: 1.66 },
  { tickIndex: 43, odds: 1.68 },
  { tickIndex: 44, odds: 1.7 },
  { tickIndex: 45, odds: 1.72 },
  { tickIndex: 46, odds: 1.74 },
  { tickIndex: 47, odds: 1.76 },
  { tickIndex: 48, odds: 1.78 },
  { tickIndex: 49, odds: 1.8 },
  { tickIndex: 50, odds: 1.82 },
  { tickIndex: 51, odds: 1.84 },
  { tickIndex: 52, odds: 1.86 },
  { tickIndex: 53, odds: 1.88 },
  { tickIndex: 54, odds: 1.9 },
  { tickIndex: 55, odds: 1.92 },
  { tickIndex: 56, odds: 1.94 },
  { tickIndex: 57, odds: 1.96 },
  { tickIndex: 58, odds: 1.98 },
  { tickIndex: 59, odds: 2 },
  { tickIndex: 60, odds: 2.05 },
  { tickIndex: 61, odds: 2.1 },
  { tickIndex: 62, odds: 2.15 },
  { tickIndex: 63, odds: 2.2 },
  { tickIndex: 64, odds: 2.25 },
  { tickIndex: 65, odds: 2.3 },
  { tickIndex: 66, odds: 2.35 },
  { tickIndex: 67, odds: 2.4 },
  { tickIndex: 68, odds: 2.45 },
  { tickIndex: 69, odds: 2.5 },
  { tickIndex: 70, odds: 2.55 },
  { tickIndex: 71, odds: 2.6 },
  { tickIndex: 72, odds: 2.65 },
  { tickIndex: 73, odds: 2.7 },
  { tickIndex: 74, odds: 2.75 },
  { tickIndex: 75, odds: 2.8 },
  { tickIndex: 76, odds: 2.85 },
  { tickIndex: 77, odds: 2.9 },
  { tickIndex: 78, odds: 2.95 },
  { tickIndex: 79, odds: 3 },
  { tickIndex: 80, odds: 3.1 },
  { tickIndex: 81, odds: 3.2 },
  { tickIndex: 82, odds: 3.3 },
  { tickIndex: 83, odds: 3.4 },
  { tickIndex: 84, odds: 3.5 },
  { tickIndex: 85, odds: 3.6 },
  { tickIndex: 86, odds: 3.7 },
  { tickIndex: 87, odds: 3.8 },
  { tickIndex: 88, odds: 3.9 },
  { tickIndex: 89, odds: 4 },
  { tickIndex: 90, odds: 4.1 },
  { tickIndex: 91, odds: 4.2 },
  { tickIndex: 92, odds: 4.3 },
  { tickIndex: 93, odds: 4.4 },
  { tickIndex: 94, odds: 4.5 },
  { tickIndex: 95, odds: 4.6 },
  { tickIndex: 96, odds: 4.7 },
  { tickIndex: 97, odds: 4.8 },
  { tickIndex: 98, odds: 4.9 },
  { tickIndex: 99, odds: 5 },
  { tickIndex: 100, odds: 5.25 },
  { tickIndex: 101, odds: 5.5 },
  { tickIndex: 102, odds: 5.75 },
  { tickIndex: 103, odds: 6 },
  { tickIndex: 104, odds: 6.25 },
  { tickIndex: 105, odds: 6.5 },
  { tickIndex: 106, odds: 6.75 },
  { tickIndex: 107, odds: 7 },
  { tickIndex: 108, odds: 7.25 },
  { tickIndex: 109, odds: 7.5 },
  { tickIndex: 110, odds: 7.75 },
  { tickIndex: 111, odds: 8 },
  { tickIndex: 112, odds: 8.25 },
  { tickIndex: 113, odds: 8.5 },
  { tickIndex: 114, odds: 8.75 },
  { tickIndex: 115, odds: 9 },
  { tickIndex: 116, odds: 9.25 },
  { tickIndex: 117, odds: 9.5 },
  { tickIndex: 118, odds: 9.75 },
  { tickIndex: 119, odds: 10 },
  { tickIndex: 120, odds: 10.5 },
  { tickIndex: 121, odds: 11 },
  { tickIndex: 122, odds: 11.5 },
  { tickIndex: 123, odds: 12 },
  { tickIndex: 124, odds: 12.5 },
  { tickIndex: 125, odds: 13 },
  { tickIndex: 126, odds: 13.5 },
  { tickIndex: 127, odds: 14 },
  { tickIndex: 128, odds: 14.5 },
  { tickIndex: 129, odds: 15 },
  { tickIndex: 130, odds: 15.5 },
  { tickIndex: 131, odds: 16 },
  { tickIndex: 132, odds: 16.5 },
  { tickIndex: 133, odds: 17 },
  { tickIndex: 134, odds: 17.5 },
  { tickIndex: 135, odds: 18 },
  { tickIndex: 136, odds: 18.5 },
  { tickIndex: 137, odds: 19 },
  { tickIndex: 138, odds: 19.5 },
  { tickIndex: 139, odds: 20 },
  { tickIndex: 140, odds: 21 },
  { tickIndex: 141, odds: 22 },
  { tickIndex: 142, odds: 23 },
  { tickIndex: 143, odds: 24 },
  { tickIndex: 144, odds: 25 },
  { tickIndex: 145, odds: 26 },
  { tickIndex: 146, odds: 27 },
  { tickIndex: 147, odds: 28 },
  { tickIndex: 148, odds: 29 },
  { tickIndex: 149, odds: 30 },
  { tickIndex: 150, odds: 31 },
  { tickIndex: 151, odds: 32 },
  { tickIndex: 152, odds: 33 },
  { tickIndex: 153, odds: 34 },
  { tickIndex: 154, odds: 35 },
  { tickIndex: 155, odds: 36 },
  { tickIndex: 156, odds: 37 },
  { tickIndex: 157, odds: 38 },
  { tickIndex: 158, odds: 39 },
  { tickIndex: 159, odds: 40 },
  { tickIndex: 160, odds: 41 },
  { tickIndex: 161, odds: 46 },
  { tickIndex: 162, odds: 51 },
  { tickIndex: 163, odds: 56 },
  { tickIndex: 164, odds: 61 },
  { tickIndex: 165, odds: 66 },
  { tickIndex: 166, odds: 71 },
  { tickIndex: 167, odds: 76 },
  { tickIndex: 168, odds: 81 },
  { tickIndex: 169, odds: 86 },
  { tickIndex: 170, odds: 91 },
  { tickIndex: 171, odds: 96 },
  { tickIndex: 172, odds: 101 },
  { tickIndex: 173, odds: 111 },
  { tickIndex: 174, odds: 121 },
  { tickIndex: 175, odds: 131 },
  { tickIndex: 176, odds: 141 },
  { tickIndex: 177, odds: 151 },
  { tickIndex: 178, odds: 161 },
  { tickIndex: 179, odds: 171 },
  { tickIndex: 180, odds: 181 },
  { tickIndex: 181, odds: 191 },
  { tickIndex: 182, odds: 201 },
  { tickIndex: 183, odds: 251 },
  { tickIndex: 184, odds: 301 },
  { tickIndex: 185, odds: 351 },
  { tickIndex: 186, odds: 401 },
  { tickIndex: 187, odds: 451 },
  { tickIndex: 188, odds: 501 },
  { tickIndex: 189, odds: 601 },
  { tickIndex: 190, odds: 701 },
  { tickIndex: 191, odds: 801 },
  { tickIndex: 192, odds: 901 },
  { tickIndex: 193, odds: 1001 },
];

export const ordinals: { [key: string]: string } = {
  1: 'st',
  2: 'nd',
  3: 'rd',
  other: 'th',
};

export const ISO_COUNTRIES: { [key: string]: string } = {
  AFG: 'Afghanistan',
  ALA: 'Aland Islands',
  ALB: 'Albania',
  DZA: 'Algeria',
  ASM: 'American Samoa',
  AND: 'Andorra',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ATA: 'Antarctica',
  ATG: 'Antigua And Barbuda',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ABW: 'Aruba',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BHS: 'Bahamas',
  BHR: 'Bahrain',
  BGD: 'Bangladesh',
  BRB: 'Barbados',
  BLR: 'Belarus',
  BEL: 'Belgium',
  BLZ: 'Belize',
  BEN: 'Benin',
  BMU: 'Bermuda',
  BTN: 'Bhutan',
  BOL: 'Bolivia',
  BIH: 'Bosnia And Herzegovina',
  BWA: 'Botswana',
  BVT: 'Bouvet Island',
  BRA: 'Brazil',
  IOT: 'British Indian Ocean Territory',
  BRN: 'Brunei Darussalam',
  BGR: 'Bulgaria',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  CPV: 'Cape Verde',
  KHM: 'Cambodia',
  CMR: 'Cameroon',
  CAN: 'Canada',
  CYM: 'Cayman Islands',
  CAF: 'Central African Republic',
  TCD: 'Chad',
  CHL: 'Chile',
  CHN: 'China',
  CXR: 'Christmas Island',
  CCK: 'Cocos (Keeling) Islands',
  COL: 'Colombia',
  COM: 'Comoros',
  COG: 'Congo',
  COD: 'Congo, Democratic Republic',
  COK: 'Cook Islands',
  CRI: 'Costa Rica',
  CIV: "Cote D'Ivoire",
  HRV: 'Croatia',
  CUB: 'Cuba',
  CYP: 'Cyprus',
  CZE: 'Czech Republic',
  DNK: 'Denmark',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  SLV: 'El Salvador',
  GNQ: 'Equatorial Guinea',
  ERI: 'Eritrea',
  EST: 'Estonia',
  ETH: 'Ethiopia',
  FLK: 'Falkland Islands (Malvinas)',
  FRO: 'Faroe Islands',
  FJI: 'Fiji',
  FIN: 'Finland',
  FRA: 'France',
  GUF: 'French Guiana',
  PYF: 'French Polynesia',
  ATF: 'French Southern Territories',
  GAB: 'Gabon',
  GMB: 'Gambia',
  GEO: 'Georgia',
  DEU: 'Germany',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GRC: 'Greece',
  GRL: 'Greenland',
  GRD: 'Grenada',
  GLP: 'Guadeloupe',
  GUM: 'Guam',
  GTM: 'Guatemala',
  GGY: 'Guernsey',
  GIN: 'Guinea',
  GNB: 'Guinea-Bissau',
  GUY: 'Guyana',
  HTI: 'Haiti',
  HMD: 'Heard Island & Mcdonald Islands',
  VAT: 'Holy See (Vatican City State)',
  HND: 'Honduras',
  HKG: 'Hong Kong',
  HUN: 'Hungary',
  ISL: 'Iceland',
  IND: 'India',
  IDN: 'Indonesia',
  IRN: 'Iran, Islamic Republic Of',
  IRQ: 'Iraq',
  IRL: 'Ireland',
  IMN: 'Isle Of Man',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JPN: 'Japan',
  JEY: 'Jersey',
  JOR: 'Jordan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KIR: 'Kiribati',
  KOR: 'Korea',
  KWT: 'Kuwait',
  KGZ: 'Kyrgyzstan',
  LAO: "Lao People's Democratic Republic",
  LVA: 'Latvia',
  LBN: 'Lebanon',
  LSO: 'Lesotho',
  LBR: 'Liberia',
  LBY: 'Libyan Arab Jamahiriya',
  LIE: 'Liechtenstein',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  MAC: 'Macao',
  MKD: 'Macedonia',
  MDG: 'Madagascar',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malta',
  MHL: 'Marshall Islands',
  MTQ: 'Martinique',
  MRT: 'Mauritania',
  MUS: 'Mauritius',
  MYT: 'Mayotte',
  MEX: 'Mexico',
  FSM: 'Micronesia, Federated States Of',
  MDA: 'Moldova',
  MCO: 'Monaco',
  MNG: 'Mongolia',
  MNE: 'Montenegro',
  MSR: 'Montserrat',
  MAR: 'Morocco',
  MOZ: 'Mozambique',
  MMR: 'Myanmar',
  NAM: 'Namibia',
  NRU: 'Nauru',
  NPL: 'Nepal',
  NLD: 'Netherlands',
  NCL: 'New Caledonia',
  NZL: 'New Zealand',
  NIC: 'Nicaragua',
  NER: 'Niger',
  NGA: 'Nigeria',
  NIU: 'Niue',
  NFK: 'Norfolk Island',
  MNP: 'Northern Mariana Islands',
  NOR: 'Norway',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PLW: 'Palau',
  PSE: 'Palestinian Territory, Occupied',
  PAN: 'Panama',
  PNG: 'Papua New Guinea',
  PRY: 'Paraguay',
  PER: 'Peru',
  PHL: 'Philippines',
  PCN: 'Pitcairn',
  POL: 'Poland',
  PRT: 'Portugal',
  PRI: 'Puerto Rico',
  QAT: 'Qatar',
  REU: 'Reunion',
  ROU: 'Romania',
  RUS: 'Russian Federation',
  RWA: 'Rwanda',
  BLM: 'Saint Barthelemy',
  SHN: 'Saint Helena',
  KNA: 'Saint Kitts And Nevis',
  LCA: 'Saint Lucia',
  MAF: 'Saint Martin',
  SPM: 'Saint Pierre And Miquelon',
  VCT: 'Saint Vincent And Grenadines',
  WSM: 'Samoa',
  SMR: 'San Marino',
  STP: 'Sao Tome And Principe',
  SAU: 'Saudi Arabia',
  SEN: 'Senegal',
  SRB: 'Serbia',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapore',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SLB: 'Solomon Islands',
  SOM: 'Somalia',
  ZAF: 'South Africa',
  SGS: 'South Georgia And Sandwich Isl.',
  ESP: 'Spain',
  LKA: 'Sri Lanka',
  SDN: 'Sudan',
  SUR: 'Suriname',
  SJM: 'Svalbard And Jan Mayen',
  SWZ: 'Swaziland',
  SWE: 'Sweden',
  CHE: 'Switzerland',
  SYR: 'Syrian Arab Republic',
  TWN: 'Taiwan',
  TJK: 'Tajikistan',
  TZA: 'Tanzania',
  THA: 'Thailand',
  TLS: 'Timor-Leste',
  TGO: 'Togo',
  TKL: 'Tokelau',
  TON: 'Tonga',
  TTO: 'Trinidad And Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TKM: 'Turkmenistan',
  TCA: 'Turks And Caicos Islands',
  TUV: 'Tuvalu',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  UAE: 'United Arab Emirates',
  GBR: 'United Kingdom',
  USA: 'United States',
  UMI: 'United States Outlying Islands',
  URY: 'Uruguay',
  UZB: 'Uzbekistan',
  VUT: 'Vanuatu',
  VEN: 'Venezuela',
  VNM: 'Viet Nam',
  VGB: 'Virgin Islands, British',
  VIR: 'Virgin Islands, U.S.',
  WLF: 'Wallis And Futuna',
  ESH: 'Western Sahara',
  YEM: 'Yemen',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe',
};

export const FIREBASE_CONFIG = firebaseConfig;
export const ENV = window.BETCLOUD_ENV || process.env;

export enum Currency {
  BCCASH = 'BC_CASH',
  GEMS = 'GEMS',
}
