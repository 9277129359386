import { MoneyDollarCircle } from '@styled-icons/remix-line/MoneyDollarCircle';
import { Home } from '@styled-icons/ionicons-outline/Home';
import { TaskListSquareRtl } from '@styled-icons/fluentui-system-regular/TaskListSquareRtl';
import { DoneAll } from '@styled-icons/material-outlined/DoneAll';
import { BuildingBank } from '@styled-icons/fluentui-system-regular/BuildingBank';
import { UserSearch } from '@styled-icons/remix-line/UserSearch';
import { Settings } from '@styled-icons/ionicons-outline/Settings';
import { BookInformation } from '@styled-icons/fluentui-system-regular/BookInformation';
import { StyledIcon } from 'styled-icons/types';
import { DocumentReport } from '@styled-icons/heroicons-solid/DocumentReport';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

type TNavigationLink = {
  name: string;
  icon: StyledIcon;
  href: string;
  dataCyValue: string;
  id: string;
};

export const links: TNavigationLink[] = [
  FEATURE_FLAGS.IS_DASHBOARD_ENABLED && {
    name: 'navigations.dashboard',
    icon: Dashboard,
    href: '/dashboard',
    dataCyValue: 'tradeManagerLink',
    id: 'scoreboard_view',
  },
  {
    name: 'navigations.scoreboard',
    icon: DocumentReport,
    href: '/scoreboard',
    dataCyValue: 'tradeManagerLink',
    id: 'scoreboard_view',
  },
  {
    name: 'navigations.riskmanager',
    icon: Home,
    href: '/trade-manager',
    dataCyValue: 'tradeManagerLink',
    id: 'risk_manager_home_view',
  },
  {
    name: 'navigations.betapprovals',
    icon: TaskListSquareRtl,
    href: '/bet-approvals',
    dataCyValue: 'betApprovalsLink',
    id: 'bet_approvals_approvals_view',
  },
  {
    name: 'navigations.placedbets',
    icon: DoneAll,
    href: '/placed-bets',
    dataCyValue: 'placedbetsLink',
    id: 'placed_bets_upcoming_view',
  },
  {
    name: 'navigations.bank',
    icon: BuildingBank,
    href: '/bank',
    dataCyValue: 'bankLink',
    id: 'bank_history_view',
  },
  {
    name: 'navigations.promomanager',
    icon: MoneyDollarCircle,
    href: '/promo-manager',
    dataCyValue: 'promoManagerLink',
    id: 'promo_manager_all_promos_view',
  },
  {
    name: 'navigations.punters',
    icon: UserSearch,
    href: '/punters',
    dataCyValue: 'puntersLink',
    id: 'punters_punter_profile_view',
  },
  {
    name: 'navigations.settings',
    icon: Settings,
    href: '/settings',
    dataCyValue: 'settingsLink',
    id: 'settings_your_profile_view',
  },
  {
    name: 'navigations.regulator',
    icon: BookInformation,
    href: '/regulator',
    dataCyValue: 'regulatorLink',
    id: 'nt_ial_view',
  },
];
