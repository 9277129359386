import React from 'react';
import { ModalBody, Text, Tr, Th, Td, Switch } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import TableChakraConfigure from '../../../../../../../../common/components/TableChakraConfigure/TableChakraConfigure';
import { TStatusFormValues } from '../Status.types';
import Select from '@/common/components/FormElements/Select';
import { EGeneralStatus } from '@/lib/DBModels';

type TForm = {
  values: TStatusFormValues;
  setRaceStatus: (id: string, status: EGeneralStatus) => void;
  setRaceFollowFeed: (
    id: string,
    status: EGeneralStatus,
    feed: boolean
  ) => void;
  setRaceSettleByFeed: (id: string, feed: boolean) => void;
  setMarketStatus: (i: number, feed: EGeneralStatus) => void;
  setMarketFollowFeed: (
    id: number,
    status: EGeneralStatus,
    feed: boolean
  ) => void;
  setMarketSettleByFeed: (id: number, feed: boolean) => void;
};

const convertStatus = (status: string) => {
  if (status === EGeneralStatus.Settled) {
    return EGeneralStatus.Closed;
  }
  if (status === EGeneralStatus.Abandoned) {
    return EGeneralStatus.Voided;
  }
  return status;
};

export default function Form({
  values,
  setRaceStatus,
  setRaceFollowFeed,
  setRaceSettleByFeed,
  setMarketStatus,
  setMarketFollowFeed,
  setMarketSettleByFeed,
}: TForm) {
  return (
    <ModalBody
      sx={{
        pt: `0 !important`,
        minHeight: '20',
        '&&': { pb: '2' },
      }}
    >
      <TableChakraConfigure
        variant="simple"
        size="sm"
        header={[
          <Th key="race-status" textAlign="center">
            <FormattedMessage id="trademanagerpage.common.status" />
          </Th>,
          <Th key="race-follow-winter" textAlign="center">
            <FormattedMessage id="trademanagerpage.common.followFeed" />
          </Th>,
          <Th key="race-follow-winter-settlement" textAlign="center">
            <FormattedMessage id="trademanagerpage.common.settleByFeed" />
          </Th>,
        ]}
      >
        <Tr bg="blue.50">
          <Td p="0">
            <Text textAlign="left" fontWeight="500" color="gray.600">
              <FormattedMessage id="trademanagerpage.config.race" />
            </Text>
          </Td>
          <Td>
            <Select
              name="race-status-dropdown"
              value={
                values.race.status === EGeneralStatus.Settled
                  ? EGeneralStatus.Closed
                  : values.race.status
              }
              onChange={(e) =>
                setRaceStatus(values.race.id, e.target.value as EGeneralStatus)
              }
            >
              {values.race.allowedStatus.map((status: EGeneralStatus) => (
                <option key={`race-status-${status}`} value={status}>
                  {status}
                </option>
              ))}
            </Select>
          </Td>
          <Td>
            <Switch
              isChecked={values.race.follow_feed}
              onChange={() =>
                setRaceFollowFeed(
                  values.race.id,
                  values.race.status,
                  !values.race.follow_feed
                )
              }
            />
          </Td>
          <Td>
            <Switch
              isChecked={values.race.settle_by_feed}
              onChange={() =>
                setRaceSettleByFeed(values.race.id, !values.race.settle_by_feed)
              }
            />
          </Td>
        </Tr>
        {values.markets.map(
          ({ name, status, follow_feed, settle_by_feed, allowedStatus }, i) => (
            <Tr key={`market-${name}`}>
              <Td p="0">
                <Text textAlign="left">{name}</Text>
              </Td>
              <Td>
                <Select
                  name="market-status-dropdown"
                  value={convertStatus(status)}
                  onChange={(e) =>
                    setMarketStatus(i, e.target.value as EGeneralStatus)
                  }
                >
                  {allowedStatus.map((status: EGeneralStatus) => (
                    <option
                      key={`market-${name}-status-${status}`}
                      value={status}
                    >
                      {status}
                    </option>
                  ))}
                </Select>
              </Td>
              <Td>
                <Switch
                  isChecked={follow_feed}
                  onChange={() => setMarketFollowFeed(i, status, !follow_feed)}
                />
              </Td>
              <Td>
                <Switch
                  isChecked={settle_by_feed}
                  onChange={() => setMarketSettleByFeed(i, !settle_by_feed)}
                />
              </Td>
            </Tr>
          )
        )}
      </TableChakraConfigure>
    </ModalBody>
  );
}
