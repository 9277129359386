import { Text, chakra, ChakraComponent, TextProps } from '@chakra-ui/react';

export const TextTableHeader = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    p: '3',
    align: 'center',
    casing: 'uppercase',
    fontWeight: 'normal',
  }),
}) as ChakraComponent<'p', TextProps>;

export const TextTableCell = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'xs',
    p: '3',
    align: 'left',
  }),
}) as ChakraComponent<'p', TextProps>;
