import React from 'react';
import { centsToDollars } from '@/common/utils';
import {
  RaceDetailsStatBoxContainer,
  StyledStatBox,
  SmallStatBox,
} from '../../RaceDetailsPage.styles';
import { TRaceDetails, TRunnerResult } from '@/lib/DBModels';

type TRaceStats = {
  raceData: TRaceDetails;
  raceResults?: TRunnerResult[];
};

export default function RaceStats({ raceData, raceResults }: TRaceStats) {
  const raceWinner = raceResults?.find((el) => el.result_place === '1');

  const winPrice = raceData?.runners?.find(
    (el) => el?.race_runner_id === raceWinner?.race_runner_id
  );

  return (
    <>
      <RaceDetailsStatBoxContainer>
        <StyledStatBox
          label="Bets"
          value={raceData?.stats?.total_bets ?? '0'}
        />

        <StyledStatBox
          label="Turnover"
          value={centsToDollars(raceData?.stats?.turnover)}
        />

        <StyledStatBox
          label="SRM Bets"
          value={raceData?.stats?.srm_total_bets || 0}
        />

        <StyledStatBox
          label="SRM Turnover"
          value={centsToDollars(raceData?.stats?.srm_turnover)}
        />

        <SmallStatBox
          label="Win"
          value={centsToDollars(raceData?.stats?.win_turnover)}
          result={winPrice?.win_result}
        />
        <SmallStatBox
          label="Place"
          value={centsToDollars(raceData?.stats?.place_turnover)}
        />

        <SmallStatBox
          label="Exotics"
          value={centsToDollars(raceData?.stats?.exotic_turnover)}
        />

        <SmallStatBox
          label="Quaddies"
          value={centsToDollars(raceData?.stats?.tote_multi_turnover)}
        />
      </RaceDetailsStatBoxContainer>
    </>
  );
}
