import { useState } from 'react';
import { querySGMCombinationPunters } from '@/api/sgm/combinations/combinations';
import { useQuery } from 'react-query';
import { keys } from '@/api/api.keys';

export const useSGMCombinationPunters = (multi_uid: string) => {
  const limit = 20;
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState<string | undefined>();
  const [offsetHistory] = useState<string[]>([]);

  const shouldRefetch = pageIndex === 0;

  const params = {
    limit,
    multi_uid: multi_uid,
    ...(offset ? { offset } : {}),
  };

  const { data, isLoading } = useQuery(
    [keys.srmCombinationPunters, multi_uid, ...Object.values(params)],
    () => querySGMCombinationPunters(params),
    {
      enabled: !!multi_uid && multi_uid !== '',
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
      staleTime: shouldRefetch ? 0 : 60 * 5000,
    }
  );

  const next = () => {
    if (!data) return;
    setOffset(data.paging?.next_offset ?? '');
    setPageIndex((prevIdx) => ++prevIdx);
  };
  const previous = () => {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  };

  return {
    data,
    isLoading,
    next,
    previous,
    previousDisabled: pageIndex === 0,
    nextDisabled: data?.paging?.next_offset === null,
    hidePagination: pageIndex === 0 && data?.paging?.next_offset === null,
  };
};
