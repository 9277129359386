import React from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient, useMutation } from 'react-query';
import { FormattedMessage } from 'react-intl';
import useErrorHandler from '@/hooks/useErrorHandler';
import { keys } from '@/api/api.keys';
import { deactivateAffiliate } from '@/api/promoManager/affiliates/affiliates';
import { useFormik } from 'formik';
import { ModalBodyText } from '@/common/components/DeleteModal/Styles';

export const DeactivateAffiliate = ({
  selectedAffiliateForDeactivation,
  setSelectedAffiliateForDeactivation,
}: {
  selectedAffiliateForDeactivation: string | undefined;
  setSelectedAffiliateForDeactivation: (affiliate: string | undefined) => void;
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const onError = useErrorHandler();

  const { mutateAsync } = useMutation(deactivateAffiliate, {
    onSuccess: () => {
      toast({
        title: 'Affiliate deactivated',
        status: 'success',
        position: 'top',
        duration: 9_000,
      });
    },
    onError,
  });

  const handleClose = () => {
    setSelectedAffiliateForDeactivation(undefined);
  };

  const { handleSubmit } = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        if (!selectedAffiliateForDeactivation) return;

        await mutateAsync(selectedAffiliateForDeactivation);
        queryClient.invalidateQueries([keys.affiliates]);
        handleClose();
      } catch {}
    },
  });

  return (
    <Modal isOpen={!!selectedAffiliateForDeactivation} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Deactivate Affiliate</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBodyText>
            Are you sure you want to deactivate{' '}
            {selectedAffiliateForDeactivation}? This will include expiring any
            existing promotions they may currently have available.
          </ModalBodyText>
          <ModalFooter>
            <Button variant="ghost" mr="3" onClick={handleClose}>
              <FormattedMessage id="punterPage.incentives.cancelButton" />
            </Button>
            <Button type="submit" colorScheme="red" isLoading={false}>
              <FormattedMessage id="punterPage.incentives.confirmButton" />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
