import React from 'react';
import { Flex, Image, Text, Box } from '@chakra-ui/react';
import SRMLogo from '@/assets/icons/srm-icon.svg';
import { BonusBet } from '../../EventMarketInfo/EventMarketInfo.styles';
import { GiftPack } from '@/assets/icons';
import Countdown from '@/components/Countdown/Countdown';

interface IMarketInfoSRMProps {
  legs: number;
  timeStamp: string;
  isBonusBet: boolean;
  venue: string;
  raceNumber: string;
  items: {
    runnerNumber: number;
    runnerName: number;
    position: string;
  }[];
}

export function MarketInfoSRM({
  legs,
  timeStamp,
  isBonusBet,
  venue,
  raceNumber,
  items,
}: IMarketInfoSRMProps) {
  return (
    <Box>
      <Flex>
        <Text fontWeight="bold">{legs} Leg Multi </Text>
        <Image src={SRMLogo} ml="1" alt="Same Game Multi Icon" />
      </Flex>
      <Box
        sx={{
          '& > div': {
            justifyContent: 'left',
          },
        }}
      >
        <Countdown eventTime={timeStamp} />
      </Box>
      {isBonusBet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>Bonus</Text>
        </BonusBet>
      )}
      <Text>{`${venue} R${raceNumber}`}</Text>
      {items.map((item) => (
        <Text
          key={item.runnerNumber}
        >{`${item.runnerNumber}.  - ${item.runnerName} - ${item.position}`}</Text>
      ))}
    </Box>
  );
}
