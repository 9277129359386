import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import * as Yup from 'yup';
import { FormikContextType } from 'formik';
import { ESettleOptions, TSettleFormValues } from '../Settlement.types';
import { useMutationSettleSportMarketPropositions } from '@/api/tradeManager/sportDetails/settleSportMarketPropositions/settleSportMarketPropositions.hooks';
import { EResult } from '@/api/tradeManager/sportDetails/settleSportMarketPropositions/settleSportMarketPropositions.types';
import { useIntl } from 'react-intl';
import { useMutationIgnoreWinterSettlementSport } from '@/api/tradeManager/sportDetails/setIgnoreWinterSettlement/setIgnoreWinterSettlement.hooks';
import { useModalConfigure } from '../../../services/ModalConfigure.hooks';
import { setIsModalConfigureLoading } from '../../../../../slices';
import {
  getMarkets,
  getMatch,
} from '@/features/tradeManager/pages/MatchDetails/api';
import { EConfigureLevel } from '@/features/tradeManager/pages/MatchDetails/types';

export const useSettle = (context?: FormikContextType<TSettleFormValues>) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { onModalConfigureClose } = useModalConfigure();

  const { propositions, market, match, configureLevel } = useAppSelector(
    (state) => state.matchDetails.modalConfigureData
  );

  const { selectedMarketGroup } = useAppSelector((state) => state.matchDetails);

  const { mutateAsync: mutateSettleSportMarketPropositions } =
    useMutationSettleSportMarketPropositions();

  const { mutateAsync: mutateIgnoreWinterSettlementSport } =
    useMutationIgnoreWinterSettlementSport();

  const handleCheckBoxChange = (value: string) => {
    // Update propositions array based on selected value
    const updatedPropositions = context?.values.propositions.map((p) => {
      if (p.proposition_id === value) {
        return {
          ...p,
          result: p.result === EResult.Loser ? EResult.Winner : EResult.Loser,
        };
      }
      return p;
    });

    // Update form values
    context?.setFieldValue('propositions', updatedPropositions);
  };

  const handleNoWinCheckBoxChange = () => {
    const updatedNoWinPropositions = context?.values.propositions.map((p) => {
      p.result = EResult.Loser;
      return p;
    });
    context?.setFieldValue('propositions', updatedNoWinPropositions);
    context?.setFieldValue('no_win', true);
  };

  const initialValues = {
    settle_option:
      market?.ignore_winter_settlement ||
      (configureLevel === EConfigureLevel.Match &&
        match?.ignore_winter_settlement)
        ? ESettleOptions.Manual
        : ESettleOptions.Winter,
    expected_winners: '1',
    propositions:
      propositions?.map((p) => ({
        proposition_id: p.proposition_id ?? '',
        result: EResult.Loser,
      })) ?? [],
    no_win: false,
  };

  const onSubmit = async (values: TSettleFormValues) => {
    dispatch(setIsModalConfigureLoading(true));
    const { expected_winners, propositions } = values;

    await mutateIgnoreWinterSettlementSport({
      match_id: match?.match_id,
      market_ids:
        configureLevel === EConfigureLevel.Match ? [] : [market?.market_id],
      ignore_winter_settlement: values.settle_option === ESettleOptions.Manual,
    });

    /*
     * We also don't want to settle on match level, because the proposiitons will be losers by default we won't call the API
     */
    if (
      values.settle_option === ESettleOptions.Manual && // Manual settlement selected
      !!values.propositions.length // Not on match level
    ) {
      await mutateSettleSportMarketPropositions({
        market_id: market?.market_id,
        expected_winners: Number(expected_winners),
        propositions,
      });
    }

    await dispatch(
      getMarkets({
        matchId: match?.match_id ?? '',
        marketGroupId: selectedMarketGroup?.market_group_id ?? '',
      })
    );

    await dispatch(
      getMatch({ matchId: match?.match_id ?? '', isUpdate: true })
    );

    dispatch(setIsModalConfigureLoading(false));
    onModalConfigureClose();
  };

  const validationSchema = Yup.object().shape({
    expected_winners: Yup.number().when('settle_option', {
      is: ESettleOptions.Winter,
      then: Yup.number(),
      otherwise: Yup.number().integer().min(0).required(),
    }),
    propositions: Yup.array(),
  });

  const settleOptions = [
    {
      option: ESettleOptions.Winter,
      tooltip: intl.formatMessage({
        id: 'trademanagerpage.config.settle.settletype.tooltip.winter',
      }),
    },
    {
      option: ESettleOptions.Manual,
      tooltip: intl.formatMessage({
        id: 'trademanagerpage.config.settle.settletype.tooltip.manual',
      }),
    },
  ];

  // Show manual settings if we are on market level and market is manual
  const showManualSettings =
    configureLevel === EConfigureLevel.Market &&
    context?.values.settle_option === ESettleOptions.Manual;

  return {
    intl,
    match,
    settleOptions,
    propositions,
    propositionsFormValues: context?.values.propositions,
    initialValues,
    validationSchema,
    showManualSettings,
    shouldValidate: context?.values.settle_option === ESettleOptions.Manual,
    onSubmit,
    handleCheckBoxChange,
    handleNoWinCheckBoxChange,
  };
};
