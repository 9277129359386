import { faker } from '@faker-js/faker';
import {
  TQueryTokenHistoryParams,
  TQueryTokenHistoryResponse,
} from './tokenHistory.types';

export function makeData({
  limit,
}: TQueryTokenHistoryParams): TQueryTokenHistoryResponse {
  return {
    token_history: Array(limit)
      .fill('')
      .map(() => ({
        token_count: 1,
        source: faker.helpers.arrayElement(['csv', 'manual', 'storm']),
        updated_at: faker.date.recent().toISOString(),
        updated_by: 'Bookie Dev',
        parameters: {
          values: [
            faker.datatype.number({
              min: 5 * 100,
              max: 50 * 100,
              precision: 5 * 100,
            }),
          ],
          probability: 1,
          input_amount: faker.datatype.number({
            min: 100,
            max: 9_999 * 100,
            precision: 100,
          }),
          multiplier_probabilities: faker.helpers.shuffle([0, 0, 1]),
        },
      })),
    offset: faker.date.recent().toISOString(),
  };
}
