import { keys } from '@/api/api.keys';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryGetMarket } from './getMarket';
import { TGetMarketParams, TMarket } from './getMarket.types';

// Trade Manager - Get Market

type TQueryGetMarketHook = {
  params: TGetMarketParams;
  options?: Omit<UseQueryOptions<TMarket[]>, 'queryKey' | 'queryFn'>;
};

export const useQueryGetMarket = ({ params, options }: TQueryGetMarketHook) =>
  useQuery([keys.getMarket, params], () => queryGetMarket(params), options);
