import { centsToDollars } from '@/common/utils';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

export const useBlendedRunnerColumns = () => {
  const runnerColumns = useMemo<
    ColumnDef<Api['BlendedRaceRunnerStatsItemResponse']>[]
  >(
    () => [
      {
        header: 'Name',
        cell: ({ row }) => {
          const { name } = row.original;

          return name;
        },
      },
      {
        id: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => {
          const { bets_count } = row.original;

          return bets_count;
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Number of Punters',
        cell: ({ row }) => {
          const { punters_count } = row.original;

          return punters_count;
        },
      },
      {
        id: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        id: 'odds',
        header: 'Avg Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        id: 'exposure',
        header: 'Avg Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  return { runnerColumns };
};
