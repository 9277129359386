import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { TProposition } from '../../../../../../../api/tradeManager/match/match.types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks/useRedux';
import { getPropositionsForMarket } from '../../../api/markets';
import {
  setIsConfigureMatchModalOpen,
  setIsModalConfigureLoading,
  setModalConfigureData,
  setSelectedConfigTab,
} from '../../../slices';
import { EConfigureLevel, TConfigureModalData } from '../../../types';
import { ETabs } from '../ModalConfigure.types';

export const useModalConfigure = () => {
  const dispatch = useAppDispatch();

  const {
    isModalConfigureLoading,
    modalConfigureData: { configureLevel, match, market, propositions },
    selectedConfigTab,
  } = useAppSelector((state) => state.matchDetails);

  const intl = useIntl();

  const tabButtons = (() => {
    if (configureLevel === EConfigureLevel.Match) {
      return [
        {
          text: intl.formatMessage({
            id: `trademanagerpage.matchDetails.configure.header.void`,
          }),
          option: ETabs.Void,
          onClick: () => dispatch(setSelectedConfigTab(ETabs.Void)),
        },
      ];
    } else {
      return [
        {
          text: intl.formatMessage({
            id: `trademanagerpage.matchDetails.configure.header.settle`,
          }),
          option: ETabs.Settlement,
          onClick: () => dispatch(setSelectedConfigTab(ETabs.Settlement)),
        },
      ];
    }
  })();

  const modalHeading = intl.formatMessage(
    {
      id: `trademanagerpage.matchDetails.configure.header.${(() => {
        if (configureLevel === EConfigureLevel.Match) return 'match';
        if (configureLevel === EConfigureLevel.Market) return 'market';

        return 'proposition';
      })()}`,
    },

    {
      proposition_name: propositions?.length
        ? propositions[0].proposition_name
        : '',
      market_type: market?.market_type,
      market_name: market?.market_name,
    }
  );

  const handleModalConfigureClose = () => {
    dispatch(
      setModalConfigureData({
        configureLevel: undefined,
        match: undefined,
        market: undefined,
        propositions: [],
      })
    );
    dispatch(setIsModalConfigureLoading(false));
  };

  const onModalConfigureOpen = async (input: TConfigureModalData) => {
    if (
      !input.market?.market_id &&
      (!(input.propositions as TProposition[]) ||
        (input.propositions as TProposition[]).every(
          (p) => p.proposition_id
        )) &&
      !input.match?.match_id
    ) {
      // Make sure you can not open the modal with null market_id, match_id or proposition_id
      toast.error(
        intl.formatMessage({
          id: 'generic.error',
        })
      );
      return;
    }

    dispatch(
      setSelectedConfigTab(
        input.configureLevel === EConfigureLevel.Market
          ? ETabs.Settlement
          : ETabs.Void
      )
    );

    let props = input.propositions;

    /* Only on market level we show the settlement modal, which needs all the propositions for market */
    if (
      input.configureLevel === EConfigureLevel.Market &&
      input.market?.market_id
    ) {
      props = await dispatch(
        getPropositionsForMarket({
          marketId: input.market.market_id ?? '',
          isUpdate: true,
        })
      ).unwrap();
    }

    dispatch(
      setModalConfigureData({
        configureLevel: input.configureLevel,
        match: input.match,
        market: input.market,
        propositions: props,
      })
    );
  };

  const modalConfigureToggle = (opened: boolean) =>
    dispatch(setIsConfigureMatchModalOpen(opened));

  return {
    modalHeading,
    selectedTab: selectedConfigTab,
    tabButtons,
    isModalConfigureOpen: !!(match || market || propositions?.length),
    isModalConfigureLoading,
    modalConfigureToggle,
    onModalConfigureClose: handleModalConfigureClose,
    onModalConfigureOpen,
  };
};
