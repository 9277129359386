import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddMatchBody } from './addMatch.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const addMatchURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/add-match`;

// Trade Manager - Add Match

export const mutateAddMatch = (body: TAddMatchBody) =>
  api.post<TMarketCreationResponse>(addMatchURL, body).then((res) => res.data);
