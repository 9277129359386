/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { keys } from '@/api/api.keys';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryRaceResult } from './raceResult';
import { TRaceResultParams, TRaceResultResponse } from './raceResult.types';

type TQueryRaceResultHook = {
  params?: TRaceResultParams;
  key?: unknown[];
  options?: UseQueryOptions<TRaceResultResponse>;
};

export const useQueryRaceResult = ({
  params = {},
  key = [],
  options = {},
}: TQueryRaceResultHook = {}) => {
  const query = useQuery(
    [keys.raceResult, ...Object.values(params), ...key],
    () => queryRaceResult(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
