import api from '../../../api';
import { TVoidMatchBody } from './voidMatch.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const voidMatchURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/void-match`;

// Post: Void Match
export const mutateVoidMatch = (body: TVoidMatchBody) =>
  api.post<TVoidMatchBody>(voidMatchURL, body).then((res) => res.data);
