import React from 'react';
import { Box } from 'grommet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  BackButton,
  LoadingSpinner,
} from '../../../../../../common/components';
import { queryRaceNumbers } from '@/api/tradeManager/raceDetails/raceNumbers/raceNumbers';
import { useQuery } from 'react-query';
import { keys } from '@/api/api.keys';
import { Flex, Tag } from '@chakra-ui/react';
import { TabButton } from '@/common/components/Tabs/styles/Tabs.styles';

export default function RaceNumbers() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const raceId = params.get('id') ?? '';

  const { data, isLoading } = useQuery([keys.raceNumbers], () =>
    queryRaceNumbers({ race_id: raceId })
  );

  return (
    <Box
      direction="row"
      pad={{ top: '5px', bottom: '10px' }}
      gap="15px"
      align="center"
      style={{ position: 'relative', minWidth: '1250px' }}
    >
      {isLoading && <LoadingSpinner />}
      <BackButton onClick={() => navigate(-1)} />
      {data?.data && (
        <Box wrap direction="row" align="center">
          {data?.data.map((x) => (
            <Flex key={x.race_id} flexDir="column" mx="1">
              {!!x.promotions.length && (
                <Tag
                  sx={{
                    fontSize: '2xs',
                    bg: 'teal.500',
                    borderBottomRadius: '0',
                    color: 'white',
                    justifyContent: 'center',
                  }}
                >
                  MB
                </Tag>
              )}

              <TabButton
                onClick={() => navigate(`?id=${x.race_id}`)}
                isActive={x.race_id === raceId}
                isForRace
                sx={{
                  borderTopRadius: !!x.promotions.length && '0',
                  mr: '0',
                  m: 0,
                }}
              >
                R{x.race_number}
              </TabButton>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
}
