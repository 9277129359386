import { rest } from 'msw';
import { punterBets } from './MultiMonitor.mock';
import { ENV } from '@/lib/Constants';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const multiMonitorBets = [
  rest.get(
    `${ENV.REACT_APP_API_URL}/${getPrefixPath(isWincore)}/trade-manager/multi`,
    (req, res, ctx) => res(ctx.delay(), ctx.json(punterBets))
  ),
];
