import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import useErrorHandler from '@/hooks/useErrorHandler';
import { keys } from '@/api/api.keys';
import { useMutationUpdateAffiliate } from '@/api/promoManager/affiliates/affiliates.hooks';
import { TAffiliate } from '@/api/promoManager/affiliates/affiliates.types';
import { AffiliateModal } from './AffiliateModal';

type TUpdateAffiliate = {
  values: TAffiliate;
  setSelectedAffiliateEdit: (val: TAffiliate | undefined) => void;
};

export const UpdateAffiliate = ({
  values,
  setSelectedAffiliateEdit,
}: TUpdateAffiliate) => {
  const onError = useErrorHandler();
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutationUpdateAffiliate({
    onError,
    onSuccess: () => {
      toast({
        title: 'Affiliate updated',
        status: 'success',
        position: 'top',
        duration: 9_000,
      });
      queryClient.invalidateQueries([keys.affiliates]);
    },
  });

  return (
    <AffiliateModal
      modalType={'update'}
      submit={mutation.mutate}
      currentValues={values}
      setSelectedAffiliateEdit={setSelectedAffiliateEdit}
    />
  );
};
