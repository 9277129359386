import { flexRender, Row } from '@tanstack/react-table';
import React, { forwardRef, LegacyRef, ReactNode } from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import { ChevronDown, ChevronUp } from '@styled-icons/evaicons-solid';
import {
  Button,
  Icon,
  Td,
  Tr,
  Badge as ChakraBadge,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';
import { EBetTypes, MarketTableItem } from './MarketTableItem';
import { CommonResponse } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import {
  ActionSchema,
  PunterSchema,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals/Components/List';
import { Punter } from '@/features/betApprovals/pages/BetApprovals/tabs/components/Punter';
import { ErrorBoundary } from 'react-error-boundary';
import { ActionWrapper } from '../BetsTable';
import RaainLogo from '@/assets/betApprovals/raain-logo.png';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { centsToDollars } from '@/common/utils';
import {
  RaainBoxWrapper,
  RaainLogoImage,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.styles';
import { logoFromBookieName } from './utils';
import { MAX_SAFE_BET } from '../../../../features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.hooks';

interface TableRowComponentProps<T> {
  row: Row<T>;
  ref?: LegacyRef<HTMLTableRowElement> | undefined;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  children?: ReactNode;
  onClickRow?: (rowData: T) => void;
  onClickExpand?: (param: T) => void;
  activeId: string | null;
  setActiveId: (id: string | null) => void;
}

export const TableRow = forwardRef<
  HTMLTableRowElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TableRowComponentProps<any>
>(
  (
    {
      row,
      draggableProps,
      dragHandleProps,
      children,
      onClickRow,
      onClickExpand,
      activeId,
      setActiveId,
    },
    ref
  ) => {
    const toggleRowOpen = (id: string | null) =>
      setActiveId(activeId === id ? null : id);

    // Bets without legs (i.e. non-multis) shouldn't show the chevron
    // Anything else should
    const renderChevron = () => {
      if (
        !!row.original.bet_description?.length &&
        !row.original.legs &&
        !row.original.bet_legs
      )
        return false;
      return true;
    };

    return (
      <>
        <Tr
          key={row.id}
          onClick={onClickRow ? () => onClickRow(row.original) : undefined}
          ref={ref}
          {...dragHandleProps}
          {...draggableProps}
        >
          {children && (
            <Td sx={{ width: '10px' }}>
              {renderChevron() && (
                <Button
                  aria-label={`button-${row.id}`}
                  type="button"
                  variant="unstyled"
                  id={row.id}
                  onClick={() => {
                    toggleRowOpen(row.id);
                    if (onClickExpand) onClickExpand(row.original);
                  }}
                >
                  <Icon
                    color="gray.500"
                    boxSize="7"
                    as={activeId === row.id ? ChevronUp : ChevronDown}
                  />
                </Button>
              )}
            </Td>
          )}
          {row.getVisibleCells().map((cell) => {
            /**
             * Once everything is moved across to websockets, this
             * snippet should be removed and the existing
             * bet approvals column hook should have the DOM extracted.
             */
            if (!isWincore) {
              return (
                <Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            }

            /**
             * Cell data is set in the useColumns hook.
             */
            const value = cell.getValue();

            return (
              <Td key={cell.column.columnDef.id}>
                <ErrorBoundary fallbackRender={() => <>EB</>}>
                  {(() => {
                    if (typeof value === 'object' && value !== null) {
                      const val = value as Record<string, unknown>;

                      if ('cell' in val) {
                        // TODO: Use a JS enum here to load the correct component
                        if (val.cell === 'punter') {
                          const _val = val as PunterSchema;
                          return <Punter bet={_val.data} />;
                        }

                        if (val.cell === 'action') {
                          const _val = val as ActionSchema;
                          return <ActionWrapper data={_val.data} />;
                        }

                        if (val.cell === 'return') {
                          const _val = val as ActionSchema;

                          const isSettlement =
                            _val.data.requestType === 'Settlement';

                          if (_val.data.requestedPayout !== 0 && isSettlement) {
                            return (
                              <p>{centsToDollars(_val.data.requestedPayout)}</p>
                            );
                          } else if (
                            _val.data.requestedPayout === 0 &&
                            isSettlement
                          ) {
                            return <p>N/A</p>;
                          } else if (!isSettlement) {
                            if (typeof _val.data.requestedOdds !== 'number') {
                              return <p>N/A</p>;
                            }

                            if (
                              _val.data.betLegs.some(
                                (leg) =>
                                  leg.price_type === 'tote_single_mid' ||
                                  leg.price_type === 'tote_single_best'
                              )
                            ) {
                              return <p>N/A</p>;
                            }

                            const isMysteryBet = _val.data.betLegs.some(
                              (bet) => bet.price_type === 'mystery_bet'
                            );
                            const hasRollover =
                              isMysteryBet && _val.data.betType === 'Multi';

                            let payout;

                            const mbOdds = hasRollover
                              ? (_val.data?.isBonusBet
                                  ? _val.data?.requestedOdds - 1
                                  : _val.data?.requestedOdds) *
                                _val.data?.requestedOdds
                              : _val.data?.isBonusBet
                              ? _val.data?.requestedOdds - 1
                              : _val.data?.requestedOdds;

                            if (isMysteryBet) {
                              payout = mbOdds * _val.data?.requestedStake;
                            } else {
                              payout = !_val.data.isBonusBet
                                ? _val.data.requestedStake *
                                  _val.data.requestedOdds
                                : _val.data.requestedStake *
                                  (_val.data.requestedOdds - 1);
                            }

                            return (
                              <Box>
                                <p>{centsToDollars(payout, true)}</p>
                                {payout >= MAX_SAFE_BET && (
                                  <ChakraBadge
                                    colorScheme="red"
                                    w="fit-content"
                                  >
                                    HIGH RETURN
                                  </ChakraBadge>
                                )}
                              </Box>
                            );
                          }

                          return <p>N/A</p>;
                        }

                        if (val.cell === 'market') {
                          const data = val.data as CommonResponse;
                          return (
                            <MarketTableItem
                              type={data.betType as EBetTypes}
                              data={data}
                            />
                          );
                        }

                        if (val.cell === 'type') {
                          const data = val.data as CommonResponse;
                          const isPlacement =
                            data?.requestType.toLowerCase() === 'placement';
                          return (
                            <>
                              <ChakraBadge
                                colorScheme={isPlacement ? 'green' : 'purple'}
                                p="1"
                              >
                                {data?.requestType}
                              </ChakraBadge>
                              <img
                                src={logoFromBookieName(
                                  data?.sourcePlatformName
                                )}
                                alt=""
                                width="200px"
                              />
                            </>
                          );
                        }

                        if (val.cell === 'exposure') {
                          const data = val.data as CommonResponse;

                          const isMysteryBet = data.betLegs.some(
                            (bet) => bet.price_type === 'mystery_bet'
                          );

                          const hasRollover =
                            isMysteryBet && data.betType === 'Multi';

                          if (data.requestType === 'Settlement') {
                            return (
                              <Text>
                                {centsToDollars(
                                  data.requestedPayout - data.stake
                                )}
                              </Text>
                            );
                          }

                          if (data.requestType === 'Settlement') {
                            return (
                              <Text>
                                {centsToDollars(
                                  data.requestedPayout - data.stake
                                )}
                              </Text>
                            );
                          }

                          const isRace =
                            data.betType === 'Single' &&
                            data.betLegs[0].event_type === 'Race';
                          if (isRace) {
                            const priceType =
                              isRace && data.betLegs[0].price_type;

                            if (
                              priceType === 'tote_single_mid' ||
                              priceType === 'tote_single_best' ||
                              priceType === 'starting'
                            ) {
                              return <Text>N/A</Text>;
                            }
                          }

                          return (
                            <>
                              {centsToDollars(
                                (hasRollover
                                  ? data.requestedOdds * data.requestedOdds - 1
                                  : data.requestedOdds - 1) *
                                  data.requestedStake
                              )}
                              {data.raainRuleBreached && (
                                <Box mt="2">
                                  <RaainBoxWrapper>
                                    <RaainLogoImage
                                      src={RaainLogo}
                                      alt="Raain Logo"
                                    />
                                  </RaainBoxWrapper>
                                </Box>
                              )}
                            </>
                          );
                        }
                        if (val.cell === 'odds') {
                          const data = val.data as string;
                          return data;
                        }

                        if (val.cell === 'stake') {
                          const data = val.data as string;
                          return data;
                        }

                        if (val.cell === 'notes') {
                          if (val.data)
                            return (
                              <Flex
                                sx={{
                                  bg: '#fffab1',
                                  p: '2',
                                  color: 'black',
                                  maxW: '150px',
                                  h: 'full',
                                  boxShadow: 'lg',
                                }}
                              >
                                {val.data as string}
                              </Flex>
                            );
                          return null;
                        }

                        if (val.render) {
                          return <Text>{value as ReactNode}</Text>;
                        } else {
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>;
                        }
                      }
                    }

                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })()}
                </ErrorBoundary>
              </Td>
            );
          })}
        </Tr>
        {activeId === row.id && children && (
          <Tr>
            <Td className="nested" colSpan={10}>
              {children}
            </Td>
          </Tr>
        )}
      </>
    );
  }
);

TableRow.displayName = 'TableRow';
