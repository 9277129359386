import React, { ReactNode } from 'react';
import {
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  TableProps,
} from '@chakra-ui/react';

type TTableChakra = TableProps & {
  header: ReactNode;
  children: ReactNode;
};

export default function TableChakraConfigure({
  header,
  children,
  ...rest
}: TTableChakra) {
  return (
    <TableContainer>
      <ChakraTable {...rest}>
        <Thead>
          <Tr>
            <Th />
            {header}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </ChakraTable>
    </TableContainer>
  );
}
