import { useDispatch } from 'react-redux';
import { TVoidBetInput } from './Types.VoidBet';
import { AppThunkDispatch } from '../../../../../app/types';
import { TBet } from '../../../../../lib/DBModels';
import { TBetsData } from '../../../../punters/pages/Bets/Services/Types.Bets';
import { setModalVoidBet } from '../../../../punters/pages/Bets/Services/Slice.Bets';
import { useMutationVoidBet } from '@/api/punters/bets/bets.hooks';
import { TMarketBet } from '@/api/tradeManager/trademanager.types';

/* Used to cancel or void a bet */
export const useBetVoid = () => {
  const { mutateAsync } = useMutationVoidBet();

  const voidBet = async (input: TVoidBetInput) =>
    mutateAsync({
      ...input,
    });

  return { voidBet };
};

/* Used to trigger the void bet modal. We need this as we need to trigger from various data types which dont have overlapping prop names :| */
export const useVoidBetModal = () => {
  const dispatch: AppThunkDispatch = useDispatch();

  const showVoidBetFromMarketBet = (bet: TMarketBet) => {
    dispatch(
      setModalVoidBet({
        punter_name: bet.bet_punter_name,
        bet_id: bet.bet_id,
        stake: bet.bet_stake,
      })
    );
  };

  const showVoidBetFromBet = (bet: TBet | TBetsData) => {
    dispatch(
      setModalVoidBet({
        punter_name: bet.punter_name,
        bet_id: bet.bet_id,
        event_title: bet.event_title,
        stake: bet.stake,
      })
    );
  };

  return { showVoidBetFromMarketBet, showVoidBetFromBet };
};
