import api from '../../../api';
import { TSuspendMatchBody } from './suspendMatch.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const suspendMatchURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/suspend-match`;

// Patch: Suspend Match
export const mutateSuspendMatch = (body: TSuspendMatchBody) =>
  api.patch<TSuspendMatchBody>(suspendMatchURL, body).then((res) => res.data);
