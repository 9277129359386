import {
  ERequestBetType,
  TRequestBet,
} from '../../features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { EBetStatus, EGeneralStatus, EPlayerType } from '../../lib/DBModels';

export const pendingApprovalMock: TRequestBet[] = [
  {
    request_id: '5f3f0df4-805f-4a5c-92f6-e86d12e33611',
    created_at: '2024-04-05T20:23:37Z',
    type: ERequestBetType.Single,
    sub_type: 'string',
    proposition_id: '105844a9-a4b6-4be2-8354-69a54b234384',
    requested_odds: 10.5,
    requested_stake: 255500,
    is_bonus_bet: false,
    status: EBetStatus.Pending,
    actioned_at: '2024-04-05T20:23:37Z',
    actioned_by: '',
    punter_id: 'ee0b03c5-7c43-4de3-b902-960ccb951db2',
    punter_name: 'Andy Aydin',
    punter_storm_score: 0,
    event_id: '55790b7a-f167-4bc8-875e-bb61c85073d3',
    event_title: 'Wyndham - R1',
    event_subtitle: 'AMBRO AMEIGO',
    event_start: '2024-04-05T23:55:00Z',
    event_type: 'Race',
    event_icon: 'Harness Racing',
    event_status: EGeneralStatus.Open,
    proposition_odds: 10.5,
    approved_odds: 0,
    approved_stake: 0,
    bet_payout: 0,
    bet_status: EBetStatus.Pending,
    bet_won: false,
    selection: [[0], [1], [2]],
    market_name: 'Selection',
    bet_legs: [],
    mbl_stake: 0,
    mbl_threshold: 0,
    intercept_reason: 'pcel_harness',
    punter_category_at_placement: EPlayerType.HighRoller,
    punter_category_limit_name: 'pcel_harness',
    punter_category_limit: 50000,
    proposition_type: 'Win',
    runner_name: 'AMBRO AMEIGO',
    runner_number: 6,
  },
];
