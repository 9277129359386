import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import { TBetCloudErrorResponse } from '@/api/api.types';

export default function useErrorHandler() {
  const intl = useIntl();
  const toast = useToast();

  function errorHandler({ response }: AxiosError<TBetCloudErrorResponse>) {
    const { user_msg } = response?.data.detail ?? {};

    if (!user_msg) {
      return;
    }

    toast.closeAll();
    toast({
      title: intl.formatMessage({
        id: 'generic.errorTitle',
      }),
      description: user_msg,
      status: 'error',
      position: 'top',
      duration: 9_000,
    });
  }

  return errorHandler;
}
