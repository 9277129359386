import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { StyledTabNavLink } from './Styles';

export const TabNavLink: FC<NavLinkProps & { isActive?: boolean }> = ({
  to,
  children,
  isActive,
  ...rest
}) => (
  <StyledTabNavLink
    to={to}
    {...rest}
    className={isActive ? 'selected' : 'unselected'}
    end
  >
    {children}
  </StyledTabNavLink>
);
