import { betApprovalsHandler } from '../../features/betApprovals/pages/BetApprovals/__mocks__/BetApprovals.handler';
import { betApprovalLimitsHandler } from '../../features/settings/pages/Settings/tabs/Limits/__mocks__/BetApprovalLimit.handler';
import { exposureLimitsHandler } from '../../features/settings/pages/Settings/tabs/Limits/Components/ExposureLimits/__mocks__/ExposureLimits.handler';
import { bulkBonusAwardsHandler } from '../../views/PromoManager/__mocks__/Handler.BulkBonusAwards';
import { multiMonitorBets } from '../../api/tradeManager/MultiMonitor.handler';
import { profileHandler } from '../../api/profile/profile.handlers';
import { pendingApprovalHandler } from '../../api/betApprovals/pendingApprovals.handlers';
import { handlersProfile } from '@/features/punters/pages/Punter/tabs/Profile/__mocks__/Profile.handler';
import { handlerBets } from '@/api/punters/received.handler';
import {
  handlerCustomMarketCompetitions,
  handlerCustomMarketSports,
} from '@/features/tradeManager/pages/TradeManager/tabs/CustomMarkets/__mocks__/CustomMarkets.handler';
import { bulkPromoUploadHandlers } from '@/api/bulkPromoUpload/bulkPromoUpload.handlers';
import { promoSummaryHandlers } from '@/api/promoSummary/promoSummary.handlers';
import { tokenHandlers } from '@/api/promos/token/token.handlers';
import { tokenHistoryHandlers } from '@/api/promos/token/tokenHistory.handlers';
import { tokenSummaryHandlers } from '@/api/promos/token/tokenSummary.handlers';
import { handlerBookieProfile } from '@/features/settings/pages/Settings/tabs/YourProfile/__mocks__/Handler.BookieProfile';
import { offeringsHandlers } from '@/api/settings/system/offerings/offerings.handlers';

export const browserHandler = [
  ...betApprovalsHandler,
  ...multiMonitorBets,
  ...bulkBonusAwardsHandler,
  ...exposureLimitsHandler,
  ...betApprovalLimitsHandler,
  ...profileHandler,
  ...pendingApprovalHandler,
  ...handlersProfile,
  ...handlerBets,
  ...handlerCustomMarketSports,
  ...handlerCustomMarketCompetitions,
  ...bulkPromoUploadHandlers,
  ...promoSummaryHandlers,
  ...tokenHandlers,
  ...tokenHistoryHandlers,
  ...tokenSummaryHandlers,
  ...handlerBookieProfile,
  ...offeringsHandlers,
];
