import api from '../../api';
import {
  TQueryPromoPuntersCSVParams,
  TQueryPromoPuntersCSVResponse,
} from './promoPuntersCSV.types';

export const promoPuntersCSVEndpoint = '/bookie/promos/promo/promo-punters-csv';

export async function queryPromoPuntersCSV(
  params: TQueryPromoPuntersCSVParams
) {
  const res = await api.get<TQueryPromoPuntersCSVResponse>(
    promoPuntersCSVEndpoint,
    { params }
  );
  return res;
}
