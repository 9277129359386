import { EBetStatus } from '../../../../lib/DBModels';

export const statusColours = {
  [EBetStatus.Pending]: {
    textColour: 'yellow.500',
    backgroundColour: 'yellow.100',
  },
  [EBetStatus.CashedOut]: {
    textColour: 'white',
    backgroundColour: 'orange.500',
  },
  [EBetStatus.Voided]: {
    textColour: 'gray.900',
    backgroundColour: 'gray.100',
  },
  [EBetStatus.PartiallySettled]: {
    textColour: 'black',
    backgroundColour: 'gray.200',
  },
  [EBetStatus.Settled]: {
    textColour: 'green.900',
    backgroundColour: 'green.50',
  },
  [EBetStatus.Rejected]: {
    textColour: 'white',
    backgroundColour: 'red.500',
  },
  [EBetStatus.Approved]: {
    textColour: 'white',
    backgroundColour: 'green.500',
  },
  [EBetStatus.ReducedStake]: {
    textColour: 'white',
    backgroundColour: 'orange.400',
  },
  [EBetStatus.Cancelled]: {
    textColour: 'white',
    backgroundColour: 'gray.500',
  },
  [EBetStatus.Partial]: {
    textColour: 'white',
    backgroundColour: 'orange.400',
  },
};
