import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import TableChakra from '@/common/components/TableChakra/TableChakra';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@/common/components/Pagination/Pagination';
import Filters from '../../components/Filters/Filters';
import { useRegulator } from '../../services/Regulator.hooks';
import { useColumns } from './services/FinancialTransactionHistory.hooks';
import { TTransaction } from '@/api/regulator/transactions/transactions.types';
import SearchRules from '../BettingHistory/components/SearchRules';

const FinancialTransactionHistory = () => {
  const {
    transactionData,
    transactionsLoading,
    isLastFetchForTransactions,
    offset,
    setOffset,
    searchBy,
    setSearchOption,
    onSubmitSearch,
    isDownloading,
    setIsDownloading,
  } = useRegulator();

  const { columns } = useColumns();

  return (
    <Flex flexDir="column">
      <Filters
        onSubmit={(values) => onSubmitSearch(values)}
        formProps={{
          downloadDisabled: !transactionData || !transactionData.length,
          isDownloading,
          setIsDownloading,
          searchBy: searchBy,
          onChangeFilter: (searchOption) => setSearchOption(searchOption),
        }}
      />

      <SearchRules />

      <TableChakra<TTransaction>
        columns={columns}
        data={(transactionData as TTransaction[]) ?? []}
        isLoading={transactionsLoading}
        placeholder={
          <Text py="5" mb="20">
            <FormattedMessage id="regulatorpage.noresults" />
          </Text>
        }
      />
      <Pagination
        nextDisabled={isLastFetchForTransactions}
        offset={offset}
        onPageChange={(newOffset) => setOffset(newOffset)}
      />
    </Flex>
  );
};

export default FinancialTransactionHistory;
