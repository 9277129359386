import api from '../../../api';
import { TProtestOverrideBody } from './fptpProtestOverride.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const protestOverrideURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/fptp-protest-override`;

// Post: Protest Override
export const mutateProtestOverride = (body: TProtestOverrideBody) =>
  api
    .post<TProtestOverrideBody>(protestOverrideURL, body)
    .then((res) => res.data);
