import * as React from 'react';
import { SVGProps } from 'react';

const StormLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={41} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.318 14.98 8.874-8.1S6.625 5.826 1.999 14.303c-4.581 8.394 1.612 16.53 1.612 16.53l6.417-8.136-7.13 1.598L29 .696 8.318 14.98Zm10.466 4.502 6.442-7.815s5.909 7.13 2.303 15.405c-3.606 8.274-15.191 8.274-15.191 8.274l8.919-8.274L.667 40.696l25.382-22.6-7.265 1.386Z"
      clipRule="evenodd"
    />
  </svg>
);

export default StormLogo;
