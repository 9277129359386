import React, { Fragment } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
  Skeleton,
} from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/evaicons-solid';
import { FormattedMessage } from 'react-intl';
import { TTableChakraProps } from './TableChakra.types';
import { useTableRows } from './services/TableChakra.hooks';
import {
  ButtonRow,
  IconButtonRow,
  TdButton,
} from './styles/TableChakra.styles';

function ChakraTable<T>({
  columns,
  data,
  placeholder,
  isLoading,
  tdSx,
  thSx,
  rowDetailsStyle,
  rowDetails,
  onClickRow,
}: TTableChakraProps<T>) {
  const { clickedRows, handleRowClick } = useTableRows();

  return (
    <Box overflow="auto">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {rowDetails && <Th />}
            {columns.map((column) => (
              <Th
                key={column.property.toString()}
                textAlign={column.align}
                sx={{ ...thSx }}
              >
                {column.header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {(() => {
            if (isLoading)
              return (
                <Tr>
                  <Td colSpan={columns.length}>
                    {[...new Array(5)].map((_, i) => (
                      <Skeleton key={`skel-${i}`} h="14" my="1" />
                    ))}
                  </Td>
                </Tr>
              );

            if (!data.length)
              return (
                <Tr>
                  <Td colSpan={columns.length} textAlign="center" py="5">
                    {placeholder ?? <FormattedMessage id="generic.nodata" />}
                  </Td>
                </Tr>
              );

            return data.map((datum, i) => (
              <Fragment key={i}>
                {/* Using the style={{ height: 'x' }} here to make the button heigh be 100% of the row, this is not possible via sx or h prop */}
                <Tr style={{ height: '100%' }}>
                  {rowDetails && (
                    <TdButton style={{ height: '0' }}>
                      <ButtonRow
                        onClick={() => {
                          if (onClickRow) onClickRow(datum);
                          handleRowClick(i);
                        }}
                      >
                        <IconButtonRow
                          as={ChevronDown}
                          transform={
                            clickedRows.includes(i)
                              ? 'rotate(-180deg)'
                              : 'rotate(0deg)'
                          }
                        />
                      </ButtonRow>
                    </TdButton>
                  )}
                  {columns.map((column, i) => (
                    <Td
                      key={column.property.toString()}
                      textAlign={column.align}
                      borderColor="gray.100"
                      sx={{
                        ...(column.size && { w: column.size }),
                        ...(i !== columns.length - 1 && {
                          borderRight: '1px solid',
                          borderColor: 'gray.100',
                        }),
                        ...(tdSx && tdSx),
                      }}
                    >
                      {column.render(datum)}
                    </Td>
                  ))}
                </Tr>
                {rowDetails && (
                  <Tr>
                    <Td
                      colSpan={columns.length + 1}
                      borderBottom={clickedRows.includes(i) ? '1px solid' : ''}
                      borderBottomColor="gray.100"
                      py="0"
                    >
                      <Box
                        as={Collapse}
                        in={clickedRows.includes(i)}
                        w="100%"
                        overflow="visible !important"
                        style={{
                          ...(rowDetailsStyle && {
                            ...rowDetailsStyle(datum),
                          }),
                        }}
                      >
                        {rowDetails(datum, i, clickedRows.includes(i))}
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Fragment>
            ));
          })()}
        </Tbody>
      </Table>
    </Box>
  );
}

export default ChakraTable;
