import api from '@/api/api';
import { TGetSportMarketParams, TSportMarket } from './getSportMarket.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const getSportMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/get-sport-market`;

// Trade Manager - Get SportMarket

export const queryGetSportMarket = (params: TGetSportMarketParams) =>
  api.get<TSportMarket>(getSportMarketURL, { params }).then((res) => res.data);
