import React from 'react';
import { ListItem, OrderedList } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const SearchRules = () => {
  return (
    <OrderedList pl="8" mb="5">
      <ListItem>
        <FormattedMessage id="regulatorpage.ruleOne" />
      </ListItem>
      <ListItem>
        <FormattedMessage id="regulatorpage.ruleTwo" />
      </ListItem>
      <ListItem>
        <FormattedMessage id="regulatorpage.ruleThree" />
      </ListItem>
      <ListItem>
        <FormattedMessage id="regulatorpage.ruleFour" />
      </ListItem>
      <ListItem>
        <FormattedMessage id="regulatorpage.ruleFive" />
      </ListItem>
    </OrderedList>
  );
};

export default SearchRules;
