/* eslint-disable camelcase */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../../lib/api/api';
import { setEventsResponse } from './slices';

import { AppThunkDispatch } from '../../../../../../app/types';

import { TFindQuery } from './types';
import { TEventsResponse } from '../../types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const findAnEvent = createAsyncThunk<
  Promise<TEventsResponse | undefined>,
  TFindQuery,
  { dispatch: AppThunkDispatch }
>(
  'events/findAnEvent',
  async (findQuery, thunkAPI): Promise<TEventsResponse | undefined> => {
    try {
      const eventsReqObj: { [key: string]: unknown } = {
        competition_id: findQuery.competition_id,
        tournament_id: findQuery.tournament_id,
        resulted: findQuery.resulted,
        start_date: findQuery.start_date,
        end_date: findQuery.end_date,
        offset: findQuery.offset,
        limit: findQuery.limit,
        sort_by: findQuery.sort_by,
        sort_direction: findQuery.sort_direction,
      };

      // If the sport type selected was a racing type, update race type, otherwise use as sport id
      if (findQuery.sport_id) {
        if (
          ['Horse Racing', 'Greyhounds', 'Harness Racing'].includes(
            findQuery.sport_id
          )
        ) {
          eventsReqObj.race_type = findQuery.sport_id;
        } else {
          eventsReqObj.sport_id = findQuery.sport_id;
        }
      }

      if (findQuery?.event_name) {
        eventsReqObj.event_name = findQuery.event_name;
      }

      const eventsData = await apiGetRequest<TEventsResponse>(
        `${getPrefixPath(isWincore)}/trade-manager/find-an-event`,
        eventsReqObj
      );
      thunkAPI.dispatch(setEventsResponse(eventsData));
      return eventsData;
    } catch (error) {
      return undefined;
    }
  }
);
