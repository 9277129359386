import styled, { css } from 'styled-components';
import { Button, Spinner } from 'grommet';
import { ReactNode } from 'react';

export const activeButtonStyle = css`
  border-bottom: 4px solid ${({ theme }) => theme.global.colors.primary};
  color: ${({ theme }) => theme.global.colors.primary};
`;

export const TextButton = styled(Button)`
  opacity: 0.3;
  font-weight: bold;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
      background: none;
      opacity: 1;
    `}
`;

export const FilterButton = styled(Button)`
  background: ${({ theme }) => theme.global.colors.filterButtonBackground};
  border: 1px solid ${({ theme }) => theme.global.colors.filterButtonBorder};
  border-radius: 12px;
  height: 26px;
  display: flex;
  padding: 0 9px;
  margin: 0 10px 10px 0;
  min-width: 150px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;

  ${(props) => props.fill && 'flex-grow: 1'}
  ${(props) => props.active && activeButtonStyle}
`;

export type TStyledButton = {
  bg?: string;
  color?: string;
  isLoading?: boolean;
  disabled?: boolean;
  children: ReactNode;
};

export const StyledButton = styled(Button).attrs((props: TStyledButton) => ({
  disabled: props.isLoading || props.disabled,
}))<TStyledButton>`
  height: 56px;
  text-align: center;
  line-height: 56px;
  background: ${(props) => {
    const bgColor = props.theme.global.colors[props.bg ?? 'lightButtonGrey'];
    return bgColor ?? props.bg ?? 'lightButtonGrey';
  }};
  color: ${(props) => {
    const bgColor = props.theme.global.colors[props.color ?? 'black'];
    return bgColor ?? props.bg ?? 'black';
  }};
  border-radius: 16px;
  padding: 0 16px;
  position: relative;

  svg {
    height: 56px;
  }

  &:hover {
    background: ${(props) => {
      const bgColor = props.theme.global.colors[props.bg ?? 'linesGrey'];
      return bgColor ?? props.bg ?? 'linesGrey';
    }}
`;

export const ButtonSpinner = styled(Spinner)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;
