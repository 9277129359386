import api from '../api';
import {
  TPunterProfileParams,
  TPunterProfile,
  TPunterFlags,
  TPunterFlagsDelete,
  PunterAffiliateProps,
  TPunterOfferings,
} from './punterProfile.types';

export const punterProfile = (punterId: TPunterProfileParams['punterId']) =>
  `/bookie/punters/profile/${punterId}`;

export const queryPunterProfile = (params: TPunterProfileParams) => {
  const { punterId, ...rest } = params;

  return api
    .get<TPunterProfile>(punterProfile(punterId), { params: rest })
    .then((res) => res.data);
};

export const mutatePunterAffiliate = (body: PunterAffiliateProps) =>
  api
    .post<PunterAffiliateProps>(`/bookie/punters/profile/affiliate`, body)
    .then((res) => res.data);

export const mutatePunterFlags = (body: TPunterFlags) =>
  api
    .post<TPunterFlags>(`/bookie/punters/profile/add-flag`, body)
    .then((res) => res.data);

export const deletePunterFlags = (body: TPunterFlagsDelete) =>
  api
    .delete<TPunterFlags>(`/bookie/punters/profile/delete-flag`, {
      ...body,
    })
    .then((res) => res.data);

export const mutatePunterDetails = (body: TPunterProfile) =>
  api
    .post<TPunterProfile>(`/bookie/punters/profile`, body)
    .then((res) => res.data);

export const queryPunterOfferings = () =>
  api
    .get<TPunterOfferings>('/bookie/punters/punter-settings/offerings', {})
    .then((res) => res.data);
