import { Tbody } from '@chakra-ui/react';
import React, { Fragment, ReactNode, useState } from 'react';
import {
  DroppableProvidedProps,
  Draggable,
  DraggableProvided,
} from '@hello-pangea/dnd';
import { TableRow } from './TableRow';
import { Table } from '@tanstack/react-table';

interface TableBodyProps<T> {
  loading: boolean | undefined;
  table: Table<T>;
  refCallback?: (element: HTMLElement | null) => void;
  droppableProps?: DroppableProvidedProps;
  placeholder?: ReactNode;
  isDraggable?: boolean;
  onClickRow?: (rowData: T) => void;
  onClickExpand?: (param: T) => void;
  children?: React.ReactNode;
}

export const TableBody = <T,>({
  refCallback,
  droppableProps,
  placeholder,
  loading,
  isDraggable = false,
  table,
  onClickRow,
  onClickExpand,
  children,
}: TableBodyProps<T>) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  return (
    <Tbody {...droppableProps} ref={refCallback}>
      {!loading &&
        table.getRowModel().rows.map((row, index) =>
          isDraggable ? (
            <Draggable key={index} draggableId={index.toString()} index={index}>
              {(provided: DraggableProvided) => (
                <Fragment key={row.id}>
                  <TableRow
                    row={row}
                    ref={provided.innerRef}
                    draggableProps={provided.draggableProps}
                    dragHandleProps={provided.dragHandleProps}
                    onClickExpand={onClickExpand}
                    onClickRow={onClickRow}
                    activeId={activeId}
                    setActiveId={setActiveId}
                  >
                    {children}
                  </TableRow>
                </Fragment>
              )}
            </Draggable>
          ) : (
            <Fragment key={row.id}>
              <TableRow
                row={row}
                onClickExpand={onClickExpand}
                onClickRow={onClickRow}
                activeId={activeId}
                setActiveId={setActiveId}
              >
                {children}
              </TableRow>
            </Fragment>
          )
        )}
      {placeholder ?? null}
    </Tbody>
  );
};

TableBody.displayName = 'TableBody';

export default TableBody;
