import api from '@/api/api';
import { TMatchStatus } from './matchStatus.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const matchStatusURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/manual-match-status-update`;

// Post: Update Match Status
export const mutateMatchStatus = (body: TMatchStatus) =>
  api.post<TMatchStatus>(matchStatusURL, body).then((res) => res.data);
