import { Flex, Box, Text, chakra } from '@chakra-ui/react';

export const BetTableStyle = chakra(Box, {
  shouldForwardProp: (prop) =>
    !['isApprovalsTable', 'isDraggable'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isApprovalsTable, isDraggable }: any) => ({
    '.tableParent': {
      display: 'flex',
      boxSizing: 'border-box',
      minWidth: '0',
      minHeight: '0',
      flexDirection: 'column',
      flex: '0 0 auto',
      overflowX: 'auto',
    },
    table: {
      position: 'relative',
      borderSpacing: '0',
      borderCollapse: 'separate',
      height: 'auto',
      '> thead tr th': {
        paddingTop: '3',
        paddingBottom: '3',

        background: 'origin.50',

        color: 'origin.500',
        fontWeight: 'normal',
        textTransform: 'capitalize',
        paddingLeft: '0',
        paddingRight: '0',
      },
      th: {
        '&:first-of-type': {
          paddingLeft: '3',
        },
      },
      'td + th:first-of-type': {
        paddingLeft: '0',
      },
      '.nested': {
        borderRight: '0 !important',
        paddingLeft: '0 !important',
      },
      table: {
        th: {
          color: 'brand.900',
          paddingTop: '3',
          paddingLeft: '3',
          paddingBottom: '3 !important',
        },
        tbody: {
          tr: {
            td: {
              paddingTop: '3',
              paddingBottom: '3',
              '&:first-of-type': {
                borderRight: '0',
              },
              '&:nth-of-type(2)': {
                display: 'table-cell',
                flexDirection: 'row',
              },
            },
            'tr:last-of-type': {
              td: {
                borderBottom: '0',

                '&:first-of-type': {
                  paddingBottom: '0 !important',
                },
              },
            },
          },
        },
      },
    },
    thead: {
      th: {
        margin: '0',
        fontWeight: 'inherit',
        textAlign: 'start',
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200',
        padding: '4',
        color: 'gray.500',
        textTransform: 'capitalize',

        span: {
          fontSize: 'lg',
        },
        div: {
          lineHeight: '4',
          paddingRight: '4',
          textAlign: 'left',
          fontFamily: 'Poppins, serif',
        },
      },
    },
    tbody: {
      tr: {
        cursor: isDraggable ? 'grab' : 'pointer',
        lineHeight: '5',
        transition: '.3s ease',
        _hover: {
          bg: 'gray.50',
        },
      },
      td: {
        '&:first-of-type': {
          width: isApprovalsTable && '15%',
          paddingRight: isApprovalsTable && '3',
          paddingLeft: '3',
          paddingTop: '3',
        },
        verticalAlign: isApprovalsTable ? 'top' : 'center',
        color: 'gray.600',
        margin: '0',
        padding: '0',
        fontWeight: 'inherit',
        textAlign: 'start',
        paddingLeft: '0',
        paddingRight: '2',
        paddingTop: '3',
        paddingBottom: '3',
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200',
        transition: '.3s ease-out',
        _hover: {
          color: 'gray.900',
        },
      },
      'th + td:first-of-type': {
        paddingLeft: '0',
      },
    },
  }),
});

export const PlaceHolderWrapper = chakra(Flex, {
  baseStyle: () => ({
    w: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
});

export const PlaceHolder = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'lg',
    color: 'gray.500',
    fontWeight: 'medium',
  }),
});
