export const downloadCsv = (csvData: string) => {
  // Create a blob from the PDF data
  const csvBlob = new Blob([csvData], { type: 'text/csv' });

  // Create a URL for the PDF blob
  const csvUrl = URL.createObjectURL(csvBlob);

  // Create a link to download the PDF
  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = 'document.csv';
  link.click();

  // Clean up the URL object
  URL.revokeObjectURL(csvUrl);
};
