import React, { useState } from 'react';
import { Text, Icon, Flex, Badge } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CheckSquareFill, App, XSquareFill } from '@styled-icons/bootstrap';
import { StartTimeCalculator } from '../../../../../../../common/components';
import {
  getStrings,
  centsToDollars,
  dateFormatter,
  readableMarketType,
  getIconAssetPath,
} from '../../../../../../../common/utils';
import {
  TBetEventData,
  TBetLegs,
} from '../../../../../../../api/punters/punters.types';
import { useQuery as useQueryString } from '../../../../../../../common/hooks/useQuery';
import { useQueryMultiBets } from '../../../../../../../api/tradeManager/multi/multi.hooks';
import IconSvg from '@/components/IconSvg/IconSvg';

export const useRaceDetailsMulti = () => {
  const q = useQueryString();
  const id = q.get('id');
  const checkId = id || '';

  const [offset, setOffset] = useState<number>(0);

  const limit = 20;

  const shouldRefetch = offset === 0;

  const { data, isLoading } = useQueryMultiBets({
    params: {
      offset,
      limit,
      event_id: checkId,
      is_multi_monitor: false,
    },
    options: {
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
      staleTime: shouldRefetch ? 0 : 60 * 5000,
    },
  });

  return {
    data,
    isLoading,
    isLastFetch: (data?.length ?? 0) < limit,
    setOffset,
    offset,
  };
};

export const useMultiColumns = () => {
  const [
    {
      TradeManagerPage: {
        MultiMonitor: { ColumnTitles },
      },
    },
  ] = getStrings();

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'punter_name',
        header: ColumnTitles.Punter,
        cell: (props: {
          row: { original: { punter_id: string; result: number } };
          getValue: () => string;
        }) => (
          <Link to={`/punter/${props.row.original.punter_id}`}>
            <Text color="brand.500">{props.getValue() ?? ''}</Text>
          </Link>
        ),
      },
      {
        accessorKey: 'bet_details',
        header: ColumnTitles.NextLeg,
        cell: (props: {
          row: {
            original: {
              bet_legs: TBetLegs[];
              bet_description: string;
              event_data: TBetEventData;
            };
          };
        }) => {
          const legs = props.row.original.bet_legs;
          const nextLeg = legs.find((leg) => leg.status === 'pending');
          const eventType = nextLeg?.event_type?.toLocaleLowerCase();

          return (
            <>
              <Link
                to={`/trade-manager/${
                  eventType === 'match' ? 'match' : 'race'
                }-details?id=${nextLeg?.event_id}`}
              >
                {eventType === 'race' ? (
                  <Text color="brand.500">
                    {nextLeg?.event_data?.runner_number}
                    {'. '}
                    {nextLeg?.event_data?.runner_name}
                  </Text>
                ) : (
                  <Text color="brand.500">
                    {nextLeg?.event_data?.proposition_name ?? ''}
                  </Text>
                )}
              </Link>

              <Flex justify="left" mt="2">
                {!!nextLeg?.event_start && (
                  <StartTimeCalculator date={nextLeg?.event_start} />
                )}
              </Flex>
            </>
          );
        },
      },
      {
        accessorKey: 'stake',
        header: ColumnTitles.StartingStake,
        cell: (props: {
          row: { original: { stake: number; is_bonus: boolean } };
        }) => {
          const original = props.row.original;
          const stake = original.stake;
          const isBonus = original.is_bonus;

          return (
            <Flex gap="2">
              <Text>{centsToDollars(stake ?? 0)}</Text>
              {isBonus && <Badge>Bonus</Badge>}
            </Flex>
          );
        },
      },
      {
        accessorKey: 'odds',
        header: ColumnTitles.StartingOdds,
        cell: (props: { getValue: () => number }) => (
          <Text>
            {props.getValue().toLocaleString('en-AU', {
              minimumFractionDigits: 2,
            }) ?? 0}
          </Text>
        ),
      },
      {
        accessorKey: 'bet_legs',
        header: ColumnTitles.Legs,
        cell: (props: { row: { original: { bet_legs: TBetLegs[] } } }) => {
          const legs = props.row.original.bet_legs;
          const totalBetLegs = legs?.reduce(
            (count, leg) =>
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              count +
              (leg.bet_description === 'Blended' || !leg.legs
                ? 1
                : leg.legs.length ?? 0),
            0
          );

          return <Text>{totalBetLegs}</Text>;
        },
      },
      {
        accessorKey: 'legs_won',
        header: ColumnTitles.LegsWon,
        cell: (props: { row: { original: { bet_legs: TBetLegs[] } } }) => {
          const original = props.row.original;
          const legs = original.bet_legs;
          const hasGroupedLegs = original.bet_legs.some((leg) => !!leg.legs);
          const won = legs.filter((leg: TBetLegs) => leg.is_won);

          let totalWonLegs = 0;
          legs.map((betLeg) => {
            const isBlended = betLeg.bet_description === 'Blended';
            const wonLegsCount = isBlended
              ? 0
              : betLeg.legs
              ? betLeg.legs.filter((leg) => leg?.is_won === true).length
              : betLeg.is_won === true
              ? 1
              : 0;
            totalWonLegs += wonLegsCount;
          });

          return (
            <Text>
              {hasGroupedLegs ? `${totalWonLegs}` : `${won ? won.length : '0'}`}
            </Text>
          );
        },
      },
      {
        accessorKey: 'current_stake_value',
        header: ColumnTitles.CurrentStakeValue,
        cell: (props: { getValue: () => number }) => (
          <Text>{centsToDollars(props.getValue() ?? 0)}</Text>
        ),
      },
      {
        accessorKey: 'potential_return',
        header: ColumnTitles.PotentialPayout,
        cell: (props: {
          row: {
            original: { bet_legs: TBetLegs[]; stake: number };
          };
        }) => {
          const { stake } = props.row.original;
          const accumulatedOdds = props.row.original.bet_legs
            .map((leg) => leg.odds)
            .reduce((prev = 1, curr = 1) => prev * curr);
          let potentialPayout;
          if (accumulatedOdds) {
            potentialPayout = stake * accumulatedOdds;
          }

          return <Text>{centsToDollars(potentialPayout ?? 0)}</Text>;
        },
      },
    ],
    [ColumnTitles]
  );

  return columns;
};

export const useMultiSubColumns = () => {
  const [
    {
      TradeManagerPage: {
        MultiMonitor: { SubColumnTitles },
      },
    },
  ] = getStrings();

  const subcolumns = React.useMemo(
    () => [
      {
        accessorKey: 'is_won',
        header: '',
        cell: (props: { row: { original: { is_won: boolean | null } } }) => (
          <>
            {props.row.original.is_won ? (
              <Icon
                paddingRight="1"
                color="green.300"
                boxSize="5"
                as={CheckSquareFill}
              />
            ) : (
              (props.row.original.is_won === false && (
                <Icon
                  paddingRight="1"
                  color="red.500"
                  boxSize="5"
                  as={XSquareFill}
                />
              )) || (
                <Icon paddingRight="1" color="gray.300" boxSize="5" as={App} />
              )
            )}
          </>
        ),
      },
      {
        accessorKey: 'bet_description',
        header: SubColumnTitles.Proposition,
        cell: (props: {
          row: {
            original: {
              event_data: TBetEventData;
              legs: TBetLegs[];
              event_type: string;
              bet_description: string;
            };
          };
        }) => {
          const row = props.row.original;
          const eventData = row.event_data;
          const isMoM = row?.legs?.length > 1;

          if (row.bet_description === 'Blended') return <Text>Blended</Text>;

          if (isMoM)
            return `${row?.legs?.length} Leg ${readableMarketType(
              row.bet_description
            )}`;

          return (
            <>
              {row.event_type.toLocaleLowerCase() === 'race' ? (
                <Text>
                  {eventData?.runner_number ?? ''}
                  {'. '}
                  {eventData?.runner_name ?? ''}
                  {eventData?.runner_barrier_number
                    ? ` (${eventData?.runner_barrier_number})`
                    : ''}
                </Text>
              ) : (
                <Text>{eventData?.proposition_name ?? ''}</Text>
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'bet_market',
        header: SubColumnTitles.Market,
        cell: (props: {
          row: {
            original: {
              event_data: TBetEventData;
              event_type: string;
              event_icon: string;
            };
          };
        }) => {
          const data = props.row.original;

          const isRace = data?.event_type?.toLowerCase() === 'race';
          const iconPath = getIconAssetPath('sports', data?.event_icon);
          const venueName = data?.event_data?.venue_name ?? '';
          const raceNumber = data?.event_data?.race_number ?? '';
          const matchName = data?.event_data?.match_name ?? '';

          return (
            <Flex alignItems="center">
              <IconSvg name={iconPath} sx={{ mr: '1' }} />
              <Text>{isRace ? `${venueName} R${raceNumber}` : matchName}</Text>
            </Flex>
          );
        },
      },
      {
        accessorKey: 'bet_market_type',
        header: SubColumnTitles.MarketType,
        cell: (props: {
          row: {
            original: {
              bet_description: string;
              event_type: string;
              event_data: TBetEventData;
            };
          };
        }) => (
          <Text>
            {readableMarketType(
              props.row.original.event_type.toLocaleLowerCase() === 'race'
                ? props.row.original.bet_description ?? ''
                : props.row.original.event_data.market_name ?? ''
            )}
          </Text>
        ),
      },
      {
        accessorKey: 'odds',
        header: SubColumnTitles.Odds,
        cell: (props: { getValue: () => number }) => (
          <Text>{props.getValue() ? props.getValue().toFixed(2) : ''}</Text>
        ),
      },
      {
        accessorKey: 'event_start',
        header: SubColumnTitles.LegTime,
        cell: (props: { getValue: () => string }) => (
          <Flex justify="left">
            {dateFormatter(props.getValue() ?? '', 'DD/MM/YYYY, h:mma')}
          </Flex>
        ),
      },
    ],
    [SubColumnTitles]
  );

  const groupedLegColumns = React.useMemo(
    () => [
      {
        accessorKey: 'is_won',
        header: '',
        cell: (props: { row: { original: { is_won: boolean | null } } }) => (
          <>
            {props.row.original.is_won ? (
              <Icon
                paddingRight="1"
                color="green.300"
                boxSize="5"
                as={CheckSquareFill}
              />
            ) : (
              (props.row.original.is_won === false && (
                <Icon
                  paddingRight="1"
                  color="red.500"
                  boxSize="5"
                  as={XSquareFill}
                />
              )) || (
                <Icon paddingRight="1" color="gray.300" boxSize="5" as={App} />
              )
            )}
          </>
        ),
      },
      {
        accessorKey: 'bet_description',
        header: SubColumnTitles.Proposition,
        cell: (props: {
          row: {
            original: {
              event_data: TBetEventData;
              legs: TBetLegs[];
              event_type: string;
              bet_description: string;
            };
          };
        }) => {
          const row = props.row.original;
          const eventData = row.event_data;
          const isMoM = row?.legs?.length > 1;

          if (isMoM)
            return `${row?.legs?.length} Leg ${readableMarketType(
              row.bet_description
            )}`;

          return (
            <>
              {row.event_type.toLocaleLowerCase() === 'race' ? (
                <Text>
                  {eventData?.runner_number ?? ''}
                  {'. '}
                  {eventData?.runner_name ?? ''}
                </Text>
              ) : (
                <Text>{eventData?.proposition_name ?? ''}</Text>
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'bet_market',
        header: SubColumnTitles.Market,
        cell: (props: {
          row: {
            original: {
              event_data: TBetEventData;
              event_type: string;
              event_icon: string;
            };
          };
        }) => {
          const data = props.row.original;

          const isRace = data?.event_type?.toLowerCase() === 'race';
          const iconPath = getIconAssetPath('sports', data?.event_icon);
          const venueName = data?.event_data?.venue_name ?? '';
          const raceNumber = data?.event_data?.race_number ?? '';
          const matchName = data?.event_data?.match_name ?? '';

          return (
            <Flex alignItems="center">
              <IconSvg name={iconPath} sx={{ mr: '1' }} />
              <Text>{isRace ? `${venueName} R${raceNumber}` : matchName}</Text>
            </Flex>
          );
        },
      },
      {
        accessorKey: 'bet_market_type',
        header: SubColumnTitles.MarketType,
        cell: (props: {
          row: {
            original: {
              bet_description: string;
              event_type: string;
              event_data: TBetEventData;
            };
          };
        }) => (
          <Text>
            {readableMarketType(
              props.row.original.event_type.toLocaleLowerCase() === 'race'
                ? props.row.original.bet_description ?? ''
                : props.row.original.event_data.market_name ?? ''
            )}
          </Text>
        ),
      },

      {
        accessorKey: 'event_start',
        header: SubColumnTitles.LegTime,
        cell: (props: { getValue: () => string }) => (
          <Flex justify="left">
            {dateFormatter(props.getValue() ?? '', 'DD/MM/YYYY, h:mma')}
          </Flex>
        ),
      },
    ],
    [SubColumnTitles]
  );

  return { subcolumns, groupedLegColumns };
};
