import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddSportBody } from './addSport.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const addSportURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/add-sport`;

// Trade Manager - Add Sport

export const mutateAddSport = (body: TAddSportBody) =>
  api.post<TMarketCreationResponse>(addSportURL, body).then((res) => res.data);
