const env = window.BETCLOUD_ENV || process.env;

export const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  authDomain: env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: env.REACT_APP_FIREBASE_APPID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENTID,
};
