import { Flex, Box } from '@chakra-ui/react';
import React from 'react';
import { ERaceMarketType } from '../../../Services/RaceDetails.types';
import { useMarketTypeNavigation } from '../../../Services/RaceDetails.hooks';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { TabButton } from '@/common/components/Tabs/styles/Tabs.styles';

export default function Tabs() {
  const { marketType, goToMarketType } = useMarketTypeNavigation();

  const tabs = [
    { text: 'Win & Place', market: ERaceMarketType.WinPlace },
    { text: 'Multis', market: ERaceMarketType.MultiBets },
    { text: 'Exotics', market: ERaceMarketType.Exotics },
    { text: 'Quaddies', market: ERaceMarketType.Multiples },
    {
      text: 'SRM',
      market: ERaceMarketType.SRMulti,
      isHidden: !FEATURE_FLAGS.IS_SRM_ENABLED,
    },
    {
      text: 'Blended',
      market: ERaceMarketType.Blended,
    },
    {
      text: 'Even Shot',
      market: ERaceMarketType.EvenShot,
    },
    {
      text: 'Mystery Bet',
      market: ERaceMarketType.MysteryBet,
      isHidden: !FEATURE_FLAGS.IS_MYSTERY_BET_ENABLED,
    },
  ];

  return (
    <Flex mr="2" flexWrap="wrap">
      {tabs
        .filter((t) => !t.isHidden)
        .map(({ text, market }) => (
          <Box key={text} m="0.5">
            <TabButton
              size="sm"
              onClick={() => goToMarketType(market)}
              minW="150"
              isForRace
              isActive={marketType === market}
            >
              {text}
            </TabButton>
          </Box>
        ))}
    </Flex>
  );
}
