export const Popover = {
  parts: ['content'],
  baseStyle: {
    content: {
      width: '2xs',
      borderRadius: '2xl',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
