import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useQueryAuth } from '../../api/firebase/authentication.hooks';

export default function PrivateRoute() {
  const { data, isLoading } = useQueryAuth();

  if (isLoading) return <Skeleton />;
  if (data) {
    return <Outlet />;
  }

  return <Navigate to="/login" replace />;
}
