import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { setCustomMarketModal } from '../../pages/TradeManager/slices';
import { FormattedMessage } from 'react-intl';
import FormHOC from './components/FormHOC';
import Form from './components/Form';
import { ECustomMarketModalMode } from '../../pages/TradeManager/types';

export default function CustomMarketModal() {
  const dispatch = useAppDispatch();
  const { customMarketModal } = useAppSelector((state) => state.allSports);
  const { isOpen, mode, market_name } = customMarketModal;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() =>
        dispatch(
          setCustomMarketModal({
            isOpen: false,
            market_id: undefined,
            market_name: undefined,
          })
        )
      }
      size="md"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          <Text fontSize="md" textAlign="center">
            <FormattedMessage
              id={`trademanagerpage.custommarkets.modal.${
                mode === ECustomMarketModalMode.Create
                  ? 'createnewmarket'
                  : 'editmarket'
              }`}
            />{' '}
            {mode === ECustomMarketModalMode.Edit && market_name}
          </Text>
        </ModalHeader>

        <FormHOC>
          <Form />
        </FormHOC>
      </ModalContent>
    </Modal>
  );
}
