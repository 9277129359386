/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */ // for ln 382 - strange TS error
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Box, ColumnConfig, Text } from 'grommet';
import { Text as TextChakra, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../../../../../../common/hooks/useRedux';
import { getWithdrawalRequestsHistoryDownload } from '../../../../actions';
import {
  centsToDollars,
  getStrings,
} from '../../../../../../../../common/utils';

import {
  DataTableMultiSelectBar,
  LoadingSpinner,
  Separator,
  StyledDataTable,
} from '../../../../../../../../common/components';

import { LeftSideMultiActions, MultiExportButton } from '../../Styles';
import GrommetTheme from '../../../../../../../../styles/GrommetTheme';

import { TPunterWithdrawalRequestsDTO } from '../../../../../../../../lib/DTO';
import { formatDateTime24 } from '../../../../../../../../lib/Time';
import { useWithdrawalRequestsHistory } from '../../services/Withdrawals.hooks';
import Datepicker from '@/common/components/FormElements/Datepicker/Datepicker';
import Select from '@/common/components/FormElements/Select';
import { TPunterWithdrawalStatus } from '../../../../types';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';

export const WithdrawalHistory: FC = () => {
  const dispatch = useAppDispatch();
  const [
    {
      BanksPage: {
        Withdrawals: { WithdrawalHistory: Strings },
      },
    },
  ] = getStrings();

  const [history, setHistory] = useState<string[]>([]);
  const [selected, setSelected] = useState<(string | number)[]>([]);

  const {
    withdrawalRequestsHistory,
    paging,
    isLoading,
    isLastFetch,
    setOffset,
    offset,
    statsData,
    statsLoading,
    filters,
    setFilters,
  } = useWithdrawalRequestsHistory();

  const handleExportSelected = useCallback(async () => {
    const file = await dispatch(
      getWithdrawalRequestsHistoryDownload({
        withdrawal_ids: [...(selected as string[])],
        format: 'csv',
      })
    ).unwrap();
    return file;
  }, [dispatch, selected]);

  const handleExportAll = useCallback(async () => {
    const file = await dispatch(
      getWithdrawalRequestsHistoryDownload({
        format: 'csv',
        download_all_filter: {
          completed_period_end:
            filters.end_date && filters.date_column === 'completed_at'
              ? new Date(filters.end_date).toISOString()
              : undefined,
          completed_period_start:
            filters.start_date && filters.date_column === 'completed_at'
              ? new Date(filters.start_date).toISOString()
              : undefined,
          requested_period_end:
            filters.end_date && filters.date_column === 'requested_at'
              ? new Date(filters.end_date).toISOString()
              : undefined,
          requested_period_start:
            filters.start_date && filters.date_column === 'requested_at'
              ? new Date(filters.start_date).toISOString()
              : undefined,
          withdrawal_status: filters.resulted,
        },
      })
    ).unwrap();
    return file;
  }, [
    dispatch,
    filters.date_column,
    filters.start_date,
    filters.end_date,
    filters.resulted,
  ]);

  const handleDownloadCSV = useCallback(
    (csvString: string, fileName: string) => {
      const contentType = 'text/csv';

      // Create blob link to download
      const csvFile = window.URL.createObjectURL(
        new Blob([csvString], { type: contentType })
      );
      const link = document.createElement('a');
      link.href = csvFile;
      link.setAttribute('download', `${fileName}.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    },
    []
  );

  const handleDownloadSelected = useCallback(async () => {
    const data = await handleExportSelected();
    if (!data) return;

    // TODO: file name format undecided, default datetime stamp
    const dateTime = dayjs().format('DD/MM/YYYY_HH:mm:ss');
    const fileName = `Withdrawal_Requests_${dateTime}`;
    handleDownloadCSV(data, fileName);
  }, [handleExportSelected, handleDownloadCSV]);

  const handleDownloadAll = useCallback(async () => {
    const data = await handleExportAll();
    if (!data) return;

    // TODO: file name format undecided, default datetime stamp
    const dateTime = dayjs().format('DD/MM/YYYY_HH:mm:ss');
    const fileName = `Withdrawal_Requests_${dateTime}`;
    handleDownloadCSV(data, fileName);
  }, [handleExportAll, handleDownloadCSV]);

  const dataCols: ColumnConfig<TPunterWithdrawalRequestsDTO>[] = [
    {
      property: 'withdrawal_id',
      primary: true,
      render: () => null,
    },
    {
      property: 'transaction_type',
      sortable: false,
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          textAlign="start"
        >
          {Strings.TableHeadings.TransactionType}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="start">
          <b>{datum?.transaction_type ?? ''}</b>
        </Text>
      ),
    },
    {
      property: 'punter_name',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          textAlign="start"
        >
          {Strings.TableHeadings.PunterName}
        </Text>
      ),
      render: (datum) => (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/punter/${datum.punter_id}`}
        >
          <Text color={GrommetTheme?.global?.colors?.midBlue} textAlign="start">
            {datum?.punter_name ?? ''}
          </Text>
        </Link>
      ),
    },
    {
      property: 'created_at',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          style={{ width: '100%' }}
          textAlign="start"
        >
          {Strings.TableHeadings.Requested}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="start">{formatDateTime24(datum?.created_at)}</Text>
      ),
    },
    {
      property: 'method',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          textAlign="start"
        >
          {Strings.TableHeadings.Method}
        </Text>
      ),
      render: (datum) => <Text textAlign="start">{datum?.method ?? ''}</Text>,
    },
    {
      property: 'status',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          textAlign="start"
        >
          {Strings.TableHeadings.Result}
        </Text>
      ),
      render: (datum) => <Text textAlign="start">{datum?.status ?? ''}</Text>,
    },
    {
      property: 'bookie_name',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          style={{ width: '100%' }}
          textAlign="center"
        >
          {Strings.TableHeadings.By}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{datum?.bookie_name ?? ''}</Text>
      ),
    },
    {
      property: 'completed_at',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          style={{ width: '100%' }}
          textAlign="start"
        >
          {Strings.TableHeadings.Completed}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="start">{formatDateTime24(datum?.completed_at)}</Text>
      ),
    },
    {
      property: 'amount',
      header: (
        <Text
          color={GrommetTheme?.global?.colors?.textOffGrey}
          style={{ width: '100%' }}
          textAlign="center"
        >
          {Strings.TableHeadings.WithdrawalAmount}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{centsToDollars(datum?.amount)}</Text>
      ),
    },
  ];

  return (
    <>
      <Box
        align="center"
        justify="start"
        direction="column"
        pad={{ horizontal: '0px' }}
      >
        <Box
          align="center"
          justify="start"
          direction="column"
          width="100%"
          pad={{ horizontal: '28px' }}
        >
          <Box direction="row" width="100%">
            <Box
              pad={{ horizontal: '40px', top: '6px', bottom: '12px' }}
              align="center"
              justify="center"
              direction="column"
            >
              {statsLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Text
                    size="32px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.black}
                  >
                    {statsData?.approved_today?.count ?? ''}
                  </Text>
                  <Text
                    size="14px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.black}
                  >
                    {centsToDollars(statsData?.approved_today?.amount)}
                  </Text>
                  <Text
                    size="16px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.darkBlue}
                  >
                    {Strings.ApprovedToday}
                  </Text>
                </>
              )}
            </Box>
            <Separator vertical />
            <Box
              pad={{ horizontal: '40px', top: '6px', bottom: '12px' }}
              align="center"
              justify="center"
              direction="column"
            >
              {statsLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Text
                    size="32px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.black}
                  >
                    {statsData?.rejected_today?.count ?? ''}
                  </Text>
                  <Text
                    size="14px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.black}
                  >
                    {centsToDollars(statsData?.rejected_today?.amount)}
                  </Text>
                  <Text
                    size="16px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.darkBlue}
                  >
                    {Strings.RejectedToday}
                  </Text>
                </>
              )}
            </Box>
            <Separator vertical />
            <Box
              pad={{ horizontal: '40px', top: '6px', bottom: '12px' }}
              align="center"
              justify="center"
              direction="column"
            >
              {statsLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Text
                    size="32px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.black}
                  >
                    {statsData?.cancelled_today?.count ?? ''}
                  </Text>
                  <Text
                    size="14px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.black}
                  >
                    {centsToDollars(statsData?.cancelled_today?.amount)}
                  </Text>
                  <Text
                    size="16px"
                    weight="bold"
                    color={GrommetTheme?.global?.colors?.darkBlue}
                  >
                    {Strings.CancelledToday}
                  </Text>
                </>
              )}
            </Box>
            <Separator vertical />
          </Box>
        </Box>
        <Separator />
        <Flex flexDir="row" gap={4} alignSelf="flex-start">
          <Select
            name="resulted"
            isFormik={false}
            label="Resulted"
            value={filters.resulted}
            sx={{ cursor: 'pointer' }}
            sxWrapper={{ width: '300px' }}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setFilters((filters) => ({
                ...filters,
                resulted: e.target.value?.length
                  ? (e.target.value as TPunterWithdrawalStatus)
                  : undefined,
              }));
            }}
          >
            <option key={undefined}></option>
            {Object.values(TPunterWithdrawalStatus)
              ?.filter(
                (status) =>
                  status === TPunterWithdrawalStatus.Processed ||
                  status === TPunterWithdrawalStatus.Rejected ||
                  status === TPunterWithdrawalStatus.Approved
              )
              .map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
          </Select>
          <Box>
            <Text>From</Text>
            <Datepicker
              value={
                filters?.start_date ? new Date(filters?.start_date) : undefined
              }
              onChange={(date) => {
                setFilters((filters) => ({
                  ...filters,
                  start_date: date?.toString(),
                }));
              }}
              innerProps={{ marginRight: '240px' }}
              wrapperProps={{ width: '100px' }}
            />
          </Box>

          <Box>
            <Text>To</Text>

            <Datepicker
              value={
                filters?.end_date ? new Date(filters?.end_date) : undefined
              }
              onChange={(date) => {
                setFilters((filters) => ({
                  ...filters,
                  end_date: date?.toString().replace('00:00:00', '23:59:59'),
                }));
              }}
              innerProps={{ marginRight: '240px' }}
              wrapperProps={{ width: '100px' }}
            />
          </Box>

          <Select
            name="date_filter"
            isFormik={false}
            label="Date Filter Column"
            value={filters.date_column}
            sx={{ cursor: 'pointer', width: '300px' }}
            sxWrapper={{ width: 'unset' }}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              setFilters((filters) => ({
                ...filters,
                date_column:
                  e.target.value === 'requested_at'
                    ? 'requested_at'
                    : 'completed_at',
              }))
            }
          >
            {['completed_at', 'requested_at']?.map((a) => (
              <option key={a} value={a}>
                {a.replace(/_/g, ' ')}
              </option>
            ))}
          </Select>
        </Flex>
        <Box
          align="center"
          justify="start"
          direction="row"
          width="100%"
          pad={{ horizontal: '0px', bottom: '13px' }}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Box width="full">
              {withdrawalRequestsHistory?.length ? (
                <StyledDataTable<TPunterWithdrawalRequestsDTO>
                  columns={dataCols}
                  data={
                    withdrawalRequestsHistory
                      ? (withdrawalRequestsHistory as TPunterWithdrawalRequestsDTO[])
                      : []
                  }
                  fill
                  style={{ width: '100%' }}
                  pad={{ vertical: '16px', horizontal: '4px' }}
                  select={selected}
                  onSelect={(selectedArray) => setSelected(selectedArray)}
                  className="no-border-vertical-selectable"
                />
              ) : (
                <TextChakra my="5">
                  <FormattedMessage
                    id={'bankspage.withdrawals.withdrawalhistory.nomorerecords'}
                  />
                </TextChakra>
              )}
              <PaginationV3
                nextDisabled={isLastFetch}
                offset={offset}
                onPageChange={(newOffset) => setOffset(newOffset)}
                history={history}
                setHistory={setHistory}
                useHistory
                nextOffsetId={!isLastFetch ? paging?.next_offset : undefined}
              />
            </Box>
          )}
        </Box>
      </Box>
      <DataTableMultiSelectBar selected={selected} setSelected={setSelected}>
        <LeftSideMultiActions>
          <MultiExportButton onClick={handleDownloadSelected}>
            <Text>&nbsp; Export selected as CSV</Text>
          </MultiExportButton>
          <MultiExportButton onClick={handleDownloadAll}>
            <Text>&nbsp; Export all pages as CSV</Text>
          </MultiExportButton>
        </LeftSideMultiActions>
      </DataTableMultiSelectBar>
    </>
  );
};
