import { ButtonProps, Icon } from '@chakra-ui/react';
import { ChevronRight } from '@styled-icons/fa-solid/ChevronRight';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonPage } from '../Pagination.styles';

export default function NextPageButton(props: Omit<ButtonProps, 'children'>) {
  return (
    <ButtonPage
      rightIcon={<Icon as={ChevronRight} color="brand.700" />}
      {...props}
    >
      <FormattedMessage id="generic.next" />
    </ButtonPage>
  );
}
