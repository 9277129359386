import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutationSuspendMatch } from '../../../../../../../../../api/tradeManager/sportDetails/suspendMatch/suspendMatch.hooks';
import { useMutationSuspendProposition } from '../../../../../../../../../api/tradeManager/sportDetails/suspendProposition/suspendPropositions.hooks';
import { useMutationCloseSportMarket } from '@/api/tradeManager/sportDetails/closeMarket/closeMarket.hooks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../common/hooks/useRedux';

import {
  getMarkets,
  getMatch,
  getPropositionsForMarket,
} from '../../../../../api';

import { setIsModalConfigureLoading } from '../../../../../slices';
import { EConfigureLevel } from '../../../../../types';
import { EGeneralStatus } from '../../../../../../../../../lib/DBModels';

import { EStatusOptions } from '../Status.types';
import { useModalConfigure } from '../../../services/ModalConfigure.hooks';
import { useMarketModifiers } from '@/features/tradeManager/pages/MatchDetails/services/MatchDetails.hooks';
import { isLevelSuspended, isLevelClosed } from './Status.utils';
import { useMutationCloseProposition } from '@/api/tradeManager/sportDetails/closeProposition/closeProposition.hooks';

export const useStatus = () => {
  const dispatch = useAppDispatch();

  const { onModalConfigureClose } = useModalConfigure();
  const { getMarketProposition } = useMarketModifiers();

  const intl = useIntl();

  const {
    matchMarkets,
    selectedMarketGroup,
    modalConfigureData: { configureLevel, match, market, propositions },
  } = useAppSelector((state) => state.matchDetails);

  const { mutateAsync: mutateCloseProposition } = useMutationCloseProposition();
  const { mutateAsync: mutateCloseSportMarket } = useMutationCloseSportMarket();
  const { mutateAsync: mutateSuspendMatch } = useMutationSuspendMatch();
  const { mutateAsync: mutateSuspendProposition } =
    useMutationSuspendProposition();

  /* To support older components */
  const refetch = async () => {
    await dispatch(
      getMatch({ matchId: match?.match_id ?? '', isUpdate: true })
    );
    await dispatch(
      getMarkets({
        matchId: match?.match_id ?? '',
        marketGroupId: selectedMarketGroup?.market_group_id ?? '',
        isUpdate: true,
      })
    );
    await dispatch(
      getPropositionsForMarket({ marketId: market?.market_id ?? '' })
    );
    if (market) {
      getMarketProposition(market);
    }
  };

  const stub = (() => {
    if (configureLevel === EConfigureLevel.Match)
      return (
        <FormattedMessage id="trademanagerpage.matchdetailstext.modals.allmarkets" />
      );

    if (configureLevel === EConfigureLevel.Market) return market?.market_name;

    if (configureLevel === EConfigureLevel.Proposition)
      return propositions?.[0].proposition_name;
  })();

  /* Add new status options here */
  const statusOptions = [
    {
      option: EStatusOptions.Winter,
      tooltip: intl.formatMessage({
        id: 'trademanagerpage.config.tooltip.winter',
      }),
    },
    {
      option: EStatusOptions.Suspend,
      tooltip: intl.formatMessage({
        id: 'trademanagerpage.config.tooltip.suspend',
      }),
    },
    ...(configureLevel !== EConfigureLevel.Proposition
      ? [
          {
            option: EStatusOptions.CloseHide,
            tooltip: intl.formatMessage({
              id: 'trademanagerpage.config.tooltip.hardclose',
            }),
          },
        ]
      : [
          {
            option: EStatusOptions.Close,
            tooltip: intl.formatMessage({
              id: 'tradeManagerPage.config.tooltip.softCloseProp',
            }),
          },
        ]),
  ];

  const submitStatus = async (value: EStatusOptions) => {
    dispatch(setIsModalConfigureLoading(true));
    const ids = (() => {
      if (configureLevel === EConfigureLevel.Match)
        return matchMarkets.map((m) => m.market_id) ?? [];

      if (configureLevel === EConfigureLevel.Proposition)
        return propositions?.map((p) => p.proposition_id) ?? [];

      /* configureLevel === EConfigureLevel.Market */
      return [market?.market_id];
    })();

    const closePropositionBody = ids.map((id) => ({
      proposition_id: id,
      lock_status: value === EStatusOptions.Close,
      status:
        value === EStatusOptions.Close
          ? EGeneralStatus.Closed
          : EGeneralStatus.Open,
    }));

    const closeBody = {
      match_id: match?.match_id ?? '',
      market_ids: ids,
      lock_status: value === EStatusOptions.CloseHide,
      status:
        value === EStatusOptions.CloseHide
          ? EGeneralStatus.Closed
          : EGeneralStatus.Open,
      is_visible: value === EStatusOptions.Winter,
      for_all_markets:
        ids.length === matchMarkets.length && value !== EStatusOptions.Suspend,
    };

    const suspendBody = {
      id: match?.match_id ?? '',
      ids,
      suspend: value === EStatusOptions.Suspend,
    };

    if (
      configureLevel === EConfigureLevel.Match ||
      configureLevel === EConfigureLevel.Market
    ) {
      await mutateCloseSportMarket([closeBody]);
      await mutateSuspendMatch(suspendBody);
    }

    if (configureLevel === EConfigureLevel.Proposition) {
      await mutateSuspendProposition(suspendBody);
      await mutateCloseProposition(closePropositionBody);
    }

    await refetch();
    onModalConfigureClose();
  };

  /* Initial value for the status form */
  const initialValue = {
    status: (() => {
      if (
        isLevelSuspended({
          configureLevel,
          matchMarkets,
          market,
          propositions,
        })
      )
        return EStatusOptions.Suspend;

      if (isLevelClosed({ configureLevel, matchMarkets, market, propositions }))
        return configureLevel === EConfigureLevel.Proposition
          ? EStatusOptions.Close
          : EStatusOptions.CloseHide;

      return EStatusOptions.Winter;
    })(),
  };

  return {
    stub,
    initialValue,
    statusOptions,
    submitStatus,
  };
};
