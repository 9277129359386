import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkDispatch } from '../../../../../../app/types';
import { apiGetRequest } from '../../../../../../lib/api/api';
import {
  TBetPendingInput,
  TBetRequestHistoryInput,
  TBetRequestHistoryResponse,
  TRequestBet,
} from './BetApprovals.types';
import {
  setPendingBetRequests,
  setHistoryBetRequests,
} from './BetApprovals.slices';

export const getBetRequestsPending = createAsyncThunk<
  TRequestBet[],
  TBetPendingInput,
  { dispatch: AppThunkDispatch }
>('betApprovals/getRequestsPending', async (input, thunkAPI) => {
  const bets = await apiGetRequest<TRequestBet[]>(
    `/bookie/bet-approvals`,
    input
  );

  thunkAPI.dispatch(setPendingBetRequests(bets));

  return bets;
});

export const getBetApprovalHistory = createAsyncThunk<
  TBetRequestHistoryResponse,
  TBetRequestHistoryInput,
  { dispatch: AppThunkDispatch }
>('betApprovals/getHistory', async (inputData, thunkAPI) => {
  const bets = await apiGetRequest<TBetRequestHistoryResponse>(
    `/bookie/bet-approvals/history`,
    inputData
  );

  thunkAPI.dispatch(setHistoryBetRequests(bets));

  return bets;
});
