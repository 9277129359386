import { rest } from 'msw';
import { TPunterProfile } from '@/api/punterProfile/punterProfile.types';
import { ENV } from '@/lib/Constants';
import { PunterStatuses } from '@/api/api.schema.d';

export const PunterMockData: TPunterProfile = {
  punter_id: '1',
  first_name: 'Joe',
  last_name: 'Bloggs',
  phone_number: '0400000000',
  verification_status: false,
  email: 'joe@bloggs.com',
  dob: '1990-01-01',
  street_name: 'Joe Street',
  street_number: '1',
  suburb: 'Demo Suburb',
  post_code: '2000',
  state: 'QLD',
  account_status: PunterStatuses[0],
  self_exclusion_days: 0,
  self_exclusion_expiry: '',
  flags: [
    { bookie_id: '123', label: 'suspicious', colour: '#F26525' },
    { bookie_id: '123', label: 'high risk', colour: '#F26525' },
  ],
  bookie_category: 'High Roller',
  use_storm_category: false,
};

const baseURL = ENV.REACT_APP_API_URL;

const handlerPunterProfileGet = rest.get(
  `${baseURL}/bookie/punters/profile/:punterID`,
  (req, res, ctx) => {
    const { punterID } = req.params;

    return res(
      ctx.json({
        ...PunterMockData,
        punter_id: punterID,
      })
    );
  }
);

const handlerPunterProfilePost = rest.post<TPunterProfile>(
  `${baseURL}/bookie/punters/profile`,
  (req, res, ctx) =>
    res(
      ctx.json({
        ...req.body,
        self_exclusion_days: 31,
        self_exclusion_expiry: '2022-07-01 00:00',
        account_status: PunterStatuses[3],
        verification_status: true,
      })
    )
);

export const handlersProfile = [
  handlerPunterProfileGet,
  handlerPunterProfilePost,
];
