import api from '@/api/api';
import { TMarketBetResponse } from '../../trademanager.types';
import { TBetsForRunnerParams } from './betsForRunner.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const betsForRunnerURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/bets-for-runner`;

// Bets For Runner
export const queryBetsForRunner = (params: TBetsForRunnerParams) =>
  api
    .get<TMarketBetResponse>(betsForRunnerURL, { params })
    .then((res) => res.data);
