import { useQuery } from 'react-query';
import { keys } from '../../api/api.keys';
import { queryTableau } from '../../api/scoreboard/scoreboard';

export const useTableau = () => {
  const { data } = useQuery([keys.scoreboard], () => queryTableau());

  const token = data?.token;
  const reportLink = data?.link ? data?.link + '?:refresh=yes' : undefined;

  return { token, reportLink };
};
