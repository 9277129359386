export const colors = {
  brand: {
    100: '#c2e2f9',
    200: '#9acff5',
    300: '#72bbf0',
    400: '#5db1ee',
    500: '#359eea',
    600: '#308ed3',
    700: '#2a7ebb',
    800: '#256fa4',
    900: '#1b4f75',
  },
  accent: {
    100: '#102534',
    200: '#ECECEC',
    300: '#FFF',
    400: '#FFF',
    500: '#FFF',
    600: '#FFF',
    700: '#FFF',
    800: '#FFF',
    900: '#FFF',
  },
  green: {
    50: '#c6e480',
    100: '#bade66',
    200: '#afd94d',
    300: '#a3d333',
    400: '#98ce19',
    500: '#8CC800',
    600: '#7eb400',
    700: '#70a000',
    800: '#628c00',
    900: '#547800',
  },
  red: {
    50: '#ff99b1',
    100: '#ff809d',
    200: '#ff6689',
    300: '#ff4d76',
    400: '#ff194f',
    500: '#ff003b',
    600: '#e60035',
    700: '#cc002f',
    800: '#b30029',
    900: '#990023',
  },
  pink: {
    50: '#ffc3d0',
    100: '#ffc0ce',
    200: '#f2b6c4',
    300: '#e6adb9',
    400: '#d9a3af',
    500: '#cc9aa5',
    600: '#bf909b',
    700: '#b38690',
    800: '#a67d86',
    900: '#99737c',
  },
  origin: {
    50: '#eff5ff',
    100: '#283a8f',
    200: '#2f44a7',
    300: '#364ebe',
    400: '#3c57d6',
    500: '#4361ee',
    600: '#1b275f',
    700: '#141d47',
    800: '#0d1330',
    900: '#070a18',
  },
};
