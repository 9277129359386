import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateUpdateMarket } from './updateMarket';
import { TUpdateMarketBody } from './updateMarket.types';

export const useMutationUpdateMarket = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TUpdateMarketBody>,
    'mutationFn'
  >
) => useMutation(mutateUpdateMarket, options);
