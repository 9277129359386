import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';

import {
  setIgnoreWinterSwitch,
  setIsConfigureRaceModalOpen,
  setManualManageToggle,
  setRaceConfigLoading,
} from '../../../Services/RaceDetails.slices';
import { loadSwitchState, saveSwitchState } from './ManageRaceConfig.utils';
import { EStorageKey } from '@/common/localStorage/localStorage.keys';

export const useRaceConfig = () => {
  const dispatch = useAppDispatch();

  const { raceData, ignoreWinterSwitchOn } = useAppSelector(
    (state) => state.raceDetails
  );

  const manuallyManageToggleState = loadSwitchState(EStorageKey.ManuallyManage);

  /* Load switch states */
  useEffect(() => {
    if (!raceData.race_id) return;

    const manuallyManageToggleState = loadSwitchState(
      EStorageKey.ManuallyManage
    );

    dispatch(
      setManualManageToggle(
        manuallyManageToggleState.includes(raceData.race_id)
      )
    );
    dispatch(setIgnoreWinterSwitch(!!raceData.lock_status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = async ({ value, key }: { value: boolean; key: string }) => {
    if (!raceData.race_id) return;

    dispatch(setRaceConfigLoading(true));

    if (key === EStorageKey.ManuallyManage) {
      /* Saving the state in local storage */
      saveSwitchState(!value, raceData.race_id, key);
      dispatch(setManualManageToggle(!value));
    }

    dispatch(setRaceConfigLoading(false));
  };

  const openConfigModal = () => dispatch(setIsConfigureRaceModalOpen(true));

  return {
    ignoreWinter: ignoreWinterSwitchOn,
    manualManage: (
      manuallyManageToggleState as (string | undefined)[]
    ).includes(raceData.race_id),
    toggle,
    openConfigModal,
  };
};
