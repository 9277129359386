import PunterInfo from '@/common/components/PunterInfo/PunterInfo';
import { centsToDollars } from '@/common/utils';
import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';

export const useBlendedRunnerColumns = () => {
  const combinationColumns = useMemo<
    ColumnDef<Api['BlendedCombinationStatsItemResponse']>[]
  >(
    () => [
      {
        id: 'combinations',
        header: 'Combination',
        cell: ({ row }) => {
          const { runners } = row.original;

          return runners?.map((runner) => (
            <p key={runner.name}>{`${runner.number}. ${runner.name}`}</p>
          ));
        },
      },
      {
        id: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => {
          const { bets_count } = row.original;

          return bets_count;
        },
      },
      {
        id: 'punters_count',
        header: 'Number of Punters',
        cell: ({ row }) => {
          const { punters_count } = row.original;

          return punters_count;
        },
      },
      {
        id: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        id: 'odds',
        header: 'Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        id: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  const combinationSubColumns = useMemo<
    ColumnDef<Api['BlendedCombinationPunterStatsItemResponse']>[]
  >(
    () => [
      {
        accessorKey: 'punters',
        header: 'Punters',
        cell: ({ row }) => (
          <PunterInfo
            punter_category={row.original.category}
            punter_name={row.original.name}
            id={row.original.id}
          />
        ),
      },
      {
        accessorKey: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => {
          const { bets_count } = row.original;

          return bets_count;
        },
      },
      {
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        accessorKey: 'odds',
        header: 'Avg Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  return { combinationColumns, combinationSubColumns };
};
