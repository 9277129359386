import { useState } from 'react';
import { useQuery as useQueryString } from '@/common/hooks/useQuery';
import {
  useQuerySRMCombinations,
  useQuerySRMPunterCombinations,
} from '@/api/tradeManager/srm/srm.hooks';

export const useSRMCombinations = () => {
  const q = useQueryString();
  const id = q.get('id');
  const checkId = id || '';
  const limit = 20;

  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState('');
  const [offsetHistory] = useState<string[]>([]);

  const shouldRefetch = pageIndex === 0;

  const { data, isLoading } = useQuerySRMCombinations({
    params: {
      offset,
      limit,
      race_id: checkId,
    },
    options: {
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
      staleTime: shouldRefetch ? 0 : 60 * 5000,
    },
  });

  const next = () => {
    if (!data) return;
    setOffset(data.paging?.next_offset ?? '');
    setPageIndex((prevIdx) => ++prevIdx);
  };
  const previous = () => {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  };

  return {
    data,
    isLoading,
    next,
    previous,
    previousDisabled: pageIndex === 0,
    nextDisabled: data?.paging?.next_offset === null,
    hidePagination: pageIndex === 0 && data?.paging?.next_offset === null,
  };
};

export const useSRMPunterCombinations = (multi_uid: string | null) => {
  const limit = 20;

  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState('');
  const [offsetHistory] = useState<string[]>([]);

  const shouldRefetch = pageIndex === 0;

  const { data, isLoading } = useQuerySRMPunterCombinations({
    params: {
      offset,
      limit,
      multi_uid: multi_uid,
    },
    options: {
      enabled: !!multi_uid && multi_uid !== '',
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
      staleTime: shouldRefetch ? 0 : 60 * 5000,
    },
  });

  const nextInner = () => {
    if (!data) return;
    setOffset(data.paging?.next_offset ?? '');
    setPageIndex((prevIdx) => ++prevIdx);
  };
  const previousInner = () => {
    if (pageIndex === 0) return;
    setOffset(offsetHistory[pageIndex]);
    setPageIndex((prevIdx) => --prevIdx);
  };

  return {
    dataInner: data,
    isLoadingInner: isLoading,
    nextInner,
    previousInner,
    previousDisabledInner: pageIndex === 0,
    nextDisabledInner: data?.paging?.next_offset === null,
    hidePaginationInner: pageIndex === 0 && data?.paging?.next_offset === null,
  };
};
