import React from 'react';
import { Text, Box, Flex, Image, ModalBody, Switch } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { srcDefaultRunnerSilk } from '../../../../../../../../assets/icons';
import Input from '../../../../../../../../common/components/FormElements/Input';
import { useSettle } from '../services/Hooks.Settle';
import BannerValidation from './BannerValidation';
import { EGeneralStatus } from '../../../../../../../../lib/DBModels';
import { useFormikContext } from 'formik';
import { TSettleFormValues } from '../Types';

export default function Form() {
  const { data, showToggleProtestOverrride } = useSettle();
  const { values, setFieldValue } = useFormikContext<TSettleFormValues>();

  return (
    <ModalBody sx={{ pt: `0 !important` }}>
      <Box>
        {showToggleProtestOverrride && (
          <Flex
            borderBottom="1px"
            borderBottomColor="blackAlpha.100"
            py="2"
            alignItems="center"
          >
            <Switch
              size="sm"
              isChecked={!!values.protest_override}
              onChange={() => {
                setFieldValue('protest_override', !values.protest_override);
              }}
            />
            <Text textAlign="left" fontSize="xs" color="gray.600" ml="1">
              <FormattedMessage id="trademanagerpage.config.disableProtest" />
            </Text>
          </Flex>
        )}
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          borderBottom="1px"
          borderBottomColor="blackAlpha.100"
          h="40px"
          pb="2px"
        >
          <Text fontWeight="medium" fontSize="xs" color="gray.400">
            <FormattedMessage id="trademanagerpage.racedetails.modal.settlement.runner" />
          </Text>
          <Text fontWeight="medium" fontSize="xs" color="gray.400">
            <FormattedMessage id="trademanagerpage.racedetails.modal.settlement.orderbyrunner" />
          </Text>
        </Flex>

        {data.map((raceRunner, i) => {
          if (!raceRunner) return null;

          const isScratched = raceRunner.status === EGeneralStatus.Scratched;
          const isRemoved = raceRunner.status === EGeneralStatus.Removed;

          return (
            <Flex
              align="center"
              key={raceRunner.race_runner_id ?? i}
              py="2"
              borderBottom="1px"
              borderBottomColor="blackAlpha.100"
            >
              <Image
                src={raceRunner.silk_url ?? ''}
                fallbackSrc={srcDefaultRunnerSilk}
                mr="4"
                boxSize="32px"
              />

              <Text
                fontSize="sm"
                fontWeight="medium"
                textTransform="lowercase"
                mr="auto"
                sx={{
                  textDecoration:
                    isScratched || isRemoved ? 'line-through' : '',
                  ':first-letter': { textTransform: 'uppercase' },
                }}
              >
                {(() => {
                  if (isScratched) return 's. ';
                  if (isRemoved) return 'r. ';
                  if (raceRunner.results_place)
                    return `${raceRunner.results_place}. `;
                  return null;
                })()}
                {`(${raceRunner.number})`} {raceRunner.display_name} (
                {raceRunner.barrier_number})
              </Text>
              {(() => {
                if (isScratched || isRemoved)
                  return (
                    <Text fontSize="xs" fontWeight="medium" color="gray.400">
                      <FormattedMessage
                        id={`trademanagerpage.racedetails.modal.settlement.${raceRunner.status?.toLowerCase()}`}
                      />
                    </Text>
                  );

                return (
                  <Input
                    name={String(raceRunner.number ?? '')}
                    size="sm"
                    sx={{ width: '32px', px: 0, textAlign: 'center' }}
                    sxWrapper={{ ml: 'auto', width: 'auto', mb: 0 }}
                    propsError={{ sx: { display: 'none' } }}
                  />
                );
              })()}
            </Flex>
          );
        })}

        <BannerValidation />
      </Box>
    </ModalBody>
  );
}
