import { Modal as BCModal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import FormHOC from './FormHOC';
import Form from './Form';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../common/hooks/useRedux';
import { centsToDollars, getStrings } from '../../../../../common/utils';
import { setModalVoidBet } from '../../../../punters/pages/Bets/Services/Slice.Bets';

type TModal = {
  onVoided?: () => void;
};

export default function VoidBetModal({ onVoided }: TModal) {
  const dispatch = useAppDispatch();
  const [
    {
      Generic,
      PunterPage: {
        Bets: { Modal: ModalString },
      },
    },
  ] = getStrings();

  const { modalVoidBet } = useAppSelector((state) => state.punterBets);

  if (!modalVoidBet) return null; // We do not want to mount the below if the modal obj is null

  const heading = `
       ${Generic.VoidOrCancel} ${modalVoidBet?.punter_name ?? ''}
       ${'\n'}
       ${centsToDollars(modalVoidBet?.stake ?? 0).replace(/\.00$/, '')}
       ${ModalString.Misc.stake} ${
    modalVoidBet?.event_title
      ? `${ModalString.Misc.On} ${modalVoidBet?.event_title}`
      : ''
  }`;

  return (
    <BCModal isOpen onClose={() => dispatch(setModalVoidBet(undefined))}>
      <ModalOverlay bg="blackAlpha.100" backdropFilter="blur(0px)" />

      <FormHOC betID={modalVoidBet?.bet_id ?? ''} onVoided={onVoided}>
        <ModalContent>
          <Form heading={heading} />
        </ModalContent>
      </FormHOC>
    </BCModal>
  );
}
