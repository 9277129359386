import React from 'react';
import loadable from '@loadable/component';
import { FC } from 'react';
import Loader from '@/components/Routes/Loader';

const ForgotPassword = loadable(
  () => import('@/features/onboarding/pages/ForgotPassword/ForgotPassword'),
  { fallback: <Loader /> }
) as FC;

export { ForgotPassword };
