import React, { FC } from 'react';
import { IconButton, Icon, ButtonProps } from '@chakra-ui/react';
import { ArrowLeft } from '@styled-icons/fa-solid/ArrowLeft';

export const BackButton: FC<ButtonProps> = ({ sx, ...rest }) => (
  <IconButton
    {...rest}
    aria-label="back-btn"
    type="button"
    size="lg"
    borderRadius="md"
    sx={{
      bg: 'blackAlpha.50',
      boxShadow: 'none !important',
      ':hover, :active, :focus': {
        bg: 'blackAlpha.200',
      },
      ...sx,
    }}
    icon={<Icon color="brand.900" as={ArrowLeft} />}
  />
);
