import React from 'react';
import Loader from '@/components/Routes/Loader';
import loadable from '@loadable/component';
import { FC } from 'react';

const Deposits = loadable(
  () => import('@/features/banking/pages/Bank/tabs/Deposits'),
  { fallback: <Loader /> }
) as FC;

const BankAnalysisDashboard = loadable(
  () => import('@/features/banking/pages/Bank/tabs/BankAnalysisDashboard'),
  { fallback: <Loader /> }
) as FC;

export { Deposits, BankAnalysisDashboard };
