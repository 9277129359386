import { useIntl } from 'react-intl';

export default function useCurrencySymbol() {
  const intl = useIntl();
  const currencySymbol =
    intl
      .formatNumberToParts(0, { style: 'currency', currency: 'AUD' })
      .find(({ type }) => type === 'currency')?.value ?? '$';

  return currencySymbol;
}
