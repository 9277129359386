import api from '@/api/api';
import { TGetMarketParams, TMarket } from './getMarket.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const getMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/get-market`;

// Trade Manager - Get Market

export const queryGetMarket = (params: TGetMarketParams) =>
  api.get<TMarket[]>(getMarketURL, { params }).then((res) => res.data);
