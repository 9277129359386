export enum EPositions {
  FirstPlaces = 'first_places',
  SecondPlaces = 'second_places',
  ThirdPlaces = 'third_places',
  ForthPlaces = 'forth_places',
  Scratched = 'scratched',
  Removed = 'removed',
}

export type TSettleRaceProps = {
  race_id: string;
  [EPositions.FirstPlaces]: string[];
  [EPositions.SecondPlaces]: string[];
  [EPositions.ThirdPlaces]: string[];
  [EPositions.ForthPlaces]: string[];
  [EPositions.Scratched]: string[];
  [EPositions.Removed]: string[];
};

export enum ESettleStatus {
  Winter = 'winter',
  Manual = 'manual',
}

export type TSettleFormValues = {
  protest_override: boolean;
  [key: string]: string | boolean;
};
