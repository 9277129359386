import { useQueryPunterProfile } from '@/api/punterProfile/punterProfile.hooks';
import { useNavPanel } from '@/common/ui/Navigation/Navigation.hooks';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

export const useNavigation = () => {
  const { expandNavPanel } = useNavPanel();
  const { punterId } = useParams();
  const intl = useIntl();

  const { data: punter, isLoading } = useQueryPunterProfile({
    params: { punterId },
    options: {
      refetchInterval: 0,
    },
  });

  const location = useLocation();
  const breadCrumbs = location.pathname.split('/').filter((x) => x);

  return { breadCrumbs, intl, isLoading, punter, punterId, expandNavPanel };
};
