import React from 'react';
import { Box } from '@chakra-ui/react';

export default function GoingInPlayBadge() {
  return (
    <Box
      sx={{
        textTransform: 'none',
        textAlign: 'center',
        borderRadius: 'lg',
        px: 2,
        fontSize: 'xs',
        color: 'black',
        borderWidth: 1,
        borderColor: 'black',
        bg: 'yellow.400',
      }}
    >
      Going In-Play
    </Box>
  );
}
