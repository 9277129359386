import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { betApprovalLimitDefault } from './BetApprovalLimit.config';
import {
  dollarsToCents,
  centsToDollars,
} from '../../../../../../../../../common/utils';
import { TBetApprovalLimitValues } from '../../../types';
import {
  queryExposureLimit,
  updateExposureLimit,
} from '../../../../../../../../../api/settings/limits/exposureLimit';
import {
  queryExoticLimit,
  updateExoticLimit,
} from '../../../../../../../../../api/settings/limits/exoticLimit';
import {
  queryCrossGameMultiLimit,
  updateCrossGameMultiLimit,
} from '../../../../../../../../../api/settings/limits/crossGameMulti';
import { keys } from '../../../../../../../../../api/api.keys';
import {
  queryGlobalBetLimit,
  updateGlobalBetLimit,
} from '../../../../../../../../../api/settings/limits/globalBetLimit';

export const useBetApprovalLimit = () => {
  const [betApprovalLimit, setBetApprovalLimit] = useState(
    betApprovalLimitDefault
  );

  const { data: globalLimitData } = useQuery(
    [keys.settings.limits.betApproval.globalLimit],
    () => queryGlobalBetLimit()
  );

  const { data: exposureLimitData } = useQuery(
    [keys.settings.limits.betApproval.exposureLimit],
    () => queryExposureLimit()
  );

  const { data: exoticLimitData } = useQuery(
    [keys.settings.limits.betApproval.exoticLimit],
    () => queryExoticLimit()
  );

  const { data: crossGameMultiLimitData } = useQuery(
    [keys.settings.limits.betApproval.crossGameMultiLimit],
    () => queryCrossGameMultiLimit()
  );
  const { mutate: mutateGlobalBetLimit } = useMutation(updateGlobalBetLimit);

  const { mutate: mutateExposureLimit } = useMutation(updateExposureLimit);

  const { mutate: mutateExoticLimit } = useMutation(updateExoticLimit);

  const { mutate: mutateCrossGameMultiLimit } = useMutation(
    updateCrossGameMultiLimit
  );

  const updateBetApprovalLimit = (
    updatedBetApprovalLimit: TBetApprovalLimitValues
  ) => {
    if (updatedBetApprovalLimit.limit !== betApprovalLimit.limit) {
      mutateGlobalBetLimit({
        limit: parseInt(dollarsToCents(updatedBetApprovalLimit.limit), 10),
      });
    }

    if (
      updatedBetApprovalLimit.exposure_limit !== betApprovalLimit.exposure_limit
    ) {
      mutateExposureLimit({
        limit: parseInt(
          dollarsToCents(updatedBetApprovalLimit.exposure_limit),
          10
        ),
      });
    }

    if (
      updatedBetApprovalLimit.exotic_limit !== betApprovalLimit.exotic_limit
    ) {
      mutateExoticLimit({
        limit: parseInt(
          dollarsToCents(updatedBetApprovalLimit.exotic_limit),
          10
        ),
      });
    }

    if (
      updatedBetApprovalLimit.cross_game_limit !==
      betApprovalLimit.cross_game_limit
    ) {
      mutateCrossGameMultiLimit({
        limit: parseInt(
          dollarsToCents(updatedBetApprovalLimit.cross_game_limit),
          10
        ),
      });
    }

    setBetApprovalLimit(updatedBetApprovalLimit);
  };

  useEffect(() => {
    setBetApprovalLimit({
      limit: globalLimitData ? centsToDollars(globalLimitData, false) : '0.00',
      exposure_limit: exposureLimitData
        ? centsToDollars(exposureLimitData, false)
        : '0.00',
      exotic_limit: exoticLimitData
        ? centsToDollars(exoticLimitData, false)
        : '0.00',
      cross_game_limit: crossGameMultiLimitData
        ? centsToDollars(crossGameMultiLimitData, false)
        : '0.00',
    });
  }, [
    globalLimitData,
    crossGameMultiLimitData,
    exoticLimitData,
    exposureLimitData,
    setBetApprovalLimit,
  ]);

  return {
    betApprovalLimit,
    updateBetApprovalLimit,
  } as const;
};
