export const en = {
  'generic.save': 'Save',
  'generic.confirm': 'Confirm',
  'generic.cancel': 'Cancel',
  'generic.configure': 'Configure',
  'generic.previous': 'Previous',
  'generic.next': 'Next',
  'generic.standarderrormessage': 'An unexpected error occurred.',
  'generic.saveupdate': 'Save & update',
  'generic.saveconfirm': 'Save & confirm',
  'generic.yesconfirm': 'Yes & confirm',
  'generic.error': 'An unspecified error occurred. Please try again later.',
  'generic.errorTitle': 'Error.',
  'generic.pickdate': 'Pick a Date',
  'generic.allbets': 'All Bets',
  'generic.dollarsign': '$',
  'generic.market': 'Market',
  'generic.match': 'Match',
  'generic.bet': 'Bet',
  'generic.odds': 'Odds',
  'generic.return': 'Return',
  'generic.current': 'Current',
  'generic.close': 'Close',
  'generic.open': 'Open',
  'generic.email': 'Email',
  'generic.proposition': 'Proposition',
  'generic.password': 'Password',
  'generic.termsconditions': 'Terms & Conditions',
  'generic.privacypolicy': 'Privacy Policy',
  'generic.submit': 'Submit',
  'generic.savechangesbutton': 'Save Changes',
  'generic.comingsoon': 'Coming soon...',
  'generic.viewall': 'View All',
  'generic.viewdetails': 'View Details',
  'generic.alloptions': 'All',
  'generic.tab': 'TAB',
  'generic.gg': 'GG',
  'generic.date': 'Date',
  'generic.nodata': 'No data to display',
  'generic.exposure': 'Exposure',
  'generic.update': 'UPDATE',
  'generic.scratched': 'SCRATCHED',
  'generic.withrecordsselected': 'With records selected',
  'generic.refund': 'Refund',
  'generic.void': 'Void',
  'generic.voidorcancel': 'Cancel or Void',
  'generic.done': 'Done',
  'generic.stake': 'Stake',
  'generic.racevoid': 'Race void',
  'generic.matchvoid': 'Match void',
  'generic.suspended': 'Suspended',
  'generic.home': 'Home',
  'generic.punterplayertypes.highroller': 'High Roller',
  'generic.punterplayertypes.recreational': 'Recreational',
  'generic.punterplayertypes.newbie': 'Newbie',
  'generic.punterplayertypes.player': 'Player',
  'generic.punterplayertypes.sharp': 'Sharp',
  'generic.fileuploadmodal.title': 'Upload File',
  'generic.fileuploadmodal.uploaderrortext':
    'Error uploading file, please try again',
  'generic.fileuploader.choosefile': 'Choose file',
  'generic.fileuploader.changefile': 'Change file',
  'generic.fileuploader.typetext': 'Photos or Documents',
  'generic.fileuploader.uploadtext': 'Click or drop file to upload',
  'generic.fileuploader.uploaderror':
    'There was an error with your upload. Please ensure your file is a PNG, JPG or PDF and less than 5mb.',
  'generic.tooltip.title': 'Remove File?',
  'generic.tooltip.canceltext': 'No, Cancel',
  'generic.tooltip.confirmtext': 'Yes, Remove',
  'generic.deletenotemodal.title': 'Delete Note',
  'generic.deletenotemodal.subtitle':
    'Are you sure you want to delete this note?',
  'generic.deletenotemodal.cancelbuttontext': 'Cancel',
  'generic.deletenotemodal.confirmbuttontext': 'Delete',
  'generic.betstatus.pending': 'Pending',
  'generic.betstatus.partially_settled': 'Partially Settled',
  'generic.betstatus.partial': 'Partial',
  'generic.betstatus.settled': 'Settled',
  'generic.betstatus.voided': 'Voided',
  'generic.betstatus.cashed_out': 'Cashed Out',
  'generic.betstatus.rejected': 'Rejected',
  'generic.betstatus.approved': 'Approved',
  'generic.betstatus.reducedstake': 'Partial',
  'generic.betstatus.cancelled': 'Cancelled',
  'generic.authorization.noPermission': 'You do not have permission to access.',
  'generic.authorization.accessDenied': 'Access Denied',
  'generic.authorization.error': 'An error occurred. Please try again later.',
  'generic.notAvailable': 'Not available.',
  'generic.yes': 'Yes',
  'generic.no': 'No',
  'generic.na': 'N/A',
  'generic.dateTo': 'Date to',
  'generic.dateFrom': 'Date from',
  'generic.winSP': 'Win (SP)',
  'generic.placeSP': 'Place (SP)',
  'generic.atStartingPriceAbbrev': '@SP',
  'generic.startingPriceAcronym': 'SP',
  'generic.sus': 'SUS',
  'errorpage.title': 'Error',
  'errorpage.message': 'An unspecified error occurred. Please try again later.',
  'errorpage.button': 'Refresh',
  'autherrormessages.errorcreatingaccount':
    'There was an issue creating your account:',
  'autherrormessages.wrongcredentials':
    "Your credentials don't seem to be correct. Please try again.",
  'autherrormessages.emailinuse': 'This email address is already in use.',
  'autherrormessages.userdisabled': 'Your account has been disabled.',
  'autherrormessages.unspecifiederror':
    'An unspecified error occurred. Please try again later.',
  'autherrormessages.usernotfound': 'Unable to find your account.',
  'autherrormessages.exceededresetlimit':
    'You have exceeded your reset password limit. Please try again later',
  'mainheader.userprofile': 'User Profile',
  'mainheader.viewprofile': 'View Profile',
  'mainheader.notifications.approval':
    '{punter} has a bet pending approval {stake} stake {odds} odds on {on}',
  'mainheader.logout': 'Log Out',
  'loginpage.header': 'Welcome back',
  'loginpage.subheader': 'Please log into your account below.',
  'loginpage.forgotpassword': 'Forgot Password?',
  'loginpage.haveanaccount': "Don't have and account?",
  'loginpage.signup': 'Sign Up',
  'loginpage.errors.generic': 'Incorrect email or password',
  'loginpage.errors.invalid.email': 'Invalid email address',
  'loginpage.errors.required.email': 'Email required',
  'loginpage.errors.required.password': 'Password required',
  'forgotpassword.heading': 'Forgot Password',
  'forgotpassword.subheading':
    'Please enter your email below and we will send you a reset link.',
  'forgotpassword.successtext': 'Thank you. We’ve sent a reset link to ',
  'forgotpassword.loginlink': 'Click here to login',
  'forgotpassword.button': 'Send reset link',
  'forgotpassword.buttonsubmitting': 'Submitting...',
  'forgotpassword.errors.invalid.email': 'Invalid email address',
  'forgotpassword.errors.required.email': 'Email required',
  'punterspage.name': 'Name',
  'punterspage.flags': 'Flags',
  'punterspage.balance': 'Balance',
  'punterspage.location': 'Location',
  'punterspage.joined': 'Joined',
  'punterspage.turnover': 'Turnover',
  'punterspage.lastbet': 'Last Bet',
  'punterspage.lastbetdate': 'Last Bet Date',
  'punterspage.bets': 'Bets',
  'punterspage.averagebet': 'Avg. Bet',
  'punterspage.bsp': 'BSP',
  'punterspage.winlost': 'Won/Lost',
  'punterspage.status': 'Status',
  'punterspage.headertitle': 'Punters',
  'punterspage.flagOption': 'All Punters',
  'punterspage.nodata': 'N/A',
  'punterspage.searchplaceholder': 'Search...',
  'punterpage.header.pageheader': 'Punter Profile',
  'punterpage.header.playerstatus': 'Player Status',
  'punterpage.header.category': 'Category',
  'punterpage.header.manualoverride': 'Manual Override',
  'punterpage.header.currentbalance': 'Current Balance',
  'punterpage.header.bonusbalance': 'Bonus Balance',
  'punterpage.header.bonusgiven': 'Bonus Given',
  'punterpage.header.bsp': 'BSP',
  'punterpage.header.turnover': 'Turnover',
  'punterpage.header.wonlost': 'Won/Lost',
  'punterpage.header.lastbet': 'Last Bet',
  'punterpage.header.avgbet': 'Average Bet',
  'punterpage.header.betsplaced': 'Bets Placed',
  'punterpage.header.active': 'Active',
  'punterpage.header.inactive': 'Inactive',
  'punterpage.header.stormscoreempty': '0.00',
  'punterpage.routename.dashboard': 'DashBoard',
  'punterpage.routename.bets': 'Bets',
  'punterpage.routename.payments': 'Payments',
  'punterpage.routename.deposits': 'Deposits',
  'punterpage.routename.incentives': 'Promos',
  'punterpage.routename.limits': 'Limits',
  'punterpage.routename.notes': 'Notes',
  'punterpage.routename.noteswithcount': 'Notes (%)',
  'punterpage.routename.profile': 'Profile',
  'punterpage.routename.chat': 'Chat',
  'punterpage.routename.activitylog': 'Activity Log',
  'punterpage.routename.transactions': 'Transactions',
  'punterpage.profile.managetheirbalances': 'Manage Their Balances',
  'punterpage.profile.payoutamount': 'Payout this Punter a Custom Amount',
  'punterpage.profile.personaldetails': 'Their Personal Details',
  'punterpage.profile.licensepassportnumber': 'License/Passport Number',
  'punterpage.profile.kyc.title': 'KYC Status',
  'punterpage.profile.kyc.verified': 'Verified',
  'punterpage.profile.kyc.unverified': 'Unverified',
  'punterpage.profile.status.types.open': 'Open',
  'punterpage.profile.status.types.closed': 'Closed',
  'punterpage.profile.status.types.suspended': 'Suspended',
  'punterpage.profile.status.types.exclusion_temporary': 'Exclusion Temporary',
  'punterpage.profile.status.types.exclusion_permanent': 'Exclusion Permanent',
  'punterpage.profile.status.types.temporary_lockout': 'Temporary Lockout',
  'punterpage.profile.status.types.permanent_lockout': 'Permanent Lockout',
  'punterpage.profile.status.types.locked_temporary': 'Locked Temporary',
  'punterpage.profile.status.types.locked_permanent': 'Locked Permanent',
  'punterpage.profile.status.title': 'Status',
  'punterpage.profile.status.exclusionperiods.placeholder': 'Select a period',
  'punterpage.profile.status.exclusionperiods.expires': 'expires',
  'punterpage.profile.status.exclusionperiods.title': 'Exclusion Period',
  'punterpage.profile.status.exclusionperiods.period24': '24 hrs',
  'punterpage.profile.status.exclusionperiods.period48': '48 hrs',
  'punterpage.profile.status.exclusionperiods.period72': '72 hrs',
  'punterpage.profile.status.exclusionperiods.periodweek': '1 week',
  'punterpage.profile.status.exclusionperiods.periodfortnight': '2 weeks',
  'punterpage.profile.status.exclusionperiods.period1month': '1 month',
  'punterpage.profile.status.exclusionperiods.period3months': '3 months',
  'punterpage.profile.status.exclusionperiods.period6months': '6 months',
  'punterpage.profile.status.exclusionperiods.periodformat': 'in X)',
  'punterpage.profile.form.firstname': 'First Name',
  'punterpage.profile.form.middleName': 'Middle Name',
  'punterpage.profile.form.lastname': 'Last Name',
  'punterpage.profile.form.title': 'Title',
  'punterpage.profile.form.email': 'Email',
  'punterpage.profile.form.phonenumber': 'Phone Number',
  'punterpage.profile.form.streetnumber': 'Street No.',
  'punterpage.profile.form.streetname': 'Street Name',
  'punterpage.profile.form.suburb': 'Suburb',
  'punterpage.profile.form.state': 'Select State',
  'punterpage.profile.form.postcode': 'Post Code',
  'punterpage.profile.form.dateofbirth': 'Date of Birth',
  'punterpage.profile.errormessages': 'Sorry! something went wrong.',
  'punterpage.profile.successmessage': 'Saved successfully!',
  'punterpage.profile.flagplaceholder': 'Add a flag...',
  'punterpage.profile.notonboarded': 'Onboarding Incomplete',
  'punterpage.profile.form.statusReason.pending_verification':
    'Pending Verification',
  'punterpage.profile.form.statusReason.customer_requested':
    'Customer Requested',
  'punterpage.profile.form.statusReason.responsible_gambling':
    'Responsible Gambling',
  'punterpage.profile.form.statusReason.fraud_risk': 'Fraud Risk',
  'punterpage.profile.form.statusReason.duplicate_account': 'Duplicate Account',
  'punterpage.profile.form.statusReason.business_decision': 'Business Decision',
  'punterpage.profile.form.statusReason.blacklist': 'Blacklist',
  'punterpage.profile.form.statusReason.docs_requested': 'Docs Requested',
  'punterpage.profile.form.statusReason.locked_temporary': 'Locked Temporary',
  'punterpage.profile.form.statusReason.locked_permanent': 'Locked Permanent',
  'punterpage.profile.form.statusReason.betstop': 'Betstop',
  'punterpage.profile.form.statusReason.cross_brand_exclusion':
    'Cross Brand Exclusion',
  'punterpage.profile.form.statusReason.none': 'None',
  'punterpage.bets.columntitles.event': 'Event',
  'punterpage.bets.columntitles.date': 'Date',
  'punterpage.bets.columntitles.betstatus': 'Bet Status',
  'punterpage.bets.columntitles.market': 'Market',
  'punterpage.bets.columntitles.selection': 'Selection',
  'punterpage.bets.columntitles.odds': 'Odds',
  'punterpage.bets.columntitles.stake': 'Stake',
  'punterpage.bets.columntitles.bonus': 'Bonus',
  'punterpage.bets.columntitles.return': 'Return',
  'punterpage.bets.columntitles.bc': 'BC',
  'punterpage.bets.columncontent.bonus': 'Bonus',
  'punterpage.bets.bb': 'BB',
  'punterpage.bets.filters.labels.race': 'Race',
  'punterpage.bets.filters.labels.match': 'Match',
  'punterpage.bets.filters.placeholders.eventname': 'Search by event name...',
  'punterpage.bets.filters.placeholders.sport': 'Sport',
  'punterpage.bets.filters.placeholders.status': 'Status',
  'punterpage.bets.selection.racing': 'Racing',
  'punterpage.bets.selection.sport': 'Sport',
  'punterpage.bets.selection.mixed': 'Mixed',
  'punterpage.bets.selection.legs': 'Legs',
  'punterpage.bets.modal.headings.confirmpayout': 'Confirm payout amount',
  'punterpage.bets.modal.labels.transactionid': 'Transaction ID',
  'punterpage.bets.modal.labels.betplaced': 'Bet Placed',
  'punterpage.bets.modal.labels.eventtime': 'Event Time',
  'punterpage.bets.modal.labels.bonus': 'Bonus Stake',
  'punterpage.bets.modal.labels.event': 'Event',
  'punterpage.bets.modal.labels.market': 'Market',
  'punterpage.bets.modal.labels.date': 'Date & Time',
  'punterpage.bets.modal.labels.stakepercombo': 'Stake per combo',
  'punterpage.bets.modal.labels.flexi': 'Flexi',
  'punterpage.bets.modal.labels.numberofcombo': 'Number of combos',
  'punterpage.bets.modal.labels.odds': 'Odds',
  'punterpage.bets.modal.labels.result': 'Result',
  'punterpage.bets.modal.labels.selection': 'Selection',
  'punterpage.bets.modal.labels.bb': 'BB',
  'punterpage.bets.modal.labels.stake': 'Stake',
  'punterpage.bets.modal.labels.return': 'Return',
  'punterpage.bets.modal.labels.puntersaccount': 'Refund into punters account',
  'punterpage.bets.modal.labels.combos': 'Combos',
  'punterpage.bets.modal.labels.legs': 'Legs',
  'punterpage.bets.modal.labels.race': 'Race',
  'punterpage.bets.modal.labels.won': 'Won',
  'punterpage.bets.modal.labels.lost': 'Lost',
  'punterpage.bets.modal.buttons.export': 'Export',
  'punterpage.bets.modal.buttons.confirm': 'Confirm and settle bet',
  'punterpage.bets.modal.misc.on': 'on',
  'punterpage.bets.modal.misc.loss': 'Loss',
  'punterpage.bets.modal.misc.lose': 'Lose',
  'punterpage.bets.modal.misc.win': 'Win',
  'punterpage.bets.modal.misc.stake': 'stake',
  'punterpage.bets.modal.misc.pending': 'Pending',
  'punterpage.bets.modal.misc.payout': 'Payout',
  'punterpage.bets.modal.misc.deduction': 'Deduction',
  'punterpage.bets.errors.refund': 'Unable to handle this request at this time',
  'punterpage.deposits.columntitles.type': 'Type',
  'punterpage.deposits.columntitles.datetime': 'Date & Time',
  'punterpage.deposits.columntitles.amount': 'Amount',
  'punterpage.deposits.columntitles.status': 'Status',
  'punterpage.deposits.depositstatus.manuallyapproved': 'ManuallyApproved',
  'punterpage.deposits.depositstatus.pending': 'Pending',
  'punterpage.deposits.depositstatus.approved': 'Approved',
  'punterpage.deposits.depositstatus.declined': 'Declined',
  'punterpage.deposits.depositstatus.abandoned': 'Abandoned',
  'punterpage.payments.accountnumber': 'Account Number',
  'punterpage.payments.bank': 'Bank',
  'punterpage.payments.blockedcarddigits': '•••• •••• •••• ',
  'punterpage.payments.bsb': 'BSB',
  'punterpage.payments.expirydate': 'Expiry Date',
  'punterpage.payments.cancel': 'Cancel',
  'punterpage.payments.remove': 'Remove',
  'punterpage.payments.removepaymentmethod': 'Remove payment method',
  'punterpage.payments.removeareyousure':
    'Are you sure you want to delete this payment method?',
  'punterpage.payments.editdetails': 'Edit Details',
  'punterpage.payments.deletionsuccess': 'Payment method deleted',
  'punterpage.payments.deletionerror': 'Something went wrong, please try again',
  'punterpage.incentives.actions.revoke': 'Revoke',
  'punterpage.incentives.table.placeholder':
    'There are currently no recieved promos',
  'punterpage.incentives.bonusbets.bonuslabel': 'Bonus Bet Balance',
  'punterpage.incentives.bonusbets.addbonus': '+ Add Bonus Bets',
  'punterpage.incentives.filters.placeholders.promoorused':
    'Search by promo or used on name',
  'punterpage.incentives.filters.placeholders.typeofincentive': 'Type: All',
  'punterpage.incentives.filters.placeholders.outcomeofrace': 'Result: All',
  'punterpage.incentives.filters.labels.incentivetype.place(fixed)':
    'Place(Fixed)',
  'punterpage.incentives.filters.labels.incentivetype.win(fixed)': 'Win(Fixed)',
  'punterpage.incentives.filters.labels.result.won': 'Won',
  'punterpage.incentives.filters.labels.result.lost': 'No Return',
  'punterpage.incentives.columntitles.amount': 'Amount',
  'punterpage.incentives.columntitles.usedon': 'Used On',
  'punterpage.incentives.columntitles.dateissues': 'Date issued',
  'punterpage.incentives.columntitles.dateused': 'Date Used',
  'punterpage.incentives.columntitles.bet': 'Bet',
  'punterpage.incentives.columntitles.bonusbet': 'Bonus Bet',
  'punterpage.incentives.columntitles.bonusBalance': 'Bonus Bet',
  'punterpage.incentives.columntitles.potentialpayout': 'Potential Payout',
  'punterpage.incentives.columntitles.reason': 'Reason',
  'punterpage.incentives.columntitles.note': 'Note',
  'punterpage.incentives.columntitles.result': 'Result',
  'punterpage.incentives.columntitles.issuer': 'Issuer',
  'punterpage.incentives.columntitles.revokeBalance': 'Revoke Balance',
  'punterpage.incentives.rowlabels.result.won': 'Won',
  'punterpage.incentives.rowlabels.result.noreturn': 'No Return',
  'punterpage.incentives.modal.header': 'Add Bonus Bets',
  'punterpage.incentives.modal.cancel': 'Cancel',
  'punterpage.incentives.modal.note': 'Note',
  'punterpage.incentives.modal.addbonusbets': '+ Add Bonus Bets',
  'punterpage.incentives.modal.selectlabel': 'Reason',
  'punterpage.incentives.modal.inputlabel': 'Amount',
  'punterpage.incentives.modal.reasons.initialvalue': 'Please select...',
  'punterpage.incentives.modal.bonusbetsschema.min':
    'Min bonus bet amount is $0.50',
  'punterpage.incentives.modal.bonusbetsschema.max':
    'Max bonus bet amount is $10,000',
  'punterpage.incentives.modal.bonusbetsschema.amountrequired':
    'Please enter amount',
  'punterpage.incentives.modal.bonusbetsschema.reasonrequired':
    'Please select a reason',
  'punterpage.incentives.successrevoke': 'Successfully revoked',
  'punterPage.incentives.addBetBoostToken': 'Add Bet Boost Token',
  'punterPage.incentives.addOddsBoostToken': 'Add Odds Boost Token',
  'punterPage.incentives.addBetBoostTokenSuccessTitle':
    'Bet Boost token added.',
  'punterPage.incentives.addOddsBoostTokenSuccessTitle':
    'Odds Boost token added.',
  'punterPage.incentives.addBonusBets': 'Add Bonus Bets',
  'punterPage.incentives.addDepositMatch': 'Add Deposit Match',
  'punterPage.incentives.addDepositMatchSuccessTitle': 'Deposit Match added.',
  'punterPage.incentives.addPromo': 'Add Promo',
  'punterPage.incentives.amountHelperText': 'Up to {maxAmount}.',
  'punterPage.incentives.amountLabel': 'Amount ({currencySymbol})',
  'punterPage.incentives.availableCount': '{count} available',
  'punterPage.incentives.baseValuesTerm': 'Base Values:',
  'punterPage.incentives.betBoostOption': 'Bet Boost',
  'punterPage.incentives.oddsBoostOption': 'Odds Boost',
  'punterPage.incentives.betBoostTableCaption': 'Bet Boost token history',
  'punterPage.incentives.betBoostTokens': 'Bet Boost Tokens',
  'punterPage.incentives.betRecoveryTokens': 'Bet Recovery Tokens',
  'punterPage.incentives.oddsBoostTokens': 'Odds Boost Tokens',
  'punterPage.incentives.depositMatchTokens': 'Auto Deposit Match Tokens',
  'punterPage.incentives.betslipBonusTokens': 'Bet Slip Bonus Tokens',
  'punterPage.incentives.betHistoryTab': 'Bet History',
  'punterPage.incentives.bonusAmountTableHeader': 'Bonus Amount',
  'punterPage.incentives.cancelButton': 'Cancel',
  'punterPage.incentives.confirmButton': 'Confirm',
  'punterPage.incentives.depositMatchOption': 'Deposit Match',
  'punterPage.incentives.depositMatchTableCaption':
    'Deposit Match token history',
  'punterPage.incentives.detailsTableHeader': 'Details',
  'punterPage.incentives.endDateTableHeader': 'End Date',
  'punterPage.incentives.expiryLabel': 'Expiry (optional)',
  'punterPage.incentives.givenCount': '{count} given',
  'punterPage.incentives.inputAmountTerm': 'Input Amount:',
  'punterPage.incentives.issuerTableHeader': 'Issuer',
  'punterPage.incentives.lastModifiedTableHeader': 'Last Modified',
  'punterPage.incentives.maxAmountErrorLabel': 'Max amount',
  'punterPage.incentives.maxAmountLabel': 'Max Amount ({currencySymbol})',
  'punterPage.incentives.maxBonusAmountLabel':
    'Max Bonus Amount ({currencySymbol})',
  'punterPage.incentives.maxAmountTerm': 'Max Amount:',
  'punterPage.incentives.multiplierProbabilitiesTerm':
    'Multiplier Probabilities:',
  'punterPage.incentives.nameTableHeader': 'Name',
  'punterPage.incentives.notesErrorLabel': 'Notes',
  'punterPage.incentives.notesLabel': 'Notes',
  'punterPage.incentives.percentageErrorLabel': 'Percentage',
  'punterPage.incentives.percentageLabel': 'Percentage ({percentSign})',
  'punterPage.incentives.percentageTerm': 'Percentage:',
  'punterPage.incentives.probabilityTerm': 'Probability:',
  'punterPage.incentives.promoHistoryTab': 'Promo History',
  'punterPage.incentives.promoTypeLabel': 'Promo Type',
  'punterPage.incentives.reasonTableHeader': 'Reason',
  'punterPage.incentives.receivedTab': 'Received',
  'punterPage.incentives.sourceTableHeader': 'Source',
  'punterPage.incentives.startDateTableHeader': 'Start Date',
  'punterPage.incentives.statusTableHeader': 'Status',
  'punterPage.incentives.tokenCountTableHeader': 'Token Count',
  'punterPage.incentives.usedCount': '{count} used',
  'punterpage.reconcile.modal.header': 'Approve Payment',
  'punterpage.reconcile.modal.button': 'Approve',
  'punterpage.reconcile.modal.cancel': 'Cancel',
  'punterpage.reconcile.modal.inputlabel': 'Amount',
  'punterpage.reconcile.modal.bonusbetsschema.min':
    'Min bonus bet amount is $0.50',
  'punterpage.reconcile.modal.bonusbetsschema.max':
    'Max bonus bet amount is $10,000',
  'punterpage.reconcile.modal.bonusbetsschema.amountrequired':
    'Please enter amount',
  'punterpage.reconcile.modal.bonusbetsschema.reasonrequired':
    'Please select a reason',
  'punterpage.reconcile.button': 'Reconcile',
  'punterpage.notes.timestamp.at': ' at ',
  'punterpage.notes.timestamp.addedby': ' added by ',
  'punterpage.notes.type': 'Type: ',
  'punterpage.notes.alltypesfilter': 'All Types',
  'punterpage.notes.notetypes.selecttypeplaceholder': 'Select Note Type',
  'punterpage.notes.notetypes.accountbalance': 'Account Balance',
  'punterpage.notes.notetypes.dispute': 'Dispute',
  'punterpage.notes.notetypes.verification': 'Verification',
  'punterpage.notes.notetypes.promo': 'Promo',
  'punterpage.notes.notetypes.general': 'General',
  'punterpage.notes.createnew': 'Create New',
  'punterpage.notes.delete': 'Delete',
  'punterpage.notes.cancel': 'Cancel',
  'punterpage.notes.savenote': 'Save Note',
  'punterpage.notes.newnoteheading': 'Create New Note',
  'punterpage.notes.newnoteplaceholder': 'New Note',
  'punterpage.notes.typenoteplaceholder': 'Please type your note...',
  'punterpage.notes.you': 'You',
  'punterpage.limits.depositlimit.heading': 'Deposit Limit',
  'punterpage.limits.depositlimit.subheading':
    'This allows you to limit the maximum amount a punter can deposit into their betting account.',
  'punterpage.limits.depositlimit.limitamountlabel': 'Limit Amount',
  'punterpage.limits.depositlimit.limitperiodlabel': 'Limit Period',
  'punterpage.limits.depositlimit.setbuttontext': 'Set Limit',
  'punterpage.limits.depositlimit.currentsubheading': 'Current',
  'punterpage.limits.depositlimit.historysubheading': 'History',
  'punterpage.limits.depositlimit.setbypunter': '% limit set by the punter',
  'punterpage.limits.depositlimit.setbyadmin': '% limit set by the X',
  'punterpage.limits.depositlimit.limitRemovedBy': 'Limit removed by {who}',
  'punterpage.limits.depositlimit.coolingoffstatement':
    'Limit will increase to % on the &',
  'punterpage.limits.depositlimit.increaselimitafter': 'Increase limit after',
  'punterpage.limits.depositlimit.approvedlimit': 'Deposit limit approved',
  'punterpage.limits.depositlimit.errorstrings.requiredamount':
    'Deposit limit amount is required',
  'punterpage.limits.depositlimit.errorstrings.cannotincrease':
    'Deposit limit cannot be increased until %',
  'punterpage.limits.depositlimit.errorstrings.requiredperiod':
    'Deposit limit period is required',
  'punterpage.limits.depositlimit.errorstrings.numbersonly':
    'Deposit limit can only contain numbers',
  'punterpage.limits.depositlimit.errorstrings.samevalue':
    'Amount entered is the same as your current deposit limit',
  'punterpage.limits.depositlimit.errorstrings.choosealimit':
    'Please choose a limit',
  'punterpage.transactions.tab.standard': 'Standard',
  'punterpage.transactions.tab.bonus': 'Bonus',
  'punterpage.transactions.modal.header': 'Manual adjustment',
  'punterpage.transactions.modal.addamount': 'Add Amount',
  'punterpage.transactions.modal.subtractamount': 'Subtract Amount',
  'punterpage.transactions.modal.amountinput': 'Amount',
  'punterpage.transactions.modal.reasoninput': 'Reason',
  'punterpage.transactions.modal.noteinput': 'Note',
  'punterpage.transactions.modal.pleaseselect': 'Please select...',
  'punterpage.transactions.modal.adjustbalance': 'Adjust Balance',
  'punterpage.transactions.modal.amountrequired': 'Please enter amount',
  'punterpage.transactions.modal.reasonrequired': 'Please select a reason',
  'punterpage.transactions.modal.noterequired': 'Please enter a note',
  'punterpage.transactions.manualadjustment': '+ Manual Adjustment',
  'punterpage.transactions.currentbalance': 'Current Balance:',
  'punterpage.transactions.downloadhistory': 'Download Transaction History',
  'punterpage.transactions.downloaderrormessage':
    "Something went wrong, couldn't download transactions...",
  'punterpage.transactions.columns.date': 'Date',
  'punterpage.transactions.columns.type': 'Type',
  'punterpage.transactions.columns.details': 'Details',
  'punterpage.transactions.columns.amount': 'Amount',
  'punterpage.transactions.columns.balance': 'Balance',
  'punterpage.transactions.type.deposit': 'Deposit',
  'punterpage.transactions.type.bet': 'Bet Stake',
  'punterpage.transactions.type.winnings': 'Winnings',
  'punterpage.transactions.type.bonus': 'Bonus',
  'punterpage.transactions.type.withdrawal': 'Withdrawal',
  'punterpage.transactions.type.void': 'Void',
  'punterpage.transactions.type.cashout': 'Cash Out',
  'punterpage.transactions.type.adjustment': 'Manual Adjustment',
  'punterpage.transactions.type.credit': 'Credit',
  'punterpage.transactions.type.refund': 'Refund',
  'punterpage.transactions.exotics.boxed': 'Boxed',
  'punterpage.transactions.betId': 'Bet ID',
  'punterpage.transactions.id': 'ID',
  'punterpage.transactions.legs': 'legs',
  'punterPage.transactions.propDetailsForSP': '{proposition} @ SP',
  'offeringspage.header': 'Offerings',
  'offeringspage.offeringstabtext.header': 'Offerings',
  'offeringspage.offeringstabtext.title':
    'Which sports would you like to offer to your punters',
  'offeringspage.offeringstabtext.subtitle':
    'This hides any sports or codes or tracks from your punters so you can get them to focus on the events you want to cover.',
  'offeringspage.offeringstabtext.offeredcount': '($/% offered)',
  'offeringspage.featuredeventstabtext.header': 'Featured Events',
  'trademanagerpage.common.norunners': 'No runners found',
  'trademanagerpage.common.na': 'N/A',
  'trademanagerpage.common.manualoverride': 'Manual Override',
  'trademanagerpage.common.settlemarket': 'Settle market',
  'trademanagerpage.common.voidmarket': 'Void market',
  'trademanagerpage.common.suspendmarket': 'Suspend market',
  'trademanagerpage.common.enablemarket': 'Enable market',
  'trademanagerpage.common.suspendproposition': 'Suspend proposition',
  'trademanagerpage.common.enableproposition': 'Enable proposition',
  'trademanagerpage.common.voidproposition': 'Void proposition',
  'trademanagerpage.common.current': 'Current',
  'trademanagerpage.common.p': 'P',
  'trademanagerpage.common.w': 'W',
  'trademanagerpage.common.date': 'Date',
  'trademanagerpage.common.status': 'Status',
  'trademanagerpage.common.followFeed': 'Follow Feed',
  'trademanagerpage.common.followFeedStatus': 'Follow Feed Status',
  'trademanagerpage.common.settleByFeed': 'Settle by feed',

  'trademanagerpage.common.dividend': 'Dividend',
  'trademanagerpage.common.race': 'Race',
  'trademanagerpage.common.bets': 'Bets',
  'trademanagerpage.common.winbets': 'Win Bets',
  'trademanagerpage.common.winhold': 'Win Hold',
  'trademanagerpage.common.placehold': 'Place Hold',
  'trademanagerpage.common.avgwinprice': 'Avg. Win Price',
  'trademanagerpage.common.placebets': 'Place Bets',
  'trademanagerpage.common.placeliability': 'Place Liability',
  'trademanagerpage.common.value': 'Value',
  'trademanagerpage.common.bonusbet': 'Bonus Bet',
  'trademanagerpage.common.bestresult': 'Best Result',
  'trademanagerpage.common.result': 'Result',
  'trademanagerpage.common.betsplaced': 'Bets Placed',
  'trademanagerpage.common.turnover': 'Turnover',
  'trademanagerpage.common.exposure': 'Exposure',
  'trademanagerpage.common.winresult': 'Win Result',
  'trademanagerpage.common.biggestwinbet': 'Biggest Win Bet',
  'trademanagerpage.common.biggestbet': 'Biggest Bet',
  'trademanagerpage.common.event': 'Event',
  'trademanagerpage.common.noracesfound': 'No races found...',
  'trademanagerpage.common.managesports': 'Manage Sports',
  'trademanagerpage.common.manageraces': 'Manage Races',
  'trademanagerpage.common.resetall': 'Reset All',
  'trademanagerpage.common.place': 'Place',
  'trademanagerpage.common.win': 'Win',
  'trademanagerpage.common.selectstatus': 'Select Status',
  'trademanagerpage.common.statuscontrol': 'Status Control',
  'trademanagerpage.common.selectevent': 'Event Type',
  'trademanagerpage.common.raceform': 'Race Form',
  'trademanagerpage.common.viewindividualbets': 'View Individual Bets',
  'trademanagerpage.common.raceresults': 'Final Race Results',
  'trademanagerpage.common.nodata': 'N/A',
  'trademanagerpage.common.viewallvenues': 'View All Venues',
  'trademanagerpage.common.hidevenues': 'Hide Venues',
  'trademanagerpage.common.noraces': 'No races currently available',
  'trademanagerpage.common.nobets': 'No bets placed on this runner',
  'trademanagerpage.common.all': 'All',
  'trademanagerpage.config.race': 'RACE',
  'trademanagerpage.config.settlementtype': 'Settlement type',
  'trademanagerpage.config.deadheat':
    'If you choose more selections as winners than the expected winners will result in a dead heat',
  'trademanagerpage.config.settle.expectedwinners': 'Expected Winners',
  'trademanagerpage.config.settle.nowinner': 'No Winner',
  'trademanagerpage.config.settle.settletype': 'Settlement Type',
  'trademanagerpage.config.settle.settletype.tooltip.winter':
    'Settle by winter',
  'trademanagerpage.config.settle.settletype.tooltip.manual': 'Manually settle',
  'trademanagerpage.config.disableProtest': 'Disable protest payout',
  'trademanagerpage.config.protest': 'Protest',
  'trademanagerpage.config.settle.tooltip.winter':
    'Use the status provided by Winter',
  'trademanagerpage.config.settle.tooltip.manual': 'Manually settle race',
  'trademanagerpage.config.tooltip.winter': 'Use the status provided by Winter',
  'trademanagerpage.config.tooltip.ignorewinter':
    'Open market. To use the Winter status please switch off Ignore Winter',
  'trademanagerpage.config.tooltip.suspend': 'Suspend all propositions',
  'trademanagerpage.config.tooltip.hardclose':
    'Close and remove market from Punter',
  'trademanagerpage.config.tooltip.softclose':
    'Close but show market on Punter',
  'tradeManagerPage.config.tooltip.softCloseProp':
    'Close but show proposition on Punter',
  'trademanagerpage.matchDetails.configure.header.status': 'Status',
  'trademanagerpage.matchDetails.configure.header.void': 'Void',
  'trademanagerpage.matchDetails.configure.header.settle': 'Settlement',
  'tradeManagerPage.matchDetails.configure.header.offerings': 'Offerings',
  'trademanagerpage.matchDetails.configure.header.match': 'Configure event',
  'trademanagerpage.matchDetails.configure.header.market':
    'Configure {market_name} market',
  'trademanagerpage.matchDetails.configure.header.proposition':
    'Configure {proposition_name}',
  'trademanagerpage.matchDetails.configure.match': 'All Markets',
  'trademanagerpage.matchDetails.configure.market': 'All Propositions',
  'trademanagerpage.matchDetails.settlement.error.onewinner':
    'At least one proposition must have a result of "WINNER"',
  'trademanagerpage.betsforrunner.punter': 'Punter',
  'trademanagerpage.betsforrunner.bet': 'Bet type',
  'trademanagerpage.betsforrunner.betstatus': 'Bet Status',
  'trademanagerpage.betsforrunner.odds': 'Odds',
  'trademanagerpage.betsforrunner.stake': 'Stake',
  'trademanagerpage.betsforrunner.potentialreturn': 'Potential Return',
  'trademanagerpage.betsforrunner.actualreturn': 'Actual Return',
  'trademanagerpage.betsforrunner.noBets': 'No bets for this runner',
  'trademanagerpage.betsforrunner.bb': 'BB',
  'trademanagerpage.header': 'Risk Manager',
  'trademanagerpage.routename.nexttojump': 'Next to Jump',
  'trademanagerpage.routename.byracing': 'Racing',
  'trademanagerpage.routename.bysport': 'Sport',
  'trademanagerpage.routename.highestexposure': 'Highest Exposure',
  'trademanagerpage.routename.findanevent': 'Find an Event',
  'trademanagerpage.routename.managecustommarkets': 'Custom Markets',
  'trademanagerpage.routename.globalmargins': 'Manage Global Margins',
  'trademanagerpage.routename.multimonitor': 'Multi Monitor',
  'trademanagerpage.custommarkets.error.namerequired': 'Name is required',
  'trademanagerpage.custommarkets.error.sportrequired': 'Sport is required',
  'trademanagerpage.custommarkets.error.competitionrequired':
    'Competition is required',
  'trademanagerpage.custommarkets.error.matchrequired': 'Match is required',
  'trademanagerpage.custommarkets.error.daterequired': 'Date is required',
  'trademanagerpage.custommarkets.error.timerequired': 'Time is required',
  'trademanagerpage.custommarkets.error.futuretimeisrequired':
    'Future time is required',
  'trademanagerpage.custommarkets.error.markettyperequired':
    'Market type is required',
  'trademanagerpage.custommarkets.error.marketgroupnamerequired':
    'Market group name is required',
  'trademanagerpage.custommarkets.error.marketnamerequired':
    'Market name is required',
  'trademanagerpage.custommarkets.error.maxstakenumber':
    'Max stake has to be a number',
  'trademanagerpage.custommarkets.error.maxstakenotnegative':
    'Max stake has to be more than 0',
  'trademanagerpage.custommarkets.error.minoneprop':
    'At least 1 proposition is required',
  'trademanagerpage.custommarkets.createcustommarket': 'Create Custom Market',
  'trademanagerpage.custommarkets.name': 'Match name',
  'trademanagerpage.custommarkets.marketname': 'Market name',
  'trademanagerpage.custommarkets.markettype': 'Market Type',
  'trademanagerpage.custommarkets.groupname': 'Group Name',
  'trademanagerpage.custommarkets.multiallowed': 'Multi Allowed',
  'trademanagerpage.custommarkets.maxstake': 'Max Stake',
  'trademanagerpage.custommarkets.modal.createnewmarket': 'Create New Market',
  'trademanagerpage.custommarkets.modal.addmore': 'Add More',
  'trademanagerpage.custommarkets.modal.deletecustommarket':
    'Delete Custom Market',
  'trademanagerpage.custommarkets.modal.removeprop': 'Remove Proposition',
  'trademanagerpage.custommarkets.modal.update': 'Update',
  'trademanagerpage.custommarkets.modal.createmarket': 'Create Market',
  'trademanagerpage.custommarkets.modal.editmarket': 'Edit',
  'trademanagerpage.custommarkets.modal.code': 'Sport Name or Code',
  'trademanagerpage.custommarkets.modal.competition': 'Competition Name',
  'trademanagerpage.custommarkets.modal.match': 'Match Name',
  'trademanagerpage.custommarkets.modal.market': 'Market Name',
  'trademanagerpage.custommarkets.modal.starttime': 'Start time',
  'trademanagerpage.custommarkets.modal.markettype': 'Market Type',
  'trademanagerpage.custommarkets.modal.marketgroupname': 'Market Group Name',
  'trademanagerpage.custommarkets.modal.maxbet': 'Max Stake',
  'trademanagerpage.custommarkets.modal.status': 'Status',
  'trademanagerpage.custommarkets.modal.odds': 'Odds',
  'trademanagerpage.custommarkets.modal.allowmultis': 'Allow Multis',
  'trademanagerpage.custommarkets.modal.propositions': 'Propositions',
  'trademanagerpage.custommarkets.modal.maxbetplaceholder': '0.00',
  'trademanagerpage.custommarkets.modal.propositionsplaceholder': 'Name',
  'trademanagerpage.custommarkets.modal.propositionsoddsplaceholder': 'Odds',
  'trademanagerpage.custommarkets.modal.marketgroupnameplaceholder':
    'i.e. Special Markets',
  'trademanagerpage.custommarkets.modal.marketnameplaceholder':
    'i.e. Cleveland OR Gold Trip to win | Randwick R7',
  'trademanagerpage.custommarkets.modal.markettypeplaceholder':
    'i.e. Special Promotion',
  'trademanagerpage.custommarkets.modal.codeplaceholder': 'i.e. Specials',
  'trademanagerpage.custommarkets.modal.competitionplaceholder':
    'i.e. Sydney Cup Special',
  'trademanagerpage.custommarkets.modal.matchplaceholder':
    'i.e. Sydney Cup (Randwick Race 7)',
  'trademanagerpage.custommarkets.modal.deleteerror':
    'Error deleting market: Please note that you can only delete a manually created market if there are no bets associated with it',
  'trademanagerpage.bysporttab.sortby.chronological': 'Chronological',
  'trademanagerpage.bysporttab.sortby.hasbets': 'Has Bets',
  'trademanagerpage.bysporttab.selectsport': 'Select Sport',
  'trademanagerpage.bysporttab.selectcompetition': 'Select Competition',
  'trademanagerpage.bysporttab.selecttournament': 'Select Tournament',
  'trademanagerpage.bysporttab.modals.headings.configure': 'Configure match',
  'trademanagerpage.bysporttab.modals.headings.suspendmarket':
    'Suspend % market',
  'trademanagerpage.bysporttab.modals.headings.suspendproposition':
    'Suspend % proposition',
  'trademanagerpage.bysporttab.modals.labels.voidproposition':
    'Void this proposition. Hide from punter website.',
  'trademanagerpage.bysporttab.modals.labels.voidmarket':
    'Void this market and all related propositions. Hide from punter website.',
  'trademanagerpage.bysporttab.modals.labels.voidmatch':
    'Void all markets and hide from punter website (this is a permanent action and cannot be undone).',
  'trademanagerpage.bysporttab.modals.labels.suspendmarkets':
    'Suspend all markets and hide from punter website',
  'trademanagerpage.bysporttab.modals.labels.suspendindividualmarket':
    'Suspend this market and all related propositions. Hide from punter website',
  'trademanagerpage.bysporttab.modals.labels.suspendproposition':
    'Suspend this proposition and hide from punter website',
  'trademanagerpage.bysporttab.othermarkets': 'Other Markets',
  'trademanagerpage.byracingtab.clear': 'Clear',
  'trademanagerpage.byracingtab.selectstatus': 'Statuses (All)',
  'trademanagerpage.byracingtab.selectvenues': 'Venues (All)',
  'trademanagerpage.byracingtab.selectcountry': 'Countries (All)',
  'trademanagerpage.byracingtab.numracesrunning': 'races running',
  'trademanagerpage.byracingtab.modals.headings.configure': 'Configure race',
  'trademanagerpage.byracingtab.modals.headings.settle': 'Settle race',
  'trademanagerpage.byracingtab.modals.labels.scratch':
    'Use "s" in order to scratch a runner',
  'trademanagerpage.byracingtab.modals.labels.void':
    'Void all markets and hide from punter website.',
  'trademanagerpage.byracingtab.modals.labels.suspendall':
    'Suspend all markets and hide from punter website',
  'trademanagerpage.findaneventtab.selectsport': 'Select Sport',
  'trademanagerpage.findaneventtab.eventname': 'Event Name',
  'trademanagerpage.managemarginglobal.marginsodds': 'Odds Margin %',
  'trademanagerpage.managemarginglobal.totesmargins': 'Totes Margins',
  'trademanagerpage.managemarginglobal.racingmargins': 'Racing Margins',
  'trademanagerpage.managemarginglobal.sportsmargins': 'Sports Margins',
  'trademanagerpage.managemarginglobal.totesodds': 'Totes Odds',
  'trademanagerpage.managemarginglobal.manuallymanage': 'Manually Manage',
  'trademanagerpage.managemarginglobal.common.code': 'Code',
  'trademanagerpage.managemarginglobal.common.track': 'Track',
  'trademanagerpage.managemarginglobal.common.competition': 'Competition',
  'trademanagerpage.managemarginglobal.racingmarginstab.title':
    'Set a margin for racings odds',
  'trademanagerpage.managemarginglobal.racingmarginstab.subtitle':
    'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
  'trademanagerpage.managemarginglobal.racingmarginstab.globalmargin':
    'Global racing margin',
  'trademanagerpage.managemarginglobal.racingmarginstab.addexceptioncode':
    '+ Add an exception for a code',
  'trademanagerpage.managemarginglobal.racingmarginstab.addexceptiontrack':
    '+ Add an exception for a track',
  'trademanagerpage.managemarginglobal.racingmarginstab.margin': 'Margin',
  'trademanagerpage.managemarginglobal.racingmarginstab.remove': 'Remove',
  'trademanagerpage.managemarginglobal.sportmarginstab.title':
    'Set a margin for sports odds',
  'trademanagerpage.managemarginglobal.sportmarginstab.subtitle':
    'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
  'trademanagerpage.managemarginglobal.sportmarginstab.globalmargin':
    'Global sports margin',
  'trademanagerpage.managemarginglobal.sportmarginstab.addexceptioncode':
    '+ Add an exception for a code',
  'trademanagerpage.managemarginglobal.sportmarginstab.addexceptioncomp':
    '+ Add an exception for a competition',
  'trademanagerpage.managemarginglobal.sportmarginstab.margin': 'Margin',
  'trademanagerpage.managemarginglobal.sportmarginstab.remove': 'Remove',
  'trademanagerpage.managemarginglobal.sportsmarginstab.title':
    'Set a margin for sports odds',
  'trademanagerpage.managemarginglobal.sportsmarginstab.subtitle':
    'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
  'trademanagerpage.managemarginglobal.sportsmarginstab.globalmargin':
    'Global sports margin',
  'trademanagerpage.managemarginglobal.sportsmarginstab.addexceptioncode':
    '+ Add an exception for a code',
  'trademanagerpage.managemarginglobal.sportsmarginstab.addexceptiontrack':
    '+ Add an exception for a track',
  'trademanagerpage.managemarginglobal.sportsmarginstab.margin': 'Margin',
  'trademanagerpage.managemarginglobal.sportsmarginstab.remove': 'Remove',
  'trademanagerpage.managemarginglobal.totesmarginstab.title':
    'Set a margin for totes odds',
  'trademanagerpage.managemarginglobal.totesmarginstab.subtitle':
    'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
  'trademanagerpage.managemarginglobal.totesmarginstab.globalmargin':
    'Global margin',
  'trademanagerpage.managemarginglobal.totesmarginstab.addexceptioncode':
    '+ Add an exception for a code',
  'trademanagerpage.managemarginglobal.totesmarginstab.addexceptiontrack':
    '+ Add an exception for a track',
  'trademanagerpage.managemarginglobal.totesmarginstab.margin': 'Margin',
  'trademanagerpage.managemarginglobal.totesmarginstab.remove': 'Remove',
  'trademanagerpage.managemarginglobal.totesoddstab.title':
    'Set a margin for totes odds',
  'trademanagerpage.managemarginglobal.totesoddstab.subtitle':
    'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
  'trademanagerpage.managemarginglobal.totesoddstab.globalmargin':
    'Global totes margin',
  'trademanagerpage.managemarginglobal.totesoddstab.addexceptioncode':
    '+ Add an exception for a code',
  'trademanagerpage.managemarginglobal.totesoddstab.addexceptiontrack':
    '+ Add an exception for a track',
  'trademanagerpage.managemarginglobal.totesoddstab.margin': 'Margin',
  'trademanagerpage.managemarginglobal.totesoddstab.remove': 'Remove',
  'trademanagerpage.matchdetailstext.custommarket': 'Custom Market',
  'trademanagerpage.matchdetailstext.fallbacktitle': 'Match Details',
  'trademanagerpage.matchdetailstext.bets': 'BETS',
  'trademanagerpage.matchdetailstext.value': 'VALUE',
  'trademanagerpage.matchdetailstext.totalwinbets': 'TOTAL WIN BETS',
  'trademanagerpage.matchdetailstext.biggestbet': 'BIGGEST BET',
  'trademanagerpage.matchdetailstext.bonusbets': 'BONUS BETS',
  'trademanagerpage.matchdetailstext.averageprice': 'AVG. PRICE',
  'trademanagerpage.matchdetailstext.exposure': 'EXPOSURE',
  'trademanagerpage.matchdetailstext.result': 'RESULT',
  'trademanagerpage.matchdetailstext.stake': 'STAKE',
  'trademanagerpage.matchdetailstext.odds': 'ODDS',
  'trademanagerpage.matchdetailstext.potentialreturn': 'POTENTIAL RETURN',
  'trademanagerpage.matchdetailstext.bonus': 'BONUS',
  'trademanagerpage.matchdetailstext.punter': 'PUNTER',
  'trademanagerpage.matchdetailstext.status': 'STATUS',
  'trademanagerpage.matchdetailstext.viewindividualbets':
    'View Individual Bets',
  'trademanagerpage.matchdetailstext.current': 'Current',
  'trademanagerpage.matchdetailstext.view': 'VIEW',
  'trademanagerpage.matchdetailstext.open': 'Open',
  'trademanagerpage.matchdetailstext.update': 'Update',
  'trademanagerpage.matchdetailstext.selectsport': 'Select Sport',
  'trademanagerpage.matchdetailstext.modals.allmarkets': 'All Markets',
  'trademanagerpage.matchdetailstext.modals.buttons.confirm':
    'Confirm and update',
  'trademanagerpage.multimonitor.columntitles.punter': 'Punter',
  'trademanagerpage.multimonitor.columntitles.betdetails': 'Bet details',
  'trademanagerpage.multimonitor.columntitles.startingstake': 'Starting Stake',
  'trademanagerpage.multimonitor.columntitles.racer': 'R',
  'trademanagerpage.multimonitor.columntitles.startingodds': 'Starting Odds',
  'trademanagerpage.multimonitor.columntitles.legs': 'Legs',
  'trademanagerpage.multimonitor.columntitles.legswon': 'Legs Won',
  'trademanagerpage.multimonitor.columntitles.currentstakevalue':
    'Current Stake Value',
  'trademanagerpage.multimonitor.columntitles.potentialpayout':
    'Potential Payout',
  'trademanagerpage.multimonitor.columntitles.nextleg': 'Next leg',
  'trademanagerpage.multimonitor.subcolumntitles.prop': 'Checkbox',
  'trademanagerpage.multimonitor.subcolumntitles.market': 'Market',
  'trademanagerpage.multimonitor.subcolumntitles.markettype': 'Market Type',
  'trademanagerpage.multimonitor.subcolumntitles.racer': 'R',
  'trademanagerpage.multimonitor.subcolumntitles.proposition': 'Proposition',
  'trademanagerpage.multimonitor.subcolumntitles.odds': 'Odds',
  'trademanagerpage.multimonitor.subcolumntitles.betdetails': 'Bet details',
  'trademanagerpage.multimonitor.subcolumntitles.legtime': 'Leg Time',
  'trademanagerpage.multimonitor.subcolumntitles.datetime': 'Date & Time',
  'trademanagerpage.multimonitor.multibets.punter': 'Punter',
  'trademanagerpage.multimonitor.multibets.bet': 'Bet',
  'trademanagerpage.multimonitor.multibets.betstatus': 'Bet Status',
  'trademanagerpage.multimonitor.multibets.odds': 'Odds',
  'trademanagerpage.multimonitor.multibets.stake': 'Stake',
  'trademanagerpage.multimonitor.multibets.bonus': 'Bonus',
  'trademanagerpage.multimonitor.multibets.potentialpayout': 'Potential Return',
  'trademanagerpage.multimonitor.multibets.legmulti': 'Leg Multi',
  'trademanagerPage.raceDetails.updatedBy': 'Updated by {updatedBy}',
  'trademanagerpage.racedetails.scratch': 'Scratch',
  'trademanagerpage.racedetails.open': 'Open',
  'trademanagerpage.racedetails.suspend': 'Suspend',
  'trademanagerpage.racedetails.modal.runnerconfig.header':
    'How would you like to update these runners?',
  'trademanagerpage.racedetails.modal.runnerconfig.resettle':
    'Save deductions and resettle impacted bets.',
  'trademanagerpage.racedetails.modal.runnerconfig.displayonly':
    'Save deductions for display only.',
  'trademanagerpage.racedetails.modal.settlement.runner': 'Runner',
  'trademanagerpage.racedetails.modal.settlement.orderbyrunner':
    'Ordered by runner number',
  'trademanagerpage.racedetails.modal.settlement.scratched': 'Scratched',
  'trademanagerpage.racedetails.modal.settlement.removed': 'Removed',
  'trademanagerpage.racedetails.modal.settlement.closed': 'Closed',
  'trademanagerpage.racedetails.modal.settlement.suspended': 'Suspended',
  'trademanagerpage.racedetails.modal.settlement.settled': 'Settled',
  'trademanagerpage.racedetails.winplace.followprices': 'Follow Prices',
  'trademanagerpage.racedetails.winplace.followprices.tooltip':
    'Feed price & suspension updates will apply to market when switched on',
  'trademanagerpage.racedetails.winplace.suspended': 'Suspended',
  'trademanagerpage.racedetails.winplace.suspended.tooltip':
    'Market prices show SUS & no bets taken when switched on',
  'trademanagerpage.racedetails.manuallymanage': 'Manually Manage',
  'trademanagerpage.racedetails.configure': 'Configure',
  'trademanagerpage.racedetails.configRunner.followFeed': 'Follow Feed',
  'trademanagerpage.racedetails.configRunner.suspended': 'Suspended',
  'tradeManagerPage.raceDetails.saveOfferingsSuccessTitle':
    'Race offerings updated.',
  'tradeManagerPage.raceDetails.startingPriceOddsMarkets': 'Starting Price',
  'bookiepage.header': 'Bookie Profile',
  'settingspage.header': 'Settings',
  'settingspage.routename.limits': 'Limits',
  'settingspage.routename.betfairintegration': 'Betfair Integration',
  'settingspage.routename.systemsetup': 'System Setup',
  'settingspage.routename.adminaccounts': 'Admin Accounts',
  'settingspage.routename.activitylog': 'Activity Log',
  'settingspage.routename.yourprofile': 'Your Profile',
  'settingspage.routename.contactus': 'Contact Us',
  'settingspage.routename.offerings': 'Offerings',
  'settingspage.activitylogpage.noresults': 'No logs found...',
  'settingspage.activitylogpage.clearfilters': 'Clear Filters',
  'settingspage.activitylogpage.type': 'Type',
  'settingspage.activitylogpage.trader': 'Trader',
  'settingspage.activitylogpage.details': 'Details',
  'settingspage.activitylogpage.actionedAt': 'Actioned At',
  'settingspage.activitylogpage.actionedBy': 'Actioned By',
  'settingspage.activitylogpage.reason': 'Reason',
  'settingspage.contactdetails.title': 'Bet Contact Details',
  'settingspage.contactdetails.description':
    'These details will be publicly accessible to all punters. These settings can be managed by all Admins',
  'settingspage.contactdetails.phone': 'Phone',
  'settingspage.contactdetails.internationalphone': 'International',
  'settingspage.contactdetails.email': 'Email',
  'settingspage.contactdetails.address': 'Address',
  'settingspage.contactdetails.streetnumber': 'Street Number',
  'settingspage.contactdetails.streetname': 'Street Name',
  'settingspage.contactdetails.suburb': 'Suburb',
  'settingspage.contactdetails.state': 'State',
  'settingspage.contactdetails.postcode': 'Postcode',
  'settingspage.contactdetails.save': 'Save Changes',
  'settingspage.contactdetails.toasts.updated': 'Contact details updated',
  'settingspage.contactdetails.toasts.error':
    'An unspecified error occurred. Please try again.',
  'settingspage.yourprofile.yourprofile.title': 'Your Profile',
  'settingspage.yourprofile.yourprofile.description':
    'You can manage the admin accounts below.',
  'settingspage.yourprofile.yourprofile.firstname': 'First Name',
  'settingspage.yourprofile.yourprofile.lastname': 'Last Name',
  'settingspage.yourprofile.yourprofile.email': 'Email',
  'settingspage.yourprofile.yourprofile.phone': 'Phone',
  'settingspage.yourprofile.yourprofile.licensed_state':
    'Licensed State/Territory',
  'settingspage.yourprofile.yourprofile.license_id': 'Bookmaker License ID',
  'settingspage.yourprofile.yourprofile.savebutton': 'Save changes',
  'settingspage.yourprofile.yourprofile.toasts.updated': 'Profile updated',
  'settingspage.yourprofile.yourprofile.toasts.error':
    'An unspecified error occurred. Please try again.',
  'settingspage.yourprofile.changepassword.title': 'Change Password',
  'settingspage.yourprofile.changepassword.current': 'Current Password',
  'settingspage.yourprofile.changepassword.new': 'Create new password',
  'settingspage.yourprofile.changepassword.button': 'Save changes',
  'settingspage.yourprofile.changepassword.errors.unspecified':
    'An unspecified error occurred. Please try again',
  'settingspage.yourprofile.changepassword.errors.incorrect':
    'Current password incorrect. Try again',
  'settingspage.yourprofile.changepassword.errors.changed': 'Password changed',
  'settingspage.yourprofile.changepassword.validation.match':
    'Passwords must match',
  'settingspage.yourprofile.changepassword.validation.rules':
    'Password must meet password rules',
  'settingspage.yourprofile.notifications.title': 'Notifications',
  'settingspage.yourprofile.notifications.description':
    'You can manage which alerts you receive through to your email.',
  'settingspage.systemsetuppage.subroutes.policies': 'Policies',
  'settingspage.systemsetuppage.subroutes.bank': 'Bank',
  'settingspage.systemsetuppage.subroutes.promos': 'Promos',
  'settingspage.systemsetuppage.subroutes.consumer': 'Consumer',
  'settingspage.systemsetuppage.security.promotionsheading': 'Promotions',
  'settingspage.systemsetuppage.security.promotionssubheading':
    'You can edit your Promotions here and they will show on the punter app for your users.',
  'settingspage.systemsetuppage.security.termsheading':
    'Your Terms & Conditions',
  'settingspage.systemsetuppage.security.termssubheading':
    'You can edit your terms & conditions here and they will show on the punter app for your users.',
  'settingspage.systemsetuppage.security.termsplaceholder':
    'Paste your terms here...',
  'settingspage.systemsetuppage.security.privacyheading': 'Your Privacy Policy',
  'settingspage.systemsetuppage.security.privacysubheading':
    'You can edit your privacy policy here and they will show on the punter app for your users.',
  'settingspage.systemsetuppage.security.privacyplaceholder':
    'Paste your privacy policy here...',
  'settingspage.systemsetuppage.security.savebutton': 'Save',
  'settingspage.systemsetuppage.security.postpromosuccessmessage':
    'Promotions successfuly updated',
  'settingspage.systemsetuppage.security.postprivacysuccessmessage':
    'Privacy Policy successfuly updated',
  'settingspage.systemsetuppage.bank.heading': 'Bank Details',
  'settingspage.systemsetuppage.bank.subheading':
    'This is the account that your earnings will be paid into.',
  'settingspage.systemsetuppage.bank.accountnamelabel': 'Account Name',
  'settingspage.systemsetuppage.bank.bsblabel': 'BSB',
  'settingspage.systemsetuppage.bank.accountnumberlabel': 'Account Number',
  'settingspage.systemsetuppage.bank.apcalabel': 'APCA Number',
  'settingspage.systemsetuppage.bank.bankcodelabel': 'Bank Code (3 Characters)',
  'settingspage.systemsetuppage.bank.savebutton': 'Save',
  'settingspage.systemsetuppage.bank.errors.accountnamerequired':
    'Account Name is required',
  'settingspage.systemsetuppage.bank.errors.accountnamelength':
    'Account Name must be less than 60 characters',
  'settingspage.systemsetuppage.bank.errors.bsbrequired': 'BSB is required',
  'settingspage.systemsetuppage.bank.errors.bsbformat':
    'BSB format must be XXX-XXX',
  'settingspage.systemsetuppage.bank.errors.accountnumberrequired':
    'Account Number is required',
  'settingspage.systemsetuppage.bank.errors.accountnumberformat':
    'Account Number must be more than 8 and less than 10 digits long',
  'settingspage.systemsetuppage.bank.errors.apcarequired':
    'APCA Number is required',
  'settingspage.systemsetuppage.bank.errors.bankcoderequired':
    'Bank Code is required',
  'settingspage.systemsetuppage.bank.errors.bankcodeformat':
    'Bank code must be 3 characters long',
  'settingspage.systemsetuppage.bank.postsuccessmessage':
    'Bank details successfuly updated',
  'settingspage.systemsetuppage.policies.upload': 'Upload',
  'settingspage.systemsetuppage.policies.policytype': 'Policy type',
  'settingspage.systemsetuppage.policies.filename': 'File name',
  'settingspage.systemsetuppage.policies.lastupload': 'Last upload',
  'settingspage.systemsetuppage.policies.terms': 'Terms & Conditions',
  'settingspage.systemsetuppage.policies.privacy': 'Privacy Policy',
  'settingspage.systemsetuppage.policies.responsiblegambling':
    'Responsible Gambling',
  'settingspage.systemsetuppage.policies.modal.uploadpdf': 'Upload PDF',
  'settingspage.systemsetuppage.policies.modal.droppdf':
    'Drop your PDF file here',
  'settingspage.systemsetuppage.policies.modal.choosefile': 'Choose file',
  'settingspage.systemsetuppage.policies.modal.cancel': 'Cancel',
  'settingspage.systemsetuppage.policies.modal.or': 'Or',
  'settingspage.limits.rejectlimits.autorejectinput': ' Reject Limit',
  'settingspage.limits.rejectlimits.manualapprovalinput':
    'Manual Approval Limit',
  'settingspage.limits.rejectlimits.heading': 'Reject Limits',
  'settingspage.limits.rejectlimits.body':
    'The bookie can set an auto reject or manual approval limit and have bets automatically rejected or require approval if they hit the limit. The bookie can set an auto-reject limit for each user category.',
  'settingspage.limits.rejectlimits.autorejectradio': 'Auto Reject',
  'settingspage.limits.rejectlimits.manualapprovalradio': 'Manual Approval',
  'settingspage.limits.rejectlimits.updated': 'Reject Limits updated',
  'settingspage.limits.betapprovallimit.heading':
    'Automatic Bet Approval Limit',
  'settingspage.limits.betapprovallimit.body':
    'Any bet where the return is less than a set amount will be auto-approved and any bet where the return exceeds that set amount will requre manual approval.',
  'settingspage.limits.betapprovallimit.globalbetlimitinputlabel':
    'Bet Approval Limit',
  'settingspage.limits.betapprovallimit.exposurelimitinputlabel':
    'Exposure Limit',
  'settingspage.limits.betapprovallimit.exoticlimitinputlabel': 'Exotic Limit',
  'settingspage.limits.betapprovallimit.crossgamemultiinputlabel':
    'Cross Game Multi Limit',
  'settingspage.limits.betapprovallimit.updated':
    'Automatic Bet Approval Limit updated',
  'settingspage.limits.exposurelimits.heading': 'Exposure',
  'settingspage.limits.exposurelimits.maxwinlabel': 'Max Win Exposure',
  'settingspage.limits.exposurelimits.maxplacelabel': 'Max Place Exposure',
  'settingspage.limits.exposurelimits.maxglobalexposurelabel':
    'Global Exposure',
  'settingspage.limits.exposurelimits.maxsportexposure': 'Max Sport Exposure',
  'settingspage.limits.exposurelimits.maxstakeexotics': 'Max Stake Exotics',
  'settingspage.limits.exposurelimits.maxexoticspayout': 'Max Exotics Payout',
  'settingspage.limits.exposurelimits.updated': 'Exposure Limits updated',
  'settingsPage.offerings.featuredEventsTabText.header': 'Featured Events',
  'settingsPage.offerings.offeringsTabText.header': 'Offerings',
  'settingsPage.offerings.spDisabledSuccessTitle':
    'Starting Price odds have been disabled globally.',
  'settingsPage.offerings.spEnabled': 'SP enabled',
  'settingsPage.offerings.spEnabledSuccessTitle':
    'Starting Price odds have been enabled globally.',
  'regulatorpage.tabs.bethistory': 'Bet History',
  'regulatorpage.tabs.bethistory.searchby': 'Search by',
  'regulatorpage.tabs.bethistory.search': 'Search',
  'regulatorpage.tabs.bethistory.download': 'Download',
  'regulatorpage.tabs.bethistory.ticketnumber': 'Ticket Number',
  'regulatorpage.tabs.bethistory.clientname': 'Client Name',
  'regulatorpage.tabs.bethistory.clientid': 'Client ID',
  'regulatorpage.sequencenumber': 'Sequence Number',
  'regulatorpage.transactiontype': 'Transaction Type',
  'regulatorpage.amount': 'Transaction Amount',
  'regulatorpage.comment': 'Transaction Comment',
  'regulatorpage.createdat': 'Date/Time',
  'regulatorpage.runningbalance': 'Account Balance',
  'regulatorpage.transactionstatus': 'Transaction Status',
  'regulatorpage.successat': 'Transaction Status Date/Time',
  'regulatorpage.bettinghistory.betid': 'Ticket Number',
  'regulatorpage.bettinghistory.licensee': 'Licensee',
  'regulatorpage.bettinghistory.licensenumber': 'License Number',
  'regulatorpage.bettinghistory.productname': 'Product Name',
  'regulatorpage.bettinghistory.clientname': 'Client Name',
  'regulatorpage.bettinghistory.email': 'Client ID',
  'regulatorpage.bettinghistory.punterlocation': 'Client Location',
  'regulatorpage.bettinghistory.ipaddress': 'Client Ip Address',
  'regulatorpage.bettinghistory.channel': 'Betting Channel',
  'regulatorpage.bettinghistory.stake': 'Bet Amount',
  'regulatorpage.bettinghistory.odds': 'Odds',
  'regulatorpage.bettinghistory.status': 'Bet Status',
  'regulatorpage.bettinghistory.statustime': 'Bet Status Date/Time',
  'regulatorpage.bettinghistory.betdescription': 'Bet Description',
  'regulatorpage.bettinghistory.channelname': 'Betting Channel Name',
  'regulatorpage.bettinghistory.timezone': 'Time Zone',
  'regulatorpage.bettinghistory.datefrom': 'Date from',
  'regulatorpage.bettinghistory.dateto': 'Date to',
  'regulatorpage.noresults': 'No results',
  'regulatorpage.tabs.financialtransactionhistory':
    'Financial Transaction history',
  'regulatorpage.ruleOne':
    'Must enter date range when searching by name or email',
  'regulatorpage.ruleTwo': 'Must enter correct search by criteria in drop down',
  'regulatorpage.ruleThree':
    'Must enter exact first and last name to search by name',
  'regulatorpage.ruleFour': 'Must enter full email address to search by email',
  'regulatorpage.ruleFive':
    'Must enter exact ticket number to search by ticket number',
  'promomanagerpage.header': 'Promo Manager',
  'promomanagerpage.errormessages.csvheadernames':
    'File does not contain the right header names, please use: punter and amount as headers in your CSV file.',
  'promomanagerpage.errormessages.csvparseerror':
    'File does not have the right format.',
  'promomanagerpage.routename.allpromos': 'All Promos',
  'promomanagerpage.bulkbonusawards.tableheadings.name': 'Name',
  'promomanagerpage.bulkbonusawards.tableheadings.numofpunterscredited':
    'No. of punters credited',
  'promomanagerpage.bulkbonusawards.tableheadings.totalamountcredited':
    'Total amount credited',
  'promomanagerpage.bulkbonusawards.tableheadings.date': 'Date',
  'promomanagerpage.importcsv': 'Import CSV',
  'promomanagerpage.addBanner': 'Add Banner',
  'promomanagerpage.addPromotion': 'Add Promotion',
  'promomanagerpage.addPromotions': 'Add Promotions',
  'promomanagerpage.createAffiliate': 'Create Affiliate',
  'promomanagerpage.modals.importcsv.reason': 'Reason',
  'promomanagerpage.modals.importcsv.name': 'Name',
  'promomanagerpage.history.filters.datefrom': 'Date From',
  'promomanagerpage.history.filters.dateto': 'Date To',
  'promomanagerpage.history.filters.reset': 'Reset',
  'promoManagerPage.actionsTableHeader': 'Actions',
  'promomanagerpage.bonusbackfor2nd': 'Bonus Back for 2nd',
  'promomanagerpage.oddsBoost': 'Odds Boost',
  'promomanagerpage.moneyBack': 'Money Back',
  'promomanagerpage.bonusbets': 'Bonus Bets',
  'promomanagerpage.giveAway': 'Give Away',
  'promomanagerpage.cancelbutton': 'Cancel',
  'promoManagerPage.claimedAmountCell':
    '<strong>{claimedAmount}</strong> ({claimedPunters})',
  'promoManagerPage.claimedAmountTableHeader': 'Claimed Amount',
  'promoManagerPage.claimedAmountTooltip':
    '{claimedAmount} total from {claimedPunters} punter(s)',
  'promomanagerpage.confirmbutton': 'Confirm',
  'promomanagerpage.depositmatch': 'Deposit Match',
  'promoManagerPage.downloadButton': 'Download',
  'promomanagerpage.dragactivedropzone': 'Drop files to upload',
  'promomanagerpage.dropzone': 'Add files',
  'promoManagerPage.endDateTableHeader': 'End Date',
  'promomanagerpage.examplecsvtablecaption': 'Example CSV table',
  'promoManagerPage.expiryLabel': 'Expiry (optional)',
  'promomanagerpage.filehelpertext': 'Example CSV table:',
  'promomanagerpage.filelabel': 'File',
  'promomanagerpage.filenametableheader': 'Filename',
  'promomanagerpage.importcsvsuccessdescription':
    'Your promo has been created.',
  'promomanagerpage.importcsvsuccesstitle': 'Promo created.',
  'promoManagerPage.nameErrorLabel': 'Name',
  'promomanagerpage.namelabel': 'Name',
  'promomanagerpage.nametableheader': 'Name',
  'promomanagerpage.pagesizelabel': 'Page Size',
  'promomanagerpage.pagesizeoption': 'Show {pageSize}',
  'promomanagerpage.potentialvaluealert':
    'Your CSV contains {totalPunters} row(s) totalling {maxValue} in potential value.',
  'promomanagerpage.potentialvaluecell':
    '<strong>{maxValue}</strong> ({totalPunters})',
  'promomanagerpage.potentialvaluetableheader': 'Potential Value',
  'promomanagerpage.potentialvaluetooltip':
    '{maxValue} total from {totalPunters} punter(s)',
  'promomanagerpage.reasonlabel': 'Reason',
  'promomanagerpage.reasontableheader': 'Reason',
  'promoManagerPage.startDateTableHeader': 'Start Date',
  'promomanagerpage.tablecaption': 'Deposit Match promos',
  'promomanagerpage.title': 'Promo Manager',
  'promomanagerpage.tabs.banners': 'Banners',
  'promomanagerpage.tabs.banners.web': 'Web',
  'promomanagerpage.tabs.banners.app': 'App',
  'promomanagerpage.tabs.affiliates': 'Affiliates',
  'promomanagerpage.tabs.promotions': 'Promotions',
  'promomanagerpage.affiliates.table.affiliateName': 'Affiliate Name',
  'promomanagerpage.affiliates.table.promotion': 'Promotion',
  'promomanagerpage.affiliates.table.details': 'Details',
  'promomanagerpage.affiliates.table.code': 'Code',
  'promomanagerpage.affiliates.table.status': 'Status',
  'promomanagerpage.affiliates.table.createdDate': 'Created Date',
  'promomanagerpage.affiliates.table.name': 'Name',
  'promomanagerpage.affiliates.table.defaultToken': 'Default Token',
  'promomanagerpage.affiliates.table.defaultDepositMatch':
    'Default Deposit Match',
  'promomanagerpage.affiliates.table.promoCode': 'Promo Code',
  'promomanagerpage.affiliates.table.affiliateStatus': 'Affiliate Status',
  'promomanagerpage.affiliates.addAffiliatePromoCode':
    'Add Affiliate Promo Code',
  'promomanagerpage.affiliates.affiliateIdLabel': 'Affiliate ID',
  'promomanagerpage.affiliates.promoCodeLabel': 'Code',
  'promomanagerpage.banners.table.thumbnail': 'Thumbnail',
  'promomanagerpage.banners.table.name': 'Name',
  'promomanagerpage.banners.table.status': 'Status',
  'promomanagerpage.banners.table.location': 'States Available',
  'promomanagerpage.banners.table.authenticated': 'Authenticated',
  'promomanagerpage.banners.table.punterCategory': 'Punter Category',
  'promomanagerpage.banners.table.device': 'Device',
  'promomanagerpage.banners.table.order': 'Order',
  'promomanagerpage.banner.title': 'Title',
  'promomanagerpage.banner.startTime': 'Start Time',
  'promomanagerpage.banner.endTime': 'End Time',
  'promomanagerpage.banner.webDesktop': 'Web (Desktop)',
  'promomanagerpage.banner.webMobile': 'Web (Mobile)',
  'promomanagerpage.banner.frontImage': 'Front Image',
  'promomanagerpage.banner.backImage': 'Back Image',
  'promomanagerpage.banner.2LayerUpload': '2 layer upload',
  'promomanagerpage.banner.app': 'App',
  'promomanagerpage.banner.save': 'Save',
  'promomanagerpage.banner.discard': 'Discard',
  'promomanagerpage.banner.status': 'Status',
  'promomanagerpage.banner.bannerDisplay': 'Banner Display',
  'promomanagerpage.banner.locations': 'Locations:',
  'promomanagerpage.banner.punterCategory': 'Punter Category:',
  'promomanagerpage.banner.titleInput.placeholder': 'Banner title...',
  'promomanagerpage.banner.linkInput.placeholder':
    'www.punterbet.com.au/sports/soccer/a-league',
  'promomanagerpage.banner.imageUplaoder.placeholder':
    'Recommended size: 836 x 120px.',
  'promomanagerpage.banner.toast.success': 'Banner successfully saved',
  'bankspage.header': 'Bank',
  'bankspage.routenames.withdrawals': 'Withdrawals',
  'bankspage.routenames.deposits': 'Deposits',
  'bankspage.routenames.bankanalysisdashboard': 'Bank Analysis Dashboard',
  'bankspage.withdrawals.errormessages.historydownloaderror':
    'ABA Download Error',
  'bankspage.withdrawals.filternames.pendingapprovals': 'Pending Approval',
  'bankspage.withdrawals.filternames.readytopay': 'Ready To Pay',
  'bankspage.withdrawals.filternames.history': 'History',
  'bankspage.withdrawals.pendingapproval.approveselected': 'Approve Selected',
  'bankspage.withdrawals.pendingapproval.rejectselected': 'Reject Selected',
  'bankspage.withdrawals.pendingapproval.nomorerecords':
    'No More Withdrawal Requests Pending Approval',
  'bankspage.withdrawals.pendingapproval.exportascsv': 'Export as CSV',
  'bankspage.withdrawals.pendingapproval.withrecordsselected':
    'With records selected',
  'bankspage.withdrawals.pendingapproval.tableheadings.transactiontype':
    'Transaction Type',
  'bankspage.withdrawals.pendingapproval.tableheadings.puntername':
    'Punter Name',
  'bankspage.withdrawals.pendingApproval.tableHeadings.punterCategory':
    'Punter Category',
  'bankspage.withdrawals.pendingapproval.tableheadings.flags': 'Flags',
  'bankspage.withdrawals.pendingapproval.tableheadings.requested': 'Requested',
  'bankspage.withdrawals.pendingapproval.tableheadings.withdrawalamount':
    'Withdrawal Amount',
  'bankspage.withdrawals.pendingapproval.tableheadings.result': 'Result',
  'bankspage.withdrawals.pendingapproval.norecords':
    'No Withdrawal Requests Pending Approval',
  'bankspage.withdrawals.pendingApproval.successMessage':
    'Withdrawal Request(s) actioned successfully',
  'bankspage.withdrawals.pendingApproval.errorMessage':
    'There was an error actioning the request(s). Please try again',
  'bankspage.withdrawals.readytopay.tableheadings.transactiontype':
    'Transaction Type',
  'bankspage.withdrawals.readytopay.tableheadings.puntername': 'Punter Name',
  'bankspage.withdrawals.readytopay.tableheadings.requested': 'Requested',
  'bankspage.withdrawals.readytopay.tableheadings.by': 'By',
  'bankspage.withdrawals.readytopay.nomorerecords':
    'No More Withdrawal Requests Ready To Pay',
  'bankspage.withdrawals.readytopay.tableheadings.neworexported':
    'New/Exported',
  'bankspage.withdrawals.readytopay.tableheadings.withdrawalamount': 'Amount',
  'bankspage.withdrawals.readytopay.tableheadings.status': 'Status',
  'bankspage.withdrawals.readytopay.tableheadings.transferstatus':
    'Transfer Status',
  'bankspage.withdrawals.readytopay.exportasaba': 'Export as ABA',
  'bankspage.withdrawals.readytopay.norecords':
    'No Withdrawal Requests Ready To Pay',
  'bankspage.withdrawals.readytopay.marksuccessful': 'Mark Successful',
  'bankspage.withdrawals.readytopay.markunsuccessful': 'Mark Unsuccessful',
  'bankspage.withdrawals.readytopay.successful': 'Successful',
  'bankspage.withdrawals.readytopay.unsuccessful': 'Unsuccessful',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.transactiontype':
    'Transaction Type',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.puntername':
    'Punter Name',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.requested':
    'Requested',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.by': 'By',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.completed':
    'Completed',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.method': 'Method',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.result': 'Result',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.withdrawalamount':
    'Amount',
  'bankspage.withdrawals.withdrawalhistory.nomorerecords':
    'No More Withdrawal Request History',
  'bankspage.withdrawals.withdrawalhistory.tableheadings.status': 'Status',
  'bankspage.withdrawals.withdrawalhistory.approvedtoday': 'Approved Today',
  'bankspage.withdrawals.withdrawalhistory.cancelledtoday': 'Cancelled Today',
  'bankspage.withdrawals.withdrawalhistory.rejectedtoday': 'Rejected Today',
  'bankspage.withdrawals.withdrawalhistory.exportasaba': 'Export as ABA',
  'bankspage.withdrawals.withdrawalhistory.norecords':
    'No Withdrawal Request History',
  'bankspage.deposits.filternames.filter': 'testFilter',
  'bankspage.bankanalysisdashboard.filternames.filter': 'testFilter',
  'bettablepopover.void': 'Cancel or Void bet',
  'bettablepopover.settlebet': 'Manually settle bet',
  'voidbetmodal.title': 'Cancel or Void',
  'voidbetmodal.titleon': 'stake on',
  'voidbetmodal.message': 'Please select a reason from the options below',
  'voidbetmodal.button': 'Confirm and update',
  'voidbetmodal.success': 'Bet cancelled/voided',
  'voidbetmodal.reasonplaceholder': 'Select a reason',
  'voidbetmodal.notes': 'Additional Comments - Optional',
  'placebetspage.header': 'Placed Bets',
  'placebetspage.noresults': 'No records found',
  'placebetspage.routename.upcoming': 'Upcoming',
  'placebetspage.routename.resulted': 'Resulted',
  'placebetspage.routename.betsdashboard': 'Bets Dashboard',
  'placebetspage.routename.flagged': 'Flagged',
  'placebetspage.common.code': 'Code',
  'placebetspage.common.result': 'Result',
  'placebetspage.common.puntersearch': 'Search by punter name',
  'placebetspage.common.eventtype': 'Event Type',
  'placebetspage.table.eventmarketpunter': 'Event/Market',
  'placebetspage.table.date': 'Date',
  'placebetspage.table.punter': 'Punter',
  'placebetspage.table.stake': 'Stake',
  'placebetspage.table.bonusstake': 'Bonus Stake',
  'placebetspage.table.status': 'Bet Status',
  'placebetspage.table.odds': 'Odds',
  'placebetspage.table.result': 'Result',
  'placebetspage.table.puntertype': 'Status',
  'placebetspage.table.return': 'Return',
  'placebetspage.table.profitandloss': 'P/L',
  'placebetspage.table.exposure': 'Exposure',
  'placebetspage.table.mbl': 'MBL',
  'placebetspage.table.bb': 'Bonus',
  'placebetspage.table.legs': 'Legs ',
  'placebetspage.upcomingtab.startstatus': 'Start Status',
  'placebetspage.upcomingtab.punterstatus': 'Punter Status',
  'betapprovalspage.header': 'Bet Approvals',
  'betapprovalspage.routename.approvals': 'Approvals',
  'betapprovalspage.routename.history': 'History',
  'betapprovalspage.toast.approved': 'Bet approved',
  'betapprovalspage.toast.countered': 'Bet countered',
  'betapprovalspage.toast.rejected': 'Bet rejected',
  'betapprovalspage.history.filters.reset': 'Reset all',
  'betapprovalspage.history.filters.searchpunter': 'Search for a punter name',
  'betapprovalspage.history.filters.datefrom': 'Date From',
  'betapprovalspage.history.filters.dateto': 'Date To',
  'betapprovalspage.history.filters.approver': 'Approver',
  'betapprovalspage.modals.counteroffer.title': 'Send a counter offer',
  'betapprovalspage.modals.counteroffer.prompt': 'Enter Counter Offer Stake',
  'betapprovalspage.modals.counteroffer.button': 'Send Counter Offer',
  'betapprovalspage.modals.reject.label': 'Reason',
  'betapprovalspage.modals.reject.optionallabel': 'Optional',
  'betapprovalspage.modals.reject.button': 'Confirm and reject bet',
  'betapprovalspage.modals.reject.title':
    'Reject {Name} {Stake} stake on {Event}',
  'betapprovalspage.modals.reject.placeholder': 'Select a reason',
  'betapprovalspage.table.eventmarket': 'Event/Market',
  'betapprovalspage.table.punter': 'Punter',
  'betapprovalspage.table.betstatus': 'Bet Status',
  'betapprovalspage.table.requestedodds': 'Requested Odds',
  'betapprovalspage.table.currentodds': 'Current Odds',
  'betapprovalspage.table.requestedstake': 'Requested Stake',
  'betapprovalspage.table.acceptedstake': 'Accepted Stake',
  'betapprovalspage.table.return': 'Return',
  'betapprovalspage.table.result': 'Result',
  'betapprovalspage.table.exposure': 'Exposure',
  'betapprovalspage.table.approvals': 'Approvals',
  'betapprovalspage.table.approveddate': 'Date Approved',
  'betapprovalspage.table.approveddateby': 'Action Date / By',
  'betapprovalspage.table.approvedby': 'By',
  'betapprovalspage.table.emptypending':
    'There are currently no pending approvals.',
  'betapprovalspage.table.emptyhistory':
    'There are currently no historical approvals.',
  'betapprovalspage.table.puntercategory': 'Punter Category',
  'betapprovalspage.table.dateplaced': 'Date Placed',
  'betapprovalspage.table.bonus': 'Bonus',
  'betapprovalspage.approvals.reject': 'Reject',
  'betapprovalspage.approvals.counter': 'Counter',
  'betapprovalspage.approvals.approve': 'Approve',
  'dashboardpage.header': 'Dashboard',
  'passwordstrength.casemix': 'Includes lower and uppercase ',
  'passwordstrength.number': 'Includes at least 1 number',
  'passwordstrength.length': 'Password is more than 8 characters in length',
  'passwordstrength.match': 'Passwords must match',
  'formvalidation.requiredfield': 'Required field. Please complete.',
  'formvalidation.mustbenumber': 'Must be a number',
  'formvalidation.minimumstake': 'Counter stake can not be or lower than 0',
  'formvalidation.higherstake':
    'Counter stake can not be higher then current stake.',
  'formvalidation.minimumamount': 'Amount has to be more than 0',
  'formvalidation.maxamount': 'Limit Exceeded',
  'formvalidation.filerequired': 'File required.',
  'formvalidation.maxlengtherror':
    'You have reached your maximum limit of characters allowed',
  'navigations.dashboard': 'Dashboard',
  'navigations.betapprovals': 'Bet Approvals',
  'navigations.placedbets': 'Placed Bets',
  'navigations.trademanager': 'Risk Manager',
  'navigations.riskmanager': 'Risk Manager',
  'navigations.promomanager': 'Promo Manager',
  'navigations.punters': 'Punters',
  'navigations.offerings': 'Offerings',
  'navigations.settings': 'Settings',
  'navigations.scoreboard': 'Scoreboard',
  'navigations.bank': 'Bank',
  'menu.profile': 'Profile',
  'menu.offerings': 'Offerings',
  'menu.systemSetup': 'System Setup',
  'menu.activityLog': 'Activity Log',
  'menu.logout': 'Logout',
  'navigations.regulator': 'Regulator',
};
