import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateAddCompetition } from './addCompetition';
import { TAddCompetitionBody } from './addCompetition.types';

export const useMutationAddCompetition = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TAddCompetitionBody>,
    'mutationFn'
  >
) => useMutation(mutateAddCompetition, options);
