import React, { FC } from 'react';
import { ContainerWrapper, ParentContainer } from './Styles';

interface CardProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const Card: FC<CardProps> = ({ children, className }) => (
  <ParentContainer className={className}>
    <ContainerWrapper>{children}</ContainerWrapper>
  </ParentContainer>
);
