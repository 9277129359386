import api from '@/api/api';
import {
  TMutateBestToteOfferingsBody,
  TMutateCashOutOfferingsBody,
  TMutateOfferingsBody,
  TMutateOfferingsResponse,
  TMutateSRMOfferingsBody,
  TMutateSRMOfferingsResponse,
  TQueryOfferingsResponse,
  TQuerySRMOfferingsResponse,
} from './offerings.types';

export const offeringsEndpoint = '/bookie/settings/system/offerings';
export const srmOfferingsEndpoint = '/bookie/settings/system/offerings/srm';

export const bestToteURL = '/bookie/settings/system/offerings/best-tote';
export const sportsCashOutURL = '/bookie/offerings/sport-cash-out-offerings';

export async function queryOfferings() {
  const res = await api.get<TQueryOfferingsResponse>(offeringsEndpoint);
  return res;
}

export async function querySRMOfferings() {
  const res = await api.get<TQuerySRMOfferingsResponse>(srmOfferingsEndpoint);
  return res;
}

export async function mutateOfferings(body: TMutateOfferingsBody) {
  const res = await api.post<TMutateOfferingsResponse>(offeringsEndpoint, body);
  return res;
}

export async function mutateSRMOfferings(body: TMutateSRMOfferingsBody) {
  const res = await api.post<TMutateSRMOfferingsResponse>(
    srmOfferingsEndpoint,
    body
  );
  return res;
}

export async function queryBestToteOfferings() {
  const res = await api.get<TMutateBestToteOfferingsBody>(bestToteURL);
  return res;
}

export async function mutateBestToteOfferings(
  body?: TMutateBestToteOfferingsBody
) {
  const res = await api.post<TMutateBestToteOfferingsBody>(bestToteURL, body);
  return res;
}

export async function mutateSportCashOutOfferings(
  body?: TMutateCashOutOfferingsBody
) {
  const res = await api.post<TMutateCashOutOfferingsBody>(
    sportsCashOutURL,
    body
  );
  return res;
}
