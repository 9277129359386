import { Select as SelectChakra, SelectProps } from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import React from 'react';

type TTextarea = {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
} & SelectProps;

export default function Select({ field, children, ...rest }: TTextarea) {
  const [fieldData] = field ?? [];

  return (
    <SelectChakra data-cy={`field-${rest.name}`} {...fieldData} {...rest}>
      {children}
    </SelectChakra>
  );
}
