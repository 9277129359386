/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  EModifierType,
  TModifierType,
} from '../../../../../../../Services/RaceDetails.types';
import {
  getPrice,
  isScratched,
  isRemoved,
} from '../../../../../../../Services/RaceDetails.utils';
import {
  UpdateBetsContainer,
  WinPlaceMarginContainer,
  WinPlaceLabel,
  CurrentOddsContainer,
  CurrentOddsText,
  CurrentOddsValue,
  BadgeSus,
} from './RunnerDetails.styles';

import { UpdateableMarginControls } from '@/common/components';
import { MARGIN_TICK_MAP } from '@/lib/Constants';
import { FormattedMessage } from 'react-intl';
import { TRunner, EGeneralStatus } from '@/lib/DBModels';

export type TRunnerDetailsState = {
  race_runner_id: string;
  winModifier: number;
  placeModifier: number;
  winPrice: number;
  placePrice: number;
};

export type TRunnerDetailsAction = {
  type: 'update' | 'reset';
  race_runner_id: string;
  winModifier?: number;
  placeModifier?: number;
  winPrice?: number;
  placePrice?: number;
};

type TRunnerDetails = {
  data: TRunner;
  state: TRunnerDetailsState;
  dispatch: React.Dispatch<TRunnerDetailsAction>;
  isManuallyManaged: boolean;
  onMarginChange?: () => void;
  selectedModifier: string;
  setSelectedModifier: (value: string) => void;
};

export default function RunnerDetails({
  data: runner,
  state,
  dispatch,
  isManuallyManaged,
  onMarginChange,
  selectedModifier,
  setSelectedModifier,
}: TRunnerDetails) {
  const race_runner_id = runner.race_runner_id ?? '';
  const isRunnerScratched = isScratched(runner);
  const isRunnerSettled = runner.status === EGeneralStatus.Settled;

  const initWinModifier =
    runner.win_proposition?.proposition_modifier?.modifier ?? 0;
  const initWinPrice = getPrice(
    runner?.win_proposition?.return_amount ?? 0,
    initWinModifier
  );

  const initPlaceModifier =
    runner.place_proposition?.proposition_modifier?.modifier ?? 0;
  const initPlacePrice = getPrice(
    runner?.place_proposition?.return_amount ?? 0,
    initPlaceModifier
  );

  useMemo(() => {
    if (
      state &&
      !isRunnerScratched &&
      (state.winPrice != initWinPrice || state.winModifier != initWinModifier)
    )
      dispatch({
        type: 'reset',
        race_runner_id,
        winPrice: initWinPrice,
        winModifier: initWinModifier,
      });
  }, [initWinPrice, initWinModifier, race_runner_id, isRunnerScratched]);

  useMemo(() => {
    if (
      state &&
      !isRunnerScratched &&
      (state.placePrice != initPlacePrice ||
        state.placeModifier != initPlaceModifier)
    )
      dispatch({
        type: 'reset',
        race_runner_id,
        placePrice: initPlacePrice,
        placeModifier: initPlaceModifier,
      });
  }, [initPlacePrice, initPlaceModifier, race_runner_id, isRunnerScratched]);

  const handleChangePropositionModifier = (
    value: number,
    propositionType: TModifierType
  ) => {
    let newPrice;
    if (propositionType == 'win_proposition') {
      newPrice = getPrice(runner?.win_proposition?.return_amount ?? 0, value);

      dispatch({
        type: 'update',
        race_runner_id,
        winPrice: newPrice,
        winModifier: value,
      });
    } else {
      newPrice = getPrice(runner?.place_proposition?.return_amount ?? 0, value);

      dispatch({
        type: 'update',
        race_runner_id,
        placePrice: newPrice,
        placeModifier: value,
      });
    }

    onMarginChange && onMarginChange();
  };

  if (!state) return null;

  return (
    <Flex direction="column">
      {isRunnerScratched || isRemoved(runner) ? (
        <Flex py="5" alignItems="center" justifyContent="center">
          <FormattedMessage id="generic.scratched" />
        </Flex>
      ) : (
        <UpdateBetsContainer>
          <WinPlaceMarginContainer>
            <CurrentOddsContainer>
              {runner.win_proposition?.is_suspended && !isRunnerSettled && (
                <BadgeSus>
                  <FormattedMessage id="generic.sus" />
                </BadgeSus>
              )}
              <WinPlaceLabel>
                <FormattedMessage id="trademanagerpage.common.w" />
              </WinPlaceLabel>
              <div>
                <CurrentOddsText>
                  <FormattedMessage id="trademanagerpage.common.current" />
                </CurrentOddsText>
                <CurrentOddsValue
                  style={
                    runner?.win_proposition?.winter_is_suspended &&
                    !isRunnerSettled
                      ? { textDecoration: 'line-through' }
                      : {}
                  }
                >
                  {runner?.win_proposition?.winter_return_amount?.toFixed(2) ??
                    ''}
                </CurrentOddsValue>
              </div>
            </CurrentOddsContainer>

            <UpdateableMarginControls
              updateDisabled={!isManuallyManaged}
              // tick
              value={isManuallyManaged ? state.winModifier : initWinModifier}
              // price
              differenceValue={
                isManuallyManaged ? state.winPrice : initWinPrice
              }
              marginType="single"
              tickUpDisabled={
                state.winPrice >=
                  MARGIN_TICK_MAP[MARGIN_TICK_MAP.length - 1].odds ||
                isManuallyManaged === false ||
                runner.win_proposition?.is_suspended
              }
              onTickUp={(_, newVal) =>
                handleChangePropositionModifier(newVal, 'win_proposition')
              }
              tickDownDisabled={
                state.winPrice <= MARGIN_TICK_MAP[0].odds ||
                isManuallyManaged === false ||
                runner.win_proposition?.is_suspended
              }
              onTickDown={(_, newVal) =>
                handleChangePropositionModifier(newVal, 'win_proposition')
              }
              testId={runner.number?.toString()}
              onSelect={() =>
                setSelectedModifier(`win-${runner?.number ?? ''}`)
              }
              isSelected={
                selectedModifier ===
                `${EModifierType.Win}-${runner?.number ?? ''}`
              }
              isEdited={
                isManuallyManaged && state.winModifier !== initWinModifier
              }
            />
          </WinPlaceMarginContainer>
          <WinPlaceMarginContainer>
            <CurrentOddsContainer>
              {runner.place_proposition?.is_suspended && !isRunnerSettled && (
                <BadgeSus>
                  <FormattedMessage id="generic.sus" />
                </BadgeSus>
              )}
              <WinPlaceLabel>
                <FormattedMessage id="trademanagerpage.common.p" />
              </WinPlaceLabel>
              <div>
                <CurrentOddsText>
                  <FormattedMessage id="trademanagerpage.common.current" />
                </CurrentOddsText>
                <CurrentOddsValue
                  style={
                    runner?.place_proposition?.winter_is_suspended &&
                    !isRunnerSettled
                      ? { textDecoration: 'line-through' }
                      : {}
                  }
                >
                  {runner?.place_proposition?.winter_return_amount?.toFixed(
                    2
                  ) ?? ''}
                </CurrentOddsValue>
              </div>
            </CurrentOddsContainer>

            <UpdateableMarginControls
              updateDisabled={!isManuallyManaged}
              value={
                isManuallyManaged ? state.placeModifier : initPlaceModifier
              }
              differenceValue={
                isManuallyManaged ? state.placePrice : initPlacePrice
              }
              marginType="single"
              tickUpDisabled={
                state.placePrice >=
                  MARGIN_TICK_MAP[MARGIN_TICK_MAP.length - 1].odds ||
                isManuallyManaged === false ||
                runner.place_proposition?.is_suspended
              }
              onTickUp={(_, newVal) =>
                handleChangePropositionModifier(newVal, 'place_proposition')
              }
              tickDownDisabled={
                state.placePrice <= MARGIN_TICK_MAP[0].odds ||
                isManuallyManaged === false ||
                runner.place_proposition?.is_suspended
              }
              onTickDown={(_, newVal) =>
                handleChangePropositionModifier(newVal, 'place_proposition')
              }
              onSelect={() =>
                setSelectedModifier(
                  `${EModifierType.Place}-${runner?.number ?? ''}`
                )
              }
              isSelected={
                selectedModifier ===
                `${EModifierType.Place}-${runner?.number ?? ''}`
              }
              isEdited={
                isManuallyManaged && state.placeModifier !== initPlaceModifier
              }
            />
          </WinPlaceMarginContainer>
        </UpdateBetsContainer>
      )}
    </Flex>
  );
}
