import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import { getStrings } from '../../../common/utils';
import { Card } from '../../../common/components';
import { ErrorLayout } from '../../../layouts/ErrorLayout/ErrorLayout';
import Button from '../../../common/components/Button/Button';

const ErrorPage: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const [{ ErrorPage: ErrorPageStrings }] = getStrings();

  const clickHandler = () => {
    resetErrorBoundary();
  };

  return (
    <Sentry.ErrorBoundary>
      <ErrorLayout>
        <Card title={ErrorPageStrings.Title}>
          {ErrorPageStrings.Message}

          <Button type="button" onClick={clickHandler}>
            {ErrorPageStrings.Button}
          </Button>
        </Card>
      </ErrorLayout>
    </Sentry.ErrorBoundary>
  );
};

export default ErrorPage;
