import { Modal as BCModal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import FormHOC from './FormHOC';
import { useModals } from '../../../../Services/BetApprovals.hooks';
import Form from './Form';

export default function Modal() {
  const { closeCounterBetModal, counterBetModalOpened } = useModals();

  return (
    <BCModal
      isOpen={counterBetModalOpened}
      onClose={() => closeCounterBetModal()}
    >
      <ModalOverlay />
      <ModalContent>
        <FormHOC>
          <Form />
        </FormHOC>
      </ModalContent>
    </BCModal>
  );
}
