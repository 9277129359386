import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '@/api/api.keys';
import { queryPromoSummary } from './promoSummary';
import { TQueryPromoSummaryParams } from './promoSummary.types';

export const useQueryPromoSummary = (
  params: TQueryPromoSummaryParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<Api['PromoSummaryResponse']>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(
    [keys.promoSummary, params],
    () => queryPromoSummary(params),
    options
  );
