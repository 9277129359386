import React from 'react';

import BetApprovalLimitForm from './BetApprovalLimitForm';
import BetApprovalLimitFormikHOC from './BetApprovalLimitFormikHOC';

export default function RejectLimits() {
  return (
    <BetApprovalLimitFormikHOC>
      <BetApprovalLimitForm />
    </BetApprovalLimitFormikHOC>
  );
}
