import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { getStrings } from '../../../../../common/utils';
import { useBetVoid } from '../Services/Services.VoidBet';
import { TVoidBetInput } from '../Services/Types.VoidBet';
import {
  VoidBetInitialValues,
  VoidBetInputValidation,
} from '../Services/Config.VoidBet';
import { setModalVoidBet } from '../../../../punters/pages/Bets/Services/Slice.Bets';
import { useAppDispatch } from '../../../../../common/hooks/useRedux';
import { errorMessageFromHTTPException } from '../../../../../lib/Error';
import { keys } from '../../../../../api/api.keys';

type TFormHOC = {
  children: ReactNode;
  betID: string;
  onVoided?: () => void;
};

export default function FormHOC({ betID, onVoided, children }: TFormHOC) {
  const client = useQueryClient();
  const { voidBet } = useBetVoid();
  const [{ VoidBetModal }] = getStrings();
  const dispatch = useAppDispatch();

  return (
    <Formik<TVoidBetInput>
      initialValues={{
        ...VoidBetInitialValues,
        bet_id: betID,
      }}
      validationSchema={VoidBetInputValidation}
      validateOnMount
      onSubmit={async (formData, { setSubmitting }) => {
        try {
          setSubmitting(true);

          await voidBet(formData);

          dispatch(setModalVoidBet(undefined));

          client.invalidateQueries(keys.punterBets).catch(() => undefined);
          onVoided?.();

          toast.success(VoidBetModal.Success);
        } catch (error) {
          toast.error(errorMessageFromHTTPException(error));
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
