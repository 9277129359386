import { usePromotions } from '../../../services/Promotions.hooks';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TData } from '@/api/promoManager/promotions/promotions.types';
import { useMutationAddPromotion } from '@/api/promoManager/promotions/promotions.hooks';

type TPropositionType = {
  id: string | undefined;
};

export const usePromotion = ({ id }: TPropositionType) => {
  const { data, isLoading } = usePromotions();
  const { mutateAsync } = useMutationAddPromotion();
  const toast = useToast();
  const navigate = useNavigate();

  const promotionToShow = data?.find((el: TData) => el?.id === id);

  const handleDelete = () => {
    const list = data?.filter((el: TData) => el.id !== promotionToShow?.id);
    const JSONObj = JSON.stringify(list);
    const base64String = btoa(JSONObj);

    mutateAsync({
      contentBase64: base64String,
      contentType: 'application/json',
      version: null,
    }).then(() => {
      toast({
        title: 'Banner Successfully Deleted',
        status: 'success',
        position: 'top-right',
        duration: 5000,
      });

      navigate('/promo-manager/promotions', { replace: true });
    });
  };

  return { promotionToShow, handleDelete, isLoading };
};
