import React, { FC } from 'react';
import { SaveButton } from './Styles';
import { getStrings } from '../../utils';

interface SaveChangesButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export const SaveChangesButton: FC<SaveChangesButtonProps> = ({
  onClick,
  disabled,
}) => {
  const [{ Generic }] = getStrings();
  return (
    <SaveButton disabled={disabled} onClick={onClick}>
      {Generic.SaveChangesButton}
    </SaveButton>
  );
};
