import React from 'react';
import { Icon, Text } from '@chakra-ui/react';
import { ArrowheadUp } from '@styled-icons/evaicons-solid/ArrowheadUp';
import { CSSObject } from '@emotion/react';
import { oddsFormatted } from '@/common/utils';

interface IPercentageBadge {
  value: number | null | undefined;
  sx?: CSSObject;
}

export const PercentageBadge = ({ value, sx }: IPercentageBadge) => {
  if (value === 0 || !value) return null;
  const isPositive = value > 0;
  return (
    <Text
      sx={{
        ...sx,
        color: isPositive ? '#29c770' : 'red.500',
        bg: isPositive ? '#3371356e' : '#523243',
        w: 'fit-content',
        p: '2px 5px',
        borderRadius: 'sm',
        right: '5px',
        textAlign: 'right',
        boxShadow: 'lg',
      }}
    >
      <Icon
        as={ArrowheadUp}
        mr="1"
        transform={isPositive ? 'rotate(0)' : 'rotate(180deg)'}
      />
      {oddsFormatted(value)}%
    </Text>
  );
};
