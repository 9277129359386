import { TBet } from '@/lib/DBModels';
import api from '../api';
import {
  TPlacedBetParams,
  TPlacedBetWincore,
  TPlacedBetsWincoreParams,
} from './placedBets.types';

export const placedBetsURL = '/bookie/placed-bets';

// Placed Bets
export const queryPlacedBets = (params: TPlacedBetParams) =>
  api.get<TBet[]>(placedBetsURL, { params }).then((res) => res.data);

// Placed Bets - Wincore
export const placedBetsWincoreURL = 'bookie/global-trading/placed-bets';
export const queryPlacedBetWincore = (params: TPlacedBetsWincoreParams) => {
  const newParams = new URLSearchParams();

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      if (!Array.isArray(value)) {
        newParams.append(key, String(value));
      } else {
        for (const val of value) {
          if (val !== undefined) {
            newParams.append(key, String(val));
          }
        }
      }
    }
  }
  return api
    .get<TPlacedBetWincore>(placedBetsWincoreURL, { params: newParams })
    .then((res) => res.data);
};
