import { useToast } from '@chakra-ui/react';
import {
  useMutationOfferings,
  useQueryOfferings,
} from '@/api/settings/system/offerings/offerings.hooks';
import useErrorHandler from '@/hooks/useErrorHandler';
import { TOfferings } from '@/api/settings/system/offerings/offerings.types';

export function useOfferings() {
  const onError = useErrorHandler();
  const mutation = useMutationOfferings();
  const query = useQueryOfferings({ refetchOnWindowFocus: false });
  const toast = useToast();
  const { data, isRefetching, isFetched, refetch } = query;
  const isLoading =
    query.isLoading || mutation.isLoading || isRefetching || !isFetched;
  const isSPEnabled = !!data?.data.starting_price;
  const isSGMEnabled = !!data?.data.sgm;
  const isBlendedEnabled = !!data?.data.blended;
  const isToteSingleEnabled = !!data?.data.tote_single;
  const isCashoutEnabled = !!data?.data.cashout;
  const isMysteryBetRolloverEnabled = !!data?.data.mystery_bet_rollover_enabled;
  const isMoMEnabled = !!data?.data.multi_of_multis_enabled;
  const isToteMultiplesEnabled = !!data?.data.tote_multi_enabled;

  const handler = (values: TOfferings) => {
    mutation.mutate(
      {
        ...(data?.data ?? {}),
        starting_price: values.starting_price,
        sgm: values.sgm,
        blended: values.blended,
        tote_single: values.tote_single,
        cashout: values.cashout,
        mystery_bet_rollover_enabled: values.mystery_bet_rollover_enabled,
        multi_of_multis_enabled: values.multi_of_multis_enabled,
        tote_multi_enabled: values.tote_multi_enabled,
      },
      {
        onError,
        onSuccess: async () => {
          await refetch();
          toast({
            title: 'Successfully updated!',
            status: 'success',
            position: 'top',
            duration: 9_000,
          });
        },
      }
    );
  };

  return {
    isSPEnabled,
    isSGMEnabled,
    isCashoutEnabled,
    isBlendedEnabled,
    isToteSingleEnabled,
    isMysteryBetRolloverEnabled,
    isMoMEnabled,
    isToteMultiplesEnabled,
    isLoading,
    onChange: handler,
  };
}
