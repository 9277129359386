import highroller from '@/assets/icons/player/highroller.svg';
import plus from '@/assets/icons/player/plus.svg';
import triangle from '@/assets/icons/player/triangle.svg';
import amber from '@/assets/icons/player/amber.svg';
import fire from '@/assets/icons/player/fire.svg';
import blacklist from '@/assets/icons/player/blacklist.svg';
import recreational from '@/assets/icons/player/recreational.svg';
import player from '@/assets/icons/player/player.svg';
import { EPlayerType } from '../../../../../../lib/DBModels';
import { TRequestBet } from './BetApprovals.types';
import { DeepPartial } from '@chakra-ui/react';
import { ApprovalResponse } from '@/app/components/Providers/ApproveRaces/types';
import store from '@/app/store';

/**
 * Util that returns the src to the players icon
 */
const PlayerIcons: Partial<Record<EPlayerType, string>> = {
  [EPlayerType.HighRoller]: highroller,
  [EPlayerType.Player]: player,
  [EPlayerType.Recreational]: recreational,
  [EPlayerType.Blacklist]: blacklist,
  [EPlayerType.Newbie]: plus,
  [EPlayerType.Sharp]: triangle,
  [EPlayerType.PromoArber]: amber,
};

export const getPlayerIcon = (playerType: EPlayerType) =>
  PlayerIcons[playerType] || plus;

/**
 * Util that returns the colour associated with the punter status
 */
const PunterCategoryColors: Partial<Record<EPlayerType, string>> = {
  [EPlayerType.HighRoller]: 'green.500',
  [EPlayerType.Newbie]: 'origin.500',
  [EPlayerType.Player]: 'yellow.400',
  [EPlayerType.Recreational]: 'purple.400',
  [EPlayerType.Sharp]: 'red.500',
  [EPlayerType.ApprovalOnly]: 'teal.400',
  [EPlayerType.PromoArber]: 'yellow.500',
};

export const getPunterCategoryColor = (playerType: EPlayerType) => {
  return PunterCategoryColors[playerType] || 'origin.500';
};

function isTBetRequestBet(
  bet: TRequestBet | ApprovalResponse
): bet is TRequestBet {
  return 'request_id' in bet;
}

type TClaimBy = {
  canClaim: boolean;
  canEdit: boolean;
  canOverrideClaim: boolean;
  canReleaseClaim: boolean;
  claimedByName: string | null;
};

const normalizeClaimData = (bet: TRequestBet | ApprovalResponse): TClaimBy => {
  // get profile
  const bookieProfile = store.getState().settings.bookieProfile;
  const canOverrideClaim =
    bookieProfile.permissions?.includes(
      'bet_approvals_approvals_claim_override'
    ) ?? false;

  if (isTBetRequestBet(bet)) {
    if (bet.claimed_by_email === bookieProfile.email) {
      return {
        canClaim: false,
        canEdit: true,
        canOverrideClaim: false,
        canReleaseClaim: true,
        claimedByName: bet.claimed_by_name ?? null,
      };
    } else if (
      bet.claimed_by_email !== bookieProfile.email &&
      bet.claimed_by_email !== null &&
      bet.claimed_by_email
    ) {
      return {
        canClaim: false,
        canEdit: false,
        canOverrideClaim,
        canReleaseClaim: false,
        claimedByName: bet.claimed_by_name ?? null,
      };
    }
    return {
      canClaim: bet.can_claim ?? true,
      canEdit: bet.can_edit ?? true,
      canOverrideClaim: bet.can_override_claim ?? false,
      canReleaseClaim: bet.can_release_claim ?? false,
      claimedByName: bet.claimed_by_name ?? null,
    };
  }

  return {
    canClaim: bet.canClaim ?? true,
    canEdit: bet.canEdit ?? true,
    canOverrideClaim: bet.canOverrideClaim ?? false,
    canReleaseClaim: bet.canReleaseClaim ?? false,
    claimedByName: bet.claimedByName ?? null,
  };
};

/**
 * Normalize data
 */
export type CommonResponse = {
  requestId: string;
  status: string;
  punterId: string;
  stake: number;
  odds: number;
  createdAt: string;
  eventStatus: string;
  runnerName: string;
  marketName: string;
  punterCategoryAtPlacement: EPlayerType | undefined;
  punterCategoryLimit: number;
  punterCategoryLimitName: string;
  punterName: string;
  sourceApiUrl: string;
  sourcePlatformName: string;
  betType: string;
  mblStake: number;
  requestType: string;
  priceType: string;
  betPayout: number;
  eventTitle: string;
  eventType: string;
  startTime: string;
  isBonusBet: boolean;
  // TODO: types
  // eslint-disable-next-line
  betLegs: any[];

  requestedStake: number;
  requestedOdds: number;
  raainRuleBreached: boolean;
  sourcePortalUrl: string;
  requestedPayout: number;
  stickyNote: TRequestBet['sticky_note'];
  punterCategory: EPlayerType;

  canClaim: boolean;
  canEdit: boolean;
  canOverrideClaim: boolean;
  canReleaseClaim: boolean;
  claimedByName: string | null;
};

export const normalizeBetApproval = (
  data: DeepPartial<ApprovalResponse> | DeepPartial<TRequestBet>
): CommonResponse => {
  /**
   * request_id is only returned via the bet approval endpoint, we can
   * use it to distinguish between the two responses.
   */

  if ('request_id' in data) {
    const _data = data as TRequestBet;
    const normalizedClaimData = normalizeClaimData(_data);
    return {
      requestId: _data.request_id ?? '',
      status: _data.status ?? '',
      punterId: _data.punter_id ?? '',
      stake: Number(_data.stake ?? 0),
      odds: Number(_data.odds ?? 0),
      createdAt: _data.created_at ?? '',
      eventStatus: _data.event_status ?? '',
      runnerName: _data.runner_name ?? '',
      marketName: _data.market_name ?? '',
      punterCategoryAtPlacement: _data.punter_category_at_placement,
      punterCategoryLimit: _data.punter_category_limit ?? 0,
      punterCategoryLimitName: _data.punter_category_limit_name ?? '',
      punterName: _data.punter_name ?? '',
      sourceApiUrl: _data.source_api_url ?? '',
      sourcePlatformName: _data.source_platform_name ?? '',
      betType: _data.type ?? '',
      mblStake: _data.mbl_stake ?? 0,
      requestType: _data.request_type ?? '',
      priceType: _data.price_type ?? '',
      betPayout: _data.bet_payout ?? 0,
      eventTitle: _data.event_title ?? '',
      eventType: _data.event_type ?? '',
      isBonusBet: _data.is_bonus_bet ?? false,
      betLegs: _data.bet_legs ?? [],
      startTime: _data.event_start ?? '',
      requestedStake: _data.requested_stake ?? 0,
      requestedOdds: _data.requested_odds ?? 0,
      raainRuleBreached: !!_data.raain_rule_breached,
      sourcePortalUrl: _data.source_portal_url ?? '',
      stickyNote: _data.sticky_note || _data.stickyNote,
      requestedPayout: _data.requested_payout ?? 0,
      punterCategory: _data.punter_category ?? EPlayerType.Newbie,

      ...normalizedClaimData,
    };
  } else {
    const _data = data as ApprovalResponse;

    return {
      requestId: _data.id ?? '',
      status: _data.status ?? '',
      punterId: _data.punterId ?? '',
      stake: Number(_data.request_stake ?? ''),
      // TODO: Look into legs odds
      odds: Number(
        _data.betLegs.length === 1 ? _data.betLegs[0]?.legOdds ?? 0 : 0
      ),
      createdAt: _data.createdAt ?? '',
      // TODO: Same here
      eventStatus:
        _data.betLegs.length === 1
          ? _data.betLegs[0]?.props?.[0].propData.eventStatus
          : '',
      runnerName:
        _data.betLegs.length === 1
          ? _data.betLegs[0]?.props?.[0].propData.runnerName
          : '',
      marketName:
        _data.betLegs.length === 1
          ? _data.betLegs[0]?.props?.[0].propData.marketName
          : '',
      punterCategoryAtPlacement: _data.punterCategoryAtPlacement,
      punterCategoryLimit: Number(_data.punterCategoryLimit ?? 0),
      punterCategoryLimitName: _data.punterCategoryLimitName,
      punterName: `${_data.punterData.punterFirstName} ${_data.punterData.punterLastName}`,
      sourceApiUrl: _data.source_api_url ?? '',
      sourcePlatformName: _data.source_platform_name ?? '',
      betType: _data.betType,
      mblStake: Number(_data.mblStake ?? 0),
      requestType: _data.requestType ?? '',
      // TODO: Double check this
      priceType: _data.requestType ?? '',
      betPayout: 0, // FIXME: BE need to return this
      eventTitle:
        _data.betLegs.length === 1
          ? _data.betLegs[0].props?.[0].propData.raceName ?? ''
          : '',
      startTime:
        _data.betLegs.length === 1
          ? _data.betLegs[0].props?.[0].propData.eventStart ?? ''
          : '',
      isBonusBet: _data.bonusBet ?? false,
      betLegs: _data.betLegs ?? [],
      requestedStake: _data.requestedStake ?? 0,
      requestedOdds: _data.requestedOdds ?? 0,
      raainRuleBreached: !!_data.raainRulesBreached,
      sourcePortalUrl: _data.sourcePortalUrl ?? '',
      stickyNote: _data.stickyNote ?? {},
      requestedPayout: _data.requestedPayout ?? 0,
      punterCategory: _data.punterCategory ?? EPlayerType.Newbie,
      canClaim: _data.canClaim ?? false,
      canEdit: _data.canEdit ?? false,
      canOverrideClaim: _data.canOverrideClaim ?? false,
      canReleaseClaim: _data.canReleaseClaim ?? false,
      claimedByName: _data.claimedByName ?? null,
      eventType: _data.eventType ?? '',
    };
  }
};

/**
 * TODO:
 * Remove unnecessary hook
 */
export const usePunterCategory = (playerType: EPlayerType) => {
  const getPlayerIcon = () => {
    switch (playerType) {
      case EPlayerType.HighRoller:
        return highroller;
      case EPlayerType.Player:
        return player;
      case EPlayerType.Recreational:
        return recreational;
      case EPlayerType.Blacklist:
        return blacklist;
      case EPlayerType.Newbie:
        return plus;
      case EPlayerType.Sharp:
        return triangle;
      case EPlayerType.PromoArber:
        return amber;
      default:
        return fire;
    }
  };

  const getPunterCategoryColor = (): string => {
    switch (playerType) {
      case EPlayerType.HighRoller:
        return 'green.500';
      case EPlayerType.Newbie:
        return 'origin.500';
      case EPlayerType.Player:
        return 'yellow.400';
      case EPlayerType.Recreational:
        return 'purple.400';
      case EPlayerType.Sharp:
        return 'red.500';
      case EPlayerType.ApprovalOnly:
        return 'teal.400';
      case EPlayerType.PromoArber:
        return 'yellow.500';
      default:
        return 'red.900';
    }
  };

  return { getPlayerIcon, getPunterCategoryColor };
};
