import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Promotion name is required'),
  promo_description: Yup.string().required('Promotion description is required'),
  start_time: Yup.string().required('Start time is required'),
  expiry: Yup.string().required('Expiry is required'),
  audience_conditions: Yup.object().shape({
    punter_categories: Yup.array(),
    age_ranges: Yup.array(),
    address_locations: Yup.array(),
  }),
  parameters: Yup.object().shape({
    percentage: Yup.number()
      .required('Percentage is required')
      .min(0, 'Percentage must be at least 0')
      .max(100, 'Percentage cannot be more than 100'),
    max_amount: Yup.number()
      .required('Max amount is required')
      .min(1, 'Max amount must be at least $1'),
    max_payout_position: Yup.number().required('Positions are required'),
  }),
  race_ids: Yup.array()
    .required('Race IDs are required')
    .min(1, 'At least one race is required'),
});

export default validationSchema;
