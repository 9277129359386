import React from 'react';
import {
  FormLabelProps,
  FormErrorMessageProps,
  TextProps,
  StyleProps,
  SelectProps,
} from '@chakra-ui/react';
import HOCs from '../HOCs';
import SelectComponent from './Select';

export type TIndex = {
  name: string;
  isFormik?: boolean;
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: TextProps;
  sxWrapper?: StyleProps;
} & SelectProps;

export default function Select({
  isFormik = true,
  name,
  label,
  error,
  helperText,
  propsLabel,
  propsError,
  propsHelper,
  sxWrapper,
  children,
  ...rest
}: TIndex) {
  const id = `${name}-input`;

  return (
    <HOCs
      {...{
        isFormik,
        id,
        name,
        label,
        error,
        helperText,
        propsLabel,
        propsError,
        propsHelper,
        sxWrapper,
      }}
    >
      <SelectComponent id={id} name={name} {...rest}>
        {children}
      </SelectComponent>
    </HOCs>
  );
}
