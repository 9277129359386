import {
  Box,
  BoxProps,
  chakra,
  ChakraComponent,
  Icon,
  IconProps,
  Image,
  ImageProps,
  keyframes,
  MenuButton,
  MenuButtonProps,
  MenuGroup,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

export const ButtonNotification: ChakraComponent<
  'button',
  MenuButtonProps & { isActive: boolean }
> = chakra(MenuButton, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isActive }: any) => ({
    color: isActive ? 'red.500' : 'gray.500',
    pos: 'relative',
    '&:after': {
      display: isActive ? 'block' : 'none',
      content: `""`,
      boxSize: '2',
      bg: 'red',
      pos: 'absolute',
      borderRadius: 'full',
      top: '1',
      right: '1',
    },
  }),
});

const shake = keyframes`
0%,
100% {
  transform: rotate(0deg)s;
  
}
20%,
60% {
  transform: rotate(6deg);
}
40%,
80% {
  transform: rotate(-6deg);
}
`;

export const IconLogout: ChakraComponent<
  'span',
  IconProps & { isActive: boolean }
> = chakra(Icon, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ isActive }: any) => ({
    boxSize: '10',
    ml: '.5',
    transition: '.3s ease',
    color: isActive ? 'gray.500' : 'gray.400',
    bg: isActive && 'gray.100',
    borderRadius: 'full',
    p: '1',
    animation: isActive ? `${shake} .6s ease infinite` : 'none',

    _hover: {
      cursor: 'pointer',
      bg: 'gray.100',
      color: 'gray.700',
    },
  }),
});

export const UserBadge = chakra(Box, {
  baseStyle: () => ({
    display: 'flex',
    minW: '7',
    boxSize: '7',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'semibold',
    mr: '2',
    border: '2px solid',
    borderColor: 'white',
    outline: '2px solid',
    textTransform: 'uppercase',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const PlayerIcon: ChakraComponent<'img', ImageProps> = chakra(Image, {
  baseStyle: () => ({
    boxSize: '5',
    p: '1',
  }),
});

export const BCMenuGroup = chakra(MenuGroup, {
  baseStyle: () => ({
    fontSize: 'lg',
    color: 'gray.700',
    fontWeight: 'sembold',
    borderBottom: '1px',
    borderColor: 'gray.300',
  }),
});

export const FlexMenuWrapper = chakra(NavLink, {
  baseStyle: () => ({
    display: 'flex',
    flexDir: 'column',
    w: 'full',
    m: '2',
    transition: '.3s ease',
    borderBottom: '1px',
    borderColor: 'gray.300',

    _hover: {
      bg: 'gray.100',
    },
  }),
});
