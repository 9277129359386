import React, { Fragment } from 'react';
import {
  Thead,
  Tr,
  Th,
  Icon,
  Box,
  Button,
  Table as ChakraTable,
} from '@chakra-ui/react';
import { Droppable, DroppableProvided } from '@hello-pangea/dnd';
import { flexRender, Table as TanStackTable } from '@tanstack/react-table';
import { ArrowUnsorted } from '@styled-icons/typicons/ArrowUnsorted';
import { BetTableStyle } from '../BetsTable.styles';
import TableBody from './TableBody';

type TableProps<T> = {
  data: T[];
  draggedOrder: T[];
  setDisplaySaveOrderButtons: React.Dispatch<React.SetStateAction<boolean>>;
  displaySaveOrderButtons: boolean;
  isApprovalsTable?: boolean;
  table: TanStackTable<T>;
  setDraggedOrder: React.Dispatch<React.SetStateAction<T[]>>;
  hasSubTable?: boolean;
  isDraggable?: boolean;
  onClickRow?: (rowData: T) => void;
  onClickExpand?: (param: T) => void;
  loading?: boolean;
  updateOrder?: (body: T[]) => Promise<void>;
  children?: React.ReactNode;
};

export const Table = <T,>({
  data,
  draggedOrder,
  setDisplaySaveOrderButtons,
  isApprovalsTable,
  displaySaveOrderButtons,
  table,
  hasSubTable,
  isDraggable,
  onClickRow,
  onClickExpand,
  loading,
  setDraggedOrder,
  updateOrder,
  children,
}: TableProps<T>) => {
  return (
    <BetTableStyle
      isApprovalsTable={isApprovalsTable}
      isDraggable={isDraggable}
    >
      <div className="tableParent">
        <ChakraTable>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Fragment key={headerGroup.headers.length}>
                <Tr key={headerGroup.id}>
                  {hasSubTable && <th colSpan={1} aria-label="MultiChevron" />}
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Box
                          cursor={
                            header.column.getCanSort() && !isDraggable
                              ? 'pointer'
                              : ''
                          }
                          onMouseDown={
                            !isDraggable
                              ? header.column.getToggleSortingHandler()
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <Icon
                                color="gray.400"
                                boxSize="4"
                                as={ArrowUnsorted}
                              />
                            ),
                            desc: (
                              <Icon
                                color="gray.400"
                                boxSize="4"
                                as={ArrowUnsorted}
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </Box>
                      )}
                    </Th>
                  ))}
                </Tr>
              </Fragment>
            ))}
          </Thead>
          {isDraggable ? (
            <Droppable droppableId="droppable">
              {(provided: DroppableProvided) => (
                <TableBody
                  refCallback={provided.innerRef}
                  droppableProps={provided.droppableProps}
                  placeholder={provided.placeholder}
                  loading={loading}
                  table={table}
                  isDraggable
                  onClickExpand={onClickExpand}
                  onClickRow={onClickRow}
                >
                  {children}
                </TableBody>
              )}
            </Droppable>
          ) : (
            <TableBody
              loading={loading}
              table={table}
              onClickExpand={onClickExpand}
              onClickRow={onClickRow}
            >
              {children}
            </TableBody>
          )}
        </ChakraTable>
      </div>
      {displaySaveOrderButtons && (
        <>
          <Button
            onClick={() => {
              setDraggedOrder(data);
              setDisplaySaveOrderButtons(false);
            }}
            variant="outline"
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (updateOrder)
                updateOrder(draggedOrder).then(() =>
                  setDisplaySaveOrderButtons(false)
                );
            }}
          >
            Save new order
          </Button>
        </>
      )}
    </BetTableStyle>
  );
};
