// import { useQueryAuthToken } from '@/api/auth/authentication.hooks';
import { HTTPError } from '@/lib/api/types';
import { useToast } from '@chakra-ui/react';

type TError = {
  error: HTTPError;
};

enum EStatus {
  noCookie = 400,
  noPermission = 403,
  expired = 401,
}

export const useErrorHandler = () => {
  const toast = useToast();
  // const query = useQueryAuthToken();

  const errorHandler = ({ error }: TError) => {
    const status = error.response.status;

    switch (status) {
      case EStatus.expired:
        // If the authentication token has expired, refetch it.
        // query.refetch();

        break;
      case EStatus.noPermission:
        // Display a toast indicating that the user does not have permission to access the requested resource.
        const message = `You do not have permission to access.`;
        toast({
          title: 'Access Denied',
          description: message,
          status: 'error',
          position: 'top',
          duration: 9000,
        });
        break;
      default:
        // Display a generic error message for all other status codes.
        const defaultMessage = `An error occurred. Please try again later.`;
        toast({
          title: 'Error',
          description: defaultMessage,
          status: 'error',
          position: 'top',
          duration: 9000,
        });
        break;
    }
  };

  return {
    errorHandler,
  };
};
