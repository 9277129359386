import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSportsOffered } from '../../../../../../lib/DBModels';
import {
  TGlobalMarginsState,
  TAllOfferedRaceTypeVenues,
  TRaceModifiers,
  TSportModifier,
} from './types';
import {
  getRaceVenues,
  getRaceModifiers,
  getSportModifiers,
  getAllSportsWithCompetitions,
} from './actions';

const initialState: TGlobalMarginsState = {
  // Race
  globalRacingMargin: 0,
  globalRaceModifiers: [],
  globalRaceModifiersLoading: false,
  saveRaceAvailable: false,
  raceVenuesList: {
    horseRacing: [],
    harnessRacing: [],
    greyhounds: [],
  },
  raceVenuesListLoading: false,

  // Sport
  globalSportsMargin: 0,
  globalSportModifiers: [],
  sportsWithCompetitions: {
    sports: [],
    all_sport: false,
  },
  sportsWithCompetitionsLoading: false,
  globalSportModifiersLoading: false,
  saveSportAvailable: false,
};

const globalMargins = createSlice({
  name: 'globalMargins',
  initialState,
  reducers: {
    setGlobalRacingMargin: (
      state: TGlobalMarginsState,
      action: PayloadAction<number>
    ) => ({ ...state, globalRacingMargin: action.payload }),
    setGlobalSportsMargin: (
      state: TGlobalMarginsState,
      action: PayloadAction<number>
    ) => ({ ...state, globalSportsMargin: action.payload }),
    setRaceVenueList: (
      state: TGlobalMarginsState,
      action: PayloadAction<TAllOfferedRaceTypeVenues>
    ) => ({ ...state, raceVenuesList: action.payload }),
    setRaceModifiers: (
      state: TGlobalMarginsState,
      action: PayloadAction<TRaceModifiers[]>
    ) => ({ ...state, globalRaceModifiers: action.payload }),
    setSaveRaceAvailable: (
      state: TGlobalMarginsState,
      action: PayloadAction<boolean>
    ) => ({ ...state, saveRaceAvailable: action.payload }),
    setSaveSportAvailable: (
      state: TGlobalMarginsState,
      action: PayloadAction<boolean>
    ) => ({ ...state, saveSportAvailable: action.payload }),
    setSportModifiers: (
      state: TGlobalMarginsState,
      action: PayloadAction<TSportModifier[]>
    ) => ({ ...state, globalSportModifiers: action.payload }),
    setSportsWithCompetitions: (
      state: TGlobalMarginsState,
      action: PayloadAction<TSportsOffered>
    ) => ({ ...state, sportsWithCompetitions: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRaceVenues.pending, (state: TGlobalMarginsState) => ({
        ...state,
        raceVenuesListLoading: true,
      }))
      .addCase(getRaceVenues.fulfilled, (state: TGlobalMarginsState) => ({
        ...state,
        raceVenuesListLoading: false,
      }))
      .addCase(
        getAllSportsWithCompetitions.pending,
        (state: TGlobalMarginsState) => ({
          ...state,
          sportsWithCompetitionsLoading: true,
        })
      )
      .addCase(
        getAllSportsWithCompetitions.fulfilled,
        (state: TGlobalMarginsState) => ({
          ...state,
          sportsWithCompetitionsLoading: false,
        })
      )
      .addCase(getRaceModifiers.pending, (state: TGlobalMarginsState) => ({
        ...state,
        globalRaceModifiersLoading: true,
      }))
      .addCase(getRaceModifiers.fulfilled, (state: TGlobalMarginsState) => ({
        ...state,
        globalRaceModifiersLoading: false,
      }))
      .addCase(getSportModifiers.pending, (state: TGlobalMarginsState) => ({
        ...state,
        globalSportModifiersLoading: true,
      }))
      .addCase(getSportModifiers.fulfilled, (state: TGlobalMarginsState) => ({
        ...state,
        globalSportModifiersLoading: false,
      }));
  },
});

// Slice actions
export const {
  // Race
  setGlobalRacingMargin,
  setRaceModifiers,
  setSaveRaceAvailable,
  setRaceVenueList,
  // Sport
  setGlobalSportsMargin,
  setSportModifiers,
  setSaveSportAvailable,
  setSportsWithCompetitions,
} = globalMargins.actions;

// Slide reducer
export default globalMargins.reducer;
