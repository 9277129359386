import styled, { keyframes } from 'styled-components';

const Impulse = ({ ...props }) => keyframes`
0% {
  background: ${props.backColor};
  transform: scale(1);
  animation-timing-function: cubic-bezier(1,0,0.7,1);
}
40% {
  background: ${props.frontColor};
  transform: scale(1.5);
  animation-timing-function: cubic-bezier(0.3,0,0,1);
}
72.5% {
  background:${props.backColor};
  transform: scale(1);
  animation-timing-function: linear;
}
100% {
  background: ${props.backColor};
  transform: scale(1);
}
`;

interface WrapperProps {
  size: number;
  sizeUnit: string;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size, sizeUnit }) => `${size}${sizeUnit}`};
  height: ${({ size, sizeUnit }) => `${size / 5}${sizeUnit}`};
`;

interface BallProps {
  size: number;
  frontColor: string;
  backColor: string;
  sizeUnit: string;
  index: number;
  x: number;
  y: number;
}

export const Ball = styled.div<BallProps>`
  position: absolute;
  top: ${({ y, sizeUnit }) => `${y}${sizeUnit}`};
  left: ${({ x, sizeUnit }) => `${x}${sizeUnit}`};
  width: ${({ size, sizeUnit }) => `${size / 5}${sizeUnit}`};
  height: ${({ size, sizeUnit }) => `${size / 5}${sizeUnit}`};
  border-radius: 50%;
  background-color: ${({ frontColor }) => frontColor};
  animation: ${Impulse} 1.25s linear infinite;
  animation-delay: ${({ index }) => index * 0.125}s;
`;
