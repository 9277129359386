import React from 'react';
import loadable from '@loadable/component';
import { FC } from 'react';
import Loader from '@/components/Routes/Loader';

const Payments = loadable(
  () => import('@/features/punters/pages/Payments/components/Payments'),
  { fallback: <Loader /> }
) as FC;

const PunterDeposits = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Deposits/Deposits'),
  { fallback: <Loader /> }
) as FC;

const Incentives = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Incentive/Incentives'),
  { fallback: <Loader /> }
) as FC;

const Limits = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Limits/Limits'),
  { fallback: <Loader /> }
) as FC;

const Notes = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Notes'),
  { fallback: <Loader /> }
) as FC;

const Profile = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Profile'),
  { fallback: <Loader /> }
) as FC;

const ActivityLog = loadable(
  () => import('@/features/punters/pages/Punter/tabs/ActivityLog/ActivityLog'),
  { fallback: <Loader /> }
) as FC;

const Transactions = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Transactions'),
  { fallback: <Loader /> }
) as FC;

const PunterDashboard = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Dashboard'),
  { fallback: <Loader /> }
) as FC;

const Offerings = loadable(
  () => import('@/features/punters/pages/Punter/tabs/Offerings/Offerings'),
  { fallback: <Loader /> }
) as FC;

export {
  Payments,
  PunterDeposits,
  Incentives,
  Limits,
  Notes,
  Profile,
  ActivityLog,
  Transactions,
  PunterDashboard,
  Offerings,
};
