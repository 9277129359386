import React from 'react';
import { useFormikContext } from 'formik';
import {
  Heading,
  Button,
  Flex,
  Box,
  Wrap,
  Text,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';

import Input from '../../../../../../../../common/components/FormElements/Input';

import { getStrings } from '../../../../../../../../common/utils';
import { TGlobalExposureLimits } from '../../types';
import { FEATURE_FLAGS } from '@/constants/featureFlags';

export default function ExposureLimitsForm() {
  const [
    {
      Generic,
      SettingsPage: {
        Limits: { ExposureLimits: LocalisedStrings },
      },
    },
  ] = getStrings();

  const { isSubmitting, dirty } = useFormikContext<TGlobalExposureLimits>();

  return (
    <>
      <Heading size="lg" mb={2}>
        {LocalisedStrings.heading}
      </Heading>

      <Text maxW="60rem">{LocalisedStrings.exposureDescription1}</Text>
      <Text maxW="60rem" mt="2">
        {LocalisedStrings.exposureDescription2p1}
        <Text as="b">{LocalisedStrings.exposureDescription2p2Bold}</Text>
        {LocalisedStrings.exposureDescription2p3}
        <Text as="b">{LocalisedStrings.exposureDescription2p4Bold}</Text>
        {LocalisedStrings.exposureDescription2p5}
      </Text>
      <Text mt="2">{LocalisedStrings.exposureDescription3}</Text>
      <OrderedList mt="2" ml="5">
        <ListItem>{LocalisedStrings.exposureDescriptionLi1}</ListItem>
        <ListItem>{LocalisedStrings.exposureDescriptionLi2}</ListItem>
        <ListItem>{LocalisedStrings.exposureDescriptionLi3}</ListItem>
        <ListItem>SGM Exposure: per SGM bet</ListItem>
      </OrderedList>

      <Wrap pt="5">
        <Box width="xs">
          <Input
            width="30"
            name="race_win_market_exposure_manual_approval_limit"
            InputLeftAddon="$"
            label={LocalisedStrings.maxWinLabel}
            placeholder={LocalisedStrings.maxWinLabel}
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="race_place_market_exposure_manual_approval_limit"
            InputLeftAddon="$"
            label={LocalisedStrings.maxPlaceLabel}
            placeholder={LocalisedStrings.maxPlaceLabel}
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="sport_win_market_exposure_manual_approval_limit"
            InputLeftAddon="$"
            label={LocalisedStrings.maxSportExposure}
            placeholder={LocalisedStrings.maxSportExposure}
          />
        </Box>

        {FEATURE_FLAGS.IS_SGM_ENABLED && (
          <Box width="xs">
            <Input
              width="30"
              name="sgm_per_bet_exposure_manual_approval_limit"
              InputLeftAddon="$"
              label="Max SGM Limit (PER BET)"
              placeholder="Max SGM Exposure"
            />
          </Box>
        )}

        {FEATURE_FLAGS.IS_SRM_ENABLED && (
          <Box width="xs">
            <Input
              width="30"
              name="srm_per_bet_exposure_manual_approval_limit"
              InputLeftAddon="$"
              label="Max SRM Exposure (PER BET)"
              placeholder="Max SRM Exposure"
            />
          </Box>
        )}

        {FEATURE_FLAGS.IS_BLENDED_ENABLED && (
          <Box width="xs">
            <Input
              width="30"
              name="blended_per_bet_exposure_manual_approval_limit"
              InputLeftAddon="$"
              label="Max Blended Limit (PER BET)"
              placeholder="Max Blended Exposure"
            />
          </Box>
        )}

        <Box width="xs">
          <Input
            width="30"
            name="even_shot_proposition"
            InputLeftAddon="$"
            label="Max Even Shot Exposure (Per Proposition)"
            placeholder="Proposition Exposure"
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="even_shot_per_bet_exposure_manual_approval_limit"
            InputLeftAddon="$"
            label="Max Even Shot Limit (PER BET)"
            placeholder="Per Bet Exposure"
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="mystery_bet_proposition"
            InputLeftAddon="$"
            label="Max Mystery Bet Limit (PER BET)"
            placeholder="Per Bet Exposure"
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="mystery_bet_per_bet_exposure_manual_approval_limit"
            InputLeftAddon="$"
            label="Max Mystery Bet Exposure (PER BET)"
            placeholder="Per Bet Exposure"
          />
        </Box>

        <Box width="xs">
          <Input
            width="30"
            name="multi_per_bet_exposure_manual_approval_limit"
            InputLeftAddon="$"
            label="Max Multi exposure (PER BET)"
            placeholder="Per Bet Exposure"
          />
        </Box>
      </Wrap>

      <Flex justify="flex-end" mt="10">
        <Button
          type="submit"
          variant="rounded"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !dirty}
        >
          {Generic.Save}
        </Button>
      </Flex>
    </>
  );
}
