export const keys = {
  platformPromo: 'platformPromo',
  banners: 'banners',
  betsForExotics: 'betsForExotics',
  betsForRunner: 'betsForRunner',
  getSportMarket: 'getSportMarket',
  getSport: 'getSport',
  getProposition: 'getProposition',
  getMatch: 'getMatch',
  getMarketGroup: 'getMarketGroup',
  getMarket: 'getMarket',
  getCompetition: 'getCompetition',
  downloadBets: 'downloadBets',
  downloadTransactions: 'downloadTransactions',
  bets: 'bets',
  transactions: 'transactions',
  bookieActions: 'bookieActions',
  punterActions: 'punterActions',
  authentication: 'authentication',
  raceDetails: 'raceDetails',
  raceResult: 'raceResult',
  punterBets: 'punterBets',
  punterProfile: 'punterProfile',
  punterTransaction: 'punterTransaction',
  punterSearch: 'punterSearch',
  punterDeposits: 'punterDeposits',
  incentivesBonusBets: 'incentivesBonusBets',
  historyWithdrawals: 'historyWithdrawals',
  historyWithdrawalStats: 'historyWithdrawalStats',
  statusWithdrawals: 'statusWithdrawals',
  pendingApprovals: 'pendingApprovals',
  historyApprovals: 'historyApprovals',
  placedBets: 'placedBets',
  multiBets: 'multiBets',
  srmBets: 'srmBets',
  srmRunners: 'srmRunners',
  srmCombinations: 'srmCombinations',
  srmPunterCombinations: 'srmPunterCombinations',
  srmCombinationPunters: 'srmCombinationPunters',
  evenShotBets: 'evenShotBets',
  byRacing: 'byRacing',
  bySport: 'bySport',
  findAnEvent: 'findAnEvent',
  bonusBets: 'bonusBets',
  policies: 'policies',
  promoManager: {
    bulkBonusAwards: 'bulkBonusAwards',
    bonusBets: 'bonusBets',
  },
  profile: 'profile',
  punterFlags: 'punterFlags',
  punterCategoryList: 'punterCategoryList',
  contact: 'contact',
  scoreboard: 'scoreboard',
  settings: {
    limits: {
      betApproval: {
        exposureLimit: 'exposureLimit',
        exoticLimit: 'exoticLimit',
        crossGameMultiLimit: 'crossGameMultiLimit',
        globalLimit: 'globalLimit',
      },
      globalExposureLimits: 'globalExposureLimits',
      autoRejectLimit: 'autoRejectLimit',
    },
  },
  payments: {
    cards: 'cards',
    bankAccounts: 'bankAccounts',
  },
  riskManager: {
    matchMarketPropositions: 'matchMarketPropositions',
    matchBetsForMarket: 'matchBetsForMarket',
  },
  withdrawals: {
    withdrawalRequests: 'withdrawalRequests',
  },
  firebaseAuth: 'firebaseAuth',
  promoPuntersCSV: 'promoPuntersCSV',
  promoSummary: 'promoSummary',
  promotions: 'promotions',
  tokenHistory: 'tokenHistory',
  tokenSummary: 'tokenSummary',
  offerings: 'offerings',
  offeringsSRM: 'offeringsSRM',
  sgm: 'sgm',
  raceNumbers: 'raceNumbers',
  cms: 'cms',
  sgmBets: 'sgmBets',
  sgmPropositions: 'sgmPropositions',
  sgmCombinations: 'sgmCombinations',
  stickyNote: 'stickyNote',
  blendedAllBets: 'blendedAllBets',
  blendedRunners: 'blendedRunners',
  bestToteOfferings: 'bestToteOfferings',
  racingMeetRaces: 'racingMeetRaces',
  racingVanueMeets: 'racingVanueMeets',
  punterOfferings: 'punterOfferings',
  affiliates: 'affiliates',
  inPlay: 'inPlay',
};
