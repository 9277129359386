import styled from 'styled-components';
import { Text } from 'grommet';
import { chakra, Box } from '@chakra-ui/react';
import { MoreVertical } from '@styled-icons/evaicons-solid';
import Input from '../../../../common/components/Input';

export const StyledInput = styled(Input)`
  width: 440px;
`;

export const TextEvent = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.global.colors.contentText};
`;

export const TextTH = styled(Text)`
  flex: 1;
  text-align: center;
`;

export const IconMoreVertical = styled(MoreVertical)`
  width: 20px;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const BonusBetFlag = chakra(Box, {
  baseStyle: () => ({
    bg: 'black',
    color: 'white',
    fontSize: 'xs',
    borderRadius: 'sm',
    textAlign: 'center',
    w: '14',
  }),
});
