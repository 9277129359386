import React, { FC } from 'react';
import { SelectProps, Select as GrommetSelect } from 'grommet';
import { SelectContainer, HeaderLabel, ErrorLabel } from './Styles';

export interface CustomSelectProps extends SelectProps {
  variation?: string;
  labelTitle?: string;
  width?: string;
  error?: boolean;
  errorText?: string;
  className?: string;
}

export const Select: FC<CustomSelectProps> = ({
  labelTitle,
  variation,
  width,
  error,
  errorText,
  className,
  ...rest
}) => (
  <div
    style={{ display: 'flex', flexDirection: 'column' }}
    className={className}
  >
    {labelTitle && (
      <HeaderLabel error={error}>
        {labelTitle} {errorText && <ErrorLabel>{errorText}</ErrorLabel>}
      </HeaderLabel>
    )}
    <SelectContainer width={width} variation={variation}>
      <GrommetSelect plain {...rest} dropHeight="190px" />
    </SelectContainer>
  </div>
);
