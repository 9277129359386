import { getStrings } from '../../../utils';

const [{ PasswordStrength: PasswordStrengthStrings }] = getStrings();

export const TPasswordStrengthRegexLength = /^.{8,}$/;
export const TPasswordStrengthRegexCaseMix = /^(?=.*[a-z])(?=.*[A-Z])/;
export const TPasswordStrengthRegexNumber = /^(?=.*[0-9])/;

export const TPasswordStrengthTests = [
  {
    regex: TPasswordStrengthRegexCaseMix,
    test: PasswordStrengthStrings.casemix,
    valid: false,
  },
  {
    regex: TPasswordStrengthRegexLength,
    test: PasswordStrengthStrings.length,
    valid: false,
  },
  {
    regex: TPasswordStrengthRegexNumber,
    test: PasswordStrengthStrings.number,
    valid: false,
  },
];
