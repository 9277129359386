import { TBetCloudErrorResponse } from '@/api/api.types';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { keys } from '../../api.keys';
import {
  mutateCreateAffiliate,
  mutateAffiliatePromotion,
  queryAffiliates,
  queryPlatformPromo,
  mutateUpdateAffiliate,
} from './affiliates';
import {
  TAffiliateSubmission,
  TAffiliatePromo,
  TQueryPlatformPromoParams,
} from './affiliates.types';

export const useMutationAffiliatePromotion = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<TBetCloudErrorResponse>,
      TAffiliatePromo
    >,
    'mutationFn'
  >
) => useMutation(mutateAffiliatePromotion, options);

export const useMutationCreateAffiliate = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<TBetCloudErrorResponse>,
      TAffiliateSubmission
    >,
    'mutationFn'
  >
) => useMutation(mutateCreateAffiliate, options);

export const useMutationUpdateAffiliate = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<TBetCloudErrorResponse>,
      TAffiliateSubmission
    >,
    'mutationFn'
  >
) => useMutation(mutateUpdateAffiliate, options);

export const useQueryAffiliatePromos = ({
  offset,
  affiliates,
  affiliate_name,
}: TQueryPlatformPromoParams) => {
  const { data, isLoading } = useQuery(
    [keys.platformPromo, affiliates, affiliate_name, offset],
    () =>
      queryPlatformPromo({
        offset,
        affiliates: false,
        affiliate_name,
      }),
    {
      staleTime: 60 * 5000, // 5 minutes
      enabled: !!affiliate_name,
    }
  );

  return {
    data,
    isLoading,
  };
};

export const useQueryAffiliates = (skip?: boolean) => {
  const { data, isLoading } = useQuery(
    [keys.affiliates],
    () => queryAffiliates(),
    {
      staleTime: 60 * 5000, // 5 minutes
      enabled: !skip,
    }
  );

  return {
    data,
    isLoading,
  };
};
