import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TAddPropositionBody } from './addProposition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const addPropositionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/add-proposition`;

// Trade Manager - Add Proposition

export const mutateAddProposition = (body: TAddPropositionBody) =>
  api
    .post<TMarketCreationResponse>(addPropositionURL, body)
    .then((res) => res.data);
