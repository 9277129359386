import { create } from 'zustand';

type TFilterOptionsStoreParams = {
  min_stake?: number | undefined;
  min_exposure?: number | undefined;
  max_odds?: number | undefined;
  bet_type?: string | undefined;
  currency?: string | undefined;
  event_type?: string | undefined;
  punter_name?: string | undefined;
  punter_category?: string[] | undefined;
  actions: {
    setState: (state: TFilterOptionsStoreParams) => void;
    clearState: () => void;
  };
};

export const FilterOptionsStore = create<TFilterOptionsStoreParams>((set) => ({
  min_stake: undefined,
  min_exposure: undefined,
  max_odds: undefined,
  bet_type: undefined,
  currency: undefined,
  event_type: undefined,
  punter_name: undefined,
  punter_category: undefined,
  actions: {
    setState: (state: TFilterOptionsStoreParams) => set(state),
    clearState: () =>
      set({
        min_stake: undefined,
        min_exposure: undefined,
        max_odds: undefined,
        bet_type: undefined,
        currency: undefined,
        event_type: undefined,
        punter_name: undefined,
        punter_category: undefined,
      }),
  },
}));
