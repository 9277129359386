import React, { useState } from 'react';
import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import EvenShotRunnersTab from './Tabs/EvenShotRunnersTab';
import EvenShotAllBetsTab from './Tabs/EvenShotAllBetsTab';
import { EVEN_SHOT_TAB } from './EvenShotBets.types';
import { useSearchParams } from 'react-router-dom';

export default function EvenShotBets() {
  const tabs = Object.values(EVEN_SHOT_TAB);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.get('evenshot_tab') || tabs[0]
  );

  const changeTab = (tabName: EVEN_SHOT_TAB) => {
    setCurrentTab(tabName);
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('evenshot_tab', tabName);
    setSearchParams(updatedSearchParams);
  };

  return (
    <Flex
      width="100%"
      direction="column"
      py="4"
      pr="2"
      borderTop="1px solid"
      borderTopColor="gray.200"
    >
      <HStack w="full" ml="6">
        {tabs.map((tab) => (
          <Button
            key={tab}
            colorScheme="gray"
            isActive={tab === currentTab}
            _active={{ bg: 'origin.500', color: 'white' }}
            onClick={() => changeTab(tab)}
            minW="150"
          >
            {tab}
          </Button>
        ))}
      </HStack>
      <Box paddingTop={3}>
        {currentTab === EVEN_SHOT_TAB.ALL_SRM && <EvenShotAllBetsTab />}
        {currentTab === EVEN_SHOT_TAB.RUNNERS && <EvenShotRunnersTab />}
      </Box>
    </Flex>
  );
}
