import { BetsTable } from '@/common/components';
import React, { useState } from 'react';
import { useMysteryBetBetsColumns } from './AllBets.config';
import { TRunner } from '@/lib/DBModels';
import { useQueryBetsForRunner } from '@/api/tradeManager/raceDetails/betsForRunner/betsForRunner.hooks';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';
import { Column } from '@tanstack/react-table';

type TAllBetsContainer = {
  runners: TRunner[];
  isLoading: boolean;
};
export default function AllBetsContainer({
  runners,
  isLoading,
}: TAllBetsContainer) {
  const [runnerId, setRunnerId] = useState<string>();
  const { columns, punterColumns } = useMysteryBetBetsColumns();

  // Runner Bets
  const limit = 20;
  const [offset, setOffset] = useState('0');
  const [history, setHistory] = useState<string[]>([]);
  const { data: runnerBets, isLoading: isRunnersLoading } =
    useQueryBetsForRunner({
      params: {
        ...(offset && { offset }),
        limit,
        race_runner: runnerId,
        price_type: 'mystery_bet',
      },
      options: {
        enabled: !!runnerId,
      },
    });

  const isLastFetch = (runnerBets?.items?.length ?? 0) < limit;

  return (
    <>
      <BetsTable
        hasSubTable
        columns={columns as unknown as Column<TRunner>[]}
        data={runners ?? []}
        loading={isLoading}
        onClickExpand={(row: TRunner) => {
          setRunnerId(row.race_runner_id);
        }}
      >
        <BetsTable
          columns={punterColumns}
          data={runnerBets?.items ?? []}
          loading={isRunnersLoading}
        />
      </BetsTable>

      <PaginationV3
        nextDisabled={isLastFetch}
        offset={offset}
        setOffset={(val) => setOffset(val)}
        onPageChange={(newOffset) => setOffset(newOffset)}
        useHistory
        history={history}
        setHistory={setHistory}
        nextOffsetId={
          !isLastFetch ? runnerBets?.paging?.next_offset : undefined
        }
      />
    </>
  );
}
