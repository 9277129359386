import React, { FC } from 'react';
import { Fade } from '@chakra-ui/react';
import {
  NavList,
  NavText,
  IconLogo,
  NavLinkLogo,
  IconTextLogo,
} from './Styles';
import { FormattedMessage } from 'react-intl';
import { useNavPanel } from './Navigation.hooks';
import { LogoMark, LogoType } from '../../../assets/logos';
import { links } from './services/Navigation.config';
import {
  MenuTooltip,
  NavIcon,
  NavLinkWrapper,
} from './styles/Navigation.styles';
import { usePendingApprovalsStore } from '@/app/components/Providers/ApproveRaces/store';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';

export const Navigation: FC = () => {
  const { expanded, hasPermission, totalRecords } = useNavPanel();

  const isDashboard = window.location.pathname.includes('dashboard');
  const { pendingApprovals } = usePendingApprovalsStore();

  return (
    <NavList
      sx={{
        border: isDashboard && 'none',
        bg:
          isDashboard &&
          'linear-gradient(359deg, rgb(12 68 121) 39%, rgb(0 28 55) 100%)',
      }}
      startingWidth={52.5}
      endWidth={240}
      isOpen={expanded}
    >
      <NavLinkLogo to="/">
        <IconLogo as={LogoMark} isOpen={expanded} fill="red" />
        <Fade in={expanded}>
          <IconTextLogo as={LogoType} color={isDashboard ? 'white' : 'black'} />
        </Fade>
      </NavLinkLogo>
      {links
        .filter((el) => hasPermission?.includes(el.id))
        .map(({ href, name, dataCyValue, icon }) => {
          const isBetApproval = name === 'navigations.betapprovals';
          const shouldDisplayTotalRecords =
            isBetApproval &&
            (isWincore ? pendingApprovals.length > 0 : totalRecords > 0);
          const shouldDisplayNavIcon = shouldDisplayTotalRecords && !expanded;

          const numberOfRequests = isWincore
            ? pendingApprovals.length
            : totalRecords;

          return (
            <MenuTooltip
              label={<FormattedMessage id={name} />}
              key={name}
              isExpanded={expanded}
              bg="gray.100"
              hasArrow
              placement="right"
            >
              <NavLinkWrapper
                data-cy={dataCyValue}
                to={href}
                isOpen={expanded}
                isFlashing={shouldDisplayTotalRecords}
              >
                {shouldDisplayNavIcon ? (
                  numberOfRequests
                ) : (
                  <NavIcon as={icon} isOpen={expanded} />
                )}
                {expanded && (
                  <Fade in={expanded}>
                    <NavText>
                      <FormattedMessage id={name} />
                    </NavText>
                  </Fade>
                )}
              </NavLinkWrapper>
            </MenuTooltip>
          );
        })}
    </NavList>
  );
};
