import React, { FunctionComponent } from 'react';
import {
  InputProps,
  Input as InputChakra,
  InputLeftAddonProps,
} from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import InputHOC from './InputHOC';

export type TInput = {
  id: string;
  name: string;
  InputLeftAddon?: string;
  inputLeftAddonSx?: InputLeftAddonProps;
  InputRightAddon?: string;
  InputRightElement?: FunctionComponent;
  InputLeftElement?: FunctionComponent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
} & InputProps;

/**
 * The actual Input component from Chakra.
 * This is wrapped with the HOC like component which adds
 * Icons/Elements to the Input.
 *
 * The field prop comes from the FormikHOC component which is dynamically
 * added.
 */
export default function Input({
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  inputLeftAddonSx,
  InputRightAddon,
  field,
  variant,
  ...rest
}: TInput) {
  const [fieldData] = field ?? [];

  return (
    <InputHOC
      InputLeftElement={InputLeftElement}
      InputRightElement={InputRightElement}
      InputLeftAddon={InputLeftAddon}
      inputLeftAddonSx={inputLeftAddonSx}
      InputRightAddon={InputRightAddon}
      size={rest.size}
      variant={variant}
    >
      <InputChakra
        type="text"
        data-cy={`field-${rest.name}`}
        {...fieldData}
        {...rest}
      />
    </InputHOC>
  );
}
