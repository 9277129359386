import React from 'react';
import { ChakraMotionBox, TMotion } from './HorizontalCollapse.styles';
import { THorizonalCollapse } from './HorizontalCollapse.types';

/* https://chakra-ui.com/community/recipes/horizontal-collapse */

export const HorizontalCollapse = ({
  children,
  startingWidth,
  endWidth,
  isOpen,
  ...rest
}: THorizonalCollapse & TMotion) => (
  <ChakraMotionBox
    hidden={false}
    initial={false}
    transition={{ type: 'spring', bounce: '0', duration: '0.5' }}
    animate={{ width: isOpen ? endWidth : startingWidth }}
    {...rest}
  >
    {children}
  </ChakraMotionBox>
);
