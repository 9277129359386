import { TPunterFlags } from '@/api/punterProfile/punterProfile.types';
import { EPlayerType } from '@/lib/DBModels';
import { TDeepPartial } from '@/lib/types';

export type TPunterSearch = TDeepPartial<{
  punter_id: string;
  full_name: string;
  state: string;
  punter_joined: string;
  punter_turnover: number;
  punter_lastbet: number;
  punter_lastbetdate: string;
  punter_numbets: number;
  punter_avgbet: number;
  punter_bcs: number;
  punter_winnings: number;
  punter_type: EPlayerType;
  flags: TPunterFlags[];
}>;

export type TPuntersResponse = TDeepPartial<{
  total_pages: number;
  total_records: number;
  records: TPunterSearch[];
}>;

export type TPuntersState = {
  puntersData: TPuntersResponse;
  puntersLoading: boolean;
};

export type TPunterQuery = {
  query?: string;
  sort_direction?: 'asc' | 'desc';
  sort_by?: string;
  offset?: number;
  limit?: number;
};

export enum EPunterFlagOptions {
  all = 'All Punters',
  allFlagged = 'all Flagged',
  flaggedByMe = 'flagged By Me',
  flaggedByOthers = 'flagged By Others',
}
