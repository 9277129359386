import { EGeneralStatus } from '../../lib/DBModels';
import { EVoidReason } from '../../features/bets/modals/VoidBetModal/Services/Types.VoidBet';
import { EBetRequestRejectReasons } from '../../features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { EBonusAwardReason } from '../../views/PromoManager/types';
import { EPunterProfileOptions } from '../../features/punters/pages/Punter/tabs/Profile/types';

const STRINGS = {
  Generic: {
    Save: 'Save',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    Configure: 'Configure',
    SaveUpdate: 'Save & update',
    SaveConfirm: 'Save & confirm',
    YesConfirm: 'Yes & confirm',
    Error: 'An unspecified error occurred. Please try again later.',
    PickDate: 'Pick a Date',
    AllBets: 'All Bets',
    DollarSign: '$',
    Market: 'Market',
    Bet: 'Bet',
    Odds: 'Odds',
    Return: 'Return',
    Current: 'Current',
    Close: 'Close',
    Open: 'Open',
    Email: 'Email',
    Proposition: 'Proposition',
    Password: 'Password',
    TermsConditions: 'Terms & Conditions',
    PrivacyPolicy: 'Privacy Policy',
    Submit: 'Submit',
    SaveChangesButton: 'Save Changes',
    ComingSoon: 'Coming soon...',
    ViewAll: 'View All',
    ViewDetails: 'View Details',
    AllOptions: 'All',
    TAB: 'TAB',
    GG: 'GG',
    Date: 'Date',
    Exposure: 'Exposure',
    Update: 'UPDATE',
    Scratched: 'SCRATCHED',
    WithRecordsSelected: 'With records selected',
    Refund: 'Refund',
    Void: 'Void',
    VoidOrCancel: 'Cancel or Void',
    Done: 'Done',
    Stake: 'Stake',
    RaceVoid: 'Race void',
    MatchVoid: 'Match void',
    Suspended: 'Suspended',
    PunterPlayerTypes: {
      highRoller: 'High Roller',
      recreational: 'Recreational',
      newbie: 'Newbie',
      player: 'Player',
      sharp: 'Sharp',
    },
    FileUploadModal: {
      title: 'Upload File',
      uploadErrorText: 'Error uploading file, please try again',
    },
    FileUploader: {
      chooseFile: 'Choose file',
      changeFile: 'Change file',
      typeText: 'Photos or Documents',
      uploadText: 'Click or drop file to upload',
      uploadError:
        'There was an error with your upload. Please ensure your file is a PNG, JPG or PDF and less than 5mb.',
    },
    ToolTip: {
      title: 'Remove File?',
      cancelText: 'No, Cancel',
      confirmText: 'Yes, Remove',
    },
    DeleteNoteModal: {
      title: 'Delete Note',
      subtitle: 'Are you sure you want to delete this note?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
    },
    BetStatus: {
      pending: 'Pending',
      partially_settled: 'Partially Settled',
      settled: 'Settled',
      voided: 'Voided',
      cashed_out: 'Cashed Out',
      Rejected: 'Rejected',
      Approved: 'Approved',
      ReducedStake: 'Partial',
    },
    GeneralStatus: {
      [EGeneralStatus.Open]: 'Open',
      [EGeneralStatus.Voided]: 'Voided',
      [EGeneralStatus.Closed]: 'Closed',
      [EGeneralStatus.Suspended]: 'Suspended',
      [EGeneralStatus.Settled]: 'Settled',
      [EGeneralStatus.Abandoned]: 'Abandoned',
      [EGeneralStatus.Scratched]: 'Scratched',
      [EGeneralStatus.Removed]: 'Removed',
      [EGeneralStatus.Mixed]: 'Mixed',
      [EGeneralStatus.ManuallySettled]: 'Manual settlement',
    },
  },
  ErrorPage: {
    Title: 'Error',
    Message: 'An unspecified error occurred. Please try again later.',
    Button: 'Refresh',
  },
  AuthErrorMessages: {
    ErrorCreatingAccount: 'There was an issue creating your account:',
    WrongCredentials:
      "Your credentials don't seem to be correct. Please try again.",
    EmailInUse: 'This email address is already in use.',
    UserDisabled: 'Your account has been disabled.',
    UnspecifiedError: 'An unspecified error occurred. Please try again later.',
    UserNotFound: 'Unable to find your account.',
    ExceededResetLimit:
      'You have exceeded your reset password limit. Please try again later',
  },
  MainHeader: {
    UserProfile: 'User Profile',
    ViewProfile: 'View Profile',
    LogOut: 'Log Out',
  },
  LoginPage: {
    Header: 'Welcome back',
    Subheader: 'Please log into your account below.',
    ForgotPassword: 'Forgot Password?',
    HaveAnAccount: "Don't have and account?",
    SignUp: 'Sign Up',
    Errors: {
      Generic: 'Incorrect email or password',
      Invalid: {
        Email: 'Invalid email address',
      },
      Required: {
        Email: 'Email required',
        Password: 'Password required',
      },
    },
  },
  ForgotPassword: {
    Heading: 'Forgot Password',
    SubHeading:
      'Please enter your email below and we will send you a reset link.',
    SuccessText: 'Thank you. We’ve sent a reset link to ',
    LoginLink: 'Click here to login',
    Button: 'Send reset link',
    ButtonSubmitting: 'Submitting...',
    Errors: {
      Invalid: {
        Email: 'Invalid email address',
      },
      Required: {
        Email: 'Email required',
      },
    },
  },
  PuntersPage: {
    Name: 'Name',
    Location: 'Location',
    Joined: 'Joined',
    Turnover: 'Turnover',
    LastBet: 'Last Bet',
    LastBetDate: 'Last Bet Date',
    Bets: 'Bets',
    AverageBet: 'Avg. Bet',
    BSP: 'BSP',
    WinLost: 'Won/Lost',
    Status: 'Status',
    HeaderTitle: 'Punters',
    NoData: 'N/A',
    SearchPlaceHolder: 'Search by name or email...',
  },
  PunterPage: {
    Header: {
      pageHeader: 'Punter Profile',
      playerStatus: 'Player Status',
      category: 'Category',
      manualOverride: 'Manual Override',
      currentBalance: 'Current Balance',
      bonusBalance: 'Bonus Balance',
      bonusGiven: 'Bonus Given',
      eligibleBonus: 'Eligible Bonus',
      turnover: 'Turnover',
      wonLost: 'Won/Lost',
      lastBet: 'Last Bet',
      avgBet: 'Average Bet',
      betsPlaced: 'Bets Placed',
      active: 'Active',
      inactive: 'Inactive',
      categoryOptions: {
        [EPunterProfileOptions.Newbie]: 'Newbie',
        [EPunterProfileOptions.Recreational]: 'Recreational',
        [EPunterProfileOptions.Player]: 'Player',
        [EPunterProfileOptions.Sharp]: 'Sharp',
        [EPunterProfileOptions.HighRoller]: 'High Roller',
        [EPunterProfileOptions.Blacklist]: 'Blacklist',
        [EPunterProfileOptions.ApprovalOnly]: 'Approval Only',
        [EPunterProfileOptions.PromoArber]: 'Promo Arber',
      },
      stormScoreEmpty: '0.00',
    },
    RouteName: {
      DashBoard: 'DashBoard',
      Bets: 'Bets',
      Payments: 'Payments',
      Deposits: 'Deposits',
      Incentives: 'Promos',
      Limits: 'Limits',
      Notes: 'Notes',
      NotesWithCount: 'Notes (%)',
      Profile: 'Profile',
      Chat: 'Chat',
      ActivityLog: 'Activity Log',
      Transactions: 'Transactions',
    },

    Profile: {
      ManageTheirBalances: 'Manage Their Balances',
      PayoutAmount: 'Payout this Punter a Custom Amount',
      PersonalDetails: 'Their Personal Details',
      LicensePassportNumber: 'License/Passport Number',
      KYC: {
        Title: 'KYC Status',
        verified: 'Verified',
        unverified: 'Unverified',
      },
      Status: {
        Types: {
          Normal: 'Normal',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Suspended_KYC: 'Suspended KYC',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Temporary_Self_Exclusion: 'Temporary Exclusion',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Permanent_Self_Exclusion: 'Permanent Exclusion',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Closed_Customer_Requested: 'Closed Customer Requested',
        },
        Title: 'Status',
        ExclusionPeriods: {
          placeholder: 'Select a period',
          expires: 'expires',
          title: 'Exclusion Period',
          period24: '24 hrs',
          period48: '48 hrs',
          period72: '72 hrs',
          periodWeek: '1 week',
          periodFortnight: '2 weeks',
          period1Month: '1 month',
          period3Months: '3 months',
          period6Months: '6 months',
          periodFormat: 'in X)',
        },
      },
      Form: {
        FirstName: 'First Name',
        LastName: 'Last Name',
        Email: 'Email',
        PhoneNumber: 'Phone Number',
        StreetNumber: 'Street No.',
        StreetName: 'Street Name',
        Suburb: 'Suburb',
        State: 'Select State',
        PostCode: 'Post Code',
        DateOfBirth: 'Date of Birth',
      },
      ErrorMessages: 'Sorry! something went wrong.',
      SuccessMessage: 'Saved successfully!',
    },
    Bets: {
      ColumnTitles: {
        Event: 'Event',
        Date: 'Date',
        BetStatus: 'Bet Status',
        Market: 'Market',
        Selection: 'Selection',
        Odds: 'Odds',
        Stake: 'Stake',
        Bonus: 'Bonus',
        Return: 'Return',
        BC: 'BC',
        PotentialAndActualReturns: 'Potential/Actual Returns',
      },
      ColumnContent: {
        Bonus: 'Bonus',
        NA: 'N/A ',
      },
      BB: 'BB',
      Filters: {
        Labels: {
          Race: 'Race',
          Match: 'Match',
        },
        Placeholders: {
          EventName: 'Search by event name...',
          Sport: 'Sport',
          Status: 'Status',
        },
      },
      Selection: {
        Racing: 'Racing',
        Sport: 'Sport',
        Mixed: 'Mixed',
        Legs: 'Legs',
      },
      Modal: {
        Headings: {
          ConfirmPayout: 'Confirm payout amount',
        },
        Labels: {
          TransactionId: 'Transaction ID',
          BetPlaced: 'Bet Placed',
          EventTime: 'Event Time',
          Bonus: 'Bonus Stake',
          Event: 'Event',
          Market: 'Market',
          Date: 'Date & Time',
          StakePerCombo: 'Stake per combo',
          Flexi: 'Flexi',
          NumberOfCombo: 'Number of combos',
          Odds: 'Odds',
          Result: 'Result',
          Selection: 'Selection',
          BB: 'BB',
          Stake: 'Stake',
          Return: 'Return',
          PuntersAccount: 'Refund into punters account',
          Combos: 'Combos',
          Selections: 'Selections',
          Legs: 'Legs',
          Race: 'Race',
          Won: 'Won',
          Lost: 'Lost',
        },
        Buttons: {
          Export: 'Export',
          Confirm: 'Confirm and settle bet',
        },
        Misc: {
          On: 'on',
          Loss: 'Loss',
          Lose: 'Lose',
          Win: 'Win',
          stake: 'stake',
          Pending: 'Pending',
          Payout: 'Payout',
          Deduction: 'Deduction',
        },
      },
      Errors: {
        Refund: 'Unable to handle this request at this time',
      },
    },
    Deposits: {
      ColumnTitles: {
        Type: 'Type',
        DateTime: 'Date & Time',
        Amount: 'Amount',
        Status: 'Status',
      },
      DepositStatus: {
        ManuallyApproved: 'ManuallyApproved',
        Pending: 'Pending',
        Approved: 'Approved',
        Declined: 'Declined',
        Abandoned: 'Abandoned',
        Cancelled: 'Cancelled',
        Unknown: 'Unknown',
      },
    },
    Payments: {
      accountNumber: 'Account Number',
      bank: 'Bank',
      blockedCardDigits: '•• •••• ',
      bsb: 'BSB',
      expiryDate: 'Expiry Date',
      cancel: 'Cancel',
      remove: 'Remove',
      removePaymentMethod: 'Remove payment method',
      removeAreYouSure: 'Are you sure you want to delete this payment method?',
      editDetails: 'Edit Details',
      deletionSuccess: 'Payment method deleted',
      deletionError: 'Something went wrong, please try again',
    },
    Incentives: {
      Tabs: {
        BetHistory: 'Bet History',
        Received: 'Received',
      },
      BonusBets: {
        BonusLabel: 'Bonus Bet Balance',
        AddBonus: '+ Add Bonus Bets',
      },
      Filters: {
        Placeholders: {
          PromoOrUsed: 'Search by promo or used on name',
          TypeOfIncentive: 'Type: All',
          OutcomeOfRace: 'Result: All',
        },
        Labels: {
          IncentiveType: {
            'Place(Fixed)': 'Place(Fixed)',
            'Win(Fixed)': 'Win(Fixed)',
          },
          Result: {
            Won: 'Won',
            Lost: 'No Return',
          },
        },
      },
      ColumnTitles: {
        Amount: 'Amount',
        UsedOn: 'Used On',
        DateIssues: 'Date issued',
        DateUsed: 'Date Used',
        Bet: 'Bet',
        Odds: 'Odds',
        BonusBet: 'Bonus Bet',
        PotentialPayout: 'Potential Payout',
        Reason: 'Reason',
        Result: 'Result',
        Return: 'Return',
      },
      RowLabels: {
        Result: {
          Won: 'Won',
          NoReturn: 'No Return',
        },
      },
      Modal: {
        Header: 'Add Bonus Bets',
        Cancel: 'Cancel',
        AddBonusBets: '+ Add Bonus Bets',
        SelectLabel: 'Reason',
        InputLabel: 'Amount',
        Reasons: {
          InitialValue: 'Please select...',
        },
        BonusBetsSchema: {
          min: 'Min bonus bet amount is $0.50',
          max: 'Max bonus bet amount is $10,000',
          amountRequired: 'Please enter amount',
          reasonRequired: 'Please select a reason',
        },
      },
      SuccessRevoke: 'Successfully revoked',
    },
    Reconcile: {
      Modal: {
        Header: 'Approve Payment',
        Button: 'Approve',
        Cancel: 'Cancel',
        InputLabel: 'Amount',
        BonusBetsSchema: {
          min: 'Min bonus bet amount is $0.50',
          max: 'Max bonus bet amount is $10,000',
          amountRequired: 'Please enter amount',
          reasonRequired: 'Please select a reason',
        },
      },
      Button: 'Reconcile',
    },
    Notes: {
      timeStamp: {
        at: ' at ',
        addedBy: ' added by ',
      },
      type: 'Type: ',
      allTypesFilter: 'All Types',
      noteTypes: {
        selectTypePlaceholder: 'Select Note Type',
        accountBalance: 'Account Balance',
        dispute: 'Dispute',
        verification: 'Verification',
        promo: 'Promo',
        general: 'General',
      },
      createNew: 'Create New',
      delete: 'Delete',
      cancel: 'Cancel',
      saveNote: 'Save Note',
      newNoteHeading: 'Create New Note',
      newNotePlaceholder: 'New Note',
      typeNotePlaceholder: 'Please type your note...',
      you: 'You',
    },
    Limits: {
      DepositLimit: {
        heading: 'Deposit Limit',
        subheading:
          'This allows you to limit the maximum amount a punter can deposit into their betting account.',
        limitAmountLabel: 'Limit Amount',
        limitPeriodLabel: 'Limit Period',
        setButtonText: 'Set Limit',
        currentSubheading: 'Current',
        historySubheading: 'History',
        setByPunter: '% limit set by the punter',
        setByAdmin: '% limit set by the X',
        coolingOffStatement: 'Limit will increase to % on the &',
        increaseLimitAfter: 'Increase limit after',
        approvedLimit: 'Deposit limit approved',
        errorStrings: {
          requiredAmount: 'Deposit limit amount is required',
          cannotIncrease: 'Deposit limit cannot be increased until %',
          requiredPeriod: 'Deposit limit period is required',
          numbersOnly: 'Deposit limit can only contain numbers',
          sameValue: 'Amount entered is the same as your current deposit limit',
          chooseALimit: 'Please choose a limit',
        },
      },
    },
    Transactions: {
      modal: {
        header: 'Manual adjustment',
        addAmount: 'Add Amount',
        subtractAmount: 'Subtract Amount',
        amountInput: 'Amount',
        reasonInput: 'Reason',
        noteInput: 'Note',
        pleaseSelect: 'Please select...',
        adjustBalance: 'Adjust Balance',
        amountRequired: 'Please enter amount',
        reasonRequired: 'Please select a reason',
        noteRequired: 'Please enter a note',
      },
      manualAdjustment: '+ Manual Adjustment',
      currentBalance: 'Current Balance:',
      downloadHistory: 'Download All Transaction History',
      downloadErrorMessage:
        "Something went wrong, couldn't download transactions...",
      columns: {
        status: 'Status',
        date: 'Date',
        type: 'Type',
        details: 'Details',
        note: 'Note',
        reason: 'Reason',
        betStatus: 'Bet Status',
        odds: 'Odds',
        stake: 'Stake',
        amount: 'Amount',
        balance: 'Balance',
      },
    },
  },
  OfferingsPage: {
    Header: 'Offerings',
    OfferingsTabText: {
      Header: 'Offerings',
      Title: 'Which sports would you like to offer to your punters',
      Subtitle:
        'This hides any sports or codes or tracks from your punters so you can get them to focus on the events you want to cover.',
      OfferedCount: '($/% offered)',
    },
    FeaturedEventsTabText: {
      Header: 'Featured Events',
    },
  },
  TradeManagerPage: {
    Common: {
      SettleMarket: 'Settle market',
      VoidMarket: 'Void market',
      SuspendMarket: 'Suspend market',
      EnableMarket: 'Enable market',
      SuspendProposition: 'Suspend proposition',
      EnableProposition: 'Enable proposition',
      VoidProposition: 'Void proposition',
      Current: 'Current',
      P: 'P',
      W: 'W',
      Date: 'Date',
      Status: 'Status',
      Dividend: 'Dividend',
      Race: 'Race',
      Bets: 'Bets',
      WinBets: 'Win Bets',
      WinHold: 'Win Hold',
      PlaceHold: 'Place Hold',
      AvgWinPrice: 'Avg. Win Price',
      PlaceBets: 'Place Bets',
      PlaceLiability: 'Place Liability',
      Value: 'Value',
      BonusBet: 'Bonus Bet',
      BestResult: 'Best Result',
      Result: 'Result',
      BetsPlaced: 'Bets Placed',
      Turnover: 'Turnover',
      Exposure: 'Exposure',
      WinResult: 'Win Result',
      BiggestWinBet: 'Biggest Win Bet',
      BiggestBet: 'Biggest Bet',
      Event: 'Event',
      NoRacesFound: 'No races found...',
      ManageSports: 'Manage Sports',
      ManageRaces: 'Manage Races',
      ResetAll: 'Reset All',
      Place: 'Place',
      Win: 'Win',
      SelectStatus: 'Select Status',
      StatusControl: 'Status Control',
      SelectEvent: 'Event Type',
      RaceForm: 'Race Form',
      ViewIndividualBets: 'View Individual Bets',
      RaceResults: 'Final Race Results',
      NoData: 'N/A',
      ViewAllVenues: 'View All Venues',
      HideVenues: 'Hide Venues',
      NoRaces: 'No races currently available',
      NoBets: 'No bets placed on this runner',
      All: 'All',
      NA: 'N/A',
    },
    BetsForRunner: {
      Punter: 'Punter',
      Bet: 'Bet type',
      BetStatus: 'Bet Status',
      Odds: 'Odds',
      Stake: 'Stake',
      PotentialReturn: 'Potential Return',
      ActualReturn: 'Actual Return',
      BB: 'BB',
    },
    Header: 'Risk Manager',
    RouteName: {
      NextToJump: 'Next to Jump',
      ByRacing: 'Racing',
      BySport: 'Sport',
      HighestExposure: 'Highest Exposure',
      FindAnEvent: 'Find an Event',
      ManageCustomMarkets: 'Custom Markets',
      GlobalMargins: 'Manage Global Margins',
      MultiMonitor: 'Multi Monitor',
    },
    BySportTab: {
      SelectSport: 'Select Sport',
      SelectCompetition: 'Select Competition',
      SelectTournament: 'Select Tournament',
      Modals: {
        Headings: {
          Configure: 'Configure match',
          SuspendMarket: 'Suspend % market',
          SuspendProposition: 'Suspend % proposition',
        },
        Labels: {
          VoidProposition: 'Void this proposition. Hide from punter website.',
          VoidMarket:
            'Void this market and all related propositions. Hide from punter website.',
          VoidMatch:
            'Void all markets and hide from punter website (this is a permanent action and cannot be undone).',
          SuspendMarkets: 'Suspend all markets and hide from punter website',
          SuspendIndividualMarket:
            'Suspend this market and all related propositions. Hide from punter website',
          SuspendProposition:
            'Suspend this proposition and hide from punter website',
        },
      },
    },
    ByRacingTab: {
      Clear: 'Clear',
      SelectStatus: 'Statuses (All)',
      SelectVenues: 'Venues (All)',
      SelectCountry: 'Countries (All)',
      NumRacesRunning: 'races running',
      Modals: {
        Headings: {
          Configure: 'Configure race',
          Settle: 'Settle race',
        },
        Labels: {
          scratch: 'Use "s" in order to scratch a runner',
          void: 'Void all markets and hide from punter website.',
          suspendAll: 'Suspend all markets and hide from punter website',
        },
      },
    },
    FindAnEventTab: {
      SelectSport: 'Select Sport',
      EventName: 'Event Name',
    },
    ManageMarginGlobal: {
      MarginsOdds: 'Odds Margin %',
      TotesMargins: 'Totes Margins',
      RacingMargins: 'Racing Margins',
      SportsMargins: 'Sports Margins',
      TotesOdds: 'Totes Odds',
      ManuallyManage: 'Manually Manage',
      Common: {
        Code: 'Code',
        Track: 'Track',
        Competition: 'Competition',
      },
      RacingMarginsTab: {
        Title: 'Set a margin for racings odds',
        Subtitle:
          'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
        GlobalMargin: 'Global racing margin',
        AddExceptionCode: '+ Add an exception for a code',
        AddExceptionTrack: '+ Add an exception for a track',
        Margin: 'Margin',
        Remove: 'Remove',
      },
      SportMarginsTab: {
        Title: 'Set a margin for sports odds',
        Subtitle:
          'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
        GlobalMargin: 'Global sports margin',
        AddExceptionCode: '+ Add an exception for a code',
        AddExceptionComp: '+ Add an exception for a competition',
        Margin: 'Margin',
        Remove: 'Remove',
      },
      SportsMarginsTab: {
        Title: 'Set a margin for sports odds',
        Subtitle:
          'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
        GlobalMargin: 'Global sports margin',
        AddExceptionCode: '+ Add an exception for a code',
        AddExceptionTrack: '+ Add an exception for a track',
        Margin: 'Margin',
        Remove: 'Remove',
      },
      TotesMarginsTab: {
        Title: 'Set a margin for totes odds',
        Subtitle:
          'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
        GlobalMargin: 'Global margin',
        AddExceptionCode: '+ Add an exception for a code',
        AddExceptionTrack: '+ Add an exception for a track',
        Margin: 'Margin',
        Remove: 'Remove',
      },
      TotesOddsTab: {
        Title: 'Set a margin for totes odds',
        Subtitle:
          'This effects your margin on any odds automatically compared to the margins offered by the Winter price feed on any market.',
        GlobalMargin: 'Global totes margin',
        AddExceptionCode: '+ Add an exception for a code',
        AddExceptionTrack: '+ Add an exception for a track',
        Margin: 'Margin',
        Remove: 'Remove',
      },
    },
    MatchDetailsText: {
      FallbackTitle: 'Match Details',
      Bets: 'BETS',
      Value: 'VALUE',
      TotalWinBets: 'TOTAL WIN BETS',
      Hold: 'HOLD',
      BiggestBet: 'BIGGEST BET',
      BonusBets: 'BONUS BETS',
      AveragePrice: 'AVG. PRICE',
      Exposure: 'EXPOSURE',
      Result: 'RESULT',
      Stake: 'STAKE',
      Odds: 'ODDS',
      PotentialReturn: 'POTENTIAL RETURN',
      Bonus: 'BONUS',
      Punter: 'PUNTER',
      Status: 'STATUS',
      ViewIndividualBets: 'View Individual Bets',
      Current: 'Current',
      US: 'US',
      View: 'VIEW',
      Open: 'Open',
      Update: 'Update',
      SelectSport: 'Select Sport',
      Modals: {
        Buttons: {
          Confirm: 'Confirm and update',
        },
      },
    },
    MultiMonitor: {
      ColumnTitles: {
        Punter: 'Punter',
        BetDetails: 'Bet details',
        StartingStake: 'Starting Stake',
        RaceR: 'R',
        StartingOdds: 'Starting Odds',
        Legs: 'Legs',
        LegsWon: 'Legs Won',
        CurrentStakeValue: 'Current Stake Value',
        PotentialPayout: 'Potential Payout',
        NextLeg: 'Next leg',
      },
      SubColumnTitles: {
        Prop: 'Checkbox',
        Market: 'Market',
        MarketType: 'Market Type',
        RaceR: 'R',
        Proposition: 'Proposition',
        Odds: 'Odds',
        BetDetails: 'Bet details',
        LegTime: 'Leg Time',
        DateTime: 'Date & Time',
      },
      MultiBets: {
        Punter: 'Punter',
        Bet: 'Bet',
        BetStatus: 'Bet Status',
        Odds: 'Odds',
        Stake: 'Stake',
        Bonus: 'Bonus',
        PotentialPayout: 'Potential Return',
        legMulti: 'Leg Multi',
      },
    },
    SRMBets: {
      ColumnTitles: {
        SRMBets: 'SRM Bets',
        Bets: 'Bets',
        DuplicateBets: 'Duplicate Bets',
        Date: 'Date',
        Punter: 'Punter',
        BetStatus: 'Bet Status',
        Stake: 'Stake',
        Odds: 'Odds',
        Exposure: 'Exposure',
        Runner: 'Runner',
        NumberPunters: 'Number of Punters',
        Combination: 'Combination',
        AvgOdds: 'Average Odds',
      },
    },
  },
  BookiePage: {
    Header: 'Bookie Profile',
  },
  SettingsPage: {
    Header: 'Settings',
    RouteName: {
      Limits: 'Limits',
      BetfairIntegration: 'Betfair Integration',
      SystemSetup: 'System Setup',
      AdminAccounts: 'Admin Accounts',
      ActivityLog: 'Activity Log',
      YourProfile: 'Your Profile',
      ContactUs: 'Contact Us',
      Offerings: 'Offerings',
    },
    ActivityLogPage: {
      NoResults: 'No logs found...',
      ClearFilters: 'Clear Filters',
      Trader: 'Trader',
      Reason: 'Reason',
      Reasons: {
        [EVoidReason.customer_requested]: 'Customer requested',
        [EVoidReason.system_error]: 'System error',
        [EVoidReason.invalid_market_or_proposition]:
          'Invalid market or proposition',
        [EVoidReason.void_terms_conditions]: 'Void - T&C',
      },
    },
    ContactDetails: {
      title: 'Bet Contact Details',
      description:
        'These details will be publicly accessible to all punters. These settings can be managed by all Admins',
      phone: 'Phone',
      internationalPhone: 'International',
      email: 'Email',
      address: 'Address',
      streetNumber: 'Street Number',
      streetName: 'Street Name',
      suburb: 'Suburb',
      state: 'State',
      postCode: 'Postcode',
      save: 'Save Changes',
      toasts: {
        updated: 'Contact details updated',
        error: 'An unspecified error occurred. Please try again.',
      },
    },
    YourProfile: {
      YourProfile: {
        title: 'Your Profile',
        description: 'You can manage the admin accounts below.',
        firstname: 'First Name',
        lastname: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        licensed_state: 'Licensed State/Territory',
        license_id: 'Bookmaker License ID',
        savebutton: 'Save changes',
        toasts: {
          updated: 'Profile updated',
          error: 'An unspecified error occurred. Please try again.',
        },
      },
      ChangePassword: {
        title: 'Change Password',
        current: 'Current Password',
        new: 'Create new password',
        button: 'Save changes',
        errors: {
          unspecified: 'An unspecified error occurred. Please try again',
          incorrect: 'Current password incorrect. Try again',
          changed: 'Password changed',
        },
        validation: {
          match: 'Passwords must match',
          required: 'New password is required',
          rules: 'Password must meet password rules',
        },
      },
      Notifications: {
        title: 'Notifications',
        description:
          'You can manage which alerts you receive through to your email.',
      },
    },
    SystemSetupPage: {
      subRoutes: {
        bank: 'Bank',
        security: 'Security',
        consumer: 'Consumer',
      },
      Security: {
        promotionsHeading: 'Promotions',
        promotionsSubheading:
          'You can edit your Promotions here and they will show on the punter app for your users.',
        termsHeading: 'Your Terms & Conditions',
        termsSubheading:
          'You can edit your terms & conditions here and they will show on the punter app for your users.',
        termsPlaceholder: 'Paste your terms here...',
        privacyHeading: 'Your Privacy Policy',
        privacySubheading:
          'You can edit your privacy policy here and they will show on the punter app for your users.',
        privacyPlaceholder: 'Paste your privacy policy here...',
        saveButton: 'Save',
        postPromoSuccessMessage: 'Promotions successfuly updated',
        postPrivacySuccessMessage: 'Privacy Policy successfuly updated',
      },
      Bank: {
        heading: 'Bank Details',
        subheading: 'This is the account that your earnings will be paid into.',
        accountNameLabel: 'Account Name',
        bsbLabel: 'BSB',
        accountNumberLabel: 'Account Number',
        apcaLabel: 'APCA Number',
        bankCodeLabel: 'Bank Code (3 Characters)',
        saveButton: 'Save',
        errors: {
          accountNameRequired: 'Account Name is required',
          accountNameLength: 'Account Name must be less than 60 characters',
          bsbRequired: 'BSB is required',
          bsbFormat: 'BSB format must be XXX-XXX',
          accountNumberRequired: 'Account Number is required',
          accountNumberFormat:
            'Account Number must be more than 6 and less than 11 digits long',
          apcaRequired: 'APCA Number is required',
          bankCodeRequired: 'Bank Code is required',
          bankCodeFormat: 'Bank code must be 3 characters long',
        },
        postSuccessMessage: 'Bank details successfuly updated',
      },
    },
    Limits: {
      RejectLimits: {
        autoRejectInput: ' Reject Limit',
        manualApprovalInput: 'Manual Approval Limit',
        heading: 'Reject Limits',
        body: 'The bookie can set an auto reject or manual approval limit and have bets automatically rejected or require approval if they hit the limit. The bookie can set an auto-reject limit for each user category.',
        autoRejectRadio: 'Auto Reject',
        manualApprovalRadio: 'Manual Approval',
        updated: 'Reject Limits updated',
      },
      BetApprovalLimit: {
        heading: 'Automatic Bet Approval Limit',
        body: 'Any bet where the return is less than a set amount will be auto-approved and any bet where the return exceeds that set amount will requre manual approval.',
        globalBetLimitInputLabel: 'Bet Approval Limit',
        exposureLimitInputLabel: 'Exposure Limit',
        exoticLimitInputLabel: 'Exotic Limit',
        crossGameMultiInputLabel: 'Cross Game Multi Limit',
        updated: 'Automatic Bet Approval Limit updated',
      },
      ExposureLimits: {
        heading: 'Exposure',
        maxWinLabel: 'Max Win Exposure',
        maxPlaceLabel: 'Max Place Exposure',
        maxGlobalExposureLabel: 'Global Exposure',
        maxSportExposure: 'Max Sport Exposure',
        maxStakeExotics: 'Max Stake Exotics',
        maxExoticsPayout: 'Max Exotics Payout',
        updated: 'Exposure Limits updated',
        exposureDescription1:
          'These limits are intended to act as a safety net to avoid being overexposed on a single market, generally as a result of a pricing error. The expectation is that when the markets are being actively managed the limits be set high, and when they are unattended by traders they are set to more restrictive limits.',
        exposureDescription2p1: 'The limits consider all bets by ',
        exposureDescription2p2Bold: 'all punters on a single market.',
        exposureDescription2p3: ' Once the limit is reached ',
        exposureDescription2p4Bold:
          'any bets exceeding the MBL will be automatically sent to approval,',
        exposureDescription2p5:
          ' and will time out after the usual expiry period.',
        exposureDescription3:
          'The exposure limit is effectively the worst outcome for the book on the market. There are different limits for:',
        exposureDescriptionLi1: 'Win: racing win markets',
        exposureDescriptionLi2:
          'Place: racing place markets, using the N worst outcomes where N = number of places',
        exposureDescriptionLi3: 'Sports: sports markets',
      },
    },
  },
  PromoManagerPage: {
    Header: 'Promo Manager',
    ErrorMessages: {
      CSVHeaderNames:
        'File does not contain the right header names, please use: punter and amount as headers in your CSV file.',
      CSVParseError: 'File does not have the right format.',
    },
    RouteName: {
      allPromos: 'All Promos',
    },
    BulkBonusAwards: {
      TableHeadings: {
        name: 'Name',
        numOfPuntersCredited: 'No. of punters credited',
        totalAmountCredited: 'Total amount credited',
        date: 'Date',
      },
    },
    importCSV: 'Import CSV',
    Modals: {
      ImportCSV: {
        reason: 'Reason',
        name: 'Name',
        Reasons: {
          [EBonusAwardReason.deposit_match]: 'Deposit Match',
          [EBonusAwardReason.bonus_back_for_2nd]: 'Bonus Back for 2nd',
          [EBonusAwardReason.loyalty_bonus]: 'Loyalty Bonus',
        },
      },
    },
    History: {
      Filters: {
        dateFrom: 'Date From',
        dateTo: 'Date To',
        reset: 'Reset',
      },
    },
  },
  BanksPage: {
    Header: 'Bank',
    RouteNames: {
      withdrawals: 'Withdrawals',
      deposits: 'Deposits',
      bankAnalysisDashboard: 'Bank Analysis Dashboard',
    },
    Withdrawals: {
      ErrorMessages: {
        HistoryDownloadError: 'CSV Download Error',
      },
      FilterNames: {
        pendingApprovals: 'Pending Approval',
        readyToPay: 'Ready To Pay',
        history: 'History',
      },
      PendingApproval: {
        ApproveSelected: 'Approve Selected',
        RejectSelected: 'Reject Selected',
        ExportAsCSV: 'Export as CSV',
        WithRecordsSelected: 'With records selected',
        TableHeadings: {
          TransactionType: 'Transaction Type',
          PunterName: 'Punter Name',
          Requested: 'Requested',
          WithdrawalAmount: 'Withdrawal Amount',
          Result: 'Result',
        },
        NoRecords: 'No Withdrawal Requests Pending Approval',
      },
      ReadyToPay: {
        TableHeadings: {
          TransactionType: 'Transaction Type',
          PunterName: 'Punter Name',
          Requested: 'Requested',
          By: 'By',
          NewOrExported: 'New/Exported',
          WithdrawalAmount: 'Amount',
          Status: 'Status',
          TransferStatus: 'Transfer Status',
        },
        ExportAsABA: 'Export as ABA',
        NoRecords: 'No Withdrawal Requests Ready To Pay',
        MarkSuccessful: 'Mark Successful',
        MarkUnsuccessful: 'Mark Unsuccessful',
        Successful: 'Successful',
        Unsuccessful: 'Unsuccessful',
      },
      WithdrawalHistory: {
        TableHeadings: {
          TransactionType: 'Transaction Type',
          PunterName: 'Punter Name',
          Requested: 'Requested',
          By: 'By',
          Completed: 'Completed',
          Method: 'Method',
          Result: 'Result',
          WithdrawalAmount: 'Amount',
          Status: 'Status',
        },
        ApprovedToday: 'Approved Today',
        CancelledToday: 'Cancelled Today',
        RejectedToday: 'Rejected Today',
        ExportAsCSV: 'Export as CSV',
        NoRecords: 'No Withdrawal Request History',
      },
    },
    Deposits: {
      FilterNames: {
        filter: 'testFilter',
      },
    },
    BankAnalysisDashboard: {
      FilterNames: {
        filter: 'testFilter',
      },
    },
  },
  BetTablePopover: {
    Void: 'Cancel or Void bet',
    SettleBet: 'Manually settle bet',
  },
  VoidBetModal: {
    Title: 'Cancel or Void',
    TitleOn: 'stake on',
    Message: 'Please select a reason from the options below',
    Button: 'Confirm and update',
    Success: 'Bet cancelled/voided',
    ReasonPlaceholder: 'Select a reason',
    Notes: 'Additional Comments - Optional',
    Reasons: {
      [EVoidReason.customer_requested]: 'Customer requested',
      [EVoidReason.system_error]: 'System error',
      [EVoidReason.invalid_market_or_proposition]:
        'Invalid market or proposition',
      [EVoidReason.void_terms_conditions]: 'Void - t&C',
    },
  },
  PlaceBetsPage: {
    Header: 'Placed Bets',
    NoResults: 'No records found',
    RouteName: {
      Upcoming: 'Upcoming',
      Resulted: 'Resulted',
      BetsDashboard: 'Bets Dashboard',
      Flagged: 'Flagged',
    },
    Common: {
      Code: 'Code',
      Result: 'Result',
      PunterSearch: 'Search by punter name',
      EventType: 'Event Type',
      Currency: 'Currency',
    },
    Table: {
      EventMarketPunter: 'Event/Market',
      Date: 'Date',
      Punter: 'Punter',
      Stake: 'Stake',
      BonusStake: 'Bonus Stake',
      Status: 'Bet Status',
      Odds: 'Odds',
      Result: 'Result',
      PunterType: 'Status',
      Return: 'Return',
      ProfitAndLoss: 'P/L',
      Exposure: 'Exposure',
      MBL: 'MBL',
      BB: 'Bonus',
      Legs: 'Legs ',
      NA: 'N/A',
      PotentialReturns: 'Potential Returns',
    },
    UpcomingTab: {
      StartStatus: 'Start Status',
      PunterStatus: 'Punter Status',
    },
  },
  BetApprovalsPage: {
    Header: 'Bet Approvals',
    RouteName: {
      Approvals: 'Approvals',
      History: 'History',
    },
    Toast: {
      Approved: 'Bet approved',
      Countered: 'Bet countered',
      Rejected: 'Bet rejected',
      MblAccepted: 'MBL accepted',
      YouApproved: `You've approved % bet`,
    },
    History: {
      Filters: {
        Reset: 'Reset all',
        SearchPunter: 'Search for a punter name',
        DateFrom: 'Date From',
        DateTo: 'Date To',
        Approver: 'Approver',
      },
    },
    Modals: {
      CounterOffer: {
        Title: 'Send a counter offer',
        Prompt: 'Enter Counter Offer',
        Button: 'Send Counter Offer',
      },
      Reject: {
        Label: 'Reason',
        OptionalLabel: 'Optional',
        Button: 'Confirm and reject bet',
        Title: 'Reject {Name} {Stake} stake on {Event}',
        Placeholder: 'Select a reason',
        Reasons: {
          [EBetRequestRejectReasons.high_risk_market]: 'High risk - market',
          [EBetRequestRejectReasons.high_risk_punter]: 'High risk - punter',
          [EBetRequestRejectReasons.fraud]: 'Fraud',
          [EBetRequestRejectReasons.t_and_c]: 'T&C',
        },
      },
    },
    Table: {
      Market: 'Market',
      Punter: 'Punter',
      BetStatus: 'Bet Status',
      RequestedOdds: 'Odds',
      CurrentOdds: 'Current Odds',
      RequestedStake: 'Stake',
      AcceptedStake: 'Accepted Stake',
      Return: 'Return',
      Result: 'Result',
      Exposure: 'Exposure',
      Approvals: 'Approvals',
      ApprovedDate: 'Date Approved',
      ApprovedDateBy: 'Action Date / By',
      ApprovedBy: 'By',
      EmptyPending: 'There are currently no pending approvals.',
      EmptyHistory: 'There are currently no historical approvals.',
      PunterCategory: 'Punter Category',
      DatePlaced: 'Date Placed',
      Bonus: 'Bonus',
      Mbl: 'MBL: ',
      LegMulti: 'Leg Multi',
      Limit: 'Limit: ',
      Placed: 'Placed: ',
      PotentialAndActual: 'Potential/actual returns',
    },
    Approvals: {
      Reject: 'Reject',
      Counter: 'Counter',
      Approve: 'Approve',
      Mbl: 'MBL',
    },
  },
  DashboardPage: {
    Header: 'Dashboard',
  },
  PasswordStrength: {
    casemix: 'Includes lower and uppercase ',
    number: 'Includes at least 1 number',
    length: 'Password is more than 8 characters in length',
    match: 'Passwords must match',
  },
  FormValidation: {
    RequiredField: 'Required field. Please complete.',
    MustBeNumber: 'Must be a number',
    MinimumStake: 'Counter stake can not be or lower than 0',
    HigherStake: 'Counter stake can not be higher then current stake.',
    MinimumAmount: 'Amount has to be more than 0',
    MaxAmount: 'Limit Exceeded',
    FileRequired: 'File required.',
    maxLengthError: 'You have reached your maximum limit of characters allowed',
  },
  Navigations: {
    Dashboard: 'Dashboard',
    BetApprovals: 'Bet Approvals',
    PlacedBets: 'Placed Bets',
    Bank: 'Bank',
    TradeManager: 'Risk Manager',
    PromoManager: 'Promo Manager',
    Punters: 'Punters',
    Offerings: 'Offerings',
    Settings: 'Settings',
  },
};

export default STRINGS;
