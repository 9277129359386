import React, { ReactElement } from 'react';
import { ECustomMarketModalMode } from '@/features/tradeManager/pages/TradeManager/types';
import { FormErrorMessage } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { TCustomMarketsFormValues } from '../../../pages/TradeManager/tabs/CustomMarkets/Custommarket.types';
import { useCustomMarketFormHOC } from '../services/CustomMarketModal.hooks';
import { useValidation } from '@/features/tradeManager/pages/TradeManager/tabs/CustomMarkets/services/CustomMarkets.hooks';

type TFormikHOC = {
  children: ReactElement;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const { initialValues, onSubmit, onUpdate, modalMode } =
    useCustomMarketFormHOC();

  const { customMarketsSchema } = useValidation();

  if (!initialValues)
    return (
      <FormErrorMessage>
        <FormattedMessage id="generic.standarderrormessage" />
      </FormErrorMessage>
    );

  return (
    <Formik<TCustomMarketsFormValues>
      initialValues={initialValues}
      validationSchema={customMarketsSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);

        if (modalMode === ECustomMarketModalMode.Create) {
          await onSubmit(values);
        }
        if (modalMode === ECustomMarketModalMode.Edit) {
          await onUpdate(values);
        }

        setSubmitting(false);
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
