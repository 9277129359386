import { CenterProps } from '@chakra-ui/react';

import React from 'react';
import NextPageButton from './components/NextPageButton';
import PreviousPageButton from './components/PreviousPageButton';
import { CenterPagination } from './Pagination.styles';

interface PaginationProps extends CenterProps {
  onNext(): void;
  onPrev(): void;
  nextDisabled: boolean;
  previousDisabled: boolean;
  hidePagination: boolean;
}

export const PaginationV2 = ({
  onNext,
  onPrev,
  nextDisabled,
  previousDisabled,
  hidePagination,
  ...rest
}: PaginationProps) => {
  if (hidePagination) return null;

  return (
    <CenterPagination {...rest}>
      <PreviousPageButton isDisabled={previousDisabled} onClick={onPrev} />
      <NextPageButton isDisabled={nextDisabled} onClick={onNext} />
    </CenterPagination>
  );
};
