import React from 'react';
import { Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BookInformation } from '@styled-icons/fluentui-system-filled/BookInformation';

import { Path, useLocation } from 'react-router-dom';
import { centsToDollars, getStrings } from '../../../../../../common/utils';
import { enumToLocalisationsDropdown } from '../../../../../../lib/Localisations';
import { EPunterProfileOptions } from '../../../../../punters/pages/Punter/tabs/Profile/types';
import { UserBadge, PlayerIcon } from '../Services/BetApprovals.styles';
import {
  CommonResponse,
  normalizeBetApproval,
  usePunterCategory,
} from '../Services/BetApprovals.utils';
import { EPlayerType } from '../../../../../../lib/DBModels';
import { TRequestBet } from '../Services/BetApprovals.types';
import { isWincore } from '../Approvals';
import toast from 'react-hot-toast';

/**
 * This component handles the existing bet approvals cell component.
 * It transforms the data to the common object.
 */
type PunterWrapperProps = {
  bet: TRequestBet;
};

export const PunterWrapper = ({ bet }: PunterWrapperProps) => {
  return <Punter bet={normalizeBetApproval(bet)} />;
};

/**
 *
 */

type PunterProps = {
  bet: CommonResponse;
};

export const Punter = ({ bet }: PunterProps) => {
  const location = useLocation();
  dayjs.extend(relativeTime);
  const [
    {
      BetApprovalsPage: { Table: Strings },
      PunterPage: PunterStrings,
    },
  ] = getStrings();

  const option = enumToLocalisationsDropdown(
    Object.values(EPunterProfileOptions),
    PunterStrings.Header.categoryOptions
  ).find((o) => o.value === bet?.punterCategoryAtPlacement);

  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory(
    bet?.punterCategoryAtPlacement ?? EPlayerType.Newbie
  );

  const isPlacement = bet?.requestType?.toLowerCase() === 'placement';

  return (
    <Flex direction="column" alignItems="start" justifyItems="start">
      <Flex mb="3" alignItems="center" justifyContent="space-between">
        <UserBadge
          to={
            isWincore && bet.sourcePortalUrl
              ? `${bet.sourcePortalUrl}/punter/${bet.punterId}`
              : ({
                  pathname: `/punter/${bet?.punterId ?? ''}`,
                  state: { from: location },
                } as unknown as Path)
          }
          rel="noopener noreferrer"
          target="_blank"
          outlineColor={getPunterCategoryColor()}
          bg={getPunterCategoryColor()}
        >
          {bet?.punterName?.substring(0, 1)}
        </UserBadge>

        <Flex direction="column">
          <Text fontSize="lg" color="gray.600" fontWeight="semibold">
            {bet?.punterName ?? ''}
          </Text>
          <Flex alignItems="flex-end">
            <PlayerIcon src={getPlayerIcon()} bg={getPunterCategoryColor()} />
            <Text color="gray.700" fontSize="sm">
              {option?.label ?? bet?.punterCategory}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {isPlacement && (
        <Flex direction="row" fontSize="sm">
          <Tooltip
            hasArrow
            label={bet.punterCategoryLimitName?.replaceAll('_', ' ')}
            textTransform="capitalize"
          >
            <Icon as={BookInformation} boxSize="5" mr="1" color="gray.500" />
          </Tooltip>
          <Text>{Strings.Limit}</Text>
          <Text fontWeight="medium" ml="1">
            {centsToDollars(bet?.punterCategoryLimit ?? 0)}
          </Text>
        </Flex>
      )}

      <Flex direction="row">
        <Text>{isPlacement ? Strings.Placed : 'Settled:'}</Text>
        <Text fontWeight="medium" ml="1">
          {dayjs(bet.createdAt).fromNow()}
        </Text>
      </Flex>
      {isWincore && bet.sourcePortalUrl && (
        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              `${bet.sourcePortalUrl}/punter/${bet.punterId}`
            );
            toast.success('Copied to clipboard');
          }}
        >
          Copy profile link
        </Button>
      )}
    </Flex>
  );
};
