import React, { useCallback } from 'react';
import { Text, Flex, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryPunterSearch } from '../../../../../api/punters/search/search.hooks';
import { TPunterSearch } from './Punters.types';
import { usePunterCategory } from './Punters.utils';
import {
  UserBadge,
  BoxFlagWrapper,
  BoxPunterFlag,
  PlayerIcon,
} from '../styles/Punters.styles';
import { EPlayerType } from '@/lib/DBModels';
import { centsToDollars, dateFormatter } from '@/common/utils';
import { useNavigate } from 'react-router-dom';
import { defaultColumns } from './Punters.config';

export const usePunters = () => {
  const navigate = useNavigate();

  const { defaultFlags } = usePunterCategory();
  const [offset, setOffset] = useState<number>(0);
  const [results, setResults] = useState<number>(10);
  const [searchVal, setSearchVal] = useState<string>('');
  const [queryVal, setQueryVal] = useState<string>('');
  const [punterFlag, setPunterFlag] = useState<string>();
  const [sortBy, setSortBy] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<
    'asc' | 'desc' | undefined
  >();

  /* refetch if user is on the first page and no filter is set */
  const shouldRefetch = !queryVal && offset === 0;
  const flagsQuery = punterFlag?.replaceAll(' ', '') ?? undefined;

  const { data, isLoading } = useQueryPunterSearch({
    params: {
      query: queryVal,
      sort_direction: sortDirection,
      flag_filter: flagsQuery,
      sort_by: sortBy,
      offset,
      limit: results,
    },
    options: {
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute on first page only
    },
  });

  /**
   * I've tried to type this out correctly, however, there seems to be
   * a problem with Grommets types throwing errors.
   * As we are moving away from using this, I dont see it being a huge blocker.
   */
  const goToPunterProfile = useCallback(
    (punter: TPunterSearch) => {
      const punterBasicDetails = {
        punter_id: punter.punter_id,
      };
      const path = {
        pathname: `/punter/${punter.punter_id}`,
        search: `?id=${punter.punter_id}`,
        state: punterBasicDetails,
      };
      navigate(path);
    },
    [navigate]
  );

  const handleFlagChange = (value: string) => {
    setPunterFlag(
      value.replaceAll(' ', '') === 'AllPunters' ? undefined : value
    );
    setOffset(0);
  };

  const handleSearch = () => setQueryVal(searchVal);

  return {
    data,
    isLoading,
    isLastFetch: (data?.length ?? 0) < results,
    setOffset,
    offset,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchVal,
    setSearchVal,
    punterFlag,
    defaultFlags,
    goToPunterProfile,
    handleFlagChange,
    results,
    setResults,
    defaultColumns,
    handleSearch,
  };
};

export const usePuntersColumns = () => {
  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory();

  const columns = [
    {
      accessorKey: 'full_name',
      header: 'Name',
      cell: (props: { row: { original: TPunterSearch } }) => {
        const punter = props.row.original;

        return (
          <Flex sx={{ alignItems: 'center' }}>
            <Tooltip
              p="2"
              borderRadius="base"
              hasArrow
              bg={getPunterCategoryColor(
                punter.punter_type ?? EPlayerType.Newbie
              )}
              label={punter.punter_type ?? ''}
            >
              <UserBadge
                bg={getPunterCategoryColor(
                  punter.punter_type ?? EPlayerType.Newbie
                )}
              >
                <PlayerIcon
                  color="white"
                  src={getPlayerIcon(punter.punter_type ?? EPlayerType.Newbie)}
                />
              </UserBadge>
            </Tooltip>

            <Tooltip
              p="2"
              borderRadius="base"
              hasArrow
              bg={getPunterCategoryColor(
                punter.punter_type ?? EPlayerType.Newbie
              )}
              label={
                <>
                  {punter.punter_type ?? ''} ~ {punter?.state ?? ''}
                </>
              }
            >
              <Text sx={{ fontWeight: '500', fontSize: 'sm' }}>
                {punter?.full_name ?? ''}
              </Text>
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: (props: { getValue: () => string | undefined }) => (
        <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
          {props.getValue()}
        </Text>
      ),
    },
    {
      accessorKey: 'flags',
      header: <FormattedMessage id="punterspage.flags" />,
      cell: (props: { row: { original: TPunterSearch } }) => {
        const punter = props.row.original;

        if (!punter.flags) return;

        const MAX_TO_SHOW = 5;
        const visibleFlags = punter?.flags?.slice(0, MAX_TO_SHOW);

        return (
          <BoxFlagWrapper>
            {visibleFlags?.map((flag) => (
              <BoxPunterFlag key={flag?.label}>
                {flag?.label?.replace('_', ' ')}
              </BoxPunterFlag>
            ))}
          </BoxFlagWrapper>
        );
      },
    },
    {
      accessorKey: 'punter_balance',
      header: <FormattedMessage id="punterspage.balance" />,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
          {centsToDollars(props.getValue())}
        </Text>
      ),
    },
    {
      accessorKey: 'punter_numbets',
      header: <FormattedMessage id="punterspage.bets" />,
      cell: (props: { getValue: () => string | undefined }) => (
        <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
          {props.getValue()}
        </Text>
      ),
    },
    {
      accessorKey: 'punter_avgbet',
      header: <FormattedMessage id="punterspage.averagebet" />,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
          {centsToDollars(props.getValue())}
        </Text>
      ),
    },
    {
      accessorKey: 'punter_turnover',
      header: <FormattedMessage id="punterspage.turnover" />,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
          {centsToDollars(props.getValue())}
        </Text>
      ),
    },
    {
      accessorKey: 'punter_winnings',
      header: <FormattedMessage id="punterspage.winlost" />,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
          {centsToDollars(props.getValue())}
        </Text>
      ),
    },
    {
      accessorKey: 'punter_joined',
      header: <FormattedMessage id="punterspage.joined" />,
      cell: (props: { row: { original: TPunterSearch } }) => {
        const punter = props.row.original;
        return (
          <Tooltip
            p="2"
            borderRadius="base"
            hasArrow
            label={dateFormatter(punter.punter_joined, 'HH:mm A ~ DD MM YYYY')}
            bg={getPunterCategoryColor(
              punter.punter_type ?? EPlayerType.Newbie
            )}
          >
            <Text sx={{ fontSize: 'xs', letterSpacing: '1px' }}>
              {dateFormatter(punter.punter_joined, 'DD MMM YYYY')}
            </Text>
          </Tooltip>
        );
      },
    },
  ];
  return { columns };
};
