import {
  SkeletonProps,
  SkeletonText,
  TableBodyProps,
  Td,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

export type TSkeletonTableBodyProps = TableBodyProps &
  Pick<SkeletonProps, 'isLoaded'> & {
    noOfColumns?: number;
    noOfRows?: number;
  };

export default function SkeletonTableBody({
  children,
  isLoaded,
  noOfColumns = 5,
  noOfRows = 10,
}: TSkeletonTableBodyProps) {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <>
      {Array(noOfRows)
        .fill('')
        .map((_, index) => (
          <Tr key={index}>
            {Array(noOfColumns)
              .fill('')
              .map((_, index) => (
                <Td key={index}>
                  <SkeletonText noOfLines={1} />
                </Td>
              ))}
          </Tr>
        ))}
    </>
  );
}
