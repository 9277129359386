import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import { number, object, string } from 'yup';
import useCurrencySymbol from '@/hooks/useCurrencySymbol';
import useErrorHandler from '@/hooks/useErrorHandler';
import usePercentSign from '@/hooks/usePercentSign';
import { keys } from '@/api/api.keys';
import { useMutationAffiliatePromotion } from '@/api/promoManager/affiliates/affiliates.hooks';
import { TAffiliatePromo } from '@/api/promoManager/affiliates/affiliates.types';

export const CreateAffiliatePromo = ({
  selectedAffiliate,
  setSelectedAffiliateForNewPromo,
}: {
  selectedAffiliate: string | undefined;
  setSelectedAffiliateForNewPromo: (affiliate: string | undefined) => void;
}) => {
  const currencySymbol = useCurrencySymbol();
  const onError = useErrorHandler();
  const intl = useIntl();
  const percentSign = usePercentSign();
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutationAffiliatePromotion({
    onError,
    onSuccess: () => {
      toast({
        title: intl.formatMessage({
          id: 'punterPage.incentives.addDepositMatchSuccessTitle',
        }),
        status: 'success',
        position: 'top',
        duration: 9_000,
      });
      queryClient.invalidateQueries([keys.platformPromo]);
    },
    onSettled: () => {
      handleClose();
    },
  });

  const handleClose = () => {
    setSelectedAffiliateForNewPromo(undefined);
  };

  const handleSetPromoCodeValue = (val: string) => {
    const allowedCharactersPattern = /^[a-zA-Z0-9!@#$%^&*()_+=[\]{}|\\/?.~-]+$/;
    const sanitizedValue = val
      .toUpperCase()
      .split('')
      .filter((char) => allowedCharactersPattern.test(char))
      .join('');
    setFieldValue('promo_code', sanitizedValue);
  };

  const {
    errors,
    getFieldProps,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = useFormik<TAffiliatePromo>({
    initialValues: {
      id: '',
      name: undefined,
      reason: 'first_deposit_match',
      affiliate_code: selectedAffiliate ?? '',
      affiliate_token: undefined,
      promo_code: undefined,
      promo_type: 'first_deposit_match',
      expiry: undefined,
      percentage: undefined,
      max_amount: undefined,
    },
    validationSchema: object({
      affiliate_code: string().required('Affiliate Code is required'),
      affiliate_token: string().required('Affiliate Token is required'),
      promo_code: string().required('Promo Code is required'),
      percentage: number().required('Percentage is required').min(1).max(100),
      max_amount: number().required('Max Amount is required').min(1).integer(),
    }).required(),
    onSubmit: (values) => {
      const id = uuid();
      const parsedValues: TAffiliatePromo = {
        id,
        name: undefined,
        reason: undefined,
        affiliate_code: values.affiliate_code,
        affiliate_token: values.affiliate_token,
        promo_code: !!values.promo_code?.length ? values.promo_code : undefined,
        promo_type: 'first_deposit_match',
        expiry: values.expiry,
        percentage: values.percentage ? values.percentage / 100 : undefined,
        max_amount: values.max_amount ? values.max_amount * 100 : undefined,
      };
      try {
        mutation.mutate(parsedValues);
      } finally {
        resetForm();
      }
    },
  });

  return (
    <Modal isOpen={!!selectedAffiliate} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Add a First Deposit Match with Promo Code</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              resetForm();
              handleClose();
            }}
          />
          <Stack as={ModalBody} spacing={4}>
            <FormControl>
              <FormLabel>
                <FormattedMessage id="promomanagerpage.namelabel" />
              </FormLabel>
              <Input
                {...getFieldProps('affiliate_code')}
                value={values.affiliate_code}
                disabled={true}
              />
              <FormErrorMessage>{errors.affiliate_code}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Affiliate Token</FormLabel>
              <Input
                {...getFieldProps('affiliate_token')}
                value={values.affiliate_token}
              />
              <FormErrorMessage>{errors.affiliate_token}</FormErrorMessage>
            </FormControl>
            <ModalHeader display="flex" p={0}>
              <Text flex={1} fontSize="md">
                <FormattedMessage id="punterPage.incentives.addDepositMatch" />
              </Text>
            </ModalHeader>
            <FormControl isInvalid={touched.promo_code && !!errors.promo_code}>
              <FormLabel>
                <FormattedMessage id="promomanagerpage.affiliates.promoCodeLabel" />
              </FormLabel>
              <Input
                {...getFieldProps('promo_code')}
                onChange={(event) =>
                  handleSetPromoCodeValue(event.target.value)
                }
              />
              <FormErrorMessage>{errors.promo_code}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={touched.percentage && !!errors.percentage}>
              <FormLabel>
                <FormattedMessage
                  id="punterPage.incentives.percentageLabel"
                  values={{ percentSign }}
                />
              </FormLabel>
              <NumberInput
                allowMouseWheel
                min={1}
                max={100}
                value={values.percentage}
                onBlur={() => setFieldTouched('percentage')}
                onChange={(value) => setFieldValue('percentage', value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>{errors.percentage}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={touched.max_amount && !!errors.max_amount}>
              <FormLabel>
                <FormattedMessage
                  id="punterPage.incentives.maxAmountLabel"
                  values={{ currencySymbol }}
                />
              </FormLabel>
              <NumberInput
                allowMouseWheel
                min={1}
                value={values.max_amount}
                onBlur={() => setFieldTouched('max_amount')}
                onChange={(value) => setFieldValue('max_amount', value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>{errors?.max_amount}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={touched.expiry && !!errors.expiry}>
              <FormLabel>
                <FormattedMessage id="punterPage.incentives.expiryLabel" />
              </FormLabel>
              <DateTimePicker
                disableClock
                minDate={new Date()}
                name="expiry"
                value={values.expiry ? new Date(values.expiry) : undefined}
                wrapperProps={{ h: '10' }}
                onCalendarClose={() => handleBlur('expiry')}
                onCalendarOpen={() => setFieldTouched('expiry')}
                onChange={(value) =>
                  setFieldValue(
                    'expiry',
                    value === null
                      ? undefined
                      : dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                  )
                }
              />
              <FormErrorMessage>{errors.expiry}</FormErrorMessage>
            </FormControl>
          </Stack>
          <ModalFooter>
            <Button
              variant="ghost"
              mr="3"
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              <FormattedMessage id="punterPage.incentives.cancelButton" />
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              <FormattedMessage id="punterPage.incentives.confirmButton" />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
