import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNavLink } from '../../common/components/TabNavLink/TabNavLink';
import { getStrings } from '../../common/utils';

export default function BetApprovalsWrapper() {
  const [{ BetApprovalsPage }] = getStrings();

  return (
    <>
      <Flex direction="row">
        <TabNavLink to="/bet-approvals/approvals">
          {BetApprovalsPage.RouteName.Approvals}
        </TabNavLink>
        <TabNavLink to="/bet-approvals/history">
          {BetApprovalsPage.RouteName.History}
        </TabNavLink>
      </Flex>

      <Outlet />
    </>
  );
}
