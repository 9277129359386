import api from '../../../api';
import { TVoidMarketBody } from './voidMarket.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const voidMarketURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/void-market`;

// Post: Void Market
export const mutateVoidMarket = (body: TVoidMarketBody) =>
  api.post<TVoidMarketBody>(voidMarketURL, body).then((res) => res.data);
