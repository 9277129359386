import api from '../../../api';
import {
  TIncentivesBonusBetsParams,
  TIncentivesBonusBets,
} from './bonusBets.types';

export const incentivesBonusBetsURL = '/bookie/punters/incentives/bonus-bets';

// Incentives - Received
export const queryIncentivesBonusBets = (params: TIncentivesBonusBetsParams) =>
  api
    .get<TIncentivesBonusBets[]>(incentivesBonusBetsURL, { params })
    .then((res) => res.data);
