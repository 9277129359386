import React, { FC, ReactNode } from 'react';

import { srcBetcloudLogo } from '../../assets/logos';

import { Container, MainContent, ChildContentWrapper, Logo } from './Styles';

export const ErrorLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <Container>
    <MainContent>
      <Logo>
        <img src={srcBetcloudLogo} alt="Betcloud" />
      </Logo>
      <ChildContentWrapper>{children}</ChildContentWrapper>
    </MainContent>
  </Container>
);
