import { rest } from 'msw';
import { baseURL } from '@/__mocks__/utils';
import { tokenHistoryEndpoint } from './tokenHistory';
import { makeData } from './tokenHistory.mocks';

export const tokenHistoryHandlers = [
  rest.get(baseURL(tokenHistoryEndpoint), (req, res, ctx) => {
    const { searchParams } = req.url;
    const limit = Number(searchParams.get('limit'));

    return res(
      ctx.status(200),
      ctx.json(
        makeData({ punter_id: 'fake-uuid', token_type: 'spin-wheel', limit })
      )
    );
  }),
];
