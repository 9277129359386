import { useMutationProtestOverride } from '@/api/tradeManager/raceDetails/fptpProtestOverride/fptpProtestOverride.hooks';
import { useMutationSettleRace } from '@/api/tradeManager/raceDetails/settleRace/settleRace.hooks';
import { Box } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { keys } from '../../../../../../../../api/api.keys';
import { useAppDispatch } from '../../../../../../../../common/hooks/useRedux';
import { errorMessageFromHTTPException } from '../../../../../../../../lib/Error';
import { setIsConfigureRaceModalOpen } from '../../../../Services/RaceDetails.slices';
import { useFormData, useSettle } from '../services/Hooks.Settle';
import { parseFormData } from '../services/Utils.Settle';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const dispatch = useAppDispatch();
  const client = useQueryClient();
  const { data, raceId, refetch } = useSettle();
  const { initialValues, validation } = useFormData();

  const { mutateAsync: mutateSettleRace } = useMutationSettleRace();

  const { mutateAsync: mutateProtestOverride } = useMutationProtestOverride();

  if (!initialValues || !validation) return null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);

          const formData = parseFormData(
            { ...values, protest_override: '' },
            data
          );

          if (formData) {
            await mutateSettleRace({
              race_id: raceId ?? '',
              ...formData,
            });
          }

          await mutateProtestOverride({
            race_id: raceId ?? '',
            override: values.protest_override,
          });

          client
            .invalidateQueries(keys.pendingApprovals)
            .catch(() => undefined);

          await refetch();

          dispatch(setIsConfigureRaceModalOpen(false));
        } catch (error) {
          toast.error(errorMessageFromHTTPException(error));
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Box
        as={Form}
        sx={{ display: 'flex', flexDir: 'column', flex: 1, overflow: 'auto' }}
      >
        {children}
      </Box>
    </Formik>
  );
}
