import SelectAdvanced, {
  TSelectAdvancedProps,
  TOptionType,
} from '@/common/components/FormElements/SelectAdvanced/SelectAdvanced';
import {
  chakra,
  ChakraComponent,
  WithCSSVar,
  Flex,
  FlexProps,
  FormLabelProps,
  TextProps,
  Text,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import { StylesConfig } from 'react-select';
import InputFormElement, {
  TIndex,
} from '@/common/components/FormElements/Input';
import { GroupBase } from 'react-select';
import styled from 'styled-components';

export const ButtonUpdate: ChakraComponent<'button', ButtonProps> = chakra(
  Button,
  {
    baseStyle: () => ({
      mt: '4',
      py: '5',
    }),
  }
);

export const ButtonAddMore: ChakraComponent<'button', ButtonProps> = chakra(
  Button,
  {
    baseStyle: () => ({
      mt: '4',
      w: '20',
      ml: '3',
      mb: '2',
    }),
  }
);

export const IconButtonDeleteProp: ChakraComponent<'button', IconButtonProps> =
  chakra(IconButton, {
    baseStyle: () => ({
      position: 'absolute',
      zIndex: '10',
      top: '-2',
      left: '-3',
      borderRadius: 'full',
      color: 'red.500',
      bg: 'white',
      p: '0',
    }),
  });

export const TextLabel: ChakraComponent<'p', TextProps> = chakra(Text, {
  baseStyle: () => ({
    ml: '1',
    mb: '2',
    mt: '4',
    fontWeight: 'medium',
    fontSize: 'sm',
  }),
});

export const Input: ChakraComponent<'input', TIndex> = chakra(
  InputFormElement,
  {
    baseStyle: () => ({
      fontSize: 'sm',
      ml: '1',
    }),
  }
);

export const Select: ChakraComponent<'select', TSelectAdvancedProps> = chakra(
  SelectAdvanced,
  {
    baseStyle: () => ({
      m: '1',
      minW: '100%',
      textAlign: 'left',
      fontSize: 'sm',
    }),
  }
);

export const FlexForm: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    p: '8',
    flexDir: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    h: '75vh',
  }),
});

export const LabelStyle: FormLabelProps = {
  ml: '1',
  mb: '2',
  mt: '4',
  fontWeight: 'medium',
  fontSize: 'sm',
  textTransform: 'none',
  color: 'gray.700',
};

export const ErrorMsgContainer = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: red;
`;

export const selectStyles = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: WithCSSVar<Record<any, any>>
): StylesConfig<TOptionType, false, GroupBase<TOptionType>> => ({
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: theme.space[3],
  }),
  placeholder: (provided) => ({ ...provided, color: theme.colors.gray[500] }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    color: 'black',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: theme.colors.brand[500],
      color: 'white',
    },
    ':active': {
      backgroundColor: theme.colors.brand[300],
    },
  }),
  control: (provided, state) => ({
    ...provided,
    '&:hover': state.isFocused
      ? {
          borderColor: theme.colors.brand[500],
        }
      : provided['&:hover'],

    boxShadow: state.isFocused
      ? `0 0 0 1px ${theme.colors.brand[500]}`
      : provided.boxShadow,
    borderColor: state.isFocused
      ? theme.colors.brand[500]
      : theme.colors.gray[200],

    height: theme.sizes[10],
    borderRadius: theme.radii.lg,
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? theme.colors.gray[600] : theme.colors.gray[500],
  }),
});
