import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  setWithdrawalRequests,
  setWithdrawalRequestsHistory,
  updateExistingWithdrawalRequests,
} from './slices';

import {
  apiGetRequest,
  apiPatchRequest,
  apiPostRequest,
} from '../../../../lib/api/api';
import { AppThunkDispatch } from '../../../../app/types';
import {
  TPunterWithdrawalRequestsPagedHistoryDTO,
  TPunterWithdrawalRequestsResponse,
} from '../../../../lib/DTO';
import {
  TPunterWithdrawalRequestsInput,
  TPunterWithdrawalStatus,
  TWithdrawalActionInput,
  TWithdrawalsDownloadInput,
} from './types';
import { getStrings } from '../../../../common/utils';

const [
  {
    BanksPage: {
      Withdrawals: { ErrorMessages },
    },
  },
] = getStrings();

/**
 * Gets the withdrawals for the logged in bookie platform.
 */
export const getWithdrawalRequests = createAsyncThunk<
  TPunterWithdrawalRequestsResponse,
  TPunterWithdrawalRequestsInput,
  { dispatch: AppThunkDispatch }
>('bank/getWithdrawalRequests', async (inputData, thunkAPI) => {
  const withdrawalRequests =
    await apiGetRequest<TPunterWithdrawalRequestsResponse>(
      '/bookie/bank/withdrawals/status',
      {
        limit: inputData?.limit,
        offset: inputData?.offset,
        status: inputData?.status,
      }
    );

  // dispatch withdrawal requests to slice/store
  thunkAPI.dispatch(setWithdrawalRequests(withdrawalRequests));
  return withdrawalRequests;
});

/**
 * Gets the withdrawals request history for the logged in bookie platform.
 */
export const getWithdrawalRequestsHistory = createAsyncThunk<
  TPunterWithdrawalRequestsPagedHistoryDTO,
  TPunterWithdrawalRequestsInput,
  { dispatch: AppThunkDispatch }
>('bank/getWithdrawalRequestsHistory', async (inputData, thunkAPI) => {
  const withdrawalRequests =
    await apiGetRequest<TPunterWithdrawalRequestsPagedHistoryDTO>(
      '/bookie/bank/withdrawals/history',
      {
        limit: inputData?.limit,
        offset: inputData?.offset,
      }
    );

  // dispatch withdrawal requests to slice/store
  thunkAPI.dispatch(setWithdrawalRequestsHistory(withdrawalRequests));
  return withdrawalRequests;
});

/**
 * Gets the ABA File for withdrawal requests history for the logged in bookie platform.
 */
export const getWithdrawalRequestsHistoryDownload = createAsyncThunk<
  string,
  TWithdrawalsDownloadInput,
  { dispatch: AppThunkDispatch }
>(
  'bank/getWithdrawalRequestsHistoryDownload',
  async ({ withdrawal_ids, format, download_all_filter }, thunkAPI) => {
    const file = await apiPostRequest<string>(
      '/bookie/bank/withdrawals/download',
      {
        withdrawal_ids,
        format,
        download_all_filter,
      },
      { failMessage: ErrorMessages.HistoryDownloadError }
    );

    await thunkAPI.dispatch(
      getWithdrawalRequests({
        status: TPunterWithdrawalStatus.Approved,
      })
    );
    return file;
  }
);

/**
 * Actions the withdrawal(s) request for the logged in bookie platform.
 * (Either Approval & Denial)
 */
export const actionWithdrawalRequests = createAsyncThunk<
  TPunterWithdrawalRequestsResponse,
  TWithdrawalActionInput,
  { dispatch: AppThunkDispatch }
>('bank/actionWithdrawalRequest', async (inputData, thunkAPI) => {
  const updatedWithdrawals =
    await apiPatchRequest<TPunterWithdrawalRequestsResponse>(
      '/bookie/bank/withdrawals/status',
      inputData
    );

  // dispatch withdrawal requests to slice/store
  thunkAPI.dispatch(updateExistingWithdrawalRequests(updatedWithdrawals));
  return updatedWithdrawals;
});
