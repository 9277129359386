import React from 'react';
import Footer from './components/Footer';
import Form from './components/Form';
import FormikHOC from './components/FormikHOC';

export default function Settle() {
  return (
    <FormikHOC>
      <Form />
      <Footer />
    </FormikHOC>
  );
}
