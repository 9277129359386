import {
  Box,
  BoxProps,
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  HStack,
  StackProps,
} from '@chakra-ui/react';

export const BoxCollapse: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    w: '100%',
    bg: 'gray.50',
    mx: '3',
  }),
});

export const HStackSwitchGroup: ChakraComponent<'div', StackProps> = chakra(
  HStack,
  {
    baseStyle: () => ({
      p: '2',
      minW: '140px',
      h: '50px',
      bg: 'white',
      ml: '3',
      borderRadius: 'md',
    }),
  }
);

export const FlexWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    bg: 'gray.200',
  }),
});
