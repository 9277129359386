import React from 'react';
import { Box } from '@chakra-ui/react';
import { getStrings } from '../../utils';

export default function MBLBadge() {
  const [
    {
      PlaceBetsPage: { Table: Strings },
    },
  ] = getStrings();

  return (
    <Box
      sx={{
        textTransform: 'none',
        textAlign: 'center',
        borderRadius: 'lg',
        px: 2,
        fontSize: 'xs',
        color: 'white',
        bg: 'gray.500',
      }}
    >
      {Strings.MBL}
    </Box>
  );
}
