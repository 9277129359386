import React from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import {
  BonusBet,
  MBLFlag,
} from '../../EventMarketInfo/EventMarketInfo.styles';
import { GiftPack } from '@/assets/icons';
import IconSvg from '@/components/IconSvg/IconSvg';
import { centsToDollars, getIconAssetPath } from '@/common/utils';
import Countdown from '@/components/Countdown/Countdown';

interface IMarketInfoSingleRacingProps {
  status: string;
  venueName: string;
  timeStamp: string;
  raceNumber: number;
  mbl: number;
  isBonusBet: boolean;
  icon: string;
  marketName: string;
  runnerName: string;
  runnerNumber: number;
  toteBadge: string;
}

export function MarketInfoSingleRacing({
  venueName,
  timeStamp,
  raceNumber,
  mbl,
  isBonusBet,
  icon,
  marketName,
  runnerName,
  runnerNumber,
  toteBadge,
}: IMarketInfoSingleRacingProps) {
  return (
    <Box>
      <HStack>
        <Text fontWeight="bold">
          {venueName} - R{raceNumber}
        </Text>
        <Text backgroundColor="gray.100" fontSize="xs" px="2" fontWeight="bold">
          {toteBadge}
        </Text>
      </HStack>
      <Box
        sx={{
          '& > div': {
            justifyContent: 'left',
          },
        }}
      >
        <Countdown eventTime={timeStamp} />
      </Box>
      {mbl > 0 && (
        <Box width="fit-content">
          <MBLFlag>MBL: {centsToDollars(mbl)}</MBLFlag>{' '}
        </Box>
      )}
      {isBonusBet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>Bonus</Text>
        </BonusBet>
      )}
      <Flex>
        <IconSvg
          name={getIconAssetPath('sports', icon)}
          sx={{
            mr: '3',
          }}
        />
        <Text>{`${marketName} - ${runnerNumber}. ${runnerName}`}</Text>
      </Flex>
    </Box>
  );
}
