import React, { useState } from 'react';
import { BetsTable } from '@/common/components';
import { useRaceDetailsEvenShot } from '../hooks/EvenShot.hooks';
import { Column } from '@tanstack/react-table';
import { useEvenShotBetsColumns } from '../config/EvenShotAllBets.config';
import { EvenShotTable } from '../EvenShotTable';

type RunnerStats = Api['EvenShotRaceRunnerStats'];

export default function EvenShotAllBetsTab() {
  const { data, isLoading } = useRaceDetailsEvenShot();
  const { columns } = useEvenShotBetsColumns();
  const [runnerId, setRunnerId] = useState<string>();

  const { runners } = data || {
    runners: [],
  };

  return (
    <BetsTable
      hasSubTable
      columns={columns as unknown as Column<RunnerStats>[]}
      data={runners || ([] as RunnerStats[])}
      loading={isLoading}
      initialSort={[{ id: 'number', desc: true }]}
      onClickExpand={(row: RunnerStats) => {
        setRunnerId(row.race_runner_id);
      }}
    >
      {runnerId ? <EvenShotTable runnerId={runnerId} /> : 'No bets found'}
    </BetsTable>
  );
}
