import * as yup from 'yup';

import { getStrings } from '../../../common/utils';

const [{ ForgotPassword }] = getStrings();

const forgotPasswordSchema = yup
  .object({
    email: yup
      .string()
      .trim()
      .email(ForgotPassword.Errors.Invalid.Email)
      .required(ForgotPassword.Errors.Required.Email),
  })
  .required();

export type ForgotPasswordSchema = yup.InferType<typeof forgotPasswordSchema>;

export default forgotPasswordSchema;
