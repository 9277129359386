import { rest } from 'msw';
import { baseURL } from '@/__mocks__/utils';
import { promoSummaryEndpoint } from './promoSummary';
import { makeData } from './promoSummary.mocks';

export const promoSummaryHandlers = [
  rest.get(baseURL(promoSummaryEndpoint), (req, res, ctx) => {
    const { searchParams } = req.url;
    const limit = Number(searchParams.get('limit'));

    return res(
      ctx.status(200),
      ctx.json(makeData({ promo_type: 'deposit_match', limit }))
    );
  }),
];
