import React from 'react';
import dayjs from 'dayjs';
import { FormikContextType, useField } from 'formik';
import { Icon } from '@chakra-ui/react';
import { ChevronDown } from '@styled-icons/fa-solid/ChevronDown';
import Datepicker from '../../../../../../../../../../../common/components/FormElements/Datepicker/Datepicker';
import Timepicker from '../../../../../../../../../../../common/components/FormElements/Timepicker/Timepicker';
import { TRaceDetailsFormValues } from '../../RaceDetailsTable.types';
import {
  FlexFormWrapper,
  FlexInputsWrapper,
  InputDeduction,
  TextUpdatedBy,
} from './ScratchFormFields.styles';
import { FormattedMessage } from 'react-intl';
import { TRunnerBody } from '@/api/tradeManager/raceDetails/runnerStatus/runnerStatus.types';
import { CalendarProps } from 'react-calendar';

type TScratchFormFields = {
  formik: FormikContextType<TRaceDetailsFormValues>;
  apiModel: Record<string, TRunnerBody>;
  runnerId: string;
  number: number;
  updatedBy?: string;
};

const convertTo24HourFormat = (time: string) => {
  const parsedTime = dayjs(time, ['h:mm A', 'HH:mm']);
  if (!parsedTime.isValid()) {
    throw new Error('Invalid time format');
  }
  return parsedTime.format('HH:mm');
};

const formatDateTime = (date: Date, time: string) => {
  const dateStr = dayjs(date).format('YYYY-MM-DD');
  const timeStr = time || '00:00';
  const newDate = new Date(`${dateStr}T${convertTo24HourFormat(timeStr)}`);

  return newDate.toISOString();
};

export default function ScratchFormFields({
  formik,
  apiModel,
  runnerId,
  number = 0,
  updatedBy,
}: TScratchFormFields) {
  const [dateField, , { setValue: setDateField }] = useField(
    `runners[${number}].date`
  );
  const [timeField, , { setValue: setTimeField }] = useField(
    `runners[${number}].time`
  );
  const [winField, winMeta, { setValue: setWinField }] = useField(
    `runners[${number}].win`
  );
  const [p2Field, p2Meta, { setValue: setP2Field }] = useField(
    `runners[${number}].p2`
  );
  const [p3Field, p3Meta, { setValue: setP3Field }] = useField(
    `runners[${number}].p3`
  );

  const { errors } = formik;

  const getOrSetScratchInfo = () => {
    const runnerModel =
      apiModel[runnerId] || (apiModel[runnerId] = { id: runnerId });
    return runnerModel.scratch_info || (runnerModel.scratch_info = {});
  };

  const handleDateChange: CalendarProps['onChange'] = (date) => {
    if (!date || Array.isArray(date)) return;

    const scratchInfo = getOrSetScratchInfo();
    setDateField(date);
    scratchInfo.scratch_time = formatDateTime(date, timeField.value);
  };

  // eslint-disable-next-line
  const handleTimeChange = (time: any) => {
    if (!time) return;

    const scratchInfo = getOrSetScratchInfo();
    setTimeField(time);
    scratchInfo.scratch_time = formatDateTime(dateField.value, time);
  };

  const handleWinChange = (value: string) => {
    if (!value) {
      setWinField('');
      return;
    }

    if (!/^\d+$/.test(value) || +value > 100) return;

    setWinField(value);

    const scratchInfo = getOrSetScratchInfo();
    scratchInfo.win_deduction = +value;
  };

  const handleP2Change = (value: string) => {
    if (!value) {
      setP2Field('');
      return;
    }

    if (!/^\d+$/.test(value) || +value > 100) return;

    setP2Field(value);
    const scratchInfo = getOrSetScratchInfo();
    scratchInfo.p2_deduction = +value;
    scratchInfo.p3_deduction = +p3Field.value;
  };

  const handleP3Change = (value: string) => {
    if (!value) {
      setP3Field('');
      return;
    }

    if (!/^\d+$/.test(value) || +value > 100) return;

    setP3Field(value);

    if (!value) return;
    const scratchInfo = getOrSetScratchInfo();
    scratchInfo.p2_deduction = +p2Field.value;
    scratchInfo.p3_deduction = +value;
  };

  return (
    <FlexFormWrapper>
      <FlexInputsWrapper>
        <Datepicker
          name={dateField.name}
          value={dateField.value ? new Date(dateField.value) : new Date()}
          onChange={handleDateChange}
          clearIcon={null}
          calendarIcon={<Icon as={ChevronDown} />}
          wrapperProps={{
            h: '8',
            paddingRight: '0 !important',
            mr: '2',
          }}
        />
        <Timepicker
          disableClock
          name={timeField.name}
          value={timeField.value}
          isInvalid={!!errors?.runners?.[number]?.time}
          onChange={handleTimeChange}
          clearIcon={null}
          wrapperProps={{ h: '8' }}
        />
        <InputDeduction
          {...winField}
          name={winField.name}
          isInvalid={!!winMeta.error}
          value={winField.value ?? ''}
          onChange={(e) => handleWinChange(e.target.value)}
          size="sm"
          propsError={{ sx: { display: 'none' } }}
          inputLeftAddonSx={{ borderLeftRadius: 'md', fontSize: 'xs' }}
          InputLeftAddon="WIN"
        />
        <InputDeduction
          {...p2Field}
          name={p2Field.name}
          isInvalid={!!p2Meta.error}
          onChange={(e) => handleP2Change(e.target.value)}
          value={p2Field.value ?? ''}
          size="sm"
          propsError={{ sx: { display: 'none' } }}
          inputLeftAddonSx={{ borderLeftRadius: 'md', fontSize: 'xs' }}
          InputLeftAddon="P2"
        />
        <InputDeduction
          {...p3Field}
          name={p3Field.name}
          isInvalid={!!p3Meta.error}
          onChange={(e) => handleP3Change(e.target.value)}
          value={p3Field.value ?? ''}
          size="sm"
          propsError={{ sx: { display: 'none' } }}
          inputLeftAddonSx={{ borderLeftRadius: 'md', fontSize: 'xs' }}
          InputLeftAddon="P3"
        />
      </FlexInputsWrapper>
      {updatedBy && (
        <TextUpdatedBy>
          <FormattedMessage
            id="trademanagerPage.raceDetails.updatedBy"
            values={{ updatedBy }}
          />
        </TextUpdatedBy>
      )}
    </FlexFormWrapper>
  );
}
