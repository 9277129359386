import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ContainerWrapper, ParentContainer } from './Styles';

interface CardProps {
  title?: string;
  className?: string;
  noBottomPad?: boolean;
}

export const Card: FC<CardProps> = ({ className, noBottomPad }) => {
  return (
    <ParentContainer className={className}>
      <ContainerWrapper noBottomPad={noBottomPad}>
        <Outlet />
      </ContainerWrapper>
    </ParentContainer>
  );
};
