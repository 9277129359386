export const headerVariants = {
  hidden: { opacity: 0, y: -50 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
};

export const sideVariants = {
  hidden: { opacity: 0, x: -150 },
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -150 },
};

export const contentVariants = {
  hidden: { opacity: 0, y: 100 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 100 },
};

export const randomVariants = {
  hidden: { opacity: 0.8 },
  enter: { opacity: 1 },
  exit: { opacity: 0.8 },
};
