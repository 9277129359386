import { keys } from '@/api/api.keys';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryGetSport } from './getSport';
import { TGetSportParams, TSport } from './getSport.types';

// Trade Manager - Get Sport

type TQueryGetSportHook = {
  params?: TGetSportParams;
  options?: Omit<UseQueryOptions<TSport[]>, 'queryKey' | 'queryFn'>;
};

export const useQueryGetSport = ({ params, options }: TQueryGetSportHook) =>
  useQuery([keys.getSport, params], () => queryGetSport(params), options);
