import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TBetsResponse } from '../../../types';
import { EPlacedBetStates } from '../../Resulted/types';

export type TFilter = {
  [key: string]: string | number | boolean | undefined;
  punter_name?: string;
  event_type?: string;
  states?: EPlacedBetStates;
  sort_direction?: 'desc' | 'asc';
  sort_by?:
    | 'event_title'
    | 'created_at'
    | 'punter_name'
    | 'status'
    | 'stake'
    | 'odds'
    | 'return';
  cashout_only?: boolean;
};

type TBets = {
  response: TBetsResponse | undefined;
  filter: TFilter;
};

const initialState: TBets = {
  response: undefined,
  filter: {
    punter_name: '',
    event_type: '',
    states: EPlacedBetStates.Pending,
    sort_direction: 'desc',
    sort_by: 'created_at',
    cashout_only: false,
  },
};

const upcomingBetsSlice = createSlice({
  name: 'upcomingBets',
  initialState,
  reducers: {
    setUpcomingData: (
      state,
      action: PayloadAction<NonNullable<TBets['response']>>
    ) => ({
      ...state,
      response: {
        ...action.payload,
      },
    }),
    setFilter: (state, action: PayloadAction<Partial<TBets['filter']>>) => {
      const key = Object.keys(action.payload)[0];
      const entireKey = Object.keys(action.payload);

      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: action.payload[key],
          ...(!entireKey.includes('offset') && { offset: 0 }),
        },
      };
    },
    reset: () => initialState,
  },
});

export const { reset, setFilter, setUpcomingData } = upcomingBetsSlice.actions;

export default upcomingBetsSlice.reducer;
