import {
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Form as FormikForm, useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '@/common/hooks/useRedux';
import { setIsConfigureRaceModalOpen } from '../../../../Services/RaceDetails.slices';
import { TOfferingsFormValues } from '../Offerings';

type TFormProps = {
  win: boolean;
  place: boolean;
  winTote?: boolean;
  placeTote: boolean;
  children?: React.ReactNode;
};

export default function Form({ children, win, place }: TFormProps) {
  const dispatch = useAppDispatch();
  const { getFieldProps, isSubmitting, values, setFieldValue } =
    useFormikContext<TOfferingsFormValues>();

  return (
    <FormikForm>
      <ModalBody sx={{ pt: '0 !important' }}>
        <Text fontWeight="bold" py="2">
          <FormattedMessage id="tradeManagerPage.raceDetails.startingPriceOddsMarkets" />
        </Text>

        {win && (
          <FormControl
            alignItems="center"
            borderTop="1px"
            borderColor="blackAlpha.100"
            display="flex"
            py="2"
          >
            <FormLabel mb="0">Starting Price</FormLabel>
            <Switch
              isChecked={values.win}
              ml="auto"
              {...getFieldProps('win')}
            />
          </FormControl>
        )}

        {place && (
          <FormControl
            alignItems="center"
            borderTop="1px"
            borderColor="blackAlpha.100"
            display="flex"
            py="2"
          >
            <FormLabel mb="0">
              <FormattedMessage id="generic.placeSP" />
            </FormLabel>
            <Switch
              isChecked={values.place}
              ml="auto"
              {...getFieldProps('place')}
            />
          </FormControl>
        )}

        <Text fontWeight="bold" py="2">
          Tote Singles
        </Text>

        <FormControl
          alignItems="center"
          borderTop="1px"
          borderColor="blackAlpha.100"
          display="flex"
          py="2"
        >
          <FormLabel mb="0">Win (MID)</FormLabel>
          <Switch
            isChecked={values.winTote}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (values.bestTote) setFieldValue('bestTote', !e.target.checked);

              setFieldValue('winTote', e.target.checked);
            }}
            ml="auto"
          />
        </FormControl>
        <FormControl
          alignItems="center"
          borderTop="1px"
          borderColor="blackAlpha.100"
          display="flex"
          py="2"
        >
          <FormLabel mb="0">Place (MID)</FormLabel>
          <Switch
            isChecked={values.placeTote}
            ml="auto"
            {...getFieldProps('placeTote')}
          />
        </FormControl>

        <FormControl
          alignItems="center"
          borderTop="1px"
          borderColor="blackAlpha.100"
          display="flex"
          py="2"
        >
          <FormLabel mb="0">WIN (BEST)</FormLabel>
          <Switch
            isChecked={values.bestTote}
            ml="auto"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (values.winTote) setFieldValue('winTote', !e.target.checked);

              setFieldValue('bestTote', e.target.checked);
            }}
          />
        </FormControl>

        {children}
      </ModalBody>
      <ModalFooter>
        <Button
          borderRadius="lg"
          colorScheme="gray"
          variant="outline-rounded"
          onClick={() => dispatch(setIsConfigureRaceModalOpen(false))}
        >
          <FormattedMessage id="generic.cancel" />
        </Button>
        <Button borderRadius="lg" isLoading={isSubmitting} ml="4" type="submit">
          <FormattedMessage id="generic.save" />
        </Button>
      </ModalFooter>
    </FormikForm>
  );
}
