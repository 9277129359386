import { object, SchemaOf, string } from 'yup';
import { TBetApprovalLimitValues } from '../../../types';
import { getStrings } from '../../../../../../../../../common/utils';

const [
  {
    FormValidation: { RequiredField: RequiredFieldMessage },
  },
] = getStrings();

export const betApprovalLimitDefault: TBetApprovalLimitValues = {
  limit: '0.00',
  exposure_limit: '0.00',
  exotic_limit: '0.00',
  cross_game_limit: '0.00',
};

export const BetApprovalLimitValidation: SchemaOf<TBetApprovalLimitValues> =
  object({
    limit: string().trim().required(RequiredFieldMessage),
    exposure_limit: string().trim().required(RequiredFieldMessage),
    exotic_limit: string().trim().required(RequiredFieldMessage),
    cross_game_limit: string().trim().required(RequiredFieldMessage),
  });
