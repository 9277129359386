import api from '../../api';
import {
  TMutatePromotionBody,
  TMutateCancelPromotionParams,
  TQueryPromotionResponse,
  TQueryPromotionParams,
  TMutateEditPromotionParams,
} from './promotion.types';

export const promotionEndpoint = '/bookie/promos/promo/promotion';
export const cancelPromotionEndpoint = '/bookie/promos/promo/cancel';

export async function mutatePromotion(body: TMutatePromotionBody) {
  const res = await api.post(promotionEndpoint, body);
  return res;
}

export async function mutateDeletePromotion(body: TQueryPromotionParams) {
  const res = await api.put(promotionEndpoint, body);
  return res;
}

export async function mutateCancelPromotion(
  body: TMutateCancelPromotionParams
) {
  const res = await api.post(cancelPromotionEndpoint, body);
  return res;
}

export async function mutateEditPromotion(body: TMutateEditPromotionParams) {
  const res = await api.patch(promotionEndpoint, body);
  return res;
}

export const queryPromotion = (params: TQueryPromotionParams) =>
  api
    .get<TQueryPromotionResponse>(promotionEndpoint, { params })
    .then((res) => res.data);
