import {
  chakra,
  ChakraComponent,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import Input, { TIndex } from '@/common/components/FormElements/Input';

export const TextUpdatedBy: ChakraComponent<'span', TextProps> = chakra(Text, {
  baseStyle: () => ({
    color: 'gray.500',
    fontSize: 'sm',
    whiteSpace: 'nowrap',
    ml: 'auto',
    mr: '3',
  }),
});

export const FlexFormWrapper: ChakraComponent<'div', FlexProps> = chakra(Flex, {
  baseStyle: () => ({
    alignItems: 'center',
  }),
});

export const FlexInputsWrapper: ChakraComponent<'div', FlexProps> = chakra(
  Flex,
  {
    baseStyle: () => ({
      alignItems: 'center',
      gap: '2',
      px: '3.5',
      py: '2',
      maxW: '500px',
    }),
  }
);

export const InputDeduction = chakra(Input, {
  baseStyle: () => ({
    minW: '60px',
    borderRightRadius: 'md',
    bg: 'white',
  }),
}) as ChakraComponent<'input', TIndex>;
