import { sendPasswordResetEmail, getAuth, AuthError } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { firebaseConfig } from '../../lib/firebase/config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

/**
 * Handles resetting a user's password.
 *
 * @param email The user's email address
 */
export const sendResetPasswordLink = async (
  email: string
): Promise<{ success: boolean; error?: AuthError }> => {
  try {
    await sendPasswordResetEmail(auth, email.toLowerCase());

    return { success: true };
  } catch (e) {
    const error = e as AuthError;

    throw error;
  }
};
