import React from 'react';
import { centsToDollars } from '@/common/utils';
import { TRequestBet } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { EPlayerType } from '@/lib/DBModels';
import { Notifications } from '@styled-icons/material-outlined/Notifications';
import { Menu, MenuList, MenuItem, Flex } from '@chakra-ui/react';
import {
  BCMenuGroup,
  ButtonNotification,
  FlexMenuWrapper,
  IconLogout,
  PlayerIcon,
  UserBadge,
} from './styles/Notification.styles';
import {
  usePendingRequestsData,
  useApprovalsNotification,
} from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.hooks';
import { usePunterCategory } from '@/features/punters/pages/Punters/services/Punters.utils';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

export default function BetMenu() {
  const { data: approvals, offset } = usePendingRequestsData();
  const { totalRecords } = useApprovalsNotification(
    approvals?.bet_requests as TRequestBet[],
    offset
  );
  dayjs.extend(relativeTime);

  const { getPlayerIcon, getPunterCategoryColor } = usePunterCategory();

  const data = approvals?.bet_requests;
  if (!data?.length) return <></>;

  return (
    <Menu>
      <ButtonNotification aria-label="Options" isActive={!!data?.length}>
        <IconLogout as={Notifications} isActive={!!data?.length} />
      </ButtonNotification>
      <MenuList w="450px">
        <BCMenuGroup title={`Notifications ${totalRecords}`}>
          {data.map((el) => (
            <MenuItem
              key={el?.request_id}
              sx={{
                px: '0',
              }}
            >
              <FlexMenuWrapper to="bet-approvals/approvals">
                <Flex fontSize="sm" gap="1">
                  <UserBadge
                    bg={getPunterCategoryColor(
                      el?.punter_category_at_placement ?? EPlayerType.Newbie
                    )}
                  >
                    <PlayerIcon
                      color="white"
                      src={getPlayerIcon(
                        el?.punter_category_at_placement ?? EPlayerType.Newbie
                      )}
                    />
                  </UserBadge>
                  <FormattedMessage
                    id="mainheader.notifications.approval"
                    values={{
                      punter: el?.punter_name,
                      stake: centsToDollars(el?.requested_stake),
                      odds: el?.requested_odds?.toFixed(2),
                      on: el?.event_title,
                    }}
                  />
                </Flex>
                <Flex justifyContent="end" ml="1" pl="1">
                  {dayjs(el?.actioned_at ?? '').fromNow()}
                </Flex>
              </FlexMenuWrapper>
            </MenuItem>
          ))}
        </BCMenuGroup>
      </MenuList>
    </Menu>
  );
}
