/* eslint-disable camelcase */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiGetRequest } from '../../../../../../lib/api/api';
import { setResultedBetsResponse } from './slices';
import { AppThunkDispatch } from '../../../../../../app/types';
import { TBetsResponse, BetsQuery } from '../../types';

const getResultedBets = createAsyncThunk<
  Promise<TBetsResponse | undefined>,
  BetsQuery,
  { dispatch: AppThunkDispatch }
>(
  'bookie/getResultedBets',
  async (betsQuery, thunkAPI): Promise<TBetsResponse | undefined> => {
    try {
      const betsQueryObj: { [key: string]: unknown } = {
        event_title: betsQuery.event_title,
        states: betsQuery.states,
        punter_name: betsQuery.punter_name,
        start_date: betsQuery.start_date,
        end_date: betsQuery.end_date,
        sort_by: betsQuery.sort_by,
        sort_direction: betsQuery.sort_direction,
        offset: betsQuery.offset,
        limit: betsQuery.limit,
      };

      if (betsQuery.event_type !== 'All') {
        betsQueryObj.event_type = betsQuery.event_type;
      }

      const betsData = await apiGetRequest<TBetsResponse>(
        'bookie/placed-bets',
        betsQueryObj
      );
      thunkAPI.dispatch(setResultedBetsResponse(betsData));
      return betsData;
    } catch (error) {
      return undefined;
    }
  }
);

export { getResultedBets };
