/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { TBet } from '@/lib/DBModels';
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../api.keys';
import { queryPlacedBets, queryPlacedBetWincore } from './placedBets';
import {
  TPlacedBetParams,
  TPlacedBetsWincoreParams,
  TPlacedBetWincore,
} from './placedBets.types';

type TQueryPlacedBetsHook = {
  params?: TPlacedBetParams;
  key?: unknown[];
  options?: UseQueryOptions<TBet[]>;
};

export const useQueryPlacedBets = ({
  params = {},
  key = [],
  options = {},
}: TQueryPlacedBetsHook = {}) => {
  const query = useQuery(
    [keys.placedBets, ...Object.values(params), ...key],
    () => queryPlacedBets(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

type TQueryPlacedBetsHookWincore = {
  params?: TPlacedBetsWincoreParams;
  key?: unknown[];
  options?: UseQueryOptions<TPlacedBetWincore>;
};

export const useQueryPlacedBetsWincore = ({
  params = {},
  key = [],
  options = {},
}: TQueryPlacedBetsHookWincore = {}) => {
  const query = useQuery(
    [keys.placedBets, ...Object.values(params), ...key],
    () => queryPlacedBetWincore(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
