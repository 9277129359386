import { BasePalette } from './types';

const colours: BasePalette = {
  midBlue: 'var(--bc-colors-blue-500)',
  lightBlue: '#00C1FF',
  turquoise: '#BCEFFF',
  babyBlue: '#EFFCFF',
  darkBlue: '#0061D9',
  textBlue: '#091825',
  silver: '#E7E7E9',
  red: '#FF003B',
  lightRed: '#FFF1F4',
  orangeText: '#D98A02',
  black: '#1A1A1A',
  blueBlack: '#101924',
  blueGreyBlack: '#102534',
  yellow: '#FFB900',
  green: '#99DA00',
  greenText: '#8CC800',
  borderGrey: '#D1DDE3',
  linesGrey: '#ECECEC',
  darkGrey: '#79848B',
  textOffGrey: '#77777A',
  midGrey: '#BFC1C1',
  greyBlue: '#C7D5DC',
  feintGrey: '#f8f8f8',
  lightButtonGrey: '#f0f0f0',
  backgroundGrey: '#F0F3F5',
  seperatorGrey: '#D1D1D1',
  white: '#FFFFFF',
  lightGrey: '#DBDBDB',
  lighterGrey: '#8C8C8C',
  deepBlue: '#176AB0',
  regularGrey: '#B4BBC0',
  faintBlue: '#E6F9FF',
  paleBlue: '#FAFEFF',
  backingGrey: '#E9E9E9',
  bcLightDark: 'var(--bc-colors-gray-500)',
  inputBorderGrey: 'var(--bc-colors-gray-200)',
};

export default colours;
