import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TEventsResponse } from '../../types';
import { TFindAnEventState } from './types';
import { findAnEvent } from './actions';

const initialState: TFindAnEventState = {
  query: {},
  eventsResponse: {
    records: [],
    total_pages: 1,
    total_records: 0,
  },
  eventsLoading: false,
};

const findAnEventSlice = createSlice({
  name: 'findAnEvent',
  initialState,
  reducers: {
    setEventsResponse: (
      state: TFindAnEventState,
      action: PayloadAction<TEventsResponse>
    ) => ({ ...state, eventsResponse: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAnEvent.pending, (state: TFindAnEventState) => ({
        ...state,
        eventsLoading: true,
      }))
      .addCase(findAnEvent.fulfilled, (state: TFindAnEventState) => ({
        ...state,
        eventsLoading: false,
      }))
      .addCase(findAnEvent.rejected, (state: TFindAnEventState) => ({
        ...state,
        eventsLoading: false,
      }));
  },
});

export const { setEventsResponse } = findAnEventSlice.actions;

export default findAnEventSlice.reducer;
