import {
  SkeletonText,
  SkeletonProps,
  TableCellProps,
  Td,
} from '@chakra-ui/react';
import React from 'react';

export type SkeletonTableCellProps = TableCellProps &
  Pick<SkeletonProps, 'isLoaded'>;

export default function SkeletonTableCell({
  children,
  isLoaded,
}: SkeletonTableCellProps) {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <Td>
      <SkeletonText noOfLines={1} />
    </Td>
  );
}
