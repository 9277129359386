import { css } from '@emotion/css';
import React from 'react';
import { getThemeVar } from 'remirror';
import { MarkdownToolbar, Remirror } from '@remirror/react';
import { useDualEditor } from './WyswygHOC';
import Save from './Save';
import { TWyswyg } from './Wyswyg';
import { Flex } from '@chakra-ui/react';

export default function VisualEditor({
  initialContent,
  policyName,
  withSave = true,
  onChange,
}: TWyswyg) {
  const { visual, setMarkdown } = useDualEditor();

  return (
    <Flex sx={{ flexDir: 'column' }}>
      <Remirror
        autoFocus
        manager={visual.manager}
        autoRender="end"
        onChange={({ helpers, state }) => {
          return onChange
            ? onChange(helpers.getMarkdown(state))
            : setMarkdown(helpers.getMarkdown(state));
        }}
        initialContent={initialContent}
        classNames={[
          css`
            &.ProseMirror {
              p,
              h3,
              h4 {
                margin-top: ${getThemeVar('space', 2)};
                margin-bottom: ${getThemeVar('space', 2)};
              }

              h1,
              h2 {
                margin-bottom: ${getThemeVar('space', 3)};
                margin-top: ${getThemeVar('space', 3)};
              }
            }

            ol,
            ul {
              padding-left: 16px;
            }
          `,
        ]}
      >
        <MarkdownToolbar />
        {withSave && <Save policyName={policyName ?? ''} />}
      </Remirror>
    </Flex>
  );
}
