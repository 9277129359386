import { HorseHead } from '@styled-icons/fa-solid/HorseHead';
import { SportBasketball } from '@styled-icons/fluentui-system-filled/SportBasketball';
import { ListCircle } from '@styled-icons/ionicons-sharp/ListCircle';

const tabs = [
  {
    to: '?type=quick-links',
    text: 'Quick Links',
    icon: ListCircle,
    dataCy: 'cmsQuickLinks',
  },
  {
    to: '?type=racing',
    text: 'Racing',
    icon: HorseHead,
    dataCy: 'cmsRacingLink',
  },
  {
    to: '?type=sports',
    text: 'Sport',
    icon: SportBasketball,
    dataCy: 'cmsSportLink',
  },
];

export default tabs;
