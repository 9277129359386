import { EStorageKey } from './localStorage.keys';

/* Utility function to handle reading from localStorage */
export const getLocalStorageItem = (storageKey: EStorageKey): string[] => {
  const serializedState = localStorage.getItem(storageKey);
  return serializedState ? JSON.parse(serializedState) : [];
};

/* Utility function to handle writing to localStorage */
export const setLocalStorageItem = (
  storageKey: EStorageKey,
  value: string[]
): void => {
  localStorage.setItem(storageKey, JSON.stringify(value));
};
