import { CenterProps } from '@chakra-ui/react';
import { useEffect } from 'react';
import React from 'react';
import NextPageButton from './components/NextPageButton';
import PreviousPageButton from './components/PreviousPageButton';
import { CenterPagination } from './Pagination.styles';

type TPagination = CenterProps & {
  offset?: string;
  setOffset?: React.Dispatch<React.SetStateAction<string>>;
  history: string[];
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  nextDisabled: boolean;
  useHistory?: boolean;
  nextOffsetId?: string;
  onPageChange: (newOffset: string) => void;
};

/**
 * Type: string
 * This pagination compenent handles next_offset token, stores he previous values.
 */
export const PaginationV3 = ({
  onPageChange,
  offset = '0',
  history = [],
  setHistory,
  nextDisabled,
  useHistory = false,
  nextOffsetId,
  ...rest
}: TPagination) => {
  useEffect(() => {
    onPageChange(history[history.length - 1] ?? '');
  }, [history, onPageChange]);

  const updateHistory = (newOffset: string) => {
    if (useHistory) {
      setHistory((prevHistory: string[]) => {
        const updatedHistory = [...prevHistory, newOffset];
        return updatedHistory;
      });
    }
  };

  const onNext = () => {
    if (nextOffsetId) {
      updateHistory(nextOffsetId);
    }
  };

  const onPrevious = () => {
    if (useHistory && history.length > 0) {
      const newHist = [...history];
      const removedLast = newHist.slice(0, newHist.length - 1);
      setHistory(removedLast);
    }
  };

  return (
    <CenterPagination {...rest}>
      <PreviousPageButton
        isDisabled={offset === '0' || offset === ''}
        onClick={onPrevious}
      />
      <NextPageButton isDisabled={nextDisabled} onClick={onNext} />
    </CenterPagination>
  );
};

export { NextPageButton, PreviousPageButton };
