import { TGlobalExposureLimits } from '../../../features/settings/pages/Settings/tabs/Limits/types';
import api from '../../api';

export const globalExposureLimitsURL =
  'bookie/settings/limits/global-exposure-limits';

export const updateGlobalExposureLimits = async (
  limits: TGlobalExposureLimits
) => api.post(globalExposureLimitsURL, limits).then((res) => res.data);

export const queryGlobalExposureLimits = async () =>
  api
    .get<TGlobalExposureLimits>(globalExposureLimitsURL)
    .then((res) => res.data);
