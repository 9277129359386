import api from '../../api';
import {
  TQueryTokenHistoryParams,
  TQueryTokenHistoryResponse,
} from './tokenHistory.types';

export const tokenHistoryEndpoint = '/bookie/promos/token/token-history';

export async function queryTokenHistory(params: TQueryTokenHistoryParams) {
  const res = await api.get<TQueryTokenHistoryResponse>(tokenHistoryEndpoint, {
    params,
  });
  return res;
}
