export const permissions = [
  'risk_manager_home_view',
  'risk_manager_event_view',
  'risk_manager_event_manage_odds',
  'risk_manager_event_configure',
  'bet_approvals_approvals_view',
  'bet_approvals_approvals_approve',
  'bet_approvals_history_view',
  'placed_bets_upcoming_view',
  'placed_bets_upcoming_cancel',
  'placed_bets_resulted_view',
  'placed_bets_resulted_cancel',
  'bank_pending_approval_view',
  'bank_pending_approval_approve',
  'bank_ready_to_pay_view',
  'bank_ready_to_pay_approve',
  'bank_ready_to_pay_export',
  'bank_history_view',
  'bank_history_export',
  'promo_manager_all_promos_view',
  'promo_manager_all_promos_import',
  'punters_punter_profile_change_category',
  'punters_punter_profile_cancel_void',
  'punters_punter_profile_manual_settle',
  'punters_punter_profile_bonus_bet_add',
  'punters_punter_profile_bonus_bet_revoke',
  'punters_punter_profile_deposit_limit_edit',
  'punters_punter_profile_notes_delete',
  'punters_punter_profile_personal_details_edit',
  'punters_punter_profile_verified_status_edit',
  'punters_punter_profile_account_status_edit',
  'punters_punter_profile_exclusion_period_edit',
  'settings_limits_view',
  'settings_limits_edit',
  'settings_offerings_view',
  'settings_offerings_edit',
  'settings_setup_bank_view',
  'settings_setup_bank_edit',
  'settings_setup_security_view',
  'settings_setup_security_edit',
  'settings_your_profile_password_change',
  'settings_your_profile_details_edit',
  'settings_activity_log_view',
  'punters_home_view',
  'punters_punter_profile_view',
  'punters_punter_profile_payment_method_remove',
  'punters_punter_profile_deposits_reconcile',
  'punters_punter_profile_notes_view',
  'punters_punter_profile_notes_add',
  'punters_punter_profile_activity_log_view',
  'punters_punter_profile_transactions_edit',
  'punters_punter_profile_transaction_history_download',
  'nt_ial_view',
  'punters_punter_profile_payment_method_view',
  'settings_your_profile_view',
  'settings_setup_policies',
  'scoreboard_view',
  'punters_punter_profile_affiliate_edit',
];

type TRoutes = {
  path: string;
  permissonId: string;
};

export const routes: TRoutes[] = [
  { path: '/scoreboard', permissonId: 'scoreboard_view' },
  { path: '/trade-manager', permissonId: 'risk_manager_home_view' },
  { path: '/bet-approvals', permissonId: 'bet_approvals_approvals_view' },
  { path: '/placed-bets', permissonId: 'placed_bets_upcoming_view' },
  { path: '/bank', permissonId: 'bank_history_view' },
  { path: '/punter', permissonId: 'punters_punter_profile_view' },
  { path: '/promo-manager', permissonId: 'promo_manager_all_promos_view' },
  { path: '/punters', permissonId: 'punters_home_view' },
  { path: '/settings', permissonId: 'settings_your_profile_view' },
  { path: '/regulator', permissonId: 'nt_ial_view' },
  { path: '/dashboard', permissonId: 'scoreboard_view' },
];
