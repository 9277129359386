import React from 'react';
import IconSvg from '@/components/IconSvg/IconSvg';
import { UserBadge } from '@/features/punters/pages/Punters/styles/Punters.styles';
import { Tooltip } from '@chakra-ui/react';

type IconTooltipProps = {
  label: string;
  iconName: string;
};

export const IconTooltip = ({ label, iconName }: IconTooltipProps) => (
  <Tooltip ml="1" hasArrow label={label}>
    <UserBadge bg="black" boxSize={5} ml={2}>
      <IconSvg name={iconName} sx={{ boxSize: '3' }} />
    </UserBadge>
  </Tooltip>
);
