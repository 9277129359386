import React, { useEffect } from 'react';
import { Badge, Box, Flex, Image, Tag, Text } from '@chakra-ui/react';
import { Link, Path, useLocation, useNavigate } from 'react-router-dom';
import { FilePaper, Refund } from '@styled-icons/remix-fill';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from '../../../../../common/hooks/useDebounce';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../common/hooks/useRedux';
import { reset, setModalInfoBet, setModalSettleBet } from './Slice.Bets';
import { TBetEventData, TBetsData, TEventType } from './Types.Bets';
import {
  TOTE_MULTI_DISPLAY_NAME,
  centsToDollars,
  getIconAssetPath,
  getStrings,
} from '../../../../../common/utils';
import { TextEvent, BonusBetFlag } from '../Styles';
import BetsTablePopover from '../../../../../common/components/BetsTablePopover';
import {
  EBetStatus,
  TPriceType,
  TPromotion,
} from '../../../../../lib/DBModels';
import { useVoidBetModal } from '../../../../bets/modals/VoidBetModal/Services/Services.VoidBet';
import BetReturnText from '../../../../../common/components/BetReturnText';
import { TExoticCombo } from '../../../../../common/components/ExoticsBetSelection/Services/Types.ExoticsBetCombos';
import BetStatusBadge from '../../../../../common/components/BetStatusBadge';
import { getExoticSelections, getFlexi, isBoxed } from './Bets.utils';
import {
  LegSelection,
  TBetLegs,
  TBetLegsEventData,
} from '../../../../../api/punters/punters.types';
import { useQueryPunterBets } from '../../../../../api/punters/bets/bets.hooks';
import SGMLogo from '@/assets/icons/sgm-icon.svg';
import SRMLogo from '@/assets/icons/srm-icon.svg';
import MultiLogo from '@/assets/icons/multi-icon.svg';
import BlendedLogo from '@/assets/icons/blended-icon.svg';
import { ManualApprovalTooltip } from '@/features/bets/components/ManualApprovalTooltip';
import { TPromoToken } from '@/api/promos/token/tokenSummary.types';
import { OddsBoostTooltip } from '@/features/bets/components/OddsBoostTooltip';
import IconSvg from '@/components/IconSvg/IconSvg';

/**
 * Hook that fetches/filters/sorts the bets data
 */
export const useBetsData = () => {
  const filters = useAppSelector((state) => state.punterBets.filter);
  const params = useDebounce(filters, 250);
  const { offset, event_title, cashout_only } = params;
  const limit = 20;

  /* refetch if user is on the first page and no filter is set */
  const shouldRefetch = !event_title && offset === 0;

  const { data, isLoading } = useQueryPunterBets({
    params: { ...params, limit, cashout_only },
    options: {
      refetchInterval: shouldRefetch ? 60000 : 0, // polling every minute
    },
  });

  return {
    data,
    offset,
    isLoading,
    isLastFetch: (data?.length ?? 0) < limit,
  };
};

/**
 * Hook that sets/resets the bet data
 */
export const useBet = () => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );
};

/**
 * Hook which returns to the columns for the table
 */
export const useColumns = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showVoidBetFromBet } = useVoidBetModal();
  const dispatch = useAppDispatch();

  const [
    {
      BetTablePopover: BetsTablePopoverStrings,
      PunterPage: {
        Bets: { ColumnTitles, ColumnContent, Selection: SelectionStrings },
      },
    },
  ] = getStrings();

  const selection = (data: TBetsData): number[][] => {
    const exoticSelections = getExoticSelections(
      data?.exotic_selections as TExoticCombo[]
    );
    const filteredSelections: number[][] = [];
    exoticSelections.map((sel) => {
      if (sel !== undefined) {
        const col: number[] = [];
        sel.map((q) => (q !== undefined ? col.push(q) : null));
        filteredSelections.push(col);
      }
      return null;
    });
    return isBoxed(filteredSelections)
      ? [filteredSelections[0]]
      : filteredSelections;
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'event',
        header: ColumnTitles.Event,
        cell: (props: {
          row: {
            original: {
              event_id: string | undefined;
              event_title: string | undefined;
              event_type: string | undefined;
              event_icon: string | undefined;
              event_data: TBetEventData;
              exotic_selections: TExoticCombo[];
              created_at: string;
              status: EBetStatus;
              bet_description: string;
              bet_legs: TBetLegs[];
              promo_tokens: TPromoToken[];
              promotions: TPromotion[];
              price_type?: TPriceType;
              bet_type?: string;
            };
          };
        }) => {
          const original = props?.row?.original;
          const betDescription = original.bet_description;

          const isMulti = betDescription === 'Multi';
          const isSGM = betDescription === 'Same Game Multi';
          const isSRM = betDescription === 'Same Race Multi';
          const isEvenShot = betDescription === 'Even Shot';

          const isBlended = betDescription === 'Blended';

          const isMysteryBet = original?.price_type === 'mystery_bet';
          const hasRollover = isMysteryBet && original?.bet_legs?.length > 1;

          const promotions = original?.promotions?.filter(
            (promotion) => promotion.type === 'money_back'
          );

          const isToteMultiBet =
            original?.bet_type?.toLowerCase() === 'totemulti';

          if (isToteMultiBet)
            return (
              <Flex sx={{ alignItems: 'center', justifyContent: 'start' }}>
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={
                    {
                      pathname: `/trade-manager/${original?.event_type?.toLowerCase()}-details`,
                      search: `?id=${original?.event_id}`,
                      state: { from: location },
                    } as unknown as Path
                  }
                >
                  <BetStatusBadge status={original?.status} />
                  <TextEvent weight="bold" truncate>
                    {original.event_data.venue_name}
                  </TextEvent>

                  {dayjs(props.row.original.created_at ?? '').format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}
                </Link>
              </Flex>
            );

          return (
            <Flex sx={{ alignItems: 'center', justifyContent: 'start' }}>
              <Link
                onClick={(e) => e.stopPropagation()}
                to={
                  {
                    pathname: `/trade-manager/${original?.event_type?.toLowerCase()}-details`,
                    search: `?id=${original?.event_id}`,
                    state: { from: location },
                  } as unknown as Path
                }
              >
                <BetStatusBadge status={original?.status} />

                <TextEvent weight="bold" truncate>
                  {isMysteryBet && (
                    <>
                      <IconSvg
                        name={getIconAssetPath('sports', 'square-question')}
                        sx={{ boxSize: '4', mr: '2', cursor: 'pointer' }}
                      />
                      {`Mystery Bet ${hasRollover ? 'with Rollover' : ''}`}
                    </>
                  )}
                  {isBlended && (
                    <>
                      <Image src={BlendedLogo} w="14" mr="3" />
                      {original?.bet_legs.length} Legs Blended
                    </>
                  )}

                  {isSRM && (
                    <>
                      <Image src={SRMLogo} boxSize="10" mr="3" />
                      {props.row.original.bet_legs.length} Legs SRM
                    </>
                  )}
                  {isSGM && (
                    <>
                      <Image src={SGMLogo} boxSize="10" mr="3" />
                      {props.row.original.bet_legs.length} Legs SGM
                    </>
                  )}
                  {isEvenShot && (
                    <>
                      {props?.row.original.event_title ?? ''}
                      <Badge colorScheme="blackAlpha" ml="3">
                        Even Shot
                      </Badge>
                    </>
                  )}
                  {isMulti && (
                    <>
                      <Image src={MultiLogo} boxSize="7" my="1" mr="1" />
                      {props.row.original.bet_legs.length} Legs Multi
                    </>
                  )}
                  {!isSGM &&
                    !isSRM &&
                    !isBlended &&
                    !isEvenShot &&
                    !isMysteryBet &&
                    !isMulti && (
                      <>
                        <IconSvg
                          name={getIconAssetPath(
                            'sports',
                            props.row.original.event_icon
                          )}
                          sx={{ boxSize: '4', mr: '2', cursor: 'pointer' }}
                        />
                        {props?.row.original.event_title ?? ''}
                      </>
                    )}
                </TextEvent>

                {isBlended && (
                  <Text>{props?.row.original.event_title ?? ''}</Text>
                )}
                <Text>
                  {props.row.original.event_type === 'Match' &&
                    `${props.row.original.event_data?.competition_name}`}
                </Text>
                {dayjs(props.row.original.created_at ?? '').format(
                  'DD/MM/YYYY HH:mm:ss'
                )}
              </Link>
              {!!promotions &&
                promotions.map((promotion) => (
                  <Box
                    key={promotion.id}
                    onClick={() =>
                      navigate(
                        `/promo-manager/money-back/promotion?id=${promotion.id}}`
                      )
                    }
                  >
                    <IconSvg
                      name={getIconAssetPath('sports', 'money-back')}
                      sx={{ boxSize: '6', cursor: 'pointer' }}
                    />
                  </Box>
                ))}
            </Flex>
          );
        },
      },

      {
        accessorKey: 'selection',
        header: ColumnTitles.Selection,
        cell: (props: {
          row: {
            original: {
              exotic_selections: TExoticCombo[];
              event_subtitle: string;
              bet_description: string;
              event_data: TBetEventData;
              bet_legs: TBetLegs[];
              event_type: TEventType;
              bet_type?: string;
              tote_multi_selections?: LegSelection[];
            };
          };
          getValue: () => string | undefined;
        }) => {
          const bet = props.row.original;
          const isToteMultiBet =
            props?.row.original.bet_type?.toLowerCase() === 'totemulti';

          if (isToteMultiBet)
            return (
              <Flex
                gap="1"
                onClick={() =>
                  dispatch(setModalInfoBet(props.row.original as TBetsData))
                }
              >
                {bet.tote_multi_selections?.map((selection, index: number) => (
                  <Box key={index} sx={{ display: 'flex', flexDir: 'column' }}>
                    <Tag
                      justifyContent="center"
                      px="1.5"
                      bg="gray.500"
                      color="white"
                      borderBottomRadius="0"
                    >
                      R{selection.leg}
                    </Tag>
                    <Flex
                      bg="gray.200"
                      gap="0.5"
                      p="2"
                      color="gray.900"
                      borderBottomRadius="sm"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {selection.leg_selection
                        .slice(0, 2)
                        .map((s, index, arr) => (
                          <React.Fragment key={s.runner_number}>
                            <p>{s.runner_number}</p>
                            {index < arr.length - 1 && <span>,</span>}
                          </React.Fragment>
                        ))}
                    </Flex>
                    {selection.leg_selection.length > 2 && (
                      <Tag
                        bg="green.500"
                        borderTopRadius="0"
                        color="white"
                        fontSize="2xs"
                        justifyContent="center"
                        p="1"
                      >
                        +{selection.leg_selection.length - 2}
                      </Tag>
                    )}
                  </Box>
                ))}
              </Flex>
            );

          // Exotic
          if (props.row.original.exotic_selections) {
            return (
              <Flex
                gap="1"
                alignItems="center"
                onClick={() =>
                  dispatch(setModalInfoBet(props.row.original as TBetsData))
                }
              >
                {selection(props.row.original)
                  .map((pl) => pl.join(','))
                  .map((s, index, arr) => (
                    <Flex
                      key={`selection-${s}`}
                      as="span"
                      alignItems="center"
                      gap="1"
                    >
                      <Tag
                        justifyContent="center"
                        px="1.5"
                        bg="gray.50"
                        color="gray.900"
                      >
                        {s}
                      </Tag>
                      {index < arr.length - 1 && ` | `}
                    </Flex>
                  ))}
                <Text as="span" fontWeight="normal">
                  {' '}
                  - {getFlexi(props.row.original)}%
                </Text>
              </Flex>
            );
          }
          // Multi
          if (props.row.original?.bet_legs?.length > 0) {
            const legs = props.row.original.bet_legs;
            const hasRacingLegs = legs.some((leg) => {
              const eventData = leg?.event_data as TBetLegsEventData;
              return !!eventData.race_name;
            });
            const hasSportLegs = legs.some((leg) => {
              const eventData = leg?.event_data as TBetLegsEventData;
              return !!eventData.match_name;
            });

            const isBlended = props.row.original?.bet_description === 'Blended';

            const multiDescription = () => {
              if (hasRacingLegs && hasSportLegs) {
                return SelectionStrings.Mixed;
              }
              if (hasRacingLegs) {
                return SelectionStrings.Racing;
              }
              return SelectionStrings.Sport;
            };
            return (
              <Text
                onClick={() =>
                  dispatch(setModalInfoBet(props.row.original as TBetsData))
                }
              >
                {multiDescription()} - {props.row.original.bet_legs.length}{' '}
                {SelectionStrings.Legs} {isBlended && 'Blended'}
              </Text>
            );
          }
          const selectionText =
            props.row.original.event_type === 'Race'
              ? `${props.row.original.event_data?.runner_number}. ${props.row.original.event_data?.runner_name}`
              : props.row.original.event_data?.proposition_name;
          return (
            <Text
              onClick={() =>
                dispatch(setModalInfoBet(props.row.original as TBetsData))
              }
            >
              {props.row.original.event_data && selectionText}
            </Text>
          );
        },
      },
      {
        accessorKey: 'market',
        header: ColumnTitles.Market,
        cell: (props: {
          row: {
            original: {
              exotic_selections: TExoticCombo[];
              event_subtitle: string;
              event_type: TEventType;
              bet_description: string;
              market_name: string;
              price_type?: TPriceType;
              bet_legs: TBetLegs[];
              bet_type?: string;
            };
          };
          getValue: () => string | undefined;
        }) => {
          const isMysteryBet = props.row.original.price_type === 'mystery_bet';
          const hasRollover =
            isMysteryBet && props?.row?.original?.bet_legs?.length > 1;
          const isToteMultiBet =
            props?.row?.original?.bet_type?.toLowerCase() === 'totemulti';

          if (isToteMultiBet)
            return TOTE_MULTI_DISPLAY_NAME[props.row.original.bet_description];

          if (isMysteryBet && hasRollover) return <Text>Mystery Bet</Text>;

          return (
            <Text>
              {props.row.original.event_type === 'Race'
                ? props.row.original.bet_description
                : props.row.original.market_name}
            </Text>
          );
        },
      },
      {
        accessorKey: 'stake',
        header: ColumnTitles.Stake,
        cell: (props: {
          row: {
            original: {
              stake: number;
              bonus_stake: number;
              is_bonus: boolean;
            };
          };
          getValue: () => number | undefined;
        }) => (
          <Text>
            {centsToDollars(
              props.row.original.is_bonus
                ? props.row.original.bonus_stake
                : props.row.original.stake ?? 0
            ).replace(/\.00$/, '')}
            {props?.row.original.is_bonus && (
              <BonusBetFlag>{ColumnContent.Bonus}</BonusBetFlag>
            )}
          </Text>
        ),
      },
      {
        accessorKey: 'odds',
        header: ColumnTitles.Odds,
        cell: (props: {
          row: {
            original: {
              odds: number;
              event_subtitle?: string;
              promo_tokens: TPromoToken[];
              price_type?:
                | 'starting'
                | 'fixed'
                | 'tote_single_mid'
                | 'tote_single_best';
            };
          };
          getValue: () => number | undefined;
        }) => {
          const subtitle =
            props.row.original.event_subtitle?.toLocaleLowerCase();

          if (subtitle === 'exotics') {
            return <FormattedMessage id="generic.na" />;
          }

          const odds = props.getValue();
          const priceType = props.row.original.price_type;
          const isSPBet = props.row.original.price_type === 'starting';
          const oddsBoost = props.row.original.promo_tokens?.find(
            ({ token_type }) => token_type === 'odds-boost'
          );

          if (odds || priceType === 'starting') {
            return (
              <Flex flexDirection="row">
                {odds?.toFixed(2)}
                {!!oddsBoost && (
                  <Text
                    whiteSpace="nowrap"
                    opacity="0.8"
                    textDecoration="line-through"
                    ml={1}
                  >
                    {oddsBoost.original_odds?.toFixed(2)}
                  </Text>
                )}
                {isSPBet && (
                  <Badge colorScheme="blue">
                    <FormattedMessage id="generic.startingPriceAcronym" />
                  </Badge>
                )}
                {!!oddsBoost && <OddsBoostTooltip />}
              </Flex>
            );
          }

          if (priceType === 'tote_single_mid')
            return <Badge colorScheme="blue">MD</Badge>;

          if (priceType === 'tote_single_best')
            return <Badge colorScheme="blue">BT</Badge>;

          return <FormattedMessage id="generic.na" />;
        },
      },

      {
        accessorKey: 'return',
        header: ColumnTitles.PotentialAndActualReturns,
        cell: (props: {
          row: {
            original: {
              payout: number;
              status: EBetStatus;
              exotic_selections: TExoticCombo[];
              stake: number;
              bonus_stake: number;
              odds: number;
              is_bonus: boolean;
              price_type?: TPriceType;
              cashout_value?: number;
              is_manual_approval: boolean;
              promo_tokens: TPromoToken[];
              is_won: boolean;
              promotions: TPromotion[];
              bet_type: string;
            };
          };
          getValue: () => number | undefined;
        }) => {
          if (
            props?.row?.original?.status === EBetStatus.Voided ||
            props?.row?.original?.status === EBetStatus.Cancelled
          ) {
            return <BetStatusBadge status={props?.row?.original?.status} />;
          }

          const data = props?.row?.original;
          const isExoticBet = !!data?.exotic_selections;
          const priceType = props.row.original.price_type;
          const isSPBet = props.row.original.price_type === 'starting';
          const oddsBoost = props.row.original.promo_tokens?.find(
            ({ token_type }) => token_type === 'odds-boost'
          );

          const isMysteryBet = priceType === 'mystery_bet';
          const hasRollover =
            priceType === 'mystery_bet' &&
            props.row.original.bet_type.toLowerCase() == 'multi';

          const mbOdds = hasRollover
            ? (data?.is_bonus ? data?.odds - 1 : data?.odds) * data?.odds
            : data?.is_bonus
            ? data?.odds - 1
            : data?.odds;

          if (isMysteryBet)
            return centsToDollars(
              data?.is_bonus ? mbOdds * data?.bonus_stake : mbOdds * data?.stake
            );

          const renderPotentialReturn = (odds: number) => {
            if (
              isExoticBet ||
              (isSPBet && props.row.original.status === EBetStatus.Pending) ||
              priceType === 'tote_single_mid' ||
              priceType === 'tote_single_best'
            ) {
              return <FormattedMessage id="generic.na" />;
            }

            const potentialReturn = data.is_bonus
              ? data.bonus_stake * odds - data.bonus_stake
              : data.stake * odds;

            return centsToDollars(potentialReturn);
          };

          return (
            <Text whiteSpace="nowrap">
              <>
                <Flex>
                  {renderPotentialReturn(data.odds)}
                  {' / '}
                  {props.row.original.status === EBetStatus.Pending ? (
                    <BetStatusBadge
                      status={props?.row.original.status ?? undefined}
                    />
                  ) : (
                    <BetReturnText
                      as="span"
                      status={props.row.original?.status}
                      payout={props.row.original?.payout}
                    />
                  )}
                  {props.row.original.is_manual_approval && (
                    <ManualApprovalTooltip />
                  )}
                </Flex>
                {data?.cashout_value && (
                  <Text>
                    <Badge
                      variant="solid"
                      bg="origin.500"
                      borderRadius="md"
                      textAlign="center"
                      my="1"
                      px="2"
                      mr="2"
                      w="fill-content"
                    >
                      Cash out
                    </Badge>{' '}
                    {centsToDollars(data.cashout_value, true)}
                  </Text>
                )}

                {!!data?.promotions?.some((promo) => !!promo.bonus_amount) &&
                  data.status === EBetStatus.Settled && (
                    <Text>
                      <Badge
                        variant="solid"
                        bg="#333"
                        borderRadius="md"
                        textAlign="center"
                        my="1"
                        px="2"
                        mr="2"
                        w="fill-content"
                      >
                        Bonus
                      </Badge>{' '}
                      {centsToDollars(data.promotions[0].bonus_amount, true)}
                    </Text>
                  )}
              </>
              <Flex>
                {oddsBoost ? (
                  <Text
                    whiteSpace="nowrap"
                    opacity="0.8"
                    textDecoration="line-through"
                  >
                    <>
                      {renderPotentialReturn(oddsBoost.original_odds)}
                      {props.row.original.status === EBetStatus.Pending ? (
                        <></>
                      ) : (
                        <>
                          {' / '}
                          <BetReturnText
                            as="span"
                            status={props.row.original?.status}
                            payout={
                              data?.is_won
                                ? oddsBoost.original_odds * data.stake
                                : 0
                            }
                            opacity="0.8"
                            textDecoration="line-through"
                          />
                        </>
                      )}
                    </>
                  </Text>
                ) : undefined}
              </Flex>
            </Text>
          );
        },
      },
      {
        accessorKey: 'settings',
        header: '',
        cell: (props: {
          row: { original: { stake: number; status: EBetStatus } };
          getValue: () => number | undefined;
        }) => (
          <BetsTablePopover
            rows={[
              {
                onClick: () => showVoidBetFromBet(props.row.original),
                title: BetsTablePopoverStrings.Void,
                disabled: props?.row.original?.status === EBetStatus.Voided,
                icon: Refund,
              },
              {
                onClick: () => dispatch(setModalSettleBet(props.row.original)),
                title: BetsTablePopoverStrings.SettleBet,
                disabled: false,
                icon: FilePaper,
              },
            ]}
          />
        ),
      },
    ],
    [
      ColumnTitles.Event,
      ColumnTitles.Selection,
      ColumnTitles.Market,
      ColumnTitles.Stake,
      ColumnTitles.Odds,
      ColumnTitles.PotentialAndActualReturns,
      location,
      dispatch,
      SelectionStrings.Legs,
      SelectionStrings.Sport,
      SelectionStrings.Mixed,
      SelectionStrings.Racing,
      ColumnContent.Bonus,
      BetsTablePopoverStrings.Void,
      BetsTablePopoverStrings.SettleBet,
      showVoidBetFromBet,
      navigate,
    ]
  );

  return columns;
};
