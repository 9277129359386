import React from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';
import { ExoticsResultsTable } from './ExoticResultsTable/ExoticResultsTable';
import { useAppSelector } from '../../../../../../../common/hooks/useRedux';
import BetsForProposition from '../../../../../components/BetsForProposition';
import { getStrings } from '../../../../../../../common/utils';
import { EEventType } from '../../../Services/RaceDetails.types';

export default function MarketExotics() {
  const { raceData, raceExoticResults, raceResultsLoading } = useAppSelector(
    (state) => state.raceDetails
  );

  const hasExoticResults = !!raceExoticResults?.length;

  const [
    {
      TradeManagerPage: { Common },
      Generic,
    },
  ] = getStrings();

  return (
    <VStack alignItems="left" spacing="5">
      {hasExoticResults && (
        <Box>
          <Text pl="5" fontSize="xl" color="brand.500">
            {Common.RaceResults}
          </Text>

          <ExoticsResultsTable
            results={raceExoticResults}
            resultsLoading={raceResultsLoading}
          />
        </Box>
      )}

      <Box>
        <Text pl="5" fontSize="xl" color="brand.500">
          {Generic.AllBets}
        </Text>

        <BetsForProposition raceId={raceData.race_id} type={EEventType.Race} />
      </Box>
    </VStack>
  );
}
