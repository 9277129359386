import React from 'react';
import { BadgeProps } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCountdown } from '../../hooks/useCountdown';
import { BadgeCountdown, CounterWrapper, DateText } from './Countdown.styles';

export type TCountdown = {
  eventTime: string;
  dateFormat?: string;
} & BadgeProps;

export default function Countdown({
  eventTime,
  dateFormat,
  ...rest
}: TCountdown) {
  const { minutesRemaining, secondsRemaining, showCountdown } =
    useCountdown(eventTime);

  const state = (() => {
    if (!showCountdown) return 'prior';
    if (minutesRemaining <= 4) return 'ended';
    return 'inprogress';
  })();

  return (
    <CounterWrapper>
      {showCountdown && (
        <BadgeCountdown state={state} className={`state-${state}`} {...rest}>
          {(() => {
            if (minutesRemaining > 4 || minutesRemaining <= -1) {
              return `${minutesRemaining}m`;
            }

            if (!!minutesRemaining && minutesRemaining <= 4) {
              return `${minutesRemaining}m ${secondsRemaining}s`;
            }

            return `${secondsRemaining}s`;
          })()}
        </BadgeCountdown>
      )}

      <DateText>
        {dayjs(eventTime).format(dateFormat || 'HH:mmA DD/MM')}
      </DateText>
    </CounterWrapper>
  );
}
