import React from 'react';
import { useCheckbox, Text } from '@chakra-ui/react';
import { BCLabel } from './Checkbox.styles';
import { CSSObject } from '@emotion/react';

type CheckboxProps = {
  onChange: () => void;
  value: string;
  name?: string;
  isChecked: boolean;
  icon?: React.ReactNode;
  withLabel?: boolean;
  text?: string;
  isDisabled?: boolean;
  checkboxWrapper?: CSSObject;
  error?: boolean;
};

function getBorderColor(
  isChecked: boolean,
  isDisabled?: boolean,
  error?: boolean
) {
  if (error) {
    return 'red';
  } else if (isChecked && !isDisabled) {
    return '#009CFF';
  } else if (isDisabled && isChecked) {
    return '#009CFF';
  } else {
    return '#C7C7C7';
  }
}

export default function Checkbox({
  value,
  onChange,
  name,
  isChecked,
  icon,
  withLabel = true,
  text,
  isDisabled,
  checkboxWrapper,
  error = false,
}: CheckboxProps) {
  const { getInputProps, getLabelProps, htmlProps } = useCheckbox({
    isChecked,
    isDisabled,
    onChange,
  });

  return (
    <BCLabel
      sx={{
        ...checkboxWrapper,

        gridColumnGap: withLabel && 2,
        boxShadow: isChecked && '2xl',
        cursor: !isDisabled ? 'pointer' : 'not-allowed',
        borderColor: getBorderColor(isChecked, isDisabled, error),
        bg: isDisabled ? 'gray.200' : 'white',
      }}
      {...htmlProps}
      onChange={onChange}
    >
      <input
        name={name}
        disabled={isDisabled}
        value={value}
        checked={isChecked}
        type="checkbox"
        {...getInputProps()}
      />
      {icon && icon}

      {withLabel && (
        <Text color={isChecked ? '#009CFF' : '#666666'} {...getLabelProps()}>
          {text ? text : value}
        </Text>
      )}
    </BCLabel>
  );
}
