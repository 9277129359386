import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';

import { getStrings } from '../../../../../../../../common/utils';

import { TGlobalExposureLimits } from '../../types';
import { useExposureLimit } from './services/ExposureLimits.hooks';
import { globalExposureLimitsValidation } from './services/ExposureLimits.config';

type TFormikHOC = {
  children: ReactNode;
};

export default function ExposureLimitsFormikHOC({ children }: TFormikHOC) {
  const { globalExposureLimits, updateExposureLimits } = useExposureLimit();
  const [
    {
      Generic,
      SettingsPage: {
        Limits: { ExposureLimits: LocalisedStrings },
      },
    },
  ] = getStrings();

  return (
    <Formik<TGlobalExposureLimits>
      initialValues={globalExposureLimits}
      enableReinitialize
      validateOnChange={false}
      validationSchema={globalExposureLimitsValidation}
      onSubmit={(limits, { setSubmitting }) => {
        try {
          setSubmitting(true);
          updateExposureLimits(limits);

          toast.success(LocalisedStrings.updated);
        } catch (e) {
          toast.error(Generic.Error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
