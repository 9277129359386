import React from 'react';
import {
  useQueryOfferings,
  useMutationOfferings,
} from '@/api/settings/system/offerings/offerings.hooks';
import useErrorHandler from '@/hooks/useErrorHandler';
import {
  Button,
  Flex,
  useToast,
  FormControl,
  Switch,
  FormLabel,
} from '@chakra-ui/react';
import { Formik, Form, FormikHelpers } from 'formik';
import { TOfferings } from '@/api/settings/system/offerings/offerings.types';

type OddsBoostProps = {
  handleSubmit: (
    values: OddsBoostFormProps,
    { setSubmitting }: FormikHelpers<OddsBoostFormProps>
  ) => void;
  data: TOfferings | undefined;
  isLoading: boolean;
};

type OddsBoostFormProps = {
  oddsboost_enabled?: boolean;
};

export const OddsBoostContainer = () => {
  const { handleSubmit, data, isLoading } = useOddsBoost();
  return (
    <OddsBoost
      handleSubmit={handleSubmit}
      data={data?.data}
      isLoading={isLoading}
    />
  );
};

const OddsBoost = ({ handleSubmit, data, isLoading }: OddsBoostProps) => {
  return (
    <Flex borderBottom="1px" borderColor="gray.100" px="6">
      <Formik<OddsBoostFormProps>
        initialValues={{
          oddsboost_enabled: data?.oddsboost_enabled,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, dirty, isSubmitting }) => (
          <Form>
            <Flex py="12" w="full" gap="2">
              <FormControl
                isDisabled={isLoading}
                alignItems="center"
                display="flex"
                px="8"
                w="auto"
                bg="gray.100"
                borderRadius="sm"
              >
                <FormLabel mb="0">Odds Boost Enabled</FormLabel>
                <Switch
                  isChecked={values.oddsboost_enabled}
                  onChange={(e) => {
                    setFieldValue('oddsboost_enabled', e.target.checked);
                  }}
                  _disabled={{
                    '.chakra-switch__track': { cursor: 'wait' },
                  }}
                />
              </FormControl>{' '}
              {dirty && (
                <Button
                  type="submit"
                  isDisabled={!dirty || isSubmitting}
                  alignSelf="end"
                >
                  Save
                </Button>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

const useOddsBoost = () => {
  const onError = useErrorHandler();
  const mutation = useMutationOfferings();
  const toast = useToast();
  const { data, refetch, isLoading } = useQueryOfferings({
    refetchOnWindowFocus: false,
  });

  const handler = (values: OddsBoostFormProps) => {
    mutation.mutate(
      {
        ...(data?.data ?? {}),
        oddsboost_enabled: values.oddsboost_enabled,
      },
      {
        onError,
        onSuccess: async () => {
          await refetch();
          toast({
            title: 'Successfully updated!',
            status: 'success',
            position: 'top',
            duration: 9_000,
          });
        },
      }
    );
  };

  const handleSubmit = (
    values: OddsBoostFormProps,
    { setSubmitting }: FormikHelpers<OddsBoostFormProps>
  ) => {
    handler(values);
    setSubmitting(false);
  };

  return {
    handleSubmit,
    data,
    isLoading,
  };
};
