import api from '../../../api';
import { TRunnerMarginsBody } from './runnerMargins.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const runnerMarginsURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/runner-margins`;

// Post: Runner Margins
export const mutateRunnerMargins = (body: TRunnerMarginsBody) =>
  api.post<TRunnerMarginsBody>(runnerMarginsURL, body).then((res) => res.data);
