import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { keys } from '../api.keys';
import {
  deletePunterFlags,
  mutatePunterAffiliate,
  mutatePunterDetails,
  mutatePunterFlags,
  queryPunterProfile,
} from './punterProfile';
import {
  TPunterProfileParams,
  TPunterProfile,
  TPunterFlags,
  PunterAffiliateProps,
} from './punterProfile.types';

type TQueryHook = {
  key?: string[];
  options?: UseQueryOptions<TPunterProfile>;
  params: TPunterProfileParams;
};

export const useQueryPunterProfile = ({
  key = [],
  options = {},
  params,
}: TQueryHook) => {
  const { punterId } = params;

  const query = useQuery(
    [keys.punterProfile, !!punterId, ...key],
    () => queryPunterProfile(params),
    {
      enabled: !!punterId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};

export const useMutationPunterFlags = (
  options?: UseMutationOptions<unknown, unknown, TPunterFlags>
) => useMutation((data) => mutatePunterFlags(data), options);

export const useDeletionPunterFlags = (
  options?: UseMutationOptions<unknown, unknown, TPunterFlags>
) => useMutation((data) => deletePunterFlags({ data }), options);

export const useMutationPunterProfile = (
  options?: UseMutationOptions<unknown, unknown, TPunterProfile>
) => useMutation((data) => mutatePunterDetails(data), options);

export const useMutationPunterAffiliate = (
  options?: UseMutationOptions<unknown, unknown, PunterAffiliateProps>
) => useMutation((data) => mutatePunterAffiliate(data), options);
