import { usePathPayload } from '@/features/punters/pages/Punter/services/Punter.utils';
import { Note } from '@styled-icons/boxicons-solid/Note';
import { ActivityOutline } from '@styled-icons/evaicons-outline/ActivityOutline';
import { User } from '@styled-icons/boxicons-regular/User';
import { TrafficBarrier } from '@styled-icons/boxicons-solid/TrafficBarrier';
import { ClockHistory } from '@styled-icons/bootstrap/ClockHistory';
import { Gift } from '@styled-icons/bootstrap/Gift';
import { Bank } from '@styled-icons/bootstrap/Bank';
import { FlagCheckered } from '@styled-icons/fa-solid/FlagCheckered';
import { Payment } from '@styled-icons/fluentui-system-regular/Payment';
import { StackedBarChart } from '@styled-icons/material-twotone/StackedBarChart';

export const usePunterTabs = () => {
  const { pathPayload } = usePathPayload();

  const tabs = [
    {
      to: 'bets',
      text: 'Bets',
      icon: StackedBarChart,
      dataCy: 'punterProfileBetsLink',
    },
    {
      to: 'payments',
      text: 'Payments',
      icon: Payment,
      dataCy: 'punterProfilePaymentsLink',
    },
    {
      to: 'deposit',
      text: 'Deposits',
      icon: Bank,
      dataCy: 'punterProfileDepositsLink',
    },
    {
      to: 'incentives?incentives=received',
      text: 'Promos',
      icon: Gift,
      dataCy: 'punterProfilePromosLink',
    },
    {
      to: 'limits',
      text: 'Limits',
      icon: TrafficBarrier,
      dataCy: 'punterProfileLimitsLink',
    },
    {
      to: 'notes',
      text: 'Notes',
      icon: Note,
      dataCy: 'punterProfileNotesLink',
    },
    {
      to: 'profile',
      text: 'Profile',
      icon: User,
      dataCy: 'punterProfileProfileLink',
    },
    {
      to: 'activity-log',
      text: 'Activity Log',
      icon: ActivityOutline,
      dataCy: 'punterProfileActivityLogLink',
    },
    {
      to: 'transactions',
      text: 'Transactions',
      icon: ClockHistory,
      dataCy: 'punterProfileTransactionsLink',
    },
    {
      to: 'offerings',
      text: 'Offerings',
      icon: FlagCheckered,
      dataCy: 'punterProfileOfferingsLink',
    },
  ];

  const updatedTabs = tabs.map((tab) => {
    return {
      ...tab,
      to: pathPayload(tab.to).pathname,
    };
  });

  return { updatedTabs };
};
