import { keys } from '@/api/api.keys';
import { AppThunkDispatch } from '@/app/types';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { ENewRelicActions } from '@/constants/newRelicActions';
import { recordUserAction } from '@/features/settings/pages/Settings/actions';
import {
  ACTIVITY_LOG_SUMMARY,
  BOOKIE_ACTIVITY_LOG_TYPE,
} from '@/lib/Constants';
import { getAuth } from 'firebase/auth';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useMenu = () => {
  const navigate = useNavigate();

  const dispatch: AppThunkDispatch = useDispatch();
  const client = useQueryClient();
  const newRelicLog = useNewRelic();

  const handleLogout = async () => {
    // Action recorded before logout as endpoint requires auth
    await dispatch(
      recordUserAction({
        action_type: BOOKIE_ACTIVITY_LOG_TYPE.LOGOUT,
        action_summary: ACTIVITY_LOG_SUMMARY[BOOKIE_ACTIVITY_LOG_TYPE.LOGOUT],
      })
    );
    const auth = getAuth();
    await auth.signOut();
    await client.invalidateQueries([keys.firebaseAuth]);
    newRelicLog(ENewRelicActions.LogOut);
  };

  return { handleLogout, navigate };
};
