import { Activity } from '@styled-icons/evaicons-solid/Activity';
import { LocalOffer } from '@styled-icons/material-outlined/LocalOffer';
import { LogOutOutline } from '@styled-icons/evaicons-outline/LogOutOutline';
import { User } from '@styled-icons/feather/User';
import { Settings } from '@styled-icons/feather/Settings';
import { StyledIcon } from 'styled-icons/types';

type TMenu = {
  name: string;
  link?: string;
  icon?: StyledIcon;
};

export const links: TMenu[] = [
  { name: 'menu.profile', link: '/settings/your-profile', icon: User },
  {
    name: 'menu.offerings',
    link: '/settings/offerings/offerings',
    icon: LocalOffer,
  },
  { name: 'menu.systemSetup', link: '/settings/system-setup', icon: Settings },
  { name: 'menu.activityLog', link: '/settings/activity-log', icon: Activity },
  { name: 'menu.logout', icon: LogOutOutline },
];
