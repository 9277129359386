import React from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { StartTimeCalculator } from '../../../../../../common/components';
import {
  centsToDollars,
  getIconAssetPath,
  getStrings,
} from '../../../../../../common/utils';
import { EGeneralStatus, TPromotion } from '../../../../../../lib/DBModels';
import { StatusLight } from '../../../../../../common/components/StatusLight/StatusLight';
import IconSvg from '@/components/IconSvg/IconSvg';
import { useNavigate } from 'react-router-dom';
import GoingInPlayBadge from '@/common/components/InPlay/GoingInPlayBadge';
import InPlayBadge from '@/common/components/InPlay/InPlayBadge';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { isSweeps } from '@/configs/brands.config';

export const useColumns = () => {
  const navigate = useNavigate();
  const [
    {
      TradeManagerPage: { Common },
    },
  ] = getStrings();

  const columns = [
    {
      accessorKey: 'event_title',
      header: Common.Event,
      omit: true,
      sortable: false,
      cell: (props: {
        row: {
          original: {
            event_icon: string;
            event_title: string;
            event_subtitle: string;
            event_status: string;
            event_id: string;
            event_type: string;
            start_time: string;
            event_start_time: string;
            promotions?: TPromotion[];
            live_betting_expected: boolean;
            in_play: boolean;
          };
        };
        getValue: () => string | undefined;
      }) => {
        const promotions = props.row.original?.promotions?.filter(
          (promotion) => promotion.type === 'money_back'
        );
        const getInPlayBadge = () => {
          if (props.row.original?.in_play) return <InPlayBadge />;
          if (props.row.original.live_betting_expected)
            return <GoingInPlayBadge />;
          return null;
        };

        return (
          <Box>
            <Flex alignItems="center" mb="1">
              <StatusLight
                status={props.row.original?.event_status as EGeneralStatus}
                size="md"
              />
              <Box boxSize="9" mx="3">
                <IconSvg
                  name={getIconAssetPath(
                    'sports',
                    props.row.original?.event_icon ?? ''
                  )}
                  sx={{ boxSize: '8' }}
                />
              </Box>
              <Flex alignItems="start" flexDirection="column" gap="2">
                <Flex flexDir="row" gap="4">
                  {!!props.row.original?.event_status && (
                    <StartTimeCalculator
                      status={
                        props.row.original?.event_status as EGeneralStatus
                      }
                      date={props.row.original?.event_start_time}
                    />
                  )}
                  {FEATURE_FLAGS.IS_IN_PLAY_ENABLED && getInPlayBadge()}

                  <Flex flexDir="row" gap="2">
                    {!!promotions &&
                      promotions.map((promotion) => (
                        <Box
                          key={promotion.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/promo-manager/money-back/promotion?id=${promotion.id}}`
                            );
                          }}
                        >
                          <IconSvg
                            name={getIconAssetPath('sports', 'money-back')}
                            sx={{ boxSize: '6', cursor: 'pointer' }}
                          />
                        </Box>
                      ))}
                  </Flex>
                </Flex>
                <Flex>
                  <Text fontWeight="bold" mr="1">
                    {props.row.original?.event_subtitle ?? ''}
                  </Text>
                  -<Text ml="1"> {props.row.original?.event_title ?? ''} </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        );
      },
    },
    {
      accessorKey: 'total_bets',
      header: Common.PlaceBets,
      cell: (props: { getValue: () => number | undefined }) => (
        <Tooltip
          hasArrow
          label={
            FEATURE_FLAGS.IS_IN_PLAY_ENABLED && Number(props.getValue()) > 0 ? (
              <>
                <Text>Pre-Play: {props.getValue()}</Text>
                {/* TODO: Add in play placed bets once it's returned */}
                <Text>In-Play: 0</Text>
              </>
            ) : (
              ''
            )
          }
        >
          <Text fontSize="sm" display="inline-block">
            {props.getValue()}
          </Text>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'cash_out_turnover',
      header: 'Cash Out Turnover',
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
      isHidden: isSweeps,
    },
    {
      accessorKey: 'turnover',
      header: Common.Turnover,
      cell: (props: { getValue: () => number | undefined }) => (
        <Tooltip
          hasArrow
          label={
            FEATURE_FLAGS.IS_IN_PLAY_ENABLED && Number(props.getValue()) > 0 ? (
              <>
                <Text>Pre-Play: {centsToDollars(props.getValue())}</Text>
                {/* TODO: Add in play turnover once it's returned */}
                <Text>In-Play: {centsToDollars(0)}</Text>
              </>
            ) : (
              ''
            )
          }
        >
          <Text fontSize="sm" display="inline-block">
            {centsToDollars(props.getValue(), true)}
          </Text>
        </Tooltip>
      ),
    },
    {
      accessorKey: 'biggest_bet',
      header: Common.BiggestBet,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
    },
    {
      accessorKey: 'exposure',
      header: Common.Exposure,
      cell: (props: { getValue: () => number | undefined }) => (
        <Text fontSize="sm">{centsToDollars(props.getValue(), true)}</Text>
      ),
    },
  ];

  return columns;
};
