import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isSweeps } from '@/configs/brands.config';

const renderHeader = () => {
  if (isSweeps) {
    return (
      <>
        <Thead>
          <Tr>
            <Th textTransform="none">punter_id</Th>
            <Th textTransform="none" isNumeric>
              gems_amount
            </Th>
            <Th textTransform="none" isNumeric>
              bc_cash_amount
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>7f2bfa01&hellip;</Td>
            <Td isNumeric>100</Td>
            <Td isNumeric>0</Td>
          </Tr>
          <Tr>
            <Td>330a6c89&hellip;</Td>
            <Td isNumeric>0</Td>
            <Td isNumeric>200</Td>
          </Tr>
          <Tr>
            <Td>c4c56637&hellip;</Td>
            <Td isNumeric>500</Td>
            <Td isNumeric>300</Td>
          </Tr>
        </Tbody>
      </>
    );
  }
  return (
    <>
      <Thead>
        <Tr>
          <Th textTransform="none">punter_id</Th>
          <Th textTransform="none" isNumeric>
            amount
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>7f2bfa01&hellip;</Td>
          <Td isNumeric>100</Td>
        </Tr>
        <Tr>
          <Td>330a6c89&hellip;</Td>
          <Td isNumeric>200</Td>
        </Tr>
      </Tbody>
    </>
  );
};

export const ExampleCSVBonusBetTable = () => {
  return (
    <TableContainer>
      <Table size="sm">
        <VisuallyHidden as={TableCaption}>
          <FormattedMessage id="promomanagerpage.examplecsvtablecaption" />
        </VisuallyHidden>
        {renderHeader()}
      </Table>
    </TableContainer>
  );
};
