import { useCallback, useEffect, useRef, useState } from 'react';
import { EUpdateActions } from '../UpdateableMarginControls.types';

type TUpdateMarginControlsHook = {
  value: number;
  differenceValue: number;
  marginType: 'global' | 'single';
  onTickUp: (prevValue: number, newValue: number) => void;
  tickUpDisabled: boolean;
  onTickDown: (prevValue: number, newValue: number) => void;
  tickDownDisabled: boolean;
  isSelected: boolean;
};

export const useUpdateMarginControls = ({
  tickDownDisabled,
  tickUpDisabled,
  onTickUp,
  onTickDown,
  marginType,
  differenceValue,
  value,
  isSelected,
}: TUpdateMarginControlsHook) => {
  /* Keep track of current ticks pos/neg from 0 */
  const [newValue, setNewValue] = useState(0);

  /* A ref to keep track of a mouse or key hold down for a quicker update */
  const intervalRef = useRef<number | null>(null);

  const increment = useCallback(() => {
    if (tickUpDisabled) return;
    setNewValue((prevValue) => {
      const newVal = prevValue + 1;
      onTickUp?.(prevValue, newVal);
      return newVal;
    });
  }, [onTickUp, tickUpDisabled]);

  const decrement = useCallback(() => {
    if (tickDownDisabled) return;
    setNewValue((prevValue) => {
      const newVal = prevValue - 1;
      onTickDown?.(prevValue, newVal);
      return newVal;
    });
  }, [onTickDown, tickDownDisabled]);

  const displayValue = (() => {
    if (marginType === 'global') return `${differenceValue.toFixed(2)}%`;
    if (newValue > 0) return `+${newValue}`;
    return newValue;
  })();

  const handleMouseDown = useCallback(
    (action: EUpdateActions) => {
      if (tickUpDisabled) return;

      /* Call increment immediately when the button is first pressed */
      if (action === EUpdateActions.Increment) {
        increment();
      } else {
        decrement();
      }

      /* Set up an interval to repeatedly call increment */
      const id = window.setInterval(
        action === EUpdateActions.Increment ? increment : decrement,
        200
      );
      intervalRef.current = id;
    },
    [decrement, increment, tickUpDisabled]
  );

  const handleMouseUp = useCallback(() => {
    /* Clear the interval when mouse click or key is released */
    if (intervalRef.current === null) return;
    window.clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);

  /* useEffect that keeps the value in sync with newValue */
  useEffect(() => {
    if (value !== undefined) setNewValue(value);
  }, [value]);

  /* useEffect that manages event listeners for quick updates with mouse click or key hold down */
  useEffect(() => {
    const handleKeyDown = (keyboardEvent: KeyboardEvent) => {
      if (isSelected && !intervalRef.current) {
        if (keyboardEvent.key === 'ArrowUp') {
          handleMouseDown(EUpdateActions.Increment);
        } else if (keyboardEvent.key === 'ArrowDown') {
          handleMouseDown(EUpdateActions.Decrement);
        }
      }

      /* Prevent scrolling on up/down arrow key press for when margin modifiers are selected */
      const target = keyboardEvent.target as HTMLElement;

      if (
        (keyboardEvent.key === 'ArrowUp' ||
          keyboardEvent.key === 'ArrowDown') &&
        target.tagName === 'BODY'
      ) {
        keyboardEvent.preventDefault();
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (isSelected) {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          handleMouseUp();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleMouseDown, handleMouseUp, value, isSelected]);

  return { handleMouseUp, handleMouseDown, newValue, displayValue };
};
