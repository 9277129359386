import { useMemo } from 'react';
import { EPlacedBetStatesMinusPartial } from './tabs/Resulted/types';
import { TFilterOptions } from './tabs/Upcoming/Upcoming';

export const getPlacedBetStates = () => {
  const placedBetResults = Object.entries(EPlacedBetStatesMinusPartial)
    .map(([, value]) => String(value))
    .join(',');
  return placedBetResults;
};

const DOLLAR_TO_CENTS = {
  $100: 10000,
  $250: 25000,
  $500: 50000,
  $1000: 100000,
  $5000: 500000,
  $10000: 1000000,
};

export type TFilterOptionsParams = {
  min_stake?: number | undefined;
  min_exposure?: number | undefined;
  max_odds?: number | undefined;
  bet_type?: string | undefined;
  punter_category?: string[] | undefined;
  start_date?: string | undefined;
  end_date?: string | undefined;
};

export const useFormatParamsForFiltering = (params: TFilterOptions) =>
  useMemo(() => {
    return {
      ...(params.punterCategories
        ? { punter_category: params.punterCategories.map((cat) => cat) }
        : {}),
      ...(params.punterName !== '' ? { punter_name: params.punterName } : {}),
      ...(params.eventType !== 'All' ? { event_type: params.eventType } : {}),
      ...(params.currency !== 'All' ? { currency: params.currency } : {}),
      ...(params.betType !== 'All' ? { bet_type: params.betType } : {}),
      ...(params.startDate ? { start_date: params.startDate } : {}),
      ...(params.endDate ? { end_date: params.endDate } : {}),
      ...(params.maxOdds ? { max_odds: 1.2 } : {}),
      ...(params.liabilityOrStake === 'Liability' &&
      params.liabilityOrStakeValue !== 'All'
        ? {
            min_exposure:
              DOLLAR_TO_CENTS[
                params.liabilityOrStakeValue as keyof typeof DOLLAR_TO_CENTS
              ] ?? 0,
          }
        : {}),
      ...(params.liabilityOrStake === 'Stake' &&
      params.liabilityOrStakeValue !== 'All'
        ? {
            min_stake:
              DOLLAR_TO_CENTS[
                params.liabilityOrStakeValue as keyof typeof DOLLAR_TO_CENTS
              ] ?? 0,
          }
        : {}),
    };
  }, [params]);
