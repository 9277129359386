import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '@/api/api.keys';
import { queryPromoPuntersCSV } from './promoPuntersCSV';
import {
  TQueryPromoPuntersCSVParams,
  TQueryPromoPuntersCSVResponse,
} from './promoPuntersCSV.types';

export const useQueryPromoPuntersCSV = (
  params: TQueryPromoPuntersCSVParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryPromoPuntersCSVResponse>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(
    [keys.promoPuntersCSV, params],
    () => queryPromoPuntersCSV(params),
    options
  );
