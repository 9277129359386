import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Text } from '@chakra-ui/react';

import EventDateTimeExposureProps from './types';
import { EventDateTimeExposureContainer, DateTimeContainer } from './Styles';
import { StartTimeCalculator } from '../../../../common/components';

export const EventDateTimeExposure: FC<EventDateTimeExposureProps> = ({
  dateTime,
  status,
  isManualOverride = false,
  isSuspended = false,
  isProtestOverride = false,
  venueName,
  ...rest
}) => {
  if (!dateTime || !status) return null;

  return (
    <EventDateTimeExposureContainer {...rest}>
      <Text
        sx={{
          bg: 'origin.500',
          p: '2',
          borderRadius: '5px',
          fontSize: 'md',
          color: 'white',
          fontWeight: '500',
          h: 'fit-content',
          boxShadow: 'sm',
          mt: '2',
        }}
      >
        {venueName}
      </Text>
      <DateTimeContainer style={{ alignItems: 'baseline', marginBottom: '0' }}>
        <Text fontWeight="semibold">Date</Text>
        <Text>{dayjs(dateTime).format('DD/MM, hh:mma')}</Text>
      </DateTimeContainer>
      <DateTimeContainer style={{ alignItems: 'baseline', marginBottom: '0' }}>
        <Text fontWeight="semibold">Status</Text>
        <StartTimeCalculator
          status={status}
          date={dateTime}
          withStatus
          isManualOverride={isManualOverride}
          isSuspended={isSuspended}
          isProtestOverride={isProtestOverride}
          sxWrapper={{ display: 'flex', gap: '2' }}
        />
      </DateTimeContainer>
    </EventDateTimeExposureContainer>
  );
};
