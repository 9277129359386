import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import React, { ReactNode } from 'react';
import bookie from '../../../Theme';
import { globalStyles } from '../../../Theme/base/styles';

type TStyleSystem = {
  children: ReactNode;
};

export default function StyleSystem({ children }: TStyleSystem) {
  return (
    <ChakraProvider theme={bookie}>
      <Global styles={globalStyles} />
      {children}
    </ChakraProvider>
  );
}
