import styled from 'styled-components';
import { Button } from 'grommet';

interface DropdownItemProps {
  selected?: boolean;
}

export const DropdownItem = styled(Button)<DropdownItemProps>`
  background: ${({ theme, selected }) =>
    selected && theme.global.colors.primary};
  color: ${({ theme, selected }) =>
    selected && theme.global.colors.contentBackground};
  min-width: 250px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;
