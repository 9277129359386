/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useQuery, UseQueryOptions } from 'react-query';
import { TRaceDetails } from '../../../lib/DBModels';
import { keys } from '../../api.keys';
import { queryRaceDetails } from './raceDetails';
import { TRaceDetailsParams } from './raceDetails.types';

// Trade Manager - Race Details

type TQueryRaceDetailsHook = {
  params?: TRaceDetailsParams;
  key?: unknown[];
  options?: UseQueryOptions<TRaceDetails>;
};

export const useQueryRaceDetails = ({
  params = {},
  key = [],
  options = {},
}: TQueryRaceDetailsHook = {}) => {
  const query = useQuery(
    [keys.raceDetails, ...Object.values(params), ...key],
    () => queryRaceDetails(params),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(options as any),
    }
  );

  return query;
};
