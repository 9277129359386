import React from 'react';
import Form from './components/Form/Form';
import FormikHOC from './components/Form/FormikHOC';

export default function CMS() {
  return (
    <FormikHOC>
      <Form />
    </FormikHOC>
  );
}
