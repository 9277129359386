import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Collapse, Switch, Text } from '@chakra-ui/react';
import ScratchFormFields from '../ScratchFormFields/ScratchFormFields';
import {
  BoxCollapse,
  FlexWrapper,
  HStackSwitchGroup,
} from './ConfigRunnerRow.styles';
import Select from '@/common/components/FormElements/Select';
import { EGeneralStatus, TRunner } from '@/lib/DBModels';
import { FormikContextType } from 'formik';
import { TRaceDetailsFormValues } from '../../RaceDetailsTable.types';
import { TRunnerBody } from '@/api/tradeManager/raceDetails/runnerStatus/runnerStatus.types';

type TConfigRunnerRow = {
  formik: FormikContextType<TRaceDetailsFormValues>;
  apiModel: Record<string, TRunnerBody>;
  runner?: TRunner;
  show: boolean;
  setIsUpdateButtonEnabled: (value: boolean) => void;
};

export default function ConfigRunnerRow({
  formik,
  apiModel,
  runner,
  show,
  setIsUpdateButtonEnabled,
}: TConfigRunnerRow) {
  const { setFieldValue, values } = formik;
  const { status, allowedStatus, follow_feed, isSuspended } =
    values.runners[runner?.number ?? 0];

  const runnerId = runner?.race_runner_id ?? '';

  const getOrSetRunnerModel = () =>
    apiModel[runnerId] || (apiModel[runnerId] = { id: runnerId });

  const handleStatusChange = (status: string) => {
    setIsUpdateButtonEnabled(true);
    setFieldValue(`runners[${runner?.number}].status`, status);
    setFieldValue(`runners[${runner?.number}].follow_feed`, false);
    setFieldValue(`runners[${runner?.number}].dropdownOpen`, false);

    const runnerModel = getOrSetRunnerModel();
    delete runnerModel.lock_price;
    delete runnerModel.is_suspended;
    runnerModel.status = status;
    runnerModel.lock_status = true;

    if (status != EGeneralStatus.Scratched) delete runnerModel.scratch_info;
  };

  const handleFeedChange = () => {
    setIsUpdateButtonEnabled(true);
    const runnerModel = getOrSetRunnerModel();
    delete runnerModel.is_suspended;
    runnerModel.lock_status = follow_feed;
    runnerModel.lock_price = follow_feed;
    setFieldValue(`runners[${runner?.number}].follow_feed`, !follow_feed);
  };

  const handleSuspended = () => {
    setIsUpdateButtonEnabled(true);
    const runnerModel = getOrSetRunnerModel();
    runnerModel.lock_price = true;
    runnerModel.is_suspended = !isSuspended;
    if (!isSuspended) {
      setFieldValue(`runners[${runner?.number}].follow_feed`, false);
    }
    setFieldValue(`runners[${runner?.number}].isSuspended`, !isSuspended);
  };

  const isScratched = status === EGeneralStatus.Scratched;

  return (
    <Collapse
      in={show}
      style={{
        /* To prevent datepicker box cut off */
        overflow: 'visible',
      }}
    >
      <FlexWrapper p="2">
        <Select
          name="status-dropdown"
          value={status}
          onChange={(e) => handleStatusChange(e.target.value)}
          sxWrapper={{
            maxW: '170px',
          }}
          sx={{ bg: 'white', w: 'full', h: 'full', p: '3' }}
        >
          {allowedStatus.map((status: EGeneralStatus, idx: number) => (
            <option key={idx} value={status}>
              {status}
            </option>
          ))}
        </Select>
        <FlexWrapper>
          <HStackSwitchGroup>
            <Text>
              <FormattedMessage id="trademanagerpage.racedetails.configRunner.followFeed" />
            </Text>
            <Switch isChecked={follow_feed} onChange={handleFeedChange} />
          </HStackSwitchGroup>
        </FlexWrapper>
        <FlexWrapper>
          <HStackSwitchGroup>
            <Text>
              <FormattedMessage id="trademanagerpage.racedetails.configRunner.suspended" />
            </Text>
            <Switch isChecked={isSuspended} onChange={handleSuspended} />
          </HStackSwitchGroup>
        </FlexWrapper>

        <BoxCollapse
          as={Collapse}
          in={isScratched}
          /* To prevent datepicker box cut off */
          style={{
            overflow: 'visible',
          }}
        >
          <ScratchFormFields
            formik={formik}
            apiModel={apiModel}
            runnerId={runnerId}
            number={runner?.number ?? 0}
            updatedBy={runner?.scratched_by}
          />
        </BoxCollapse>
      </FlexWrapper>
    </Collapse>
  );
}
