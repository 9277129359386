import { EGeneralStatus } from '@/lib/DBModels';

export type TRunnerForm = {
  runner_number: number;
  race_runner_external_ref: string;
  runner_id: string;
  allowedStatus: EGeneralStatus[];
  status?: EGeneralStatus;
  winterStatus?: EGeneralStatus;
  follow_feed: boolean;
  isOpen: boolean;
  isScratched: boolean;
  isSuspended: boolean;
  winterIsSuspended: boolean;
  date: Date;
  time: string;
  win: string;
  p2: string;
  p3: string;
};

export type TRaceDetailsFormValues = { runners: Record<string, TRunnerForm> };

export enum ERunnerConfigureOption {
  Resettle = 'Resettle',
  DisplayOnly = 'DisplayOnly',
}
