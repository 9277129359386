import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETabs } from './components/ModalConfigure/ModalConfigure.types';

import { MatchDetailsState } from './types';

const initialState: MatchDetailsState = {
  isModalSettleOpen: true,
  modalSettleData: undefined,
  isConfigureMatchModalOpen: false,
  modalVoidData: undefined,
  modalSuspendData: undefined,
  modalConfigureData: {},
  isModalConfigureLoading: false,
  matchData: undefined,
  matchMarkets: [],
  selectedMarket: undefined,
  selectedMarketGroup: undefined,
  selectedMarketPropositions: [],
  selectedConfigTab: ETabs.Settlement,
};

const matchDetailsSlice = createSlice({
  name: 'matchDetails',
  initialState,
  reducers: {
    setSelectedConfigTab: (
      state,
      action: PayloadAction<MatchDetailsState['selectedConfigTab']>
    ) => ({
      ...state,
      selectedConfigTab: action.payload,
    }),
    setModalSettleData: (
      state,
      action: PayloadAction<MatchDetailsState['modalSettleData']>
    ) => ({
      ...state,
      modalSettleData: action.payload,
    }),
    setModalVoidData: (
      state,
      action: PayloadAction<MatchDetailsState['modalVoidData']>
    ) => ({
      ...state,
      modalVoidData: action.payload,
    }),
    setModalSuspendData: (
      state,
      action: PayloadAction<MatchDetailsState['modalSuspendData']>
    ) => ({
      ...state,
      modalSuspendData: action.payload,
    }),
    setModalConfigureData: (
      state,
      action: PayloadAction<MatchDetailsState['modalConfigureData']>
    ) => ({
      ...state,
      modalConfigureData: action.payload,
    }),
    setIsModalConfigureLoading: (
      state,
      action: PayloadAction<MatchDetailsState['isModalConfigureLoading']>
    ) => ({
      ...state,
      isModalConfigureLoading: action.payload,
    }),
    setIsConfigureMatchModalOpen: (
      state,
      action: PayloadAction<MatchDetailsState['isConfigureMatchModalOpen']>
    ) => ({
      ...state,
      isConfigureMatchModalOpen: action.payload,
    }),
    setMatchData: (
      state,
      action: PayloadAction<MatchDetailsState['matchData']>
    ) => ({
      ...state,
      matchData: action.payload,
    }),
    setSelectedMarket: (
      state,
      action: PayloadAction<MatchDetailsState['selectedMarket']>
    ) => ({
      ...state,
      selectedMarket: action.payload,
    }),
    setMatchMarkets: (
      state,
      action: PayloadAction<MatchDetailsState['matchMarkets']>
    ) => ({
      ...state,
      matchMarkets: action.payload,
    }),
    setSelectedMarketGroup: (
      state,
      action: PayloadAction<MatchDetailsState['selectedMarketGroup']>
    ) => ({
      ...state,
      selectedMarketGroup: action.payload,
    }),
    setSelectedMarketPropositions: (
      state,
      action: PayloadAction<MatchDetailsState['selectedMarketPropositions']>
    ) => ({
      ...state,
      selectedMarketPropositions: action.payload,
    }),
    clearSelectedMarketPropositions: (state) => ({
      ...state,
      selectedMarketPropositions: [],
    }),
  },
});

// Slice actions
export const {
  setSelectedConfigTab,
  setModalSettleData,
  setIsConfigureMatchModalOpen,
  setIsModalConfigureLoading,
  setModalVoidData,
  setModalConfigureData,
  setMatchData,
  setModalSuspendData,
  setMatchMarkets,
  setSelectedMarketGroup,
  setSelectedMarketPropositions,
  clearSelectedMarketPropositions,
  setSelectedMarket,
} = matchDetailsSlice.actions;

// Slice reducer
export default matchDetailsSlice.reducer;
