import styled from 'styled-components';

export const SaveButton = styled.button`
  height: 56px;
  width: 200px;
  line-height: 56px;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  align-items: flex-start;
  color: ${({ theme }) => theme.global.colors.primaryButtonText};
  ${({ disabled, theme }) =>
    `background-color: ${
      disabled
        ? theme.global.colors.lightButtonGrey
        : theme.global.colors.midBlue
    };`}
`;
