import {
  Button,
  ButtonProps,
  Center,
  CenterProps,
  chakra,
  ChakraComponent,
} from '@chakra-ui/react';

export const CenterPagination: ChakraComponent<'div', CenterProps> = chakra(
  Center,
  {
    baseStyle: {
      gap: '6',
      p: '2',
    },
  }
);

export const ButtonPage: ChakraComponent<'button', ButtonProps> = chakra(
  Button,
  {
    baseStyle: {
      bg: 'transparent',
      color: 'blackAlpha.900',

      _hover: {
        bg: 'gray.100',
      },

      _active: {
        bg: 'gray.100',
      },

      _disabled: {
        _hover: {
          bg: 'transparent',
        },

        _active: {
          bg: 'transparent',
        },
      },
    },
  }
);
