import { TLimit } from '../../../features/settings/pages/Settings/tabs/Limits/types';
import api from '../../api';

export const globalBetLimitsURL = '/bookie/settings/limits/global-bet-limit';

export const updateGlobalBetLimit = async (limit: TLimit) =>
  api.post(globalBetLimitsURL, limit).then((res) => res.data);

export const queryGlobalBetLimit = async () =>
  api.get<number>(globalBetLimitsURL).then((res) => res.data);
