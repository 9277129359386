import {
  TPunterWithdrawalRequestsPagedHistoryDTO,
  TPunterWithdrawalRequestsResponse,
} from '../../../../lib/DTO';
import { TPagedAPIInput } from '../../../../lib/api/types';
import { TWithdrawalHistoryExportParams } from '@/api/withdrawals/withdrawals.types';

export interface TBankState {
  withdrawalRequests: TPunterWithdrawalRequestsResponse;
  withdrawalRequestsHistory: TPunterWithdrawalRequestsPagedHistoryDTO;
  withdrawalRequestsLoading: boolean;
  withdrawalRequestsHistoryLoading: boolean;
}

export enum TPunterWithdrawalStatus {
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Processed = 'Processed',
  Error = 'Error',
}

export interface TPunterWithdrawalRequestsInput extends TPagedAPIInput {
  status?: TPunterWithdrawalStatus;
}

export interface TWithdrawalActionInput {
  withdrawal_ids: string[];
  status: TPunterWithdrawalStatus;
}

export interface TWithdrawalsDownloadInput {
  withdrawal_ids?: string[];
  format?: 'aba' | 'csv';
  exported?: boolean;
  download_all_filter?: TWithdrawalHistoryExportParams;
}
