import { ButtonExtendedProps } from 'grommet';
import React, { ReactNode } from 'react';
import { ButtonSpinner, StyledButton, TStyledButton } from '.';

type TButton = {
  children: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  type?: ButtonExtendedProps['type'];
  onClick?: ButtonExtendedProps['onClick'];
} & TStyledButton;

export default function Button({
  children,
  isLoading,
  disabled,
  ...rest
}: TButton) {
  return (
    <StyledButton isLoading={isLoading} disabled={disabled} {...rest}>
      {children}
      {isLoading && <ButtonSpinner color="black" />}
    </StyledButton>
  );
}
