import api from '../api';
import {
  TBulkBonusAward,
  TCSVUploadInput,
} from '../../views/PromoManager/types';
import { TBonusAwardsParams } from './bulkBonusAwards.types';

export const getBulkBonusAwardsEndpoint = '/bookie/promos/bonus/bonus-bet';

export const postBulkBonusAwardsEndpoint =
  '/bookie/promos/bonus-bets/bulk-bonus-award';

export const queryBulkBonusAwards = async (params: TBonusAwardsParams) =>
  api
    .get<TBulkBonusAward[]>(getBulkBonusAwardsEndpoint, { params })
    .then((res) => res.data);

export const addBulkBonusAward = async (bulkBonusAward: TCSVUploadInput) =>
  api.post(postBulkBonusAwardsEndpoint, bulkBonusAward).then((res) => res.data);
