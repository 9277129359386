import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateAddMarket } from './addMarket';
import { TAddMarketBody } from './addMarket.types';

export const useMutationAddMarket = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TAddMarketBody>,
    'mutationFn'
  >
) => useMutation(mutateAddMarket, options);
