import { useEffect, useState } from 'react';
import { useTheme } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { TBetsParams } from '@/api/regulator/bets/bets.types';
import { useQueryBets } from '@/api/regulator/bets/bets.hooks';
import {
  ERegulatorFilters,
  ERegulatorPathname,
  TRegulatorForm,
} from '../Regulator.types';
import { useLocation } from 'react-router-dom';
import { useQueryTransactions } from '@/api/regulator/transactions/transactions.hooks';
import { useQueryDownloadTransactions } from '@/api/regulator/dowloadTransactions/downloadTransactions.hooks';
import { useQueryDownloadBets } from '@/api/regulator/downloadBets/downloadBets.hooks';
import { downloadCsv } from './Regulator.utils';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { useNewRelic } from '@/common/hooks/useNewRelic';
import { ENewRelicActions } from '@/constants/newRelicActions';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const useRegulator = () => {
  const limit = 20;

  const theme = useTheme();

  const intl = useIntl();

  const location = useLocation();

  const pathname = location.pathname.split('/')[2];

  const [isDownloading, setIsDownloading] = useState(false);

  const [offset, setOffset] = useState(0);

  const [searchBy, setSearchBy] = useState(ERegulatorFilters.ClientName);

  const [params, setParams] = useState<TBetsParams>({});

  const newRelicLog = useNewRelic();

  useQueryDownloadBets({
    params: {
      ...params,
      limit,
      offset,
    },
    options: {
      enabled: pathname === ERegulatorPathname.Bets && isDownloading,
      onSuccess(csvData) {
        downloadCsv(csvData);
        setIsDownloading(false);
      },
      onError() {
        toast.error(
          intl.formatMessage({
            id: 'generic.error',
          })
        );
        setIsDownloading(false);
      },
    },
  });

  useQueryDownloadTransactions({
    params: {
      ...params,
      limit,
      offset,
    },
    options: {
      enabled: pathname === ERegulatorPathname.Transactions && isDownloading,
      onSuccess(csvData) {
        downloadCsv(csvData);
        setIsDownloading(false);
      },
      onError() {
        toast.error(
          intl.formatMessage({
            id: 'generic.error',
          })
        );
        setIsDownloading(false);
      },
    },
  });

  const { data: betsData, isLoading: betsLoading } = useQueryBets({
    params: {
      ...params,
      limit,
      offset,
    },
    options: {
      enabled:
        pathname === ERegulatorPathname.Bets &&
        !!(
          params.bet_id ||
          ((params.email || params.name) &&
            params.start_date &&
            params.end_date)
        ),
      refetchInterval: offset === 0 ? 60000 : 0, // polling every minute on first page only
      onSuccess: () =>
        newRelicLog(ENewRelicActions.BetHistory, {
          message: 'Betting history requested',
          ...params,
        }),
      onError: (e) =>
        newRelicLog(ENewRelicActions.BetHistory, {
          error: JSON.stringify(e),
          ...params,
        }),
    },
  });

  const { data: transactionData, isLoading: transactionsLoading } =
    useQueryTransactions({
      params: {
        ...params,
        limit,
        offset,
      },
      options: {
        retry: 0,
        enabled:
          pathname === ERegulatorPathname.Transactions &&
          !!(
            (params.email || params.name) &&
            params.start_date &&
            params.end_date
          ),
        refetchInterval: offset === 0 ? 60000 : 0, // polling every minute on first page only
      },
    });

  const isLastFetchForBets = (betsData?.length ?? 0) < limit;

  const isLastFetchForTransactions = (transactionData?.length ?? 0) < limit;

  type TDateRange = 'from' | 'to';
  const convertDate = (range: TDateRange, date: string) => {
    const method = range === 'from' ? 'startOf' : 'endOf';
    return dayjs(date)[method]('day').utc().format('YYYY-MM-DD HH:mm:ss');
  };

  const onSubmitSearch = (values: TRegulatorForm) => {
    setParams({
      ...(values.searchOption === ERegulatorFilters.Ticket && {
        bet_id: values.ticket_number,
      }),
      ...(values.searchOption === ERegulatorFilters.ClientName && {
        name: values.client_name,
      }),
      ...(values.searchOption === ERegulatorFilters.ClientEmail && {
        email: values.client_email,
      }),
      ...(values.date_from && {
        start_date: convertDate('from', values.date_from),
      }),
      ...(values.date_to && {
        end_date: convertDate('to', values.date_to),
      }),
    });
  };

  const clearFilter = () => {
    setParams({});
    setOffset(0);
  };

  const setSearchOption = (searchOption: ERegulatorFilters) => {
    setSearchBy(searchOption);
  };

  /* Clear filter every time bookie changes tab */
  useEffect(() => {
    clearFilter();
  }, [pathname]);

  return {
    betsData,
    transactionData,
    betsLoading,
    transactionsLoading,
    isLastFetchForBets,
    isLastFetchForTransactions,
    offset,
    theme,
    searchBy,
    isDownloading,
    setOffset,
    setIsDownloading,
    setSearchOption,
    onSubmitSearch,
  };
};
