import { DeepPartial } from '@reduxjs/toolkit';

export enum EResult {
  Winner = 'WINNER',
  Loser = 'LOSER',
}

export type TSettlePropositions = {
  proposition_id: string;
  result: EResult;
};

export type TSettleSportMarketPropositionsBody = DeepPartial<{
  market_id: string;
  expected_winners: number;
  propositions: TSettlePropositions[];
}>;
