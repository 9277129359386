import api from '@/api/api';
import { TRacePriceTypes } from './priceTypes.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const racePriceTypesURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/force-price-type-approval`;

export const mutateRacePriceTypes = (body: TRacePriceTypes) =>
  api.post<TRacePriceTypes>(racePriceTypesURL, body).then((res) => res.data);
