import loadable from '@loadable/component';
import { FC } from 'react';

const OfferingsTab = loadable(
  () =>
    import(
      '@/features/settings/pages/Settings/tabs/Offerings/tabs/Offerings/Offerings'
    )
) as FC;

const BetfairIntegration = loadable(
  () => import('@/features/settings/pages/Settings/tabs/BetfairIntegration')
) as FC;

const FeaturedEvents = loadable(
  () =>
    import(
      '@/features/settings/pages/Settings/tabs/Offerings/tabs/FeaturedEvents/FeaturedEvents'
    )
) as FC;

const SystemSetup = loadable(
  () => import('@/features/settings/pages/Settings/tabs/SystemSetup')
) as FC;

const AdminAccounts = loadable(
  () => import('@/features/settings/pages/Settings/tabs/AdminAccounts')
) as FC;

const YourProfile = loadable(
  () => import('@/features/settings/pages/Settings/tabs/YourProfile')
) as FC;

const ContactDetails = loadable(
  () => import('@/features/settings/pages/Settings/tabs/ContactDetails')
) as FC;

const SettingsActivtyLogs = loadable(
  () => import('@/features/settings/pages/Settings/tabs/ActivityLog')
) as FC;

const CMS = loadable(
  () => import('@/features/settings/pages/Settings/tabs/CMS/Cms')
) as FC;

export {
  OfferingsTab,
  BetfairIntegration,
  FeaturedEvents,
  SystemSetup,
  AdminAccounts,
  YourProfile,
  ContactDetails,
  SettingsActivtyLogs,
  CMS,
};
