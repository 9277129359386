import { ENV } from '@/lib/Constants';
import { rest } from 'msw';

export const betApprovalLimitsHandler = [
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/global-bet-limit`,
    async (req, res, ctx) => res(ctx.json(600000))
  ),
  rest.post(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/global-bet-limit`,
    (req, res, ctx) => res(ctx.status(200))
  ),
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/exposure-limit`,
    async (req, res, ctx) => res(ctx.json(600000))
  ),
  rest.put(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/exposure-limit`,
    (req, res, ctx) => res(ctx.status(200))
  ),
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/exotic-limit`,
    async (req, res, ctx) => res(ctx.json(600000))
  ),
  rest.put(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/exotic-limit`,
    (req, res, ctx) => res(ctx.status(200))
  ),
  rest.get(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/cross-game-multi`,
    async (req, res, ctx) => res(ctx.json(600000))
  ),
  rest.put(
    `${ENV.REACT_APP_API_URL}/bookie/settings/limits/cross-game-multi`,
    (req, res, ctx) => res(ctx.status(200))
  ),
];
