import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { mutateAddPromotion, mutateMedia, queryPromotions } from './promotions';
import {
  TAddPromoParams,
  TUploadMediaParams,
  TUploadMediaData,
  TData,
} from './promotions.types';
import { useHeader } from '@/common/ui/Header/services/Header.hooks';
import { keys } from '@/api/api.keys';

export const useMutationAddPromotion = (
  options?: UseMutationOptions<unknown, unknown, TAddPromoParams>
) => useMutation((data: TAddPromoParams) => mutateAddPromotion(data), options);

export const useMutationUploadMedia = (
  options?: UseMutationOptions<unknown, unknown, TUploadMediaData>
) => {
  const query = useMutation(
    (data: TUploadMediaParams) => mutateMedia(data),
    options
  );

  return query;
};

type TQueryPromotionHook = {
  key?: string[];
  options?: UseQueryOptions<TData[], string[]>;
};
export const useQueryPromotions = (hook: TQueryPromotionHook = {}) => {
  const { key = [], options = {} } = hook;

  const { platform } = useHeader();
  const query = useQuery<TData[], string[]>(
    [keys.promotions, ...key],
    () => queryPromotions(platform ?? ''),
    {
      ...options,
    }
  );
  return query;
};
