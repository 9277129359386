import React from 'react';
import {
  FormLabelProps,
  FormErrorMessageProps,
  TextProps,
  StyleProps,
} from '@chakra-ui/react';
import Creatable, { CreatableProps } from 'react-select/creatable';
import HOCs from '../HOCs';
import { GroupBase } from 'react-select';

export type TOptionType = {
  label: string;
  value: string;
  __isNew__?: boolean;
};

export type TSelectAdvancedProps = {
  name: string;
  isFormik?: boolean;
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: TextProps;
  sxWrapper?: StyleProps;
} & CreatableProps<TOptionType, false, GroupBase<TOptionType>>;

export default function Input({
  isFormik = true,
  name,
  label,
  error,
  helperText,
  propsLabel,
  propsError,
  propsHelper,
  sxWrapper,
  ...rest
}: TSelectAdvancedProps) {
  const id = `${name}-input`;

  return (
    <HOCs
      {...{
        isFormik,
        id,
        name,
        label,
        error,
        helperText,
        propsLabel,
        propsError,
        propsHelper,
        sxWrapper,
      }}
    >
      <Creatable id={id} name={name} {...rest} />
    </HOCs>
  );
}
