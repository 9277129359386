import React, { FC } from 'react';
import { Wrapper, Ball } from './Styles';
import colours from '../../../styles/colours';

export const EllipsisLoader: FC<EllipsisLoaderProps> = ({
  loading = true,
  size = 50,
  sizeUnit = 'px',
  frontColor = colours.darkBlue,
  backColor = colours.lightBlue,
  ballCount = 5,
}) => {
  const getBalls = () => {
    const balls = [];
    for (let i = 0; i < ballCount; i += 1) {
      balls.push(
        <Ball
          frontColor={frontColor}
          backColor={backColor}
          size={size}
          x={i * (size / 5 + size / 5)}
          y={0}
          key={i.toString()}
          index={i}
          sizeUnit={sizeUnit}
        />
      );
    }
    return balls;
  };
  return (
    <>
      {loading && (
        <Wrapper size={size} sizeUnit={sizeUnit}>
          {getBalls()}
        </Wrapper>
      )}
    </>
  );
};

interface EllipsisLoaderProps {
  loading?: boolean;
  size?: number;
  frontColor?: string;
  backColor?: string;
  sizeUnit?: string;
  ballCount?: number;
}
