import api from '../../../api';
import { TVoidPropositionBody } from './voidProposition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const voidPropositionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/void-proposition`;

// Post: Void Proposition
export const mutateVoidProposition = (body: TVoidPropositionBody) =>
  api
    .post<TVoidPropositionBody>(voidPropositionURL, body)
    .then((res) => res.data);
