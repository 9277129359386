import toast from 'react-hot-toast';
import { useState } from 'react';
import { getStrings } from '../../../../../../../../../common/utils';
import { useMutationVoidMatch } from '@/api/tradeManager/sportDetails/voidMatch/voidMatch.hooks';
import { useMutationVoidProposition } from '@/api/tradeManager/sportDetails/voidProposition/voidProposition.hooks';
import { useMutationVoidMarket } from '@/api/tradeManager/sportDetails/voidMarket/voidMarket.hooks';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { EConfigureLevel } from '@/features/tradeManager/pages/MatchDetails/types';
import {
  getMarkets,
  getMatch,
  getPropositionsForMarket,
} from '@/features/tradeManager/pages/MatchDetails/api';
import { useMarketModifiers } from '@/features/tradeManager/pages/MatchDetails/services/MatchDetails.hooks';
import { useModalConfigure } from '../../../services/ModalConfigure.hooks';

export const useVoid = () => {
  const [{ Generic }] = getStrings();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const { getMarketProposition } = useMarketModifiers();
  const { onModalConfigureClose } = useModalConfigure();

  const {
    selectedMarketGroup,
    modalConfigureData: { configureLevel, match, market, propositions },
  } = useAppSelector((state) => state.matchDetails);

  const { mutateAsync: mutateVoidMatch } = useMutationVoidMatch();
  const { mutateAsync: mutateVoidMarket } = useMutationVoidMarket();
  const { mutateAsync: mutateVoidProposition } = useMutationVoidProposition();

  /* To support older components */
  const refetch = async () => {
    await dispatch(
      getMatch({ matchId: match?.match_id ?? '', isUpdate: true })
    );
    await dispatch(
      getMarkets({
        matchId: match?.match_id ?? '',
        marketGroupId: selectedMarketGroup?.market_group_id ?? '',
        isUpdate: true,
      })
    );
    await dispatch(
      getPropositionsForMarket({
        marketId: market?.market_id ?? '',
        isUpdate: true,
      })
    );
    if (market) {
      getMarketProposition(market);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);

      if (configureLevel === EConfigureLevel.Match) {
        await mutateVoidMatch({ match_id: match?.match_id });
      }

      if (configureLevel === EConfigureLevel.Market) {
        await mutateVoidMarket({ market_id: market?.market_id });
      }

      if (
        configureLevel === EConfigureLevel.Proposition &&
        propositions?.length
      ) {
        await mutateVoidProposition({
          proposition_id: propositions[0]?.proposition_id,
        });
      }

      setLoading(false);

      refetch();
      onModalConfigureClose();
    } catch (e) {
      toast.error(Generic.Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    submit,
    loading,
  };
};
