import * as yup from 'yup';

const runnerSchema = yup.object().shape({
  time: yup
    .string()
    .test(
      'time',
      'Time is required when runner details are expanded and runner is scratched',
      function (value) {
        const { status } = this.parent;

        if (this.resolve(status) != 'Scratched') return true;

        return !!value;
      }
    ),
  win: yup
    .string()
    .test(
      'win',
      'Win is required when runner details are expanded and runner is scratched',
      function (value) {
        const { status } = this.parent;

        if (this.resolve(status) != 'Scratched') return true;

        return !!value;
      }
    ),
  p2: yup
    .string()
    .test(
      'p2',
      'P2 is required when runner details are expanded and runner is scratched',
      function (value) {
        const { status } = this.parent;

        if (this.resolve(status) != 'Scratched') return true;

        return !!value;
      }
    ),
  p3: yup
    .string()
    .test(
      'p3',
      'P3 is required when runner details are expanded and runner is scratched',
      function (value) {
        const { status } = this.parent;

        if (this.resolve(status) != 'Scratched') return true;

        return !!value;
      }
    ),
});

export const raceDetailsSchema = yup.object().shape({
  runners: yup.lazy((value) =>
    yup
      .object()
      .shape(
        Object.fromEntries(
          Object.entries(value).map(([key]) => [key, runnerSchema])
        )
      )
  ),
});
