import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabNavLink } from '../../common/components/TabNavLink/TabNavLink';
import { notForP0 } from '../../common/config/flags';
import { getStrings } from '../../common/utils';

export default function PlacedBetsWrapper() {
  const [
    {
      PlaceBetsPage: { RouteName },
    },
  ] = getStrings();

  return (
    <>
      <Flex direction="row" justify="start">
        <TabNavLink to="/placed-bets/upcoming">{RouteName.Upcoming}</TabNavLink>
        <TabNavLink to="/placed-bets/resulted">{RouteName.Resulted}</TabNavLink>

        {notForP0 && (
          <>
            <TabNavLink to="/placed-bets/bets-dashboard">
              {RouteName.BetsDashboard}
            </TabNavLink>
            <TabNavLink to="/placed-bets/flagged">
              {RouteName.Flagged}
            </TabNavLink>
          </>
        )}
      </Flex>

      <Outlet />
    </>
  );
}
