import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { Paragraph } from 'grommet';

import { sendResetPasswordLink } from '../../actions';
import { getStrings } from '../../../../common/utils';
import { recordResetPasswordAction } from '../../../settings/pages/Settings/actions';

import { LoadingSpinner } from '../../../../common/components';
import { FormContainer, SubmitButton, LoginLink, StyledInput } from './Styles';

import {
  AuthErrorCode,
  BOOKIE_ACTIVITY_LOG_TYPE,
  ACTIVITY_LOG_SUMMARY,
  FIREBASE_CONFIG,
} from '../../../../lib/Constants';
import forgotPasswordSchema, {
  ForgotPasswordSchema,
} from '../../validators/forgotPasswordSchema';
import { AnyAction } from '@reduxjs/toolkit';
import * as THREE from 'three';
import CLOUDS from 'vanta/dist/vanta.clouds.min';
import { Box, Text } from '@chakra-ui/react';

const ForgotPassword: FC = () => {
  const vantaRef = useRef(null);

  const [{ ForgotPassword: Strings, AuthErrorMessages }] = getStrings();
  const dispatch = useDispatch();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [sentAddress, setSentAddress] = useState('');

  useEffect(() => {
    const vantaEffect = CLOUDS({
      mouseControls: true,
      touchControls: true,
      gyroControls: true,
      el: vantaRef.current,
      THREE: THREE, // Pass the Three.js library
    });

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  const handleFormSubmit = async (
    email: string,
    { setErrors }: FormikHelpers<ForgotPasswordSchema>
  ) => {
    await sendResetPasswordLink(email)
      .then(({ success }) => {
        if (success) {
          setIsFormSubmitting(false);
          setEmailSuccess(success);
          setSentAddress(email);
          dispatch(
            recordResetPasswordAction({
              action_type: BOOKIE_ACTIVITY_LOG_TYPE.RESET_PASSWORD,
              action_summary:
                ACTIVITY_LOG_SUMMARY[BOOKIE_ACTIVITY_LOG_TYPE.RESET_PASSWORD],
              email,
              firebase_project_name: FIREBASE_CONFIG.projectId,
            }) as unknown as AnyAction
          );
        }
      })
      .catch((error) => {
        setIsFormSubmitting(false);
        switch (error.code) {
          case AuthErrorCode.INVALID_EMAIL:
          default:
            setErrors({ email: AuthErrorMessages.UserNotFound });
            break;
          case AuthErrorCode.USER_DISABLED:
            setErrors({ email: AuthErrorMessages.UserDisabled });
            break;
          case AuthErrorCode.RESET_PASSWORD_EXCEED_LIMIT:
            setErrors({ email: AuthErrorMessages.ExceededResetLimit });
            break;
        }
      });
  };

  const { values, handleSubmit, handleChange, errors, handleBlur, touched } =
    useFormik({
      validateOnChange: false,
      validateOnBlur: true,
      initialValues: {
        email: '',
      },
      validationSchema: forgotPasswordSchema,
      onSubmit: async ({ email }, formHelpers) => {
        setIsFormSubmitting(true);
        await handleFormSubmit(email, formHelpers);
      },
    });

  return (
    <div
      ref={vantaRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          bg: 'white',
          width: '350px',
          margin: 'auto',
          p: '6',
          borderRadius: 'md',
          boxShadow: '2xl',
        }}
      >
        <Text textAlign="center" fontSize="lg" fontWeight="semibold">
          {Strings.Heading}
        </Text>
        <Text textAlign="center" fontSize="md">
          {Strings.SubHeading}
        </Text>
        {emailSuccess ? (
          <>
            <Paragraph>
              {Strings.SuccessText}
              {sentAddress}
            </Paragraph>
            <LoginLink to="/login" data-cy="forgotPasswordLoginLink">
              {Strings.LoginLink}
            </LoginLink>
          </>
        ) : (
          <FormContainer>
            {isFormSubmitting && <LoadingSpinner />}
            <form onSubmit={handleSubmit}>
              <StyledInput
                id="email"
                name="email"
                type="text"
                labelText="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!errors.email && touched.email}
                errorText={touched.email ? errors.email : ''}
                dataCy="forgotPasswordEmail"
              />
              <SubmitButton type="submit" data-cy="forgotPasswordSubmit">
                {isFormSubmitting ? Strings.ButtonSubmitting : Strings.Button}
              </SubmitButton>
            </form>
          </FormContainer>
        )}
      </Box>
    </div>
  );
};

export default ForgotPassword;
