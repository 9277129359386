import React from 'react';
import { useAppDispatch } from '@/common/hooks/useRedux';
import { setCustomMarketModal } from '@/features/tradeManager/pages/TradeManager/slices';
import { ECustomMarketModalMode } from '@/features/tradeManager/pages/TradeManager/types';
import { Button, Flex } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import tabs from './services/Tabs.config';
import Tabs from '@/common/components/Tabs/Tabs';

export default function TradeManagerWrapper() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const showCustomMarketButton =
    location.pathname === '/trade-manager/custom-markets';

  return (
    <>
      <Flex
        sx={{
          justifyContent: 'start',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Tabs tabs={tabs} />

        {showCustomMarketButton && (
          <Button
            mr="5"
            ml="auto"
            minW="44"
            onClick={() =>
              dispatch(
                setCustomMarketModal({
                  isOpen: true,
                  mode: ECustomMarketModalMode.Create,
                })
              )
            }
          >
            <FormattedMessage id="trademanagerpage.custommarkets.createcustommarket" />
          </Button>
        )}
      </Flex>

      <Outlet />
    </>
  );
}
