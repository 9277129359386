import {
  chakra,
  ChakraComponent,
  FormLabel,
  FormLabelProps,
  WithCSSVar,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import { StylesConfig } from 'react-select';

export const selectStyles = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: WithCSSVar<Record<any, any>>
): StylesConfig => ({
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isSelected ? theme.colors.brand[500] : 'white',
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: '225px',
    '&:hover': state.isFocused
      ? {
          borderColor: theme.colors.brand[500],
        }
      : provided['&:hover'],

    boxShadow: state.isFocused
      ? `0 0 0 1px ${theme.colors.brand[500]}`
      : provided.boxShadow,
    borderColor: state.isFocused
      ? theme.colors.brand[500]
      : theme.colors.gray[200],

    height: theme.sizes[14],
    borderRadius: theme.radii.lg,
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? theme.colors.gray[600] : theme.colors.gray[500],
  }),
});

export const FormLabelDatepicker = chakra(FormLabel, {
  baseStyle: () => ({
    color: 'gray.700',
    fontSize: 'sm',
    textTransform: 'inherit',
    py: '1',
    mb: 0,
    fontWeight: 'normal',
  }),
}) as ChakraComponent<'p', FormLabelProps>;

export const FlexWrapper = chakra(Flex, {
  baseStyle: () => ({
    py: '5',
    px: '10',
    mb: '3',
    gap: '5',
  }),
}) as ChakraComponent<'div', FlexProps>;

export const inputLabelStyles: FormLabelProps = {
  color: 'gray.700',
  textTransform: 'none',
  fontSize: 'sm',
  fontWeight: 'normal',
  py: '1',
  mb: 0,
};
