/* This function takes enum and a list of localised enum status labels and 
    returns an object suitable to pass to a select component
    
    Usage: enumToLocalisationsDropdown(Object.values(ENUM), DictOfLocalisedStrings)
*/
export const enumToLocalisationsDropdown = (
  enumValues: string[],
  localisedStrings: Record<string, string>
) => {
  const localisedLabels = Object.entries(localisedStrings);

  return enumValues.map((value) => {
    const matchingLabel = localisedLabels.find(([key]) => key === value);

    return {
      value,
      label: matchingLabel ? matchingLabel[1] : '',
    };
  });
};
