import { Box, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import AllBetsContainer from './tabs/AllBets/AllBets';
import { useQuery } from 'react-query';
import {
  queryMysteryBet,
  queryMysteryBetRollovers,
} from '@/api/tradeManager/mysteryBet/mysteryBet';
import { useSearchParams } from 'react-router-dom';
import RunnersContainer from './tabs/Runners/Runners';
import RolloversContainer from './tabs/Rollovers/Rollovers';

export default function MysteryBet() {
  enum ETab {
    allBets = 'Singles',
    rollovers = 'Rollovers',
    runners = 'Runners',
  }

  const [params] = useSearchParams();
  const raceId = params.get('id');

  const [currentTab, setCurrentTab] = useState<ETab>(ETab.allBets);

  // Mystery Bets
  const { data, isLoading } = useQuery(
    ['mysteryBet', !!raceId, currentTab],
    () => queryMysteryBet({ race_id: raceId ?? '' })
  );

  const { data: rollovers, isLoading: isRolloversLoading } = useQuery(
    ['mysteryBet.rollovers', !!raceId, currentTab],
    () => queryMysteryBetRollovers({ race_id: raceId ?? '' })
  );

  return (
    <>
      {Object.values(ETab).map((tab) => (
        <Button
          key={tab}
          colorScheme="gray"
          isActive={tab === currentTab}
          _active={{ bg: 'origin.500', color: 'white' }}
          onClick={() => setCurrentTab(tab)}
          minW="150"
          m="2"
        >
          {tab}
        </Button>
      ))}

      <Box paddingTop={3}>
        {currentTab === ETab.allBets && (
          <AllBetsContainer
            runners={data?.runners ?? []}
            isLoading={isLoading}
          />
        )}
        {currentTab === ETab.runners && (
          <RunnersContainer
            runners={data?.runners ?? []}
            isLoading={isLoading}
          />
        )}
        {currentTab === ETab.rollovers && (
          <RolloversContainer
            bets={rollovers ?? []}
            isLoading={isRolloversLoading}
          />
        )}
      </Box>
    </>
  );
}
