import {
  chakra,
  Box,
  IconButton,
  ChakraComponent,
  BoxProps,
  IconButtonProps,
  ImageProps,
  Image,
  keyframes,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';

export const BonusBetBox = chakra(Box, {
  baseStyle: () => ({
    bg: 'black',
    color: 'white',
    fontSize: 'xs',
    borderRadius: 'sm',
    textAlign: 'center',
    w: '14',
  }),
}) as ChakraComponent<'div', BoxProps>;

export const UserBadge = chakra(Link, {
  baseStyle: () => ({
    display: 'flex',
    boxSize: '12',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    color: 'white',
    fontSize: '2xl',
    fontWeight: 'semibold',
    mr: '2',
    border: '2px solid',
    borderColor: 'white',
    outline: '2px solid',
  }),
}) as ChakraComponent<'link', LinkProps>;

type TActionButton = 'approve' | 'reject' | 'counter' | 'mbl';
export const ActionButton = chakra(IconButton, {
  shouldForwardProp: (prop) => !['action'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ action }: any) => ({
    w: '100%',
    h: '30px',
    borderRadius: '2xl',
    transition: '.3s ease',
    color: action === 'mbl' ? 'red.500' : 'white',
    bg: () => {
      switch (action) {
        case 'reject':
          return 'red.500';
        case 'counter':
          return 'yellow.500';
        case 'mbl':
          return '#ffe0e0';
        default:
          return 'green.400';
      }
    },
    _hover: {
      bg: () => {
        switch (action) {
          case 'reject':
            return 'red.700';
          case 'counter':
            return 'yellow.700';
          case 'mbl':
            return '#ffe0e0';
          default:
            return 'green.700';
        }
      },
    },

    m: '1',
    mt: '0',
    px: '3',
  }),
}) as ChakraComponent<'div', IconButtonProps & { action: TActionButton }>;

export const PlayerIcon: ChakraComponent<'img', ImageProps> = chakra(Image, {
  baseStyle: () => ({
    boxSize: '5',
    borderRadius: 'full',
    p: '1',
    mr: '1',
  }),
});

export const RaainBoxWrapper: ChakraComponent<'div', BoxProps> = chakra(Box, {
  baseStyle: () => ({
    bg: '#0C26A1',
    boxSize: '16',
    borderRadius: 'full',
  }),
});

const flash = keyframes`
  0% {
    transform: scale(1, 1);
    opacity: 0.5;
    filter: drop-shadow(0 0 0 #00A5EA);
  }
  50% {
    transform: scale(1.1, 1.1);
    opacity: 1;
    filter: drop-shadow(0 0 20px #00A5EA);
  }
  100% {
    transform: scale(1, 1);
    opacity: 0.5;
    filter: drop-shadow(0 0 0 #00A5EA);
  }
`;

export const RaainLogoImage: ChakraComponent<'img', ImageProps> = chakra(
  Image,
  {
    baseStyle: () => ({
      boxSize: '16',
      animation: `${flash} 2s infinite`,
    }),
  }
);
