import React, { useCallback, useEffect, useState } from 'react';
import { ArrowDownSquareFill } from '@styled-icons/bootstrap/ArrowDownSquareFill';
import { InfoCircle } from '@styled-icons/fa-solid';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { errorMessageFromHTTPException } from '@/lib/Error';
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt';
import {
  Icon,
  Tooltip,
  Flex,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Switch,
  Button as ButtonChakra,
} from '@chakra-ui/react';
import { Box } from 'grommet';

import { getStrings } from '../../../../../../common/utils';
import { getPropositionsForMarket } from '../../api';

import {
  MatchOrRunnerDetailsTable,
  UpdateableMarginControls,
} from '../../../../../../common/components';
import Select from '../../../../../../common/components/FormElements/Select';
import {
  MarketTitle,
  PropositionCount,
  TextDisclaimer,
  FlexTitleWrapper,
  SwitchWrapper,
} from './MarketDetails.styles';

import {
  EGeneralStatus,
  TMarket,
  TMatch,
} from '../../../../../../lib/DBModels';
import { AppThunkDispatch } from '../../../../../../app/types';
import { setSelectedMarket } from '../../slices';
import { TMatchStatus } from '@/api/tradeManager/sportDetails/matchStatus/matchStatus.types';

import { useMarketModifiers } from '../../services/MatchDetails.hooks';
import { EStatusOptions } from '../../components/ModalConfigure/components/Status/Status.types';
import { getStatus } from '../../components/ModalConfigure/components/Status/services/Status.utils';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import PropositionDetails from '../PropositionDetails/PropositionDetails';
import { useMatchConfig } from '../../services/MatchDetails.hooks';
import { getEGeneralStatus } from '../../services/MatchDetails.utils';
import { useModalConfigure } from '../../components/ModalConfigure/services/ModalConfigure.hooks';
import { EConfigureLevel } from '../../types';

type TMarketDetails = {
  market: TMarket;
  selectedMarket: TMarket | undefined;
  matchData: TMatch | undefined;
  matchStatusState: EGeneralStatus | undefined;
};

export default function MarketDetails({
  market,
  selectedMarket,
  matchData,
  matchStatusState,
}: TMarketDetails) {
  const allowedStatus = [
    EGeneralStatus.Open,
    EGeneralStatus.Closed,
    EGeneralStatus.Voided,
    ...(matchData?.sport_name === 'Racing Futures' ||
    matchData?.sport_name === 'Specials'
      ? [EGeneralStatus.Removed]
      : []),
  ];
  const [
    {
      TradeManagerPage: { MatchDetailsText },
    },
  ] = getStrings();
  const dispatch: AppThunkDispatch = useDispatch();
  const { onModalConfigureOpen } = useModalConfigure();
  const [marketDropDownId, setMarketDropDownId] = useState<string>();
  const [followFeedState, setFollowFeedState] = useState(!market?.lock_status);
  const [followPriceState, setFollowPriceState] = useState(!market?.lock_price);
  const [suspendedState, setSuspendedState] = useState(market?.is_suspended);
  const [statusState, setStatusState] = useState(
    matchData?.status === 'Closed' && market?.status === 'Open'
      ? EGeneralStatus.Closed
      : market?.status
  );

  const {
    handleChangeMarketModifier,
    handleSubmitMarketModifier,
    ellipsisLoaderId,
    getMarketProposition,
  } = useMarketModifiers();
  const { updateMatch } = useMatchConfig();

  const getUpdatedPropositions = useCallback(
    async (market: TMarket) => {
      try {
        await dispatch(
          getPropositionsForMarket({
            marketId: market.market_id ?? '',
            isUpdate: true,
          })
        );
      } catch (e) {
        toast.error(errorMessageFromHTTPException(e));
      }

      getMarketProposition(market);
    },
    [dispatch, getMarketProposition]
  );

  const handleMarketsDropDown = useCallback(
    async (market: TMarket) => {
      if (!market) return;

      if (market !== marketDropDownId) {
        try {
          await dispatch(
            getPropositionsForMarket({ marketId: market.market_id ?? '' })
          );
        } catch (e) {
          toast.error(errorMessageFromHTTPException(e));
        }
        setMarketDropDownId(market.market_id);
        dispatch(setSelectedMarket(market));
      }
      getMarketProposition(market);
    },
    [marketDropDownId, dispatch, getMarketProposition]
  );

  const handleFeedChange = (market_id: string) => () => {
    const matchToUpdate: TMatchStatus = {
      markets: [
        {
          id: market_id || '',
          lock_status: followFeedState,
        },
      ],
      propositions: selectedMarket?.market_proposition?.map((p) => {
        return {
          id: p?.proposition_id || '',
          lock_status: followFeedState,
        };
      }),
    };
    if (!followFeedState) {
      matchToUpdate.match = {
        id: matchData?.match_id || '',
        lock_status: followFeedState,
      };
    }
    updateMatch({
      matchUpdateData: matchToUpdate,
    });
    setFollowFeedState((followFeed) => !followFeed);
  };
  const handleFollowPriceChange = (market_id: string) => () => {
    updateMatch({
      matchUpdateData: {
        markets: [
          {
            id: market_id || '',
            lock_price: followPriceState,
          },
        ],
        propositions: selectedMarket?.market_proposition?.map((p) => {
          return {
            id: p?.proposition_id || '',
            lock_price: followPriceState,
          };
        }),
      },
    });
    setFollowPriceState((followPrice) => !followPrice);
  };
  const handleSuspendedChange = (market_id: string) => () => {
    updateMatch({
      matchUpdateData: {
        markets: [
          {
            id: market_id || '',
            is_suspended: !suspendedState,
            lock_price: true,
          },
        ],
        propositions: selectedMarket?.market_proposition?.map((p) => {
          return {
            id: p?.proposition_id || '',
            is_suspended: !suspendedState,
            lock_price: true,
          };
        }),
      },
    });
    setFollowPriceState(false);
    setSuspendedState((suspended) => !suspended);
  };
  const handleStatusChange = (market_id: string) => (status: string) => {
    const matchToUpdate: TMatchStatus = {
      markets: [
        {
          id: market_id || '',
          status: getEGeneralStatus(status),
          lock_status: true,
        },
      ],
      propositions: selectedMarket?.market_proposition?.map((p) => {
        return {
          id: p?.proposition_id || '',
          status: getEGeneralStatus(status),
          lock_status: true,
        };
      }),
    };
    updateMatch({
      matchUpdateData: matchToUpdate,
    });
    setFollowFeedState(false);
    setStatusState(getEGeneralStatus(status));
  };

  useEffect(() => {
    // refetch propositions for market every 60 secs
    const reFetchPropositions = setTimeout(() => {
      async () => {
        if (marketDropDownId) {
          try {
            await dispatch(
              getPropositionsForMarket({
                marketId: marketDropDownId,
                isUpdate: true,
              })
            );
          } catch (e) {
            toast.error(errorMessageFromHTTPException(e));
          }
        }
      };
    }, 60000);

    return () => clearInterval(reFetchPropositions);
  });

  useEffect(() => {
    if (
      matchStatusState === EGeneralStatus.Closed &&
      market?.status === 'Open'
    ) {
      setStatusState(matchStatusState);
    }
    if (matchStatusState === EGeneralStatus.Open) {
      setStatusState(market?.status);
    }
  }, [market.status, matchStatusState]);

  return (
    <AccordionItem key={`accord-${market.market_id}`} w="full" p="0">
      {({ isExpanded }) => (
        <MatchOrRunnerDetailsTable
          cellPadding={0}
          cellSpacing={0}
          key={market.market_id}
        >
          <thead>
            <th colSpan={1}>
              <Flex
                direction="row"
                align="center"
                justify="between"
                width="100%"
              >
                <AccordionButton
                  _hover={{ bg: 'white' }}
                  _focus={{ bg: 'white' }}
                  p="0"
                  border="none"
                  onClick={() => handleMarketsDropDown(market)}
                  width="50%"
                >
                  <Box
                    direction="row"
                    align="center"
                    justify="between"
                    width="100%"
                  >
                    <Flex align="center" ml="3">
                      <Icon
                        as={ArrowDownSquareFill}
                        sx={{
                          color:
                            market.exposure && market?.exposure !== 0
                              ? 'green.500'
                              : 'gray.300',
                          boxSize: '6',
                          transform: `rotate(${isExpanded ? '180deg' : '0'})`,
                        }}
                      />
                      <FlexTitleWrapper>
                        <MarketTitle>{market?.market_name ?? ''}</MarketTitle>
                        {market.disclaimer && (
                          <Flex>
                            <Icon as={InfoCircle} boxSize="2.5" />
                            <TextDisclaimer>{market.disclaimer}</TextDisclaimer>
                          </Flex>
                        )}
                      </FlexTitleWrapper>
                    </Flex>
                  </Box>
                </AccordionButton>
                <Flex
                  alignItems="center"
                  justify="space-between"
                  width="50%"
                  fontSize="16px"
                  fontWeight="400"
                >
                  <Flex align="center" mr="10">
                    <Select
                      isFormik={false}
                      name="status-dropdown"
                      value={
                        statusState && !allowedStatus.includes(statusState)
                          ? EGeneralStatus.Closed
                          : statusState
                      }
                      onChange={(e) =>
                        handleStatusChange(market.market_id ?? '')(
                          e.target.value
                        )
                      }
                      sxWrapper={{
                        maxW: '170px',
                      }}
                      sx={{
                        bg: 'white',
                        w: 'full',
                        h: 'full',
                        p: '3',
                      }}
                    >
                      {allowedStatus.map(
                        (status: EGeneralStatus, idx: number) => (
                          <option key={idx} value={status}>
                            {status}
                          </option>
                        )
                      )}
                    </Select>
                    <SwitchWrapper>
                      <Text>
                        <FormattedMessage id="trademanagerpage.racedetails.winplace.followprices" />
                      </Text>
                      <Switch
                        isChecked={followPriceState}
                        onChange={handleFollowPriceChange(
                          market.market_id ?? ''
                        )}
                      />
                    </SwitchWrapper>
                    <SwitchWrapper>
                      <Text>
                        <FormattedMessage id="trademanagerpage.common.followFeedStatus" />
                      </Text>
                      <Switch
                        isChecked={followFeedState}
                        onChange={handleFeedChange(market.market_id ?? '')}
                      />
                    </SwitchWrapper>
                    <SwitchWrapper>
                      <Text>
                        <FormattedMessage id="trademanagerpage.racedetails.configRunner.suspended" />
                      </Text>
                      <Switch
                        isChecked={suspendedState}
                        onChange={handleSuspendedChange(market.market_id ?? '')}
                      />
                    </SwitchWrapper>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={`${market?.propositions_count} propositions available`}
                    >
                      <PropositionCount>
                        {market?.propositions_count &&
                          `${market.propositions_count}`}
                      </PropositionCount>
                    </Tooltip>
                    {market.status && (
                      <StatusBadge
                        status={market?.status}
                        isSuspended={market.is_suspended}
                        isManualOverride={
                          getStatus([market]) !== EStatusOptions.Winter
                        }
                        size="sm"
                      />
                    )}
                    <ButtonChakra
                      ml="2"
                      size="sm"
                      borderRadius="md"
                      colorScheme="gray"
                      onClick={() =>
                        onModalConfigureOpen({
                          configureLevel: EConfigureLevel.Market,
                          match: matchData,
                          market: market,
                        })
                      }
                    >
                      <Icon as={EditAlt} />
                    </ButtonChakra>
                  </Flex>
                </Flex>
              </Flex>
            </th>
          </thead>
          {selectedMarket && (
            <AccordionPanel w="100%" display="inline-table">
              <thead>
                <th style={{ maxWidth: '100px', fontSize: '15px' }} colSpan={0}>
                  <UpdateableMarginControls
                    value={market?.modifiers?.display_modifier ?? 0}
                    differenceValue={selectedMarket?.update_value ?? 0}
                    marginType="global"
                    onUpdate={() => {
                      handleSubmitMarketModifier(market.market_id ?? '').catch(
                        undefined
                      );

                      getUpdatedPropositions(market ?? undefined).catch(
                        undefined
                      );
                    }}
                    onTickUp={(prevVal, newVal) =>
                      handleChangeMarketModifier(
                        prevVal,
                        newVal,
                        market.market_id ?? ''
                      )
                    }
                    onTickDown={(prevVal, newVal) =>
                      handleChangeMarketModifier(
                        prevVal,
                        newVal,
                        market.market_id ?? ''
                      )
                    }
                    loading={ellipsisLoaderId === market.market_id}
                  />
                </th>
                <th style={{ maxWidth: '100px' }} colSpan={0}>
                  {null}
                </th>
                <th style={{ maxWidth: '100px' }} colSpan={0}>
                  {MatchDetailsText.Bets}
                </th>
                <th style={{ maxWidth: '100px' }} colSpan={1}>
                  {MatchDetailsText.Hold}
                </th>
                <th style={{ maxWidth: '100px' }} colSpan={1}>
                  {MatchDetailsText.BiggestBet}
                </th>
                <th style={{ maxWidth: '100px' }}>
                  {MatchDetailsText.AveragePrice}
                </th>
                <th style={{ maxWidth: '200px' }}>{MatchDetailsText.Result}</th>
              </thead>

              {selectedMarket &&
                selectedMarket?.market_proposition?.map((p) => (
                  <PropositionDetails
                    proposition={p}
                    market={market}
                    selectedMarket={selectedMarket}
                    matchData={matchData}
                    marketDropDownId={marketDropDownId}
                    key={p?.proposition_id}
                    matchStatusState={matchStatusState}
                  ></PropositionDetails>
                ))}
            </AccordionPanel>
          )}
        </MatchOrRunnerDetailsTable>
      )}
    </AccordionItem>
  );
}
