import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { centsToDollars } from '@/common/utils';
import { Box } from '@chakra-ui/react';
import PunterInfo from '@/common/components/PunterInfo/PunterInfo';

export const useCombinationsColumns = () => {
  const combinationColumns = useMemo<
    ColumnDef<Api['SGMultisCombinationStatResponse']>[]
  >(
    () => [
      {
        accessorKey: 'combination',
        header: 'Combination',
        cell: ({ row }) => <Box maxW="700px">{row.original.name}</Box>,
      },
      {
        accessorKey: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => {
          const { bets_count } = row.original;

          return bets_count;
        },
      },
      {
        accessorKey: 'punters',
        header: 'Number of punters',
        cell: ({ row }) => {
          const { punters_count } = row.original;

          return punters_count;
        },
      },
      {
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        accessorKey: 'odds',
        header: 'Avg Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  const combinationSubColumns = useMemo<
    ColumnDef<Api['SEMultisCombinationPunterStatsItemResponse']>[]
  >(
    () => [
      {
        accessorKey: 'punters',
        header: 'Punters',
        cell: ({ row }) => (
          <PunterInfo
            punter_category={row.original.category}
            punter_name={row.original.name}
            id={row.original.id}
          />
        ),
      },
      {
        accessorKey: 'bets_count',
        header: 'Bets',
        cell: ({ row }) => {
          const { bets_count } = row.original;

          return bets_count;
        },
      },
      {
        accessorKey: 'stake',
        header: 'Stake',
        cell: ({ row }) => {
          const { stake } = row.original;

          return centsToDollars(stake);
        },
      },
      {
        accessorKey: 'odds',
        header: 'Avg Odds',
        cell: ({ row }) => {
          const { odds } = row.original;

          return odds?.toFixed(2);
        },
      },
      {
        accessorKey: 'exposure',
        header: 'Exposure',
        cell: ({ row }) => {
          const { exposure } = row.original;

          return centsToDollars(exposure);
        },
      },
    ],
    []
  );

  return { combinationColumns, combinationSubColumns };
};
