import React from 'react';
import { Button, ModalFooter } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useModalConfigure } from '../services/ModalConfigure.hooks';

export default function Footer() {
  const { isModalConfigureLoading, onModalConfigureClose } =
    useModalConfigure();

  return (
    <ModalFooter alignItems="flex-end">
      <Button
        variant="outline-rounded"
        borderRadius="lg"
        colorScheme="gray"
        onClick={() => onModalConfigureClose()}
        disabled={isModalConfigureLoading}
      >
        <FormattedMessage id="cancel" />
      </Button>

      <Button
        type="submit"
        borderRadius="lg"
        ml="4"
        isLoading={isModalConfigureLoading}
      >
        <FormattedMessage id="update" />
      </Button>
    </ModalFooter>
  );
}
