import { CenterProps } from '@chakra-ui/react';
import { useState } from 'react';
import React from 'react';
import NextPageButton from './components/NextPageButton';
import PreviousPageButton from './components/PreviousPageButton';
import { CenterPagination } from './Pagination.styles';

type TPagination = CenterProps & {
  limit?: number;
  offset?: number;
  nextDisabled: boolean;
  useHistory?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nextOffsetId?: any;
  onPageChange: (newOffset: number) => void;
};

export const Pagination = ({
  onPageChange,
  offset = 0,
  limit = 20,
  nextDisabled,
  useHistory = false,
  nextOffsetId,
  ...rest
}: TPagination) => {
  const [history, setHistory] = useState<number[]>([]);

  const onNext = () => {
    let nextOffset = offset + limit;

    if (useHistory) setHistory((h) => [...h, offset]);

    if (nextOffsetId) nextOffset = nextOffsetId;

    onPageChange(nextOffset);
  };

  const onPrevious = () => {
    let previousOffset = offset - limit;

    if (useHistory) {
      const newHist = [...history];
      const histOffset = newHist.pop();
      setHistory(newHist);

      previousOffset = histOffset ?? 0;
    }

    onPageChange(previousOffset);
  };

  /* don't show pagination when records are less than limit */
  if (nextDisabled && offset === 0) {
    return null;
  }

  return (
    <CenterPagination {...rest}>
      <PreviousPageButton isDisabled={offset === 0} onClick={onPrevious} />
      <NextPageButton isDisabled={nextDisabled} onClick={onNext} />
    </CenterPagination>
  );
};

export { NextPageButton, PreviousPageButton };
