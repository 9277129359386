import { ButtonProps, Icon } from '@chakra-ui/react';
import { ChevronLeft } from '@styled-icons/fa-solid/ChevronLeft';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonPage } from '../Pagination.styles';

export default function PreviousPageButton(
  props: Omit<ButtonProps, 'children'>
) {
  return (
    <ButtonPage
      leftIcon={<Icon as={ChevronLeft} color="brand.700" />}
      {...props}
    >
      <FormattedMessage id="generic.previous" />
    </ButtonPage>
  );
}
