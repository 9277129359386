import React, { ReactNode } from 'react';
import { QueryClientProvider, QueryClientProviderProps } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

type TQuery = {
  children: ReactNode;
  client: QueryClientProviderProps['client'];
};

/**
 * React Query Provider
 */
export default function Query({ children, client }: TQuery) {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
