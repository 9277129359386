import React, { FC } from 'react';
import { DropButtonProps, ButtonProps, Box, Text, DropButton } from 'grommet';
import { PlainSelectContainer, HeaderLabel } from './Styles';
import { DropDownButtonArrow } from '../../../assets/icons';

export interface DropButtonExtendedProps extends DropButtonProps, ButtonProps {}

export interface CustomPlainSelectProps extends DropButtonExtendedProps {
  labelTitle?: string;
  label?: string;
}

export const PlainSelect: FC<CustomPlainSelectProps> = ({
  labelTitle,
  label,
  ...rest
}) => (
  <PlainSelectContainer>
    {labelTitle && <HeaderLabel>{labelTitle}</HeaderLabel>}
    <DropButton
      {...rest}
      plain
      justify="between"
      margin={{ top: '3px', bottom: '3px' }}
      dropAlign={{ top: 'bottom' }}
      focusIndicator
    >
      <Box
        direction="row"
        gap="medium"
        align="center"
        pad="small"
        justify="between"
      >
        <Text>{label}</Text>
        <DropDownButtonArrow />
      </Box>
    </DropButton>
  </PlainSelectContainer>
);
