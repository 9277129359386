import React from 'react';
import { useHelpers } from '@remirror/react';
import { Button } from '@chakra-ui/react';
import { useSave } from './services/Wyswyg.hooks';

/**
 * This is inheriting the Remirror theme. As it's going
 * to be stripped out soon, I'm not precious about it being hardcoded.
 */
type TSave = {
  policyName: string;
};
export default function Save({ policyName }: TSave) {
  const { getMarkdown } = useHelpers();
  const [saveMD, { isSaving }] = useSave();

  return (
    <Button
      onClick={() => {
        const md = getMarkdown();
        saveMD(md, policyName);
      }}
      sx={{
        order: 3,
        height: '56px',
        textAlign: 'center',
        lineHeight: '56px',
        background: 'var(--bc-colors-blue-500)',
        color: '#FFFFFF',
        borderRadius: '16px',
        padding: '0 16px',
        position: 'relative',
        alignSelf: 'flex-end',
        fontWeight: 'bold',
        fontSize: '18px',
        minW: '124px',
        mt: '16px',
      }}
      isLoading={isSaving}
      data-cy="savePromotions"
    >
      Save
    </Button>
  );
}
