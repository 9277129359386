import React from 'react';
import loadable from '@loadable/component';
import { FC } from 'react';
import Loader from '@/components/Routes/Loader';

const ByRacingTab = loadable(
  () => import('@/features/tradeManager/pages/TradeManager/tabs/ByRacing'),
  { fallback: <Loader /> }
) as FC;

const BySportTab = loadable(
  () => import('@/features/tradeManager/pages/TradeManager/tabs/BySport'),
  { fallback: <Loader /> }
) as FC;

const HighestExposureTab = loadable(
  () =>
    import('@/features/tradeManager/pages/TradeManager/tabs/HighestExposure'),
  { fallback: <Loader /> }
) as FC;

const FindAnEventTab = loadable(
  () => import('@/features/tradeManager/pages/TradeManager/tabs/FindAnEvent'),
  { fallback: <Loader /> }
) as FC;

const ManageMyCustomMarketsTab = loadable(
  () => import('@/features/tradeManager/pages/TradeManager/tabs/CustomMarkets'),
  { fallback: <Loader /> }
) as FC;

const ManageGlobalMarginsTab = loadable(
  () =>
    import(
      '@/features/tradeManager/pages/TradeManager/tabs/ManageGlobalMargins'
    ),
  { fallback: <Loader /> }
) as FC;

const MultiMonitor = loadable(
  () =>
    import(
      '@/features/tradeManager/pages/TradeManager/tabs/MultiMonitor/MultiMonitor'
    ),
  { fallback: <Loader /> }
) as FC;

const RaceDetails = loadable(
  () => import('@/features/tradeManager/pages/RaceDetails/RaceDetailsPage'),
  { fallback: <Loader /> }
) as FC;

const MatchDetails = loadable(
  () => import('@/features/tradeManager/pages/MatchDetails/MatchDetails'),
  { fallback: <Loader /> }
) as FC;

const SameGameMultiTab = loadable(
  () =>
    import(
      '@/features/tradeManager/pages/TradeManager/tabs/SameGameMulti/SameGameMulti'
    ),
  { fallback: <Loader /> }
) as FC;

const SameRaceMultiTab = loadable(
  () =>
    import(
      '@/features/tradeManager/pages/TradeManager/tabs/SameRaceMulti/SameRaceMulti'
    ),
  { fallback: <Loader /> }
) as FC;

export {
  ByRacingTab,
  BySportTab,
  HighestExposureTab,
  FindAnEventTab,
  ManageMyCustomMarketsTab,
  ManageGlobalMarginsTab,
  MultiMonitor,
  RaceDetails,
  MatchDetails,
  SameGameMultiTab,
  SameRaceMultiTab,
};
