import api from '@/api/api';
import {
  TClosePropositionBody,
  TClosePropositionResponse,
} from './closeProposition.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const closePropositionURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/sport-details/open-close-proposition`;

// Post: Sports Status - Close Proposition
export const mutateCloseProposition = (body: TClosePropositionBody) =>
  api
    .post<TClosePropositionResponse>(closePropositionURL, body)
    .then((res) => res.data);
