import { Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

/**
 * Route specific loading animation
 */
export default function Loader() {
  return (
    <Stack sx={{ p: '4' }}>
      {[...new Array(2)].map((_, i) => (
        <Skeleton key={`skel-route-${i}`} sx={{ h: '20' }} />
      ))}

      <Stack direction="row">
        {[...new Array(2)].map((_, i) => (
          <Skeleton key={`skel-route-col-${i}`} sx={{ flex: i + 1, h: '20' }} />
        ))}
      </Stack>

      <Skeleton key={`skel-route-sep`} sx={{ h: '20' }} />

      <Stack direction="row">
        {[...new Array(2)].map((_, i) => (
          <Skeleton
            key={`skel-route-col-sec-${i}`}
            sx={{ flex: i === 0 ? 2 : 1, h: '20' }}
          />
        ))}
      </Stack>

      {[...new Array(2)].map((_, i) => (
        <Skeleton key={`skel-route-${i}`} sx={{ h: '20' }} />
      ))}
    </Stack>
  );
}
