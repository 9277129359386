import React from 'react';
import { BetsTable } from '@/common/components';
import { useRaceDetailsEvenShot } from '../hooks/EvenShot.hooks';
import { useEvenShotRunnerColumns } from '../config/EvenShotRunners.config';
import { Column } from '@tanstack/react-table';

export default function EvenShotRunnersTab() {
  const { data, isLoading } = useRaceDetailsEvenShot();
  const { columns } = useEvenShotRunnerColumns();

  const { runners } = data || {
    runners: [],
  };

  return (
    <>
      <BetsTable
        columns={columns as unknown as Column<Api['EvenShotRaceRunnerStats']>[]}
        data={runners || ([] as Api['EvenShotRaceRunnerStats'][])}
        loading={isLoading}
        initialSort={[{ id: 'number', desc: true }]}
      />
    </>
  );
}
