import api from '@/api/api';
import { TMarketCreationResponse } from '../marketCreation.types';
import { TUpdateMarketGroupBody } from './updateMarketGroup.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const updateMarketGroupURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/market-creation/update-market-group`;

// Trade Manager - Update Market Group

export const mutateUpdateMarketGroup = (body: TUpdateMarketGroupBody) =>
  api
    .post<TMarketCreationResponse>(updateMarketGroupURL, body)
    .then((res) => res.data);
