import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';
import { TCMSFormValues, TLinks } from './Cms.types';
import * as Yup from 'yup';

export const useCMS = () => {
  const foo = false;

  return foo;
};

export const useValidation = () => {
  const linkSchema = Yup.object().shape({
    is_event: Yup.boolean().required(),
    name: Yup.string().when('is_event', {
      is: false,
      then: Yup.string().required('required'),
    }),
    order: Yup.number().required(),
    url: Yup.string().when('is_event', {
      is: false,
      then: Yup.string().required('required'),
    }),
    top_level: Yup.boolean().required(),
    competition_id: Yup.string().when(['is_event', 'is_sport_event'], {
      is: (isEvent: boolean, isSportEvent: boolean) => isEvent && isSportEvent,
      then: Yup.string().required('required'),
    }),
    event_id: Yup.string().when('is_event', {
      is: true,
      then: Yup.string().required('required'),
    }),
  });

  const customMarketsSchema = Yup.object().shape({
    links: Yup.array().min(1, 'Error').of(linkSchema),
  });

  return { customMarketsSchema };
};

export const useListItem = () => {
  const context = useFormikContext<TCMSFormValues>();

  type TAddProposition = {
    index: number;
    isEvent: boolean;
    isSport?: boolean;
    rawData: TLinks[];
  };

  const addProposition = ({
    index,
    isEvent,
    isSport,
    rawData,
  }: TAddProposition) => {
    if (!context) return;

    const { setFieldValue, values } = context;

    const newPropositions = [...rawData];

    newPropositions[index] = {
      id: uuid(),
      name: '',
      order: 0,
      top_level: true,
      url: '',
      is_event: isEvent,
      event_id: '',
      competition_id: '',
      is_sport_event: isSport ?? false,
    };

    // Merge newPropositions with existing links, filtering out duplicates
    setFieldValue('links', [
      ...newPropositions,
      ...values.links.filter((val) => !rawData?.some((rd) => rd.id === val.id)),
    ]);
  };

  const removeProposition = (index: number, data: TLinks[]) => {
    if (!context) return;

    const { setFieldValue, values } = context;

    const newPropositions = [...data];
    newPropositions.splice(index, 1);
    setFieldValue('links', [
      ...newPropositions,
      ...values.links.filter((val) => !data.some((rd) => rd.id === val.id)),
    ]);
  };

  return { addProposition, removeProposition };
};
