import React, { ReactNode } from 'react';
import { createContextState } from 'create-context-state';
import { CodeBlockExtension, DocExtension } from 'remirror/extensions';
import { extensions, useWyswyg } from './services/Wyswyg.hooks';
import {
  ReactExtensions,
  ThemeProvider,
  UseRemirrorReturn,
} from '@remirror/react';

type TWyswygHOC = {
  children: ReactNode;
};

type TContextProps = {
  visual: UseRemirrorReturn<
    ReactExtensions<ReturnType<typeof extensions>[number]>
  >;
  markdown: UseRemirrorReturn<
    ReactExtensions<DocExtension | CodeBlockExtension>
  >;
};

type TContext = {
  setMarkdown: (markdown: string) => void;
  setVisual: (markdown: string) => void;
} & TContextProps;

export const [DualEditorProvider, useDualEditor] = createContextState<
  TContext,
  TContextProps
>(({ props }) => {
  return {
    ...props,

    setMarkdown: (text: string) => {
      return props.markdown.getContext()?.setContent({
        type: 'doc',
        content: [
          {
            type: 'codeBlock',
            attrs: { language: 'markdown' },
            content: text ? [{ type: 'text', text }] : undefined,
          },
        ],
      });
    },
    setVisual: (markdown: string) => {
      return props.visual.getContext()?.setContent(markdown);
    },
  };
});

export default function WyswygHOC({ children }: TWyswygHOC) {
  const { visual, markdown } = useWyswyg();

  return (
    <DualEditorProvider visual={visual} markdown={markdown}>
      <ThemeProvider>{children}</ThemeProvider>
    </DualEditorProvider>
  );
}
