import api from '@/api/api';
import { TMarketsStatus } from './marketStatus.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

export const marketsStatusURL = `/${getPrefixPath(
  isWincore
)}/trade-manager/race-details/manual-race-status-update`;

// Post: Update Markets Status
export const mutateMarketsStatus = (body: TMarketsStatus) =>
  api.post<TMarketsStatus>(marketsStatusURL, body).then((res) => res.data);
