/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, FC, useState } from 'react';
import { Box, ColumnConfig, Text } from 'grommet';
import {
  Text as TextChakra,
  Box as BoxChakra,
  Flex,
  Button,
  Select,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Back } from '@styled-icons/entypo/Back';

import {
  centsToDollars,
  getStrings,
} from '../../../../../../../../common/utils';

import {
  LoadingSpinner,
  StyledDataTable,
  DataTableMultiSelectBar,
  Separator,
} from '../../../../../../../../common/components';

import {
  ActionsContainer,
  DeclineButton,
  ApproveButton,
  LeftSideMultiActions,
  RightSideMultiActions,
} from '../../Styles';
import GrommetTheme from '../../../../../../../../styles/GrommetTheme';

import { TPunterWithdrawalStatus } from '../../../../types';
import { TPunterWithdrawalRequestsDTO } from '../../../../../../../../lib/DTO';
import { formatDateTime24 } from '../../../../../../../../lib/Time';
import { useWithdrawalRequest } from '../../services/Withdrawals.hooks';
import IconSvg from '@/components/IconSvg/IconSvg';
import { filterOptions } from '../../services/Withdrawals.config';
import { PaginationV3 } from '@/common/components/Pagination/PaginationV3';

export const ReadyToPay: FC = () => {
  const [
    {
      BanksPage: {
        Withdrawals: { ReadyToPay: Strings },
      },
    },
  ] = getStrings();

  const [history, setHistory] = useState<string[]>([]);
  const {
    handleActionSingle,
    handleActionMulti,
    isLastFetch,
    isLoading,
    selected,
    setSelected,
    data,
    paging,
    setOffset,
    filter,
    setFilter,
    offset,
    handleDownloadClick,
    statsData,
    statsLoading,
  } = useWithdrawalRequest(TPunterWithdrawalStatus.Approved);

  const dataCols: ColumnConfig<TPunterWithdrawalRequestsDTO>[] = [
    {
      property: 'withdrawal_id',
      primary: true,
      render: () => null,
    },
    {
      property: 'transaction_type',
      sortable: false,
      header: (
        <Text textAlign="start">{Strings.TableHeadings.TransactionType}</Text>
      ),
      render: (datum) => (
        <Text textAlign="start">
          <b>{datum?.transaction_type ?? ''}</b>
        </Text>
      ),
    },
    {
      property: 'punter_name',
      header: <Text textAlign="start">{Strings.TableHeadings.PunterName}</Text>,
      render: (datum) => (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/punter/${datum.punter_id}`}
        >
          <Text color={GrommetTheme?.global?.colors?.midBlue} textAlign="start">
            {datum?.punter_name ?? ''}
          </Text>
        </Link>
      ),
    },
    {
      property: 'created_at',
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          {Strings.TableHeadings.Requested}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{formatDateTime24(datum?.created_at)}</Text>
      ),
    },
    {
      property: 'bookie_name',
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          {Strings.TableHeadings.By}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{datum?.bookie_name ?? ''}</Text>
      ),
    },
    {
      property: 'exported',
      header: <Text textAlign="start">Status</Text>,
      render: (datum) => (
        <Text color={GrommetTheme?.global?.colors?.midBlue} textAlign="start">
          {datum?.exported ? 'Exported' : 'New'}
        </Text>
      ),
    },
    {
      property: 'amount',
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          {Strings.TableHeadings.WithdrawalAmount}
        </Text>
      ),
      render: (datum) => (
        <Text textAlign="center">{centsToDollars(datum?.amount)}</Text>
      ),
    },
    {
      property: 'status',
      sortable: false,
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          Transfer Status
        </Text>
      ),
      render: (datum) => (
        <ActionsContainer>
          <DeclineButton
            onClick={() =>
              handleActionSingle(
                datum?.withdrawal_id,
                TPunterWithdrawalStatus.Rejected
              )
            }
            disabled={!datum?.exported}
          >
            {Strings.Unsuccessful}
          </DeclineButton>
          <ApproveButton
            onClick={() =>
              handleActionSingle(
                datum?.withdrawal_id,
                TPunterWithdrawalStatus.Processed
              )
            }
            disabled={!datum?.exported}
          >
            {Strings.Successful}
          </ApproveButton>
        </ActionsContainer>
      ),
    },
    {
      property: 'status',
      header: (
        <Text style={{ width: '100%' }} textAlign="center">
          Back to Pending
        </Text>
      ),
      render: (datum) => (
        <Button
          mx="auto"
          onClick={() => {
            handleActionSingle(
              datum?.withdrawal_id,
              TPunterWithdrawalStatus.Pending
            );
          }}
        >
          <Back size={25} />
        </Button>
      ),
    },
  ];

  const tableData = (data ?? []) as TPunterWithdrawalRequestsDTO[];

  return (
    <>
      <Box
        align="center"
        justify="start"
        direction="column"
        pad={{ horizontal: '28px' }}
      >
        <Box
          align="center"
          justify="start"
          direction="column"
          width="100%"
          pad={{ horizontal: '28px' }}
        >
          <Box direction="row" width="100%">
            <Box
              pad={{ horizontal: '40px', top: '6px', bottom: '12px' }}
              align="center"
              justify="center"
              direction="column"
            >
              {statsLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Text size="32px" weight="bold" color="black">
                    {statsData?.new_withdrawals?.count ?? ''}
                  </Text>
                  <Text size="14px" weight="bold" color="black">
                    {centsToDollars(statsData?.new_withdrawals?.amount)}
                  </Text>
                  <Text size="16px" weight="bold" color="darkBlue">
                    New Withdrawals
                  </Text>
                </>
              )}
            </Box>
            <Separator vertical />
            <Box
              pad={{ horizontal: '40px', top: '6px', bottom: '12px' }}
              align="center"
              justify="center"
              direction="column"
            >
              {statsLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Text size="32px" weight="bold" color="black">
                    {statsData?.total_withdrawals?.count ?? ''}
                  </Text>
                  <Text size="14px" weight="bold" color="black">
                    {centsToDollars(statsData?.total_withdrawals?.amount)}
                  </Text>
                  <Text size="16px" weight="bold" color="darkBlue">
                    Total Withdrawals
                  </Text>
                </>
              )}
            </Box>
            <Separator vertical />
          </Box>
        </Box>
        <Flex
          sx={{
            justifyContent: 'space-between',
            w: 'full',
            alignItems: 'space-between',
            py: '3',
          }}
        >
          <BoxChakra>
            <Select
              name="select-status"
              value={filter === undefined ? 'all' : filter ? 'exported' : 'new'}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = e.target.value;

                if (selectedValue === 'all') {
                  setFilter(undefined);
                } else if (selectedValue === 'new') {
                  setFilter(false);
                } else if (selectedValue === 'exported') {
                  setFilter(true);
                }
              }}
            >
              {filterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </BoxChakra>
          <BoxChakra>
            <Button onClick={handleDownloadClick}>Export All as ABA</Button>
          </BoxChakra>
        </Flex>
        {isLoading ? <LoadingSpinner /> : null}
        <Box width="full">
          {data?.length ? (
            <StyledDataTable<TPunterWithdrawalRequestsDTO>
              columns={dataCols}
              data={tableData}
              fill
              style={{ width: '100%' }}
              pad={{ vertical: '16px' }}
              select={selected}
              onSelect={(selectedArray) => setSelected(selectedArray)}
              className="no-border-vertical-selectable"
            />
          ) : (
            <TextChakra my="5">
              <FormattedMessage
                id={'bankspage.withdrawals.readytopay.nomorerecords'}
              />
            </TextChakra>
          )}
          <PaginationV3
            nextDisabled={isLastFetch}
            offset={offset}
            onPageChange={(newOffset) => setOffset(newOffset)}
            history={history}
            setHistory={setHistory}
            useHistory
            nextOffsetId={!isLastFetch ? paging?.next_offset : undefined}
          />
        </Box>
      </Box>
      <DataTableMultiSelectBar selected={selected} setSelected={setSelected}>
        <LeftSideMultiActions>
          <Button
            colorScheme="green"
            mr="2"
            onClick={() => handleActionMulti(TPunterWithdrawalStatus.Processed)}
            disabled={data?.some(
              (req) =>
                req?.withdrawal_id &&
                selected.includes(req?.withdrawal_id) &&
                !req?.exported
            )}
          >
            <IconSvg name="checkmark" sx={{ mr: '1', boxSize: '2.5' }} />
            <Text>
              &nbsp;
              {Strings.MarkSuccessful}
            </Text>
          </Button>
          <Button
            colorScheme="red"
            onClick={() => handleActionMulti(TPunterWithdrawalStatus.Rejected)}
            mr="2"
            disabled={data?.some(
              (req) =>
                req?.withdrawal_id &&
                selected.includes(req?.withdrawal_id) &&
                !req?.exported
            )}
          >
            <IconSvg name="close-white" sx={{ mr: '1', boxSize: '2.5' }} />
            <Text>
              &nbsp;
              {Strings.MarkUnsuccessful}
            </Text>
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => handleActionMulti(TPunterWithdrawalStatus.Pending)}
            disabled={data?.some(
              (req) =>
                req?.withdrawal_id &&
                selected.includes(req?.withdrawal_id) &&
                !req?.exported
            )}
          >
            <Back color="white" size="15" />
            <Text>&nbsp; Mark Pending</Text>
          </Button>
        </LeftSideMultiActions>
        <RightSideMultiActions>
          <Button onClick={handleDownloadClick}>
            <Text>
              &nbsp;
              {Strings.ExportAsABA}
            </Text>
          </Button>
        </RightSideMultiActions>
      </DataTableMultiSelectBar>
    </>
  );
};
