import React, {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthUser } from './types';

interface AuthProviderProps {
  children: ReactElement;
}

type ContextProps = {
  currentUser: AuthUser;
};

const AuthContext = createContext<Partial<ContextProps>>({});

export const useAuthContext = (): Partial<ContextProps> =>
  useContext(AuthContext);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<AuthUser>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  const value = { currentUser };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
