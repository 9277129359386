import { keys } from '@/api/api.keys';
import { useQuery, UseQueryOptions } from 'react-query';
import { TMarketBet } from '../../trademanager.types';
import { queryBetsForExotics, queryBetsForToteMulti } from './betsForExotics';
import { TBetsForExoticsParams } from './betsForExotics.types';

type TQueryBetsForExoticsHook = {
  params: TBetsForExoticsParams;
  options?: Omit<UseQueryOptions<TMarketBet[]>, 'queryKey' | 'queryFn'>;
};

export const useQueryBetsForExotics = ({
  params,
  options,
}: TQueryBetsForExoticsHook) =>
  useQuery(
    [keys.betsForExotics, params],
    () => queryBetsForExotics(params),
    options
  );

export const useQueryBetsForToteMulti = ({
  params,
  options,
}: TQueryBetsForExoticsHook) =>
  useQuery(
    ['betsForToteMulti', params],
    () => queryBetsForToteMulti(params),
    options
  );
