import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { BonusBet } from '../../EventMarketInfo/EventMarketInfo.styles';
import { GiftPack } from '@/assets/icons';
import IconSvg from '@/components/IconSvg/IconSvg';
import { getIconAssetPath } from '@/common/utils';
import Countdown from '@/components/Countdown/Countdown';

interface IMarketInfoSingleSportProps {
  status: string;
  matchName: string;
  timeStamp: string;
  icon: string;
  propositionName: string;
  marketName: string;
  isBonusBet: boolean;
}

export function MarketInfoSingleSport({
  status,
  matchName,
  timeStamp,
  icon,
  propositionName,
  isBonusBet,
  marketName,
}: IMarketInfoSingleSportProps) {
  return (
    <Box>
      {status}
      <Text fontWeight="bold">{matchName}</Text>
      <Box
        sx={{
          '& > div': {
            justifyContent: 'left',
          },
        }}
      >
        <Countdown eventTime={timeStamp} />
      </Box>
      {isBonusBet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>Bonus</Text>
        </BonusBet>
      )}
      <Flex>
        <IconSvg
          name={getIconAssetPath('sports', icon)}
          sx={{
            mr: '3',
          }}
        />
        <Text>{`${propositionName} -  ${marketName}`}</Text>
      </Flex>
    </Box>
  );
}
