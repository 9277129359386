import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { activeButtonStyle } from '../Button';

export const StyledTabNavLink = styled(NavLink)`
  background: ${({ theme }) => theme.global.colors.tabButtonBackground};
  border-bottom: 4px solid transparent;
  color: var(--bc-colors-gray-600);
  height: var(--bc-sizes-10);
  font-size: 14px;
  font-weight: 400;
  padding: var(--bc-sizes-8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-out;
  position: relative;

  &.active,
  &.selected.active {
    ${activeButtonStyle}
  }

  &.unselected.active {
    border-bottom: 4px solid transparent;
    color: var(--bc-colors-gray-600);
  }

  &:hover {
    background-color: var(--bc-colors-gray-100);
  }
  &.small {
    padding: 0 15px;
  }

  &.no-margin-bottom {
    margin-bottom: 0;
  }
`;
