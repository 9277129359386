export const FormLabel = {
  baseStyle: {
    mb: 1,
    fontSize: 'xs',
    color: 'blackAlpha.700',
    fontFamily: 'heading',
    textTransform: 'uppercase',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
