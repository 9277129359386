import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack';
import { IconBack, BCGridItem } from './styles/Promotion.styles';
import FormHOC from './components/Form/FormHOC';
import Form from './components/Form/Form';
import { usePromotion } from './services/Promotion.hooks';
import Loader from '@/components/Routes/Loader';

export default function Promotion() {
  const { promotionId } = useParams();
  const { promotionToShow } = usePromotion({ id: promotionId });
  const navigate = useNavigate();
  const handleClickBack = () => navigate('/promo-manager/promotions');

  if (!promotionToShow && promotionId) return <Loader />;

  return (
    <>
      <BCGridItem bg="transparent" boxShadow="none" p="0" area={'header'}>
        <Flex alignItems="center">
          <IconBack as={ArrowBack} onClick={handleClickBack} />
          {promotionToShow?.title}
        </Flex>
      </BCGridItem>
      <FormHOC>
        <Form promotion={promotionToShow ?? undefined} />
      </FormHOC>
    </>
  );
}
