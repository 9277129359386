import { TBetsData } from '@/api/punters/punters.types';
import api from '../../api';
import { TMultiBetsParams } from './multi.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const multiBetsURL = `/${getPrefixPath(isWincore)}/trade-manager/multi`;

// Trade Manager - Multi Bets

export const queryMultiBets = (params: TMultiBetsParams) =>
  api.get<TBetsData[]>(multiBetsURL, { params }).then((res) => res.data);
