import styled from 'styled-components';
import device from '../../styles/device';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.global.colors.contentText};
  overflow: auto;
`;

export const MainContent = styled.main`
  background: ${({ theme }) => theme.global.colors.contentBackground};
  padding: 90px 80px;
  width: 560px;
`;

export const ChildContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Logo = styled.div`
  margin-bottom: 20px;
`;

export const Slider = styled.div`
  background: black;
  flex: 1;
  order: 2;
  min-height: 100vh;
`;

export const Slide = styled.div`
  position: relative;
  height: 100%;
`;

export const SlideImage = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SlideText = styled.div`
  color: white;
  font-size: 80px;
  line-height: 80px;
  font-weight: 500;
  position: absolute;
  z-index: 2;
  bottom: 0;
  margin: 0;
  padding: 20px 60px 60px 60px;
  background: black;
  width: 100%;

  ${device.tabletLarge} {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: -320px;
    left: 0;
    width: 100%;
    height: 320px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );
  }
`;
