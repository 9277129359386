import styled from 'styled-components';

export const EventDateTimeExposureContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

export const DateTimeContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const ExposureContainer = styled.div``;
