import { StyleFunctionProps, theme } from '@chakra-ui/react';

export const Button = {
  baseStyle: {
    fontWeight: 'medium',
    lineHeight: 'none',
  },
  sizes: {
    lg: { borderRadius: 'lg', fontSise: 'md' },
    md: { borderRadius: 'md', fontSize: 'sm' },
    sm: { borderRadius: 'sm' },
    xs: { borderRadius: 'sm' },
  },
  variants: {
    rounded: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants?.solid(props),
      borderRadius: (() => {
        switch (props.size) {
          case 'lg':
            return '24px';
          case 'md':
            return '20px';
          case 'sm':
            return '16px';
          default:
            return '12px';
        }
      })(),
    }),
    'outline-rounded': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants?.outline(props),
      borderRadius: (() => {
        switch (props.size) {
          case 'lg':
            return '24px';
          case 'md':
            return '20px';
          case 'sm':
            return '16px';
          default:
            return '12px';
        }
      })(),
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',
    }),
  },
  defaultProps: {
    colorScheme: 'brand',
  },
};
