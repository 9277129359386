import React from 'react';

import { Box } from 'grommet';

import { popupConfirmDeleteIcon } from '../../../assets/icons';

import {
  ModalBackground,
  ModalWrapper,
  ModalTitle,
  ModalSubtext,
  ModalBodyText,
  Icon,
  ButtonCancel,
  ButtonConfirm,
} from './Styles';

import { DeleteModalProps } from './types';

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  subtext,
  bodyText,
  cancelButtonText,
  confirmButtonText,
  isDeleting,
}) => {
  const stopClickBubbling = (e: React.MouseEvent<HTMLDivElement>) =>
    e.stopPropagation();

  return (
    <ModalBackground opened={isOpen} onClick={onCancel}>
      <ModalWrapper onClick={stopClickBubbling}>
        <Icon src={popupConfirmDeleteIcon} />
        <Box
          justify="center"
          align="center"
          gap="10px"
          margin={{ bottom: '30px' }}
        >
          <ModalTitle>{title}</ModalTitle>
          <ModalSubtext>{subtext}</ModalSubtext>
        </Box>
        {bodyText && <ModalBodyText>{bodyText}</ModalBodyText>}
        <Box direction="row" gap="20px">
          <ButtonCancel label={cancelButtonText} onClick={onCancel} />
          <ButtonConfirm
            onClick={onConfirm}
            disabled={isDeleting}
            label={confirmButtonText}
          />
        </Box>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default DeleteModal;
