import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const timerStart = dayjs();

export const useCountdown = (eventTime: string) => {
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const msTillStart = dayjs(eventTime).diff(timerStart).valueOf();
  const minutesRemaining =
    secondsRemaining < 0
      ? Math.ceil(secondsRemaining / 60)
      : Math.floor(secondsRemaining / 60);
  const secondsRemainder = secondsRemaining % 60;

  useEffect(() => {
    const getMS = () => {
      const now = dayjs(); // get current time each interval to maintain accuracy
      const delta = now.diff(timerStart); // time elapsed since timerStart
      const msRemaining = msTillStart - delta;

      setSecondsRemaining(Math.floor(msRemaining / 1000));
    };

    getMS();
    const interval = setInterval(getMS, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [msTillStart]);

  return {
    minutesRemaining,
    secondsRemaining: secondsRemainder,
    showCountdown: minutesRemaining < 60 && minutesRemaining > -60,
  };
};
