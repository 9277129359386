import api from '@/api/api';
import {
  TQueryPlatformPromoResponse,
  TQueryPlatformPromoParams,
  TAffiliatePromo,
  TQueryPlatformAffiliateResponse,
  TAffiliate,
} from './affiliates.types';

export const platformPromoEndpoint = '/bookie/promos/promo/platform-promo';
export const affiliatesCreateEndpoint = '/bookie/promos/affiliates/create';
export const affiliatesUpdateEndpoint = '/bookie/promos/affiliates/update';
export const affiliatesDeactivateEndpoint =
  '/bookie/promos/affiliates/deactivate';

export const queryPlatformPromo = (params: TQueryPlatformPromoParams) =>
  api
    .get<TQueryPlatformPromoResponse>(platformPromoEndpoint, {
      params,
    })
    .then((res) => res.data);

export function mutateAffiliatePromotion(body: TAffiliatePromo) {
  return api.post(platformPromoEndpoint, body);
}

export function mutateCreateAffiliate(body: TAffiliate) {
  return api.post(affiliatesCreateEndpoint, body);
}

export function mutateUpdateAffiliate(body: TAffiliate) {
  return api.post(affiliatesUpdateEndpoint, body);
}

export const deactivateAffiliate = (name: string) =>
  api.post(`${affiliatesDeactivateEndpoint}/${name}`, {});

export const affiliatesEndpoint = '/bookie/promos/affiliates';

export const queryAffiliates = () =>
  api
    .get<TQueryPlatformAffiliateResponse>(affiliatesEndpoint)
    .then((res) => res.data);
