import { chakra, ChakraComponent } from '@chakra-ui/react';
import ReactTimePicker from 'react-time-picker';
import { TimePickerProps } from '../Timepicker.types';

export const TimePicker = chakra(ReactTimePicker, {
  shouldForwardProp: (prop) => !['wrapperProps', 'isInvalid'].includes(prop),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: ({ wrapperProps, isInvalid }: any) => ({
    w: 'full',

    '.react-time-picker__wrapper': {
      bg: 'white',
      borderRadius: 'md',
      h: '55px',
      border: isInvalid ? '2px' : '1px',
      borderColor: isInvalid ? 'red' : 'gray.200',
      px: '2.5',
      overflow: 'visible',
      '.react-time-picker__inputGroup': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
      },
      ...wrapperProps,

      '[data-invalid="true"] &': {
        borderColor: 'red.500',
      },
    },
  }),
}) as ChakraComponent<'input', TimePickerProps>;
