import '@remirror/styles/all.css';
import React from 'react';
import WyswygHOC from './WyswygHOC';
import VisualEditor from './VisualEditor';

export type TWyswyg = {
  initialContent: string;
  policyName?: string;
  withSave?: boolean;
  onChange?: (s: string) => void;
};

export default function Wyswyg({
  initialContent,
  policyName,
  withSave,
  onChange,
}: TWyswyg) {
  return (
    <WyswygHOC>
      <VisualEditor
        initialContent={initialContent}
        withSave={withSave}
        policyName={policyName}
        onChange={onChange}
      />
    </WyswygHOC>
  );
}
