import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateAddMarketGroup } from './addMarketGroup';
import { TAddMarketGroupBody } from './addMarketGroup.types';

export const useMutationAddMarketGroup = (
  options?: Omit<
    UseMutationOptions<TMarketCreationResponse, unknown, TAddMarketGroupBody>,
    'mutationFn'
  >
) => useMutation(mutateAddMarketGroup, options);
