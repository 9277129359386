import {
  TPunterTransaction,
  TPunterCurrentDepositLimit,
  TPunterDepositLimit,
  TPunterStats,
  EBetStatus,
} from '../../../../lib/DBModels';
import { BetTokenType, BetType, RaceTypes } from '@/api/api.schema.d';
import { TDeepPartial } from '../../../../lib/types';

import { EVoidReason } from '../../../bets/modals/VoidBetModal/Services/Types.VoidBet';

export type TPunterState = {
  punterActivityLogs: TPunterActivityLog[];
  punterActivityLogsLoading: boolean;
  punterNotes: TNote[];
  punterStats?: TPunterStats;
  depositLimits: TPunterDepositLimit[];
  currentDepositLimit?: TPunterCurrentDepositLimit;
  punterTransactionsData: TPunterTransactionsResponse;
  punterTransactionsDataLoading: boolean;
  modalBonusBets?: boolean;
  modalReconcile?: number;
  amount?: number;
  depositId?: string;
  modalBalanceAdjustment?: boolean;
  modalRemovePayment: boolean;
  modalEditPayment: boolean;
};

export type TPunterActivityLog = TDeepPartial<{
  id: string;
  action_type: string;
  action_summary: string;
  ip: string;
  location?: string;
  created_at: string;
  punter_name: string | null;
  bookie_name: string | null;
  action_info: {
    bet_id?: string;
    note?: string | null;
    punter_id: string;
    reason: EVoidReason;
    transaction_id: string;
  };
}>;

export type TPunterActivityLogFilterOptions = {
  start_date?: string;
  end_date?: string;
  action_type?: string;
};

export type TActivityLogBookieForPlatform = {
  id: string;
  first_name: string;
  last_name: string;
};

// eslint-disable-next-line no-shadow
export enum ENoteType {
  ACCOUNT_BALANCE = 'Account Balance',
  DISPUTE = 'Dispute',
  VERIFICATION = 'Verification',
  PROMO = 'Promo',
  GENERAL = 'General',
}

export type TNote = TDeepPartial<{
  note_id: string;
  punter_id: string;
  bookie_id: string;
  created_at: string;
  text: string;
  note_type: ENoteType;
  has_attachment: boolean;
  bookie_name: string;
  attachments: TNoteAttachment[];
}>;

export type TNoteAttachment = TDeepPartial<{
  attachment_id?: string;
  note_id?: string;
  title: string;
  url: string;
}>;

export type TNewNoteAttachment = {
  title: string;
  url: string;
};

export type TNewPunterNote = {
  punter_id: string;
  note_type: ENoteType;
  text: string;
};

export const LimitPeriodOptions = [
  { label: '1 Day', value: 1 },
  { label: '7 Days', value: 7 },
  { label: '14 Days', value: 14 },
  { label: '28 Days', value: 28 },
];

export const BonusBetsOptions = [
  { label: 'Goodwill', value: 0 },
  { label: 'Promotion', value: 1 },
  { label: 'Reconciliation', value: 2 },
  { label: 'Customer Service', value: 3 },
  { label: 'TandC', value: 4 },
  { label: 'Deposit Match', value: 5 },
  { label: 'VIP', value: 6 },
];

export type TAddDepositLimitData = {
  amount: number;
  frequency: number;
  punter_id: string;
};

export type TCreateDepositLimitResponse = TDeepPartial<{
  deposit_limit_active: string;
  deposit_limit_cooldown_ends: string;
  deposit_limit_current: number;
  deposit_limit_frequency: number;
  deposit_limit_waiting: string;
}>;

export type TPunterTransactionsResponse = TDeepPartial<{
  total_records: number;
  total_pages: number;
  records: TPunterTransaction[];
}>;

export type TPunterTransactionsQuery = {
  punter_id?: string;
  offset?: number;
  limit?: number;
};

export type TBonusAwardItem = {
  punter: string;
  amount: number;
  reason: string;
  note: string;
};

export type TRevokeIncentive = {
  transaction_id: string;
  reason: string;
};

export type TEventType = 'Race' | 'Match';

export type TBetEventData = {
  venue_name: string;
  race_name: string;
  race_number: number;
  runner_name: string;
  runner_number: number;
  runner_barrier_number: number;
  match_name: string;
  proposition_name: string;
};

export type TBetsData = TDeepPartial<{
  bet_id: string;
  event_id: string;
  created_at: string;
  event_type: TEventType;
  event_icon: string;
  stake: number;
  odds: number;
  is_won?: boolean;
  is_settled: boolean;
  punter_id: string;
  punter_name: string;
  punter_type: string;
  event_title: string;
  event_subtitle: string;
  event_start: string;
  market_name: string;
  is_flagged: boolean;
  bet_description: string;
  event_data: TBetEventData;
  tab_odds?: number;
  current_odds?: number;
  bonus_stake?: number;
  bcs?: number;
  punter_total_profit?: number;
  punter_code_profit?: number;
  event_exposure?: number;
  reason?: string;
  date_issued: string;
  date_used?: string;
  bonus_cash_award: number;
  result?: number;
  status?: EBetStatus;
}>;

export type TBetResponse = TDeepPartial<{
  total_pages: number;
  total_records: number;
  records: TBetsData[];
}>;

export type TBetBonusData = {
  bonus_cash_awarded: number;
  date_issued: string;
  date_used: string;
  id: string;
  reason: string;
};

export type TPunterReconcile = {
  deposit_id: string;
  amount: number;
};

export type BetTokenTypeSchema = (typeof BetTokenType)[number];
export type BetTypeSchema = (typeof BetType)[number];
export type RaceTypesSchema = (typeof RaceTypes)[number];

export type PunterRestrictionsSchema = {
  promo_tokens: string[];
  bet_type: string[];
  racing: string[];
  sport: string[];
};

export const SportTypes = [
  'Baseball',
  'Football',
  'Racing Futures',
  'Table Tennis',
  'Cricket',
  'Rugby Union',
  'Sport Futures',
  'Bowls',
  'Tennis',
  'Snooker',
  'Sports Futures',
  'newp',
  'Soccer',
  'Ping Pong',
  'Golf',
  'Badminton',
  'American Football',
  'Handball',
  'Australian Rules',
  'Volleyball',
  'Netball',
  'Basketball',
  'Surfing',
  'Rugby League',
  'Beach Volleyball',
  'Specials',
  'Cycling',
  'Fencing',
  'Darts',
  'Motor Racing',
  'Sumo',
  'Ice Hockey',
];

export type TPunterProfileSettingsParams = {
  punter_id: string | null;
  bookie_category?: string;
  storm_category?: string | null;
  use_storm_category?: boolean;
  t?: number;
  withdrawal_cancel_disabled?: boolean;
};

export type TPunterProfileSettingsSchema = TPunterProfileSettingsParams & {
  restrictions?: PunterRestrictionsSchema;
};

export type TPunterProfileOfferingsParams = {
  punter_id: string | null;
  restrictions?: PunterRestrictionsSchema;
};
