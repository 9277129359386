import React from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import useCurrencySymbol from '@/hooks/useCurrencySymbol';
import usePercentSign from '@/hooks/usePercentSign';
import {
  TAffiliate,
  TAffiliateSubmission,
} from '@/api/promoManager/affiliates/affiliates.types';
import { useOutletContext } from 'react-router-dom';
import { number, object, string } from 'yup';
import { UseMutateFunction } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { TBetCloudErrorResponse } from '@/api/api.types';

type TAffiliateModal = {
  modalType: 'create' | 'update';
  submit: UseMutateFunction<
    AxiosResponse<unknown, unknown>,
    AxiosError<TBetCloudErrorResponse, unknown>,
    TAffiliateSubmission,
    unknown
  >;
  currentValues?: TAffiliate;
  setSelectedAffiliateEdit?: (val: TAffiliate | undefined) => void;
};

dayjs.extend(utc);
dayjs.extend(tz);

export const AffiliateModal = ({
  modalType,
  submit,
  currentValues,
  setSelectedAffiliateEdit,
}: TAffiliateModal) => {
  const currencySymbol = useCurrencySymbol();
  const percentSign = usePercentSign();
  const { isOpen, onClose } = useOutletContext<ModalProps>();
  const timeZone = dayjs.tz.guess();

  const handleClose = () =>
    modalType === 'update' && setSelectedAffiliateEdit
      ? setSelectedAffiliateEdit(undefined)
      : onClose();

  const handleSetPromoCodeValue = (val: string) => {
    const allowedCharactersPattern = /^[a-zA-Z0-9!@#$%^&*()_+=[\]{}|\\/?.~-]+$/;
    const sanitizedValue = val
      .toUpperCase()
      .split('')
      .filter((char) => allowedCharactersPattern.test(char))
      .join('');
    setFieldValue('default_deposit_match.promo_code', sanitizedValue);
  };

  const {
    errors,
    getFieldProps,
    handleBlur,
    handleSubmit,
    isSubmitting,
    resetForm,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
    dirty,
  } = useFormik<TAffiliate>({
    initialValues: currentValues ?? {
      affiliate_name: '',
      default_token: '',
      default_deposit_match: {
        affiliate_token: '',
        id: undefined,
        name: undefined,
        reason: 'first_deposit_match',
        affiliate_code: '',
        promo_code: undefined,
        promo_type: 'first_deposit_match',
        expiry: undefined,
        percentage: undefined,
        max_amount: undefined,
      },
      status: '',
    },
    validationSchema: object({
      affiliate_name: string().required('Affiliate Name is required'),
      default_token: string().required('Default Token is required'),
      default_deposit_match: object()
        .shape({
          affiliate_code: string(),
          promo_code: string(),
          promo_type: string(),
          percentage: number(),
          max_amount: number(),
        })
        .test((values, context) => {
          const { promo_code, percentage, max_amount } = values;
          const isAnyPopulated = promo_code || percentage || max_amount;
          if (isAnyPopulated) {
            if (!percentage) {
              return context.createError({
                message: `You've added a ${
                  !!promo_code ? 'promo code' : 'max amount'
                } so must also add a percentage`,
                path: 'default_deposit_match.percentage',
              });
            }
            if (!max_amount) {
              return context.createError({
                message: `You've added a ${
                  !!promo_code ? 'promo code' : 'percentage'
                } so must also add a max amount`,
                path: 'default_deposit_match.max_amount',
              });
            }
          }
          return true;
        }),
    }).required(),
    onSubmit: (values) => {
      try {
        submit({
          ...values,
          default_deposit_match:
            values.default_deposit_match?.promo_code ||
            values.default_deposit_match?.max_amount ||
            values.default_deposit_match?.percentage
              ? {
                  ...values.default_deposit_match,
                  affiliate_token: values.default_token,
                  percentage: values.default_deposit_match?.percentage
                    ? values.default_deposit_match.percentage / 100
                    : undefined,
                  max_amount: values.default_deposit_match?.max_amount
                    ? values.default_deposit_match.max_amount * 100
                    : undefined,
                  id:
                    modalType === 'create'
                      ? undefined
                      : values.default_deposit_match?.id ?? undefined,
                  promo_type:
                    values.default_deposit_match?.promo_type ??
                    'first_deposit_match',
                }
              : undefined,
        });
        resetForm();
        handleClose();
      } catch {}
    },
  });

  return (
    <Modal
      isOpen={modalType === 'create' ? isOpen : !!currentValues}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            {modalType.charAt(0).toUpperCase() + modalType.slice(1)} Affiliate
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              resetForm();
              handleClose();
            }}
          />
          <Stack as={ModalBody} spacing={4}>
            <FormControl
              isInvalid={touched.affiliate_name && !!errors.affiliate_name}
            >
              <FormLabel>Affiliate Name</FormLabel>
              <Input
                {...getFieldProps('affiliate_name')}
                value={values.affiliate_name}
                isDisabled={modalType === 'update'}
              />
              <FormErrorMessage>{errors.affiliate_name}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={touched.default_token && !!errors.default_token}
            >
              <FormLabel>Default Affiliate Token</FormLabel>
              <Input
                {...getFieldProps('default_token')}
                value={values.default_token}
                isDisabled={modalType === 'update'}
              />
              <FormErrorMessage>{errors.default_token}</FormErrorMessage>
            </FormControl>

            <ModalHeader display="flex" p={0}>
              <Text flex={1} fontSize="md">
                Add a Default Deposit Match{' '}
                <Text
                  as="span"
                  fontSize="xs"
                  textTransform="uppercase"
                  fontWeight="medium"
                >
                  (Optional)
                </Text>
              </Text>
            </ModalHeader>
            <FormControl
              isInvalid={
                touched.default_deposit_match?.promo_code &&
                !!errors.default_deposit_match?.promo_code
              }
            >
              <FormLabel>
                <FormattedMessage id="promomanagerpage.affiliates.promoCodeLabel" />
              </FormLabel>
              <Input
                {...getFieldProps('default_deposit_match.promo_code')}
                onChange={(event) =>
                  handleSetPromoCodeValue(event.target.value)
                }
              />
              <FormErrorMessage>
                {errors.default_deposit_match?.promo_code}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                touched.default_deposit_match?.percentage &&
                !!errors.default_deposit_match?.percentage
              }
            >
              <FormLabel>
                <FormattedMessage
                  id="punterPage.incentives.percentageLabel"
                  values={{ percentSign }}
                />
              </FormLabel>
              <NumberInput
                allowMouseWheel
                min={1}
                max={100}
                value={values.default_deposit_match?.percentage}
                onBlur={() =>
                  setFieldTouched('default_deposit_match.percentage')
                }
                onChange={(value) =>
                  setFieldValue(
                    'default_deposit_match.percentage',
                    !!value ? Number(value) : undefined
                  )
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {errors.default_deposit_match?.percentage}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                touched.default_deposit_match?.max_amount &&
                !!errors.default_deposit_match?.max_amount
              }
            >
              <FormLabel>
                <FormattedMessage
                  id="punterPage.incentives.maxAmountLabel"
                  values={{ currencySymbol }}
                />
              </FormLabel>
              <NumberInput
                allowMouseWheel
                min={1}
                value={values.default_deposit_match.max_amount}
                onBlur={() =>
                  setFieldTouched('default_deposit_match.max_amount')
                }
                onChange={(value) =>
                  setFieldValue(
                    'default_deposit_match.max_amount',
                    !!value ? Number(value) : undefined
                  )
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormErrorMessage>
                {errors?.default_deposit_match?.max_amount}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                touched.default_deposit_match?.expiry &&
                !!errors.default_deposit_match?.expiry
              }
            >
              <FormLabel>
                <FormattedMessage id="punterPage.incentives.expiryLabel" />
              </FormLabel>
              <DateTimePicker
                disableClock
                minDate={new Date()}
                name="expiry"
                value={
                  values.default_deposit_match.expiry
                    ? dayjs
                        .utc(values.default_deposit_match.expiry)
                        .tz(timeZone)
                        .format()
                    : undefined
                }
                wrapperProps={{ h: '10' }}
                onCalendarClose={() =>
                  handleBlur('default_deposit_match.expiry')
                }
                onCalendarOpen={() =>
                  setFieldTouched('default_deposit_match.expiry')
                }
                onChange={(value) =>
                  setFieldValue(
                    'default_deposit_match.expiry',
                    value === null
                      ? undefined
                      : dayjs(value).utc()?.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                  )
                }
              />
              <FormErrorMessage>
                {errors.default_deposit_match?.expiry}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <ModalFooter>
            <Button
              variant="ghost"
              mr="3"
              onClick={() => {
                resetForm();
                handleClose();
              }}
            >
              <FormattedMessage id="punterPage.incentives.cancelButton" />
            </Button>
            <Button type="submit" isDisabled={!dirty} isLoading={isSubmitting}>
              <FormattedMessage id="punterPage.incentives.confirmButton" />
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
