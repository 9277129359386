import { TLimit } from '../../../features/settings/pages/Settings/tabs/Limits/types';
import api from '../../api';

export const exoticLimitsURL = 'bookie/settings/limits/exotic-limit';

export const updateExoticLimit = async (limit: TLimit) =>
  api.put(exoticLimitsURL, limit).then((res) => res.data);

export const queryExoticLimit = async () =>
  api.get<number>(exoticLimitsURL).then((res) => res.data);
