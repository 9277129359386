import { EGeneralStatus, TRaceMarket } from '@/lib/DBModels';
import { EStatusOptions } from '../Status.types';

/* Get status for market */
export const getStatus = (current: TRaceMarket, ignoreWinterOn: boolean) => {
  if (current.market_is_suspended) return EStatusOptions.Suspend;
  if (
    current.lock_status &&
    current.status === EGeneralStatus.Closed &&
    current.is_visible
  )
    return EStatusOptions.SoftClose;
  if (
    current.lock_status &&
    current.status === EGeneralStatus.Closed &&
    !current.is_visible
  )
    return EStatusOptions.HardClose;
  if (ignoreWinterOn) return EStatusOptions.Open;
  return EStatusOptions.Winter;
};
