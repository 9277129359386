import { useMutation, UseMutationOptions } from 'react-query';
import { TMarketCreationResponse } from '../marketCreation.types';
import { mutateUpdateMarketGroup } from './updateMarketGroup';
import { TUpdateMarketGroupBody } from './updateMarketGroup.types';

export const useMutationUpdateMarketGroup = (
  options?: Omit<
    UseMutationOptions<
      TMarketCreationResponse,
      unknown,
      TUpdateMarketGroupBody
    >,
    'mutationFn'
  >
) => useMutation(mutateUpdateMarketGroup, options);
