import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TPuntersResponse, TPuntersState } from './Punters.types';

import { getAllPunters } from './Punters.actions';

const initialState: TPuntersState = {
  puntersData: {
    records: [],
    total_pages: 0,
    total_records: 0,
  },
  puntersLoading: false,
};

const allPuntersSlice = createSlice({
  name: 'allPunters',
  initialState,
  reducers: {
    setAllPunters: (
      state: TPuntersState,
      action: PayloadAction<TPuntersResponse>
    ) => ({
      ...state,
      puntersData: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPunters.pending, (state: TPuntersState) => ({
        ...state,
        puntersLoading: true,
      }))
      .addCase(getAllPunters.fulfilled, (state: TPuntersState) => ({
        ...state,
        puntersLoading: false,
      }))
      .addCase(getAllPunters.rejected, (state: TPuntersState) => ({
        ...state,
        puntersLoading: false,
      }));
  },
});

export const { setAllPunters } = allPuntersSlice.actions;

export default allPuntersSlice.reducer;
