import { useMutationSettleBetProps } from '@/api/betApprovals/betApprovals.hooks';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { keys } from '../../../../../../../api/api.keys';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../common/hooks/useRedux';
import { dollarsToCents } from '../../../../../../../common/utils';
import { errorMessageFromHTTPException } from '../../../../../../../lib/Error';
import { setModalSettleBet } from '../../../Services/Slice.Bets';

type TFormikHOC = {
  children: ReactNode;
};

export default function FormikHOC({ children }: TFormikHOC) {
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const { modalSettleBet } = useAppSelector((state) => state.punterBets);
  const { mutateAsync } = useMutationSettleBetProps();

  return (
    <Formik
      initialValues={{
        is_won: '',
        payout: 0,
        // deduction: '',
      }}
      validationSchema={Yup.object().shape({
        is_won: Yup.boolean().required(),
        payout: Yup.number().required(),
        // deduction: Yup.number().required(),
      })}
      onSubmit={async ({ is_won, payout }, { setSubmitting }) => {
        try {
          setSubmitting(true);

          const data = {
            bet_id: modalSettleBet?.bet_id ?? '',
            is_won: is_won === 'true',
            payout: is_won === 'false' ? 0 : Number(dollarsToCents(payout)),
          };

          mutateAsync({
            ...data,
          });

          client.invalidateQueries(keys.punterBets).catch(() => undefined);

          dispatch(setModalSettleBet());
        } catch (error) {
          toast.error(errorMessageFromHTTPException(error));
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
}
