import React from 'react';
import Input from '@/common/components/FormElements/Input';
import { inputLabelStyles } from '../styles/Filters.styles';
import { useIntl } from 'react-intl';

const ByTicketNumberForm = () => {
  const intl = useIntl();

  return (
    <>
      <Input
        variant="filled"
        size="lg"
        label={intl.formatMessage({
          id: 'regulatorpage.tabs.bethistory.ticketnumber',
        })}
        propsLabel={inputLabelStyles}
        sxWrapper={{ marginBottom: '0', maxW: '200px' }}
        name="ticket_number"
        placeholder={intl.formatMessage({
          id: 'regulatorpage.tabs.bethistory.ticketnumber',
        })}
      />
    </>
  );
};

export default ByTicketNumberForm;
