import React, { FC } from 'react';

import { getStrings } from '../../utils';
import { Separator } from '..';

import {
  MultiSelectActionWrapper,
  ClearSelectionsButton,
  SelectedRecordsText,
  MultiSelectActionsContainer,
} from './Styles';

import { DataTableMultiSelectBarProps } from './types';

export const DataTableMultiSelectBar: FC<DataTableMultiSelectBarProps> = ({
  children,
  selected,
  setSelected,
}) => {
  const [{ Generic: Strings }] = getStrings();

  return (
    <MultiSelectActionWrapper visible={!!selected.length}>
      <ClearSelectionsButton onClick={() => setSelected([])}>
        <b>-</b>
      </ClearSelectionsButton>
      <SelectedRecordsText>
        {Strings.WithRecordsSelected}
        {` (${selected.length})`}
      </SelectedRecordsText>
      <Separator vertical />
      <MultiSelectActionsContainer>{children}</MultiSelectActionsContainer>
    </MultiSelectActionWrapper>
  );
};
