import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { TBetCloudErrorResponse } from '../../api.types';
import { mutatePromotion } from './promotion';
import { TMutatePromotionBody } from './promotion.types';

export const useMutationPromotion = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse,
      AxiosError<TBetCloudErrorResponse>,
      TMutatePromotionBody
    >,
    'mutationFn'
  >
) => useMutation(mutatePromotion, options);
