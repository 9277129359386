/* eslint-disable no-param-reassign */
import { EGeneralStatus, TRunner } from '../../../../../lib/DBModels';
import { MARGIN_TICK_MAP } from '@/lib/Constants';
import { EModifierType } from './RaceDetails.types';

export const clampToTickMap = (index: number): number =>
  Math.min(MARGIN_TICK_MAP.length - 1, Math.max(0, index));

export const getPrice = (originalPrice: number, tick: number) => {
  const originalTickIndex = MARGIN_TICK_MAP.findIndex(
    (tick) => tick.odds === originalPrice
  );

  const clampedTickIndex = Math.min(
    MARGIN_TICK_MAP.length - 1,
    Math.max(0, clampToTickMap(originalTickIndex + tick))
  );

  return MARGIN_TICK_MAP[clampedTickIndex].odds;
};

/* This function is to determine wich modifier to select when browsing through the modifiers */
export const getNextModifier = (
  currentModifier: string,
  runners: TRunner[]
) => {
  const regexNumber = /\d+/; // Get modifier number
  const regexType = /[a-zA-Z]+/g; // Get modifier type

  const currentModifierNumber = parseInt(
    regexNumber.exec(currentModifier)?.[0] ?? '',
    10
  );

  const currentModifierType = regexType.exec(currentModifier)?.[0] ?? '';

  /* If it is the last modifier go back to the first win modifier in the list */
  if (
    currentModifierNumber === runners.length &&
    currentModifierType === EModifierType.Place
  ) {
    return `${EModifierType.Win}-1`;
  }

  /* If it is the win modifier select the place modifier next to it */
  if (currentModifierType === EModifierType.Win) {
    return `${EModifierType.Place}-${currentModifierNumber}`;
  }

  /* If it is the place modifier and the next runner is scratched find the next modifier to select */
  if (
    currentModifierType === EModifierType.Place &&
    runners?.[currentModifierNumber]?.status === EGeneralStatus.Scratched
  ) {
    /* Determine the number of scratched runners after the current one */
    const remainingRunners = runners?.slice(currentModifierNumber);

    const nextIndex = remainingRunners.findIndex(
      (r) => r?.status !== EGeneralStatus.Scratched
    );

    /* if no scratched runners after current one select the first */
    const newModifierNumber =
      nextIndex === -1 ? 1 : currentModifierNumber + 1 + nextIndex;

    return `${EModifierType.Win}-${newModifierNumber}`;
  }

  /* If it is the place modifier select the win modifier of the next runner */
  if (currentModifierType === EModifierType.Place) {
    return `${EModifierType.Win}-${currentModifierNumber + 1}`;
  }

  return `${EModifierType.Win}-1`;
};

export const isScratched = (datum: TRunner) =>
  datum?.is_scratched ||
  datum?.status === EGeneralStatus.Scratched ||
  datum?.status === EGeneralStatus.Removed ||
  datum?.status === EGeneralStatus.Voided;

export const isRemoved = (datum: TRunner) =>
  datum?.status === EGeneralStatus.Removed;

/**
 * Calculates the odds margin percentage
 */
export const calculateOddsMarginPercentage = (prices: number[]) => {
  // Calculate the odds margin percentage for the valid runners.
  const newOddsMarginPercentage = prices.reduce((acc, price) => {
    if (!price) return acc;

    // Calculate the percentage for this runner and add it to the accumulator.
    const percentage = 100 / price;
    return acc + percentage;
  }, 0);

  // Round the newOddsMarginPercentage to 2 decimal places.
  return parseFloat(newOddsMarginPercentage.toFixed(2));
};
