import { keys } from '@/api/api.keys';
import { UseQueryOptions, useQuery } from 'react-query';
import { queryGetCompetition } from './getCompetition';
import { TCompetition, TGetCompetitionParams } from './getCompetition.types';

// Trade Manager - Get Competition

type TQueryGetCompetitionHook = {
  params: TGetCompetitionParams;
  options?: Omit<UseQueryOptions<TCompetition[]>, 'queryKey' | 'queryFn'>;
};

export const useQueryGetCompetition = ({
  params,
  options,
}: TQueryGetCompetitionHook) =>
  useQuery(
    [keys.getCompetition, params],
    () => queryGetCompetition(params),
    options
  );
