import api from '../../api';
import { TSRMParams } from './srm.types';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { getPrefixPath } from '@/common/utils';

const srmBetsURL = `/${getPrefixPath(isWincore)}/trade-manager/srmultis`;

// Trade Manager - SRM Bets
export const querySRMBets = (params: TSRMParams) =>
  api
    .get<Api['SRMultisRaceBetsResponse']>(`${srmBetsURL}/bets`, { params })
    .then((res) => res.data);

// Trade Manager - SRM Runners
export const querySRMRunners = (params: TSRMParams) =>
  api
    .get<Api['SRMultisRaceRunnerStatsResponse']>(`${srmBetsURL}/runners`, {
      params,
    })
    .then((res) => res.data);

// Trade Manager - SRM Combinations
export const querySRMCombinations = (params: TSRMParams) =>
  api
    .get<Api['SRMultisRaceCombinationStatsResponse']>(
      `${srmBetsURL}/combinations`,
      { params }
    )
    .then((res) => res.data);

// Trade Manager - SRM Punter Combinations
export const querySRMPunterCombinations = (params: TSRMParams) =>
  api
    .get<Api['SRMultisRaceCombinationPunterStatsResponse']>(
      `${srmBetsURL}/combination-punters`,
      { params }
    )
    .then((res) => res.data);
